import React from 'react';
import CheckboxItem from '../Core/CheckboxItem/CheckboxItem';
import ConnectForm from './ConnectForm';

type CheckboxItemGroupProps = {
  options: { label: string; value: unknown }[];
  name: string;
};

function CheckboxItemGroup({ options, name }: CheckboxItemGroupProps) {
  return (
    <ConnectForm>
      {({ register }) => (
        <div className={'checkbox-group'}>
          {options.map((option) => (
            <CheckboxItem {...register(name)} label={option.label} value={option.value as never} />
          ))}
        </div>
      )}
    </ConnectForm>
  );
}

export default CheckboxItemGroup;
