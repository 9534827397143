import React, { InputHTMLAttributes, SyntheticEvent, useMemo, useState } from 'react';
import moment from 'moment';
import InputBase, { INPUT_COLORS } from '../../../components/Core/InputBase/InputBase';

type DateInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'color' | 'onChange'> & {
  displayValue?: string;
  //eslint-disable-next-line
  onChange: any;
};

function DateInput(props: DateInputProps) {
  const { displayValue, onChange: onChangeProp, ...propsToForward } = props;
  const [dateString, setDateString] = useState<string | undefined>('');

  const value = useMemo(() => {
    return !displayValue ? dateString : displayValue;
  }, [displayValue]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onChange = (newValue: unknown, e?: SyntheticEvent) => {
    setDateString(newValue as string);

    const date = moment(newValue as string, 'DD/MM/YYYY', true);

    date.isValid() ? onChangeProp(date) : onChangeProp(null);
  };

  return <InputBase color={INPUT_COLORS.normal} value={value} onChange={onChange} {...propsToForward} />;
}

export default DateInput;
