/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';
import styled from '@emotion/styled';
import { SHA256 } from 'crypto-js';
import React, { PropsWithChildren, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Joi from 'joi';
import passwordComplexity from 'joi-password-complexity';
import { selectSessionToken, selectUser } from '../../auth/selectors/authSelectors';
import Button from '../../components/Core/Button/Button';
import FormGroupCard from '../../components/FormGroupCard/FormGroupCard';
import useBuildForm from '../../hook/useBuildForm';
import { PWD_SEED } from '../../internal/constants/api';
import { homeRoute } from '../../router/routes';
import { GetFormFieldsFunction } from '../admin/Collectivity/updateForm';
import { saveUser } from '../../auth/actions/authActions';
import { useSnackbar } from '../../context/SnackbarContext';
import { TOAST_SEVERITY } from '../../components/Core/Toast/Toast';
import usePasswordChangeApi from './usePasswordChangeApi';

const getPasswordChangeForm: GetFormFieldsFunction = (t) => {
  return [
    {
      fields: [
        {
          name: 'password',
          type: 'password',
          label: t('password'),
          helper: t('helper'),
          placeholder: t('password'),
          schema: passwordComplexity({
            min: 12,
            max: 1000,
            upperCase: 1,
            lowerCase: 1,
            numeric: 1,
            symbol: 1,
            requirementCount: 6,
          }).required(),
        },
        {
          name: 'copy_password',
          type: 'password',
          label: t('copyPassword'),
          placeholder: t('copyPassword'),
          schema: Joi.string()
            .required()
            .valid(Joi.ref('password'))
            .messages({ 'any.only': t('form:errors.passwordsDontMatch') }),
        },
      ],
    },
  ];
};

function PasswordChangePage() {
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const { t } = useTranslation(['changePassword', 'form']);
  const { putUserPassword } = usePasswordChangeApi();
  const token = useSelector(selectSessionToken);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const { builtFormFields, handleSubmit } = useBuildForm({ formObject: getPasswordChangeForm(t), colsNumber: 1 });

  const onSubmit = useCallback(
    handleSubmit(async (data) => {
      const response = await putUserPassword({
        token,
        password: SHA256(data.password + PWD_SEED).toString(),
        copypassword: SHA256(data.copy_password + PWD_SEED).toString(),
      });
      if (response && response.status === 200) {
        snackbar?.setAlert({ message: t('successAlert'), severity: TOAST_SEVERITY.success });
        //eslint-disable-next-line
        dispatch(saveUser({ ...user, token: response.data.token, mustChangePassword: false } as unknown as any));
        // TODO: add success alert ???
        navigate(homeRoute.toLink());
      }
    }),
    [token, user]
  );

  return (
    <ChangePasswordRoot>
      <FormGroupCard
        css={css`
          min-width: 400px;
          max-width: 500px;
        `}
        title={t('title')}
        WrapperComponent={({ children }: PropsWithChildren<unknown>) => <form onSubmit={onSubmit}>{children}</form>}
        FooterSlotComponent={() => (
          <FooterCard>
            <Button type={'submit'} label={t('form:global_update_label')} />
          </FooterCard>
        )}>
        {builtFormFields}
      </FormGroupCard>
    </ChangePasswordRoot>
  );
}

const ChangePasswordRoot = styled.main`
  background-color: gray;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: normal;
`;

const FooterCard = styled.div`
  display: grid;
  justify-content: flex-end;
  padding: 16px;
`;

export default PasswordChangePage;
