import { AxiosResponse } from 'axios';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryFunction } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RawGenericListReturn } from '../../../api/params/vouchers.api';
import { fetchUserInvoices } from '../../../api/user.api';
import { selectCollAndDeliveryPointID, selectSessionToken } from '../../../auth/selectors/authSelectors';
import IconButton from '../../../components/Core/Button/IconButton';
import { RowItem } from '../../../components/Core/Datagrid/Datagrid';
import { GridColDefinition } from '../../../components/Core/Datagrid/gridCols';
import MainSection from '../../../components/Core/MainSection/MainSection';
import GenericList from '../../Parameters/Voucher/components/GenericList';
import { ListParams } from '../../park/BikeList/BikeList';

function useColumns(): GridColDefinition[] {
  const { t } = useTranslation('contracts/invoiceList');
  const token = useSelector(selectSessionToken);

  return useMemo(() => {
    return [
      { field: 'date_facture', type: 'date', headerName: t('dateInvoice'), sortable: true, format: 'DD/MM/YYYY' },
      {
        field: 'total_ttc',
        // width: 250,
        type: 'custom',
        headerName: t('amount'),
        renderCell: ({ row }) => {
          const hasSecurityDeposit = !!row.montant_garantie_ttc && parseFloat(row.montant_garantie_ttc) > 0;
          return (
            <RowItem>
              {row.total_ttc}
              {row.devise}
              {hasSecurityDeposit && (
                <>
                  <br />
                  {' '.concat(
                    t('has_security_deposit', {
                      securityDepositAmount: row.montant_garantie_ttc,
                      articleAmount: row.montant_article_ttc,
                      currency: row.devise,
                    })
                  )}
                </>
              )}
            </RowItem>
          );
        },
      },
      {
        field: 'operation',
        type: 'string',
        headerName: t('operation'),
        sortable: true,
      },
      {
        type: 'custom',
        field: '',
        headerName: '',
        renderCell: ({ row }) => {
          return (
            <RowItem>
              {row.date_anonymization ? (
                <span>
                  <i>{t('archived')}</i>
                </span>
              ) : (
                <IconButton as={'a'} target={'_blank'} href={`${row.media_url}&token=${token}`} icon={'UploadIcon'} />
              )}
            </RowItem>
          );
        },
      },
    ];
  }, [t, token]);
}

function InvoiceListPage() {
  const { t } = useTranslation('user/userLayout');
  const { userId } = useParams();
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const columns = useColumns();

  const fetchContracts: QueryFunction<AxiosResponse<RawGenericListReturn> | undefined> = ({ queryKey }) => {
    const [, listParams] = queryKey as [string, ListParams & { id_coll: number; id_user?: number }];

    if (listParams.id_user) {
      return fetchUserInvoices({
        id_coll: listParams.id_coll,
        id_user: listParams.id_user,
        ipp: listParams.itemPerPage,
        p: listParams.currentPage,
      });
    }
  };

  //eslint-disable-next-line
  const transformFn = useCallback((dataResponse: any) => {
    //eslint-disable-next-line
    return { rows: (dataResponse?.data as any) || [] };
  }, []);

  return (
    <MainSection>
      <GenericList
        title={t('invoiceListTitle')}
        transformFn={transformFn}
        queryFn={fetchContracts}
        queryKey={['user.invoice.list', { id_coll, id_user: userId }]}
        columns={columns}
      />
    </MainSection>
  );
}

export default InvoiceListPage;
