import React, { useEffect, useMemo } from 'react';
import {
  ActionFunction,
  json,
  LoaderFunction,
  NavLink,
  useFetcher,
  useLoaderData,
  useNavigate,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { QueryClient } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import _ from 'lodash';
import { useMainTitle } from '../../../../components/Layout/MainTitleContext';
import MainSection from '../../../../components/Core/MainSection/MainSection';
import Card from '../../../../components/Core/Card/Card';
import ButtonWithIcon from '../../../../components/Core/Button/ButtonWithIcon';
import store from '../../../../internal/store';
import { instance } from '../../../../api/user.api';
import useDisplayError from '../../../../hook/useDisplayError';
import { TOAST_SEVERITY } from '../../../../components/Core/Toast/Toast';
import { useSnackbar } from '../../../../context/SnackbarContext';
import { dataURLtoFile } from '../../../../parameters/utils/file';
import { velocareTypesQuery } from '../Bike/NewBike.page';
import { ParkForm } from './NewParkPage';
import { parkListQuery } from './ParkListPage';

/*const mock = new MockAdapter(instance, { onNoMatch: 'passthrough' });
mock.onGet('bike/3').reply(200, { name: 'test', description: 'test' });*/

export const editParkAction: (queryClient: QueryClient) => ActionFunction =
  (queryClient) =>
  async ({ request }) => {
    const collId = store.getState().authState.collectivity;
    const { img, ...data } = await request.json();
    let response = null;

    try {
      response = await instance.post('consigne/edit', { ...data, idColl: collId });
      if (response.status === 200) {
        await queryClient.invalidateQueries(['consigne/list']);
        if (img) {
          const file = await dataURLtoFile(img.src, 'img.png');
          await instance.put('consigne/media', file, {
            params: { idColl: collId, idArticle: response.data.idArticle },
          });
        }
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        console.error(e);
        return json(e.response?.data);
      }
    }

    return response;
  };

export const editParkLoader: (queryClient: QueryClient) => LoaderFunction =
  (queryClient) =>
  async ({ params }) => {
    const collId = store.getState().authState.collectivity;

    const listParkResponse = await queryClient.ensureQueryData({
      ...parkListQuery({ idColl: collId }),
    });

    const velocareTypesResponse = await queryClient.ensureQueryData({
      ...velocareTypesQuery({ id_coll: collId }),
    });

    return json({ collId, listParkResponse, velocareTypesResponse, parkId: Number(params.id) });
  };

function EditParkPage() {
  const fetcher = useFetcher();
  const { t } = useTranslation(['parameters/products/editPark', 'form']);
  useDisplayError(fetcher.data);
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  // const params = useParams();
  const { listParkResponse, parkId } = useLoaderData() as {
    listParkResponse?: AxiosResponse;
    collId: number;
    parkId: number;
  };

  const parkSelected = useMemo(() => {
    return _.find(listParkResponse?.data, { id: parkId });
  }, [listParkResponse, parkId]);

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      img: { src: parkSelected?.mediaUrl },
      name: parkSelected?.name,
      description: parkSelected?.description,
      velocareType: parkSelected?.velocareType,
    },
  });
  useMainTitle(parkSelected?.name || '');

  const onSubmit = (data: FieldValues) => {
    const copyData = _.cloneDeep(data);
    if (copyData.img.src === parkSelected?.mediaUrl) delete copyData.img;

    fetcher.submit({ ...copyData, id: parkId }, { method: 'post', encType: 'application/json' });
  };

  useEffect(() => {
    if (fetcher.data && fetcher.data.status === 200) {
      snackbar?.setAlert({
        message: t('successMessage'),
        severity: TOAST_SEVERITY.success,
      });
      navigate('/parametres/articles/consigne');
    }
  }, [fetcher.data, t]);

  return (
    <MainSection className={'reset'}>
      <Card
        content={
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <p>{t('form:all_required')}</p>
              <ParkForm />
              <div className={'row justify-between mt-4'}>
                <ButtonWithIcon
                  as={NavLink}
                  to={'/parametres/articles/consigne'}
                  icon={'CrossWithoutCircle'}
                  color={'secondary'}>
                  {t('common:cancel')}
                </ButtonWithIcon>
                <ButtonWithIcon icon={'CheckWithoutCircle'} type={'submit'}>
                  {t('common:edit')}
                </ButtonWithIcon>
              </div>
            </form>

            <DevTool control={methods.control} />
          </FormProvider>
        }
      />
    </MainSection>
  );
}

export default EditParkPage;
