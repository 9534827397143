/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { css, ThemeProvider } from '@emotion/react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { selectSessionToken } from '../../../../auth/selectors/authSelectors';
import MainSection from '../../../../components/Core/MainSection/MainSection';
import { SERVICE_KEYS } from '../../../../ioc/keys';
import { GetAllDepositsResult } from '../../../../repository/parameter.repository';
import Datagrid from '../../../../components/Core/Datagrid/Datagrid';
import Card from '../../../../components/Core/Card/Card';
import Button from '../../../../components/Core/Button/Button';
import theme from '../../../../theme';
import container from '../../../../ioc/ioc';
import { Api } from '../../../../api/axios';
import DepositRow from './components/DepositRow';
import withDepositListData from './withDepositListData';
import useDepositListColumns from './useDepositListColumns';

type DepositListProps = {
  depositList: GetAllDepositsResult;
};

type DepositRow = { article: string; depotId: number; depotName: string; articles: number[] };

export function DepositList(props: DepositListProps) {
  const { t } = useTranslation(['deposit/listPage']);
  const { depositList } = props;
  const [depositRows, setDepositRows] = useState<DepositRow[]>([]);
  const columnsDefinition = useDepositListColumns({ depositRows, setDepositRows });

  useEffect(() => {
    const entries = Object.entries(depositList);
    setDepositRows(() =>
      entries.length > 0
        ? [
            ...entries.map(([article, groupDepositItem]) => [
              ...groupDepositItem.map((depositItem) => ({ ...depositItem, article })),
            ])[0],
          ]
        : []
    );
  }, [depositList]);

  return (
    <MainSection className={'reset'}>
      <Header>
        <Button as={NavLink} to={'/parametres/depot-de-garantie/create'} label={t('add_cta')} />
      </Header>
      <Card
        title={t('list_title')}
        css={css`
          padding: 0;
          height: 100%;
        `}>
        <Datagrid RowComponent={DepositRow} rows={depositRows} columns={columnsDefinition} />
      </Card>
    </MainSection>
  );
}

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export function withTheme<T>(WrappedComponent: React.ComponentType<T>) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const ComponentWithTheme = (props: T) => {
    const [ready, setReady] = useState(false);
    const token = useSelector(selectSessionToken);
    const api = container.get<Api>(SERVICE_KEYS.API_INSTANCE);

    useEffect(() => {
      if (token) {
        api.setAuthToken(token);
        setReady(true);
      }
    }, [token]);

    return (
      <ThemeProvider theme={theme}>
        <div className={'reset'}>{ready && <WrappedComponent {...(props as T)} />}</div>
      </ThemeProvider>
    );
  };

  ComponentWithTheme.displayName = `withTheme(${displayName})`;

  return ComponentWithTheme;
}

export default withTheme(withDepositListData(DepositList));
