import { combineReducers } from '@reduxjs/toolkit';

import appState from 'internal/reducers/appReducer';
import authState from 'auth/reducers/authReducer';
import usersState from 'users/reducers/usersReducer';

const rootReducer = combineReducers({
  appState,
  authState,
  usersState,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
