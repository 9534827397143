import styled from '@emotion/styled';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';
import { selectUserRights } from '../../auth/selectors/authSelectors';
import {
  BikeIcon,
  BillIcon,
  CashIcon,
  ChartIcon,
  DashboardIcon,
  ListIcon,
  SettingsIcon,
  UsersIcon,
} from '../../const/icons';
import { generatePath, getRoute } from '../../utils/router';
import MenuItem from './MenuItem';

type RouteExtended = ReturnType<typeof getRoute>;

function useNavLinks(
  list: { icon: JSX.Element; route: Partial<RouteExtended>; child?: RouteExtended[] }[]
  //eslint-disable-next-line
): { icon: any; subCategories: []; to: string; title: string; pathForMatch: string }[] {
  const { t } = useTranslation('menu');
  const userRights = useSelector(selectUserRights);

  const transformRouteToNavLink = useCallback(
    (route: Partial<RouteExtended>) => {
      return {
        title: t(route.metadata?.name),
        to: route.path,
        pathForMatch: route.pathForMatch,
        end: route.end,
      };
    },
    [t]
  );

  return useMemo(
    () =>
      list.reduce((prev, currentItem) => {
        if (userRights?.global.includes(currentItem.route.metadata?.authorization || '')) {
          if (!('child' in currentItem)) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            prev.push({ icon: currentItem.icon, ...transformRouteToNavLink(currentItem.route!) });
          } else {
            prev.push({
              icon: currentItem.icon,
              title: t(currentItem.route.metadata?.name),
              subCategories: currentItem.child?.reduce((prev, childItem) => {
                if (
                  userRights?.rights.includes(childItem.metadata?.authorization || '') ||
                  !childItem.metadata?.authorization
                )
                  prev.push(transformRouteToNavLink(childItem));
                return prev;
                // eslint-disable-next-line
              }, [] as any[]),
            });
          }
        }
        return prev;
        // eslint-disable-next-line
      }, [] as any[]),
    [list, userRights, transformRouteToNavLink, t]
  );
}

function Menu() {
  const location = useLocation();
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const categories = useNavLinks(
    useMemo(
      () => [
        {
          route: getRoute('home'),
          icon: <DashboardIcon width={14} />,
        },
        {
          // route: userRoutes,
          route: getRoute('home.user'),
          icon: <UsersIcon width={14} />,
          child: [getRoute('home.user.create'), getRoute('home.user.list')],
        },
        {
          route: getRoute('home.park'),
          icon: <BikeIcon width={14} />,
          child: [
            getRoute('home.park.bikeList'),
            getRoute('home.park.parkList', { end: true }),
            getRoute('home.park.freeParkList'),
            getRoute('home.park.accessoryList'),
          ],
        },
        {
          route: getRoute('home.funds'),
          icon: <CashIcon width={14} />,
          child: [getRoute('home.funds.daily'), getRoute('home.funds.moves')],
        },
        {
          route: getRoute('home.invoicing'),
          icon: <BillIcon width={14} />,
          child: [
            getRoute('home.invoicing.contracts'),
            getRoute('home.invoicing.invoices'),
            getRoute('home.invoicing.bookings'),
          ],
        },
        {
          route: getRoute('home.parameters'),
          icon: <ListIcon width={14} />,
          child: [
            getRoute('home.parameters.collectivity'),
            getRoute('home.parameters.renewal'),
            getRoute('home.parameters.priceList', { useCopy: true }),
            getRoute('home.parameters.articles', { useCopy: true }),
            getRoute('home.parameters.deposits'),
            getRoute('home.parameters.securityDeposit'),
            getRoute('home.parameters.checkinCheckout'),
            getRoute('home.parameters.broadcast'),
            getRoute('home.parameters.degradations'),
            getRoute('home.parameters.delays'),
            getRoute('home.parameters.vouchers', {
              pathForMatch: generatePath('home.parameters.vouchers.list', { raw: true }),
            }),
          ],
        },
        {
          route: getRoute('home.admin'),
          icon: <SettingsIcon width={14} />,
          child: [
            getRoute('home.admin.collectivity'),
            getRoute('home.admin.waitingList'),
            // getRoute('home.admin.paymentProviders'),
            getRoute('home.admin.contracts'),
            getRoute('home.admin.theme'),
            getRoute('home.admin.permission'),
            getRoute('home.admin.locales'),
            getRoute('home.admin.forms'),
            getRoute('home.admin.pages'),
            getRoute('home.admin.workshop'),
            getRoute('home.admin.contact'),
            getRoute('home.admin.modules'),
            getRoute('home.admin.configCaution'),
            getRoute('home.admin.configBooking'),
            getRoute('home.admin.insurance', {
              pathForMatch: generatePath('home.admin.insurance', { raw: true }),
            }),
          ],
        },
        {
          icon: <ChartIcon width={14} />,
          route: getRoute('home.stats', { useCopy: true }),
        },
      ],
      []
    )
  );

  const handleChange = useCallback(
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    },
    []
  );

  useEffect(() => {
    const matchedCategory = categories.find((category) =>
      category.subCategories
        ? category.subCategories.find((subCategory: { pathForMatch: string }) =>
            matchPath(subCategory.pathForMatch, location.pathname)
          )
        : matchPath((category as { pathForMatch: string }).pathForMatch, location.pathname)
    );

    if (matchedCategory) setExpanded(matchedCategory.title);
  }, [categories, location.pathname]);

  return (
    <MenuRoot>
      {categories.map((category, index) => (
        <MenuItem
          key={category.title + index}
          onChange={handleChange(category.title)}
          expanded={expanded === category.title}
          category={category}
        />
      ))}
    </MenuRoot>
  );
}

const MenuRoot = styled.nav`
  background-color: #414b5a;
`;

export default Menu;
