import React, { useMemo } from 'react';
import MainSection from 'components/Core/MainSection/MainSection';
import { useOutletContext } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { withTheme } from '../../Parameters/Deposit/List/DepositList';
import LogoSection from './Section/LogoSection';
import BannerSection from './Section/BannerSection';

function VisualPage() {
  //eslint-disable-next-line
  const themeConfig: AxiosResponse<any> | undefined = useOutletContext();

  const logoDefaultValues = useMemo(() => {
    return Object.entries(themeConfig?.data?.logos || {}).reduce((prev, [key, value]) => {
      //eslint-disable-next-line
      return { ...prev, [key]: (value as any).mediaUrl };
    }, {});
  }, [themeConfig?.data?.logos]);

  return (
    <MainSection>
      <LogoSection defaultValues={logoDefaultValues} />
      <BannerSection
        defaultValues={useMemo(
          () => ({
            background: themeConfig?.data?.logos?.headerBackground.mediaUrl,
            opacity: themeConfig?.data?.banner['banner-opacity'],
          }),
          [themeConfig?.data?.logos, themeConfig?.data?.banner]
        )}
      />
    </MainSection>
  );
}

export default withTheme(VisualPage);
