/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { css } from '@emotion/react';
import { TFunction } from 'i18next';
import { withTheme } from '../Deposit/List/DepositList';
import { selectCollAndDeliveryPointID } from '../../../auth/selectors/authSelectors';
import { RawDelayType } from '../../../parameters/types/parameters';
import Card from '../../../components/Core/Card/Card';
import Datagrid, { Row, RowItem } from '../../../components/Core/Datagrid/Datagrid';
import { GridColDefinition } from '../../../components/Core/Datagrid/gridCols';
import { GridDataState } from '../../../utils/request';
import InputBase from '../../../components/Core/InputBase/InputBase';
import { getAmountExcludingTaxes, getAmountIncludingTaxes } from '../Deposit/List/utils';
import { RowProps } from '../Caution/CautionList';
import Button from '../../../components/Core/Button/Button';
import amountForm from '../../../const/amountForm';
import useBuildForm, { FormObject } from '../../../hook/useBuildForm';
import FormModal from '../../../components/Core/FormModal/FormModalProps';
import { useSnackbar } from '../../../context/SnackbarContext';
import { TOAST_SEVERITY } from '../../../components/Core/Toast/Toast';
import DelayListActionCell from './DelayListActionCell';
import useDelayListApi from './useDelayListApi';

const NAMESPACE = 'parameters/delayList';

function useGetDelayListColumnDefinitions() {
  const { t } = useTranslation(NAMESPACE);

  return useMemo<GridColDefinition[]>(() => {
    return [
      {
        type: 'formField',
        field: 'nom_retard',
        headerName: t('columns.nom_retard'),
        // TODO: add name param in renderCell function (for Controller name and defaultValue)
        renderCell: ({ row, control }) => {
          return (
            <Controller
              control={control}
              name={'nom_retard'}
              key={`input-name-${row.id_retard}`}
              defaultValue={row.nom_retard}
              render={({ field: { onChange, value, name } }) => (
                <InputBase name={name} onChange={onChange} value={value} />
              )}
            />
          );
        },
      },
      {
        type: 'formField',
        field: 'montant_ht',
        headerName: t('columns.montant_ht'),
        renderCell: ({ row, control, setValue }) => {
          const handleChange = useCallback(
            (
              onChange: (newValue: string | number | readonly string[] | undefined) => void,
              newValue: string | number | readonly string[] | undefined
            ) => {
              const amountExcludingTaxes = Number(newValue);
              setValue('montant_ttc', getAmountIncludingTaxes(amountExcludingTaxes, Number(row.taux_tva)));
              onChange(newValue);
            },
            [row]
          );

          return (
            <Controller
              control={control}
              name={'montant_ht'}
              defaultValue={Number(row.montant_ht).toFixed(4)}
              key={`input-price-${row.depotId}`}
              render={({ field: { onChange, value, name } }) => (
                <InputBase onChange={(newValue) => handleChange(onChange, newValue)} value={value} name={name} />
              )}
            />
          );
        },
      },
      {
        type: 'string',
        field: 'taux_tva',
        headerName: t('columns.taux_tva'),
        width: 120,
      },
      {
        headerName: t('columns.montant'),
        field: 'montant',
        type: 'formField',
        renderCell: ({ row, control, setValue }) => {
          const handleChange = useCallback(
            (
              onChange: (newValue: string | number | readonly string[] | undefined) => void,
              newValue: string | number | readonly string[] | undefined
            ) => {
              const amountIncludingTaxes = Number(newValue);
              setValue('montant_ht', getAmountExcludingTaxes(amountIncludingTaxes, Number(row.taux_tva)));
              onChange(newValue);
            },
            [row]
          );

          return (
            <Controller
              control={control}
              name={'montant'}
              key={`montant-${row.id_retard}`}
              defaultValue={Number(row.montant).toFixed(4)}
              render={({ field: { onChange, value, name } }) => (
                <InputBase name={name} onChange={(newValue) => handleChange(onChange, newValue)} value={value} />
              )}
            />
          );
        },
      },
      {
        type: 'formField',
        field: '',
        headerName: '',
        renderCell: (props) => <DelayListActionCell {...props} />,
        width: '1fr',
      },
    ];
  }, [t]);
}

function DelayListRow({ columns, row, index, colsTemplate }: RowProps) {
  const useFormReturn = useForm({ mode: 'onChange' });

  const { isDirty, dirtyFields } = useFormReturn.formState;

  const buildCell = useCallback(
    (column: GridColDefinition, index: number) => {
      if (column.type === 'formField') {
        return (
          <div
            key={'value-' + column.field + index}
            css={css`
              display: grid;
              align-items: center;
            `}>
            {column.renderCell({ row, ...useFormReturn, columnDef: column })}
          </div>
        );
      }

      return (
        <RowItem key={'value-' + column.field + index}>
          <span>{row[column.field]}</span>
        </RowItem>
      );
    },
    [row, isDirty, dirtyFields]
  );

  return (
    <Row key={'line'.concat(index.toString())} colsTemplate={colsTemplate}>
      {columns.map((column, index) => buildCell(column, index))}
    </Row>
  );
}

function getCreateDelayForm(t: TFunction): FormObject {
  return [
    {
      fields: [
        { type: 'text', name: 'nom_retard', label: t('nom_retard'), placeholder: t('nom_retard') },
        ...amountForm(t),
      ],
    },
  ];
}

function DelayList() {
  const { t } = useTranslation([NAMESPACE, 'form']);
  const snackbar = useSnackbar();
  const columnDefinitions = useGetDelayListColumnDefinitions();
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const { fetchDelayList, postDelay } = useDelayListApi();
  const [delayList, setDelayList] = useState<RawDelayType[]>([]);
  const [loading, setLoading] = useState(false);
  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
  const [dataToForwardForGridData, setDataToForwardForGridData] = useState<GridDataState>({
    totalLines: 0,
    itemPerPage: 10,
    nbrPages: undefined,
    currentPage: 1,
  });
  const { builtFormFields, handleSubmit, reset } = useBuildForm({
    colsNumber: 2,
    formObject: getCreateDelayForm(t),
  });

  const onSubmitCreateDelay = useCallback(
    handleSubmit(async ({ montant_ttc, ...data }) => {
      const response = await postDelay({ id_coll, ...data });

      if (response && response.status === 200) {
        reset();
        snackbar?.setAlert({ message: 'success', severity: TOAST_SEVERITY.success });
        setCreateModalIsOpen(false);
        setLoading(true);
        onMount(dataToForwardForGridData);
      }
    }),
    [id_coll, dataToForwardForGridData.currentPage, dataToForwardForGridData.itemPerPage]
  );

  const onMount = useMemo(
    () =>
      _.debounce(async (queryParams) => {
        const response = await fetchDelayList({ id_coll, ...queryParams });

        if (response && response.status === 200) {
          setDelayList(response.data.result);
          setDataToForwardForGridData((prevState) => ({
            ...prevState,
            totalLines: response.data.total,
            itemPerPage: response.data.itemPerPage,
            nbrPages: response.data.totalPage,
            currentPage: response.data.currentPage,
            order: response.data.order,
          }));

          setLoading(false);
        }
      }, 1000),
    [id_coll]
  );

  useEffect(() => {
    setLoading(true);
    onMount(dataToForwardForGridData);
  }, [onMount, dataToForwardForGridData.itemPerPage, dataToForwardForGridData.currentPage]);

  return (
    <MainSection className={'reset'}>
      <div
        css={css`
          display: flex;
          //padding-top: 16px;
          //padding-right: 16px;
          justify-content: flex-end;
        `}>
        <Button label={t('title_delay')} onClick={() => setCreateModalIsOpen(true)} />
        {createModalIsOpen && (
          <FormModal
            validButtonLabel={t('form:add')}
            onSubmit={onSubmitCreateDelay}
            title={t('title_delay')}
            setIsOpen={setCreateModalIsOpen}>
            {builtFormFields}
          </FormModal>
        )}
      </div>
      <Card>
        <Datagrid
          RowComponent={DelayListRow}
          handleChangePage={(newPage) =>
            setDataToForwardForGridData((prevState) => ({ ...prevState, currentPage: newPage }))
          }
          onChangeItemPerPage={(newValue) =>
            setDataToForwardForGridData((prevState) => ({ ...prevState, itemPerPage: newValue as number }))
          }
          loading={loading}
          rows={delayList}
          columns={columnDefinitions}
          {...(({ filters, ...propsToForward }) => propsToForward)(dataToForwardForGridData)}
        />
      </Card>
    </MainSection>
  );
}

const MainSection = styled.section`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export default withTheme(DelayList);
