import { selectCollAndDeliveryPointID } from 'auth/selectors/authSelectors';
import Button from 'fragments/button/Button';
import { setSnackbar, showSnackbar } from 'internal/actions/appActions';
import SnackbarSeverity from 'internal/constants/snackbar';
import useApi, { Methods } from 'internal/hooks/useApi';
import { t } from 'internal/i18n';
import { SOGECOMMERCE_ENABLED_ENDPOINT } from 'parameters/constants/endpoints';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { REFUND_DEPOSIT_USER } from 'reservations/constants/endpoints';
import { ReservationType } from 'reservations/types/reservation';
import ReservationDepositDialog from './ReservationDepositDialog';

type Props = {
  isPark: boolean;
  reservation: ReservationType | null;
  refresh: () => void;
  resaID: string;
};

const ReservationDepositRefund = ({ isPark, reservation, resaID, refresh }: Props) => {
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const [isDepositSogec, setIsDepositSogec] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const { request: requestActivation, response: responseActivation } = useApi<{
    caution: boolean;
  }>(Methods.GET, SOGECOMMERCE_ENABLED_ENDPOINT);

  const { request: refundSubmit, response: refundResponse } = useApi<ReservationType>(
    Methods.POST,
    REFUND_DEPOSIT_USER
  );

  useEffect(() => {
    if (id_coll) {
      requestActivation({
        params: {
          id_coll,
        },
      });
    }
  }, [id_coll, requestActivation]);

  const refund = useCallback(() => {
    refundSubmit({
      id_coll,
      id_resa: parseInt(resaID),
      type: 'caution',
    });
  }, [id_coll, resaID]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const releaseDeposit = useCallback(() => {
    refund();
    setOpen(false);
  }, [refund]);

  useEffect(() => {
    if (id_coll) {
      requestActivation({
        params: {
          id_coll,
        },
      });
    }
  }, [id_coll, requestActivation]);

  useEffect(() => {
    if (responseActivation?.success && responseActivation?.data) {
      setIsDepositSogec(responseActivation.data.caution);
    }
  }, [responseActivation]);

  useEffect(() => {
    if (refundResponse?.success) {
      dispatch(setSnackbar(t('reservations.refund_deposit_success'), SnackbarSeverity.SUCCESS));
      dispatch(showSnackbar());

      refresh();
    }
  }, [refundResponse]);

  const renderRefundDeposit = useMemo(() => {
    if (isPark || !reservation?.sepa_caution_transac_id || !!reservation.date_rbt_caution || !isDepositSogec) {
      return null;
    }

    return (
      <div className={'row'} id={'deposit_refund'}>
        <div className={'col-md-12'}>
          <Button label={t('reservations.refund_deposit')} className={'btn-primary btn-fit'} onClick={handleOpen} />
        </div>
      </div>
    );
  }, [isPark, reservation, isDepositSogec]);

  return (
    <>
      {renderRefundDeposit}

      <ReservationDepositDialog open={open} handleClose={handleClose} releaseDeposit={releaseDeposit} />
    </>
  );
};

export default ReservationDepositRefund;
