import React from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import ButtonWithIcon from '../Core/Button/ButtonWithIcon';
import Dialog, { DialogActions, DialogContent, DialogTitle } from '../Dialog/Dialog';

type Props = {
  open?: boolean;
  title?: string;
  content?: string;
  yesButton?: React.JSX.Element;
  confirmLabel?: string;
  onConfirm: () => void;
  onCancel: () => void;
};

function ConfirmDialog(props: Props) {
  const { yesButton, open, title, content, onCancel, onConfirm } = props;

  const { t } = useTranslation(['common']);
  return createPortal(
    <>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <Dialog style={{ width: 500 }} open={open} onCancel={onCancel}>
        <form method={'dialog'} onSubmit={onConfirm}>
          {title && <DialogTitle>{title}</DialogTitle>}
          {content && <DialogContent>{content}</DialogContent>}
          <DialogActions>
            <ButtonWithIcon onClick={onCancel} value={'cancel'} color={'secondary'} icon={'CrossWithoutCircle'}>
              {t('confirmDialog.no')}
            </ButtonWithIcon>
            {yesButton ? (
              yesButton
            ) : (
              <ButtonWithIcon icon={'CheckWithoutCircle'} type={'submit'}>
                {t('confirmDialog.yes')}
              </ButtonWithIcon>
            )}
          </DialogActions>
        </form>
      </Dialog>
    </>,
    document.body
  );
}

export default ConfirmDialog;
