/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { TFunction } from 'i18next';
import _ from 'lodash';
import React, { HtmlHTMLAttributes, useEffect, useMemo, useState } from 'react';
import { useTranslation, UseTranslationResponse } from 'react-i18next';
import { LessIcon, MoreIcon } from '../../../const/icons';
import useBuildForm, { FormObject } from '../../../hook/useBuildForm';
import { getColor } from '../../../utils/style';
import Card from '../Card/Card';
import ListLoader from '../ListLoader/ListLoader';

type ValueObject = { label: string; value: string | number };

export type FiltersRaw = {
  [filterName: string]: {
    selected: string | null;
    values: ValueObject[];
    order: number;
    zone: number | null;
  } | null;
};

type FiltersProps = {
  // formObject: FormObject;
  translationNamespace: string | string[];
  filtersRaw: FiltersRaw;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (filtersObject: { [filterName: string]: any }) => void;
};

function buildFormObject(
  filtersRaw: FiltersRaw,
  t: TFunction,
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  i18n: UseTranslationResponse<any>['i18n'],
  translationNamespace: string | string[]
) {
  const formObject: FormObject = [
    {
      fields: [],
    },
    {
      fields: [],
    },
  ];

  Object.entries(filtersRaw).forEach((filter) => {
    if (filter) {
      const [filterName, filterData] = filter;

      if (filterData && typeof filterData === 'object' && 'values' in filterData && filterData.zone) {
        //eslint-disable-next-line
        const options: { label: string; value: any }[] = filterData.values.map(({ label, value }) => ({
          label: i18n.exists('options.'.concat(label), { ns: translationNamespace })
            ? t('options.'.concat(label))
            : label,
          value: value,
        }));

        if (!['filterPdl', 'filterEmpl', 'articles'].includes(filterName)) {
          options.unshift({ label: t('common:filters.all'), value: null });
        }

        formObject[filterData.zone - 1].fields.push({
          name: filterName,
          label: t('filters.'.concat(filterName)),
          type: 'select',
          defaultValue: filterData.selected ?? '',
          id: 'filter-'.concat(filterData.order.toString()),
          options,
        });
      } else if (filterData && filterData.zone) {
        formObject[filterData.zone - 1].fields.push({
          type: 'daterange',
          name: filterName,
          label: t('filters.'.concat(filterName)),
          placeholder: t('filters.'.concat(filterName)),
          id: 'filter-'.concat(filterData.order.toString()),
        });
      }
    }
  });

  formObject.forEach((groupObject) => {
    groupObject.fields.sort((a, b) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return Number(a.id!.charAt(a.id!.length - 1)) - Number(b.id!.charAt(a.id!.length - 1));
    });
  });

  return formObject;
}

function ToggleMoreButton(props: { showMoreToggle: boolean } & HtmlHTMLAttributes<HTMLButtonElement>) {
  const { t } = useTranslation(['common']);
  const { onClick, showMoreToggle } = props;

  const showMoreContent = useMemo(() => {
    return (
      <>
        {showMoreToggle ? <LessIcon width={16} /> : <MoreIcon width={16} />}
        <span
          css={css`
            margin-top: 4px;
            font-weight: bold;
            font-size: 12px;
          `}>
          {t(showMoreToggle ? 'filters.less' : 'filters.more')}
        </span>
      </>
    );
  }, [showMoreToggle, t]);

  return <ShowMoreButton onClick={onClick}>{showMoreContent}</ShowMoreButton>;
}

function Filters(props: FiltersProps) {
  const { filtersRaw, translationNamespace, onChange } = props;
  const { t, i18n } = useTranslation(
    translationNamespace
      ? typeof translationNamespace === 'string'
        ? [translationNamespace, 'common']
        : [...translationNamespace, 'common']
      : 'common'
  );
  const formObject = buildFormObject(filtersRaw, t, i18n, translationNamespace);
  const { builtFormFields, watch, setValue, getValues } = useBuildForm({
    formObject,
    colsNumber: 4,
    withValidation: false,
  });
  const [showMoreToggle, setShowMoreToggle] = useState(false);

  const hasMoreFilters = useMemo(() => {
    return React.Children.toArray(builtFormFields[1].props.children).length > 0;
  }, [builtFormFields]);

  useEffect(() => {
    const currentFilterValues = getValues();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const remoteFilterValues = Object.keys(filtersRaw).reduce<Record<string, any>>(
      (previousValue, currentValue) => ({ ...previousValue, [currentValue]: filtersRaw[currentValue]?.selected }),
      {}
    );
    const isEqual = _.isEqual(currentFilterValues, remoteFilterValues);

    if (!isEqual)
      Object.keys(remoteFilterValues).forEach((filterName) =>
        setValue(
          filterName,
          typeof remoteFilterValues[filterName] === 'object' && remoteFilterValues[filterName] !== null
            ? remoteFilterValues[filterName].value
            : remoteFilterValues[filterName]
        )
      );
  }, [filtersRaw]);

  useEffect(() => {
    const subscription = watch(
      _.debounce((data) => {
        onChange?.(data);
      }, 400)
    );

    return () => subscription.unsubscribe();
  }, [filtersRaw]);

  return Object.keys(filtersRaw).length > 0 ? (
    <FiltersCard
      content={
        <>
          {/* TODO: change for isLoading prop */}
          {Object.keys(filtersRaw).length === 0 ? (
            <div
              css={css`
                display: flex;
                justify-content: center;
              `}>
              <ListLoader color={'primary'} />
            </div>
          ) : (
            <>
              <div
                css={css`
                  display: grid;
                  grid-template-columns: 1fr 120px;
                `}>
                {builtFormFields[0]}
                {hasMoreFilters && (
                  <ToggleMoreButton
                    showMoreToggle={showMoreToggle}
                    onClick={() => setShowMoreToggle(!showMoreToggle)}
                  />
                )}
              </div>
              {showMoreToggle && hasMoreFilters && (
                <>
                  <hr />
                  {builtFormFields[1]}
                </>
              )}
            </>
          )}
        </>
      }
    />
  ) : null;
}

const FiltersCard = styled(Card)`
  background-color: ${(props) => props.theme.background.filtersCard};
`;

const ShowMoreButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${getColor('primary.200')};
  background: none;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

export default Filters;
