import { SelectOptionType } from 'internal/types/select';
import { BikeTypologyType } from 'users/types/forms';

export const bikeTypeToSelectOptions = (bikeTypology: BikeTypologyType[]): SelectOptionType[] =>
  bikeTypology.map(({ id_type, nom_type }) => ({
    value: id_type.toString() || '',
    label: nom_type || '',
  }));

export const rentalDurationToSelectOptions = (rentalDuration: Record<string, string>[]): SelectOptionType[] =>
  rentalDuration.map(({ nom_duree }) => ({
    value: nom_duree || '',
    label: nom_duree || '',
  }));

export const parkLocationToSelectOptions = (parkLocation: Record<string, string>[]): SelectOptionType[] =>
  parkLocation.map(({ id_empl: value, nom: label }) => ({
    value,
    label,
  }));
