import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import MainSection from '../../../components/Core/MainSection/MainSection';
import Card from '../../../components/Core/Card/Card';
import useGenerateFields from '../../../hook/useGenerateFields';
import { useMainTitle } from '../../../components/Layout/MainTitleContext';
import Button from '../../../components/Core/Button/Button';
import { instance } from '../../../api/user.api';
import { selectCollAndDeliveryPointID } from '../../../auth/selectors/authSelectors';
import { useSnackbar } from '../../../context/SnackbarContext';
import { TOAST_SEVERITY } from '../../../components/Core/Toast/Toast';

function PartnerInsurancePage() {
  const snackbar = useSnackbar();
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const { t } = useTranslation(['form']);
  const queryClient = useQueryClient();
  useMainTitle('Assurance partenaire');
  const { data: partnerInsuranceInfosResponse } = useQuery(['insurance/partner', { id_coll }], ({ queryKey }) => {
    const [, queryParams] = queryKey;
    return instance.get('insurance/partner', { params: queryParams });
  });

  const { control, handleSubmit } = useForm({ defaultValues: partnerInsuranceInfosResponse?.data });
  const fields = useGenerateFields({
    fields: [{ type: 'text', label: 'URL de l’assurance partenaire', name: 'url_assurance' }],
    control,
  });

  //eslint-disable-next-line
  const { mutate: postPartnerInsurance } = useMutation(
    ['insurance/partner'],
    //eslint-disable-next-line
    (bodyForm: any) => {
      return instance.post('insurance/partner', bodyForm);
    },
    {
      onSuccess: () => {
        snackbar?.setAlert({ message: t('form:global_update_success_message'), severity: TOAST_SEVERITY.success });
        queryClient.invalidateQueries(['insurance/partner']);
      },
    }
  );

  const onSubmit = useCallback(
    handleSubmit((data) => {
      postPartnerInsurance({ ...data, id_coll });
    }),
    [handleSubmit]
  );

  return (
    <MainSection className={'reset'}>
      <Card
        title={'Paramétrer le module d’assurance'}
        content={
          <>
            <form onSubmit={onSubmit}>
              {fields}
              <div className={'row justify-end mt-4'}>
                <Button type={'submit'}>Enregistrer</Button>
              </div>
            </form>
          </>
        }
      />
    </MainSection>
  );
}

export default PartnerInsurancePage;
