import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { selectCollAndDeliveryPointID } from '../../auth/selectors/authSelectors';
import Spinner from '../../fragments/spinner/Spinner';
import { USERS } from '../../internal/constants/routes';
import useApi, { Methods } from '../../internal/hooks/useApi';
import { RESA_PROPERTIES_ENDPOINT } from '../../parameters/constants/endpoints';
import ReservationCommentary from '../../reservations/components/reservation/ReservationCommentary';
import ReservationStatus from '../../reservations/components/ReservationStatus';
import ReservationSummary from '../../reservations/components/ReservationSummary';
import {
  CANCEL_RESA_ENDPOINT,
  CONFIRM_RESA_ENDPOINT,
  FINISH_RESA_ENDPOINT,
  GET_USER_RESERVATION_ENDPOINT,
} from '../../reservations/constants/endpoints';
import { ReservationType } from '../../reservations/types/reservation';

function OldBookingInfosPage() {
  const location = useLocation();
  const { t } = useTranslation('old');

  const [id_resa, setResaID] = useState('');
  const [id_user, setUserID] = useState('');
  const [reservation, setReservation] = useState<ReservationType | null>(null);
  const [finishState, setFinishState] = useState('');

  const { id_coll, id_pdl } = useSelector(selectCollAndDeliveryPointID);

  const { request: postConfirmResa, status: submitStatus } = useApi(Methods.POST, CONFIRM_RESA_ENDPOINT);
  const { request: postCancelResa } = useApi(Methods.POST, CANCEL_RESA_ENDPOINT);
  const { request: finishResa } = useApi(Methods.POST, FINISH_RESA_ENDPOINT);
  const { request: fetchBooking, response: bookingRes } = useApi<{
    bookingEnabled: boolean;
    demande_signature: boolean;
  }>(Methods.GET, RESA_PROPERTIES_ENDPOINT);

  useEffect(() => {
    fetchBooking({
      params: {
        id_coll,
      },
    });
  }, [fetchBooking, id_coll, submitStatus]);

  const submit = useCallback(() => {
    postConfirmResa({
      id_coll,
      id_pdl,
      id_resa,
    });
  }, [postConfirmResa, id_coll, id_pdl, id_resa]);

  const cancel = useCallback(() => {
    postCancelResa({
      id_coll,
      id_pdl,
      id_resa,
    });
  }, [id_coll, id_pdl, id_resa]);

  const finish = useCallback(() => {
    finishResa({
      id_coll,
      id_pdl,
      id_resa,
      t_etat: finishState || 'pdl',
    });
  }, [id_coll, id_pdl, id_resa, finishState]);

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      const resaID = params.get('resaID') || '';
      const userID = params.get('userID') || '';

      setResaID(resaID);
      setUserID(userID);
    }
  }, [location.search]);

  const { request: fetchResaReq, response: reservationResponse } = useApi<ReservationType>(
    Methods.GET,
    GET_USER_RESERVATION_ENDPOINT
  );

  const fetchReservation = useCallback(() => {
    if (id_resa && id_user) {
      fetchResaReq({
        params: {
          id_coll,
          id_user,
          id_resa,
        },
      });
    }
  }, [fetchResaReq, id_coll, id_user, id_resa, submitStatus]);

  useEffect(() => {
    fetchReservation();
  }, [fetchReservation]);

  useEffect(() => {
    if (reservationResponse?.success && reservationResponse.data) {
      setReservation(reservationResponse.data);
    }
  }, [reservationResponse]);

  const contract = useMemo(() => {
    if (reservation?.date_valid && reservation.pdl_valid && reservation.contrat_link) {
      return {
        label: t('reservations.validation_contract', {
          dateValidation: dayjs(reservation.date_valid).format('DD/MM/YYYY'),
          hourValidation: dayjs(reservation.date_valid).format('HH:mm'),
          pdl: reservation.pdl_valid,
        }),
        url: reservation.contrat_link,
      };
    }
    return null;
  }, [reservation?.date_valid, reservation?.pdl_valid, reservation?.contrat_link, submitStatus, t]);

  const isPark = useMemo(() => !(reservation?.article === 'velo'), [reservation?.article]);

  const renderContent = useMemo(() => {
    if (!reservation) {
      return <Spinner />;
    }

    const { nom_user: lastNameClient, prenom_user: firstNameClient } = reservation;

    return (
      <>
        <div className={'card-header'}>
          <h4 className={'card-title'}>
            <Link to={`${USERS}/${id_user}`}>
              <Trans
                t={t}
                i18nKey={'reservations.client_name'}
                values={{ firstName: firstNameClient, lastName: lastNameClient }}
                components={{ st: <strong /> }}
              />
            </Link>
          </h4>
        </div>
        <div className={'card-body'}>
          <div className={'row'}>
            <ReservationSummary
              resaID={id_resa}
              reservation={reservation}
              isFree={!bookingRes?.data.bookingEnabled}
              isRenewal={!!reservation?.id_parent_resa}
              isPark={isPark}
              refresh={fetchReservation}
            />
            {((reservation.is_canceled !== 1 && reservation.is_returned !== 1) ||
              (!!reservation.montant_caution_ttc && reservation.sepa_transac_id)) && (
              <ReservationStatus
                isPark={reservation.article === 'consigne'}
                userID={id_user}
                resaID={id_resa}
                reservation={reservation}
                submit={submit}
                submitStatus={submitStatus}
                cancel={cancel}
                finish={[setFinishState, finish]}
                contract={contract}
                refresh={fetchReservation}
                askSignature={(bookingRes?.data?.demande_signature && !reservation?.id_parent_resa) ?? false}
              />
            )}
            <hr className={'seperator'} />
            <ReservationCommentary resaID={id_resa} commentary={reservation.commentaire} />
          </div>
        </div>
      </>
    );
  }, [
    reservation,
    id_user,
    id_resa,
    contract,
    submitStatus,
    submit,
    cancel,
    setFinishState,
    finish,
    fetchReservation,
    bookingRes,
    isPark,
  ]);

  return (
    <div className={'main-content'}>
      <div className={'card'}>{renderContent}</div>
    </div>
  );
}

export default OldBookingInfosPage;
