import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { t } from 'internal/i18n';
import { textEditorToolbarConfig } from 'internal/constants/textEditor';
import useApi, { Methods } from 'internal/hooks/useApi';
import { CGL_GENERATE_PDF, CGL_LAST_PDF } from 'parameters/constants/endpoints';
import { useDispatch, useSelector } from 'react-redux';
import { selectCollAndDeliveryPointID } from 'auth/selectors/authSelectors';
import { CglType } from 'parameters/types/parameters';
import { setSnackbar, showSnackbar } from 'internal/actions/appActions';
import SnackbarSeverity from 'internal/constants/snackbar';
import { MIN_WIDTH_ARTICLE_IMAGE_TYPE } from 'parameters/constants/image';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

type Props = {
  type: string;
  editorState: EditorState;
  setEditorState: (state: EditorState) => void;
  onSubmit: (markup: string, type: string) => void;
  withImages?: boolean;
};

enum EDITOR_TYPES {
  CG_VELO = 'cg_velo',
  CG_CONS = 'cg_cons',
  CG_CONS_PARK = 'cg_cons_park',
  CG_PRIVACY = 'cg_privacy',
  CG_LEGAL = 'cg_legal',
}

enum CGL_TYPES {
  BIKE = 'velo',
  PARK = 'cons',
  FREEPARK = 'cons_park',
  PRIVACY = 'privacy',
  LEGAL = 'legal',
}

const TextEditor = ({
  //prettier-ignore
  type,
  editorState,
  setEditorState,
  onSubmit,
  withImages,
}: Props): ReactElement => {
  const [mediaUrl, setMediaUrl] = useState<string | null>(null);
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const { request: fetchLastCGL, response: lastCGLResponse } = useApi<CglType>(Methods.GET, CGL_LAST_PDF);
  const { request: generateCGL, response: CGLResponse } = useApi<CglType>(Methods.POST, CGL_GENERATE_PDF);
  const dispatch = useDispatch();

  const article = useMemo(() => {
    switch (type) {
      case EDITOR_TYPES.CG_VELO:
        return CGL_TYPES.BIKE;
      case EDITOR_TYPES.CG_CONS:
        return CGL_TYPES.PARK;
      case EDITOR_TYPES.CG_CONS_PARK:
        return CGL_TYPES.FREEPARK;
      case EDITOR_TYPES.CG_PRIVACY:
        return CGL_TYPES.PRIVACY;
      case EDITOR_TYPES.CG_LEGAL:
        return CGL_TYPES.LEGAL;
      default:
        return null;
    }
  }, [type]);

  const onEditorStateChange = useCallback(
    (state: EditorState) => {
      setEditorState(state);
    },
    [setEditorState]
  );

  const _onSubmit = useCallback(() => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const markup = draftToHtml(rawContentState);

    onSubmit(markup, type);
  }, [editorState]);

  const _onGeneratePdf = useCallback(() => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const markup = draftToHtml(rawContentState);

    generateCGL({
      id_coll,
      cgtxt: markup,
      cgt: article,
    });
  }, [editorState]);

  const linkToLastPdf = useMemo(() => {
    if (mediaUrl) {
      return mediaUrl;
    }

    return null;
  }, [mediaUrl]);

  const needSavePdf = useMemo(() => {
    return [
      EDITOR_TYPES.CG_VELO,
      EDITOR_TYPES.CG_CONS,
      EDITOR_TYPES.CG_CONS_PARK,
      EDITOR_TYPES.CG_PRIVACY,
      EDITOR_TYPES.CG_LEGAL,
    ].includes(type as EDITOR_TYPES);
  }, [type]);

  useEffect(() => {
    if (needSavePdf && article) {
      fetchLastCGL({
        params: {
          id_coll,
          type_article: article,
        },
      });
    }
  }, [needSavePdf, article]);

  useEffect(() => {
    if (lastCGLResponse?.success && lastCGLResponse?.data) {
      setMediaUrl(lastCGLResponse.data.media_url);
    }
  }, [lastCGLResponse]);

  useEffect(() => {
    if (CGLResponse?.success && CGLResponse?.data) {
      setMediaUrl(CGLResponse.data.media_url);
      dispatch(setSnackbar(t('parameters.modification_success'), SnackbarSeverity.SUCCESS));
      dispatch(showSnackbar());
    }
  }, [CGLResponse]);

  const onUploadImageArticle = useCallback(
    (file: File) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.addEventListener(
          'load',
          () => {
            const img = new Image();
            img.src = URL.createObjectURL(file);
            img.onload = () => {
              if (img.width < MIN_WIDTH_ARTICLE_IMAGE_TYPE) {
                dispatch(setSnackbar(t('error.minWidthError'), SnackbarSeverity.ERROR));
                dispatch(showSnackbar());

                reject();
              }

              resolve({ data: { link: reader.result } });
            };
          },
          false
        );
      }),
    []
  );

  const config = useMemo(() => {
    let toolbarConfig = { ...textEditorToolbarConfig };

    if (withImages) {
      toolbarConfig = {
        ...textEditorToolbarConfig,
        options: [...toolbarConfig.options, 'image'],
      };

      return {
        ...toolbarConfig,
        image: {
          component: undefined,
          uploadCallback: onUploadImageArticle,
          alignmentEnabled: false,
          urlEnabled: false,
          previewImage: true,
          inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
          alt: { present: true, mandatory: false },
          defaultSize: {
            height: 'auto',
            width: 'auto',
          },
        },
      };
    }

    return toolbarConfig;
  }, [onUploadImageArticle, withImages]);

  return (
    <div className={'row'}>
      <div className={'col-md-12'}>
        <Editor
          editorState={editorState}
          toolbarClassName={'note-toolbar panel-heading'}
          wrapperClassName={'rdw-bordered'}
          editorClassName={'rdw-padded'}
          onEditorStateChange={onEditorStateChange}
          toolbar={config}
          handlePastedText={() => false}
          stripPastedStyles={true}
        />
      </div>
      <div className={'col-md-12 space-between'}>
        <button id={'savevelo'} onClick={_onSubmit} className={'btn btn-w-xl btn-primary'}>
          {t('parameters.save')}
        </button>
        {needSavePdf && (
          <>
            <button id={'generatepdf'} onClick={_onGeneratePdf} className={'btn btn-w-xl btn-primary spacing-button'}>
              {t('parameters.generate_pdf')}
            </button>
            {linkToLastPdf && (
              <a href={linkToLastPdf} target={'_blank'}>
                {t('parameters.last_pdf')}
              </a>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default TextEditor;
