import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { PutBookingConfigBody } from '../../../api/admin/bookingConfig.api';
import { getDepositConfig, putDepositConfig } from '../../../api/admin/depositConfig.api';
import { selectCollAndDeliveryPointID } from '../../../auth/selectors/authSelectors';
import FormCard from '../../../components/Core/FormCard/FormCard';
import MainSection from '../../../components/Core/MainSection/MainSection';
import { TOAST_SEVERITY } from '../../../components/Core/Toast/Toast';
import { useSnackbar } from '../../../context/SnackbarContext';
import useBuildForm from '../../../hook/useBuildForm';
import useBookingForm, { transformRawBookingConfig } from './depositConfig.form';

function DepositConfigPage() {
  const { t } = useTranslation('form');
  const snackbar = useSnackbar();
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const queryClient = useQueryClient();

  const { data: rawConfigBooking } = useQuery(
    ['get.depositConfig', id_coll],
    ({ queryKey }) => {
      const [, id_coll] = queryKey;
      return getDepositConfig(id_coll as number);
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 40000_000,
    }
  );
  const { mutate: handleUpdateDepositConfig, isLoading } = useMutation(
    ['post.depositConfig'],
    (data: Omit<PutBookingConfigBody, 'id_coll'>) => putDepositConfig({ ...data, id_coll }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['get.depositConfig', id_coll]);
        snackbar?.setAlert({ message: t('global_update_success_message'), severity: TOAST_SEVERITY.success });
      },
    }
  );
  const depositConfig = useMemo(() => {
    return transformRawBookingConfig(rawConfigBooking?.data);
  }, [rawConfigBooking]);

  const bookingConfigForm = useBookingForm(rawConfigBooking?.data.paymentsMeans, depositConfig?.options);
  const { builtFormFields, handleSubmit } = useBuildForm({
    formObject: bookingConfigForm,
    defaultValues: depositConfig?.defaultValues,
    withValidation: false,
  });

  const onSubmit = useCallback(
    handleSubmit((data) => {
      handleUpdateDepositConfig(data as Omit<PutBookingConfigBody, 'id_coll'>);
    }),
    [handleSubmit]
  );

  return (
    <MainSection className={'reset'}>
      <FormCard onSubmit={onSubmit} isLoading={isLoading}>
        {builtFormFields}
      </FormCard>
    </MainSection>
  );
}

export default DepositConfigPage;
