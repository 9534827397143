import { getListReservationsFiltersById } from 'dashbaord/constants/dashboard';
import { DashboardItemType } from 'dashbaord/types/dashboard';
import { BOOKINGS, INVOICING } from 'internal/constants/routes';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

type Props = {
  item: DashboardItemType;
};

const links: Record<string, string> = getListReservationsFiltersById();

const DashboardCard = ({ item }: Props): ReactElement => {
  const { t } = useTranslation('home');

  return (
    <div key={item.id} className={'col-md-6'}>
      {/*<Link to={`${INVOICING}${BOOKINGS}?${dashboardParamsLinks[item.id]}`}>*/}
      <Link to={`${INVOICING}${BOOKINGS}?${links[item.id]}`}>
        <div className={'card card-body'}>
          <h6 className={'text-uppercase text-gray'}>{t(item.id)}</h6>
          <div className={'flexbox mt-2'}>
            <span className={`${item.icon} fs-30`}></span>
            <span id={'rv_0'} className={'fs-30'}>
              {item.value}
            </span>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default DashboardCard;
