import { OpUnitType } from 'dayjs';
import { TFunction } from 'i18next';
import { t } from 'internal/i18n';
import ArticleTypology from 'users/constants/article';

export const computeRentalDuration = (durationTypology: string): OpUnitType => {
  switch (durationTypology) {
    case 'annee':
      return 'year';
    case 'mois':
      return 'month';
    case 'trimestre':
      return 'month';
    case 'jour':
      return 'day';
    default:
      return 'day';
  }
};

export const computeRentalDurationTrads = (durationTypology: string): string => {
  switch (durationTypology) {
    case 'annee':
      return t('duration.year');
    case 'mois':
      return t('duration.month');
    case 'trimestre':
      return t('duration.trimester');
    case 'jour':
      return t('duration.day');
    default:
      return t('duration.day');
  }
};

export const computeRentalItems = (t: TFunction, durationTypology: ArticleTypology): string => {
  switch (durationTypology) {
    case ArticleTypology.BIKE:
      return t('old:reservations.article_type.velo');
    case ArticleTypology.PARK:
      return t('old:reservations.article_type.consigne');
    case ArticleTypology.FREEPARK:
      return t('old:reservations.article_type.consignepark');
    default:
      return t('old:reservations.article_type.velo');
  }
};

export const computeDurationDayNumber = (duration: string): number => {
  if (duration === 'trimestre') {
    return 3;
  }

  if (duration === 'jour' || duration === 'annee') {
    return 1;
  }

  if (duration === 'mois') {
    return 1;
  }

  return 1;
};
