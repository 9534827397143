import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import SnackbarMui from '@mui/material/Snackbar';
import _ from 'lodash';
import Toast, { TOAST_SEVERITY } from '../components/Core/Toast/Toast';
import { withTheme } from '../pages/Parameters/Deposit/List/DepositList';

type AlertSeverity = TOAST_SEVERITY;

const AUTO_DISMISS = 5000;

type SnackbarState = {
  setAlert: (params: { message: string; severity: AlertSeverity }) => void;
};

const ToastThemed = withTheme(Toast);

const SnackbarContext = React.createContext<SnackbarState | null>(null);

function SnackbarProvider({ children }: PropsWithChildren<unknown>) {
  const [alert, setAlert] = useState<{ message: string; severity: AlertSeverity }>({
    message: '',
    severity: TOAST_SEVERITY.primary,
  });
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (alert.message !== '') {
      setOpen(true);
      _.debounce(() => {
        setOpen(false);
      }, AUTO_DISMISS - 200)();
      _.debounce(() => {
        setAlert(() => ({ message: '', severity: TOAST_SEVERITY.primary }));
      }, AUTO_DISMISS)();
    }
  }, [alert.message]);

  return (
    <SnackbarContext.Provider value={{ setAlert }}>
      <SnackbarMui
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={open}
        ClickAwayListenerProps={{
          onClickAway: () => {
            return;
          },
        }}>
        <div>
          <ToastThemed type={alert.severity}>{alert.message}</ToastThemed>
        </div>
      </SnackbarMui>
      {children}
    </SnackbarContext.Provider>
  );
}

export function useSnackbar() {
  return useContext(SnackbarContext);
}

export default SnackbarProvider;
