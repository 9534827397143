/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react';
import { css } from '@emotion/react/macro';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getBikeList, RawBike } from '../../../api/park/bike.api';
import { selectCollAndDeliveryPointID } from '../../../auth/selectors/authSelectors';
import Card from '../../../components/Core/Card/Card';
import Datagrid, { RowItem } from '../../../components/Core/Datagrid/Datagrid';
import { GridColDefinition } from '../../../components/Core/Datagrid/gridCols';
import Filters from '../../../components/Core/Filters/Filters';
import Link from '../../../components/Core/Link/Link';
import MainSection from '../../../components/Core/MainSection/MainSection';
import { InfoIcon } from '../../../const/icons';
import HistoryDialog from '../../../park/components/HistoryDialog';
import { getColor } from '../../../utils/style';
import BookingCell from './BookingCell';
import LocationCell from './LocationCell';
import StatusCell from './StatusCell';

//eslint-disable-next-line
function useColumns(openModal: (bikeId: number) => void, filters: any): GridColDefinition[] {
  const { t } = useTranslation('park/genericList');
  const theme = useTheme();

  return useMemo(() => {
    return [
      {
        field: 'id_interne',
        type: 'custom',
        headerName: t('columns.veloNumber'),
        sortable: true,
        renderCell: ({ row }) => (
          <RowItem css={css({ fontWeight: 'bold', display: 'flex', gap: 8 })} key={'interneId' + row.id_velo}>
            <span
              css={css({
                cursor: 'pointer',
                color: getColor('primary.200')({ theme }),
                ':hover': { color: getColor('primary.300')({ theme }) },
              })}
              onClick={() => {
                openModal(row.id_velo);
              }}>
              {row.id_interne}
            </span>
            {row.velocareId && (
              <Link target={'_blank'} externalLink to={row.velocareLink}>
                <InfoIcon width={12} />
              </Link>
            )}
          </RowItem>
        ),
      },
      {
        field: 'bicycode',
        type: 'custom',
        headerName: t('columns.bicycode'),
        sortable: true,
        renderCell: ({ row }) => <RowItem key={'bicycode' + row.id_velo}>{`${row.bicycode}`}</RowItem>,
      },
      {
        field: 'nom_type',
        type: 'string',
        headerName: t('columns.bikeType'),
      },
      {
        field: '',
        type: 'custom',
        headerName: t('columns.reservation'),
        renderCell: ({ row }) => <BookingCell key={'booking' + row.id_velo} bike={row as RawBike} />,
      },
      {
        field: '',
        width: 300,
        type: 'custom',
        headerName: t('columns.state'),
        renderCell: ({ row }) => <StatusCell key={'status' + row.id_velo} filters={filters} bike={row as RawBike} />,
      },
      {
        field: '',
        type: 'custom',
        headerName: t('columns.affectation'),
        renderCell: ({ row }) => (
          <LocationCell key={'location' + row.id_velo} filters={filters} bike={row as RawBike} />
        ),
      },
    ];
  }, [t, openModal, filters]);
}

export type ListParams = {
  filters?: Record<string, unknown>;
  order?: { dir: string; column: string };
  currentPage: number;
  itemPerPage: number;
};

function BikeList() {
  const { id_coll, id_pdl } = useSelector(selectCollAndDeliveryPointID);
  const [modalOpen, setModalOpen] = useState(false);
  const [bikeHistory, setBikeHistory] = useState(0);
  // const [pdlSelected, setPdlSelected] = useState(defaultPdl);
  const [listParams, setListParams] = useState<{
    filters?: Record<string, unknown>;
    order?: unknown;
    currentPage: number;
    itemPerPage: number;
  }>({
    order: undefined,
    filters: undefined,
    currentPage: 1,
    itemPerPage: 10,
  });

  const { data: bikeListData, isFetching: isLoading } = useQuery(
    ['bike.list', { ...listParams, id_coll, id_pdl }],
    ({ queryKey }) => {
      const [, listParams] = queryKey as [string, ListParams & { id_coll: number; id_pdl: number }];
      /*
      console.log(listParams.filters, listParams.id_pdl);
      listParams.filters && setPdlSelected(listParams.filters.filterPdl as number);*/

      return getBikeList({
        id_coll: listParams.id_coll,
        id_pdl: listParams.id_pdl,
        p: listParams.currentPage,
        ipp: listParams.itemPerPage,
        'order[column]': listParams.order?.column,
        'order[dir]': listParams.order?.dir,
        ...listParams.filters,
      });
    },
    { refetchOnWindowFocus: false, staleTime: 260_000, keepPreviousData: true }
  );

  const handleOpenModal = useCallback((bikeID: number) => {
    setBikeHistory(bikeID);
    setModalOpen(true);
  }, []);

  const columns = useColumns(handleOpenModal, listParams.filters);

  const handleCloseModal = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  return (
    <MainSection className={'reset'}>
      <Filters
        onChange={(filters) => setListParams((prev) => ({ ...prev, filters }))}
        translationNamespace={'park/genericList'}
        filtersRaw={bikeListData?.data.filters || {}}
      />
      <Card>
        <Datagrid
          loading={isLoading}
          translationNamespace={'park/genericList'}
          rows={bikeListData?.data.result || []}
          columns={columns}
          totalLines={bikeListData?.data.total}
          currentPage={bikeListData?.data.currentPage}
          itemPerPage={bikeListData?.data.itemPerPage}
          nbrPages={bikeListData?.data.totalPage}
          onChangeFilterText={(search) =>
            setListParams((prev) => ({ ...prev, filters: { ...prev.filters, filter: search } }))
          }
          onSort={(order) => setListParams((prev) => ({ ...prev, order }))}
          handleChangePage={(newPage) => setListParams((prev) => ({ ...prev, currentPage: newPage }))}
          onChangeItemPerPage={(newValue) => setListParams((prev) => ({ ...prev, itemPerPage: newValue as number }))}
          search={bikeListData?.data.filters.filter.selected}
          order={bikeListData?.data.order}
        />
      </Card>
      <HistoryDialog open={modalOpen} onClose={handleCloseModal} bikeHistory={bikeHistory} />
    </MainSection>
  );
}

export default BikeList;
