import React, { ChangeEvent, ForwardedRef, InputHTMLAttributes, useCallback, useMemo } from 'react';
import styled from '@emotion/styled/macro';
import { css, Theme } from '@emotion/react';
import { CheckIcon, CrossIcon } from '../../../const/icons';
import { getColor } from '../../../utils/style';
import useInput, { UseInputParameters } from './useInput';

const defaultIconMapping = {
  success: <CheckIcon width={20} />,
  error: <CrossIcon width={20} />,
};

export enum INPUT_COLORS {
  success = 'success',
  normal = 'normal',
}

type InputBaseProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> &
  UseInputParameters & {
    color?: INPUT_COLORS;
  };

function InputBase(props: InputBaseProps, ref: ForwardedRef<HTMLInputElement>) {
  const { onChange: onChangeProp, color: colorProp, error, value, ...propsToForward } = props;
  const { onChange, ...inputProps } = useInput({ onChange: onChangeProp, error, value });

  const color = useMemo(() => {
    if (colorProp) return colorProp;

    if (inputProps.value && inputProps.value !== '' && !inputProps.error) {
      return INPUT_COLORS.success;
    }
    return undefined;
  }, [inputProps.value, inputProps.error, colorProp]);

  const icon = useMemo(() => {
    if (inputProps.error) return defaultIconMapping.error;
    else if (color === INPUT_COLORS.success) return defaultIconMapping.success;

    return null;
  }, [color, inputProps.error]);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      onChange(
        //eslint-disable-next-line
        (propsToForward.type === 'number' ? (newValue === '' ? null : Number(newValue)) : newValue) as unknown as any,
        event
      );
    },
    [onChange]
  );

  return (
    <Root color={color} error={inputProps.error}>
      <Input color={color} ref={ref} onChange={handleChange} {...inputProps} {...propsToForward} />
      {icon}
    </Root>
  );
}

const Root = styled.div<{ error?: boolean }>`
  //display: flex;
  display: block;
  position: relative;
  width: 100%;
  margin: 0;

  & > svg {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: ${(props) => {
      if (props.error) return getColor('red.200')(props);
      else if (props.color === INPUT_COLORS.success) return getColor('green.200')(props);
    }};
  }

  // TODO: add hover styles for svg
`;

const Input = styled.input<Omit<InputBaseProps, 'onChange'>>`
  display: block;
  background-color: white;
  outline: none;
  font-size: 14px;
  border-radius: 8px;
  padding: 8px;
  border: solid 1px ${getColor('gray.200')};
  transition: all 0.3s;
  width: 100%;
  text-overflow: ellipsis;

  :disabled {
    background-color: ${getColor('gray.200')};
  }

  ::placeholder {
    color: ${getColor('gray.300')};
  }

  ${(props) => {
    if (props.error) return errorStyle(props);
    if (props.color === INPUT_COLORS.success) return successStyle(props);

    return normalStyle(props);
  }}
`;

const normalStyle = (props: { theme: Theme }) => css`
  :hover {
    border-color: ${getColor('gray.300')(props)};
  }

  :focus,
  :not([value='']):not(:placeholder-shown) {
    border-color: ${getColor('primary.200')(props)};
  }

  :not([value='']):not(:placeholder-shown):hover {
    border-color: ${getColor('primary.300')(props)};
  }
`;

const errorStyle = (props: { theme: Theme }) => css`
  border-color: ${getColor('red.200')(props)};

  :hover,
  :focus {
    border-color: ${getColor('red.300')(props)};
  }
`;

const successStyle = (props: { theme: Theme }) => css`
  border-color: ${getColor('green.200')(props)};

  :hover,
  :focus {
    border-color: ${getColor('green.300')(props)};
  }
`;

export default React.forwardRef<HTMLInputElement, InputBaseProps>(InputBase);
