import React, { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { SIGN_IN } from 'internal/constants/routes';
import { useTranslation } from 'react-i18next';
import packageInfos from '../../../package.json';

const Footer = (): ReactElement | null => {
  const { t } = useTranslation('components/footer');
  const location = useLocation();

  if (location.pathname === SIGN_IN) {
    return null;
  }

  return (
    <footer className={'site-footer'}>
      <div className={'row'}>
        <div className={'col-md-12'}>
          <p className={'text-center text-md-left'}>
            {t('copyright', { year: dayjs().year().toString() })}
            <a target={'_blank'} href={'http://velogik.com'}>
              Vélogik
            </a>
            {t('all_rights_reserved', { version: packageInfos?.version })}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
