import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import { BOOKING_STATUS, useBooking } from 'pages/Reservation/Reservation.layout';
import React, { ComponentPropsWithoutRef, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectSessionToken } from '../../auth/selectors/authSelectors';
import { generatePath } from '../../utils/router';
import Card from '../Core/Card/Card';
import Link from '../Core/Link/Link';
import Step from '../Core/Step/Step';
import Stepper from '../Core/Stepper/Stepper';
import { getCancelAfterValidateSteps, getCancelSteps, getUnPaidSteps, getWaitingSteps } from './steps';

type RawAccessory = {
  id_accessoire: number;
  id_interne: string;
  montant_article_ht: string;
  montant_article_ttc: string;
  nom_tarif: string;
  nom_type: string;
  taux_tva: number;
  total_ht: string;
  total_ttc: string;
  velocareId: number;
};

export type RawBooking = {
  accessoires?: RawAccessory[];
  article: string;
  isBookingOnlineCB?: boolean;
  cb_booking_transac_id: string;
  sepa_caution_transac_id: string;
  sepa_booking_transac_id?: string;
  cb_caution_transac_id?: string;
  checkout_link: string | null;
  commentaire: string | null;
  contrat_link: string;
  date_cancel: string;
  date_end: string;
  date_naissance: string;
  date_rbt_caution: string | null;
  date_reservation: string;
  date_return: string | null;
  date_start: string;
  date_valid: string;
  devise: string;
  id_consigne: number | null;
  id_interne: string;
  id_paiement: number;
  id_parent_resa: number;
  id_pdl: number;
  id_user: number;
  id_userbo_valid: number;
  id_velo: number;
  isVelocareSynced: boolean;
  is_auto: number;
  is_canceled: number;
  is_returned: number;
  montant_caution_ttc: string;
  montant_article_ttc: string;
  montant_article_ht: string;
  montant_depot_garantie_ht: string;
  montant_depot_garantie_ttc: string;
  nom: string;
  nom_empl: string;
  nom_pdl: string;
  nom_tarif: string;
  nom_type: string;
  nom_user: string;
  numero_resa: string;
  pdl_valid: string;
  prenom: string;
  prenom_user: string;
  rum: string;
  rentReturnDate: string;
  total_ht: string;
  queue_position?: number;
  total_ttc: string;
  valid_bo: number;
  is_paid: boolean;
};

type BookedStepProps = ComponentPropsWithoutRef<typeof Step> & {
  startDate?: string;
  endDate?: string;
};

export function BookedStep(props: BookedStepProps) {
  const { t } = useTranslation('components/bookingCard');

  return (
    <Step {...props} type={'primary'}>
      <Trans
        t={t}
        i18nKey={'bookedStep'}
        values={{ date: dayjs(props.startDate).format('DD/MM/YYYY'), time: dayjs(props.startDate).format('HH:mm') }}>
        <strong />
      </Trans>
    </Step>
  );
}

type BookingState =
  | 'canceled'
  | 'booked'
  | 'validate'
  | 'waiting'
  | 'unPaid'
  // | 'started'
  | 'finished';

//eslint-disable-next-line
const stepsMap: { [key in BookingState]: (booking?: RawBooking, t?: TFunction) => JSX.Element[] } = {
  canceled: (booking, t) => [
    <BookedStep startDate={booking?.date_reservation} lineColor={'danger'} />,
    <Step type={'danger'}>
      <Trans t={t} i18nKey={'canceledStep'} values={{ date: dayjs(booking?.date_cancel).format('DD/MM/YYYY') }} />
    </Step>,
  ],
  booked: (booking, t) => {
    const startDate = dayjs(booking?.date_start);
    const endDate = dayjs(booking?.date_end);
    return [
      <BookedStep startDate={booking?.date_reservation} />,
      <Step type={'wait'} lineColor={'secondary'}>
        <Trans
          t={t}
          i18nKey={'detailsBookingStep'}
          values={{
            startDate: { date: startDate.format('DD/MM/YYYY'), time: startDate.format('HH:mm') },
            endDate: { date: endDate.format('DD/MM/YYYY'), time: endDate.format('HH:mm') },
          }}
        />
      </Step>,
      <Step type={'gray'}>
        <Trans t={t} i18nKey={'bookedNextStep'} />
      </Step>,
    ];
  },
  /*validate: (booking, t) => {
    const startDate = dayjs(booking?.date_start);
    const endDate = dayjs(booking?.date_end);
    const validDate = dayjs(booking?.date_valid);

    return [
      <BookedStep startDate={booking?.date_reservation} />,
      <Step lineColor={'success'}>
        <Trans
          t={t}
          i18nKey={'detailsBookingStep'}
          values={{
            startDate: { date: startDate.format('DD/MM/YYYY'), time: startDate.format('HH:mm') },
            endDate: { date: endDate.format('DD/MM/YYYY'), time: endDate.format('HH:mm') },
          }}
        />
      </Step>,
      <Step type={'success'} lineColor={'secondary'}>
        <Trans
          t={t}
          i18nKey={'validateStep'}
          values={{
            date: { date: validDate.format('DD/MM/YYYY'), time: validDate.format('HH:mm') },
            location: booking?.pdl_valid,
          }}
          components={{ customLink: <Link externalLink to={booking?.contrat_link || ''} /> }}
        />
      </Step>,
      <Step type={'gray'}>
        <Trans t={t} i18nKey={'validateNextStep'} />
      </Step>,
    ];
  },*/
  //eslint-disable-next-line
  unPaid: () => [<BookedStep />],
  waiting: () => [<BookedStep />],
  validate: (booking, t) => {
    return [
      <BookedStep />,
      <Step>
        <Trans
          t={t}
          i18nKey={'detailsBookingStep'}
          values={{
            startDate: { date: dayjs().format('DD/MM/YYYY'), time: dayjs().format('HH:mm') },
            endDate: { date: dayjs().format('DD/MM/YYYY'), time: dayjs().format('HH:mm') },
          }}
        />
      </Step>,
      <Step lineColor={'success'}>
        <Trans
          t={t}
          i18nKey={'validateStep'}
          values={{
            date: { date: dayjs().format('DD/MM/YYYY'), time: dayjs().format('HH:mm') },
            location: `${booking?.pdl_valid} par PICARD Nadege`,
          }}
          components={{ customLink: <Link to={'/'} /> }}
        />
      </Step>,
      <Step type={'success'} lineColor={'secondary'}>
        <Trans
          values={{ date: dayjs().format('DD/MM/YYYY'), time: dayjs().format('HH:mm') }}
          t={t}
          i18nKey={'bookingStartStep'}
        />
      </Step>,
      <Step type={'wait'}>
        <Trans
          values={{ date: dayjs().format('DD/MM/YYYY'), time: dayjs().format('HH:mm') }}
          t={t}
          i18nKey={'bookingEndStep'}
        />
      </Step>,
    ];
  },
  finished: (booking, t) => {
    return [
      <BookedStep />,
      <Step>
        <Trans
          t={t}
          i18nKey={'detailsBookingStep'}
          values={{
            startDate: { date: dayjs().format('DD/MM/YYYY'), time: dayjs().format('HH:mm') },
            endDate: { date: dayjs().format('DD/MM/YYYY'), time: dayjs().format('HH:mm') },
          }}
        />
      </Step>,
      <Step>
        <Trans
          t={t}
          i18nKey={'validateStep'}
          values={{
            date: { date: dayjs().format('DD/MM/YYYY'), time: dayjs().format('HH:mm') },
            location: `${booking?.pdl_valid} par PICARD Nadege`,
          }}
          components={{ customLink: <Link to={'/'} /> }}
        />
      </Step>,
      <Step>
        <Trans
          t={t}
          i18nKey={'bookingStartStep'}
          values={{ date: dayjs().format('DD/MM/YYYY'), time: dayjs().format('HH:mm') }}
        />
      </Step>,
      <Step lineColor={'success'}>
        <Trans
          t={t}
          i18nKey={'bookingEndStep'}
          values={{ date: dayjs().format('DD/MM/YYYY'), time: dayjs().format('HH:mm') }}
        />
      </Step>,
      <Step type={'success'}>
        <Trans
          t={t}
          i18nKey={'finishedStep'}
          values={{ date: dayjs().format('DD/MM/YYYY'), time: dayjs().format('HH:mm') }}
          components={{ customLink: <Link to={booking?.checkout_link || ''} /> }}
        />
      </Step>,
    ];
  },
};

function useSteps(rawBooking?: RawBooking, bookingStatus?: BOOKING_STATUS) {
  const { t } = useTranslation('components/bookingCard');
  const sessionToken = useSelector(selectSessionToken);

  return useMemo(() => {
    const startDate = dayjs(rawBooking?.date_start);
    const endDate = dayjs(rawBooking?.date_end);

    const steps = [
      <Link
        className={'bold mb-4'}
        to={generatePath('home.user.userDetails', { params: { userId: rawBooking?.id_user } })}>
        {t('profileLink')}
      </Link>,
    ];

    if (bookingStatus === BOOKING_STATUS.CANCELED) return getCancelSteps(t, rawBooking, steps);

    if (bookingStatus === BOOKING_STATUS.WAITING) return getWaitingSteps(t, rawBooking, steps);

    if (bookingStatus === BOOKING_STATUS.UNPAID) return getUnPaidSteps(t, rawBooking, steps);

    steps.push(
      <BookedStep key={'bookedStep'} startDate={rawBooking?.date_reservation} />,
      <Step
        key={'detailsBookingStep'}
        type={bookingStatus === BOOKING_STATUS.BOOKED ? 'wait' : 'primary'}
        lineColor={bookingStatus === BOOKING_STATUS.BOOKED ? 'secondary' : 'primary'}>
        <Trans
          t={t}
          i18nKey={'detailsBookingStep'}
          values={{
            startDate: { date: startDate.format('DD/MM/YYYY'), time: startDate.format('HH:mm') },
            endDate: { date: endDate.format('DD/MM/YYYY'), time: endDate.format('HH:mm') },
          }}
        />
      </Step>
    );

    if (bookingStatus === BOOKING_STATUS.BOOKED) {
      steps.push(
        <Step key={'bookedNextStep'} type={'gray'}>
          <Trans t={t} i18nKey={'bookedNextStep'} />
        </Step>
      );
      return steps;
    }

    if (bookingStatus === BOOKING_STATUS.CANCELED_AFTER_VALIDATED) {
      steps.push(...getCancelAfterValidateSteps(t, sessionToken, rawBooking));
      return steps;
    }

    steps.push(
      <Step key={'validateStep'} lineColor={bookingStatus === BOOKING_STATUS.VALIDATE ? 'success' : 'primary'}>
        <Trans
          t={t}
          i18nKey={'validateStep'}
          values={{
            date: {
              date: dayjs(rawBooking?.date_valid).format('DD/MM/YYYY'),
              time: dayjs(rawBooking?.date_valid).format('HH:mm'),
            },
            location: `${rawBooking?.pdl_valid}`,
          }}
          components={{
            customLink: (
              <Link
                target={'_blank'}
                externalLink
                to={rawBooking?.contrat_link.concat(`&token=${sessionToken}`) || ''}
              />
            ),
          }}
        />
      </Step>,
      <Step
        key={'bookingStartStep'}
        type={bookingStatus === BOOKING_STATUS.VALIDATE ? 'success' : 'primary'}
        lineColor={bookingStatus === BOOKING_STATUS.VALIDATE ? 'secondary' : 'primary'}>
        <Trans
          values={{
            date: dayjs(rawBooking?.date_valid).format('DD/MM/YYYY'),
            time: dayjs(rawBooking?.date_valid).format('HH:mm'),
          }}
          t={t}
          i18nKey={'bookingStartStep'}
        />
      </Step>,
      <Step
        key={'bookingEndStep'}
        type={bookingStatus === BOOKING_STATUS.VALIDATE ? 'wait' : 'primary'}
        lineColor={bookingStatus === BOOKING_STATUS.FINISHED ? 'success' : 'primary'}>
        <Trans
          values={{ date: endDate.format('DD/MM/YYYY'), time: endDate.format('HH:mm') }}
          t={t}
          i18nKey={'bookingEndStep'}
        />
      </Step>
    );

    if (bookingStatus === BOOKING_STATUS.VALIDATE) return steps;

    steps.push(
      <Step type={'success'}>
        <Trans
          t={t}
          i18nKey={'finishedStep'}
          values={{
            date: dayjs(rawBooking?.date_return).format('DD/MM/YYYY'),
            time: dayjs(rawBooking?.date_return).format('HH:mm'),
          }}
        />
        {rawBooking?.checkout_link && (
          <Trans
            i18nKey={'seeCheckOut'}
            t={t}
            components={{
              customLink: (
                <Link target={'_blank'} externalLink to={rawBooking.checkout_link?.concat(`&token=${sessionToken}`)} />
              ),
            }}
          />
        )}
      </Step>
    );

    return steps;
  }, [rawBooking, bookingStatus, t, sessionToken]);
}

function BookingCard() {
  const { t } = useTranslation('components/bookingCard');
  const { rawBooking, bookingStatus } = useBooking();

  const steps = useSteps(rawBooking, bookingStatus);

  return rawBooking ? (
    <Card
      title={t('cardTitle')}
      content={
        <>
          <Stepper>{steps}</Stepper>
        </>
      }
    />
  ) : null;
}

export default BookingCard;
