import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { Dialog } from '@mui/material';

import Button from 'fragments/button/Button';

import style from 'fund/components/ExportDialog.module.css';
import Select from 'fragments/select/Select';
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  onClose: () => void;
  onValidate: (value: string) => () => void;
};

const ExportDialog = ({ open, onClose, onValidate }: Props): ReactElement => {
  const { t } = useTranslation('export');
  const [format, setFormat] = useState('csv');

  const formats = useMemo(
    () => [
      {
        value: 'csv',
        label: t('format.csv'),
      },
      {
        value: 'pdf',
        label: t('format.pdf'),
      },
    ],
    [t]
  );

  const handleChange = useCallback((value: string) => {
    setFormat(value);
  }, []);

  return (
    <Dialog open={open} onClose={onClose}>
      <div className={style.modal}>
        <div className={style.modal__content}>
          <Select
            id={'list_all_pdl'}
            name={'list_all_pdl'}
            handleChange={handleChange}
            options={formats}
            value={format}
            noWrapper
          />
        </div>
        <div className={style.modal__footer}>
          <Button label={t('cancel')} className={`btn btn-margin btn-bold btn-primary`} onClick={onClose} />
          <Button label={t('validate')} className={`btn btn-bold btn-primary`} onClick={onValidate(format)} />
        </div>
      </div>
    </Dialog>
  );
};

export default ExportDialog;
