import React, { ChangeEvent, ReactElement, useCallback, useMemo, useState } from 'react';
import cx from 'classnames';
import countries from 'internal/utils/countries';
import { SelectOptionType } from 'internal/types/select';

type Props = {
  id?: string;
  name?: string;
  label?: string;
  options?: SelectOptionType[];
  className?: string;
  size?: string;
  defaultValue?: string;
  value?: string;
  noWrapper?: boolean;
  handleChange?: (value: string) => void;
};

const Select = ({
  id,
  name,
  label = '',
  options = [],
  size = '',
  className = '',
  defaultValue = '',
  value = '',
  noWrapper = false,
  handleChange = () => null,
}: Props): ReactElement => {
  const [_value, select] = useState(value);

  const selectClassName = cx('form-control', {
    [className]: className !== '',
  });

  const wrapperClassName = cx('form-group', {
    [size]: size !== '',
  });

  const onChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement> | string) => {
      if (typeof e === 'string') {
        return select(e);
      }
      const { value: selected } = e.target;

      select(selected);
      handleChange(selected);
    },
    [handleChange]
  );

  const renderOptions = useMemo(
    () =>
      options.map(({ label, value }, index) => (
        <option key={`option-${index}`} value={value}>
          {label}
        </option>
      )),
    [options]
  );

  const renderCountries = useMemo(
    () =>
      countries.map(({ label, value }) => (
        <option key={label} value={value}>
          {label}
        </option>
      )),
    []
  );

  if (id === 'pays' || id === 'pays_orig') {
    if (noWrapper) {
      return (
        <>
          {label !== '' && <label htmlFor={id}>{label}</label>}
          <select className={selectClassName} name={name} onChange={onChange} value={value || _value || defaultValue}>
            {renderCountries}
          </select>
        </>
      );
    }
    return (
      <div className={wrapperClassName}>
        {label !== '' && <label htmlFor={id}>{label}</label>}
        <select className={selectClassName} name={name} onChange={onChange} value={value || _value || defaultValue}>
          {renderCountries}
        </select>
      </div>
    );
  }

  if (noWrapper) {
    return (
      <>
        {label !== '' && <label htmlFor={id}>{label}</label>}
        <select
          id={id}
          className={selectClassName}
          name={name}
          onChange={onChange}
          value={value || _value || defaultValue}>
          {renderOptions}
        </select>
      </>
    );
  }

  return (
    <div className={wrapperClassName}>
      {label !== '' && <label htmlFor={id}>{label}</label>}
      <select
        id={id}
        className={selectClassName}
        name={name}
        onChange={onChange}
        value={value || _value || defaultValue}>
        {renderOptions}
      </select>
    </div>
  );
};

export default Select;
