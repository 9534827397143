/** @jsxImportSource @emotion/react */
import React, { ComponentPropsWithoutRef, useCallback, useMemo } from 'react';
import { FieldError } from 'react-hook-form';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import FormControl from './FormControl/FormControl';
import DatePickerBase from './DatePicker/DatePickerBase';
import Label from './Label/Label';

type DateRangeFieldProps = Omit<ComponentPropsWithoutRef<typeof FormControl>, 'error'> &
  ComponentPropsWithoutRef<typeof DatePickerBase> & {
    error?: FieldError;
    label?: string;
  };

function DateRangeField(props: DateRangeFieldProps) {
  const { label, onChange, value, error, required } = props;

  const [startDate, endDate] = useMemo(() => {
    const dateArray = value?.split('/');
    if (dateArray?.length === 2) {
      const startDateObject = dayjs(dateArray[0]);
      const endDateObject = dayjs(dateArray[1]);
      return [
        startDateObject.isValid() ? startDateObject.toDate() : null,
        endDateObject.isValid() ? endDateObject.toDate() : null,
      ];
    }
    return [null, null];
  }, [value]);

  const handleStartDateChange = useCallback(
    (newStartDate: unknown) => {
      if (newStartDate !== null && typeof newStartDate === 'string') {
        const dateRange = dayjs(newStartDate)
          .format('YYYY-MM-DD')
          .concat(endDate ? `/${dayjs(endDate).format('YYYY-MM-DD')}` : '/null');
        onChange?.(dateRange);
      }
    },
    [endDate]
  );

  const handleEndDateChange = useCallback(
    (newEndDate: unknown) => {
      if (newEndDate !== null && typeof newEndDate === 'string') {
        const dateRange = (startDate ? dayjs(startDate).format('YYYY-MM-DD') : 'null').concat(
          `/${dayjs(newEndDate).format('YYYY-MM-DD')}`
        );
        onChange?.(dateRange);
      }
    },
    [startDate]
  );

  return (
    <div>
      {label && <Label required={required}>{label}</Label>}
      <div
        css={css`
          display: flex;
        `}>
        <FormControl onChange={handleStartDateChange} value={dayjs(startDate).format('YYYY/MM/DD')} error={!!error}>
          <DatePickerBase startDate={startDate} endDate={endDate} selectsStart placeholder={'Du'} />
        </FormControl>
        <FormControl onChange={handleEndDateChange} value={dayjs(endDate).format('YYYY/MM/DD')}>
          <DatePickerBase startDate={startDate} endDate={endDate} selectsEnd placeholder={'Au'} />
        </FormControl>
      </div>
    </div>
  );
}

export default DateRangeField;
