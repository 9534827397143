import { useQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, NavLink, Outlet, useLocation, useMatch, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getPagesConfig } from '../../../api/admin/pagesAdmin.api';
import { selectCollAndDeliveryPointID } from '../../../auth/selectors/authSelectors';
import { Tab, Tabs, TabsList } from '../../../components/Core/Tabs/Tabs';
import { TabComponent } from '../../Reservation/Reservation.layout';

function PagesAdministrationLayout() {
  const { t } = useTranslation(['admin/pages']);
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const navigate = useNavigate();
  const location = useLocation();
  const match = useMatch('/administration/pages/:lang');

  const { data: pagesConfigResponse } = useQuery(
    ['param/pages', { id_coll }],
    ({ queryKey }) => {
      const [, queryParams] = queryKey as [string, { id_coll: number }];
      return getPagesConfig(queryParams);
    },
    {
      staleTime: 400_000,
    }
  );

  useEffect(() => {
    if (pagesConfigResponse && !match) navigate(pagesConfigResponse?.data.langs[0]);
  }, [pagesConfigResponse, location, match]);

  return (
    <div className={'reset'}>
      <div id={'pages-config-layout'}>
        <Tabs>
          <TabsList>
            {pagesConfigResponse?.data.langs.map((lang) => (
              <Tab
                key={'tabs.'.concat(lang)}
                component={({ children, ...otherProps }: TabComponent) => (
                  <NavLink {...otherProps} to={generatePath(lang)} end>
                    {children}
                  </NavLink>
                )}>
                {t('langs.'.concat(lang))}
              </Tab>
            ))}
          </TabsList>
        </Tabs>
      </div>

      <Outlet context={pagesConfigResponse} />
    </div>
  );
}

export default PagesAdministrationLayout;
