import React from 'react';
import * as ICONS from '../../../const/icons';
import styles from './button.module.scss';
import ButtonBase from './ButtonBase';

type IconButtonColors = 'primary' | 'danger' | 'action';

type IconKeyList = keyof typeof ICONS;

type IconButtonProps = React.ComponentPropsWithoutRef<typeof ButtonBase> & {
  color?: IconButtonColors;
  icon: IconKeyList;
  width?: number;
};

function IconButton(props: IconButtonProps) {
  const { width = 20, icon, color = 'primary', className, ...propsToForward } = props;

  const Icon = ICONS[icon];

  return (
    <ButtonBase
      className={[
        styles.IconButton,
        color === 'primary'
          ? styles.IconButton__primary
          : color === 'danger'
          ? styles.IconButtonDanger
          : styles.IconButtonAction,
        className,
      ].join(' ')}
      {...propsToForward}>
      <Icon width={width} height={width} />
    </ButtonBase>
  );
}

export default IconButton;
