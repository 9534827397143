import { instance } from '../user.api';

const URL = {
  depositConfig: 'param/admincautions',
};

export type RawBookingConfig = {
  confirmationText: Record<string, string>;
  enabled: boolean;
  langs: string[];
  options: Record<string, string[]>;
  paymentInfoText: Record<string, string>;
  paymentsMeans: Record<string, boolean | string | null>;
};

export async function getDepositConfig(id_coll: number) {
  return await instance.get<RawBookingConfig>(URL.depositConfig, {
    params: {
      id_coll,
    },
  });
}

export type PutBookingConfigBody = Omit<RawBookingConfig, 'langs' | 'options'> & {
  id_coll: number;
};

export async function putDepositConfig(data: PutBookingConfigBody) {
  return await instance.post(URL.depositConfig, data);
}
