export const BASE64_HEAD_REGEXP = /^data:(image|application)\/[a-z]+;base64,/;

export const getMimeType = (format: string): string => {
  switch (format) {
    case 'pdf':
      return 'application/pdf';
    case 'csv':
      return 'text/csv';
    default:
      return 'application/octet-stream';
  }
};
