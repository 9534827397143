import 'reflect-metadata';
import 'index.css';
import App from 'internal/components/App';
import 'internal/i18n';
import store, { persistor } from 'internal/store';
import React from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import * as serviceWorker from 'serviceWorker';
import './assets/scss/index.scss';
import { ConfirmContextProvider } from './components/Confirm/ConfirmContext';
import SnackbarProvider from './context/SnackbarContext';
import 'api/mock';

const container = document.getElementById('root');
if (!container) throw new Error('container not found!');

const root = createRoot(container);
root.render(
  <React.StrictMode>
    <ConfirmContextProvider>
      <Provider store={store}>
        <SnackbarProvider>
          <PersistGate loading={null} persistor={persistor}>
            <React.Suspense fallback={<></>}>
              <App />
            </React.Suspense>
          </PersistGate>
        </SnackbarProvider>
      </Provider>
    </ConfirmContextProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
