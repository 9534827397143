import React, { useCallback, useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { setSnackbar, showSnackbar } from '../../../internal/actions/appActions';
import SnackbarSeverity from '../../../internal/constants/snackbar';
import ParametersEmpls from '../../../parameters/components/collectivity/ParametersEmpls';
import ParametersEmplCreator from '../../../parameters/components/ParametersEmplCreator';

function EmplListPage() {
  const { t } = useTranslation(['old']);
  //eslint-disable-next-line
  const collResp: any = useOutletContext();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const empls = useMemo(() => {
    if (collResp.data) {
      return collResp.data.empl;
    }

    return null;
  }, [collResp]);

  const notifySuccess = useCallback(() => {
    dispatch(setSnackbar(t('parameters.modification_success'), SnackbarSeverity.SUCCESS));
    dispatch(showSnackbar());
  }, [t]);

  return (
    <div className={'main-content'}>
      <div className={'card'}>
        <div className={'card-body'}>
          <ParametersEmpls empls={empls} />
          <br />
          <p className={'lead'}>{t('parameters.add_empl')}</p>
          <ParametersEmplCreator
            onSuccess={notifySuccess}
            onRefresh={() => queryClient.invalidateQueries(['param/coll'])}
          />
        </div>
      </div>
    </div>
  );
}

export default EmplListPage;
