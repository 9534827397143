import React, { useMemo } from 'react';
import { FetchQueryOptions, useQuery, WithRequired } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { instance } from '../../api/user.api';
import Card from '../../components/Core/Card/Card';
import StatBlock from '../../components/Core/StatBlock/StatBlock';
import { selectCollAndDeliveryPointID } from '../../auth/selectors/authSelectors';
import styles from './UsersSection.module.scss';
import AgeBarChart, { AgeBarData } from './AgeBarChart';
import FiltersInfos, { contentInfoMap } from './FiltersInfos/FiltersInfos';
import RepartitionByType from './RepartitionByType';
import { ArticleTypeListResponse } from './FleetSection';
import { articleTypeListQuery } from './Indicator.page';

export const userStatsQuery: (
  queryParams: unknown
) => WithRequired<FetchQueryOptions<AxiosResponse>, 'queryFn' | 'queryKey'> = (queryParams) => ({
  queryKey: ['indicator/users', queryParams],
  queryFn: ({ queryKey }) => {
    const [url, queryParams] = queryKey as [string, never];
    return instance.get(url, { params: queryParams });
  },
});

type UserStatsResponse = {
  nbRentInProgress: 0;
  repartiontByType: {
    total: number;
    civilite: Record<
      string,
      {
        total: 106;
        age?: Record<string, number>;
      }
    >;
  };
  repartionByAge?: {
    age: Record<string, Record<string, number>>;
  };
};

function transformRepartitionByAge(
  rawData: UserStatsResponse['repartionByAge'],
  articleTypes?: string[]
): [Record<string, Record<string, number>> | undefined, string[] | undefined] {
  const values: Record<string, Record<string, number>> | undefined = articleTypes?.reduce((prev, articleType) => {
    let total = 0;
    const ages = {};
    Object.keys(rawData?.age || {}).forEach((age) => {
      const value = rawData?.age[age][articleType] || Number.NaN;
      Object.assign(ages, { [age]: value });
      total += value || 0;
    });

    Object.assign(prev, { [articleType]: { ...ages, total } });
    return prev;
  }, {});

  //eslint-disable-next-line
  return [values, articleTypes?.sort((a, b) => values![b].total - values![a].total)];
}

function UsersSection() {
  const { t } = useTranslation(['stats/indicator']);
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const [searchParams] = useSearchParams();

  const { data: userStatsResponse } = useQuery<AxiosResponse<UserStatsResponse>>({
    ...userStatsQuery({
      type: searchParams.get('articles') || 'bike',
      idColl: id_coll,
      pdl: searchParams.get('pdl') !== 'null' ? searchParams.get('pdl') : undefined,
    }),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });

  const { data: articleTypeListResponse } = useQuery<AxiosResponse<ArticleTypeListResponse>>({
    ...articleTypeListQuery({
      article: searchParams.get('articles') || 'bike',
      idColl: id_coll,
    }),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });

  const ageBarData: AgeBarData = useMemo(() => {
    return Object.entries(userStatsResponse?.data?.repartiontByType?.civilite || {}).map(([gender, item]) => {
      return {
        label: gender,
        data: Object.values(item.age || {}),
      };
    });
  }, [userStatsResponse]);

  const [repartitionByTypeTransformed, articleTypesSorted] = useMemo(
    () => transformRepartitionByAge(userStatsResponse?.data.repartionByAge, articleTypeListResponse?.data),
    [userStatsResponse, articleTypeListResponse]
  );

  const repartitionByTypeData = useMemo(() => {
    return _.reduce(
      userStatsResponse?.data?.repartionByAge?.age,
      (result, value, age) => {
        result.push({
          label: age,
          /*data: articleTypeListResponse!.data.map((articleType) =>
         articleType in item ? item[articleType] : Number.NaN
       ),*/
          data:
            articleTypesSorted?.map((articleType) => {
              //eslint-disable-next-line
              return repartitionByTypeTransformed![articleType][age];
            }) || [],
        });
        return result;
      },
      //eslint-disable-next-line
      [] as { label: string; data: any }[]
    );
  }, [userStatsResponse, repartitionByTypeTransformed, articleTypesSorted]);

  return (
    <Card
      title={(baseStyle) => (
        <div className={[baseStyle, styles.Title].join(' ')}>
          <h3>{t('users.title')}</h3>
          <div>
            <span>{t('dateTitle', { date: dayjs().format('DD/MM/YYYY, HH[h]mm') })}</span>
          </div>
        </div>
      )}
      content={
        <div>
          <div className={styles.FirstSection}>
            <StatBlock
              title={t('users.rentalInProgress.title')}
              subtitle={t('users.rentalInProgress.subtitle')}
              nbr={userStatsResponse?.data.nbRentInProgress}
            />
            <div>
              <FiltersInfos />
              <AgeBarChart total={userStatsResponse?.data?.repartiontByType?.total} data={ageBarData} />
            </div>
          </div>
          <div className={styles.SecondSection}>
            <h4>{t('users.distribution')}</h4>
            <div>
              <FiltersInfos />
              <RepartitionByType
                title={t(`chartTitles.${searchParams.get('type') || 'type'}`, {
                  // eslint-disable-next-line
                  article: `$t(common:${contentInfoMap.get(searchParams.get('articles') || 'bike')!.tKey})`,
                })}
                data={repartitionByTypeData}
                labels={articleTypesSorted || []}
              />
            </div>
          </div>
        </div>
      }
    />
  );
}

export default UsersSection;
