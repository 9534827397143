/** @jsxImportSource @emotion/react */
import React, { ComponentPropsWithoutRef, PropsWithChildren } from 'react';
import { NavLink } from 'react-router-dom';
import { css } from '@emotion/react/macro';
import { Theme, useTheme } from '@emotion/react';
import { getColor } from '../../utils/style';
import { Ellipse } from './MenuItem';

const subMenuCss = {
  self: (theme: Theme) => css`
    cursor: pointer;
    color: #899eb9;
    font-size: 13px;
    display: flex;
    align-items: center;
    margin: 0 8px;
    padding: 8px 0;
    border-radius: 8px;
    //transition: all 0.4s;
    text-decoration: none;
    outline: none;

    :hover {
      color: white;
      background-color: #5a616e;
    }

    > span:nth-of-type(2) {
      margin-left: 8px;
    }

    /*&[data-selected='true'] {
    background-color: ${getColor('primary.200')({ theme })};
    color: white;
  }

  &[data-selected='true'] ${Ellipse} {
    background-color: white;
  }*/

    &.active {
      background-color: ${getColor('primary.200')({ theme })};
      color: white;
    }

    &.active ${Ellipse} {
      background-color: white;
    }
  `,
};

type SubMenuProps = PropsWithChildren<ComponentPropsWithoutRef<typeof NavLink>>;

function SubMenuLink(props: SubMenuProps) {
  const theme = useTheme();

  return (
    <NavLink {...props} css={subMenuCss.self(theme)}>
      {props.children}
    </NavLink>
  );
}

export default SubMenuLink;
