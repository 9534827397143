import Joi from 'joi';
import { GetFormFieldsFunction } from '../Collectivity/updateForm';

const updateWaitingListParametersForm: GetFormFieldsFunction = (t) => {
  return [
    {
      fields: [
        {
          type: 'select',
          name: 'temps_validation',
          label: t('waiting_list_time'),
          options: [
            { label: t('time_validation.day'), value: 24 },
            { label: t('time_validation.two_days'), value: 48 },
            { label: t('time_validation.three_days'), value: 72 },
          ],
          schema: Joi.number(),
        },
      ],
    },
  ];
};

export default updateWaitingListParametersForm;
