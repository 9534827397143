import { STORE_USER_DETAILS } from 'users/actions/usersActions';
import { UsersActionType } from 'users/types/actions';
import { UserDetailsType } from 'users/types/users';

type State = {
  userDetails: UserDetailsType | null;
};

const initialState: State = {
  userDetails: null,
};

export default (state = initialState, action: UsersActionType): State => {
  switch (action.type) {
    case STORE_USER_DETAILS:
      const { userDetails } = action.payload;

      return {
        ...state,
        userDetails,
      };

    default:
      return state;
  }
};
