import { instance, ListQueryParams } from '../user.api';

const URL = {
  fetchList: 'parc/consignes',
  putStatus: 'parc/etatcons',
};

type RawPark = {
  id_consigne: number;
  id_interne: string;
  available: number;
  nom_type: string;
  is_upstairs: number;
  nom_empl: string;
  velocareId: number | null;
  id_resa: number;
  id_user: number;
  currentStatus: {
    id_etat: number;
    value: string;
  };
  //eslint-disable-next-line
  availableStatusTo: any[];
  velocareLink: string;
};

export async function getParkList(queryParams: ListQueryParams & { id_coll: number; id_pdl: number }) {
  return await instance.get<{
    currentPage: number;
    itemPerPage: number;
    // eslint-disable-next-line
    filters: any;
    // eslint-disable-next-line
    result: RawPark[];
    total: number;
    totalPage: number;
    //eslint-disable-next-line
    order: any;
  }>(URL.fetchList, {
    params: queryParams,
  });
}

type PutStatusBody = {
  id_coll: number;
  id_pdl: number;
  id_cons: number;
  id_etat: string;
};

export async function putStatus(putBody: PutStatusBody) {
  return await instance.post(URL.putStatus, putBody);
}
