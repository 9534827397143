import React, { Fragment, useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { FetchQueryOptions, QueryClient, WithRequired } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { ActionFunction, generatePath, json, LoaderFunction, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { createPortal } from 'react-dom';
import moment from 'moment';
import _ from 'lodash';
import { instance } from '../../../api/user.api';
import store from '../../../internal/store';
import { GridColDefinition } from '../../../components/Core/Datagrid/gridCols';
import { renderCell, Row, RowItem } from '../../../components/Core/Datagrid/Datagrid';
import MainSection from '../../../components/Core/MainSection/MainSection';
import Card from '../../../components/Core/Card/Card';
import { selectCollAndDeliveryPointID } from '../../../auth/selectors/authSelectors';
import ButtonWithIcon from '../../../components/Core/Button/ButtonWithIcon';
import GenericList from '../Voucher/components/GenericList';
import { ListParams } from '../../park/BikeList/BikeList';
import { useMainTitle } from '../../../components/Layout/MainTitleContext';
import { ChevronIcon } from '../../../const/icons';

export const priceListQuery: (
  queryParams: unknown
) => WithRequired<FetchQueryOptions<AxiosResponse>, 'queryFn' | 'queryKey'> = (queryParams) => ({
  queryKey: ['price/list', queryParams],
  queryFn: ({ queryKey }) => {
    const [apiUrl, queryParams, listParams] = queryKey as [string, { id_coll: number }, ListParams];
    return instance.get(apiUrl as string, {
      params: { ...queryParams, ipp: listParams?.itemPerPage, p: listParams?.currentPage, ...listParams?.filters },
    });
  },
});

export const priceListAction: (queryClient: QueryClient) => ActionFunction =
  (queryClient) =>
  async ({ request }) => {
    const collId = store.getState().authState.collectivity;
    const { action, category, ...data } = await request.json();
    let response = null;

    try {
      switch (action) {
        case 'new':
          response = await instance.post('price/new', { ...data, idColl: collId });
          if (response.status === 200) await queryClient.invalidateQueries(['price/list']);
          break;
        case 'edit':
          response = await instance.post('price/edit', { ...data, idColl: collId });
          if (response.status === 200) {
            await queryClient.invalidateQueries(['price/list']);
            await queryClient.invalidateQueries(['price/edit', { idColl: collId, idPrice: data.id }]);
          }
          break;
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        console.error(e);
        return json(e.response?.data);
      }
    }

    return response;
  };

export const priceListLoader: (queryClient: QueryClient) => LoaderFunction = (queryClient) => async () => {
  const collId = store.getState().authState.collectivity;

  const priceListResponse = await queryClient.ensureQueryData({
    ...priceListQuery({ idColl: collId }),
  });

  return { collId, priceListResponse };
};

function NewPrice() {
  const { t } = useTranslation(['parameters/priceList/priceList']);
  const [layoutElement, setLayoutElement] = useState<Element | null>(null);

  useLayoutEffect(() => {
    setLayoutElement(document.querySelector('#price-list-layout'));
  }, []);

  return (
    layoutElement &&
    createPortal(
      <Fragment key={'create-price'}>
        <ButtonWithIcon
          as={NavLink}
          to={'/parametres/grille-tarifaire/prix/nouveau'}
          icon={'AddWithoutCircle'}
          color={'major'}>
          {t('newPriceCta')}
        </ButtonWithIcon>
      </Fragment>,
      layoutElement
    )
  );
}

function useColumns(): GridColDefinition[] {
  const { t } = useTranslation('parameters/priceList/priceList');

  const getColumnText = useCallback(
    (key: string) => {
      return t('columns.'.concat(key));
    },
    [t]
  );

  return useMemo(
    () => [
      {
        field: '',
        type: 'custom',
        headerName: getColumnText('category'),
        renderCell: ({ row }) => <RowItem>{t(`common:${row.articleParent}`)}</RowItem>,
      },
      {
        field: 'articleType',
        type: 'string',
        headerName: getColumnText('articleType'),
      },
      {
        field: 'duration',
        type: 'string',
        headerName: getColumnText('duration'),
      },
      {
        field: 'priceType',
        type: 'string',
        headerName: getColumnText('priceType'),
      },
      {
        field: 'show',
        type: 'custom',
        headerName: getColumnText('show'),
        renderCell: ({ row }) => {
          return <RowItem>{row.show ? t('common:yes') : t('common:no')}</RowItem>;
        },
      },
      {
        field: 'mntHT',
        type: 'string',
        headerName: getColumnText('amountExcludingTaxes'),
      },
      {
        field: 'tauxTVA',
        type: 'string',
        headerName: getColumnText('vat'),
      },
      {
        field: 'mntTTC',
        type: 'custom',
        headerName: getColumnText('amountIncludingTaxes'),
        renderCell: ({ row }) => <RowItem>{(row.mntHT * (1 + row.tauxTVA / 100)).toFixed(2)}</RowItem>,
      },
      /*{
        field: 'id',
        type: 'actions',
        renderCell: ({ row }) => (
          <div className={'row justify-end items-center w-100'}>
            <UpdateDuration row={row} />
          </div>
        ),
        width: 'minmax(100px, auto)',
      },*/
    ],
    [t]
  );
}

function PriceListPage() {
  const { t } = useTranslation(['parameters/priceList/priceList']);
  useMainTitle(t('mainTitle'));

  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const columns = useColumns();
  const query = priceListQuery({ idColl: id_coll });

  return (
    <>
      <NewPrice />
      <MainSection>
        <Card>
          <GenericList
            translationNamespace={'parameters/priceList/priceList'}
            //eslint-disable-next-line
            queryKey={query.queryKey as any}
            queryFn={query.queryFn}
            columns={columns}
            RowComponent={({ row, columns, colsTemplate, key }) => (
              <Row
                key={key}
                as={NavLink}
                to={generatePath('/parametres/grille-tarifaire/prix/:id', { id: row.id })}
                colsTemplate={colsTemplate}>
                {columns.map((column, index) => renderCell(column, row, index))}
                <RowItem style={{ gridColumnStart: '-1', gridRow: '2 span', color: 'var(--theme-default-gray300)' }}>
                  <ChevronIcon width={14} />
                </RowItem>
                {!_.isNull(row.start_date) && (
                  <span
                    style={{
                      gridColumn: '3 span',
                      marginTop: 28,
                      marginBottom: 4,
                      color: 'var(--theme-default-gray400)',
                    }}>
                    {t('rangeDateVisible', {
                      startDate: moment(row.start_date).format('DD/MM/YYYY'),
                      endDate: moment(row.end_date).format('DD/MM/YYYY'),
                    })}
                  </span>
                )}
              </Row>
            )}
          />
        </Card>
      </MainSection>
    </>
  );
}

export default PriceListPage;
