import { GridColDefinition } from 'components/Core/Datagrid/gridCols';
import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import InputBase from '../../../../components/Core/InputBase/InputBase';
import usePriceTypeSelect from '../UsePriceTypeSelect';
import usePriceOptions from '../UsePriceOptions';
import { selectCollAndDeliveryPointID } from '../../../../auth/selectors/authSelectors';
import DepositActionCell from './components/DepositActionCell';
import { getAmountExcludingTaxes, getAmountIncludingTaxes } from './utils';

type DepositRow = { article: string; depotId: number; depotName: string; articles: number[] };

type UseDepositListColumnsProps = {
  depositRows: DepositRow[];
  setDepositRows: Dispatch<SetStateAction<DepositRow[]>>;
};

function useDepositListColumns(props: UseDepositListColumnsProps) {
  const { t } = useTranslation(['deposit/listPage']);
  const { depositRows, setDepositRows } = props;
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const getPriceOptionsByArticle = usePriceOptions();
  const getPriceTypeSelectByArticle = usePriceTypeSelect(getPriceOptionsByArticle);

  const handleDeleteOneDeposit = useCallback(
    (depotId: number) => {
      const index = depositRows.findIndex((depositRow) => {
        return depositRow.depotId === depotId;
      });
      if (index !== -1) {
        setDepositRows((prevState) => {
          const copy = [...prevState];
          copy.splice(index, 1);
          return [...copy];
        });
      }
    },
    [depositRows]
  );

  return useMemo<GridColDefinition[]>(() => {
    return [
      {
        headerName: t('columns.name'),
        field: 'depotName',
        type: 'formField',
        renderCell: ({ row, control }) => {
          return (
            <Controller
              control={control}
              name={'depotName'}
              key={`input-name-${row.depotId}`}
              defaultValue={row.depotName}
              render={({ field: { onChange, value, name } }) => (
                <InputBase name={name} onChange={onChange} value={value} />
              )}
            />
          );
        },
      },
      {
        headerName: t('columns.prices'),
        field: 'articles',
        type: 'formField',
        width: 'minmax(400px, 2fr)',
        renderCell: ({ row, control }) => {
          const name = 'articles';
          const selectNode = getPriceTypeSelectByArticle(row.article, control, name, row.articles);
          return React.cloneElement(selectNode, {
            id: name,
            key: `select-${row.depotId}-${row.depotName}`,
          });
        },
      },
      {
        headerName: t('columns.amount_excluding_taxes'),
        field: 'montant_ht',
        type: 'formField',
        renderCell: ({ row, control, setValue }) => {
          const handleChange = useCallback(
            (
              onChange: (newValue: string | number | readonly string[] | undefined) => void,
              newValue: string | number | readonly string[] | undefined
            ) => {
              const amountExcludingTaxes = Number(newValue);
              setValue('montant_ttc', getAmountIncludingTaxes(amountExcludingTaxes, Number(row.taux_tva)));
              onChange(newValue);
            },
            [row]
          );

          return (
            <Controller
              control={control}
              name={'montant_ht'}
              defaultValue={Number(row.montant_ht).toFixed(4)}
              key={`input-price-${row.depotId}`}
              render={({ field: { onChange, value, name } }) => (
                <InputBase onChange={(newValue) => handleChange(onChange, newValue)} value={value} name={name} />
              )}
            />
          );
        },
      },
      {
        headerName: t('columns.amount_including_taxes'),
        field: 'montant_ttc',
        type: 'formField',
        renderCell: ({ row, control, setValue }) => {
          const handleChange = useCallback(
            (
              onChange: (newValue: string | number | readonly string[] | undefined) => void,
              newValue: string | number | readonly string[] | undefined
            ) => {
              const amountIncludingTaxes = Number(newValue);
              setValue('montant_ht', getAmountExcludingTaxes(amountIncludingTaxes, Number(row.taux_tva)));
              onChange(newValue);
            },
            [row]
          );

          return (
            <Controller
              control={control}
              name={'montant_ttc'}
              key={`input-price-with-taxes-${row.depotId}`}
              defaultValue={Number(row.montant_ttc).toFixed(4)}
              render={({ field: { onChange, value, name } }) => (
                <InputBase name={name} onChange={(newValue) => handleChange(onChange, newValue)} value={value} />
              )}
            />
          );
        },
      },
      {
        headerName: t('columns.vat_rate'),
        field: 'taux_tva',
        type: 'string',
        width: 120,
      },
      {
        headerName: '',
        type: 'formField',
        field: '',
        width: '1fr',
        renderCell: (props) => <DepositActionCell handleDelete={handleDeleteOneDeposit} {...props} id_coll={id_coll} />,
      },
    ] as GridColDefinition[];
  }, [getPriceTypeSelectByArticle, handleDeleteOneDeposit]);
}

export default useDepositListColumns;
