export const PARK_BIKES_ENDPOINT = 'parc/velos';
export const PARK_HISTORY_ENDPOINT = 'parc/histovelo';
export const PARK_POST_BIKE_STATE = 'parc/etatvelo';
export const PARK_PDL_ENDPOINT = 'parc/pdl';
export const PARK_POST_BIKE_AFFECTATION = 'parc/affvelo';
export const CONSIGNE_PARK_ENDPOINT = 'parc/consignespark';
export const UPDATE_CONSIGNE_PARK_ENDPOINT = 'parc/updconspark';
export const CONSIGNE_ENDPOINT = 'parc/consignes';
export const CONSIGNE_STATE_ENDPOINT = 'parc/etatcons';
export const STATUSES_ENDPOINT = 'parc/statuses';
export const ACCESSORIES_ENDPOINT = 'parc/accessoires';
export const ACCESSORIES_STATE_ENDPOINT = 'parc/etataccessoire';
export const ACCESSORIES_POST_ENDPOINT_AFFECTATION = 'parc/affaccessoire';
