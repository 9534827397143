import { AssetsKeysType } from 'parameters/types/theme';

const MIN_WIDTH_NAVBAR_LOGO = 48;
const MIN_WIDTH_HEADER_LOGO = 250;
const MIN_WIDTH_HEADER_BACKGROUND = 1500;

const MIN_WIDTH_ARTICLE_IMAGE_TYPE = 100;

const minAssetWidth: { [key in AssetsKeysType]: number } = {
  navbarLogo: MIN_WIDTH_NAVBAR_LOGO,
  headerLogo: MIN_WIDTH_HEADER_LOGO,
  headerBackground: MIN_WIDTH_HEADER_BACKGROUND,
};

export {
  MIN_WIDTH_NAVBAR_LOGO,
  MIN_WIDTH_HEADER_LOGO,
  MIN_WIDTH_HEADER_BACKGROUND,
  MIN_WIDTH_ARTICLE_IMAGE_TYPE,
  // eslint-disable-next-line @typescript-eslint/comma-dangle
  minAssetWidth,
};
