import React, { useEffect, useId, useState } from 'react';
import {
  FloatingFocusManager,
  FloatingOverlay,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { useLocation } from 'react-router-dom';
import Sidebar from '../Sidebar/Sidebar';
import { CloseIcon } from '../../const/icons';
import styles from './Drawer.module.scss';

function Drawer() {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context, {
    outsidePressEvent: 'mousedown',
  });
  const role = useRole(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role]);

  const labelId = useId();
  const descriptionId = useId();

  useEffect(() => {
    setIsOpen(false);
  }, [location.pathname]);

  return (
    <>
      <button className={styles.Button} ref={refs.setReference} {...getReferenceProps()}>
        <div>
          <span />
        </div>
      </button>
      {isOpen && (
        <React.Suspense fallback={<></>}>
          <FloatingOverlay lockScroll style={{ background: 'rgba(0, 0, 0, 0.8)', zIndex: 500 }}>
            <FloatingFocusManager context={context}>
              <div
                ref={refs.setFloating}
                aria-labelledby={labelId}
                aria-describedby={descriptionId}
                {...getFloatingProps()}>
                <Sidebar
                  top={
                    <div>
                      <button className={styles.Button} {...getReferenceProps()}>
                        <CloseIcon width={20} />
                      </button>
                    </div>
                  }
                />
              </div>
            </FloatingFocusManager>
          </FloatingOverlay>
        </React.Suspense>
      )}
    </>
  );
}

export default Drawer;
