import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LoaderFunction, NavLink, useFetcher, useLoaderData, useNavigate } from 'react-router-dom';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import _ from 'lodash';
import { FetchQueryOptions, QueryClient, WithRequired } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import useDisplayError from '../../../hook/useDisplayError';
import { useSnackbar } from '../../../context/SnackbarContext';
import { useMainTitle } from '../../../components/Layout/MainTitleContext';
import { TOAST_SEVERITY } from '../../../components/Core/Toast/Toast';
import MainSection from '../../../components/Core/MainSection/MainSection';
import Card from '../../../components/Core/Card/Card';
import ButtonWithIcon from '../../../components/Core/Button/ButtonWithIcon';
import store from '../../../internal/store';
import { bikeListQuery } from '../Products/Bike/BikeList.page';
import { accessoryListQuery } from '../Products/Accessory/AccessoryListPage';
import { instance } from '../../../api/user.api';
import { getAmountIncludingTaxes } from '../Deposit/List/utils';
import { PriceForm } from './NewPrice.page';
import { durationListQuery } from './DurationList.page';
import { priceTypeListQuery } from './TypePriceList.page';

const priceQuery: (queryParams: unknown) => WithRequired<FetchQueryOptions<AxiosResponse>, 'queryFn' | 'queryKey'> = (
  queryParams
) => ({
  queryKey: ['price/details', queryParams],
  queryFn: ({ queryKey }) => {
    const [apiUrl, queryParams] = queryKey as [string, { idColl: number; idPrice: number }];
    return instance.get(apiUrl as string, {
      params: queryParams,
    });
  },
});

export const editPriceLoader: (queryClient: QueryClient) => LoaderFunction =
  (queryClient) =>
  async ({ request, params }) => {
    const collId = store.getState().authState.collectivity;
    const url = new URL(request.url);
    const category = url.searchParams.get('category');

    let articleListResponse = null;

    const priceResponse = await queryClient.ensureQueryData({
      ...priceQuery({ idColl: collId, idPrice: Number(params.id) }),
    });

    if (category) {
      switch (category) {
        case 'bike':
          articleListResponse = await queryClient.ensureQueryData({
            ...bikeListQuery({ idColl: collId }),
          });
          break;
        case 'accessory':
          articleListResponse = await queryClient.ensureQueryData({
            ...accessoryListQuery({ idColl: collId }),
          });
          break;
      }
    }

    const durationListResponse = await queryClient.ensureQueryData({
      ...durationListQuery({ idColl: collId }),
    });

    const priceTypeListResponse = await queryClient.ensureQueryData({
      ...priceTypeListQuery({ idColl: collId }),
    });

    return { collId, durationListResponse, priceTypeListResponse, articleListResponse, priceResponse };
  };

function EditPricePage() {
  const fetcher = useFetcher();
  const { t } = useTranslation(['parameters/priceList/editPrice', 'form']);
  const {
    priceResponse: { data: currentPrice },
  } = useLoaderData() as {
    durationListResponse?: AxiosResponse;
    articleListResponse?: AxiosResponse | null;
    priceTypeListResponse?: AxiosResponse;
    collId: number;
    priceResponse: AxiosResponse;
  };

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      type: currentPrice.articleType.articleParent.name,
      idArticleType: currentPrice.articleType.id,
      idDuration: currentPrice.duration.id,
      idPriceType: currentPrice.priceType.id,
      mntHT: currentPrice.mntHT,
      mntTTC: getAmountIncludingTaxes(Number(currentPrice.mntHT), currentPrice.tauxTVA),
      tauxTVA: currentPrice.tauxTVA,
      show: currentPrice.show,
      dates: {
        startDate: _.isString(currentPrice.startDate) ? moment(currentPrice.startDate) : null,
        endDate: _.isString(currentPrice.endDate) ? moment(currentPrice.endDate) : null,
      },
    },
  });
  const navigate = useNavigate();
  useDisplayError(fetcher.data);
  const snackbar = useSnackbar();
  useMainTitle(t('title'));

  const onSubmit = (data: FieldValues) => {
    const copyData = _(data)
      .chain()
      .assign(data.dates)
      .update('startDate', (value) => (_.eq(value, '') ? null : value))
      .update('endDate', (value) => (_.eq(value, '') ? null : value))
      .omit(['dates'])
      .value();

    fetcher.submit(
      { action: 'edit', id: currentPrice.id, ...copyData },
      { method: 'post', encType: 'application/json' }
    );
  };

  useEffect(() => {
    if (fetcher.data && fetcher.data.status === 200) {
      snackbar?.setAlert({
        message: t('successMessage'),
        severity: TOAST_SEVERITY.success,
      });
      navigate('/parametres/grille-tarifaire');
    }
  }, [fetcher.data, t]);

  return (
    <MainSection className={'reset'}>
      <Card
        content={
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <p>{t('form:all_required')}</p>
              <PriceForm isEdit />
              <div className={'row mt-4 justify-between items-center'}>
                <ButtonWithIcon
                  as={NavLink}
                  to={'/parametres/grille-tarifaire'}
                  icon={'CrossWithoutCircle'}
                  color={'secondary'}>
                  {t('common:cancel')}
                </ButtonWithIcon>
                <ButtonWithIcon icon={'CheckWithoutCircle'} type={'submit'}>
                  {t('parameters/priceList/editPrice:submitCta')}
                </ButtonWithIcon>
              </div>
            </form>

            <DevTool control={methods.control} />
          </FormProvider>
        }
      />
    </MainSection>
  );
}

export default EditPricePage;
