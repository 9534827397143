import React, { FieldsetHTMLAttributes, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { ActionFunction, json, LoaderFunction, useFetcher } from 'react-router-dom';
import { FetchQueryOptions, QueryClient, useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import axios, { AxiosResponse } from 'axios';
import { cloneDeep } from 'lodash';
import { DevTool } from '@hookform/devtools';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import MainSection from '../../components/Core/MainSection/MainSection';
import Card from '../../components/Core/Card/Card';
import useGenerateFields from '../../hook/useGenerateFields';
import store from '../../internal/store';
import { instance } from '../../api/user.api';
import { selectCollAndDeliveryPointID } from '../../auth/selectors/authSelectors';
import useDisplayError from '../../hook/useDisplayError';
import styles from './Fieldset.module.scss';
import ParkRawDataExport from './ParkRawDataExport';
import ExportButton from './ExportButton/ExportButton';
import RentalRawDataExport from './RentalRawDataExport';
import PaymentsRawDataExport from './PaymentsRawDataExport';

type FieldsetProps = PropsWithChildren<
  FieldsetHTMLAttributes<HTMLFieldSetElement> & {
    legend: string;
  }
>;

export function Fieldset({ legend, children, className }: FieldsetProps) {
  return (
    <fieldset className={[styles.Root, className].join(' ')}>
      <legend className={styles.Legend}>{legend}</legend>
      {children}
    </fieldset>
  );
}

const query: (queryParams: unknown) => FetchQueryOptions<AxiosResponse> = (queryParams) => ({
  queryKey: ['statistic/rawData', queryParams],
  queryFn: ({ queryKey }) => {
    const [, queryParams] = queryKey;
    return instance.get('statistic/rawData', { params: queryParams });
  },
});

export const rawDataLoader: (queryClient: QueryClient) => LoaderFunction = (queryClient) => () => {
  const collId = store.getState().authState.collectivity;
  queryClient.ensureQueryData({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryKey: query({ idColl: collId }).queryKey!,
    queryFn: query({ idColl: collId }).queryFn,
  });

  return { collId };
};

export const rawDataAction: (queryClient: QueryClient) => ActionFunction =
  () =>
  async ({ request }) => {
    const collId = store.getState().authState.collectivity;
    // const data = Object.fromEntries(await request.formData());
    const data = await request.json();

    let _copyData = cloneDeep(data);
    if ('dateStartContract' in _copyData) {
      Object.assign(_copyData, {
        dateStartContractMin: _copyData.dateStartContract.startDate,
        dateStartContractMax: _copyData.dateStartContract.endDate,
        dateEndContractMin: _copyData.dateEndContract.startDate,
        dateEndContractMax: _copyData.dateEndContract.endDate,
      });

      delete _copyData.dateStartContract;
      delete _copyData.dateEndContract;
    }

    if ('dates' in _copyData) {
      _copyData = _(_copyData)
        .assign({ ..._copyData.dates })
        .omit('dates')
        .value();
    }

    try {
      return await instance.post('statistic/exportRawData', { ..._copyData, idColl: collId });
    } catch (e) {
      console.error(e);
      if (axios.isAxiosError(e)) {
        return json(e.response?.data);
      }
    }

    // const response = new Promise<{ link: string }>((resolve) => setTimeout(resolve, 2000, { link: 'test' }));

    return {};
  };

function RawDataPage() {
  const { t } = useTranslation(['stats/rawData']);
  const fetcher = useFetcher();
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const { data } = useQuery({ ...query({ idColl: id_coll }), refetchOnWindowFocus: false });
  useDisplayError(fetcher.data);

  const exportTypeOptions = useMemo(() => {
    const copy = cloneDeep(data?.data);
    delete copy.format;
    return Object.keys(copy).map((key) => ({
      label: t(key),
      value: key,
      disabled: copy[key] === false ? true : undefined,
    }));
  }, [data, t]);

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      export: null,
      format: null,
      emplacements: [],
      pdl: [],
      articles: [] as unknown as { value: string; articles: string[] }[],
    },
  });
  const {
    formState: { isDirty },
    reset,
  } = methods;

  const fields = useGenerateFields({
    control: methods.control,
    fields: [
      {
        name: 'export',
        type: 'radio-item',
        big: true,
        box: true,
        options: exportTypeOptions,
      },
      {
        name: 'format',
        type: 'radio-item',
        box: true,
        options: [
          { label: t('fields.format.options.csv'), value: 'csv' },
          { label: t('fields.format.options.xlsx'), value: 'xlsx' },
        ],
      },
    ],
  });

  const exportTypeChoose = methods.watch('export');

  const [state, setState] = useState<string | null>(null);

  useEffect(() => {
    if (fetcher.state === 'idle' && fetcher.data) {
      setState('ready');
    }
  }, [fetcher]);

  useEffect(() => {
    if (fetcher.state === 'submitting') setState('loading');
  }, [fetcher.state]);

  useEffect(() => {
    if (isDirty) setState(null);
  }, [exportTypeChoose, isDirty]);

  useEffect(() => {
    if (exportTypeChoose) {
      methods.setValue('emplacements', data?.data[exportTypeChoose].emplacements);
      methods.setValue('pdl', data?.data[exportTypeChoose].pdl);
      methods.setValue(
        'articles',
        Object.entries(data?.data[exportTypeChoose].articles || {}).reduce((prev, currentValue) => {
          prev.push({ value: currentValue[0], articles: currentValue[1] as string[] });
          return prev;
        }, [] as { value: string; articles: string[] }[])
      );
    }
  }, [exportTypeChoose, data?.data]);

  const formatChoose = methods.watch('format');

  const onSubmit = (data: FieldValues) => {
    const copy = cloneDeep(data);
    const articles = (copy.articles as { value: string; articles: string[] }[]).reduce((prev, currentValue) => {
      Object.assign(prev, { [currentValue.value]: currentValue.articles });
      return prev;
    }, {} as Record<string, string[]>);
    delete copy.articles;
    const articleTypes = Object.keys(articles);

    if (articleTypes.some((articleType) => ['bike', 'accessory'].includes(articleType))) {
      delete copy.emplacements;
    }

    const manuels = true;

    fetcher.submit({ ...copy, articles, format: formatChoose, manuels}, { method: 'post', encType: 'application/json' });

    reset(data);
  };

  const onDownload = () => {
    const _csvFilename = `exports_stats_${dayjs().format('DD/MM/YYYY')}.${formatChoose}`;
    const link = document.createElement('a');

    link.href = fetcher.data.data.link as string;
    link.setAttribute('download', _csvFilename);
    document.body.appendChild(link);
    link.click();
  };

  return (
    <MainSection className={'reset'}>
      <Card color={'primary'} content={<>{t('advice')}</>} />
      <Card
        content={
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Fieldset legend={t('typeSection')}>{fields[0]}</Fieldset>
              {exportTypeChoose === 'parc' && <ParkRawDataExport data={data?.data.parc} />}
              {exportTypeChoose === 'reservations' && <RentalRawDataExport data={data?.data.reservations} />}
              {exportTypeChoose === 'payments' && <PaymentsRawDataExport data={data?.data.payments} />}

              {exportTypeChoose && (
                <Fieldset legend={t('formatSection')} className={'mt-4'}>
                  {fields[1]}
                  {formatChoose && (
                    <ExportButton
                      className={'mt-4'}
                      state={state}
                      isLoading={fetcher.state === 'submitting'}
                      link={fetcher.data}
                      onDownload={onDownload}
                    />
                  )}
                </Fieldset>
              )}
            </form>

            <DevTool control={methods.control} />
          </FormProvider>
        }
      />
    </MainSection>
  );
}

export default RawDataPage;
