/**
 * Transform Base64 string to File
 * @param {string} dataURL Base64 string
 * @param {string} filename Filename
 * @returns {Promise<File>} File in Promise
 */
const dataURLtoFile = async (dataURL: string, filename: string): Promise<File> => {
  const res = await fetch(dataURL);
  const blob = await res.blob();
  const mimeType = detectMimeType(dataURL);
  return new File([blob], filename, { type: mimeType });
};

/**
 * Detect Mime Type in Base64 string
 * @param base64 Base64 string
 * @returns {string} Mime Type detected
 */
const detectMimeType = (base64: string): string => {
  return base64.split(';')[0].split('/')[1];
};

export { dataURLtoFile };
