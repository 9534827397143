import React, { ComponentPropsWithoutRef, useCallback, useRef } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import { postBookingSign, PostSignatureBodyForm } from '../../../../api/booking.api';
import { selectCollAndDeliveryPointID } from '../../../../auth/selectors/authSelectors';
import ButtonWithIcon from '../../../../components/Core/Button/ButtonWithIcon';
import Label from '../../../../components/Core/Label/Label';
import Modal from '../../../../components/Core/Modal/Modal';
import { BASE64_HEAD_REGEXP } from '../../../../internal/utils/file';
import styles from '../../bookingDetails.module.scss';

function SignatureModal(props: ComponentPropsWithoutRef<typeof Modal>) {
  const { t } = useTranslation(['reservation/components/signature', 'common']);
  const { bookingId } = useParams();
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const signCanvas = useRef<SignatureCanvas | null>(null);
  const queryClient = useQueryClient();

  const { mutate: handlePostBookingSign } = useMutation(
    ['booking.post.signature'],
    (data: PostSignatureBodyForm) => {
      return postBookingSign(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['signatureBooking']);
        props.setIsOpen(false);
      },
    }
  );

  const onSubmit = useCallback(() => {
    if (signCanvas.current && bookingId) {
      handlePostBookingSign({
        id_resa: bookingId,
        id_coll,
        sign: signCanvas.current.getTrimmedCanvas().toDataURL('image/png').replace(BASE64_HEAD_REGEXP, ''),
      });
    }
  }, [id_coll, bookingId, signCanvas.current]);

  return (
    <Modal className={styles.assignModal} title={t('modalTitle')} {...props}>
      <div className={styles.formControl}>
        <Label>{t('signatureField.label')}</Label>
        <SignatureCanvas
          ref={signCanvas}
          canvasProps={{ width: 500, height: 250, className: styles.signatureModal__canvas }}
        />
      </div>

      <div className={styles.assignModal__footer}>
        <ButtonWithIcon icon={'CrossWithoutCircle'} color={'secondary'} onClick={() => props.setIsOpen(false)}>
          {t('common:cancel')}
        </ButtonWithIcon>
        <ButtonWithIcon onClick={onSubmit} icon={'CheckWithoutCircle'}>
          {t('common:validate')}
        </ButtonWithIcon>
      </div>
    </Modal>
  );
}

export default SignatureModal;
