import { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryFunction } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RawGenericListReturn } from '../../../api/params/vouchers.api';
import { fetchUserContracts } from '../../../api/user.api';
import { selectCollAndDeliveryPointID, selectSessionToken } from '../../../auth/selectors/authSelectors';
import IconButton from '../../../components/Core/Button/IconButton';
import { RowItem } from '../../../components/Core/Datagrid/Datagrid';
import { GridColDefinition } from '../../../components/Core/Datagrid/gridCols';
import MainSection from '../../../components/Core/MainSection/MainSection';
import GenericList from '../../Parameters/Voucher/components/GenericList';
import { ListParams } from '../../park/BikeList/BikeList';

function useContractListColumnDefinitions() {
  const { t } = useTranslation('contracts/invoiceList');
  const token = useSelector(selectSessionToken);

  return useMemo<GridColDefinition[]>(() => {
    return [
      {
        type: 'custom',
        field: 'date_start',
        headerName: t('dateContract'),
        sortable: true,
        renderCell: ({ row }) => {
          return (
            <RowItem>
              {dayjs(row.date_start).format('DD/MM/YYYY')} - {dayjs(row.date_end).format('DD/MM/YYYY')}
            </RowItem>
          );
        },
      },
      {
        type: 'string',
        field: 'operation',
        headerName: t('operation'),
        sortable: true,
      },
      {
        type: 'custom',
        field: '',
        headerName: '',
        renderCell: ({ row }) => {
          return (
            <RowItem style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {row.date_anonymization ? (
                <span>
                  <i>{t('archived')}</i>
                </span>
              ) : (
                <IconButton as={'a'} target={'_blank'} href={`${row.media_url}&token=${token}`} icon={'UploadIcon'} />
              )}
            </RowItem>
          );
        },
      },
    ];
  }, [t, token]);
}

function ContractListPage() {
  const { t } = useTranslation('user/userLayout');
  const { userId } = useParams();
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const columns = useContractListColumnDefinitions();

  const fetchContracts: QueryFunction<AxiosResponse<RawGenericListReturn> | undefined> = ({ queryKey }) => {
    const [, listParams] = queryKey as [string, ListParams & { id_coll: number; id_user?: number }];

    if (listParams.id_user) {
      return fetchUserContracts({
        id_coll: listParams.id_coll,
        id_user: listParams.id_user,
        ipp: listParams.itemPerPage,
        p: listParams.currentPage,
        ...listParams.filters,
      });
    }
  };

  const transformFn = useCallback((dataResponse?: AxiosResponse) => {
    //eslint-disable-next-line
    return { rows: (dataResponse?.data as any) || [] };
  }, []);

  return (
    <MainSection>
      <GenericList
        title={t('contractListTitle')}
        transformFn={transformFn}
        queryFn={fetchContracts}
        queryKey={['user.contract.list', { id_coll, id_user: userId }]}
        columns={columns}
      />
    </MainSection>
  );
}

export default ContractListPage;
