import { SERVICE_KEYS } from 'ioc/keys';
import { inject, injectable } from 'inversify';
import { AxiosResponse } from 'axios';
import { Api } from '../api/axios';

const ENDPOINTS = {
  getAllBookings: 'cf/reservations',
};

export type GetAllBookingsQueryParams = {
  id_coll: number;
  p: number;
  ipp: number;
  filter: string;
  'order[column]': string;
  'order[dir]': string;
  filterStatus?: string;
};

export type GetAllBookingsResult = {
  currentPage: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filters: any;
  itemPerPage: number;
  order: {
    column: string;
    dir: string;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  result: any;
  total: number;
  totalPage: number;
};

@injectable()
export class ContractRepository {
  @inject(SERVICE_KEYS.API_INSTANCE)
  private _apiInstance: Api | undefined;

  getAllBookings(
    queryParams: Partial<GetAllBookingsQueryParams>
  ): Promise<AxiosResponse<GetAllBookingsResult>> | undefined {
    return this._apiInstance?.instance.get(ENDPOINTS.getAllBookings, { params: queryParams });
  }
}
