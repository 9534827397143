import { selectCollAndDeliveryPointID } from 'auth/selectors/authSelectors';
import Button from 'fragments/button/Button';
import { EDIT, RESA_DETAIL, USERS } from 'internal/constants/routes';
import useApi, { Methods } from 'internal/hooks/useApi';
import { t } from 'internal/i18n';
import { BASE64_HEAD_REGEXP } from 'internal/utils/file';
import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import { SIGN_RESA_ENDPOINT } from 'reservations/constants/endpoints';
import { USER_DETAILS_ENDPOINT } from 'users/constants/endpoints';
import { UserDetailsType } from 'users/types/users';

const ReservationSignature = (): ReactElement => {
  const navigate = useNavigate();
  const signCanvas = useRef<SignatureCanvas | null>(null);
  const [id_resa, setResaID] = useState('');
  const [id_user, setUserID] = useState('');

  const { id_coll } = useSelector(selectCollAndDeliveryPointID);

  const { request: postSignature, response: postSignatureRes } = useApi(Methods.POST, SIGN_RESA_ENDPOINT);
  const { request: fetchUser, response: userResponse } = useApi<UserDetailsType>(Methods.GET, USER_DETAILS_ENDPOINT);

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      const resaID = params.get('resaID') || '';
      const userID = params.get('userID') || '';

      setResaID(resaID);
      setUserID(userID);
    }
  }, [location.search]);

  useEffect(() => {
    if (id_coll && id_user) {
      const params = {
        params: {
          id_coll,
          id_user: id_user,
        },
      };

      fetchUser(params);
    }
  }, [fetchUser, id_coll, id_user]);

  const { name, firstName } = useMemo(() => {
    if (userResponse?.success && userResponse.data) {
      return {
        name: userResponse.data.nom,
        firstName: userResponse.data.prenom,
      };
    }
    return {
      name: '',
      firstName: '',
    };
  }, [userResponse]);

  const handleClearSign = useCallback(() => {
    if (signCanvas.current) {
      signCanvas.current.clear();
    }
  }, [signCanvas]);

  const handleSubmitSign = useCallback(() => {
    if (signCanvas.current) {
      postSignature({
        id_resa,
        id_coll,
        sign: signCanvas.current.getTrimmedCanvas().toDataURL('image/png').replace(BASE64_HEAD_REGEXP, ''),
      });
    }
  }, [signCanvas, id_resa, id_coll]);

  useEffect(() => {
    if (postSignatureRes?.success) {
      navigate(`${USERS}${RESA_DETAIL}?userID=${id_user}&resaID=${id_resa}`);
    }
  }, [postSignatureRes]);

  return (
    <div className={'main-content'}>
      <div className={'card'}>
        <h4 className={'card-title'} id={'nomprenom'}>
          <Link to={`${USERS}${EDIT}?userID=${id_user}`}>
            <Trans
              i18nKey={'reservations.client_name_sign'}
              values={{
                firstName,
                name,
              }}
              components={{ st: <strong /> }}
            />
          </Link>
        </h4>
        <div className={'card-body'}>
          <div className={'row'}>
            <div className={'col-md-12'}>
              <SignatureCanvas
                penColor={'blue'}
                ref={signCanvas}
                canvasProps={{ width: 500, height: 180, className: 'sign-canvas' }}
              />
              ,
            </div>
          </div>
          <div className={'row'}>
            <div className={'col-md-6'}>
              <Button
                label={t('reservations.clear')}
                className={'btn btn-primary clearsign'}
                onClick={handleClearSign}
              />
              <Button
                label={t('reservations.valid_sign')}
                className={'btn btn-primary sendsign'}
                onClick={handleSubmitSign}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReservationSignature;
