import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FetchQueryOptions, QueryClient, useQuery, WithRequired } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { ActionFunction, Await, defer, json, LoaderFunction, useFetcher, useLoaderData } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragStartEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers';
import { shallowEqual, useSelector } from 'react-redux';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { clone } from 'lodash';
import { useDebounce } from 'use-debounce';
import { instance } from '../../../api/user.api';
import store from '../../../internal/store';
import { GridColDefinition } from '../../../components/Core/Datagrid/gridCols';
import Datagrid, { RowItem } from '../../../components/Core/Datagrid/Datagrid';
import { GridValidRowModel } from '../../../components/Core/Datagrid/gridRows';
import MainSection from '../../../components/Core/MainSection/MainSection';
import Card from '../../../components/Core/Card/Card';
import ButtonWithIcon from '../../../components/Core/Button/ButtonWithIcon';
import { selectCollAndDeliveryPointID } from '../../../auth/selectors/authSelectors';
import { GridLinesIcon } from '../../../const/icons';
import Dialog, { DialogActions, DialogContent, DialogTitle } from '../../../components/Dialog/Dialog';
import { TOAST_SEVERITY } from '../../../components/Core/Toast/Toast';
import { useSnackbar } from '../../../context/SnackbarContext';
import useDisplayError from '../../../hook/useDisplayError';
import styles from './DurationList.module.scss';
import NewPriceType, { PriceTypeForm } from './NewPriceType';

export const priceTypeListQuery: (
  queryParams: unknown
) => WithRequired<FetchQueryOptions<AxiosResponse>, 'queryFn' | 'queryKey'> = (queryParams) => ({
  queryKey: ['pricetype/list', queryParams],
  queryFn: ({ queryKey }) => {
    const [apiUrl, queryParams] = queryKey;
    return instance.get(apiUrl as string, { params: queryParams });
  },
});

export const priceTypeListAction: (queryClient: QueryClient) => ActionFunction =
  (queryClient) =>
  async ({ request }) => {
    const collId = store.getState().authState.collectivity;
    const { type, ...data } = await request.json();
    let response = null;

    try {
      switch (type) {
        case 'updateOrder':
          response = await instance.post('pricetype/updateOrder', data.items);
          if (response.status === 200) await queryClient.invalidateQueries(['pricetype/list']);
          break;
        case 'new':
          response = await instance.post('pricetype/new', { ...data, idColl: collId });
          if (response.status === 200) await queryClient.invalidateQueries(['pricetype/list']);
          break;
        case 'edit':
          response = await instance.post('pricetype/edit', { ...data, idColl: collId });
          if (response.status === 200) await queryClient.invalidateQueries(['pricetype/list']);
          break;
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        console.error(e);
        return json(e.response?.data);
      }
    }

    return response;
  };

export const priceTypeListLoader: (queryClient: QueryClient) => LoaderFunction = (queryClient) => () => {
  const collId = store.getState().authState.collectivity;

  const priceTypeListResponse = queryClient.ensureQueryData({
    ...priceTypeListQuery({ idColl: collId }),
    staleTime: 3000_000,
  });

  return defer({ collId, priceTypeListResponse });
};

type UpdatePriceTypeProps = {
  row: GridValidRowModel;
};

function UpdatePriceType({ row }: UpdatePriceTypeProps) {
  const fetcher = useFetcher();
  const { t } = useTranslation(['parameters/priceList/priceTypeList', 'common']);
  const [open, setIsOpen] = useState(false);
  const snackbar = useSnackbar();
  useDisplayError(fetcher.data);
  const methods = useForm({ defaultValues: row, mode: 'onChange' });
  const { reset } = methods;

  const onSubmit = (data: FieldValues) => {
    fetcher.submit({ type: 'edit', ...data }, { method: 'post', encType: 'application/json' });
  };

  const onCancel = useCallback(() => {
    setIsOpen(false);
    reset(row);
  }, [reset, setIsOpen]);

  useEffect(() => {
    if (fetcher.data && fetcher.data.status === 200 && fetcher.data.config.url === 'pricetype/edit') {
      snackbar?.setAlert({
        message: t('editSuccessfullyMessage'),
        severity: TOAST_SEVERITY.success,
      });
      onCancel();
    }
  }, [fetcher.data, onCancel, t]);

  return (
    <>
      <ButtonWithIcon
        color={'secondary'}
        icon={'EditIcon'}
        onClick={(e: MouseEvent) => {
          e.preventDefault();
          setIsOpen(true);
        }}>
        {t('common:edit')}
      </ButtonWithIcon>
      {open && (
        <Dialog style={{ minWidth: 600 }} onCancel={onCancel} open={open}>
          <DialogTitle>{t('updateModal.title')}</DialogTitle>
          <FormProvider {...methods}>
            <form onSubmit={methods?.handleSubmit(onSubmit)}>
              <DialogContent>
                <Card className={'mb-4'} color={'primary'} content={<>{t('updateModal.advice')}</>} />
                <span className={'block mb-4'}>{t('form:all_required')}</span>
                <PriceTypeForm />
              </DialogContent>
              <DialogActions>
                <ButtonWithIcon onClick={onCancel} icon={'CrossWithoutCircle'} color={'secondary'} type={'button'}>
                  {t('common:cancel')}
                </ButtonWithIcon>
                <ButtonWithIcon icon={'CheckWithoutCircle'} type={'submit'}>
                  {t('updateModal.submitCta')}
                </ButtonWithIcon>
              </DialogActions>
            </form>
          </FormProvider>
        </Dialog>
      )}
    </>
  );
}

function useColumns(): GridColDefinition[] {
  const { t } = useTranslation(['parameters/priceList/priceTypeList', 'common']);

  const getColumnText = useCallback(
    (key: string) => {
      return t('columns.'.concat(key));
    },
    [t]
  );

  return useMemo(
    () => [
      {
        field: '',
        type: 'custom',
        renderCell: () => <GridLinesIcon width={14} />,
        width: 26,
      },
      {
        field: 'name',
        type: 'string',
        headerName: getColumnText('name'),
        width: '1fr',
      },
      {
        field: '',
        type: 'actions',
        renderCell: ({ row }) => (
          <div className={'row justify-end items-center w-100'}>
            <UpdatePriceType row={row} />
          </div>
        ),
        width: 'auto',
      },
      {
        field: '',
        type: 'custom',
        renderCell: () => <GridLinesIcon className={'ml-2'} width={14} />,
        width: 'auto',
      },
    ],
    [t]
  );
}

type DraggableRowProps = {
  columns: GridColDefinition[];
  row: GridValidRowModel;
  index: number;
  colsTemplate: string;
};

export function DraggableRow(props: DraggableRowProps) {
  const { row, columns, index, colsTemplate } = props;

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: row.position + 1,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    touchAction: 'none',
    gridTemplateColumns: colsTemplate,
    display: 'grid',
    padding: 16,
    gap: 8,
    background: 'white',
    borderRadius: 4,
    cursor: isDragging ? 'grabbing' : 'grab',
    border: 'solid 1px #ebebeb',
  };

  const buildCell = useCallback(
    (column: GridColDefinition, index: number) => {
      if (column.type === 'actions') {
        return (
          <RowItem key={'actions-' + column.field + index + row.id}>
            {column.renderCell({
              row,
              index,
            })}
          </RowItem>
        );
      }

      if (column.type === 'custom') {
        return (
          <RowItem key={'custom-' + column.field + index + row.id} {...attributes} {...listeners}>
            {column.renderCell({
              row,
              index,
              custom: '',
            })}
          </RowItem>
        );
      }

      return (
        <RowItem key={'value-' + column.field + index + row.id} {...attributes} {...listeners}>
          <span>{row[column.field]}</span>
        </RowItem>
      );
    },
    [row, attributes, listeners]
  );

  return (
    <div key={'line'.concat(index.toString())} ref={setNodeRef} style={style}>
      {columns.map((column, index) => buildCell(column, index))}
    </div>
  );
}

function SortablePriceTypeList() {
  const { t } = useTranslation(['parameters/priceList/priceList', 'common']);
  const fetcher = useFetcher();
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const snackbar = useSnackbar();
  const [items, setItems] = useState<{ name: string; type: string; position: number; id: number }[]>([]);
  const { data: priceTypeListResponseResolve } = useQuery<AxiosResponse>({
    ...priceTypeListQuery({ idColl: id_coll }),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
  const [, setActiveId] = useState<number | null>(null);
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    setItems(clone(priceTypeListResponseResolve?.data) || []);
  }, [priceTypeListResponseResolve]);

  function handleDragStart(event: DragStartEvent) {
    const { active } = event;

    setActiveId(Number(active.id));
  }

  useEffect(() => {
    if (fetcher.data && fetcher.data.status === 200 && fetcher.data.config.url === 'pricetype/updateOrder') {
      snackbar?.setAlert({
        message: t('orderUpdatedSuccessfullyMessage'),
        severity: TOAST_SEVERITY.success,
      });
    }
  }, [fetcher.data, t]);

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (over && active.id !== over?.id) {
      setItems((items) => {
        const oldIndex = items.findIndex((item) => item.position + 1 === active.id);
        const newIndex = items.findIndex((item) => item.position + 1 === over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }

    setActiveId(null);
  }

  function reset() {
    setItems(priceTypeListResponseResolve?.data || []);
  }

  const isDirty = useMemo(() => {
    return priceTypeListResponseResolve?.data && items.length > 0
      ? !shallowEqual(items, priceTypeListResponseResolve.data)
      : false;
  }, [items, priceTypeListResponseResolve]);

  const [isDirtyDebounced] = useDebounce(isDirty, 100);

  const columns = useColumns();

  const handleUpdateOrderList = () => {
    fetcher.submit(
      {
        type: 'updateOrder',
        items: items.reduce((prev, currentItem, currentIndex) => {
          Object.assign(prev, { [currentItem.id]: currentIndex });
          return prev;
        }, {} as Record<string, string>),
      },
      { method: 'post', encType: 'application/json' }
    );
  };

  return (
    <>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        modifiers={[restrictToFirstScrollableAncestor]}>
        <SortableContext items={items.map((item) => ({ id: item.position + 1 }))}>
          <Datagrid className={styles.List} RowComponent={DraggableRow} rows={items} columns={columns} />
        </SortableContext>
      </DndContext>
      {isDirtyDebounced && (
        <div className={'row items-center p-16 justify-between'}>
          <ButtonWithIcon color={'secondary'} icon={'CrossWithoutCircle'} onClick={reset}>
            {t('cancel')}
          </ButtonWithIcon>
          <ButtonWithIcon icon={'EditIcon'} onClick={handleUpdateOrderList}>
            {t('apply')}
          </ButtonWithIcon>
        </div>
      )}
    </>
  );
}

function TypePriceListPage() {
  const { t } = useTranslation('parameters/priceList/priceTypeList');
  const { priceTypeListResponse } = useLoaderData() as {
    priceTypeListResponse?: AxiosResponse;
    collId: number;
  };

  return (
    <>
      <NewPriceType />
      <MainSection>
        <Card color={'primary'} content={<>{t('advice')}</>} />
        <Card>
          <Await resolve={priceTypeListResponse}>
            <SortablePriceTypeList />
          </Await>
        </Card>
      </MainSection>
    </>
  );
}

export default TypePriceListPage;
