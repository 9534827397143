import React, { ReactElement, useCallback, useMemo } from 'react';
import { generateUniqueKey } from 'internal/utils/componentUtils';
import { tableSections } from 'fund/constants/table';
import { FundElementType, FundSubElementType } from 'fund/types/fund';
import { useTranslation } from 'react-i18next';

type Props = {
  fund?: {
    [key: string]: FundElementType;
  };
};

const FundTable = ({ fund }: Props): ReactElement | null => {
  const { t } = useTranslation('fund');

  const renderTableSectionRow = useCallback(
    (fundSectionRow: { [key: string]: FundSubElementType }, isTotal: boolean) => {
      const fundSectionRowKeys = Object.keys(fundSectionRow);

      return fundSectionRowKeys.map((fundSectionKey) => {
        const key = generateUniqueKey();

        return (
          <tr key={key}>
            <td></td>
            <td>{!isTotal ? <>{t(fundSectionKey)}</> : <strong>{t(fundSectionKey)}</strong>}</td>
            <td id={'location_velo_cb_internet_ht'}>{fundSectionRow[fundSectionKey].ht}</td>
            <td id={'location_velo_cb_internet_ttc'}>{fundSectionRow[fundSectionKey].ttc}</td>
          </tr>
        );
      });
    },
    [fund]
  );

  const renderFundRow = useMemo(() => {
    if (fund) {
      return tableSections.map((section) => {
        const fundSection = fund[section];

        if (fundSection) {
          return (
            <>
              <tr key={section}>
                <td colSpan={4}>
                  <strong>{t(section)}</strong>
                </td>
              </tr>
              {renderTableSectionRow(fundSection, section === 'totauxg')}
            </>
          );
        }
      });
    }
    return null;
  }, [fund, t]);

  if (!fund) {
    return null;
  }

  return (
    <table className={'table table-striped table-bordered'}>
      <thead>
        <tr>
          <th>&nbsp;</th>
          <th>{t('payment_solution')}</th>
          <th>{t('total')}</th>
          <th>{t('total_VAT')}</th>
        </tr>
      </thead>
      <tbody>{renderFundRow}</tbody>
    </table>
  );
};

export default FundTable;
