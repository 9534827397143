import Button from 'fragments/button/Button';
import Select from 'fragments/select/Select';
import { t } from 'internal/i18n';
import React, { useMemo } from 'react';
import { ReservationType } from 'reservations/types/reservation';

type Props = {
  hasCheckout: boolean;
  handleOpenCheckout: () => void;
  finishRent: () => void;
  isPark: boolean;
  userSign: string;
  reservation: ReservationType | null;
  setFinishState: React.Dispatch<React.SetStateAction<string>>;
};

const ReservationFinish = ({
  hasCheckout,
  handleOpenCheckout,
  finishRent,
  isPark,
  userSign,
  reservation,
  setFinishState,
}: Props) => {
  const renderParkOrBikeFinish = useMemo(() => {
    const action = hasCheckout ? handleOpenCheckout : finishRent;

    if (isPark && userSign && reservation?.date_valid) {
      return (
        <>
          <div className={'row'} id={'retour_reservation_cons'}>
            <div className={'col-md-12'}>
              <Select
                name={'end-park-rent'}
                id={'end-park-rent'}
                label={t('reservations.end_park')}
                options={[
                  { value: 'pdl', label: t('reservations.available_end') },
                  { value: 'repare', label: t('reservations.unavailable_end') },
                ]}
                handleChange={setFinishState}
              />
              <Button label={t('reservations.end_park_button')} className={'btn-primary btn-fit'} onClick={action} />
            </div>
          </div>
          <br />
        </>
      );
    }

    if (!reservation?.date_valid) {
      return null;
    }

    return (
      <>
        <div className={'row'} id={'retour_reservation_velo'}>
          <div className={'col-md-12'}>
            <Select
              name={'end-rent'}
              id={'end-rent'}
              label={t('reservations.end_rent')}
              options={[
                { value: 'pdl', label: t('reservations.pdl_option') },
                { value: 'repare', label: t('reservations.repare_option') },
                { value: 'vole', label: t('reservations.stolen_option') },
              ]}
              handleChange={setFinishState}
            />
            <Button label={t('reservations.end_rent_button')} className={'btn-primary btn-fit'} onClick={action} />
          </div>
        </div>
        <br />
      </>
    );
  }, [setFinishState, finishRent, isPark, userSign, reservation, hasCheckout]);

  return <>{renderParkOrBikeFinish}</>;
};

export default ReservationFinish;
