import Button from 'fragments/button/Button';
import Select from 'fragments/select/Select';
import { t } from 'internal/i18n';
import React, { useMemo } from 'react';
import { ReservationType } from 'reservations/types/reservation';

type Props = {
  reservation: ReservationType | null;
  isInWaitingList: boolean;
  isPark: boolean;
  otherBikesShown: boolean;
  submitBikeChange: () => void;
  showOtherBikes: () => void;
  setNewBikeID: React.Dispatch<React.SetStateAction<string>>;
  otherBikes: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    label: any;
    value: string;
  }[];
  articleNotAttributed: boolean;
  contract: {
    url: string;
    label: string;
  } | null;
};

const ReservationOtherBikes = ({
  isPark,
  isInWaitingList,
  otherBikesShown,
  submitBikeChange,
  showOtherBikes,
  setNewBikeID,
  otherBikes,
  articleNotAttributed,
  reservation,
  contract,
}: Props) => {
  const renderOtherBikesSelection = useMemo(() => {
    if (isPark || reservation?.isVelocareSynced || contract || articleNotAttributed || isInWaitingList) {
      return null;
    }

    if (!otherBikesShown) {
      return (
        <span className={'cursor-pointer link'} onClick={showOtherBikes}>
          {t('reservations.issue')}
        </span>
      );
    }

    return (
      <div className={'row'}>
        <div className={'col-md-6'}>
          <Select
            label={''}
            id={'other-bike'}
            name={'other-bike'}
            options={otherBikes}
            handleChange={setNewBikeID}
            noWrapper
          />
        </div>
        <div className={'col-md-4'}>
          <Button
            label={t('reservations.change_bike')}
            className={'btn btn-primary changeresa ptn-fit'}
            onClick={submitBikeChange}
          />
        </div>
      </div>
    );
  }, [
    isPark,
    otherBikesShown,
    submitBikeChange,
    showOtherBikes,
    setNewBikeID,
    otherBikes,
    articleNotAttributed,
    contract,
    reservation?.isVelocareSynced,
    isInWaitingList,
  ]);

  return <>{renderOtherBikesSelection}</>;
};

export default ReservationOtherBikes;
