import styled from '@emotion/styled';
import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getBooking } from '../../api/reservation.api';
import { selectCollAndDeliveryPointID } from '../../auth/selectors/authSelectors';
import BookingCard, { RawBooking } from '../../components/BookingCard/BookingCard';
import Card from '../../components/Core/Card/Card';
import MainSection from '../../components/Core/MainSection/MainSection';
import TextField from '../../components/Core/TextField';
import { getColor } from '../../utils/style';
import Actions from './components/Actions';

function InfosReservationPage() {
  const { t } = useTranslation('reservation/reservation');
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const location = useLocation();

  const [id_resa, setResaID] = useState('');
  const [id_user, setUserID] = useState('');

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      const resaID = params.get('resaID') || '';
      const userID = params.get('userID') || '';

      setResaID(resaID);
      setUserID(userID);
    }
  }, [location.search]);

  const { data: booking } = useQuery(['reservation', { id_resa, id_user, id_coll }], ({ queryKey }) => {
    const [, params] = queryKey as [string, { id_user: string }];
    if (params.id_user !== '') {
      //eslint-disable-next-line
      return getBooking(params as any);
    }
  });

  const lines = useMemo(() => {
    return [
      ['nom', 'prenom'],
      'id_interne',
      'id_parent_resa',
      'nom_type',
      ['montant_caution_ttc', 'devise'],
      'nom_pdl',
      'nom_tarif',
    ].map((key) => {
      const values = Array.isArray(key)
        ? key.reduce((prev, keyName) => ({ ...prev, [keyName]: booking?.data?.[keyName as keyof RawBooking] }), {})
        : { [key]: booking?.data[key as keyof RawBooking] };

      return Object.values(values).some((value) => value === undefined || value === null) ? null : (
        <Grid columns={'1fr'} gap={8}>
          <Trans
            i18nKey={'lines.'.concat(Array.isArray(key) ? key[0] : key)}
            t={t}
            values={values}
            components={{ blue: <BigText />, uppercase: <Upper />, bold: <BoldText /> }}
          />
        </Grid>
      );
    });
  }, [booking?.data, t]);

  return (
    <MainSection>
      <Grid align={'flex-start'}>
        <Grid columns={'1fr'}>
          <Card>
            <Grid columns={'1fr'} rows={'auto 1fr'} justify={'space-between'}>
              <Grid columns={'repeat(2, 1fr)'}>{lines}</Grid>
              <Grid columns={'1fr'} align={'flex-end'}>
                <TextField label={'Commentaire'} />
              </Grid>
            </Grid>
          </Card>
          <Actions booking={booking?.data} />
        </Grid>

        <BookingCard />
      </Grid>
    </MainSection>
  );
}

export const Grid = styled.div<{ columns?: string; rows?: string; gap?: number; justify?: string; align?: string }>`
  display: grid;
  height: 100%;
  grid-template-rows: ${({ rows = '' }) => rows};
  grid-template-columns: ${({ columns = '1fr auto' }) => columns};
  gap: ${({ gap = 16 }) => `${gap}px`};
  justify-content: ${({ justify = '' }) => justify};
  align-items: ${({ align = '' }) => align};
`;

const BigText = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: ${getColor('primary.200')};
  margin: 0;
`;

const BoldText = styled.strong`
  font-weight: bold;
  margin: 0;
`;

const Upper = styled.span`
  text-transform: uppercase;
`;

export default InfosReservationPage;
