import React, { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AxiosResponse } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { filtersQuery } from '../Indicator.page';
import { BikeIcon, FreeParkIcon, ParkIcon, PinIcon } from '../../../const/icons';
import { selectCollAndDeliveryPointID } from '../../../auth/selectors/authSelectors';
import styles from './FiltersInfos.module.scss';

export const contentInfoMap = new Map([
  ['bike', { icon: <BikeIcon width={8} />, tKey: 'bike' }],
  ['consigne', { icon: <ParkIcon width={8} />, tKey: 'park' }],
  ['consignePark', { icon: <FreeParkIcon width={8} />, tKey: 'freePark' }],
]);

function FiltersInfos() {
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const [searchParams] = useSearchParams();

  const { data: filtersResponse } = useQuery<AxiosResponse>({
    ...filtersQuery({
      idColl: id_coll,
    }),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });

  const { t } = useTranslation(['stats/indicator']);

  const pdlName = useMemo(() => {
    const pdlId = Number(searchParams.get('pdl'));
    return pdlId ? filtersResponse?.data.pdl[pdlId] : '$t(common:filters.all)';
  }, [searchParams, filtersResponse]);

  const emplName = useMemo(() => {
    const emplId = Number(searchParams.get('emplacements'));
    return emplId ? filtersResponse?.data.emplacements[emplId] : '$t(common:filters.all)';
  }, [searchParams, filtersResponse]);

  return (
    <div className={styles.Root}>
      <div className={styles.Group}>
        {contentInfoMap.get(searchParams.get('articles') || 'bike')?.icon}
        <span>
          {t('filtersInfos.content', {
            // eslint-disable-next-line
            article: `$t(common:${contentInfoMap.get(searchParams.get('articles') || 'bike')!.tKey})`,
          })}
        </span>
      </div>
      <div className={styles.Group}>
        <PinIcon width={8} />
        <span>{t('filtersInfos.pdl', { pdlName })}</span>
      </div>
      <div className={styles.Group}>
        <PinIcon width={8} />
        <span>{t('filtersInfos.empl', { emplName })}</span>
      </div>
    </div>
  );
}

export default FiltersInfos;
