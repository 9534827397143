import React from 'react';
import { generatePath, NavLink, Outlet } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TabComponent } from '../../Reservation/Reservation.layout';
import { Tab, Tabs, TabsList } from '../../../components/Core/Tabs/Tabs';
import { selectCollAndDeliveryPointID } from '../../../auth/selectors/authSelectors';
import { instance } from '../../../api/user.api';

function CollectivityLayout() {
  const { t } = useTranslation(['parameters/collectivity']);
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const { data: collResponse } = useQuery(
    ['param/coll', { id_coll }],
    ({ queryKey }) => {
      const [, queryParams] = queryKey;
      return instance.get('param/coll', { params: queryParams });
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 400_000,
    }
  );

  return (
    <div className={'reset'}>
      <div>
        <Tabs>
          <TabsList>
            <Tab
              component={({ children, ...otherProps }: TabComponent) => (
                <NavLink {...otherProps} to={generatePath('.')} end>
                  {children}
                </NavLink>
              )}>
              {t('layout.global')}
            </Tab>
            <Tab
              component={({ children, ...otherProps }: TabComponent) => (
                <NavLink {...otherProps} to={generatePath('pdl-list')} end>
                  {children}
                </NavLink>
              )}>
              {t('layout.pdl')}
            </Tab>
            <Tab
              component={({ children, ...otherProps }: TabComponent) => (
                <NavLink {...otherProps} to={generatePath('empl-list')} end>
                  {children}
                </NavLink>
              )}>
              {t('layout.empl')}
            </Tab>
          </TabsList>
        </Tabs>
      </div>

      <Outlet context={collResponse} />
    </div>
  );
}

export default CollectivityLayout;
