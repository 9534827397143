import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import validator from 'validator';
import { useQueryClient } from '@tanstack/react-query';
import Button from '../../../fragments/button/Button';
import MainSection from '../../../components/Core/MainSection/MainSection';
import { selectCollAndDeliveryPointID } from '../../../auth/selectors/authSelectors';
import useApi, { Methods } from '../../../internal/hooks/useApi';
import { PARAM_COLL_ENDPOINT } from '../../../parameters/constants/endpoints';
import useForm from '../../../internal/hooks/useForm';
import { collParamForm } from '../../../parameters/constants/forms';
import { setSnackbar, showSnackbar } from '../../../internal/actions/appActions';
import SnackbarSeverity from '../../../internal/constants/snackbar';
import { locales } from '../../../internal/constants/locales';
import Input from '../../../fragments/input/Input';

function CollectivityGlobalParamsPage() {
  //eslint-disable-next-line
  const collResp: any = useOutletContext();
  const { t } = useTranslation(['old']);
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { request: submitColl, response: submitCollResp } = useApi(Methods.POST, PARAM_COLL_ENDPOINT);

  const [homepageText, setHomePageText] = useState<Record<string, string>>();

  const [coll, , _homepageText] = useMemo(() => {
    if (collResp.data) {
      return [collResp.data.coll, collResp.data.pdl, collResp.data.homepageText];
    }
    return [null, null];
  }, [collResp]);

  useEffect(() => {
    if (_homepageText) {
      setHomePageText(_homepageText);
    }
  }, [_homepageText]);

  const initialState = useMemo(() => {
    return {
      // url_assurance: coll?.url_assurance || '',
      emailreg_coll: coll?.email_regisseur_caisse || '',
    };
  }, [coll]);

  const [form, formState] = useForm(collParamForm(t), initialState, {
    rowWrapperClass: 'row',
  });

  const submitCollChanges = useCallback(() => {
    submitColl({
      ...formState,
      id_coll,
      homepageText,
    });
  }, [submitColl, homepageText, formState, id_coll]);

  const notifySuccess = useCallback(() => {
    dispatch(setSnackbar(t('parameters.modification_success'), SnackbarSeverity.SUCCESS));
    dispatch(showSnackbar());
  }, [t]);

  useEffect(() => {
    if (submitCollResp?.success) {
      queryClient.invalidateQueries(['param/coll']);
      notifySuccess();
    }
  }, [submitCollResp, notifySuccess, queryClient]);

  const disabled = useMemo(() => {
    return (
      (homepageText && Object.keys(homepageText).some((field) => validator.isEmpty(homepageText[field]))) ||
      !formState?.emailreg_coll
    );
  }, [formState, homepageText]);

  const handleChangeHomepageText = useCallback(
    (locale: string) => (value: string) => {
      setHomePageText((prevState) => ({
        ...prevState,
        [locale]: value,
      }));
    },
    []
  );

  const renderDynamicStrInputs = useMemo(() => {
    return locales.map((locale) => (
      <div className={'col-md-6'}>
        <Input
          label={t(`parameters.homepage_txt_${locale}`)}
          handleChange={handleChangeHomepageText(locale)}
          value={homepageText?.[locale]}
          controlled
        />
      </div>
    ));
  }, [handleChangeHomepageText, homepageText, t]);

  return (
    <MainSection>
      <div className={'card'}>
        <div className={'card-body'}>
          {form}
          <div className={'row'}>{renderDynamicStrInputs}</div>

          <div className={'row'}>
            <div className={'col-md-12'}>
              <Button
                className={'btn btn-w-xl btn-primary'}
                label={t('parameters.update')}
                onClick={submitCollChanges}
                disabled={disabled}
              />
            </div>
          </div>
        </div>
      </div>
    </MainSection>
  );
}

export default CollectivityGlobalParamsPage;
