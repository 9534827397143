/** @jsxImportSource @emotion/react */
import { Theme } from '@emotion/react';
import { css } from '@emotion/react/macro';
import React, { PropsWithChildren } from 'react';
import { getColor } from '../../../utils/style';

const badgeCss = {
  container: css({
    position: 'relative',
  }),
  self: (theme: Theme) =>
    css({
      content: "''",
      position: 'absolute',
      right: 0,
      top: 0,
      width: 16,
      height: 16,
      backgroundColor: getColor('red.200')({ theme }),
      color: 'white',
      borderRadius: 2,
      fontSize: 11,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      lineHeight: 1,
      textAlign: 'center',
    }),
};

type BadgeProps = PropsWithChildren<{
  badgeContent?: JSX.Element | number | string;
  translate?: string;
}>;

function Badge({ children, badgeContent, translate }: BadgeProps) {
  return badgeContent ? (
    <span css={badgeCss.container}>
      {children}
      <span css={badgeCss.self} style={{ transform: `translate(${translate})` }}>
        {badgeContent}
      </span>
    </span>
  ) : (
    <>{children}</>
  );
}

export default Badge;
