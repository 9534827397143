import React, { useCallback, useEffect } from 'react';
import { Controller, FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DevTool } from '@hookform/devtools';
import { LoaderFunction, NavLink, useFetcher, useLoaderData, useNavigate } from 'react-router-dom';
import { QueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import _, { cloneDeep } from 'lodash';
import MainSection from '../../../../components/Core/MainSection/MainSection';
import Card from '../../../../components/Core/Card/Card';
import TextFieldControlled, {
  TextAreaFieldControlled,
} from '../../../../components/Form/ControlledFields/TextFieldControlled';
import Label from '../../../../components/Core/Label/Label';
import { useMainTitle } from '../../../../components/Layout/MainTitleContext';
import DropzoneFileInput from '../../../../components/Core/DropzoneFileInput/DropzoneFileInput';
import ConnectForm from '../../../../components/Form/ConnectForm';
import ButtonWithIcon from '../../../../components/Core/Button/ButtonWithIcon';
import store from '../../../../internal/store';
import { bikeListQuery, BikeListRawResponse } from '../Bike/BikeList.page';
import CheckboxItem from '../../../../components/Core/CheckboxItem/CheckboxItem';
import { Fieldset } from '../../../stats/RawData.page';
import SelectField from '../../../../components/Core/SelectField';
import { Option } from '../../../../hook/useBuildForm';
import { velocareTypesQuery } from '../Bike/NewBike.page';
import { TOAST_SEVERITY } from '../../../../components/Core/Toast/Toast';
import { useSnackbar } from '../../../../context/SnackbarContext';
import styles from './NewBike.module.scss';

type AccessoryFormProps = {
  //eslint-disable-next-line
  bikes?: any[];
};

export function AccessoryForm(props: AccessoryFormProps) {
  const { t } = useTranslation(['parameters/products/newAccessory', 'form']);
  const fieldsT = useCallback((key: string) => t(`fields.${key}`), [t]);
  const { bikes } = props;

  const { velocareTypesResponse } = useLoaderData() as {
    velocareTypesResponse?: AxiosResponse<[]>;
  };

  return (
    <ConnectForm>
      {({ register }) => (
        <>
          <div className={styles.FirstSection}>
            <div className={styles.Left}>
              <fieldset>
                <Label required={true}>{fieldsT('name.label')}</Label>
                <TextFieldControlled
                  placeholder={fieldsT('name.placeholder')}
                  {...register('name', { required: true })}
                />
              </fieldset>
              <fieldset className={styles.DescriptionField}>
                <Label required={true}>{fieldsT('description.label')}</Label>
                <TextAreaFieldControlled
                  placeholder={fieldsT('description.placeholder')}
                  {...register('description', { required: true })}
                />
              </fieldset>
            </div>
            <div className={styles.Right}>
              <fieldset>
                <Controller
                  name={'img'}
                  defaultValue={null}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <Label>{t('fields.img.label')}</Label>
                      <DropzoneFileInput
                        returnFile
                        className={styles.ImgInput}
                        //eslint-disable-next-line
                        onChange={(file: any) => {
                          const reader = new FileReader();
                          reader.onload = function (e) {
                            if (e.target && e.target.result && typeof e.target.result === 'string') {
                              const src = e.target.result;
                              // onChange(dataURLtoFile(src, file.path));
                              onChange({ src });
                            }
                          };

                          reader.readAsDataURL(file as File);
                        }}
                        defaultValue={value}
                      />
                    </>
                  )}
                />
              </fieldset>
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            {velocareTypesResponse && !_.isNil(velocareTypesResponse?.data) && velocareTypesResponse.data.length > 0 && (
              <div style={{ minWidth: 300 }}>
                <Label>{fieldsT('velocareType.label')}</Label>
                <Controller
                  name={'velocareType'}
                  render={({ fieldState: { error }, field: { onChange, value } }) => (
                    <SelectField
                      placeholder={fieldsT('velocareType.placeholder')}
                      error={error}
                      onChange={(newValue) => {
                        onChange(newValue);
                      }}
                      //eslint-disable-next-line
                      options={velocareTypesResponse?.data as any}
                      value={velocareTypesResponse?.data.find((option: Option) => option.value === value) || null}
                    />
                  )}
                />
              </div>
            )}
          </div>
          <Fieldset className={'mt-4'} legend={t('linkedBikesFieldset.title')}>
            <Card className={'mb-4'} color={'primary'} content={t('linkedBikesFieldset.advice')} />
            {bikes?.map((bike) => {
              return <CheckboxItem {...register('linkedBike')} label={bike.name} value={bike.id} />;
            })}
          </Fieldset>
        </>
      )}
    </ConnectForm>
  );
}

export const newAccessoryLoader: (queryClient: QueryClient) => LoaderFunction = (queryClient) => async () => {
  const collId = store.getState().authState.collectivity;

  const bikeListResponse = await queryClient.ensureQueryData({
    ...bikeListQuery({ idColl: collId }),
  });

  const velocareTypesResponse = await queryClient.ensureQueryData({
    ...velocareTypesQuery({ id_coll: collId }),
  });

  return { collId, bikeListResponse, velocareTypesResponse };
};

function NewAccessoryPage() {
  const fetcher = useFetcher();
  const { t } = useTranslation(['parameters/products/newAccessory', 'form']);
  useMainTitle(t('title'));
  const navigate = useNavigate();
  const snackbar = useSnackbar();

  const { bikeListResponse } = useLoaderData() as {
    bikeListResponse?: AxiosResponse<BikeListRawResponse>;
    collId: number;
  };

  const methods = useForm({
    mode: 'onChange',
    defaultValues: { linkedBike: _.map(bikeListResponse?.data, (bike) => bike.id.toString()) },
  });

  const onSubmit = (data: FieldValues) => {
    const _copy = cloneDeep(data);
    _copy.linkedBike = _copy.linkedBike.map((stringValue: string) => Number(stringValue));
    fetcher.submit({ action: 'new', ..._copy }, { method: 'post', encType: 'application/json' });
  };

  useEffect(() => {
    if (fetcher.data && fetcher.data.status === 200) {
      snackbar?.setAlert({
        message: t('successMessage'),
        severity: TOAST_SEVERITY.success,
      });
      navigate('/parametres/articles/accessoire');
    }
  }, [fetcher.data, t]);

  return (
    <MainSection className={'reset'}>
      <Card
        content={
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <p>{t('form:all_required')}</p>
              <AccessoryForm bikes={bikeListResponse?.data} />
              <div className={'row mt-4 justify-between items-center'}>
                <ButtonWithIcon
                  as={NavLink}
                  to={'/parametres/articles/accessoire'}
                  icon={'CrossWithoutCircle'}
                  color={'secondary'}>
                  {t('common:cancel')}
                </ButtonWithIcon>
                <ButtonWithIcon icon={'CheckWithoutCircle'} type={'submit'}>
                  {t('submitCta')}
                </ButtonWithIcon>
              </div>
            </form>

            <DevTool control={methods.control} />
          </FormProvider>
        }
      />
    </MainSection>
  );
}

export default NewAccessoryPage;
