import React, { useCallback, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosResponse } from 'axios';
import { useQueryClient } from '@tanstack/react-query';
import Input from '../../../fragments/input/Input';
import { BroadcastStateType } from '../../../parameters/types/broadcasting';
import Button from '../../../fragments/button/Button';
import { selectCollAndDeliveryPointID } from '../../../auth/selectors/authSelectors';
import useApi, { Methods } from '../../../internal/hooks/useApi';
import { BROADCAST_DAYS_ENDPOINT } from '../../../parameters/constants/endpoints';
import { setSnackbar, showSnackbar } from '../../../internal/actions/appActions';
import SnackbarSeverity from '../../../internal/constants/snackbar';

function BroadcastingParametersPage() {
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const broadcastingResponse = useOutletContext<AxiosResponse | undefined>();
  const { t } = useTranslation(['old']);
  const dispatch = useDispatch();
  const [broadcastState, setBroadcastState] = useState<Partial<BroadcastStateType>>({
    enquete_email_days: 0,
    relance_email_days: 0,
    relance_sms_cons: '',
    relance_sms_cons_park: '',
    relance_sms_days: 0,
    relance_sms_velo: '',
  });
  const queryClient = useQueryClient();

  const { request: postDaysDiff, response: postDaysResp } = useApi(Methods.POST, BROADCAST_DAYS_ENDPOINT);

  useEffect(() => {
    if (postDaysResp?.success) {
      dispatch(setSnackbar(t('parameters.modification_success'), SnackbarSeverity.SUCCESS));
      dispatch(showSnackbar());
      queryClient.invalidateQueries(['param/diff']);
    }
  }, [postDaysResp, queryClient]);

  useEffect(() => {
    if (broadcastingResponse) {
      //prettier-ignore
      const {
        enquete_email,
        relance_email_cons_park,
        relance_email_velo,
        relance_email_cons,
        ...rest
      } = broadcastingResponse.data;

      const state: BroadcastStateType = {
        ...rest,
      };

      setBroadcastState(state);
    }
  }, [broadcastingResponse]);

  const changeEditorState = useCallback(
    (editorType: string) => (editorState: string) => {
      setBroadcastState((prevState) => ({
        ...prevState,
        [editorType]: editorState,
      }));
    },
    []
  );

  const onSubmitDiffDays = useCallback(() => {
    const { relance_email_days, relance_sms_days, enquete_email_days } = broadcastState;

    postDaysDiff({
      id_coll,
      relance_email_days,
      relance_sms_days,
      enquete_email_days,
    });
  }, [broadcastState]);

  return (
    <div className={'main-content'}>
      <div className={'card'}>
        <div className={'card-body'}>
          <div className={'row'}>
            <div className={'col-md-12'}>
              <div className={'row'}>
                <div className={'col-md-12'}>
                  <p className={'lead'}>{t('parameters.contract_end')}</p>
                </div>
              </div>
              <div className={'row'}>
                <div className={'col-md-2'}>
                  {t('parameters.reminder_email')}
                  <Input
                    id={'relance_email_days'}
                    name={'relance_email_days'}
                    type={'number'}
                    className={'form-control'}
                    handleChange={changeEditorState('relance_email_days')}
                    value={broadcastState.relance_email_days}
                    controlled
                  />
                </div>
                <div className={'col-md-2'}>
                  {t('parameters.reminder_message')}
                  <Input
                    id={'relance_sms_days'}
                    name={'relance_sms_days'}
                    type={'number'}
                    className={'form-control'}
                    handleChange={changeEditorState('relance_sms_days')}
                    value={broadcastState.relance_sms_days}
                    controlled
                  />
                </div>
                <div className={'col-md-2'}>
                  {t('parameters.email_inquiry_plus')}
                  <Input
                    id={'enquete_email_days'}
                    name={'enquete_email_days'}
                    type={'number'}
                    className={'form-control'}
                    handleChange={changeEditorState('enquete_email_days')}
                    value={broadcastState.enquete_email_days}
                    controlled
                  />
                </div>
                <div className={'col-md-6 m-auto'}>
                  <Button
                    className={'btn btn-w-xl btn-primary'}
                    label={t('parameters.save')}
                    onClick={onSubmitDiffDays}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BroadcastingParametersPage;
