import React, { ComponentPropsWithoutRef, InputHTMLAttributes } from 'react';
import SwitchBase from '../SwitchBase/SwitchBase';
import styles from './checkbox.module.scss';

type CheckboxProps = InputHTMLAttributes<HTMLInputElement> &
  Omit<ComponentPropsWithoutRef<typeof SwitchBase>, 'icon' | 'checkedIcon' | 'type'>;

function Checkbox(props: CheckboxProps) {
  const { value, onChange, ...propsToForward } = props;

  return (
    <SwitchBase
      className={styles.Checkbox}
      type={'checkbox'}
      icon={React.cloneElement(<span className={styles.Checkmark} />)}
      checkedIcon={React.cloneElement(<span className={styles.Checkmark} />)}
      // checked={value as unknown as boolean}
      onChange={onChange}
      {...propsToForward}
    />
  );
}

export default Checkbox;
