import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Select from 'fragments/select/Select';
import useApi, { Methods } from 'internal/hooks/useApi';
import { SelectOptionType } from 'internal/types/select';
import {
  BIKE_TYPES_ENDPOINT,
  FREEPARK_TYPES_ENDPOINT,
  PARK_TYPES_ENDPOINT,
  RENTAL_DURATIONS_ENDPOINT,
  RENTAL_PRICES_ENDPOINT,
} from 'users/constants/endpoints';
import { bikeTypeToSelectOptions, rentalDurationToSelectOptions } from 'users/services/userReservationServices';
import { BikeTypologyType, RentalPriceType } from 'users/types/forms';
import { selectCollAndDeliveryPointID } from 'auth/selectors/authSelectors';
import ArticleTypology from 'users/constants/article';
import { selectCurrency } from 'internal/selectors/currencySelectors';

export type State = {
  selectedBikeType: string;
  selectedPriceFormula: RentalPriceType | null;
  selectedPrice: string;
};

type Props = {
  handleChange: (state: State) => void;
  articleType: ArticleTypology;
  isFree?: boolean;
};

const ProductSelectionForm = ({ handleChange, articleType, isFree = false }: Props): ReactElement => {
  const { t } = useTranslation(['old']);
  // @XXX: Entities fetched from the api saved in state
  const [bikeTypes, saveBikeTypes] = useState<SelectOptionType[]>([]);
  const [duration, saveDuration] = useState<SelectOptionType[]>([]);
  const [rentalPrices, saveRentalPrices] = useState<RentalPriceType[]>([]);
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const currency = useSelector(selectCurrency);

  // @XXX: fetch bike or park or freepark typology form data
  const ARTICLE_ENDPOINT = useMemo(() => {
    if (articleType === ArticleTypology.BIKE) {
      return BIKE_TYPES_ENDPOINT;
    }

    if (articleType === ArticleTypology.PARK) {
      return PARK_TYPES_ENDPOINT;
    }

    if (articleType === ArticleTypology.FREEPARK) {
      return FREEPARK_TYPES_ENDPOINT;
    }

    return BIKE_TYPES_ENDPOINT;
  }, [articleType]);

  const { request: fetchBikeTypes, response: bikesTypesRes } = useApi<BikeTypologyType[]>(
    Methods.GET,
    ARTICLE_ENDPOINT
  );

  useEffect(() => {
    fetchBikeTypes({
      params: {
        id_coll,
      },
    });
  }, [id_coll, articleType, fetchBikeTypes]);

  useEffect(() => {
    if (bikesTypesRes?.success && bikesTypesRes.data) {
      saveBikeTypes(bikeTypeToSelectOptions(bikesTypesRes.data));
    }
  }, [bikesTypesRes]);

  // @XXX: save the three select state locally when user chooses

  const [selectedBikeType, selectBikeType] = useState('');
  const [selectedDuration, selectDuration] = useState('');
  const [selectedPrice, selectPrice] = useState('');

  // @XXX: Once a bike type is selected, let's fetch duration
  const { request: rentalDurationReq, response: rentalDurationRes } = useApi<Record<string, string>[]>(
    Methods.GET,
    RENTAL_DURATIONS_ENDPOINT
  );

  useEffect(() => {
    if (selectedBikeType) {
      rentalDurationReq({
        params: {
          id_coll,
          article: articleType,
          id_type: selectedBikeType,
        },
      });
    }
  }, [id_coll, selectedBikeType, rentalDurationReq]);

  useEffect(() => {
    if (rentalDurationRes?.success && rentalDurationRes.data) {
      saveDuration(rentalDurationToSelectOptions(rentalDurationRes.data));
    }
  }, [rentalDurationRes]);

  // @XXX: Then, when duration is chosen, fetch prices
  const { request: rentalPricesReq, response: rentaPricesRes } = useApi<RentalPriceType[]>(
    Methods.GET,
    RENTAL_PRICES_ENDPOINT
  );

  useEffect(() => {
    if (selectedBikeType && (selectedDuration || duration[0]?.value)) {
      rentalPricesReq({
        params: {
          id_coll,
          article: articleType,
          id_type: selectedBikeType,
          nom_duree: selectedDuration || duration[0]?.value,
        },
      });
    }
  }, [id_coll, selectedBikeType, selectedDuration, rentalPricesReq, duration]);

  useEffect(() => {
    if (rentaPricesRes?.success && rentaPricesRes.data) {
      saveRentalPrices(rentaPricesRes.data);
    }
  }, [rentaPricesRes]);

  const pricesOptions = useMemo(
    () =>
      rentalPrices.map(({ nom_tarif, id_article, montant_ttc }) => ({
        label: !isFree
          ? nom_tarif
          : `${nom_tarif} (${String(montant_ttc.toFixed(2))} ${t('parameters.with_tax', { currency })})`,
        value: id_article.toString(),
      })),
    [rentalPrices, isFree, t]
  );

  // @XXX: Get the selected price option
  const selectedPriceFormula = useMemo(
    () =>
      rentalPrices.find(({ id_article }) => id_article === parseInt(selectedPrice || pricesOptions[0]?.value, 10)) ||
      null,
    [pricesOptions, selectedPrice]
  );

  // @XXX Give state to parent handler
  useEffect(() => {
    handleChange({
      selectedBikeType,
      selectedPriceFormula,
      selectedPrice,
    });
  }, [handleChange, selectedBikeType, selectedDuration, selectedPrice, selectedPriceFormula]);

  const label = useMemo(() => {
    if (articleType === ArticleTypology.BIKE) {
      return t('users.bike_type');
    }

    if (articleType === ArticleTypology.FREEPARK) {
      return t('users.freepark_type');
    }

    return t('users.park_type');
  }, [articleType, t]);

  const selectDurLabel = useMemo(() => {
    if (articleType === ArticleTypology.BIKE) {
      return t('users.choose_bike_before');
    }
    return t('users.choose_consigne_before');
  }, [articleType, t]);

  return (
    <div className={'row'}>
      <div className={'col-md-4'}>
        <Select
          id={'select_article'}
          name={'select_article'}
          label={label}
          handleChange={selectBikeType}
          options={[{ label: t('users.choose'), value: '0' }, ...bikeTypes]}
        />
      </div>
      <div className={'col-md-4'}>
        <Select
          name={'select_dur'}
          id={'select_dur'}
          label={t('users.rental_duration')}
          handleChange={selectDuration}
          defaultValue={duration[0]?.value}
          options={[{ label: selectDurLabel, value: '0' }, ...duration]}
        />
      </div>
      <div className={'col-md-4'}>
        <Select
          id={'select_price'}
          name={'select_price'}
          label={t('users.rental_prices')}
          handleChange={selectPrice}
          defaultValue={pricesOptions[0]?.value}
          options={[{ label: t('users.choose_duration_before'), value: '0' }, ...pricesOptions]}
        />
      </div>
    </div>
  );
};

export default ProductSelectionForm;
