import React, { ComponentPropsWithoutRef, InputHTMLAttributes, useState } from 'react';
import { FieldError } from 'react-hook-form';
import styled from '@emotion/styled/macro';
import _ from 'lodash';
import InputBase from '../InputBase/InputBase';
import Label from '../Label/Label';
import FormControl from '../FormControl/FormControl';
import { getColor } from '../../../utils/style';
import PhoneInputBase from './PhoneInputBase';

type PhoneFieldProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> &
  Omit<ComponentPropsWithoutRef<typeof InputBase>, 'error'> & {
    label: string;
    error?: FieldError;
  };

function PhoneField(props: PhoneFieldProps) {
  const { label, error, className, defaultValue, ...phoneInputProps } = props;
  const [id] = useState(_.uniqueId('phone-input-'));

  return (
    <FormControl value={props.value} error={!!error} defaultValue={defaultValue}>
      <FieldSet data-testid={'phone-field'} className={className}>
        <Label htmlFor={id}>{label}</Label>
        <PhoneInputBase {...phoneInputProps} />
        {error && <ErrorMessage>{error.message}</ErrorMessage>}
      </FieldSet>
    </FormControl>
  );
}

const ErrorMessage = styled.span`
  font-size: 12px;
  color: ${getColor('red.200')};
`;

const FieldSet = styled.fieldset`
  > div:hover + ${ErrorMessage} {
    color: ${getColor('red.300')};
  }
`;

export default PhoneField;
