import React from 'react';
import { RawBooking } from '../../../components/BookingCard/BookingCard';
import OtherActionsCard from './OtherActionsCard';

//eslint-disable-next-line
function Actions({ booking }: { booking?: RawBooking }) {
  return (
    <div>
      <OtherActionsCard />
      <div>card pour la fin de location</div>
    </div>
  );
}

export default Actions;
