import React, { ComponentPropsWithoutRef, PropsWithChildren } from 'react';
import * as ICONS from '../../../const/icons';
import Button from './Button';
import styles from './button.module.scss';

type IconKeyList = keyof typeof ICONS;

type ButtonWithIconProps = PropsWithChildren<
  ComponentPropsWithoutRef<typeof Button> & {
    icon: IconKeyList;
  }
>;

function ButtonWithIcon({ label, icon, children, ...propsToForward }: ButtonWithIconProps) {
  const IconComponent = ICONS[icon];

  return (
    <Button className={styles.ButtonWithIcon} {...propsToForward}>
      <IconComponent width={13} /> {children ? children : label}
    </Button>
  );
}

export default ButtonWithIcon;
