import React, { useEffect, useMemo, useState } from 'react';
import { FetchQueryOptions, QueryClient, useQuery, WithRequired } from '@tanstack/react-query';
import { LoaderFunction, useLoaderData, useRevalidator, useSearchParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { useSelector } from 'react-redux';
import _, { cloneDeep, isEqual } from 'lodash';
import Skeleton from 'react-loading-skeleton';
import { instance } from '../../api/user.api';
import store from '../../internal/store';
import MainSection from '../../components/Core/MainSection/MainSection';
import { selectCollAndDeliveryPointID } from '../../auth/selectors/authSelectors';
import Filters, { FiltersRaw } from '../../components/Core/Filters/Filters';
import FleetSection from './FleetSection';
import SupplyAndDemandSection, { supplyQuery } from './SupplyAndDemandSection';
import UsersSection, { userStatsQuery } from './UsersSection';
import 'react-loading-skeleton/dist/skeleton.css';

export const filtersQuery: (
  queryParams: unknown
) => WithRequired<FetchQueryOptions<AxiosResponse>, 'queryFn' | 'queryKey'> = (queryParams) => ({
  queryKey: ['indicator/generalFilters', queryParams],
  queryFn: ({ queryKey }) => {
    const [url, queryParams] = queryKey as [string, never];
    return instance.get(url, { params: queryParams });
  },
  staleTime: 900_000,
});

export const fleetQuery: (
  queryParams: unknown
) => WithRequired<FetchQueryOptions<AxiosResponse>, 'queryFn' | 'queryKey'> = (queryParams) => ({
  queryKey: ['indicator/fleet', queryParams],
  queryFn: ({ queryKey }) => {
    const [url, queryParams] = queryKey as [string, never];
    return instance.get(url, { params: queryParams });
  },
  staleTime: 900_000,
});

export const articleTypeListQuery: (
  queryParams: unknown
) => WithRequired<FetchQueryOptions<AxiosResponse>, 'queryFn' | 'queryKey'> = (queryParams) => ({
  queryKey: ['indicator/typearticle', queryParams],
  queryFn: ({ queryKey }) => {
    const [url, queryParams] = queryKey as [string, never];
    return instance.get(url, { params: queryParams });
  },
  staleTime: 900_000,
});

type IndicatorFiltersRaw = {
  [filterName: string]: string[] | { [value: string]: string };
};

function transformIndicatorFiltersRawToFilterRaw(
  indicatorFiltersRaw?: IndicatorFiltersRaw,
  defaultValues?: URLSearchParams
): FiltersRaw {
  return Object.entries(indicatorFiltersRaw || {}).reduce((prev, [filterName, stringOptions], index) => {
    Object.assign(prev, {
      [filterName]: {
        selected: defaultValues?.has(filterName)
          ? defaultValues?.get(filterName)
          : filterName === 'articles'
          ? Array.isArray(stringOptions)
            ? stringOptions[0]
            : Object.keys(stringOptions)[0]
          : null,
        zone: 1,
        order: index,
        values: Array.isArray(stringOptions)
          ? stringOptions.map((stringOption) => ({ label: stringOption, value: stringOption }))
          : Object.entries(stringOptions).map(([value, label]) => ({ value, label })),
      },
    });

    return prev;
  }, {});
}

function GlobalFilters() {
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: response } = useQuery<AxiosResponse>({
    ...filtersQuery({
      idColl: id_coll,
    }),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });

  const searchParamsFilters = useMemo(() => {
    const _searchParamsFilters = Object.fromEntries(searchParams);
    delete _searchParamsFilters.type;
    return _searchParamsFilters;
  }, [searchParams]);

  const [filters, setFilters] = useState(
    transformIndicatorFiltersRawToFilterRaw(response?.data, new URLSearchParams(searchParamsFilters))
  );

  useEffect(() => {
    const newFilters = transformIndicatorFiltersRawToFilterRaw(
      response?.data,
      new URLSearchParams(searchParamsFilters)
    );
    if (!_.isEqual(newFilters, filters)) setFilters(newFilters);
  }, [response, searchParamsFilters]);

  //eslint-disable-next-line
  const onChange = (filtersObject: { [filterName: string]: any }) => {
    setFilters((prevState) => {
      const copy = cloneDeep(prevState);
      Object.keys(filtersObject).forEach((filterName) => {
        //eslint-disable-next-line
        copy[filterName]!.selected = filtersObject[filterName];
      });
      return copy;
    });
  };

  useEffect(() => {
    const keyValueFilters = _(
      Object.entries(filters).reduce((prev, [filterName, value]) => {
        Object.assign(prev, { [filterName]: value?.selected });
        return prev;
      }, {})
    );

    if (!isEqual(keyValueFilters.omitBy(_.isUndefined).omitBy(_.isNull).value(), searchParamsFilters))
      setSearchParams((prev) =>
        _({
          ...Object.fromEntries(prev),
          ...keyValueFilters.value(),
        })
          .omitBy(_.isUndefined)
          .omitBy(_.isNull)
          .value()
      );
  }, [filters, searchParamsFilters]);

  return <Filters translationNamespace={'stats/indicator'} filtersRaw={filters} onChange={onChange} />;
}

export const indicatorLoader: (queryClient: QueryClient) => LoaderFunction =
  (queryClient) =>
  async ({ request }) => {
    const collId = store.getState().authState.collectivity;

    try {
      // const pdl = store.getState().authState.deliveryPoint;
      const url = new URL(request.url);
      const articles = url.searchParams.get('articles');
      const pdl = url.searchParams.get('pdl');

      const filtersResponse = await queryClient.ensureQueryData({
        ...filtersQuery({ idColl: collId }),
      });

      const queryParams = { idColl: collId, type: articles || 'bike' };
      if (pdl !== 'null') {
        Object.assign(queryParams, { pdl });
      }
      const fleetResponse = await queryClient.ensureQueryData<AxiosResponse>({
        ...fleetQuery(queryParams),
      });

      const queryParamsSupplyRequest = { idColl: collId, type: articles || 'bike' };
      if (pdl !== 'null') {
        Object.assign(queryParamsSupplyRequest, { pdl });
      }
      const supplyResponse = await queryClient.ensureQueryData({
        ...supplyQuery(queryParamsSupplyRequest),
      });

      const queryParamsUserStatsRequest = { idColl: collId, type: articles || 'bike' };
      if (pdl !== 'null') {
        Object.assign(queryParamsUserStatsRequest, { pdl });
      }
      const userStatsResponse = await queryClient.ensureQueryData({
        ...userStatsQuery(queryParamsUserStatsRequest),
      });

      const articleTypeListResponse = await queryClient.ensureQueryData({
        ...articleTypeListQuery({ idColl: collId, article: articles || 'bike' }),
        staleTime: 900_000,
      });

      return {
        filtersResponse,
        fleetResponse,
        articleTypeListResponse,
        supplyResponse,
        userStatsResponse,
        collId,
        pdl,
      };
    } catch (e) {
      return { collId };
    }
  };

function IndicatorPage() {
  const { revalidate } = useRevalidator();
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const { fleetResponse, supplyResponse, articleTypeListResponse, filtersResponse, collId } = useLoaderData() as {
    filtersResponse?: AxiosResponse;
    fleetResponse?: AxiosResponse;
    articleTypeListResponse?: AxiosResponse;
    supplyResponse?: AxiosResponse;
    userStatsResponse?: AxiosResponse;
    collId: number;
    pdl: number;
  };

  useEffect(() => {
    if (id_coll !== collId) revalidate();
  }, [id_coll, collId]);

  return (
    <MainSection className={'reset'}>
      {/*<React.Suspense fallback={<></>}>*/}
      <React.Suspense fallback={<Skeleton height={660} />}>
        {/*<Await resolve={filtersResponse} errorElement={<></>}>*/}
        <GlobalFilters />
        {/*</Await>*/}
        {/*<React.Suspense fallback={<Skeleton height={660} />}>*/}
        {/*<Await resolve={Promise.all([fleetResponse, articleTypeListResponse, filtersResponse])} errorElement={<></>}>*/}
        {_.every([fleetResponse, articleTypeListResponse, filtersResponse]) && <FleetSection />}
        {/*</Await>*/}
        {/*<React.Suspense fallback={<Skeleton height={871} />}>
        <Await resolve={Promise.all([supplyResponse, articleTypeListResponse, filtersResponse])} errorElement={<></>}>*/}
        {_.every([supplyResponse, articleTypeListResponse, filtersResponse]) && <SupplyAndDemandSection />}
        {/*</Await>*/}
        {/*</React.Suspense>
      <React.Suspense fallback={<></>}>
        <Await resolve={Promise.all([userStatsResponse, articleTypeListResponse])}>*/}
        <UsersSection />
        {/*</Await>
      </React.Suspense>*/}
      </React.Suspense>
    </MainSection>
  );
}

export default IndicatorPage;
