import React, { Fragment, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createPortal } from 'react-dom';
import { FetchQueryOptions, QueryClient, useQuery, WithRequired } from '@tanstack/react-query';
import {
  ActionFunction,
  Await,
  defer,
  json,
  LoaderFunction,
  NavLink,
  useFetcher,
  useLoaderData,
} from 'react-router-dom';
import axios, { AxiosResponse } from 'axios';
import { shallowEqual, useSelector } from 'react-redux';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragStartEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers';
import { useDebounce } from 'use-debounce';
import MainSection from '../../../../components/Core/MainSection/MainSection';
import Card from '../../../../components/Core/Card/Card';
import ButtonWithIcon from '../../../../components/Core/Button/ButtonWithIcon';
import store from '../../../../internal/store';
import { instance } from '../../../../api/user.api';
import { selectCollAndDeliveryPointID } from '../../../../auth/selectors/authSelectors';
import Datagrid, { RowItem } from '../../../../components/Core/Datagrid/Datagrid';
import { DraggableRow } from '../../PriceList/TypePriceList.page';
import { GridColDefinition } from '../../../../components/Core/Datagrid/gridCols';
import { AccessoryFOIcon, GridLinesIcon } from '../../../../const/icons';
import { TOAST_SEVERITY } from '../../../../components/Core/Toast/Toast';
import { useSnackbar } from '../../../../context/SnackbarContext';
import styles from '../Bike/BikeList.module.scss';
import { useMainTitle } from '../../../../components/Layout/MainTitleContext';
import { dataURLtoFile } from '../../../../parameters/utils/file';
import { useRights } from '../Bike/BikeList.page';
import UserRights from '../../../../auth/constants/userRights';

export type AccessoryListRawResponse = {
  id: number;
  idArticleparent: number;
  idColl: number;
  name: string;
  description: string;
  idMedia: number;
  position: number;
  mediaUrl: string;
  articleParent: string;
  linkedBike: number[];
  velocareType: string;
}[];

function NewAccessory() {
  const { t } = useTranslation(['parameters/products/accessoryList', 'common']);
  const [layoutElement, setLayoutElement] = useState<Element | null>(null);

  useLayoutEffect(() => {
    setLayoutElement(document.querySelector('#products-layout'));
  }, []);

  return (
    layoutElement &&
    createPortal(
      <Fragment key={'create-accessory'}>
        <ButtonWithIcon
          as={NavLink}
          to={'/parametres/articles/accessoire/nouveau'}
          icon={'AddWithoutCircle'}
          color={'major'}>
          {t('newAccessoryCta')}
        </ButtonWithIcon>
      </Fragment>,
      layoutElement
    )
  );
}

export const accessoryListAction: (queryClient: QueryClient) => ActionFunction =
  (queryClient) =>
  async ({ request }) => {
    const collId = store.getState().authState.collectivity;
    const { action, img, ...data } = await request.json();
    let response = null;

    try {
      switch (action) {
        case 'updateOrder':
          response = await instance.post('accessory/updateOrder', data.items);
          if (response.status === 200) await queryClient.invalidateQueries(['accessory/list']);
          break;
        case 'new':
          response = await instance.post('accessory/new', { ...data, idColl: collId });
          if (response.status === 200) {
            await queryClient.invalidateQueries(['accessory/list']);
            if (img) {
              const file = await dataURLtoFile(img.src, 'img.png');
              await instance.put('accessory/media', file, {
                params: { idColl: collId, idArticle: response.data.idArticle },
              });
            }
          }
          break;
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        console.error(e);
        return json(e.response?.data);
      }
    }

    return response;
  };

export const accessoryListQuery: (
  queryParams: unknown
) => WithRequired<FetchQueryOptions<AxiosResponse>, 'queryFn' | 'queryKey'> = (queryParams) => ({
  queryKey: ['accessory/list', queryParams],
  queryFn: ({ queryKey }) => {
    const [apiUrl, queryParams] = queryKey;
    return instance.get(apiUrl as string, { params: queryParams });
  },
});

export const accessoryListLoader: (queryClient: QueryClient) => LoaderFunction = (queryClient) => () => {
  const collId = store.getState().authState.collectivity;

  const accessoryListResponse = queryClient.ensureQueryData({
    ...accessoryListQuery({ idColl: collId }),
  });

  return defer({ collId, accessoryListResponse });
};

function useColumns(): GridColDefinition[] {
  const { t } = useTranslation('parameters/products/accessoryList');
  const { hasRight } = useRights();

  const getColumnText = useCallback(
    (key: string) => {
      return t('columns.'.concat(key));
    },
    [t]
  );

  return useMemo(
    () => [
      {
        field: '',
        type: 'custom',
        renderCell: () => hasRight(UserRights.PARAMETERS_ARTICLES_EDIT) && <GridLinesIcon width={14} />,
        width: 26,
      },
      {
        field: 'img',
        type: 'custom',
        headerName: getColumnText('img'),
        renderCell: ({ row }) => (
          <RowItem className={styles.ImgContainer}>
            {row.mediaUrl ? (
              <img alt={`image-accessoire-${row.id}`} src={row.mediaUrl} />
            ) : (
              <div className={styles.PlaceholderImg}>
                <AccessoryFOIcon />
              </div>
            )}
          </RowItem>
        ),
        width: 64,
      },
      {
        field: 'name',
        type: 'string',
        headerName: getColumnText('name'),
      },
      {
        field: 'description',
        type: 'string',
        headerName: getColumnText('description'),
      },
      {
        field: 'id',
        type: 'actions',
        renderCell: ({ row }) =>
          hasRight(UserRights.PARAMETERS_ARTICLES_EDIT) && (
            <div className={'row justify-end items-center w-100'}>
              <ButtonWithIcon
                color={'secondary'}
                as={NavLink}
                to={`/parametres/articles/accessoire/${row.id}`}
                icon={'EditIcon'}>
                {t('common:edit')}
              </ButtonWithIcon>
            </div>
          ),
        width: 'minmax(100px, auto)',
      },
      {
        field: 'id',
        type: 'custom',
        renderCell: () =>
          hasRight(UserRights.PARAMETERS_ARTICLES_EDIT) && <GridLinesIcon className={'ml-2'} width={14} />,
        width: 26,
      },
    ],
    [t, hasRight]
  );
}

function SortableAccessoryList() {
  const { t } = useTranslation('common');
  const fetcher = useFetcher();
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const snackbar = useSnackbar();
  const { hasRight } = useRights();
  const { data: accessoryListResponse } = useQuery<AxiosResponse>({
    ...accessoryListQuery({ idColl: id_coll }),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
  const [, setActiveId] = useState<number | null>(null);
  const [items, setItems] = useState<{ name: string; type: string; position: number; id: number }[]>([]);
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    setItems(accessoryListResponse?.data || []);
  }, [accessoryListResponse]);

  function handleDragStart(event: DragStartEvent) {
    const { active } = event;

    setActiveId(Number(active.id));
  }

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (over && active.id !== over?.id) {
      setItems((items) => {
        const oldIndex = items.findIndex((item) => item.position + 1 === active.id);
        const newIndex = items.findIndex((item) => item.position + 1 === over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }

    setActiveId(null);
  }

  function reset() {
    setItems(accessoryListResponse?.data || []);
  }

  useEffect(() => {
    if (fetcher.data && fetcher.data.status === 200) {
      snackbar?.setAlert({
        message:
          // t('freeParkUpdateSuccessMessage'),
          'mettre message pour update order',
        severity: TOAST_SEVERITY.success,
      });
    }
  }, [fetcher.data]);

  const isDirty = useMemo(() => {
    return accessoryListResponse?.data && items.length > 0 ? !shallowEqual(items, accessoryListResponse.data) : false;
  }, [items, accessoryListResponse]);

  const [isDirtyDebounced] = useDebounce(isDirty, 100);

  const columns = useColumns();

  const handleUpdateOrderList = useCallback(() => {
    fetcher.submit(
      {
        action: 'updateOrder',
        items: items.reduce((prev, currentItem, currentIndex) => {
          Object.assign(prev, { [currentItem.id]: currentIndex });
          return prev;
        }, {} as Record<string, string>),
      },
      { method: 'post', encType: 'application/json' }
    );
  }, [items]);

  return (
    <>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        modifiers={[restrictToFirstScrollableAncestor]}>
        <SortableContext
          disabled={!hasRight(UserRights.PARAMETERS_ARTICLES_EDIT)}
          items={items.map((item) => ({ id: item.position + 1 }))}>
          <Datagrid className={styles.List} RowComponent={DraggableRow} rows={items} columns={columns} />
        </SortableContext>
      </DndContext>
      {isDirtyDebounced && (
        <div className={'row items-center p-16 justify-between'}>
          <ButtonWithIcon color={'secondary'} icon={'CrossWithoutCircle'} onClick={reset}>
            {t('cancel')}
          </ButtonWithIcon>
          <ButtonWithIcon icon={'EditIcon'} onClick={handleUpdateOrderList}>
            {t('apply')}
          </ButtonWithIcon>
        </div>
      )}
    </>
  );
}

function AccessoryListPage() {
  const { t } = useTranslation(['parameters/products/accessoryList', 'parameters/products/bikeList']);
  useMainTitle(t('title'));
  const { accessoryListResponse } = useLoaderData() as {
    accessoryListResponse?: AxiosResponse;
    collId: number;
  };
  const { hasRight } = useRights();

  return (
    <>
      {hasRight(UserRights.PARAMETERS_ARTICLES_EDIT) && <NewAccessory />}
      <MainSection>
        <Card color={'primary'} content={<>{t('advice')}</>} />
        <Card>
          <Await resolve={accessoryListResponse}>
            <SortableAccessoryList />
          </Await>
        </Card>
      </MainSection>
    </>
  );
}

export default AccessoryListPage;
