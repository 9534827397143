import React from 'react';
import { generatePath, NavLink, Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tab, Tabs, TabsList } from '../../../components/Core/Tabs/Tabs';
import { TabComponent } from '../../Reservation/Reservation.layout';

function AdminRightsLayout() {
  const { t } = useTranslation(['admin/rightsSection']);

  return (
    <div className={'reset'}>
      <div>
        <Tabs>
          <TabsList>
            <Tab
              component={({ children, ...otherProps }: TabComponent) => (
                <NavLink {...otherProps} to={generatePath('.')} end>
                  {children}
                </NavLink>
              )}>
              {t('layout.tabs.profiles')}
            </Tab>
            <Tab
              component={({ children, ...otherProps }: TabComponent) => (
                <NavLink {...otherProps} to={generatePath('agent')} end>
                  {children}
                </NavLink>
              )}>
              {t('layout.tabs.users')}
            </Tab>
          </TabsList>
        </Tabs>
      </div>

      <Outlet />
    </div>
  );
}

export default AdminRightsLayout;
