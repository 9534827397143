import { useQuery } from '@tanstack/react-query';
import React, { ComponentPropsWithoutRef, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { saveCollectivity, saveDeliveryPoint } from '../../auth/actions/authActions';
import { selectCollAndDeliveryPointID, selectCollectivityList } from '../../auth/selectors/authSelectors';
import { mapDeliveryPointListToOptions, mapUserCollectivityListToOptions } from '../../auth/services/authServices';
import { UserCollectivityType } from '../../auth/types/collectivity';
import useBuildForm, { FormObject } from '../../hook/useBuildForm';
import FormModal from '../Core/FormModal/FormModalProps';
import { pdlListQuery } from './Sidebar';

const useGetCollectivitySelectForm = (collectivitySelected: UserCollectivityType | null) => {
  const { t } = useTranslation('menu');
  const collectivityList = useSelector(selectCollectivityList);

  const { data: pdlListResponse } = useQuery({
    ...pdlListQuery({ id_coll: collectivitySelected?.id_coll }),
    enabled: Boolean(collectivitySelected?.id_coll),
    staleTime: 400_000,
    refetchOnWindowFocus: false,
  });

  const pdlList = useMemo(() => {
    return mapDeliveryPointListToOptions(pdlListResponse?.data || []) || [];
  }, [pdlListResponse]);

  return useMemo<FormObject>(() => {
    return [
      {
        fields: [
          {
            name: 'id_coll',
            type: 'select',
            label: '',
            options: mapUserCollectivityListToOptions(collectivityList),
          },
          {
            name: 'id_pdl',
            type: 'select',
            label: '',
            placeholder: t('changeCollectivityModal.locationPointSelectPlaceholder'),
            options: pdlList,
          },
        ],
      },
    ];
  }, [collectivityList, pdlList]);
};

function CollectivityModal(props: Omit<ComponentPropsWithoutRef<typeof FormModal>, 'validButtonLabel'>) {
  const { t } = useTranslation('menu');
  const dispatch = useDispatch();
  const collectivityList = useSelector(selectCollectivityList);
  const { id_coll: defaultColl, id_pdl: defaultPdl } = useSelector(selectCollAndDeliveryPointID);
  const [collectivitySelected, setCollectivitySelected] = useState<UserCollectivityType | null>(null);
  const formObject = useGetCollectivitySelectForm(collectivitySelected);

  const defaultValues = useMemo(() => {
    return {
      id_coll: defaultColl.toString(),
      id_pdl: defaultPdl.toString(),
    };
  }, [defaultColl, defaultPdl]);

  const { builtFormFields, handleSubmit, watch, setValue, formState } = useBuildForm({
    formObject,
    defaultValues,
  });

  const _currentCollectivity = watch('id_coll');

  useEffect(() => {
    const initialCollectivity = collectivityList.find(
      (collectivity) => collectivity.id_coll.toString() === _currentCollectivity
    );
    if (initialCollectivity) {
      setCollectivitySelected(initialCollectivity as unknown as UserCollectivityType);
    }
  }, []);

  useEffect(() => {
    const newCollectivity = collectivityList.find(
      (collectivity) => collectivity.id_coll.toString() === _currentCollectivity
    );
    if (newCollectivity && formState.isDirty) {
      setCollectivitySelected(newCollectivity as unknown as UserCollectivityType);
      setValue('id_pdl', null);
    }
  }, [_currentCollectivity]);

  const onSubmit = useCallback(
    handleSubmit((data) => {
      if (data.id_pdl && data.id_coll) {
        dispatch(saveCollectivity(Number(data.id_coll)));
        dispatch(saveDeliveryPoint(Number(data.id_pdl)));
        props.setIsOpen(false);
      }
    }),
    [props.setIsOpen]
  );

  return (
    <FormModal
      title={t('changeCollectivityModal.title')}
      setIsOpen={props.setIsOpen}
      validButtonLabel={t('changeCollectivityModal.cta')}
      onSubmit={onSubmit}>
      {builtFormFields}
    </FormModal>
  );
}

export default CollectivityModal;
