import { selectCollAndDeliveryPointID } from 'auth/selectors/authSelectors';
import dayjs from 'dayjs';

import Button from 'fragments/button/Button';
import Select from 'fragments/select/Select';
import useApi, { Methods } from 'internal/hooks/useApi';
import useForm from 'internal/hooks/useForm';
import { selectCurrency } from 'internal/selectors/currencySelectors';
import { selectLocale } from 'internal/selectors/localeSelectors';
import { SelectOptionType } from 'internal/types/select';
import { DYNAMIC_FORMS_ENDPOINT, RESA_PROPERTIES_ENDPOINT } from 'parameters/constants/endpoints';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ProductSelectionForm, { State as ProductState } from 'users/components/BikeProductSelectionForm';
import DurationForm from 'users/components/DurationForm';
import VouchersList from 'users/components/reservation/VouchersList';
import { RemoteFieldType } from 'users/components/UsersResaBike';
import ArticleTypology from 'users/constants/article';
import {
  BIKE_AVAILABILITY_ENDPOINT,
  PARK_LOCATION_ENDPOINT,
  POST_USER_RESERVATION_ENDPOINT,
} from 'users/constants/endpoints';
import { commonConsigneForm, paymentMeanForm } from 'users/constants/forms';
import { selectUserDetails } from 'users/selectors/usersSelectors';
import { parkLocationToSelectOptions } from 'users/services/userReservationServices';
import { assembleForm } from 'users/utils/dynamicForms';
import { computeRentalDuration, computeRentalDurationTrads, computeRentalItems } from 'users/utils/resabike';
import { generatePath } from '../../utils/router';

const UsersResaPark = (): ReactElement => {
  const { t } = useTranslation(['reservation/reservation', 'old']);
  const { id_coll, id_pdl } = useSelector(selectCollAndDeliveryPointID);
  const locale = useSelector(selectLocale);
  const currency = useSelector(selectCurrency);

  const { request: fetchBooking, response: bookingRes } = useApi<{
    bookingEnabled: boolean;
    paiement_bo: string[];
  }>(Methods.GET, RESA_PROPERTIES_ENDPOINT);

  const { request: fetchForm, response: fetchFormRes } = useApi<{
    bookingConsignePark: {
      fields: RemoteFieldType[][];
    };
  }>(Methods.GET, DYNAMIC_FORMS_ENDPOINT);

  const remoteConsigneForm = useMemo(() => fetchFormRes?.data.bookingConsignePark.fields || [], [fetchFormRes?.data]);

  useEffect(() => {
    if (id_coll) {
      const params = { id_coll };

      fetchBooking({ params });
      fetchForm({ params: { id_coll, formName: 'bookingConsignePark' } });
    }
  }, [id_coll]);

  const isBookingEnabled = useMemo(() => bookingRes?.data.bookingEnabled, [bookingRes]);
  const isPaymentBOEnabled = useMemo(
    () => bookingRes?.data?.paiement_bo && bookingRes?.data?.paiement_bo.length > 0,
    [bookingRes]
  );

  // @XXX: Fetch park locations and save locally
  const [parkLocations, saveParkLocations] = useState<SelectOptionType[]>([]);
  const { request: fetchParkLocations, response: parkLocationResp } = useApi<Record<string, string>[]>(
    Methods.GET,
    PARK_LOCATION_ENDPOINT
  );

  useEffect(() => {
    fetchParkLocations({
      params: {
        id_coll,
      },
    });
  }, [fetchParkLocations, id_coll]);

  useEffect(() => {
    if (parkLocationResp?.success && parkLocationResp.data) {
      saveParkLocations(parkLocationToSelectOptions(parkLocationResp.data));
    }
  }, [parkLocationResp]);

  // @XXX: Select user state and initialise userInfo form
  const user = useSelector(selectUserDetails);

  const initialUserFormState = useMemo(
    () => ({
      nom: user?.nom || '',
      prenom: user?.prenom || '',
      email: user?.email || '',
      boursier: user?.boursier || '',
      date_naissance: user?.date_naissance || '',
      entr_asso: user?.entr_asso || '',
      abotc: '',
      iban: user?.iban || '',
      bic: user?.bic || '',
      motif_utilisation: user?.motif_utilisation || '',
    }),
    [user]
  );

  const consigneForm = useCallback(() => {
    if (remoteConsigneForm) {
      return assembleForm(remoteConsigneForm, undefined, t);
    }
    return commonConsigneForm(t);
  }, [remoteConsigneForm, t]);

  const initTcParkState = useMemo(() => ({ moyen_reglement: 'cheque' }), []);

  // @XXX: All the form state
  //eslint-disable-next-line
  const [form, formState] = useForm(consigneForm() as unknown as any, initialUserFormState, {
    shouldLoad: true,
  });
  const [paymentForm, paymentFormState] = useForm(
    paymentMeanForm(t, isBookingEnabled, isPaymentBOEnabled),
    initTcParkState
  );
  const [location, setLocation] = useState('');
  const [{ startingDate, endingDate }, setDateFormState] = useState<{ startingDate: string; endingDate: string }>({
    startingDate: '',
    endingDate: '',
  });
  const [{ selectedBikeType, selectedPriceFormula }, setProductFormState] = useState<ProductState>({
    selectedBikeType: '',
    selectedPriceFormula: null,
    selectedPrice: '',
  });

  const tcForm = useMemo(() => Array.isArray(form) && form.splice(form.length - 1), [form]);

  const rentalDurationTypology = useMemo(() => {
    return computeRentalDuration(selectedPriceFormula?.type_duree || '');
  }, [selectedPriceFormula?.type_duree]);

  const rentalDurationTrads = useMemo(() => {
    return computeRentalDurationTrads(selectedPriceFormula?.type_duree || '');
  }, [selectedPriceFormula?.type_duree, locale]);

  // @XXX: Compute the text of the green card in func of duration
  const durationText = useMemo(() => {
    const isTrimester = selectedPriceFormula?.type_duree === 'trimestre';
    const end = dayjs(endingDate).hour(0).minute(0).second(0).millisecond(0);
    const start = dayjs(startingDate).hour(0).minute(0).second(0).millisecond(0);
    const diff = dayjs(end).diff(dayjs(start), rentalDurationTypology) || 0;
    const duration = isTrimester ? diff / 3 : diff;

    if (!isBookingEnabled || !selectedPriceFormula?.montant_ttc) {
      return `
        ${duration || 1} ${rentalDurationTrads}${duration < 2 || rentalDurationTrads === 'mois' ? '' : 's'}
      `;
    }

    const calc = diff * selectedPriceFormula?.montant_ttc;
    let price = isTrimester ? calc / 3 : calc;
    price /= selectedPriceFormula?.min_duree || 1;

    return `${duration || 1} ${rentalDurationTrads}${duration < 2 || rentalDurationTrads === 'mois' ? '' : 's'}
    ${t('users.duration_price', { price: price.toFixed(2), currency })}`;
  }, [selectedPriceFormula, endingDate, startingDate, isBookingEnabled, rentalDurationTrads]);

  // @XXX : Fetch bike availability for selected dates
  const [bikeAvailability, saveBikeAvailability] = useState(0);
  const { request: fetchBikeAvailability, response: bikeAvailabilityResponse } = useApi<{ nb_cons: number }>(
    Methods.GET,
    BIKE_AVAILABILITY_ENDPOINT
  );

  useEffect(() => {
    if (id_coll && id_pdl && location && selectedBikeType && startingDate && endingDate) {
      fetchBikeAvailability({
        params: {
          id_coll,
          id_pdl,
          id_empl: parseInt(location, 10),
          article: ArticleTypology.FREEPARK,
          id_type: selectedBikeType,
          date_debut: startingDate,
          date_fin: endingDate,
        },
      });
    }
  }, [fetchBikeAvailability, id_coll, id_pdl, selectedBikeType, startingDate, endingDate, location]);

  useEffect(() => {
    if (bikeAvailabilityResponse?.success && bikeAvailabilityResponse.data) {
      saveBikeAvailability(bikeAvailabilityResponse.data.nb_cons);
    }
  }, [bikeAvailabilityResponse]);

  const renderDurationForm = useMemo(
    () => <DurationForm price={selectedPriceFormula} handleChangeDate={setDateFormState} />,
    [selectedPriceFormula, setDateFormState]
  );

  // @XXX : Aggregate forms and POST it to users/reservation on button click
  const { request: postForm, response: postFormResponse } = useApi<{ id_reservation: string; redir_to: string }>(
    Methods.POST,
    POST_USER_RESERVATION_ENDPOINT
  );

  const [id_user, setUserID] = useState('');
  const { search } = useLocation();

  useEffect(() => {
    if (search) {
      const queryParams = new URLSearchParams(search);
      const userID = queryParams.get('userID') || '';

      setUserID(userID);
    }
  }, [search]);

  const handleSubmit = useCallback(() => {
    postForm({
      id_coll,
      id_pdl,
      ...formState,
      moyen_reglement: paymentFormState.moyen_reglement || initTcParkState.moyen_reglement,
      id_user: parseInt(id_user, 10),
      id_empl: parseInt(location, 10),
      id_article: selectedPriceFormula?.id_article,
      date_fin: dayjs(endingDate).format('YYYY-MM-DD'),
      date_debut: dayjs(startingDate).format('YYYY-MM-DD'),
    });
  }, [
    postForm,
    id_coll,
    id_pdl,
    id_user,
    formState,
    paymentFormState,
    selectedPriceFormula,
    endingDate,
    startingDate,
    initTcParkState,
    location,
  ]);

  const navigate = useNavigate();

  useEffect(() => {
    if (postFormResponse?.success && postFormResponse.data) {
      if (postFormResponse.data.redir_to) {
        window.location.href = postFormResponse.data.redir_to;
      } else {
        navigate(
          generatePath('home.invoicing.reservationDetails', {
            params: { userId: user?.id_user, bookingId: postFormResponse.data.id_reservation },
          })
        );
      }
    }
  }, [postFormResponse]);

  const informativeWording = useMemo(() => {
    if (isBookingEnabled) {
      return 'users.rent_park_conditions';
    }
    return 'users.rent_park_conditions_no_payment';
  }, [isBookingEnabled]);

  return (
    <div className={'tab-pane fade show active'}>
      <br />
      <ProductSelectionForm
        articleType={ArticleTypology.FREEPARK}
        handleChange={setProductFormState}
        isFree={isBookingEnabled}
      />
      <div className={'row'}>
        <div className={'col-md-12'}>
          <Select
            id={'empl_cons_park'}
            name={'empl_cons_park'}
            handleChange={setLocation}
            options={[{ label: 'Choisissez un emplacement', value: '0' }, ...parkLocations]}
          />
        </div>
      </div>
      <label>{t('users.user_info')}</label>
      <div className={'row'}>{form}</div>
      <label>{t('old:users.rent_duration')}</label>
      {selectedBikeType && (
        <div className={'alert alert-success fade show'} role={'alert'} id={'info_cons_park'}>
          {t('old:users.max_duration')}
          <span id={'duree_minmax_cons_park'}>
            {selectedPriceFormula?.min_duree && (
              <strong>
                {' '}
                {selectedPriceFormula?.min_duree} {rentalDurationTrads}
                {(selectedPriceFormula?.min_duree || 0) > 1 && rentalDurationTrads !== 'mois' ? 's ' : ' '}
              </strong>
            )}
            {selectedPriceFormula?.max_duree && selectedPriceFormula?.max_duree > 1 && (
              <>
                {' / '}
                <strong>
                  {selectedPriceFormula?.max_duree} {rentalDurationTrads}
                  {rentalDurationTrads === 'mois' ? '' : 's'}
                </strong>
              </>
            )}
          </span>
          <br />
          {t('old:users.selected_duration')}
          <span id={'duree_sel_cons_park'}>{durationText}</span>
          <br />
          {t('old:users.availability')}
          <span id={'info_cons_park_dispo'}>
            {startingDate && endingDate ? (
              <strong>
                {' '}
                {bikeAvailability}{' '}
                {t('old:users.availability_details', {
                  item: `${computeRentalItems(t, ArticleTypology.FREEPARK)}${bikeAvailability > 1 ? 's' : ''}`,
                })}
              </strong>
            ) : (
              ' choisissez une date de début et une date de fin...'
            )}
          </span>
        </div>
      )}
      {renderDurationForm}
      <br />
      <VouchersList articleID={selectedPriceFormula?.id_article} type={ArticleTypology.FREEPARK} />
      <br />
      <div className={'row'}>
        {tcForm}
        {paymentForm}
      </div>
      <br />
      <Button className={'btn btn-w-xl btn-primary'} label={t('users.create_resa')} onClick={handleSubmit} />
      <br />
      <span>
        <Trans i18nKey={informativeWording} components={{ bold: <strong />, lineBreak: <br />, u: <u /> }} />
      </span>
    </div>
  );
};

export default UsersResaPark;
