/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectCollAndDeliveryPointID } from '../../../auth/selectors/authSelectors';
import Button from '../../../components/Core/Button/Button';
import SelectField from '../../../components/Core/SelectField';
import { TOAST_SEVERITY } from '../../../components/Core/Toast/Toast';
import FormGroupCard from '../../../components/FormGroupCard/FormGroupCard';
import { useSnackbar } from '../../../context/SnackbarContext';
import useBuildForm from '../../../hook/useBuildForm';
import { withTheme } from '../../Parameters/Deposit/List/DepositList';
import updateContactParametersForm from './updateContactParametersForm';
import useContactParametersApi from './useContactParametersApi';

function ContactParametersPage() {
  const { t } = useTranslation(['admin/contactParameters', 'form']);
  const snackbar = useSnackbar();
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const { fetchContactParameters, putContactParameters } = useContactParametersApi();
  const [localeSelected, setLocaleSelected] = useState<string | null>(null);
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [defaultContactParameters, setDefaultContactParameters] = useState<Record<string, any> | null>(null);
  const { builtFormFields, handleSubmit } = useBuildForm({
    formObject: updateContactParametersForm(t),
    defaultValues: useMemo(() => {
      if (defaultContactParameters && localeSelected) {
        if ('langs' in defaultContactParameters[localeSelected]) {
          const { langs, ...defaultValuesToForward } = defaultContactParameters[localeSelected];
          return defaultValuesToForward;
        } else {
          return defaultContactParameters[localeSelected];
        }
      }

      return {};
    }, [localeSelected, defaultContactParameters]),
  });

  const onMount = useCallback(async () => {
    const response = await fetchContactParameters({ id_coll });
    if (response && response.status === 200) {
      setLocaleSelected(Object.keys(response.data)[0]);
      setDefaultContactParameters(response.data);
    }
  }, [id_coll]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const onSubmit = useCallback(
    handleSubmit(async (data) => {
      if (localeSelected) {
        const response = await putContactParameters({
          id_coll,
          infos: {
            [localeSelected]: data,
          },
        });

        if (response && response.status === 200) {
          snackbar?.setAlert({ message: t('success'), severity: TOAST_SEVERITY.success });
        }
      }
    }),
    [id_coll, localeSelected]
  );

  return (
    <MainSection>
      <FormGroupCard
        title={null}
        WrapperComponent={({ children }) => <form onSubmit={onSubmit}>{children}</form>}
        FooterSlotComponent={({ defaultWrapper: DefaultWrapper }) => (
          <DefaultWrapper>
            <Button type={'submit'} label={t('form:global_update_label')} />
          </DefaultWrapper>
        )}>
        {localeSelected && (
          <SelectField
            css={css`
              margin-bottom: 16px;
            `}
            label={t('locale_to_update')}
            defaultValue={{ label: t('locales.'.concat(localeSelected)), value: localeSelected }}
            onChange={(newValue) => setLocaleSelected(newValue as string)}
            options={
              defaultContactParameters
                ? Object.keys(defaultContactParameters).map((locale) => ({
                    label: t('locales.'.concat(locale)),
                    value: locale,
                  }))
                : []
            }
          />
        )}
        {builtFormFields}
      </FormGroupCard>
    </MainSection>
  );
}

const MainSection = styled.section`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export default withTheme(ContactParametersPage);
