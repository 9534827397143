import Button from 'fragments/button/Button';
import { t } from 'internal/i18n';
import React, { useMemo } from 'react';

type Props = {
  canValidResa: boolean | '' | undefined;
  hasCheckin: boolean;
  handleOpenCheck: () => void;
  submitResa: () => void;
};

const ReservationValid = ({ canValidResa, hasCheckin, handleOpenCheck, submitResa }: Props) => {
  const renderValidRes = useMemo(() => {
    if (canValidResa) {
      const action = hasCheckin ? handleOpenCheck : submitResa;

      return (
        <div>
          <>
            <Button
              label={t('reservations.confirm_resa')}
              className={'btn btn-primary validresa btn-fit'}
              onClick={action}
            />
          </>
          <br />
          <br />
        </div>
      );
    }

    return null;
  }, [canValidResa, hasCheckin]);

  return <>{renderValidRes}</>;
};

export default ReservationValid;
