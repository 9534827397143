import { selectCollAndDeliveryPointID } from 'auth/selectors/authSelectors';
import useApi, { Methods } from 'internal/hooks/useApi';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ArticleTypology from 'users/constants/article';
import { BIKE_AVAILABILITY_ENDPOINT } from 'users/constants/endpoints';
import { RentalPriceType } from 'users/types/forms';
import { computeRentalItems } from 'users/utils/resabike';

type Props = {
  selectedPriceFormula: RentalPriceType | null;
  rentalDurationTrads: string;
  durationText: string;
  startingDate: string;
  endingDate: string;
  selectedBikeType: string;
};

const DurationBloc = ({
  selectedPriceFormula,
  rentalDurationTrads,
  durationText,
  startingDate,
  endingDate,
  selectedBikeType,
}: Props): ReactElement => {
  const { t } = useTranslation(['old']);
  const { id_coll, id_pdl } = useSelector(selectCollAndDeliveryPointID);

  const [bikeAvailability, saveBikeAvailability] = useState(0);

  const { request: fetchBikeAvailability, response: bikeAvailabilityResponse } = useApi<{ nb_velo: number }>(
    Methods.GET,
    BIKE_AVAILABILITY_ENDPOINT
  );

  useEffect(() => {
    if (id_coll && id_pdl && selectedBikeType && startingDate && endingDate) {
      fetchBikeAvailability({
        params: {
          id_coll,
          id_pdl,
          article: ArticleTypology.BIKE,
          id_type: selectedBikeType,
          date_debut: startingDate,
          date_fin: endingDate,
        },
      });
    }
  }, [fetchBikeAvailability, id_coll, id_pdl, selectedBikeType, startingDate, endingDate]);

  useEffect(() => {
    if (bikeAvailabilityResponse?.success && bikeAvailabilityResponse.data) {
      saveBikeAvailability(bikeAvailabilityResponse.data.nb_velo);
    }
  }, [bikeAvailabilityResponse]);

  return (
    <>
      <label>{t('users.rent_duration')}</label>
      <div className={'alert alert-success fade show'} role={'alert'} id={'info_cons_park'}>
        {t('users.max_duration')}
        <span id={'duree_minmax_cons_park'}>
          {selectedPriceFormula?.min_duree && (
            <strong>
              {' '}
              {selectedPriceFormula?.min_duree} {rentalDurationTrads}
              {(selectedPriceFormula?.min_duree || 0) > 1 && rentalDurationTrads !== 'mois' ? 's ' : ' '}
            </strong>
          )}
          {selectedPriceFormula?.max_duree && selectedPriceFormula?.max_duree > 1 && (
            <>
              {' / '}
              <strong>
                {selectedPriceFormula?.max_duree} {rentalDurationTrads}
                {rentalDurationTrads === 'mois' ? '' : 's'}
              </strong>
            </>
          )}
        </span>
        <br />
        {t('users.selected_duration')}
        <span id={'duree_sel_cons_park'}>{durationText}</span>
        <br />
        {t('users.availability')}
        <span id={'info_cons_park_dispo'}>
          {startingDate && endingDate ? (
            <strong>
              {' '}
              {bikeAvailability}{' '}
              {t('users.availability_details', {
                item: `${computeRentalItems(t, ArticleTypology.BIKE)}${bikeAvailability > 1 ? 's' : ''}`,
              })}
            </strong>
          ) : (
            ' choisissez une date de début et une date de fin...'
          )}
        </span>
      </div>
    </>
  );
};

export default DurationBloc;
