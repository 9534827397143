import React, { useEffect } from 'react';
import { NavLink, generatePath, Outlet, useLocation, useMatch, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { Tab, Tabs, TabsList } from '../../../components/Core/Tabs/Tabs';
import { TabComponent } from '../../Reservation/Reservation.layout';
import { instance } from '../../../api/user.api';
import { selectCollAndDeliveryPointID } from '../../../auth/selectors/authSelectors';

function FormsLayout() {
  const { t } = useTranslation(['admin/formParameters']);
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const navigate = useNavigate();
  const location = useLocation();
  const match = useMatch('/administration/formulaires/:formSlug');

  const { data: formListResponse } = useQuery(
    ['param/forms', { id_coll }],
    ({ queryKey }) => {
      const [, queryParams] = queryKey;
      return instance.get('param/forms', { params: queryParams });
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 400_000,
    }
  );

  useEffect(() => {
    if (formListResponse && !match) navigate(Object.keys(formListResponse.data || {})[0]);
  }, [formListResponse, location, match]);

  return (
    <div className={'reset'}>
      <div id={'pages-config-layout'}>
        <Tabs>
          <TabsList>
            {Object.keys(formListResponse?.data || {}).map((formSlug) => (
              <Tab
                key={'tabs.'.concat(formSlug)}
                component={({ children, ...otherProps }: TabComponent) => (
                  <NavLink {...otherProps} to={generatePath(formSlug)} end>
                    {children}
                  </NavLink>
                )}>
                {t(formSlug)}
              </Tab>
            ))}
          </TabsList>
        </Tabs>
      </div>

      <Outlet context={formListResponse} />
    </div>
  );
}

export default FormsLayout;
