/** @jsxImportSource @emotion/react */
import React, { useCallback } from 'react';
import { css } from '@emotion/react';
import { useForm } from 'react-hook-form';
import { Row, RowItem } from '../../../../../components/Core/Datagrid/Datagrid';
import { GridColDefinition } from '../../../../../components/Core/Datagrid/gridCols';
import { GridValidRowModel } from '../../../../../components/Core/Datagrid/gridRows';

type DepositRowProps = {
  columns: GridColDefinition[];
  row: GridValidRowModel;
  index: number;
  colsTemplate: string;
};

function DepositRow({ columns, row, index, colsTemplate }: DepositRowProps) {
  const useFormReturn = useForm({ mode: 'onChange' });

  const { isDirty, dirtyFields } = useFormReturn.formState;

  const buildCell = useCallback(
    (column: GridColDefinition, index: number) => {
      if (column.type === 'formField') {
        return (
          <div
            key={'value-' + column.field + index}
            css={css`
              display: grid;
              align-items: center;
            `}>
            {column.renderCell({ row, ...useFormReturn, columnDef: column })}
          </div>
        );
      }

      return (
        <RowItem key={'value-' + column.field + index}>
          <span>{row[column.field]}</span>
        </RowItem>
      );
    },
    [row, isDirty, dirtyFields]
  );

  return (
    <Row key={'line'.concat(index.toString())} colsTemplate={colsTemplate}>
      {columns.map((column, index) => buildCell(column, index))}
    </Row>
  );
}

export default DepositRow;
