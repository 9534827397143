import UserRights from 'auth/constants/userRights';
import dayjs from 'dayjs';
import {
  INVOICES,
  USERS,
  VALIDATION,
  BOOKINGS,
  EDIT,
  CONTRACTS,
  RESA_DETAIL,
  CHECKOUT,
} from 'internal/constants/routes';
import { t } from 'internal/i18n';
import React from 'react';
import { Link } from 'react-router-dom';
import { AccessoryType } from 'reservations/types/reservation';

import {
  RawUserInvoiceType,
  UserDetailsType,
  UserInvoiceTableType,
  UserListTableType,
  RawUserContractType,
  UserContractTableType,
  UserReservationTableType,
  RawUserReservationType,
  RawUserCheckoutType,
  UserCheckoutTableType,
} from 'users/types/users';

export const formatUsersInvoices = (rawInvoices: RawUserInvoiceType[], token: string): UserInvoiceTableType[] =>
  // because of auto format bug
  rawInvoices.map(
    // eslint-disable-next-line object-curly-newline
    ({
      montant_article_ttc,
      montant_garantie_ttc,
      total_ttc: amount,
      operation,
      date_facture: date,
      media_url,
      devise,
    }) => ({
      securityDepositAmount: montant_garantie_ttc,
      articleAmount: montant_article_ttc,
      hasSecurityDeposit: !!montant_garantie_ttc && parseFloat(montant_garantie_ttc) > 0,
      amount: parseFloat(amount),
      operation,
      date: `${dayjs(date).format('DD/MM/YYYY')}`,
      devise,
      actions: (
        <a href={`${media_url}&token=${token}`} target={'_blank'}>
          {t('users.see_print')}
        </a>
      ),
    })
  );

export const formatUserList = (rawUserList: UserDetailsType[], userRights: string[]): UserListTableType[] =>
  // prettier-ignore
  rawUserList.map(({
 nom, prenom, email, adresse, telephone, valid_bo, nb_attente, cp, ville, id_user,
}) => ({
    name: `${prenom} ${nom}`,
    address: (
      <>
        {adresse}, {cp} {ville}
        <br />
        {email}
        <br />
        {telephone}
      </>
    ),
    actions:
      valid_bo === 0 ? (
        <Link className={'text-danger'} to={`${USERS}${VALIDATION}?userID=${id_user}`}>
          <i className={'icon ti-check'} /> {t('users.confirm_user')}
        </Link>
      ) : (
        <>
          {userRights.includes(UserRights.USERS_DETAIL_READ) && (
            <Link to={`${USERS}${EDIT}?userID=${id_user}`}>
              <i className={'icon ti-pencil'} /> {t('users.edit_file')}
            </Link>
          )}
          <br />
          {userRights.includes(UserRights.USERS_FUND_READ) && (
            <Link to={`${USERS}${CHECKOUT}?userID=${id_user}`}>
              <i className={'icon ti-credit-card'} /> {t('users.cashings')}
            </Link>
          )}
          <br />
          {userRights.includes(UserRights.USERS_CONTRACT_READ) && userRights.includes(UserRights.USERS_RESA_READ) && (
            <>
              <Link to={`${USERS}${INVOICES}?userID=${id_user}`}>
                <i className={'icon ti-file'} />
                {t('users.edit.invoices_access')}
              </Link>{' '}
              -{' '}
              <Link to={`${USERS}${CONTRACTS}?userID=${id_user}`}>
                <i className={'icon ti-files'} />
                {t('users.edit.contracts_access')}
              </Link>
            </>
          )}
          <br />
          {userRights.includes(UserRights.USERS_RESA_READ) && userRights.includes(UserRights.USERS_RESA_READ) && (
            <Link className={nb_attente > 0 ? 'text-danger' : ''} to={`${USERS}${BOOKINGS}?userID=${id_user}`}>
              <i className={'icon ti-shopping-cart'} /> {t('users.edit.bookings')}{' '}
              {nb_attente > 0 ? `(${nb_attente} en attente)` : ''}
            </Link>
          )}
        </>
      ),
  }));

export const formatUsersContracts = (rawContracts: RawUserContractType[], token: string): UserContractTableType[] =>
  rawContracts.map(({ date_end: dateEnd, operation, date_start: dateStart, media_url }) => ({
    dateContract: `${dayjs(dateStart).format('DD/MM/YYYY')} - ${dayjs(dateEnd).format('DD/MM/YYYY')}`,
    dateEnd,
    operation,
    actions: (
      <a href={`${media_url}&token=${token}`} target={'_blank'}>
        {t('users.see_print')}
      </a>
    ),
  }));

export const formatAccessories = (accessories: AccessoryType[]): string => {
  if (!accessories || accessories.length === 0) {
    return '';
  }

  return `+ ${accessories.map(({ id_interne, nom_type }) => `${nom_type} (${id_interne})`).join(' + ')}`;
};

export const formatQueue = (queue: number): string => {
  if (!queue) {
    return '';
  }

  return `- ${t('reservations.waiting_list', { queue })}`;
};

export const formatUsersReservations = (
  rawReservations: RawUserReservationType[],
  userID: string
): UserReservationTableType[] =>
  rawReservations.map(
    // prettier-ignore
    ({
      id_reservation,
      date_end: dateEnd,
      statut: status,
      date_start: dateStart,
      montant_article_ttc,
      montant_depot_garantie_ttc,
      article,
      total_ttc: amount,
      id_paiement,
      bookingMean,
      devise,
      nom_pdl,
      nom_empl,
      nom,
      prenom,
      accessoires,
      queue_position: queue,
    }) => ({
      dateReservation:
        dateStart &&
        dateEnd &&
        `${dayjs(dateStart).format('DD/MM/YYYY HH:mm')} - ${dayjs(dateEnd).format('DD/MM/YYYY HH:mm')}`,
      dateStart,
      operation: `${article} ${formatAccessories(accessoires)} ${formatQueue(queue)}`,
      usagers: `${prenom} ${nom}`,
      bookingMean,
      securityDepositAmount: montant_depot_garantie_ttc,
      articleAmount: montant_article_ttc,
      hasSecurityDeposit: !!montant_depot_garantie_ttc && parseFloat(montant_depot_garantie_ttc) > 0,
      amount: parseFloat(amount),
      id_paiement,
      status,
      devise,
      pdl: nom_pdl !== '' ? nom_pdl : nom_empl,
      actions: (
        <Link to={`${USERS}${RESA_DETAIL}?resaID=${id_reservation}&userID=${userID}`}>
          {t('users.see_validate_back')}
        </Link>
      ),
    })
  );

export const formatUsersCheckouts = (rawCheckouts: RawUserCheckoutType[]): UserCheckoutTableType[] =>
  rawCheckouts.map(
    ({
      date_created: date,
      operation: checkoutOperation,
      montant_ttc: amount,
      moyen_paiement: paymentMethod,
      devise,
    }) => ({
      dateCheckout: `${dayjs(date).format('DD/MM/YYYY HH:mm')}`,
      checkoutOperation,
      amount,
      paymentMethod,
      devise,
    })
  );
