import React from 'react';
import styled from '@emotion/styled';
import { getColor } from '../../../utils/style';

type LoaderProps = {
  width?: number;
  color?: string;
};

const ListLoader = (props: LoaderProps) => {
  return (
    <LoaderRoot {...props}>
      <div />
      <div />
      <div />
      <div />
    </LoaderRoot>
  );
};

const LoaderRoot = styled.div<LoaderProps>`
  display: inline-block;
  position: relative;
  width: ${({ width = 20 }) => `${width}px`};
  height: ${({ width = 20 }) => `${width}px`};
  overflow: hidden;

  & div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: ${({ width = 20 }) => `${(width / 3) * 2}px`};
    height: ${({ width = 20 }) => `${(width / 3) * 2}px`};
    //width: 64px;
    //height: 64px;
    //margin: 8px;
    border: 2px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${({ color, ...props }) => (color === 'primary' ? getColor('primary.200')(props) : 'white')}
      transparent transparent transparent;
  }

  & div:nth-child(1) {
    animation-delay: -0.45s;
  }

  & div:nth-child(2) {
    animation-delay: -0.3s;
  }

  & div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
`;

export default ListLoader;
