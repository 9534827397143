import { useInstanceApi } from '../../../api/axios';
import { GridDataState, useRequest } from '../../../utils/request';

const URL = {
  fetchDelayList: 'param/retards',
  putDelay: 'param/updretard',
  postDelay: 'param/addretard',
};

function useDelayListApi() {
  const instance = useInstanceApi();

  const { request: fetchDelayList } = useRequest((queryParams: GridDataState & { id_coll: number }) =>
    instance.get(URL.fetchDelayList, {
      params: {
        id_coll: queryParams.id_coll,
        p: queryParams.currentPage,
        ipp: queryParams.itemPerPage,
      },
    })
  );

  const { request: putDelay } = useRequest((data) => instance.post(URL.putDelay, data));

  const { request: postDelay } = useRequest((data) => instance.post(URL.postDelay, data));

  return {
    fetchDelayList,
    putDelay,
    postDelay,
  };
}

export default useDelayListApi;
