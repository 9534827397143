import { SERVICE_KEYS } from 'ioc/keys';
import { inject, injectable } from 'inversify';
import { AxiosResponse } from 'axios';
import { SHA256 } from 'crypto-js';
import { Api } from '../api/axios';
import { UserType } from '../auth/types/user';
import { PWD_SEED } from '../internal/constants/api';
import { UserDetailsType } from '../users/types/users';

const ENDPOINTS = {
  signIn: 'user/signin',
  post: 'user/create',
  getAll: 'user/list',
  get: 'user/details',
  put: 'user/details',
  getReservations: 'user/reservations',
};

export type PostUserBody = {
  email: string;
  password: string;
};

export type GetAllUsersQueryParams = {
  id_coll: number;
  p: number;
  ipp: number;
  filter: string;
  'order[column]': string;
  'order[dir]': string;
  filterStatus?: string;
};

export type GetAllResult = {
  result: UserDetailsType[];
  total: number;
  filters: {
    filter: string;
    filterStatus: string;
  };
};

export type GetAllBookingsForUserQueryParams = {
  id_user: string;
  id_coll: number;
  p: number;
  ipp: number;
  filter: string;
  'order[column]': string;
  'order[dir]': string;
  filterStatus?: string;
};

export type GetAllBookingsForUserResult = {
  currentPage: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filters: any;
  itemPerPage: number;
  order: {
    column: string;
    dir: string;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  result: any;
  total: number;
  totalPage: number;
};

// eslint-disable-next-line
export type PostUserBodyParams = Record<string, any>;

@injectable()
export class UserRepository {
  @inject(SERVICE_KEYS.API_INSTANCE)
  private _apiInstance: Api | undefined;

  getAll(queryParams: GetAllUsersQueryParams): Promise<AxiosResponse<GetAllResult>> | undefined {
    return this._apiInstance?.instance.get(ENDPOINTS.getAll, { params: queryParams });
  }

  signIn(params: PostUserBody): Promise<AxiosResponse<UserType>> | undefined {
    const { email, password } = params;
    return this._apiInstance?.instance.post(ENDPOINTS.signIn, {
      email,
      password: SHA256(password + PWD_SEED).toString(),
    });
  }

  getOneById(queryParams: { id_user: string; id_coll: number }): Promise<AxiosResponse<UserDetailsType>> | undefined {
    return this._apiInstance?.instance.get(ENDPOINTS.get, { params: queryParams });
  }

  post(bodyParams: PostUserBodyParams): Promise<AxiosResponse<{ id_usager: string }>> | undefined {
    return this._apiInstance?.instance.post(ENDPOINTS.post, bodyParams);
  }

  update(bodyParams: PostUserBodyParams): Promise<AxiosResponse<UserType>> | undefined {
    return this._apiInstance?.instance.post(ENDPOINTS.put, bodyParams);
  }

  getReservations(
    queryParams: Partial<GetAllBookingsForUserQueryParams>
  ): Promise<AxiosResponse<GetAllBookingsForUserResult>> | undefined {
    return this._apiInstance?.instance.get(ENDPOINTS.getReservations, { params: queryParams });
  }
}
