import { CollectivityType, DeliveryPointType, UserCollectivityType } from 'auth/types/collectivity';
import { RawUserRightsType, UserRightsType } from 'auth/types/user';
import { SelectOptionType } from 'internal/types/select';

export const mapCollectivityListToOptions = (collectivityList: CollectivityType[]): SelectOptionType[] => {
  return collectivityList.map(({ nom: label, id_coll }: CollectivityType) => ({
    label,
    value: id_coll.toString(),
  }));
};

export const mapUserCollectivityListToOptions = (collectivityList: UserCollectivityType[]): SelectOptionType[] => {
  return collectivityList.map(({ nom_coll: label, id_coll }: UserCollectivityType) => ({
    label,
    value: id_coll.toString(),
  }));
};

export const mapDeliveryPointListToOptions = (deliveryPointList: DeliveryPointType[]): SelectOptionType[] => {
  return deliveryPointList.map(({ nom_pdl: label, id_pdl }: DeliveryPointType) => ({
    label,
    value: id_pdl.toString(),
  }));
};

export const chosenPdlFinder = (
  devliveryPoints: DeliveryPointType[],
  chosenValue: number
): DeliveryPointType | undefined => devliveryPoints.find(({ id_pdl }: DeliveryPointType) => id_pdl === chosenValue);

export const formatUserRights = (rawUserRights: RawUserRightsType): UserRightsType => {
  const global = Object.keys(rawUserRights).reduce<string[]>((acc, globalRight) => {
    const actuelRightsIteration = rawUserRights[globalRight];

    if (actuelRightsIteration.global) {
      return [...acc, globalRight];
    }
    return acc;
  }, []);

  const rights = Object.keys(rawUserRights).reduce<string[]>((acc: string[], globalRight: string) => {
    const actuelRightsIteration = rawUserRights[globalRight];

    if (actuelRightsIteration.global) {
      const fullChildrenRights = actuelRightsIteration.rights.reduce(
        (acc: string[], childUserRight: Record<string, boolean>) => {
          const right = Object.keys(childUserRight);
          const [key] = right;

          if (childUserRight[key as string]) {
            return [...acc, key];
          }
          return acc;
        },
        []
      );

      return [...acc, ...fullChildrenRights];
    }
    return acc;
  }, []);

  return {
    global,
    rights,
  };
};
