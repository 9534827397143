import React, { ComponentPropsWithoutRef, InputHTMLAttributes, useState } from 'react';
import { FieldError } from 'react-hook-form';
import _ from 'lodash';
import styled from '@emotion/styled/macro';
import FormControl from '../../FormControl/FormControl';
import Label from '../../Label/Label';
import { getColor } from '../../../../utils/style';
import Checkbox from '../../Checkbox/Checkbox';

type CheckboxFieldProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> &
  Omit<ComponentPropsWithoutRef<typeof Checkbox>, 'error'> & {
    label: string;
    error?: FieldError;
  };

function CheckboxField(props: CheckboxFieldProps) {
  const { label, error, className, defaultValue, ...checkboxInputProps } = props;
  const [id] = useState(_.uniqueId('phone-input-'));

  return (
    <FormControl value={checkboxInputProps.value} defaultValue={defaultValue} error={!!error}>
      <FieldSet data-testid={'phone-field'} className={className}>
        <Checkbox {...checkboxInputProps} />
        <Label htmlFor={id}>
          <strong>{label}</strong>
        </Label>
        {error && <ErrorMessage>{error.message}</ErrorMessage>}
      </FieldSet>
    </FormControl>
  );
}

const ErrorMessage = styled.span`
  font-size: 12px;
  color: ${getColor('red.200')};
`;

const FieldSet = styled.fieldset`
  display: flex;
  align-items: center;
  gap: 8px;

  > div:hover + ${ErrorMessage} {
    color: ${getColor('red.300')};
  }
`;

export default CheckboxField;
