import React, { InputHTMLAttributes, useState } from 'react';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { ClassNames, ClassNamesContent } from '@emotion/react';
import { getColor } from '../../../utils/style';
import useInput, { UseInputParameters } from '../InputBase/useInput';

type InputBaseProps = InputHTMLAttributes<HTMLInputElement> & UseInputParameters;

function PhoneInputBase(props: InputBaseProps) {
  const { onChange: onChangeProp, error, value, ...propsToForward } = props;
  const { onChange, ...inputProps } = useInput<string>({
    onChange: onChangeProp,
    error,
    value,
  });
  const [isFocus, setIsFocus] = useState(false);

  const onFocus = () => {
    setIsFocus(true);
  };

  const onBlur = () => {
    setIsFocus(false);
  };

  return (
    <ClassNames>
      {(classNamesContent) => (
        <ReactPhoneInput
          onFocus={onFocus}
          onBlur={onBlur}
          containerClass={containerStyle(classNamesContent, isFocus, inputProps.error)}
          inputClass={inputStyle(classNamesContent)}
          buttonClass={buttonStyle(classNamesContent)}
          onChange={(value) => onChange(value)}
          {...inputProps}
          {...propsToForward}
          inputProps={{
            'data-testid': 'phone-input',
          }}
          country={'fr'}
        />
      )}
    </ClassNames>
  );
}

const containerStyle = ({ theme, css }: ClassNamesContent, isFocus: boolean, error: boolean) => css`
  & .form-control {
    width: 100%;
    border-color: ${getColor(error ? 'red.200' : isFocus ? 'primary.200' : 'gray.200')({ theme })};
  }

  &:hover .form-control {
    border-color: ${getColor(error ? 'red.300' : isFocus ? 'primary.300' : 'gray.300')({ theme })};
  }

  & .selected-flag:hover,
  & .selected-flag:focus {
    background-color: transparent;
  }

  & .flag-dropdown.open,
  & .flag-dropdown.open .selected-flag {
    border-radius: 8px 0 0 8px;
    background: none;
  }

  & .selected-flag .arrow {
    left: 24px;
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: translateY(-50%) scale(var(--ggs, 1));
    width: 10px;
    height: 10px;
    border: 1px solid transparent;
    border-radius: 100px;
    margin-top: -3px;
  }

  & .arrow::after {
    content: '';
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 5px;
    height: 5px;
    border-bottom: 1px solid ${getColor('gray.300')({ theme })};
    border-right: 1px solid ${getColor('gray.300')({ theme })};
    transform: rotate(45deg);
    top: 3px;
  }

  & .selected-flag .arrow.up {
    left: 21px;
    margin-top: -2px;
    border: 1px solid transparent;
    transform-style: preserve-3d;
    transform-origin: center;
    transform: scale(-1) translateY(0);
  }

  & .country-list {
    border-radius: 8px;
  }
`;

const inputStyle = ({ css }: ClassNamesContent) => css`
  &.form-control {
    font-size: 14px;
    padding: 8px 12px;
    padding-left: 48px;
    transition: all 0.3s;
    border-radius: 8px;
  }

  &.form-control:focus {
    box-shadow: none;
  }
`;

const buttonStyle = ({ css }: ClassNamesContent) => css`
  &.flag-dropdown {
    background-color: transparent;
    border: none;
    border-radius: 8px 0 0 8px;
  }
`;

export default PhoneInputBase;
