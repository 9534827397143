import React, { ButtonHTMLAttributes } from 'react';
import styles from './button.module.scss';

type ButtonBaseProps<T extends React.ElementType> = ButtonHTMLAttributes<HTMLButtonElement> & {
  as?: T;
};

function ButtonBase<T extends React.ElementType = 'button'>(
  {
    children,
    as,
    className,
    ...propsToForward
  }: ButtonBaseProps<T> & Omit<React.ComponentPropsWithoutRef<T>, keyof ButtonBaseProps<T>>,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const Component = as ?? 'button';

  return (
    <Component className={[styles.Button, className].join(' ')} {...propsToForward} ref={ref}>
      {children}
    </Component>
  );
}

export default React.forwardRef(ButtonBase);
