import Devises from 'internal/constants/devises';
import SnackbarSeverity from 'internal/constants/snackbar';
import {
  ChangeLocaleActionType,
  HideSnackbarActionType,
  SetCurrencyActionType,
  SetLocalesActionType,
  SetSnackbarActionType,
  ShowSnackbarActionType,
} from 'internal/types/actions';

export const SET_SNACKBAR_CONTENT = 'SET_SNACKBAR_CONTENT';
export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
export const HIDE_SNACKBAR = 'HIDE_SNACKBAR';
export const CHANGE_LOCALE = 'CHANGE_LOCALE';
export const SET_LOCALES = 'SET_LOCALES';
export const SET_CURRENCY = 'SET_CURRENCY';

export const setSnackbar = (content: string, severity: SnackbarSeverity): SetSnackbarActionType => ({
  type: SET_SNACKBAR_CONTENT,
  payload: {
    content,
    severity,
  },
});

export const showSnackbar = (): ShowSnackbarActionType => ({
  type: SHOW_SNACKBAR,
});

export const hideSnackbar = (): HideSnackbarActionType => ({
  type: HIDE_SNACKBAR,
});

export const changeLocale = (locale: string): ChangeLocaleActionType => ({
  type: CHANGE_LOCALE,
  payload: {
    locale,
  },
});

export const setLocales = (locales: string[]): SetLocalesActionType => ({
  type: SET_LOCALES,
  payload: {
    locales,
  },
});

export const setCurrency = (currency: Devises): SetCurrencyActionType => ({
  type: SET_CURRENCY,
  payload: {
    currency,
  },
});
