import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { useForm } from 'react-hook-form';
import { useSnackbar } from '../../../context/SnackbarContext';
import { selectCollAndDeliveryPointID } from '../../../auth/selectors/authSelectors';
import useGenerateFields, { Field } from '../../../hook/useGenerateFields';
import { instance } from '../../../api/user.api';
import { TOAST_SEVERITY } from '../../../components/Core/Toast/Toast';
import MainSection from '../../../components/Core/MainSection/MainSection';
import Card from '../../../components/Core/Card/Card';
import Button from '../../../components/Core/Button/Button';
import ParametersSync from '../../../parameters/components/ParametersSync';
import { CollectivityAdminParamsResponse } from './CollectivityAdminParams.layout';
import styles from './AlwinParameters.module.scss';

type VelocareRawBodyForm = { velocareBusinessId: number | null; velocareSubsidiaryId: number };

function useVelocareForm() {
  const { t } = useTranslation(['admin/collectivityAdmin']);
  return useMemo<Field[]>(() => {
    return [
      {
        type: 'number',
        label: t('velocareSubsidiaryId'),
        name: 'velocareSubsidiaryId',
      },
      {
        type: 'text',
        label: t('velocareBusinessId'),
        name: 'velocareBusinessId',
      },
    ];
  }, [t]);
}

function VelocareParamsPage() {
  const { t } = useTranslation(['form']);
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();
  const collectivityAdminParamsResponse = useOutletContext<
    AxiosResponse<CollectivityAdminParamsResponse> | undefined
  >();
  const defaultValues = useMemo(() => {
    if (collectivityAdminParamsResponse) {
      const {
        data: {
          admin: { velocareBusinessId, velocareSubsidiaryId },
        },
      } = collectivityAdminParamsResponse;
      return { velocareBusinessId, velocareSubsidiaryId };
    }
  }, [collectivityAdminParamsResponse]);
  const { control, handleSubmit } = useForm({ defaultValues });
  const fields = useVelocareForm();
  //eslint-disable-next-line
  const form = useGenerateFields({ fields, control: control as any });

  const { mutate: postAlwinParams } = useMutation(
    ['param/admin'],
    (bodyFormRaw: VelocareRawBodyForm & { id_coll: number }) => {
      return instance.post('param/admin', bodyFormRaw);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['param/admin']);
        snackbar?.setAlert({ message: t('form:global_update_success_message'), severity: TOAST_SEVERITY.success });
      },
    }
  );

  const onSubmit = useCallback(
    handleSubmit((data) => {
      postAlwinParams({ ...data, id_coll });
    }),
    [id_coll]
  );

  return (
    <MainSection className={'reset'}>
      <Card
        content={
          <>
            <form onSubmit={onSubmit}>
              <div className={styles.FormGrid}>{form}</div>
              <div className={'row justify-end mt-4'}>
                <Button type={'submit'}>{t('form:update')}</Button>
              </div>
            </form>
            <ParametersSync />
          </>
        }
      />
    </MainSection>
  );
}

export default VelocareParamsPage;
