export const GET_USER_RESERVATION_ENDPOINT = 'user/reservation';
export const GET_USER_SIGN_ENDPOINT = 'user/signresa';
export const OTHER_BIKES_ENDPOINT = 'user/dispother';
export const CHANGE_BIKE_ENDPOINT = 'user/changeresa';
export const SIGN_RESA_ENDPOINT = 'user/signresa';
export const CONFIRM_RESA_ENDPOINT = 'user/validresa';
export const STD_EXCHANGE_ENDPOINT = 'user/echangeresa';
export const CANCEL_RESA_ENDPOINT = 'user/cancelresa';
export const FINISH_RESA_ENDPOINT = 'user/endresa';
export const POST_ATTACHMENTS_ENDPOINT = 'user/media64';
export const GET_ATTACHMENTS_ENDPOINT = 'user/pj';
export const SELECT_DEGRADATION_ENDPOINT = 'param/degradations';
export const SELECT_DELAY_ENDPOINT = 'param/retards';
export const POST_DELAY_DEGRADATION_ENDPOINT = 'user/caisseretdeg';
export const POST_REGULARIZATION_ENDPOINT = 'user/caisseregul';
export const GET_DELAY_DEGRADATION_ENDPOINT = 'user/caisse';
export const GET_CHECKIN_ENDPOINT = 'checkin/checkin';
export const GET_CHECKOUT_ENDPOINT = 'checkin/checkout';
export const REFUND_DEPOSIT_USER = 'user/rbt';
