import Joi from 'joi';
import * as iban from 'ibantools';

const JoiExtended = Joi.extend((joi) => {
  return {
    base: joi.string(),
    type: 'bank',
    messages: {
      'string.invalidIBAN': 'error invalid iban',
      'string.invalidSWIFTBIC': 'error invalid swift bic',
    },
    rules: {
      iban: {
        method(strict: boolean) {
          return this.$_addRule({ name: 'iban', args: { strict } });
        },
        args: [
          {
            name: 'strict',
            ref: true,
            assert: (value) => typeof value === 'boolean',
            message: 'must be a boolean',
          },
        ],
        validate(value, helpers, args) {
          let newVal = value;

          // If strict mode is NOT on, we can convert the input into the machine format
          // If strict mode is on, do not convert, which will throw errors if it's not already in machine format
          if (args.strict !== true) {
            newVal = iban.electronicFormatIBAN(value);
          }

          if (iban.isValidIBAN(newVal)) return newVal;
          return helpers.error('string.invalidIBAN', { value });
        },
      },
      swiftbic: {
        validate(value, helpers) {
          if (iban.isValidBIC(value)) return value;
          return helpers.error('string.invalidSWIFTBIC', { value });
        },
      },
    },
  };
});

export default JoiExtended;
