import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useInjection } from '../../../../ioc/ioc.react';
import { GetAllDepositsResult, ParameterRepository } from '../../../../repository/parameter.repository';
import { selectCollAndDeliveryPointID } from '../../../../auth/selectors/authSelectors';
import { SERVICE_KEYS } from '../../../../ioc/keys';

function withDepositListData<T>(WrappedComponent: React.ComponentType<T>) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const ComponentWithDepositListData = (props: Omit<T, 'depositList'>) => {
    const { id_coll } = useSelector(selectCollAndDeliveryPointID);
    const parameterRepository = useInjection<ParameterRepository>(SERVICE_KEYS.PARAMETER_REPOSITORY);
    const [depositList, setDepositList] = useState<GetAllDepositsResult>({});

    useEffect(() => {
      async function fetchList() {
        const depositsResponse = await parameterRepository.getAllDeposits({
          id_coll,
        });

        if (depositsResponse?.data) {
          setDepositList(depositsResponse.data);
        }
      }

      fetchList();
    }, [id_coll]);

    return <WrappedComponent depositList={depositList} {...(props as T)} />;
  };

  ComponentWithDepositListData.displayName = `withDepositListData(${displayName})`;

  return ComponentWithDepositListData;
}

export default withDepositListData;
