import styled from '@emotion/styled';
import React, { ComponentPropsWithoutRef, useCallback, useMemo } from 'react';
import { FieldError as FieldErrorType } from 'react-hook-form';
import { Option } from '../../hook/useBuildForm';
import FieldError from './FieldError/FieldError';
import FormControl from './FormControl/FormControl';
import Label from './Label/Label';
import SelectBase from './SelectBase/SelectBase';

type SelectProps = Omit<ComponentPropsWithoutRef<typeof SelectBase>, 'theme' | 'onChange'> & {
  label?: string;
  error?: FieldErrorType;
} & Pick<ComponentPropsWithoutRef<typeof FormControl>, 'onChange'>;

function SelectField(props: SelectProps) {
  const { className, label, onChange, required, error, value, defaultValue, ...selectProps } = props;

  const handleChange: (newValue: unknown) => void = useCallback(
    (newValue) => {
      if (selectProps.isMulti) {
        onChange?.((newValue as Option[]).map((option) => option.value));
      } else {
        onChange?.((newValue as Option).value);
      }
    },
    [onChange, selectProps.isMulti]
  );

  const optionSelected = useMemo(() => {
    return selectProps.isMulti
      ? (value as unknown[]).map((item) =>
          selectProps.options.find((option) =>
            typeof item === 'object' ? option.value === (item as Option).value : option.value === item
          )
        )
      : selectProps.options.find((option) =>
          typeof value === 'object'
            ? option.value?.toString() === (value as Option)?.value?.toString()
            : option.value === value
        );
  }, [value, selectProps.options]);

  return (
    <FormControl onChange={handleChange} error={!!error} value={optionSelected || null} defaultValue={defaultValue}>
      <div className={className}>
        {label && <Label required={required}>{label}</Label>}
        <Select defaultValue={defaultValue} {...selectProps} />
        {error && <FieldError>{error.message}</FieldError>}
      </div>
    </FormControl>
  );
}

const Select = styled(SelectBase)`
  font-size: 14px;
`;

export default SelectField;
