import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSubmit } from 'react-router-dom';
import Select from 'fragments/select/Select';
import background from 'assets/img/signin-background.jpg';
import { saveCollectivity, saveDeliveryPoint, saveUser } from 'auth/actions/authActions';
import AuthForm from 'auth/components/AuthForm';
import {
  chosenPdlFinder,
  mapCollectivityListToOptions,
  mapDeliveryPointListToOptions,
} from 'auth/services/authServices';
import { CollectivityType } from 'auth/types/collectivity';
import { UserType } from 'auth/types/user';
import { useTranslation } from 'react-i18next';
import { setApiToken } from '../../api/user.api';

const SignIn = (): ReactElement => {
  const { t } = useTranslation('signIn');
  const [localUser, saveLocalUser] = useState<Omit<UserType, 'coll'> | null>(null);
  const [collectivityList, saveCollectivityList] = useState<CollectivityType[]>([]);
  const [collectivity, _saveCollectivity] = useState<CollectivityType | null>(null);
  const navigate = useNavigate();
  const submit = useSubmit();

  const dispatch = useDispatch();

  const coll = useMemo(() => mapCollectivityListToOptions(collectivityList || []), [collectivityList]);

  const pdl = useMemo(() => {
    if (collectivity) {
      return mapDeliveryPointListToOptions(collectivity.pdl);
    }
    return [];
  }, [collectivity]);

  const handleSelectCollectivity = useCallback(
    (value: string) => {
      if (value) {
        const _coll = collectivityList?.find(({ id_coll }) => id_coll === parseInt(value, 10)) || {
          id_coll: 0,
          nom: '',
          pdl: [],
        };

        _saveCollectivity(_coll);
        dispatch(saveCollectivity(_coll.id_coll));
      }
    },
    [collectivityList]
  );

  const handleSelectDeliveryPoint = useCallback(
    (value: string) => {
      if (value) {
        const _pdl =
          collectivityList?.reduce(
            (acc, { pdl }) => ({
              ...acc,
              ...chosenPdlFinder(pdl, parseInt(value, 10)),
            }),
            { id_pdl: 0, nom_pdl: '' }
          ).id_pdl || 0;

        dispatch(saveDeliveryPoint(_pdl));
        dispatch(saveUser(localUser));

        setApiToken(localUser?.token || null).then((result) => {
          // result && navigate('/', { replace: true });
          result && submit(null, { method: 'post', action: '/connexion' });
        });
      }
    },
    [collectivityList, localUser, submit]
  );

  useEffect(() => {
    if (collectivity?.pdl.length && collectivity?.pdl.length < 2) {
      const [pdl] = collectivity.pdl;

      dispatch(saveDeliveryPoint(pdl.id_pdl));
      dispatch(saveUser(localUser));

      setApiToken(localUser?.token || null).then((result) => {
        result && navigate('/', { replace: true });
      });

      // navigate(DASHBOARD);
    }
  }, [collectivity, navigate, localUser]);

  return (
    <div
      className={'min-h-fullscreen bg-img center-vh p-20 pace-running'}
      style={{ backgroundImage: `url(${background})` }}
      data-overlay={7}>
      <div className={'card card-round card-shadowed px-50 py-30 w-400px mb-0'} style={{ maxWidth: '100%' }}>
        {localUser ? (
          <div id={'signinchoose'}>
            <h5 className={'text-uppercase'}>{t('selection')}</h5>
            <br />
            <p className={'text-left'}>
              {t('hello')}{' '}
              <span id={'names'}>
                {localUser.prenom} {localUser.nom}
              </span>
              ,
            </p>
            <Select
              id={'select_coll'}
              name={'select_coll'}
              label={t('collectivity')}
              handleChange={handleSelectCollectivity}
              options={[{ label: t('collectivitySelectPlaceholder'), value: '0' }, ...coll]}
            />
            <Select
              id={'select_pdl'}
              name={'select_pdl'}
              label={t('pdl')}
              handleChange={handleSelectDeliveryPoint}
              options={[{ label: t('pdlSelectPlaceholder'), value: '0' }, ...pdl]}
            />
          </div>
        ) : (
          <AuthForm saveCollectivityList={saveCollectivityList} saveLocalUser={saveLocalUser} />
        )}
      </div>
    </div>
  );
};

export default SignIn;
