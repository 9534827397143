import React, { HtmlHTMLAttributes } from 'react';
import { useDebounce } from 'use-debounce';
import { useTranslation } from 'react-i18next';
import ButtonWithIcon from '../../../components/Core/Button/ButtonWithIcon';
import LinearProgressBar from '../LinearProgressBar/LinearProgressBar';
import { CheckIcon } from '../../../const/icons';
import styles from './ExportButton.module.scss';

type ExportButtonProps = HtmlHTMLAttributes<HTMLDivElement> & {
  isSubmitting?: boolean;
  // state?: 'idle' | 'submitting' | 'loading';
  state?: string | null;
  link?: string | null;
  onDownload?: () => void;
  isLoading?: boolean;
};

function ExportButton({ state, className, onDownload }: ExportButtonProps) {
  const { t } = useTranslation(['stats/customExport']);
  const [debouncedState] = useDebounce(state, 500);

  return (
    <div className={[styles.Root, state === 'ready' ? styles.Downloaded : null, className].join(' ')}>
      {state === null ? (
        <ButtonWithIcon icon={'RefreshIcon'} type={'submit'}>
          {t('generateCta')}
        </ButtonWithIcon>
      ) : debouncedState !== 'ready' ? (
        <LinearProgressBar isLoading={state === 'loading'} />
      ) : (
        <>
          <span className={styles.SuccessText}>
            <CheckIcon width={16} />
            {t('generatedNotice')}
          </span>
          <ButtonWithIcon onClick={onDownload} icon={'DownloadIcon'}>
            {t('downloadCta')}
          </ButtonWithIcon>
        </>
      )}
    </div>
  );
}

export default ExportButton;
