/* eslint-disable @typescript-eslint/no-explicit-any */
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import en from 'internal/constants/en';

import fr from 'internal/constants/fr';
import { initReactI18next } from 'react-i18next';
import pjson from '../../package.json';

// Helper for populating a JSON with all the translation strings.
// With this, each locale will be populated with namespaces based on the filename
/*const compileStrings = (ctx: any) => {
  const compiled = { resources: {} as Record<string, unknown>, ns: [] as unknown[] };
  // This regex does not work for en-US, en-UK, etc.
  const fileRegex = /(?<context>\w*)\/(?<locale>\w+)\/(?<namespace>\w+)\.json$/;

  ctx.keys().forEach((file: any) => {
    const { namespace, locale } = file.match(fileRegex).groups;

    compiled.ns.push(namespace);
    compiled.resources[locale] = compiled.resources[locale] ? compiled.resources[locale] : {};
    (compiled.resources[locale] as Record<string, unknown>)[namespace] = ctx(file);
  });

  // Filter unique namespaces
  compiled.ns = compiled.ns.filter((value, index, self) => {
    return self.indexOf(value) === index;
  });

  return compiled;
};
export const strings = compileStrings(require.context('../../i18n', true, /\w+\.json$/));*/

// console.log(strings);

export const resources = {
  en,
  fr,
} as const;

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    returnNull: false,
    lng: 'fr',
    fallbackLng: 'fr',
    debug: true,
    // resources,
    interpolation: {
      escapeValue: false,
      skipOnVariables: false,
    },
    backend: {
      queryStringParams: { v: pjson.version },
    },
    /*react: {
      useSuspense: false,
    },*/
  });

i18n.addResourceBundle('fr', 'translation', fr);
i18n.addResourceBundle('en', 'translation', en);

export const t = i18n.t.bind(i18n);
export default i18n;
