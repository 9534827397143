enum Devises {
  EUR = '€',
  POUND = '£',
}

export const devisesIcons = {
  [Devises.EUR]: 'fa fa-euro',
  [Devises.POUND]: 'fa fa-gbp',
};

export default Devises;
