import React, { ChangeEvent, ReactElement, useCallback, useMemo } from 'react';
import DataTable, { Props as DataTableProps } from 'fragments/dataTable/DataTable';
import Pagination from 'fragments/pagination/Pagination';
import Loader from 'fragments/loader/Loader';
import { t } from 'internal/i18n';
import { Filters } from 'internal/constants/list';
import lodash from 'lodash';
import DateRangePicker from 'fund/components/DateRangePicker';

type Props<DataItemType> = {
  dataTableProps: DataTableProps<DataItemType>;
  perPage: number;
  page: number;
  maxItems: number;
  setSearch?: lodash.DebouncedFunc<React.Dispatch<React.SetStateAction<string>>> | ((search: string) => void);
  setPerPage: (value: number) => void;
  setPage?: (page: number) => void;
  setSearchUser?: (search: string) => void;
  handleClickNextPage?: () => void;
  handleClickPrevPage?: () => void;
  handlePageClick?: (page: number) => () => void;
  startDateBeginning?: Date | null;
  endDateBeginning?: Date | null;
  startDateClose?: Date | null;
  endDateClose?: Date | null;
  handleStartDateRange?: (startDate: string, endDate: string) => void;
  handleEndDateRange?: (startDate: string, endDate: string) => void;
  isSearchActive?: boolean;
  isLoading?: boolean;
  filters?: Filters;
  filtersChoose?:
    | {
        [key: string]: string;
      }
    | undefined;
  handleFilterChange?: (key: string) => (e: ChangeEvent<HTMLSelectElement>) => void;
};

// eslint-disable-next-line @typescript-eslint/comma-dangle
const DataHub = <DataItemType,>({
  setSearch = () => null,
  setPage = () => null,
  setSearchUser,
  setPerPage,
  dataTableProps,
  maxItems,
  perPage,
  page,
  isLoading = false,
  isSearchActive = false,
  filters,
  filtersChoose,
  handleFilterChange,
  handleStartDateRange,
  handleEndDateRange,
  startDateBeginning = null,
  endDateBeginning = null,
  startDateClose = null,
  endDateClose = null,
}: Props<DataItemType>): ReactElement => {
  const handleChangeItems = useCallback(
    //eslint-disable-next-line
    (e: any) => {
      setPerPage(parseInt(e.target.value, 10));
      setPage(1);
    },
    [setPerPage]
  );

  const handleClickNextPage = useCallback(() => {
    if (page + 1 <= Math.ceil(maxItems / perPage)) {
      setPage(page + 1);
    }
  }, [page, maxItems, setPage]);

  const handleClickPrevPage = useCallback(() => {
    if (page - 1 > 0) {
      setPage(page - 1);
    }
  }, [page, setPage]);

  const handlePageClick = useCallback(
    (_page: number) => () => {
      setPage(_page);
    },
    [setPage]
  );

  const renderFilters = useMemo(() => {
    const filtersSelects = [];

    if (filters && handleFilterChange) {
      for (const [key, value] of Object.entries(filters)) {
        const defaultValue = filtersChoose ? filtersChoose[key] : '';

        filtersSelects.push(
          <div className={'dataTables_length dataTables_div_filter'} id={'listuser_length'}>
            <div className={'btn-margin bold'}>{t(`filters.${key}`)}</div>
            <select
              name={'listuser_length'}
              aria-controls={'listuser'}
              className={'form-control form-control-sm dataTables_select filter-select'}
              onChange={handleFilterChange(key)}
              value={defaultValue}>
              <option value={''}>{t('filters.all')}</option>
              {value.map((option, i) => (
                <option key={`${i}-${option.value}`} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        );
      }
    }

    return filtersSelects;
  }, [filters, filtersChoose]);

  const renderStartDateRange = useMemo(() => {
    if (handleStartDateRange) {
      return (
        <>
          <div className={'dataTables_length dataTables_div_filter'}>
            <div className={'btn-margin bold'}>{t('reservations.date_start')}</div>
            <DateRangePicker
              handleChange={handleStartDateRange}
              defaultStartDate={startDateBeginning}
              defaultEndDate={endDateBeginning}
              noDefault
            />
          </div>
        </>
      );
    }
  }, [handleStartDateRange, startDateBeginning, endDateBeginning]);

  const renderEndDateRange = useMemo(() => {
    if (handleEndDateRange) {
      return (
        <>
          <div className={'dataTables_length dataTables_div_filter'}>
            <div className={'btn-margin bold'}>{t('reservations.date_end')}</div>
            <DateRangePicker
              handleChange={handleEndDateRange}
              defaultStartDate={startDateClose}
              defaultEndDate={endDateClose}
              noDefault
            />
          </div>
        </>
      );
    }
  }, [handleStartDateRange, startDateClose, endDateClose]);

  return (
    <div className={'dataTables_wrapper container-fluid dt-bootstrap4 no-footer'}>
      <div className={'row'}>
        <div className={'col-sm-12 col-md-6'}>
          <div className={'dataTables_length'} id={'listuser_length'}>
            <div className={'bold'}>{t('global.display')}</div>
            <select
              name={'listuser_length'}
              aria-controls={'listuser'}
              className={'form-control form-control-sm dataTables_select'}
              onChange={handleChangeItems}>
              <option value={'10'}>10</option>
              <option value={'25'}>25</option>
              <option value={'50'}>50</option>
              <option value={'100'}>100</option>
            </select>{' '}
            <div className={'bold'}>{t('global.elements')}</div>
          </div>
          {renderFilters}
        </div>
        {isSearchActive && (
          <div className={'col-sm-12 col-md-6'}>
            <div id={'listuser_filter'} className={'dataTables_filter'}>
              <div className={'dataTables_filter-inpt'}>
                <div className={'btn-margin bold'}>{t('global.search')}</div>
                <input
                  type={'search'}
                  className={'form-control form-control-sm dataTables_inpt_inner'}
                  placeholder={''}
                  onChange={(e) => setSearch(e.target.value)}
                  aria-controls={'listuser'}
                />
              </div>
              {setSearchUser && (
                <div className={'dataTables_filter-inpt mt-3'}>
                  <div className={'btn-margin bold'}>{t('global.search_by_user')}</div>
                  <input
                    type={'search'}
                    className={'form-control form-control-sm dataTables_inpt_inner'}
                    placeholder={''}
                    onChange={(e) => setSearchUser(e.target.value)}
                    aria-controls={'listuser'}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {renderStartDateRange}
      {renderEndDateRange}
      <div className={'row'} style={{ marginTop: '2rem' }}>
        <div className={'col-md-12'}>{isLoading ? <Loader /> : <DataTable<DataItemType> {...dataTableProps} />}</div>
      </div>
      <div className={'row'}>
        <div className={'col-sm-12 col-md-5'}>
          {maxItems > 0 && (
            <div className={'dataTables_info'}>
              {t('users.display_first_el', { index: page === 1 ? 1 : perPage * (page - 1) + 1 })}
              {t('users.x_elements_displayed', {
                perPage: page * perPage > maxItems ? maxItems : perPage * page,
                count: maxItems,
              })}
            </div>
          )}
        </div>
        <div className={'col-sm-12 col-md-7 pagination-container'}>
          <Pagination
            actualPage={page}
            threshold={perPage}
            maxItems={maxItems}
            handleClickNext={handleClickNextPage}
            handleClickPrev={handleClickPrevPage}
            handlePageClick={handlePageClick}
          />
        </div>
      </div>
    </div>
  );
};

export default DataHub;
