import React, { ChangeEvent, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';

type Props = {
  type?: string;
  name?: string;
  id?: string;
  placeholder?: string;
  className?: string;
  wrapperClassName?: string;
  style?: Record<string, string>;
  icon?: string;
  size?: string;
  label?: string;
  defaultValue?: string;
  disabled?: boolean;
  value?: string | number;
  controlled?: boolean;
  onError?: (value: string) => string;
  handleChange?: (value: string) => void;
  onFocus?: () => void;
};

const Input = ({
  type = '',
  name = '',
  id = '',
  placeholder = '',
  className = '',
  wrapperClassName = '',
  icon = '',
  size = '',
  label = '',
  defaultValue = '',
  value = '',
  style = {},
  disabled = false,
  controlled = false,
  onError = () => '',
  handleChange = () => null,
  onFocus = () => null,
}: Props): ReactElement => {
  const [_value, setValue] = useState('');

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const error = useMemo(() => onError(_value), [_value]);

  const inputClassName = cx('form-control', {
    [className]: className !== '',
    [style['form-group__error']]: error !== '',
  });

  const _wrapperClassName = cx('form-group', {
    [size]: size !== '',
    [wrapperClassName]: wrapperClassName !== '',
  });

  const iconClassName = cx(icon, {
    [style.icon__error]: error !== '',
  });

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value: inputVal } = e.target;

      if (controlled) {
        handleChange(inputVal);
        return;
      }

      setValue(`${inputVal}`);
    },
    [handleChange, setValue, controlled]
  );

  useEffect(() => {
    if (!value) {
      handleChange(_value);
    }
  }, [_value]);

  return (
    <div className={_wrapperClassName} style={style}>
      {label && <label>{label}</label>}
      <input
        className={inputClassName}
        type={type}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        onFocus={onFocus}
        value={value || _value}
        disabled={disabled}
        id={id}
        autoComplete={'off'}
      />
      {error !== '' && <p className={style['form-group__errorMessage']}>{error}</p>}
      {icon && (
        <div className={'input-group-append'}>
          <span className={'input-group-text'}>
            <i className={iconClassName} />
          </span>
        </div>
      )}
    </div>
  );
};

export default Input;
