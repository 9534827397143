import { css } from '@emotion/react';
import { AxiosResponse } from 'axios';
import React, { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { QueryFunction, useMutation, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getVouchers, postVoucher, PostVoucherBody, RawGenericListReturn } from '../../../../api/params/vouchers.api';
import { selectCollAndDeliveryPointID } from '../../../../auth/selectors/authSelectors';
import { Row, RowItem } from '../../../../components/Core/Datagrid/Datagrid';
import { GridColDefinition } from '../../../../components/Core/Datagrid/gridCols';
import FormCard from '../../../../components/Core/FormCard/FormCard';
import InputBase from '../../../../components/Core/InputBase/InputBase';
import MainSection from '../../../../components/Core/MainSection/MainSection';
import { TOAST_SEVERITY } from '../../../../components/Core/Toast/Toast';
import { useMainTitle } from '../../../../components/Layout/MainTitleContext';
import { useSnackbar } from '../../../../context/SnackbarContext';
import useBuildForm from '../../../../hook/useBuildForm';
import { RowProps } from '../../Caution/CautionList';
import usePriceOptions from '../../Deposit/UsePriceOptions';
import usePriceTypeSelect from '../../Deposit/UsePriceTypeSelect';
import GenericList from './GenericList';
import { ToggleRadioWithController, useNewVoucherForm } from './voucher.forms';
import VoucherListActionCell from './VoucherListActionCell';

function useColumns(articleType?: string): GridColDefinition[] {
  const { t } = useTranslation(['parameters/voucherList']);
  const getPriceOptionsByArticle = usePriceOptions();
  const getPriceTypeSelectByArticle = usePriceTypeSelect(getPriceOptionsByArticle);

  const getSelectNode = useCallback(
    //eslint-disable-next-line
    ({ row, control }: any) => {
      const name = 'articles';
      const selectNode = getPriceTypeSelectByArticle(articleType || 'velo', control, name);
      // console.log(selectNode, 'la', getPriceOptionsByArticle(articleType || 'velo'));
      return React.cloneElement(selectNode, {
        id: name,
        key: `select-${row.pjId}-${row.pjName}`,
      });
    },
    [articleType, getPriceTypeSelectByArticle]
  );

  return useMemo(() => {
    return [
      {
        field: 'pjName',
        type: 'formField',
        headerName: t('columns.name'),
        renderCell: ({ control }) => {
          return (
            <Controller
              control={control}
              render={({ field: { value, name, onChange } }) => (
                <InputBase name={name} onChange={onChange} value={value} />
              )}
              name={'pjName'}
            />
          );
        },
      },
      {
        headerName: t('columns.prices'),
        field: 'articles',
        type: 'formField',
        width: 'minmax(400px, 2fr)',
        renderCell: getSelectNode,
      },
      {
        field: 'isRequired',
        type: 'formField',
        headerName: t('columns.isRequired'),
        renderCell: ({ row, control }) => {
          return (
            <ToggleRadioWithController
              style={'column'}
              control={control}
              controllerName={'isRequired'}
              name={row.pjId.toString().concat('isRequired')}
            />
          );
        },
      },
      {
        field: '',
        type: 'formField',
        renderCell: ({ control, row, reset }) => {
          return <VoucherListActionCell control={control} row={row} reset={reset} />;
        },
      },
    ];
  }, [getSelectNode, t]);
}

function VoucherListRow({ columns, row, colsTemplate }: RowProps) {
  //eslint-disable-next-line
  const useFormReturn = useForm<any>({
    mode: 'onChange',
    defaultValues: useMemo(
      () => ({
        isRequired: row.isRequired,
        pjName: row.pjName,
        articles: row.articles,
      }),
      [row]
    ),
  });

  const { isDirty, dirtyFields } = useFormReturn.formState;

  const buildCell = useCallback(
    (column: GridColDefinition) => {
      if (column.type === 'formField') {
        return (
          <div
            key={'value-' + column.field + row.pjId}
            css={css`
              display: grid;
              align-items: center;
            `}>
            {column.renderCell({ row, ...useFormReturn, columnDef: column })}
          </div>
        );
      }

      return (
        <RowItem key={'value-' + column.field + row.pjId}>
          <span>{row[column.field]}</span>
        </RowItem>
      );
    },
    [row, isDirty, dirtyFields, useFormReturn]
  );

  return (
    <Row key={'line'.concat(row.pjId.toString().concat(row.pjName))} colsTemplate={colsTemplate}>
      {columns.map((column) => buildCell(column))}
    </Row>
  );
}

function BikeVoucherPage() {
  const { articleType } = useParams();
  const snackbar = useSnackbar();
  const { t } = useTranslation(['parameters/voucherList']);
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const queryClient = useQueryClient();
  const newVoucherForm = useNewVoucherForm();
  useMainTitle(t('mainTitle'));
  const { builtFormFields, handleSubmit, reset } = useBuildForm({ formObject: newVoucherForm });
  const columns = useColumns(articleType);

  const { mutate: handlePostVoucher, isLoading } = useMutation(
    ['voucher.post'],
    //eslint-disable-next-line
    (data: any) => {
      const { articles, articleType, ...pj } = data;
      return postVoucher({
        id_coll,
        pj: { ...pj, article: articleType },
        articles,
      } as PostVoucherBody);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['voucher.list']);
        reset();
        snackbar?.setAlert({ message: t('postSuccessMessage'), severity: TOAST_SEVERITY.success });
      },
    }
  );

  const onSubmit = useCallback(
    handleSubmit((data) => {
      handlePostVoucher({ ...data, articleType });
    }),
    [handlePostVoucher, articleType]
  );

  const fetchBikeVouchers: QueryFunction<AxiosResponse<RawGenericListReturn>> = ({ queryKey }) => {
    const [, listParams] = queryKey as [string, { id_coll: number }];

    return getVouchers({
      id_coll: listParams.id_coll,
    });
  };

  const transformFn = useCallback(
    (dataResponse?: AxiosResponse) => {
      //eslint-disable-next-line
      return { rows: (dataResponse?.data as any)?.[articleType || ''] || [] };
    },
    [articleType]
  );

  return (
    <MainSection className={'reset'}>
      <FormCard
        title={t('add', { type: t(articleType as string) })}
        submitLabel={t('postSubmitLabel')}
        onSubmit={onSubmit}
        isLoading={isLoading}>
        {builtFormFields}
      </FormCard>
      <GenericList
        title={t('title')}
        transformFn={transformFn}
        queryFn={fetchBikeVouchers}
        queryKey={['voucher.list', { id_coll }]}
        columns={columns}
        RowComponent={VoucherListRow}
      />
    </MainSection>
  );
}

export default BikeVoucherPage;
