import React, { useEffect, useMemo } from 'react';
import {
  ActionFunction,
  json,
  LoaderFunction,
  NavLink,
  useFetcher,
  useLoaderData,
  useNavigate,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { QueryClient } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import _ from 'lodash';
import { useMainTitle } from '../../../../components/Layout/MainTitleContext';
import MainSection from '../../../../components/Core/MainSection/MainSection';
import Card from '../../../../components/Core/Card/Card';
import ButtonWithIcon from '../../../../components/Core/Button/ButtonWithIcon';
import store from '../../../../internal/store';
import { instance } from '../../../../api/user.api';
import useDisplayError from '../../../../hook/useDisplayError';
import { TOAST_SEVERITY } from '../../../../components/Core/Toast/Toast';
import { useSnackbar } from '../../../../context/SnackbarContext';
import { dataURLtoFile } from '../../../../parameters/utils/file';
import { BikeForm, velocareTypesQuery } from './NewBike.page';
import { bikeListQuery, BikeListRawResponse } from './BikeList.page';

/*const mock = new MockAdapter(instance, { onNoMatch: 'passthrough' });
mock.onGet('bike/3').reply(200, { name: 'test', description: 'test' });*/

export const editBikeAction: (queryClient: QueryClient) => ActionFunction =
  (queryClient) =>
  async ({ request }) => {
    const collId = store.getState().authState.collectivity;
    const { img, ...data } = await request.json();
    let response = null;

    try {
      response = await instance.post('bike/edit', { ...data, idColl: collId });
      if (response.status === 200) {
        await queryClient.invalidateQueries(['bike/list']);
        if (img) {
          const imgFile = await dataURLtoFile(img.src, 'file');
          await instance.put('bike/media', imgFile, {
            params: { idColl: collId, idArticle: response.data.idArticle },
          });
        }
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        console.error(e);
        return json(e.response?.data);
      }
    }

    return response;
  };

export const editBikeLoader: (queryClient: QueryClient) => LoaderFunction =
  (queryClient) =>
  async ({ params }) => {
    const collId = store.getState().authState.collectivity;

    const listBikeResponse = await queryClient.ensureQueryData({
      ...bikeListQuery({ idColl: collId }),
    });

    const velocareTypesResponse = await queryClient.ensureQueryData({
      ...velocareTypesQuery({ id_coll: collId }),
    });

    return json({ collId, listBikeResponse, velocareTypesResponse, bikeId: Number(params.id) });
  };

function EditBikePage() {
  const fetcher = useFetcher();
  const { t } = useTranslation(['parameters/products/editBike', 'form']);
  useDisplayError(fetcher.data);
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const { listBikeResponse, bikeId } = useLoaderData() as {
    listBikeResponse?: AxiosResponse<BikeListRawResponse>;
    collId: number;
    bikeId: number;
  };

  const bikeSelected = useMemo(() => {
    return _.find(listBikeResponse?.data, { id: bikeId });
  }, [listBikeResponse, bikeId]);

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      img: { src: bikeSelected?.mediaUrl },
      name: bikeSelected?.name,
      description: bikeSelected?.description,
      velocareType: bikeSelected?.velocareType,
    },
  });
  useMainTitle(bikeSelected?.name || '');

  const onSubmit = (data: FieldValues) => {
    const copyData = _(data).set('velocareType', bikeSelected?.velocareType);
    if (copyData.get('img.src') === bikeSelected?.mediaUrl) copyData.unset('img');

    fetcher.submit({ ...copyData.value(), id: bikeId }, { method: 'post', encType: 'application/json' });
  };

  useEffect(() => {
    if (fetcher.data && fetcher.data.status === 200) {
      snackbar?.setAlert({
        message: t('successMessage'),
        severity: TOAST_SEVERITY.success,
      });
      navigate('/parametres/articles');
    }
  }, [fetcher.data, t]);

  return (
    <MainSection className={'reset'}>
      <Card
        content={
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <p>{t('form:all_required')}</p>
              <BikeForm />
              <div className={'row justify-between mt-4'}>
                <ButtonWithIcon
                  as={NavLink}
                  to={'/parametres/articles'}
                  icon={'CrossWithoutCircle'}
                  color={'secondary'}>
                  {t('common:cancel')}
                </ButtonWithIcon>
                <ButtonWithIcon icon={'CheckWithoutCircle'} type={'submit'}>
                  {t('common:edit')}
                </ButtonWithIcon>
              </div>
            </form>

            <DevTool control={methods.control} />
          </FormProvider>
        }
      />
    </MainSection>
  );
}

export default EditBikePage;
