import React, { HtmlHTMLAttributes, PropsWithChildren } from 'react';
import styles from './StatBlock.module.scss';

type StatBlockProps = HtmlHTMLAttributes<HTMLDivElement> &
  (
    | PropsWithChildren<{
        title?: never;
        subtitle?: never;
        nbr?: never;
        chart?: never;
        small?: never;
      }>
    | {
        children?: never;
        title?: string;
        subtitle?: string;
        nbr?: number | string;
        chart?: React.ReactNode;
        small?: boolean;
      }
  );
function StatBlock({ children, chart, subtitle, title, nbr, small, className }: StatBlockProps) {
  return (
    <div className={[styles.Card, className].join(' ')}>
      {children ? (
        children
      ) : (
        <div className={small ? styles.Small : undefined}>
          {title && <h5 className={styles.Title}>{title}</h5>}
          <span className={styles.Number}>{nbr}</span>
          {subtitle && <span className={styles.Subtitle}>{subtitle}</span>}
        </div>
      )}
      {chart && <div className={styles.Chart}>{chart}</div>}
    </div>
  );
}

export function StatContent({
  nbr,
  title,
  subtitle,
  small,
}: Pick<StatBlockProps, 'title' | 'subtitle' | 'nbr' | 'small'>) {
  return (
    <div className={small ? styles.Small : undefined}>
      {title && <h5 className={styles.Title}>{title}</h5>}
      <span className={styles.Number}>{nbr}</span>
      {subtitle && <span className={styles.Subtitle}>{subtitle}</span>}
    </div>
  );
}

export default StatBlock;
