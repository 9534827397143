import { instance, ListQueryParams } from './user.api';

const URL = {
  getAllBookings: 'cf/reservations',
  getBooking: 'user/reservation',
  getBookingParams: 'param/resaproperties',
  getArticleOptions: 'user/dispother',
  getAccessoriesOptions: 'user/dispaccessoires',
  getDepositConfig: 'param/sogecommerceenabled',
  postReleaseDeposit: 'user/rbt',
  checkoutList: 'checkin/checkout',
  postChangeBooking: 'user/changeresa',
  postChangeAccessoryBooking: 'user/changeaccessoire',
  checkIn: 'checkin/checkin',
  checkOut: 'checkin/checkout',
  getSign: 'user/signresa',
  validBooking: 'user/validresa',
  finishBooking: 'user/endresa',
  cancelBooking: 'user/cancelresa',
  postBookingCommentary: 'user/commentaire',
  postBookingMedia: 'user/media64',
  putStartDate: 'user/changeresastart',
  putEndDate: 'user/changeresaend',
};

export type PutStartDateBodyForm = {
  id_coll: number;
  id_resa: string;
  date_debut: string;
};

export function putStartDate(bodyForm: PutStartDateBodyForm) {
  return instance.post(URL.putStartDate, bodyForm);
}

export type PutEndDateBodyForm = {
  id_coll: number;
  id_resa: string;
  date_fin: string;
};

export function putEndDate(bodyForm: PutEndDateBodyForm) {
  return instance.post(URL.putEndDate, bodyForm);
}

export function getAllBookings(queryParams: ListQueryParams & { id_coll: number }) {
  return instance.get(URL.getAllBookings, { params: queryParams });
}

//eslint-disable-next-line
export function getCheckout(queryParams: any) {
  return instance.get(URL.checkoutList, { params: queryParams });
}

//eslint-disable-next-line
export function getBookingConfig(queryParams: any) {
  return instance.get(URL.getBookingParams, { params: queryParams });
}

export function getDepositConfig(queryParams: { id_coll: number }) {
  return instance.get(URL.getDepositConfig, { params: queryParams });
}

export type PostReleaseDepositBodyForm = {
  id_coll: number;
  id_resa: number;
  type: string;
};

export function postReleaseDeposit(bodyForm: PostReleaseDepositBodyForm) {
  return instance.post(URL.postReleaseDeposit, bodyForm);
}

export type GetAccessoriesOptionsQueryParams = {
  id_coll: number;
  id_pdl: number;
  id_resa: number;
};

export type GetAccessoriesOptionsReturn = Record<
  number,
  {
    id_accessoire: number;
    id_interne: string;
    id_type: number;
    nom_type: string;
  }[]
>;

export function getAccessoriesOptions(queryParams: GetAccessoriesOptionsQueryParams) {
  return instance.get<GetAccessoriesOptionsReturn>(URL.getAccessoriesOptions, { params: queryParams });
}

export type GetArticleOptionsQueryParams = {
  id_coll: number;
  id_pdl: number;
  id_resa: number;
};

export function getArticleOptions(queryParams: GetArticleOptionsQueryParams) {
  return instance.get<{ id_interne: string; id: number }[]>(URL.getArticleOptions, { params: queryParams });
}

//eslint-disable-next-line
export function getCheckInVerifs(queryParams: any) {
  return instance.get(URL.checkIn, { params: queryParams });
}

type GetCheckOutVerifsQueryParams = {
  id_coll: number;
};

export function getCheckOutVerifs(queryParams: GetCheckOutVerifsQueryParams) {
  return instance.get(URL.checkOut, { params: queryParams });
}

//eslint-disable-next-line
export function getSign(queryParams: any) {
  return instance.get(URL.getSign, { params: queryParams });
}

export type PostChangeBookingBodyForm = {
  id_coll: number;
  id_new: string;
  id_pdl: number;
  id_resa: string;
};

export function postChangeBooking(bodyForm: PostChangeBookingBodyForm) {
  return instance.post(URL.postChangeBooking, bodyForm);
}

export type PostChangeAccessoryBookingBodyForm = {
  id_coll: number;
  id_pdl: number;
  id_resa: string;
  id_accessoire: number;
  id_type: number;
};

export function postChangeAccessoryBooking(bodyForm: PostChangeAccessoryBookingBodyForm) {
  return instance.post(URL.postChangeAccessoryBooking, bodyForm);
}

export type PostSignatureBodyForm = {
  id_coll: number;
  id_resa: string | number;
  sign: string;
};

export function postBookingSign(bodyForm: PostSignatureBodyForm) {
  return instance.post(URL.getSign, bodyForm);
}

export type ValidBookingBodyForm = {
  id_coll: number;
  id_pdl: number;
  id_resa: string | number;
};

export function validBooking(bodyForm: ValidBookingBodyForm) {
  return instance.post(URL.validBooking, bodyForm);
}

export type FinishBookingBodyForm = {
  id_coll: number;
  id_pdl: number;
  id_resa: string | number;
  t_etat: string;
};

export function postFinishBooking(bodyForm: FinishBookingBodyForm) {
  return instance.post(URL.finishBooking, bodyForm);
}

export type PostBookingCommentaryBodyForm = {
  id_coll: number;
  id_resa: string | number;
  content: string;
};

export function postBookingCommentary(bodyForm: PostBookingCommentaryBodyForm) {
  return instance.post(URL.postBookingCommentary, bodyForm);
}

export function cancelBooking(bodyForm: ValidBookingBodyForm) {
  return instance.post(URL.cancelBooking, bodyForm);
}

export type PostMediaBodyForm = {
  id_coll: number;
  file: string;
  id_user: string;
  pjName: string;
};

export function postBookingMedia(bodyForm: PostMediaBodyForm) {
  return instance.post(URL.postBookingMedia, bodyForm);
}

export type PostCheckInBodyForm = {
  id_coll: number;
  id_resa: string;
  form: {
    commentaire: string;
    commentary: string;
    has_media: number;
    id_form: number;
    id_media: string;
    is_mandatory: number;
    is_media_mandatory: number;
    label: string;
    mediaUrl: string;
    need_status: number;
    status: string;
  }[];
};

export function postCheckIn(bodyForm: PostCheckInBodyForm) {
  return instance.post(URL.checkIn, bodyForm);
}

export function postCheckOut(bodyForm: PostCheckInBodyForm) {
  return instance.post(URL.checkOut, bodyForm);
}
