import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Button from 'fragments/button/Button';
import { t } from 'internal/i18n';
import React from 'react';

type Props = {
  releaseDeposit: () => void;
  handleClose: () => void;
  open: boolean;
};

const ReservationDepositDialog = ({ releaseDeposit, handleClose, open }: Props) => {
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>
        <div className={'modal-confirm-header'}>
          <div className={'modal-confirm-icon'}>!</div>
          <p className={'modal-confirm-title'}>{t('reservations.are_you_sure')}</p>
        </div>
      </DialogTitle>
      <DialogContent className={'modal-confirm-body'}>
        <p>{t('reservations.resa_release_deposit_confirm')}</p>
      </DialogContent>
      <DialogActions className={'modal-confirm-actions'}>
        <Button
          label={t('reservations.button_yes')}
          className={'btn-primary btn-fit btn-margin'}
          onClick={releaseDeposit}
        />
        <Button label={t('reservations.button_no')} className={'btn-secondary btn-fit'} onClick={handleClose} />
      </DialogActions>
    </Dialog>
  );
};

export default ReservationDepositDialog;
