import React, { useContext, useEffect } from 'react';

export const MainTitleContext = React.createContext<
  [string | JSX.Element | null, React.Dispatch<React.SetStateAction<string | JSX.Element | null>>]
>(['', () => '']);

export function useMainTitle(title: string | JSX.Element | null) {
  const [, setMainTitle] = useContext(MainTitleContext);

  useEffect(() => {
    setMainTitle(title);
  }, [title]);
}

export function MainTitle({ className }: { className: string }) {
  return <MainTitleContext.Consumer>{([title]) => <h2 className={className}>{title}</h2>}</MainTitleContext.Consumer>;
}
