import { selectCollAndDeliveryPointID } from 'auth/selectors/authSelectors';
import Button from 'fragments/button/Button';
import Select from 'fragments/select/Select';
import useApi, { Methods } from 'internal/hooks/useApi';
import { t } from 'internal/i18n';
import React, { useCallback, useEffect, useState } from 'react';
import { ReactElement, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { UserAccessoryType } from 'users/types/users';

type Props = {
  resaID: string;
  refresh: () => void;
};

const AccessoriesAvailables = ({ resaID, refresh }: Props): ReactElement => {
  const { id_pdl, id_coll } = useSelector(selectCollAndDeliveryPointID);
  const [formState, setFormState] = useState<UserAccessoryType[][]>([]);
  const [show, setShow] = useState(false);
  const [accessoriesChoose, setAccessoriesChoose] = useState<{
    [key: string]: string | boolean;
  }>({});

  const { request: fetchAccessoriesAvalaibles, response: accessoriesAvailablesResponse } = useApi<{
    [key: string]: UserAccessoryType[];
  }>(Methods.GET, 'user/dispaccessoires');

  const { request: postNewAccessory, response: newAccessoryResponse } = useApi(Methods.POST, 'user/changeaccessoire');

  const fetchAccessoriesAvalaiblesCallback = useCallback(() => {
    fetchAccessoriesAvalaibles({
      params: {
        id_resa: resaID,
        id_coll,
        id_pdl,
      },
    });
  }, [fetchAccessoriesAvalaibles, resaID, id_coll, id_pdl]);

  useEffect(() => {
    if (resaID) {
      fetchAccessoriesAvalaiblesCallback();
    }
  }, [fetchAccessoriesAvalaibles, resaID, id_coll, id_pdl]);

  useEffect(() => {
    if (accessoriesAvailablesResponse?.success && accessoriesAvailablesResponse.data) {
      const newFormState = Object.values(accessoriesAvailablesResponse.data);
      const newAccessoriesChoose: {
        [key: string]: string | boolean;
      } = {};

      newFormState.forEach((newFormState, i) => {
        newAccessoriesChoose[i] = newFormState[0].id_accessoire.toString();
      });

      setFormState(Object.values(accessoriesAvailablesResponse.data));
      setAccessoriesChoose(newAccessoriesChoose);
    }
  }, [setFormState, accessoriesAvailablesResponse?.data, accessoriesAvailablesResponse?.success]);

  useEffect(() => {
    if (newAccessoryResponse?.success && newAccessoryResponse.data) {
      refresh();
      fetchAccessoriesAvalaiblesCallback();
    }
  }, [newAccessoryResponse?.success, newAccessoryResponse?.data]);

  const showAccessories = useCallback(() => {
    setShow(true);
  }, []);

  const handleChangeFormState = useCallback(
    (index: number) => (value: boolean | string) => {
      setAccessoriesChoose((prevState) => {
        const newFormState = prevState;
        newFormState[index] = value;
        return newFormState;
      });
    },
    [formState, accessoriesChoose]
  );

  const submitAccessories = useCallback(
    (index: number) => () => {
      const article = formState[index].find(
        (accessory: UserAccessoryType) => accessory.id_accessoire.toString() === accessoriesChoose[index]
      );

      postNewAccessory({
        id_resa: resaID,
        id_accessoire: article?.id_accessoire,
        id_type: article?.id_type,
        id_coll,
        id_pdl,
      });
    },
    [formState, accessoriesChoose, resaID, id_coll, id_pdl]
  );

  const renderArticlesSelection = useMemo(() => {
    if (formState.length === 0) {
      return null;
    }

    if (!show) {
      return (
        <span className={'cursor-pointer link'} onClick={showAccessories}>
          {t('reservations.is_not_attributed')}
        </span>
      );
    }

    return formState.map((accessories, i) => {
      const options = accessories.map((accessory) => ({
        label: `${accessory.nom_type} ${accessory.id_interne}`,
        value: accessory.id_accessoire.toString(),
      }));

      return (
        <div className={'row mt-6'}>
          <div className={'col-md-6'}>
            <Select
              label={''}
              id={'other-bike'}
              name={'other-bike'}
              options={options}
              handleChange={handleChangeFormState(i)}
              noWrapper
            />
          </div>
          <div className={'col-md-4'}>
            <Button
              label={t('reservations.assign')}
              className={'btn btn-primary changeresa ptn-fit'}
              onClick={submitAccessories(i)}
            />
          </div>
        </div>
      );
    });
  }, [formState, show]);

  return <>{renderArticlesSelection}</>;
};

export default AccessoriesAvailables;
