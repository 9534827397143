import React, { ComponentPropsWithoutRef, PropsWithChildren, useEffect, useState } from 'react';
import { FieldValues, Form, FormProvider, FormSubmitHandler } from 'react-hook-form';
import dayjs from 'dayjs';
import Card from '../../../components/Core/Card/Card';
import ExportButton from '../ExportButton/ExportButton';

type CustomExportCardProps = PropsWithChildren<
  ComponentPropsWithoutRef<typeof FormProvider> & {
    title: string;
    text?: string;
    onSubmit: FormSubmitHandler<FieldValues>;
    //eslint-disable-next-line
    fetcherData: any;
    fetcherState: 'idle' | 'submitting' | 'loading';
  }
>;

function CustomExportCard(props: CustomExportCardProps) {
  const { title, text, fetcherData, children, fetcherState, onSubmit, ...formProviderProps } = props;
  const onDownload = () => {
    const _csvFilename = `exports_stats_${dayjs().format('DD/MM/YYYY')}.csv`;
    const url = window.URL.createObjectURL(new Blob([fetcherData.data]));
    const link = document.createElement('a');

    link.href = url;
    link.target = '_blank';
    link.setAttribute('download', _csvFilename);
    document.body.appendChild(link);
    link.click();
  };

  const { isDirty } = formProviderProps.formState;

  const [state, setState] = useState<string | null>(null);

  useEffect(() => {
    if (fetcherState === 'idle' && fetcherData) {
      if ('exception' in fetcherData) {
        setState(null);
      } else {
        setState('ready');
      }
    }
  }, [fetcherState, fetcherData]);

  useEffect(() => {
    if (fetcherState === 'submitting' && state !== 'ready') setState('loading');
  }, [fetcherState, state]);

  useEffect(() => {
    if (isDirty) setState(null);
  }, [isDirty]);

  return (
    <Card
      title={title}
      content={
        <FormProvider {...formProviderProps}>
          <Form onSubmit={onSubmit}>
            {text && <p>{text}</p>}

            {children}
            <div className={'mt-4'}>
              <ExportButton
                onDownload={onDownload}
                state={state}
                /*link={useMemo(
                  () => (fetcherData && !isDirty ? (fetcherData as string) : undefined),
                  [isDirty, fetcherData, fetcherState]
                )}*/
              />
            </div>
          </Form>
        </FormProvider>
      }
    />
  );
}

export default CustomExportCard;
