import axios, { AxiosResponse } from 'axios';
import { CollectivityType } from '../auth/types/collectivity';
import { API_KEY, BASE_URL, TIMEOUT } from '../internal/constants/api';
import { ParametersAdminType } from '../parameters/types/collectivity';
import { UserDetailsType } from '../users/types/users';
import { RawGenericListReturn } from './params/vouchers.api';

export const instance = axios.create({
  baseURL: BASE_URL,
  timeout: TIMEOUT,
  headers: {
    ['X-Velogikback-Apikey']: API_KEY,
    ['X-Velogik-Host']: 'veloclik-inte.velogik.com',
  },
});

export function setApiToken(token: string | null) {
  return new Promise<boolean>((resolve) => {
    if (token) {
      instance.defaults.headers.common['Authorization'] = token;
      resolve(true);
    } else {
      resolve(false);
    }
    // setTimeout(() => resolve(), 7000);
  });
}

export function removeApiToken() {
  delete instance.defaults.headers.common['Authorization'];
}

const ENDPOINTS = {
  fetchRights: 'user/authsidebar',
  fetchCollectivities: 'user/auth',
  getPdlListByCollId: 'user/authpdl',
  getAdminParams: 'param/admin',
  getUserList: 'user/list',
  fetchContracts: 'user/contrats',
  fetchInvoices: 'user/factures',
  fetchReservations: 'user/reservations',
  fetchCheckouts: 'user/caisse',
  fetchOneUser: 'user/details',
  postRefund: 'user/caisse',
};

export function getAllCollectivites() {
  return instance.get(ENDPOINTS.fetchCollectivities);
}

export function getPdlListByCollId(queryParams: { id_coll: number }): Promise<AxiosResponse> {
  return instance.get(ENDPOINTS.getPdlListByCollId, { params: queryParams });
}

export type RawUser = {
  nom: string;
  prenom: string;
  token: string;
  mustChangePassword: boolean;
  coll?: CollectivityType[];
};

export async function getUserById(queryParams: { id_coll: number; id_user: number }) {
  return await instance.get<UserDetailsType>(ENDPOINTS.fetchOneUser, {
    params: queryParams,
  });
}

export type ListQueryParams = {
  p: number;
  ipp: number;
  [key: string]: unknown;
};

export async function getUserList(queryParams: ListQueryParams & { id_coll: number }) {
  return await instance.get<{
    currentPage: number;
    itemPerPage: number;
    // eslint-disable-next-line
    filters: any;
    // eslint-disable-next-line
    result: any[];
    total: number;
    totalPage: number;
    order?: {
      column: string;
      dir: string;
    };
  }>(ENDPOINTS.getUserList, {
    params: queryParams,
  });
}

export async function fetchUserContracts(queryParams: ListQueryParams & { id_coll: number; id_user: number }) {
  return await instance.get<RawGenericListReturn>(ENDPOINTS.fetchContracts, { params: queryParams });
}

export async function fetchUserInvoices(queryParams: ListQueryParams & { id_coll: number; id_user: number }) {
  return await instance.get<RawGenericListReturn>(ENDPOINTS.fetchInvoices, { params: queryParams });
}

export async function fetchUserBookings(queryParams: ListQueryParams & { id_coll: number; id_user: number }) {
  return await instance.get<RawGenericListReturn>(ENDPOINTS.fetchReservations, { params: queryParams });
}

export async function fetchUserCheckout(queryParams: ListQueryParams & { id_coll: number; id_user: number }) {
  return await instance.get<RawGenericListReturn>(ENDPOINTS.fetchCheckouts, { params: queryParams });
}

export type PostUserCheckoutBodyForm = {
  id_coll: number;
  id_pdl: number;
  id_user: number;
  rbt: boolean;
  moyen: string;
  type?: string;
  operation: string;
  montant: string;
};

export async function postUserCheckout(body: PostUserCheckoutBodyForm) {
  return await instance.post<RawGenericListReturn>(ENDPOINTS.fetchCheckouts, body);
}
//eslint-disable-next-line
export async function postUserRefund(data: any) {
  return await instance.post(ENDPOINTS.postRefund, data);
}

export type RawUserRights = {
  [key: string]: {
    global: boolean;
    rights: Record<string, boolean>[];
  };
};

export async function getUserRights(id_coll: number) {
  return instance.get<RawUserRights>(ENDPOINTS.fetchRights, {
    params: {
      id_coll,
    },
  });
}

type RawAdminParams = {
  admin: ParametersAdminType;
};

export async function getAdminParams(id_coll: number) {
  return await instance.get<RawAdminParams>(ENDPOINTS.getAdminParams, {
    params: {
      id_coll,
    },
  });
}
