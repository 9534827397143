import React, { ComponentPropsWithoutRef, InputHTMLAttributes, useState } from 'react';
import DateRangePicker from '../../../pages/stats/customExport/DateRangePicker';
import styles from './CheckboxItem.module.scss';

type CheckboxItemProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'onChange'> &
  ComponentPropsWithoutRef<typeof DateRangePicker> & {
    label: string;
  };

const CheckboxField = React.forwardRef<HTMLInputElement, CheckboxItemProps>((props) => {
  const { label, defaultChecked = false, onDatesChange, startDate, endDate } = props;
  const [checked, setChecked] = useState(defaultChecked);

  return (
    <label className={[styles.Label, styles.CheckboxField].join(' ')}>
      <div className={styles.Left}>
        <div className={styles.Checkbox}>
          <input
            checked={checked}
            type={'checkbox'}
            onChange={(e) => {
              setChecked(e.target.checked);
              if (!e.target.checked) {
                onDatesChange({ startDate: null, endDate: null });
              }
            }}
            className={styles.Input}
          />
          <span className={styles.Checkmark} />
        </div>
        {label}
      </div>

      <div>
        <DateRangePicker
          disabled={!checked && startDate === null}
          startDate={startDate}
          endDate={endDate}
          onDatesChange={onDatesChange}
        />
      </div>
    </label>
  );
});

export default CheckboxField;
