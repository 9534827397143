import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Badge from '../../components/Core/Badge/Badge';
import IconButton from '../../components/Core/Button/IconButton';
import { RowItem } from '../../components/Core/Datagrid/Datagrid';
import { WarningIcon } from '../../const/icons';
import { generatePath } from '../../utils/router';
import { getColor } from '../../utils/style';
import { Grid } from '../Reservation/InfosReservation.page';

//eslint-disable-next-line
function UserActionsCell({ row }: { row: any }) {
  const { t } = useTranslation('user/userList');

  return (
    <RowItem>
      {row.valid_bo === 0 ? (
        <WarningText>
          <WarningIcon width={20} />
          {t('need_validate')}
        </WarningText>
      ) : (
        <Grid columns={'repeat(4, 1fr)'} align={'center'} gap={8}>
          <IconButton
            as={Link}
            to={generatePath('home.user.userDetails.checkout', { params: { userId: row.id_user } })}
            icon={'ReceiptIcon'}
          />
          <IconButton
            as={Link}
            to={generatePath('home.user.userDetails.contractList', { params: { userId: row.id_user } })}
            icon={'ContractIcon'}
          />
          <IconButton
            as={Link}
            to={generatePath('home.user.userDetails.invoices', { params: { userId: row.id_user } })}
            icon={'InvoiceIcon'}
          />
          <Badge badgeContent={row.nb_attente}>
            <IconButton
              as={Link}
              to={generatePath('home.user.userDetails.booking', { params: { userId: row.id_user } })}
              icon={'BookingIcon'}
            />
          </Badge>
        </Grid>
      )}
    </RowItem>
  );
}

const WarningText = styled.span`
  color: ${getColor('red.200')};
  display: flex;
  align-items: center;
  gap: 8px;
`;

export default UserActionsCell;
