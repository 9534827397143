export const textEditorToolbarConfig = {
  options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'colorPicker', 'link'],
  inline: {
    monospace: { className: 'd-none' },
    superscript: { className: 'd-none' },
    subscript: { className: 'd-none' },
  },
  list: {
    indent: { className: 'd-none' },
    outdent: { className: 'd-none' },
  },
  blockType: {
    className: 'd-none',
  },
  fontSize: {
    className: 'd-none',
  },
  fontFamily: {
    className: 'd-none',
  },
  link: {
    unlink: {
      className: 'd-none',
    },
  },
};
