import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import dayjs, { ConfigType } from 'dayjs';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from 'fragments/button/Button';
import useRefresh from 'internal/hooks/useRefreshList';
import useApi, { Methods } from 'internal/hooks/useApi';
import { selectCollAndDeliveryPointID, selectSessionToken } from 'auth/selectors/authSelectors';
import FundTable from 'fund/components/FundTable';
import { DAILY_FUND_ENDPOINT, DAILY_FUND_STATE_ENDPOINT, EXPORT_FUND_ENDPOINT } from 'fund/constants/endpoints';
import { FundElementType } from 'fund/types/fund';
import ExportDialog from 'fund/components/ExportDialog';
import { getMimeType } from 'internal/utils/file';

const DailyFund = (): ReactElement => {
  const { t } = useTranslation('fund');
  const { id_coll, id_pdl } = useSelector(selectCollAndDeliveryPointID);
  const token = useSelector(selectSessionToken);
  const [modalOpen, setModalOpen] = useState(false);
  const [formatFile, setFormatFile] = useState('');
  const [buffer, setBuffer] = useState<Blob | null>();

  const { request: fetchFund, response: fundResponse } = useApi<
    {
      postes: { [key: string]: FundElementType };
    } & {
      [key: string]: FundElementType;
    }
  >(Methods.GET, DAILY_FUND_ENDPOINT);
  const { request: fetchFundState, response: fundStateResponse } = useApi<{
    is_valid: boolean;
    date_created?: ConfigType;
  }>(Methods.GET, DAILY_FUND_STATE_ENDPOINT);
  const { request: postFund } = useApi(Methods.POST, DAILY_FUND_STATE_ENDPOINT);
  const { request: fetchExport, response: exportResponse } = useApi<Blob>(Methods.GET, EXPORT_FUND_ENDPOINT);

  useEffect(() => {
    fetchFund({
      params: {
        id_coll,
        id_pdl,
      },
    });
  }, [id_pdl, id_coll]);

  const fetchState = useCallback(() => {
    fetchFundState({
      params: {
        id_coll,
        id_pdl,
      },
    });
  }, [id_pdl, id_coll]);

  const refresh = useRefresh(fetchState, 1000);

  const submitFund = useCallback(
    (state: string) => () => {
      postFund({
        id_coll,
        id_pdl,
        state,
      });

      refresh();
    },
    [postFund, id_coll, id_pdl]
  );

  useEffect(() => {
    fetchState();
  }, [fetchState]);

  useEffect(() => {
    if (exportResponse?.success && exportResponse?.data) {
      setBuffer(exportResponse.data);
    }
  }, [exportResponse?.success && exportResponse?.data]);

  useEffect(() => {
    if (buffer && formatFile) {
      const filename = `exports_daily_funds_${dayjs().format('DD/MM/YYYY')}.${formatFile}`;
      const url = window.URL.createObjectURL(new Blob([buffer], { type: getMimeType(formatFile) }));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      setBuffer(null);
    }
  }, [buffer, formatFile]);

  const [validFund, dateFundDone] = useMemo(() => {
    if (fundStateResponse?.success && fundStateResponse.data) {
      return [!!fundStateResponse.data.is_valid, fundStateResponse.data.date_created];
    }
    return [undefined, undefined];
  }, [fundStateResponse]);

  const fund = useMemo((): { [key: string]: FundElementType } | undefined => {
    if (fundResponse?.success && fundResponse.data) {
      const { postes, ...totaux } = fundResponse.data;
      return { ...postes, ...totaux };
    }
    return undefined;
  }, [fundResponse]);

  const handleModalOpen = useCallback(() => {
    setModalOpen(true);
  }, [setModalOpen]);

  const handleModalClose = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  const handleModalValidate = useCallback(
    (format: string) => () => {
      setFormatFile(format);

      fetchExport({
        responseType: 'arraybuffer',
        params: {
          id_coll,
          id_pdl,
          format,
          token,
        },
      });
      handleModalClose();
    },
    [handleModalClose, id_coll, id_pdl, token, dayjs]
  );

  const buttonsWhenNotValid = useMemo(() => {
    if (validFund) {
      return null;
    }

    return (
      <>
        <Button label={t('confirm_fund')} className={'btn-primary btn-margin'} onClick={submitFund('valid')} />
        <Button label={t('report_error')} className={'btn-primary'} onClick={submitFund('error')} />
      </>
    );
  }, [validFund, submitFund]);

  return (
    <div className={'main-content'}>
      <div className={'card'}>
        <div className={'card-body'}>
          <div className={'row'}>
            <div className={'col-md-6'}>
              {t('date_fund', { date: dayjs().format('DD/MM/YYYY') })}
              {validFund && (
                <Trans
                  i18nKey={validFund ? 'done_valid' : 'done_error'}
                  t={t}
                  values={{
                    date: dayjs(dateFundDone).format('DD/MM/YYYY'),
                    hour: dayjs(dateFundDone).format('HH:mm'),
                  }}
                  components={{ st: <strong /> }}
                />
              )}
            </div>
            <div className={'col-md-6'}>
              {buttonsWhenNotValid}
              <button onClick={handleModalOpen} className={'btn btn-margin-left btn-square btn-outline btn-primary'}>
                <i className={'ti-download'} />
              </button>
            </div>
          </div>
          <br />
          <div className={'row'}>
            <div className={'col-md-12'}>
              <FundTable fund={fund} />
            </div>
          </div>
        </div>
      </div>
      <ExportDialog open={modalOpen} onClose={handleModalClose} onValidate={handleModalValidate} />
    </div>
  );
};

export default DailyFund;
