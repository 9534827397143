import React, { SyntheticEvent } from 'react';

type AccordionState = {
  expanded: boolean;
  toggle: (e: SyntheticEvent) => void;
};

const AccordionContext = React.createContext<AccordionState>({} as AccordionState);

if (process.env.NODE_ENV !== 'production') {
  AccordionContext.displayName = 'AccordionContext';
}

export default AccordionContext;
