import React, { TextareaHTMLAttributes } from 'react';
import styles from './textArea.module.scss';

type TextAreaProps = TextareaHTMLAttributes<HTMLTextAreaElement>;

function TextArea({ className, ...propsToForward }: TextAreaProps) {
  return <textarea className={[styles.textArea, className].join(' ')} rows={4} {...propsToForward} />;
}

export default TextArea;
