/** @jsxImportSource @emotion/react */
import React from 'react';
import { Control, FieldValues, UseFormReset } from 'react-hook-form';
import { css } from '@emotion/react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useInjection } from '../../../../../ioc/ioc.react';
import { ParameterRepository } from '../../../../../repository/parameter.repository';
import { GridValidRowModel } from '../../../../../components/Core/Datagrid/gridRows';
import { SERVICE_KEYS } from '../../../../../ioc/keys';
import IconButton from '../../../../../components/Core/Button/IconButton';
import { useSnackbar } from '../../../../../context/SnackbarContext';
import { TOAST_SEVERITY } from '../../../../../components/Core/Toast/Toast';

type DepositActionsCellProps = {
  control: Control;
  reset?: UseFormReset<FieldValues>;
  row: GridValidRowModel;
  id_coll: number;
  handleDelete?: (depotId: number) => void;
};

function DepositActionCell(props: DepositActionsCellProps) {
  const snackbar = useSnackbar();
  const { t } = useTranslation(['translation']);
  const parameterRepository = useInjection<ParameterRepository>(SERVICE_KEYS.PARAMETER_REPOSITORY);
  const { row, control, id_coll, reset } = props;
  const { isDirty, dirtyFields } = control._formState;

  const isDisabled = !isDirty || Object.keys(dirtyFields).length === 0;

  async function handleDelete() {
    const response = await parameterRepository.deleteDeposit({ depotId: row.depotId, id_coll });
    if (response?.status === 200) {
      props?.handleDelete?.(row.depotId);
    }
  }

  async function handleUpdate() {
    const formValues = control._formValues;
    const rowValue = {
      id_coll,
      depotId: row.depotId,
      depot: {
        article: 'velo',
        depotName: formValues.depotName,
        montant_ht: Number(formValues.montant_ht),
        taux_tva: row.taux_tva || 20,
      },
      articles: formValues.articles,
    };

    try {
      const response = await parameterRepository.putDeposit(rowValue);
      if (response?.status === 200) {
        reset && reset([], { keepValues: true });
      }
    } catch (error: unknown) {
      console.error(error);
      if (axios.isAxiosError(error)) {
        const messageCode = error.response?.data.code;
        snackbar?.setAlert({ message: t('translation:'.concat(messageCode)), severity: TOAST_SEVERITY.error });
      }
    }
  }

  return (
    <div
      key={`actions-${row.depotId}`}
      css={css`
        display: grid;
        grid-template-columns: repeat(2, auto);
        justify-content: flex-end;
        gap: 8px;
      `}>
      <IconButton disabled={isDisabled} icon={'RefreshIcon'} color={'action'} onClick={handleUpdate} />
      <IconButton icon={'TrashIcon'} color={'danger'} onClick={handleDelete} />
    </div>
  );
}

export default DepositActionCell;
