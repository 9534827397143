import { useInstanceApi } from '../../../api/axios';
import { useRequest } from '../../../utils/request';

const URL = {
  fetchFormList: 'param/forms',
  putFormFieldParametersForPage: 'param/forms',
};

function useFormParametersApi() {
  const instance = useInstanceApi();

  const { request: fetchFormList } = useRequest((queryParams: { id_coll: number }) =>
    instance.get(URL.fetchFormList, { params: queryParams })
  );

  const { request: putFormFieldParametersForPage } = useRequest((data) =>
    instance.post(URL.putFormFieldParametersForPage, data)
  );

  return {
    fetchFormList,
    putFormFieldParametersForPage,
  };
}

export default useFormParametersApi;
