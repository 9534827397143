import axios, { AxiosInstance } from 'axios';
import { injectable } from 'inversify';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectSessionToken } from '../auth/selectors/authSelectors';
import { API_KEY, BASE_URL, TIMEOUT } from '../internal/constants/api';

export const instance = axios.create({
  baseURL: BASE_URL,
  timeout: TIMEOUT,
  headers: {
    ['X-Velogikback-Apikey']: API_KEY,
    ['X-Velogik-Host']: 'veloclik-inte.velogik.com',
  },
});

@injectable()
export class Api {
  public instance: AxiosInstance;

  constructor() {
    this.instance = instance;
  }

  setAuthToken(token: string) {
    this.instance.defaults.headers.common['Authorization'] = token;
  }
}

export const useInstanceApi = () => {
  const token = useSelector(selectSessionToken);
  const instance = useMemo(
    () =>
      axios.create({
        baseURL: BASE_URL,
        timeout: TIMEOUT,
        headers: {
          ['X-Velogikback-Apikey']: API_KEY,
          ['X-Velogik-Host']: 'veloclik-inte.velogik.com',
        },
      }),
    []
  );

  useEffect(() => {
    if (token) {
      instance.defaults.headers.common['Authorization'] = token;
    } else {
      delete instance.defaults.headers.common['Authorization'];
    }
  }, [token, instance]);

  return instance;
};
