import React, { useEffect, useMemo, useState } from 'react';
import { css, Global } from '@emotion/react';
import { useQuery } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, ScrollRestoration, useNavigate } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { getAdminParams, getUserRights } from '../../api/user.api';
import { saveUserRights } from '../../auth/actions/authActions';
import {
  selectCollAndDeliveryPointID,
  selectCollectivity,
  selectDeliveryPoint,
  selectUser,
} from '../../auth/selectors/authSelectors';
import { formatUserRights } from '../../auth/services/authServices';
import Footer from '../../fragments/footer/Footer';
import { setCurrency } from '../../internal/actions/appActions';
import { passwordChangeRoute } from '../../router/routes';
import Sidebar from '../Sidebar/Sidebar';
import TopBar from '../TopBar/TopBar';
import ScrollToTop from '../../fragments/scrollToTop/ScrollToTop';
import { MainTitleContext } from './MainTitleContext';
import styles from './DefaultLayout.module.scss';

export function useMediaQuery(query: string) {
  const subscribe = React.useCallback(
    //eslint-disable-next-line
    (callback: any) => {
      const matchMedia = window.matchMedia(query);

      matchMedia.addEventListener('change', callback);
      return () => {
        matchMedia.removeEventListener('change', callback);
      };
    },
    [query]
  );

  const getSnapshot = () => {
    return window.matchMedia(query).matches;
  };

  const getServerSnapshot = () => {
    throw Error('useMediaQuery is a client-only hook');
  };

  return React.useSyncExternalStore(subscribe, getSnapshot, getServerSnapshot);
}

function withAuthenticated<T>(WrappedComponent: React.ComponentType<T>) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const ComponentWithAuthentication = (props: T) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectUser);
    const collectivity = useSelector(selectCollectivity);
    const deliveryPoint = useSelector(selectDeliveryPoint);
    const { id_coll } = useSelector(selectCollAndDeliveryPointID);

    const { isLoading: getUserRightIsLoading } = useQuery(
      ['userRights', id_coll, user?.token],
      ({ queryKey }) => {
        const [, id_coll] = queryKey;
        return getUserRights(id_coll as number);
      },
      {
        // enabled: ready,
        retry: 0,
        staleTime: 800_000,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        onSuccess: (response) => {
          dispatch(saveUserRights(formatUserRights(response.data)));
        },
      }
    );

    const { isLoading: getAdminParamsIsLoading } = useQuery(
      ['adminParams', id_coll, user?.token],
      ({ queryKey }) => {
        const [, id_coll] = queryKey;
        return getAdminParams(id_coll as number);
      },
      {
        // enabled: ready,
        retry: 0,
        staleTime: 800_000,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        onSuccess: (response) => {
          dispatch(setCurrency(response.data.admin.devise));
        },
      }
    );

    const isLoading = useMemo(() => {
      return getAdminParamsIsLoading || getUserRightIsLoading;
    }, [getAdminParamsIsLoading, getUserRightIsLoading]);

    useEffect(() => {
      if (user && user.mustChangePassword) {
        navigate(passwordChangeRoute.toLink());
      }
    }, [user]);

    const isAuth = useMemo(
      () => user && !user.mustChangePassword && !!user.token && !!collectivity && !!deliveryPoint,
      [user, collectivity, deliveryPoint]
    );

    return isLoading ? null : isAuth ? (
      //eslint-disable-next-line
      <WrappedComponent {...(props as any)} />
    ) : (
      <Navigate replace to={'/connexion'} />
    );
  };

  ComponentWithAuthentication.displayName = `WithAuth(${displayName})`;

  return ComponentWithAuthentication;
}

export function MainTitle({ className }: { className: string }) {
  return <MainTitleContext.Consumer>{([title]) => <h2 className={className}>{title}</h2>}</MainTitleContext.Consumer>;
}

function DefaultLayout() {
  const isDesktop = useMediaQuery('(min-width : 1200px)');
  const titleContext = useState<string | JSX.Element | null>('');

  return (
    <QueryParamProvider adapter={ReactRouter6Adapter}>
      <MainTitleContext.Provider value={titleContext}>
        <ScrollRestoration getKey={(location) => location.pathname} />
        <ScrollToTop />
        <Global
          styles={css`
            body {
              background-color: #f8f9fa;
            }

            b,
            strong {
              font-weight: bold;
            }
          `}
        />
        {isDesktop && <Sidebar />}
        <main className={styles.Main}>
          <TopBar />
          {/*{children}*/}
          <React.Suspense fallback={<div className={'reset'}></div>}>
            <Outlet />
          </React.Suspense>
          <Footer />
        </main>
      </MainTitleContext.Provider>
    </QueryParamProvider>
  );
}

export default withAuthenticated(DefaultLayout);
