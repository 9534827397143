import { t } from 'i18next';
import { FormItemType } from 'users/types/forms';

const inOutCreationForm = (): FormItemType[][] => [
  [
    {
      type: 'text',
      placeholder: t('parameters.verification_text'),
      name: 'verification_text',
      id: 'verification_text',
    },
  ],
];

export default inOutCreationForm;
