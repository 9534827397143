import React, { HTMLAttributes, PropsWithChildren, useContext } from 'react';
import styled from '@emotion/styled';
import AccordionContext from './AccordionContext';

function AccordionDetails({ children, className }: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) {
  const { expanded } = useContext(AccordionContext);

  return expanded ? <AccordionDetailsRoot className={className}>{children}</AccordionDetailsRoot> : null;
}

const AccordionDetailsRoot = styled.div``;

export default AccordionDetails;
