import React, { useEffect, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { ChartData, ChartOptions, TooltipItem } from 'chart.js';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import { emptyPlugin } from '../../components/charts/StackedBar/StackedBar';
import { htmlLegendPlugin } from './RepartitionByType';

export const options: ChartOptions<'bar'> = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    intersect: false,
    mode: 'index',
  },
  plugins: {
    htmlLegend: {
      containerId: 'age-bar-legend',
    },
    tooltip: {
      position: 'nearest',
      mode: 'point',
      displayColors: false,
      backgroundColor: '#17394d',
      // mode: 'index',
      cornerRadius: 8,
      callbacks: {
        title(tooltipItems: TooltipItem<'bar'>[]): string | string[] | void {
          return tooltipItems.map((tooltipItem) => {
            return `${tooltipItem.dataset.label} ${tooltipItem.label}                                ${tooltipItem.raw}`;
          });
        },
        label: (tooltipItem: TooltipItem<'bar'>): string | string[] | void => {
          const total = (tooltipItem.dataset.data as number[]).reduce((prev, current) => {
            prev += current;
            return prev;
          }, 0);

          return [
            `Dans cette tranche d’âge:     ${`${((Number(tooltipItem.raw) / total) * 100 || 0).toFixed(2)}%`}`,
            `Total des utilisateur:                      ${`${
              //eslint-disable-next-line
              ((Number(tooltipItem.raw) / (tooltipItem.chart as any).usersTotal) * 100 || 0).toFixed(2)
            }%`}`,
          ];
        },
      },
    },
    /*tooltip: {

      position: 'nearest',
      mode: 'point',
      // mode: 'index',
      cornerRadius: 10,
      callbacks: {
        title(tooltipItems: TooltipItem<'bar'>[]): string | string[] | void {
          return tooltipItems.map((tooltipItem) => {
            // console.log(tooltipItem.chart.getActiveElements());
            return `${tooltipItem.label} ${tooltipItem.dataset.label}    ${tooltipItem.raw}`;
          });
          // return '';
        },
        /!*label: (tooltipItem: TooltipItem<'bar'>): string | string[] | void => {
          // console.log(tooltipItem);
          return '';
        },
        beforeBody: (tooltipItems: TooltipItem<'bar'>[]): string | string[] | void => {
          return '';
        },*!/
      },
    },*/
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      beginAtZero: true,
      stacked: true,
      // display: false,
      grid: {
        display: false,
      },
    },
    y: {
      beginAtZero: true,
      stacked: true,
      ticks: {
        callback: function (val, index) {
          // Hide every 2nd tick label
          return index % 2 === 0 ? this.getLabelForValue(Number(val)) : '';
        },
      },
      /*min: 0,
      max: 150,*/
    },
  },
};

const labels = ['- de 25 ans', '25 - 36 ans', '36 - 45 ans', '46 - 55 ans', 'NA'];

const backgroundColors = ['#f8f988', '#6d67e4', '#28abb9', '#b8de6f', '#ffd89c', '#ad8e70'];

export type AgeBarData = {
  label: string;
  data: number[];
}[];

const createData: (data: AgeBarData) => ChartData<'bar'> = (data) => {
  return {
    labels,
    datasets: data.map((item, index) => ({
      label: item.label,
      backgroundColor: backgroundColors[index],
      data: item.data,
      stack: `Stack ${index}`,
      borderRadius: 8,
      minBarLength: 3,
    })),
    /*datasets: [
      {
        label: 'Homme',
        backgroundColor: '#28abb9',
        data: [1, 5, 6, 8],
        stack: 'Stack 0',
        borderRadius: 6,
      },
      {
        label: 'Femme',
        backgroundColor: '#6d67e4',
        data: [9, 10, 6, 40],
        stack: 'Stack 1',
        borderRadius: 6,
      },
      {
        label: 'Entreprise',
        backgroundColor: '#b8de6f',
        data: [1, 20, 40, 8],
        stack: 'Stack 2',
        borderRadius: 6,
      },
    ],*/
  };
};

type AgeBarChartProps = {
  data: AgeBarData;
  total?: number;
};
function AgeBarChart(props: AgeBarChartProps) {
  const chart = useRef<ChartJSOrUndefined<'bar'>>();

  useEffect(() => {
    if (chart.current) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      Object.assign(chart.current, { usersTotal: props.total });
    }
  }, [chart.current]);

  return (
    <div>
      <div>
        <Bar
          ref={chart}
          height={285}
          data={createData(props.data)}
          options={options}
          plugins={[htmlLegendPlugin, emptyPlugin]}
        />
      </div>
      <ul id={'age-bar-legend'} className={'legend'} />
    </div>
  );
}

export default AgeBarChart;
