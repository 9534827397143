import React from 'react';
import { generatePath, LoaderFunction, NavLink, Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tab, Tabs, TabsList } from '../../components/Core/Tabs/Tabs';
import { TabComponent } from '../Reservation/Reservation.layout';
import { useMainTitle } from '../../components/Layout/MainTitleContext';
import { CustomDataIcon, IndicatorIcon, RawDataIcon } from '../../const/icons';

export const statsLayoutLoader: LoaderFunction = () => {
  return {};
};

function StatsLayout() {
  const { t } = useTranslation(['stats/layout']);
  useMainTitle('Statistiques');

  return (
    <div className={'reset'}>
      <div>
        <Tabs>
          <TabsList>
            <Tab
              component={({ children, ...otherProps }: TabComponent) => (
                <NavLink {...otherProps} to={generatePath('.')} end>
                  {children}
                </NavLink>
              )}>
              <IndicatorIcon width={16} />
              {t('indicators')}
            </Tab>
            <Tab
              component={({ children, ...otherProps }: TabComponent) => (
                <NavLink {...otherProps} to={generatePath('donnee-brutes')} end>
                  {children}
                </NavLink>
              )}>
              <RawDataIcon width={16} />
              {t('rawData')}
            </Tab>
            <Tab
              component={({ children, ...otherProps }: TabComponent) => (
                <NavLink {...otherProps} to={generatePath('donnees-personnalisees')} end>
                  {children}
                </NavLink>
              )}>
              <CustomDataIcon width={16} />
              {t('customData')}
            </Tab>
          </TabsList>
        </Tabs>
      </div>

      <React.Suspense fallback={<></>}>
        <Outlet />
      </React.Suspense>
    </div>
  );
}

export default StatsLayout;
