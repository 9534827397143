import React, { FunctionComponent, HTMLAttributes, PropsWithChildren, SVGProps, useMemo } from 'react';
import styled from '@emotion/styled';
import { css, SerializedStyles, Theme } from '@emotion/react';
import { CheckIcon, WarningIcon } from '../../../const/icons';

export enum TOAST_SEVERITY {
  primary = 'primary',
  error = 'error',
  success = 'success',
}

type ToastProps = HTMLAttributes<HTMLDivElement> & {
  type: keyof typeof TOAST_SEVERITY;
};

const defaultIconMappingBySeverity: {
  [key in Exclude<TOAST_SEVERITY, TOAST_SEVERITY.primary>]: FunctionComponent<
    SVGProps<SVGSVGElement> & { title?: string | undefined }
  >;
} = {
  error: WarningIcon,
  success: CheckIcon,
};

function Toast(props: PropsWithChildren<ToastProps>) {
  const { children, type: severityType, ...otherProps } = props;

  const IconComponent = useMemo(
    () => (severityType !== 'primary' ? defaultIconMappingBySeverity[severityType] : null),
    [severityType]
  );

  return (
    <ToastRoot severityType={severityType} {...otherProps}>
      {IconComponent && <IconComponent height={32} width={32} />}
      {children}
    </ToastRoot>
  );
}

const primary = (theme: Theme) => css`
  background-color: ${theme.color.primary['100']};
  border: solid 1px ${theme.color.primary['200']};
  color: ${theme.color.primary['200']};
`;

const error = (theme: Theme) => css`
  background-color: ${theme.color.red['100']};
  border: solid 1px ${theme.color.red['200']};
  color: ${theme.color.red['200']};
`;

const success = (theme: Theme) => css`
  background-color: ${theme.color.green['100']};
  border: solid 1px ${theme.color.green['200']};
  color: ${theme.color.green['200']};
`;

const colorsMapping: { [key in TOAST_SEVERITY]: (theme: Theme) => SerializedStyles } = {
  primary,
  error,
  success,
};

const ToastRoot = styled.div<{ severityType: keyof typeof TOAST_SEVERITY }>`
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: ${(props) => (props.severityType === 'primary' ? '8px' : '16px')};
  border-radius: 8px;

  ${(props) => colorsMapping[props.severityType](props.theme)};
`;

export default Toast;
