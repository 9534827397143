import React, { useCallback, useEffect, useMemo } from 'react';
import { Controller, FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DevTool } from '@hookform/devtools';
import { LoaderFunction, NavLink, useFetcher, useLoaderData, useNavigate, useSearchParams } from 'react-router-dom';
import { QueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import _, { upperFirst } from 'lodash';
import ConnectForm from '../../../components/Form/ConnectForm';
import Label from '../../../components/Core/Label/Label';
import TextFieldControlled from '../../../components/Form/ControlledFields/TextFieldControlled';
import { useMainTitle } from '../../../components/Layout/MainTitleContext';
import MainSection from '../../../components/Core/MainSection/MainSection';
import Card from '../../../components/Core/Card/Card';
import ButtonWithIcon from '../../../components/Core/Button/ButtonWithIcon';
import { Fieldset } from '../../stats/RawData.page';
import SelectField from '../../../components/Core/SelectField';
import { Option } from '../../../hook/useBuildForm';
import CheckboxItem from '../../../components/Core/CheckboxItem/CheckboxItem';
import store from '../../../internal/store';
import { bikeListQuery } from '../Products/Bike/BikeList.page';
import { RadioItem } from '../../Reservation/components/BookingDetails/FinishCard';
import { getAmountExcludingTaxes, getAmountIncludingTaxes } from '../Deposit/List/utils';
import CheckboxField from '../../../components/Core/CheckboxItem/CheckboxField';
import { TOAST_SEVERITY } from '../../../components/Core/Toast/Toast';
import useDisplayError from '../../../hook/useDisplayError';
import { useSnackbar } from '../../../context/SnackbarContext';
import { accessoryListQuery } from '../Products/Accessory/AccessoryListPage';
import styles from './NewPrice.module.scss';
import { durationListQuery } from './DurationList.page';
import { priceTypeListQuery } from './TypePriceList.page';

type PriceFormProps = {
  isEdit?: boolean;
};

export function PriceForm(props: PriceFormProps) {
  const { isEdit = false } = props;
  const { t } = useTranslation(['parameters/priceList/newPrice', 'form', 'common']);
  const [, setSearchParams] = useSearchParams();
  const fieldsT = useCallback((key: string) => t(`fields.${key}`), [t]);
  const { articleListResponse, durationListResponse, priceTypeListResponse, priceResponse } = useLoaderData() as {
    durationListResponse?: AxiosResponse;
    articleListResponse?: AxiosResponse | null;
    priceTypeListResponse?: AxiosResponse;
    priceResponse?: AxiosResponse;
    collId: number;
  };

  const VAToptions = [
    {
      value: 5.5,
      label: '5.5%',
    },
    { value: 10, label: '10%' },
    { value: 20, label: '20%' },
  ];

  const articleOptions = useMemo(
    () =>
      isEdit
        ? _(priceResponse?.data)
            .pick(['articleType.id', 'articleType.name'])
            .mapValues(({ id, name }) => ({ id, label: name }))
            .flatMap()
            .value()
        : //eslint-disable-next-line
          articleListResponse?.data?.map((article: any) => ({
            label: article.name,
            value: article.id,
          })) || [],
    [articleListResponse?.data, isEdit, priceResponse]
  );

  const durationOptions = useMemo(
    () =>
      //eslint-disable-next-line
      durationListResponse?.data?.map((duration: any) => ({
        label: duration.name,
        value: duration.id,
      })) || [],
    [durationListResponse?.data]
  );

  const priceTypeOptions = useMemo(
    () =>
      //eslint-disable-next-line
      priceTypeListResponse?.data?.map((priceType: any) => ({
        label: priceType.name,
        value: priceType.id,
      })) || [],
    [priceTypeListResponse?.data]
  );

  const categoryOptions = useMemo(() => {
    const commonT = (key: string) => upperFirst(t('common:'.concat(key)));
    return ['bike', 'accessory', 'park', 'freePark'].map((durationValue) => ({
      label: commonT(durationValue),
      value: durationValue,
    }));
  }, [t]);

  //eslint-disable-next-line
  const handleCategoryChange = useCallback((e: any) => {
    setSearchParams({ category: e.target.value });
  }, []);

  return (
    <ConnectForm>
      {({ register, setValue, getValues }) => (
        <>
          <div>
            <div className={'mb-4'}>
              <Label>{fieldsT('category.label')}</Label>
              <div className={styles.CategoryRadioItemGroup}>
                {categoryOptions.map((option) => (
                  <RadioItem
                    disabled={isEdit}
                    {...register('type', { disabled: isEdit, required: true, onChange: handleCategoryChange })}
                    label={option.label}
                    value={option.value as never}
                  />
                ))}
              </div>
            </div>

            <div className={styles.DefinitionSection}>
              <div>
                <Label>{fieldsT('article.label')}</Label>
                <Controller
                  name={'idArticleType'}
                  rules={{ required: true }}
                  // disabled={isEdit}
                  render={({ fieldState: { error }, field: { onChange, value } }) => (
                    <SelectField
                      disabled={isEdit}
                      placeholder={fieldsT('article.placeholder')}
                      error={error}
                      onChange={(newValue) => {
                        onChange(newValue);
                      }}
                      options={articleOptions}
                      value={articleOptions.find((option: Option) => option.value === value) || null}
                    />
                  )}
                />
              </div>

              <div>
                <Label>{fieldsT('duration.label')}</Label>
                <Controller
                  name={'idDuration'}
                  rules={{ required: true }}
                  // disabled={isEdit}
                  render={({ fieldState: { error }, field: { onChange, value } }) => (
                    <SelectField
                      disabled={isEdit}
                      placeholder={fieldsT('duration.placeholder')}
                      error={error}
                      onChange={(newValue) => {
                        onChange(newValue);
                      }}
                      options={durationOptions}
                      value={durationOptions.find((option: Option) => option.value === value) || null}
                    />
                  )}
                />
              </div>

              <div>
                <Label>{fieldsT('priceType.label')}</Label>
                <Controller
                  name={'idPriceType'}
                  rules={{ required: true }}
                  // disabled={isEdit}
                  render={({ fieldState: { error }, field: { onChange, value } }) => (
                    <SelectField
                      placeholder={fieldsT('priceType.placeholder')}
                      disabled={isEdit}
                      error={error}
                      onChange={(newValue) => {
                        onChange(newValue);
                      }}
                      options={priceTypeOptions}
                      value={priceTypeOptions.find((option: Option) => option.value === value) || null}
                    />
                  )}
                />
              </div>
            </div>
          </div>

          <Fieldset legend={t('price')} className={styles.PriceSection}>
            <div className={'row gap-8 mb-4'}>
              <div>
                <Label>{fieldsT('amountExcludingTaxes.label')}</Label>
                <TextFieldControlled
                  placeholder={fieldsT('amountExcludingTaxes.placeholder')}
                  {...register('mntHT', {
                    required: true,
                    valueAsNumber: true,
                    onChange: (e) => {
                      setValue('mntTTC', getAmountIncludingTaxes(Number(e.target.value), getValues('tauxTVA')));
                    },
                  })}
                />
              </div>
              <div>
                <Label>{fieldsT('amountIncludingTaxes.label')}</Label>
                <TextFieldControlled
                  placeholder={fieldsT('amountIncludingTaxes.placeholder')}
                  {...register('mntTTC', {
                    required: true,
                    valueAsNumber: true,
                    onChange: (e) => {
                      setValue('mntHT', getAmountExcludingTaxes(Number(e.target.value), getValues('tauxTVA')));
                    },
                  })}
                />
              </div>
            </div>

            <div>
              <Label>{fieldsT('vat.label')}</Label>
              <Controller
                name={'tauxTVA'}
                render={({ fieldState: { error }, field: { onChange, value } }) => (
                  <SelectField
                    error={error}
                    onChange={(newValue) => {
                      onChange(newValue);
                      setValue('mntTTC', getAmountIncludingTaxes(Number(getValues('mntHT')), newValue as number));
                    }}
                    options={VAToptions}
                    value={VAToptions.find((option: Option) => option.value === value) || null}
                  />
                )}
              />
            </div>
          </Fieldset>
          <Fieldset legend={t('display')}>
            <CheckboxItem {...register('show')} label={t('fields.show.label')} />

            <Controller
              name={'dates'}
              defaultValue={{ startDate: null, endDate: null }}
              render={({ field }) => (
                <CheckboxField
                  defaultChecked={field.value.startDate !== null}
                  label={t('fields.dateRange.label')}
                  startDate={field.value.startDate}
                  endDate={field.value.endDate}
                  onDatesChange={field.onChange}
                />
              )}
            />
          </Fieldset>
        </>
      )}
    </ConnectForm>
  );
}

export const newPriceLoader: (queryClient: QueryClient) => LoaderFunction =
  (queryClient) =>
  async ({ request }) => {
    const collId = store.getState().authState.collectivity;
    const url = new URL(request.url);
    const category = url.searchParams.get('category');

    let articleListResponse = null;

    if (category) {
      switch (category) {
        case 'bike':
          articleListResponse = await queryClient.ensureQueryData({
            ...bikeListQuery({ idColl: collId }),
          });
          break;
        case 'accessory':
          articleListResponse = await queryClient.ensureQueryData({
            ...accessoryListQuery({ idColl: collId }),
          });
          break;
      }
    }

    const durationListResponse = await queryClient.ensureQueryData({
      ...durationListQuery({ idColl: collId }),
    });

    const priceTypeListResponse = await queryClient.ensureQueryData({
      ...priceTypeListQuery({ idColl: collId }),
    });

    return { collId, durationListResponse, priceTypeListResponse, articleListResponse };
  };

function NewPricePage() {
  const fetcher = useFetcher();
  const { t } = useTranslation(['parameters/priceList/newPrice', 'form']);
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      category: null,
      idArticleType: null,
      idDuration: null,
      idPriceType: null,
      mntHT: null,
      mntTTC: null,
      tauxTVA: 5.5,
      show: true,
    },
  });
  const navigate = useNavigate();
  useDisplayError(fetcher.data);
  const snackbar = useSnackbar();
  useMainTitle(t('title'));

  const onSubmit = (data: FieldValues) => {
    const copyData = _(data).chain().assign(data.dates).omit('dates').value();

    fetcher.submit({ action: 'new', ...copyData }, { method: 'post', encType: 'application/json' });
  };

  useEffect(() => {
    if (fetcher.data && fetcher.data.status === 200) {
      snackbar?.setAlert({
        message: t('successMessage'),
        severity: TOAST_SEVERITY.success,
      });
      navigate('/parametres/grille-tarifaire');
    }
  }, [fetcher.data, t]);

  return (
    <MainSection className={'reset'}>
      <Card
        content={
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <p>{t('form:all_required')}</p>
              <PriceForm />
              <div className={'row mt-4 justify-between items-center'}>
                <ButtonWithIcon
                  as={NavLink}
                  to={'/parametres/grille-tarifaire'}
                  icon={'CrossWithoutCircle'}
                  color={'secondary'}>
                  {t('common:cancel')}
                </ButtonWithIcon>
                <ButtonWithIcon icon={'CheckWithoutCircle'} type={'submit'}>
                  {t('submitCta')}
                </ButtonWithIcon>
              </div>
            </form>

            <DevTool control={methods.control} />
          </FormProvider>
        }
      />
    </MainSection>
  );
}

export default NewPricePage;
