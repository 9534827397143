import React, { useCallback, useMemo } from 'react';
import { Controller, useForm, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getCheckInVerifs, postBookingMedia, postCheckIn } from '../../api/booking.api';
import { selectCollAndDeliveryPointID } from '../../auth/selectors/authSelectors';
import ButtonWithIcon from '../../components/Core/Button/ButtonWithIcon';
import Card from '../../components/Core/Card/Card';
import Datagrid, { RowItem } from '../../components/Core/Datagrid/Datagrid';
import { GridColDefinition } from '../../components/Core/Datagrid/gridCols';
import DropzoneFileInput, { DROPZONE_SIZES } from '../../components/Core/DropzoneFileInput/DropzoneFileInput';
import FieldError from '../../components/Core/FieldError/FieldError';
import Label from '../../components/Core/Label/Label';
import MainSection from '../../components/Core/MainSection/MainSection';
import SelectField from '../../components/Core/SelectField';
import TextField from '../../components/Core/TextField';
import TextArea from '../../components/Form/TextArea/TextArea';
import { useMainTitle } from '../../components/Layout/MainTitleContext';
import { WarningIcon } from '../../const/icons';
import { generatePath } from '../../utils/router';
import styles from './bookingDetails.module.scss';

export function useCheckStatuses() {
  const { t } = useTranslation(['reservation/components/checkIn']);

  return useMemo(() => {
    return [
      { label: t('page.statuses.good_condition'), value: 'bon_etat' },
      { label: t('page.statuses.used'), value: 'used' },
      { label: t('page.statuses.defective'), value: 'defectueux' },
    ];
  }, [t]);
}

function useColumns({ control }: Partial<UseFormReturn>): GridColDefinition[] {
  const { t } = useTranslation(['reservation/components/checkIn', 'form']);
  const statuses = useCheckStatuses();

  return useMemo(() => {
    return [
      {
        width: 50,
        field: '',
        type: 'custom',
        renderCell: ({ row }) => {
          return <div>{row.is_mandatory ? <WarningIcon className={'warning'} width={16} /> : null}</div>;
        },
      },
      {
        field: 'label',
        headerName: t('page.columns.elements'),
        type: 'custom',
        renderCell: ({ row }) => {
          return (
            <div>
              <span>{row.label}</span>
            </div>
          );
        },
      },
      {
        field: '',
        headerName: t('page.columns.image'),
        type: 'custom',
        renderCell: ({ row }) => {
          return (
            <RowItem key={row.id_form.toString().concat('media')}>
              <Controller
                rules={{ required: row.is_media_mandatory === 1 }}
                control={control}
                render={({ fieldState: { error }, field: { onChange } }) => (
                  <div className={'field'}>
                    <DropzoneFileInput
                      required={row.is_media_mandatory === 1}
                      canBeDeleted
                      size={DROPZONE_SIZES.mini}
                      onChange={onChange}
                    />
                    {error && <FieldError>{t('form:errors.'.concat(error.type))}</FieldError>}
                  </div>
                )}
                name={`${row.id_form}.media`}
              />
            </RowItem>
          );
        },
      },
      {
        field: '',
        headerName: t('page.columns.status'),
        type: 'custom',
        renderCell: ({ row }) => {
          return (
            <RowItem key={row.id_form.toString().concat('status')}>
              <Controller
                name={`${row.id_form}.status`}
                rules={{ required: t('form:errors.required') }}
                defaultValue={statuses[0].value}
                control={control}
                render={({ fieldState: { error }, field: { onChange, value } }) => (
                  <SelectField
                    error={error}
                    placeholder={t('page.statusField.placeholder')}
                    onChange={onChange}
                    value={value}
                    className={'field--full field'}
                    menuPosition={'fixed'}
                    options={statuses}
                  />
                )}
              />
            </RowItem>
          );
        },
      },
      {
        field: '',
        headerName: t('page.columns.commentary'),
        type: 'custom',
        renderCell: ({ row }) => {
          return (
            <RowItem key={row.id_form.toString().concat('commentary')}>
              <Controller
                rules={{ required: row.is_mandatory === 1 ? t('form:errors.required') : false }}
                defaultValue={''}
                control={control}
                render={({ fieldState: { error }, field: { onChange, value } }) => (
                  // <div className={'field'}>
                  <TextField
                    className={'w-full'}
                    error={error}
                    placeholder={t('page.commentaryField.placeholder').concat(row.is_mandatory === 1 ? '*' : '')}
                    value={value || ''}
                    onChange={onChange}
                  />
                  // </div>
                )}
                name={`${row.id_form}.commentaire`}
              />
            </RowItem>
          );
        },
      },
    ];
  }, [control, statuses, t]);
}

function CheckInPage() {
  const { t } = useTranslation(['reservation/components/checkIn', 'form']);
  const { control, handleSubmit } = useForm();
  const { bookingId, userId } = useParams();
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  useMainTitle(t('page.title'));
  const columns = useColumns({ control });
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { data: checkInListResponse } = useQuery(
    ['checkInVerifList', { id_resa: bookingId, id_coll }],
    ({ queryKey }) => {
      const [, queryParams] = queryKey as [string, { id_resa: number; id_coll: number }];
      return getCheckInVerifs(queryParams);
    },
    {
      onSuccess: async (response) => {
        if (response.data.length === 0) {
          await queryClient.invalidateQueries(['checkInVerifList']);
          navigate(generatePath('home.invoicing.reservationDetails', { params: { bookingId, userId } }));
        }
      },
      staleTime: 260_000,
      refetchOnWindowFocus: false,
    }
  );

  const { mutate: handlePostCheckIn, isLoading } = useMutation(
    ['booking.post.checkIn'], //eslint-disable-next-line
    async (data: any) => {
      if (!bookingId || !userId) throw new Error('params is empty');
      const copy = {
        ...data,
      };
      await Promise.all(
        //eslint-disable-next-line
        Object.entries(data).map(async ([formId, values]: [string, any]) => {
          //eslint-disable-next-line
          const currentCheckIn = checkInListResponse?.data.find((checkIn: any) => checkIn.id_form === Number(formId));
          if (values.media) {
            const response = await postBookingMedia({
              id_coll,
              id_user: userId,
              file: values.media,
              pjName: 'checkin_'.concat(formId),
            });
            delete copy[formId].media;
            Object.assign(copy[formId], { id_media: response.data.id_media });
          } else {
            Object.assign(copy[formId], { id_media: '' });
          }
          Object.assign(copy[formId], { ...currentCheckIn });
        })
      );

      return postCheckIn({
        form: Object.values(copy),
        id_coll,
        id_resa: bookingId,
      });
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['booking']);
        await queryClient.invalidateQueries(['checkInVerifList']);
        navigate(generatePath('home.invoicing.reservationDetails', { params: { bookingId, userId } }));
      },
    }
  );

  const onSubmit = useCallback(
    handleSubmit(async (data) => {
      handlePostCheckIn(data);
    }),
    [userId, bookingId, id_coll, checkInListResponse]
  );

  return (
    <MainSection className={'reset'}>
      <Card color={'primary'}>{t('infoBanner')}</Card>
      <span>{t('form:required_fields_notice')}</span>

      <Card title={t('page.cardTitle')}>
        <Datagrid
          //eslint-disable-next-line
          rows={checkInListResponse?.data.filter((row: any) => row.need_status !== 0) || []}
          columns={columns}
        />
      </Card>
      {checkInListResponse?.data
        //eslint-disable-next-line
        .filter((verif: any) => verif.need_status === 0)
        //eslint-disable-next-line
        .map((row: any) => (
          <Card
            title={t('page.globalControlCardTitle')}
            content={
              <>
                <div className={'row gap-8'}>
                  <div className={'flex-1'}>
                    <Controller
                      name={`${row.id_form}.commentaire`}
                      rules={{ required: row.is_mandatory === 1 }}
                      defaultValue={''}
                      control={control}
                      render={({ fieldState: { error }, field: { onChange, value } }) => (
                        <>
                          <Label required={row.is_mandatory === 1}>{t('commentaryField.label')}</Label>
                          <div>
                            <TextArea
                              placeholder={t('commentaryField.placeholder')}
                              onChange={onChange}
                              value={value}
                            />
                            {error && <FieldError>{t('form:errors.'.concat(error.type))}</FieldError>}
                          </div>
                        </>
                      )}
                    />
                  </div>
                  <Controller
                    name={`${row.id_form}.media`}
                    rules={{ required: row.is_media_mandatory === 1 }}
                    control={control}
                    render={({ fieldState: { error }, field: { onChange } }) => (
                      <div>
                        <DropzoneFileInput canBeDeleted required={row.is_media_mandatory === 1} onChange={onChange} />
                        {error && <FieldError>{t('form:errors.'.concat(error.type))}</FieldError>}
                      </div>
                    )}
                  />
                </div>
              </>
            }
          />
        ))}
      <div className={styles.FooterActions}>
        <ButtonWithIcon
          as={Link}
          to={generatePath('home.invoicing.reservationDetails', { params: { bookingId, userId } })}
          color={'secondary'}
          icon={'CrossWithoutCircle'}>
          {t('page.cancelCta')}
        </ButtonWithIcon>
        <ButtonWithIcon isLoading={isLoading} icon={'CheckWithoutCircle'} onClick={onSubmit}>
          {t('page.validateCta')}
        </ButtonWithIcon>
      </div>
    </MainSection>
  );
}

export default CheckInPage;
