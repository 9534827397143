import styled from '@emotion/styled';
import React, { ComponentPropsWithoutRef, PropsWithChildren } from 'react';
import { CheckIcon } from '../../../const/icons';
import { getColor } from '../../../utils/style';

const iconColorMap = {
  success: getColor('green.200'),
  primary: getColor('primary.200'),
  yellow: () => '#ce6900',
  wait: getColor('primary.200'),
  danger: getColor('red.200'),
  gray: getColor('gray.200'),
};

type StepIconProps = {
  type?: keyof typeof iconColorMap;
};

function StepIcon({ type = 'primary' }: StepIconProps) {
  return (
    <StepIconRoot type={type}>
      {type === 'gray' ? (
        <Circle />
      ) : ['wait', 'yellow'].includes(type) ? (
        <CircleWithDot />
      ) : (
        <CheckIcon width={16} height={16} />
      )}
    </StepIconRoot>
  );
}

const Circle = styled.div`
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: currentColor;
`;

const CircleWithDot = styled(Circle)`
  position: relative;
  background-color: transparent;
  border: 2px solid currentColor;

  :after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: currentColor;
  }
`;

const StepIconRoot = styled.div<StepIconProps>`
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  background-color: white;
  color: ${({ type }) => iconColorMap[type || 'primary']};
  //padding: 4px;
  border-radius: 50px;
`;

const lineColorMap = {
  primary: getColor('primary.200'),
  secondary: getColor('gray.200'),
  success: () => 'linear-gradient(to bottom, #0080cc, #81cc2d)',
  yellow: () => 'linear-gradient(to bottom, #0080cc, #ce6900)',
  danger: () => 'linear-gradient(to bottom, #0080cc, #cc4119)',
};

type StepProps = ComponentPropsWithoutRef<typeof StepIcon> & {
  last?: boolean;
  lineColor?: keyof typeof lineColorMap;
};

function Step(props: PropsWithChildren<StepProps>) {
  const { children, last, lineColor, type } = props;

  return (
    <StepRoot {...props}>
      <StepRootLeftSection>
        <StepIcon type={type} />
        {!last && <StepLine lineColor={lineColor} />}
      </StepRootLeftSection>
      <p>{children}</p>
    </StepRoot>
  );
}

const StepRoot = styled.div<StepProps>`
  display: flex;
  gap: 16px;
  min-height: 40px;
  line-height: normal;

  p {
    margin-bottom: 16px;
  }
`;

const StepRootLeftSection = styled.div`
  position: relative;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StepLine = styled.div<{ lineColor?: keyof typeof lineColorMap }>`
  width: 2px;
  background: ${({ lineColor, theme }) => lineColorMap[lineColor || 'primary']({ theme })};
  height: 100%;
`;

export default Step;
