import React from 'react';
import { useFetcher } from 'react-router-dom';
import { Controller, FieldValues, FormSubmitHandler, useForm } from 'react-hook-form';
import moment from 'moment';
import { DevTool } from '@hookform/devtools';
import { useTranslation } from 'react-i18next';
import useDisplayError from '../../../hook/useDisplayError';
import styles from './GlobalStatsExport.module.scss';
import DateRangePicker from './DateRangePicker';
import CustomExportCard from './CustomExportCard';

function GlobalStatsExport() {
  const { t } = useTranslation('stats/customExport');
  const fetcher = useFetcher();
  const methods = useForm();
  useDisplayError(fetcher.data);

  const onSubmit: FormSubmitHandler<FieldValues> = ({ data }) => {
    fetcher.submit({ ...data, type: 'global' }, { method: 'post', encType: 'application/json' });

    methods.reset({}, { keepValues: true });
  };

  return (
    <CustomExportCard
      title={t('globalExport.title')}
      onSubmit={onSubmit}
      fetcherData={fetcher.data}
      fetcherState={fetcher.state}
      text={t('globalExport.advice')}
      {...methods}>
      <Controller
        name={'dates'}
        defaultValue={{ startDate: moment(), endDate: moment() }}
        render={({ field }) => {
          return (
            <div className={[styles.DateRangeBoxField, 'box-field'].join(' ')}>
              <span>{t('fields.period.label')}</span>
              <DateRangePicker
                startDate={field.value.startDate}
                endDate={field.value.endDate}
                onDatesChange={field.onChange}
              />
            </div>
          );
        }}
      />
      <DevTool control={methods.control} />
    </CustomExportCard>
  );
}

export default GlobalStatsExport;
