import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import {
  BarController,
  BarElement,
  Chart as ChartJS,
  ChartData,
  ChartDataset,
  ChartOptions,
  Plugin,
  TooltipItem,
} from 'chart.js';
import { cloneDeep } from 'lodash';
import { createPortal } from 'react-dom';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import _ from 'lodash';
import { CustomLegend, emptyPlugin } from '../../components/charts/StackedBar/StackedBar';
import FiltersInfos from './FiltersInfos/FiltersInfos';

const htmlLegendPlugin: Plugin = {
  id: 'htmlLegend',
  afterUpdate: (chart) => {
    const legendElements: Element[] = [];

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const items = chart.options.plugins.legend.labels.generateLabels(chart);

    items.forEach((item, index) => {
      const li = document.createElement('li');
      li.className = 'legend-item';
      li.style.alignItems = 'center';
      li.style.cursor = 'pointer';
      li.style.display = 'flex';
      li.style.flexDirection = 'row';
      li.style.marginLeft = '10px';

      li.addEventListener('click', () => {
        const isVisible = chart.isDatasetVisible(index);
        chart.setDatasetVisibility(index, !isVisible);
        chart.update();
        isVisible ? (li.className = 'legend-item hidden') : (li.className = 'legend-item');
      });

      /*li.onclick = () => {
        chart.setDatasetVisibility(index, !chart.isDatasetVisible(index));
        chart.update();
      };*/

      // Color box
      const boxSpan = document.createElement('span');
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      boxSpan.style.background = item.fillStyle;

      // Text
      const textContainer = document.createElement('p');
      textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

      const text = document.createTextNode(item.text as string);
      textContainer.appendChild(text);

      li.appendChild(boxSpan);
      li.appendChild(textContainer);

      legendElements.push(li);
    });

    //eslint-disable-next-line
    // @ts-ignore
    Object.assign(chart, { legendElements });
  },
};

class AvailableStackedBarController extends BarController {
  draw() {
    const meta = this._cachedMeta;
    const rects = meta.data as BarElement[];
    const ilen = rects.length;
    let i = 0;

    for (; i < ilen; ++i) {
      const currentRect = cloneDeep(rects[i]);
      const totalValue = meta.controller.getDataset().data[0];

      if (!currentRect.x) currentRect.x = rects[0].x;
      if (i === 1) {
        currentRect.options.backgroundColor = totalValue !== 0 ? 'rgba(0, 0, 0, 0.2)' : 'rgba(0, 0, 0, 0.0)';
      }
      currentRect.draw(this.chart.ctx);
    }
  }
}

AvailableStackedBarController.id = 'availableStackedBar';
AvailableStackedBarController.defaults = BarController.defaults;

ChartJS.register(AvailableStackedBarController);

export const backgroundColors = ['#0081c9', '#7149c6', '#cc79ce', '#ff7e67', '#f39233', '#ffdf31', '#03c988'];

export const createData: (items: AvailableChartData) => ChartData<'availableStackedBar'> = (items) => {
  //eslint-disable-next-line
  const datasets: ChartDataset<'availableStackedBar', any>[] = items.map((item, index) => ({
    label: item.label,
    data: item.data,
    backgroundColor: item.backgroundColor,
    minBarLength: 10,
    borderRadius: 15,
    barPercentage: 0.5,
  }));

  return {
    labels: ['test'],
    datasets,
  };
};

export const defaultOptions: ChartOptions<'availableStackedBar'> = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    intersect: false,
    mode: 'index',
  },
  plugins: {
    tooltip: {
      position: 'nearest',
      mode: 'point',
      displayColors: false,
      backgroundColor: '#17394d',
      // mode: 'index',
      cornerRadius: 8,
      callbacks: {
        title(tooltipItems: TooltipItem<'availableStackedBar'>[]): string | string[] | void {
          return tooltipItems.map((tooltipItem) => {
            return `${tooltipItem.dataset.label}`;
          });
        },
        label: (tooltipItem: TooltipItem<'availableStackedBar'>): string | string[] | void => {
          return [
            `Total                                 ${tooltipItem.dataset.data[0]}`,
            `Disponibles                      ${tooltipItem.dataset.data[1]}`,
          ];
        },
      },
    },
    legend: {
      display: false,
      position: 'top',
    },
    title: {
      display: true,
      text: 'Type de vélos',
      position: 'bottom',
    },
  },
  scales: {
    x: {
      beginAtZero: true,
      display: false,
      grid: {
        display: false,
      },
    },
    y: {
      beginAtZero: true,
      ticks: {
        // stepSize: 10,
        callback: function (val, index) {
          // Hide every 2nd tick label
          return index % 2 === 0 ? this.getLabelForValue(Number(val)) : '';
        },
      },
      min: 0,
    },
  },
};

export type AvailableChartData = {
  label: string;
  data: [number, number];
  backgroundColor: string;
}[];

type AvailableStackedBarChartProps = {
  containerLegendId: string;
  title?: string;
  className?: string;
  data: AvailableChartData;
};

function AvailableStackedBarChart(props: AvailableStackedBarChartProps) {
  const { containerLegendId, className, data } = props;
  const chart = useRef<ChartJSOrUndefined<'availableStackedBar'>>();
  const [container, setContainer] = useState<HTMLElement | null>(null);
  //eslint-disable-next-line
  const [legendItems, setLegendItems] = useState<any[]>([]);

  useEffect(() => {
    if (chart.current) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const legendItems = chart.current.legendElements;
      if (legendItems) setLegendItems(legendItems);
    }
  }, [chart.current]);

  useLayoutEffect(() => {
    if (containerLegendId) {
      const element = document.getElementById(containerLegendId);
      if (element) setContainer(element);
    }
  }, [containerLegendId]);

  const options = useMemo(() => {
    return _.merge(defaultOptions, { plugins: { title: { text: props.title } } });
  }, [props.title]);

  return (
    <>
      {container && createPortal(<CustomLegend legendItems={legendItems} />, container)}
      <div className={className}>
        <FiltersInfos />
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
          <Chart
            ref={chart}
            type={'availableStackedBar'}
            data={createData(data)}
            options={options}
            plugins={[htmlLegendPlugin, emptyPlugin]}
          />
        </div>
      </div>
    </>
  );
}

export default AvailableStackedBarChart;
