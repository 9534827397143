import { useInstanceApi } from '../../../api/axios';
import { useRequest } from '../../../utils/request';

const URL = {
  fetchWorkshopParameters: 'param/workshop',
  putWorkshopParameters: 'param/workshop',
};

function useWorkshopParametersApi() {
  const instance = useInstanceApi();

  const { request: fetchWorkshopParameters } = useRequest((queryParams: { id_coll: number }) =>
    instance.get(URL.fetchWorkshopParameters, { params: queryParams })
  );

  const { request: putWorkshopParameters } = useRequest((data) => instance.post(URL.putWorkshopParameters, data));

  return {
    fetchWorkshopParameters,
    putWorkshopParameters,
  };
}

export default useWorkshopParametersApi;
