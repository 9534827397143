import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import routes from './routes';

function Router() {
  const queryClient = useQueryClient();
  const router = createBrowserRouter(routes(queryClient));

  return (
    <React.Suspense fallback={<></>}>
      <ReactQueryDevtools position={'bottom-right'} />
      <RouterProvider router={router} />
    </React.Suspense>
  );
}

export default Router;
