import { Container } from 'inversify';
import { Api } from '../api/axios';
import { DashboardRepository } from '../repository/dashboard.repository';
import { AuthRepository } from '../repository/auth.repository';
import { UserRepository } from '../repository/user.repository';
import { ParameterRepository } from '../repository/parameter.repository';
import { ContractRepository } from '../repository/contract.repository';
import { SERVICE_KEYS } from './keys';

const container = new Container();
container.bind<ContractRepository>(SERVICE_KEYS.CONTRACT_REPOSITORY).to(ContractRepository).inSingletonScope();
container.bind<UserRepository>(SERVICE_KEYS.USER_REPOSITORY).to(UserRepository).inSingletonScope();
container.bind<ParameterRepository>(SERVICE_KEYS.PARAMETER_REPOSITORY).to(ParameterRepository).inSingletonScope();
container.bind<DashboardRepository>(SERVICE_KEYS.DASHBOARD_REPOSITORY).to(DashboardRepository).inSingletonScope();
container.bind<AuthRepository>(SERVICE_KEYS.AUTH_REPOSITORY).to(AuthRepository).inSingletonScope();
container.bind<Api>(SERVICE_KEYS.API_INSTANCE).to(Api).inSingletonScope();

export default container;
