import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import DatePicker from 'fragments/datepicker/DatePicker';
import { t } from 'internal/i18n';
import { RentalPriceType } from 'users/types/forms';
import { computeRentalDuration } from 'users/utils/resabike';

type Props = {
  handleChangeDate?: (dates: { startingDate: string; endingDate: string }) => void;
  price: RentalPriceType | null;
  showTimeInput?: boolean;
};

const DurationForm = ({ price, handleChangeDate = () => null, showTimeInput = true }: Props): ReactElement | null => {
  const rentalStartDate = dayjs().format('YYYY-MM-DD HH:mm');

  const [startingDate, setStartingDate] = useState<string>(
    (rentalStartDate && dayjs(rentalStartDate, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm')) || ''
  );

  const [maxRentalDuration, rentalDurationTypology] = useMemo(() => {
    return [price?.max_duree ?? 0, computeRentalDuration(price?.type_duree || '')];
  }, [price?.type_duree]);

  const isTrimester = useMemo(() => price?.type_duree === 'trimestre', [price?.type_duree]);

  const minAdded = useMemo(() => {
    if (price?.min_duree) {
      return isTrimester ? 3 * price?.min_duree : price?.min_duree;
    }

    return 0;
  }, [price?.min_duree, isTrimester]);

  const numberAdded = useMemo(() => {
    if (price?.max_duree) {
      return isTrimester ? 3 * price?.max_duree : price?.max_duree;
    }

    return 0;
  }, [price?.max_duree, isTrimester]);

  const [endingDate, setEndingDate] = useState<string>('');

  useEffect(() => {
    if (rentalStartDate && maxRentalDuration && rentalDurationTypology) {
      setStartingDate(dayjs(rentalStartDate, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm'));

      setEndingDate(
        dayjs(rentalStartDate, 'YYYY-MM-DD HH:mm').add(minAdded, rentalDurationTypology).format('YYYY-MM-DD HH:mm')
      );
    }
  }, [rentalStartDate, maxRentalDuration, rentalDurationTypology, isTrimester, price, minAdded]);

  const onChangeDate = useCallback(
    (type: string) => (value: string) => {
      if (type === 'starting') {
        if (value === 'Invalid Date' || !value) {
          setStartingDate('');
          return;
        }

        setStartingDate(dayjs(value, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm'));
        setEndingDate(
          dayjs(value, 'YYYY-MM-DD HH:mm').add(minAdded, rentalDurationTypology).format('YYYY-MM-DD HH:mm')
        );
        return;
      }

      if (type === 'ending') {
        if (value === 'Invalid Date' || !value) {
          setEndingDate('');
          return;
        }

        setEndingDate(value);
        return;
      }
    },
    [maxRentalDuration, rentalDurationTypology, minAdded]
  );

  useEffect(() => {
    handleChangeDate({ startingDate, endingDate });
  }, [startingDate, endingDate]);

  const setIncludeDates = useCallback(() => {
    if (price?.type_duree === 'jour') {
      return undefined;
    }

    if (price?.max_duree && price?.max_duree > 1) {
      const includeDates = [];
      const max = isTrimester ? price?.max_duree * 3 : price?.max_duree;
      const start = isTrimester ? 3 : 1;

      for (let i = start; i <= max; isTrimester ? (i += 3) : i++) {
        const date = dayjs(startingDate || rentalStartDate, 'YYYY-MM-DD HH:mm').add(i, rentalDurationTypology);
        if (date.isValid()) {
          includeDates.push(date.toDate());
        }
      }

      return includeDates;
    } else if (price?.max_duree) {
      const max = isTrimester ? price?.max_duree * 3 : price?.max_duree;

      return [
        dayjs(startingDate || rentalStartDate, 'YYYY-MM-DD HH:mm')
          .add(max, rentalDurationTypology)
          .toDate(),
      ];
    }
  }, [startingDate, maxRentalDuration, rentalDurationTypology, price, isTrimester]);

  const minDay = useMemo(() => {
    if ((startingDate || rentalStartDate) && rentalDurationTypology && price?.min_duree) {
      return dayjs(startingDate || rentalStartDate, 'YYYY-MM-DD HH:mm')
        .add(minAdded, rentalDurationTypology)
        .toDate();
    }

    return dayjs(startingDate || rentalStartDate, 'YYYY-MM-DD HH:mm').toDate();
  }, [price?.type_duree, price?.min_duree, startingDate, rentalStartDate, rentalDurationTypology]);

  const maxDay = useMemo(() => {
    if ((startingDate || rentalStartDate) && rentalDurationTypology && price?.max_duree && price?.max_duree > 0) {
      return dayjs(startingDate || rentalStartDate, 'YYYY-MM-DD HH:mm')
        .add(numberAdded, rentalDurationTypology)
        .toDate();
    }

    return null;
  }, [price, startingDate, numberAdded]);

  const maxDateForStart = useMemo(() => {
    if (price?.type_duree === 'jour') {
      return dayjs(rentalStartDate, 'YYYY-MM-DD HH:mm').add(30, 'days').toDate();
    }
    return null;
  }, [price?.type_duree]);

  const renderForm = useMemo(() => {
    if (!price) {
      return (
        <div className={'row'}>
          <div className={'col-md-6'}>
            <input className={'form-control'} disabled />
          </div>
          <div className={'col-md-6'}>
            <input className={'form-control'} disabled />
          </div>
        </div>
      );
    }

    if (maxRentalDuration && rentalDurationTypology) {
      return (
        <div className={'form-row'}>
          <DatePicker
            placeholder={t('forms.dateStart')}
            className={'datetimepicker-input'}
            name={'date_debut'}
            id={'date_debut'}
            size={'col-md-6'}
            type={'datetimepicker-input'}
            defaultValue={startingDate}
            parentValue={startingDate}
            handleChange={onChangeDate('starting')}
            minDate={dayjs(rentalStartDate, 'YYYY-MM-DD HH:mm').toDate()}
            maxDate={maxDateForStart}
            showTimeInput={showTimeInput}
          />
          <DatePicker
            placeholder={t('forms.dateEnd')}
            className={'datetimepicker-input'}
            name={'date_fin'}
            id={'date_fin'}
            type={'datetimepicker-input'}
            size={'col-md-6'}
            defaultValue={endingDate}
            parentValue={endingDate}
            handleChange={onChangeDate('ending')}
            minDate={minDay}
            maxDate={price?.type_duree === 'jour' ? maxDay : null}
            includeDates={setIncludeDates()}
            showTimeInput={showTimeInput}
          />
        </div>
      );
    }
    return null;
  }, [price, maxRentalDuration, rentalDurationTypology, startingDate, endingDate, rentalStartDate, showTimeInput]);

  return <>{renderForm}</>;
};

export default DurationForm;
