/* eslint-disable max-len */

export default {
  global: {
    Dashboard: 'Dashboard',
    sign_in: 'Se connecter',
    sign_out: 'Se déconnecter',
    home: 'Home',
    prev: 'Précédent',
    next: 'Suivant',
    bike: 'Vélo',
    park: 'Consigne',
    free_park: 'Consigne libre',
    forgot_pwd: 'Mot de passe oublié',
    go_back: 'Revenir à la page précédente',
    search: 'Rechercher : ',
    search_by_user: 'Rechercher par utilisateur: ',
    daily_fund: 'Jour',
    from: 'Du',
    to: 'Au',
    collectivity: 'Collectivité',
    display: 'Afficher ',
    elements: 'élements',
    add: 'Ajouter',
    users: 'Usagers',
    title: 'Titre',
    subtitle: 'Sous-titre',
    banner: "Bandeau d'info",
    delete: 'Supprimer',
    reset: 'Réinitialiser',
  },
  filters: {
    all: 'Tous',
  },
  stats: {
    turnover_title: "Chiffre d'affaire par moyen de paiement",
    turnover_by_article: "Chiffre d'affaire et nombre de réservation par type d'article",
  },
  export: {
    title: 'Exporter',
  },
  error: {
    invalidAbotc: "Le numéro du passe navigo n'est pas bon",
    invalidDateOfBirth: "La date de naissance n'est pas bonne",
    cannotSetNegOrZeroPrice: 'Le montant doit être supérieur à zéro',
    depotNameTooLong: 'Le nom du dépôt est trop long',
    montant_htIsEmpty: 'Le montant est requis',
    cannotSetZeroPrice: 'Le montant doit être supérieure à zéro',
    tooSmallDateEnd: 'La date de fin doit être supérieure à la date de début',
    alreadyRegisteredAgent: 'Cet agent est déjà enregistré',
    meanNotAvailable: 'Moyen de paiement non disponible pour ce type de remboursement',
    motif_utilisationIsEmpty: "Le motif d'utilisation est obligatoire",
    wrongReservation: "La réservation n'est pas valide",
    paymentNotCompleted: "Le paiement n'a pas été effectué",
    transactionNotFoundForRefund: "La transaction n'existe pas ou n'a pas été remisée",
    badTransactionRefundAmount: 'Remboursement déjà faite ou montant supérieur à la transaction',
    cannotRefundTransaction: 'Impossible de rembourser la transaction',
    cannotCancelToken: 'Libération SEPA impossible',
    tooBigRefund: 'Le montant de remboursement est trop grand',
    refundAlreadyDone: 'Le remboursement a déjà été effectué',
    resaNotFound: 'Réservation non trouvée',
    natureIsEmpty: 'Nature obligatoire',
    ibanIsMissing: 'Veuillez renseigner un IBAN',
    bicIsMissing: 'Veuillez renseigner un BIC',
    sogecommerceError: "Une erreur s'est produite avec Sogecommerce",
    invalidPasReservation: 'Heure invalide, veuillez choisir une heure de réservation valide',
    notAvailableAccessoire: 'Accessoire non disponible ou deja reservé',
    default: "Une erreur inattendue s'est produite. Veuillez réessayer plus tard",
    unknownError: "Une erreur inattendue s'est produite. Veuillez réessayer plus tard.",
    invalidOrExpiredToken: 'Votre session est expirée. Merci de vous reconnecter.',
    missingToken: 'Votre session est expirée. Merci de vous reconnecter.',
    emailNotValidated:
      "Vous n'avez pas encore validée votre adresse email. Vous pourrez ensuite vous connecter. Merci.",
    resetCodeAlreadyUsed:
      'Vous avez déjà utilisé ce lien pour renouveler votre mot de passe, veuillez recommencer la procédure de mot de passe oublié.',
    minWidthError: "L'image que vous venez d'uploader ne respecte pas la largeur minimale requise.",
    defaultLangNotInLangs: 'La langue par défaut ne se trouve pas parmi les langues choisi',
    mustBeAnAdult: 'Vous devez avoir plus de 18 ans !',
    password_error:
      'Votre mot de passe doit comporter 12 caractères minimum, dont au moins 1 majuscule, 1 minuscule, 1 chiffre et 1 caractère spécial.',
  },
  reservations: {
    form_start_date: 'Date de début',
    form_end_date: 'Date de fin',
    waiting_list: 'position : {{ queue }}',
    accessories: 'Accessoires : ',
    modify_start_date: 'Modifier la date de début',
    modify_end_date: 'Modifier la date de fin',
    valid: 'Valider',
    renewal: 'Renouvellement',
    file_uploaded: 'Votre fichier a bien été uploadé !',
    statement_submit: 'Votre déclaration a bien été prise en compte !',
    comment_submit: 'Votre commentaire a bien été pris enregistré !',
    client_name: "<st>{{ lastName }}</st> {{ firstName }} - Détail d'une réservation",
    user_name: 'Usager : {{ lastName }} {{ firstName }}',
    done: 'Réservation effectuée le : {{ date }} à {{ hour }}',
    valid_date: 'Date de début effective : {{ date }} à {{ hour }}',
    number: 'numéro',
    resa_sum: 'Réservation : {{ article }} {{ number }} <st>{{ attribution }}</st> - Type : {{ type }}',
    free_park: 'placement libre',
    not_attributed: 'Non attribué',
    pdl: 'Point de location : {{ pdl }}',
    queue: 'Position : {{ queue }}',
    place: 'Emplacement : <st>{{ place }}</st>',
    start_date: 'Date de début : {{ date }} à {{ hour }}',
    end_date: 'Date de retour prévue : {{ date }} à {{ hour }}',
    date_return: 'Date de retour effective : {{ date }} à {{ hour }}',
    date_cancel: "Date d'annulation : {{ date }} à {{ hour }}",
    date_refund_deposit: 'Date de libération de la caution : {{ date }} à {{ hour }}',
    price: 'Montant de la réservation : {{ amount }} {{ currency }} TTC - Tarif : <st>{{ price }}</st>',
    security_deposit: 'Montant du dépôt de garantie : {{ securityDepositAmount }} {{ currency }} TTC',
    deposit: 'Montant de la caution : {{ depositAmount }} {{ currency }} TTC',
    iban_bic: 'IBAN / BIC',
    iban: 'IBAN',
    bic: 'BIC',
    issue: 'Un problème avec le vélo/la consigne de la réservation ? vous pouvez changer.',
    is_not_attributed: 'Vous êtes en attribution manuelle ? Changez vos accessoires.',
    sign_resa: 'Signer le contrat/mandat',
    other_bike: 'Vélo #{{ id }}',
    other_parks: 'Consigne #{{ id }}',
    change_bike: 'Changer de vélo',
    assign: 'Attribuer',
    assign_bike: 'Attribuer ce vélo',
    assign_park: 'Attribuer cette consigne',
    clear: 'Effacer',
    valid_sign: 'Valider la signature',
    client_name_sign: '<st>{{ name }}</st> {{ firstName }} - signature du contrat',
    confirm_resa: 'Valider la réservation',
    contract_generation: 'Le contrat de location va être généré ainsi que le mandat SEPA',
    choose_bike: 'Choisissez un vélo avant de valider la réservation :',
    cancel: 'Annuler la réservation',
    cancelation_text:
      "Plus aucune disponibilité ? Vous avez créé la réservation par erreur ? Impossible d'encaisser votre usager ?",
    validation_contract:
      "Cette réservation a été validée le : {{ dateValidation }} à {{ hourValidation }} à l'agence : {{ pdl }}",
    contract: 'Contrat de location',
    standard_exchange: 'Échange standard',
    std_exchange_stolen: 'Echange standard (vélo volé)',
    are_you_sure: 'Êtes vous sûr ?',
    resa_cancel_confirm: 'Vous allez annuler la réservation, êtes-vous sûr ?',
    resa_release_deposit_confirm: 'Vous allez libérer la caution, êtes-vous sûr ?',
    end_rent: 'Fin de location du vélo :',
    pdl_option: 'Disponible à la location (dans ce point de location)',
    repare_option: 'Non disponible à la location (révision nécessaire)',
    stolen_option: 'Non disponible à la location (vélo volé)',
    end_rent_button: 'Fin de location - Retour vélo',
    refund_deposit: 'Libération de la caution',
    refund_deposit_success: 'La caution a bien été libérée',
    button_yes: 'Oui, je suis sûr',
    button_no: "Non, j'abandonne",
    date: "Date de l'envoi PJ",
    type: 'Type',
    operation: 'Opération',
    send_attachments: 'Envoyer',
    title_attachments: "Pièces d'identité, IBAN, justificatifs de transport...",
    add_iban: 'Ajouter une pièce justificative :',
    checkout_type: 'Choisissez...',
    create_checkout: 'Créer encaissement',
    title_table: 'Liste des encaissements :',
    security_deposit_type: 'Dépôt de garantie',
    rental: 'Location',
    other: 'Autre',
    degradation_title: 'Encaissement Dégradation',
    delay_title: 'Encaissement Retard',
    regularization_title: 'Encaissement Régularisation',
    commentary: 'Commentaire',
    end_park: 'Fin de location consigne (état non pris en compte pour du placement libre) :',
    end_park_button: 'Fin de location consigne',
    available_end: 'Disponible à la location',
    unavailable_end: 'Non disponible à la location',
    cb_transac: 'Référence commande CB : {{ value }}',
    sepa_deposit_transac: 'Référence caution SEPA : {{ value }}',
    rum: 'Numéro RuM caution : {{ rum }}',
    sepa_transac: 'Référence commande SEPA : {{ value }}',
    cb_deposit_transac: 'Référence caution CB : {{ value }}',
    date_start: 'Date de début : ',
    date_end: 'Date de fin : ',
  },
  users: {
    accessories: {
      title: '- Accessoires',
      name: 'Nom',
      price: 'Prix TTC par durée',
      use: 'Utiliser',
      empty: 'Aucun accessoire disponible',
      dispo: 'Disponibilité',
      total: 'Prix total : {{ total }} {{ currency }} TTC',
    },
    has_security_deposit:
      '( Dépôt de garantie {{ securityDepositAmount }} {{ currency }} + Réservation {{ articleAmount }} {{ currency }} )',
    online_checkout: 'Payer en ligne',
    pj_check: 'Vérification de pièce justificative : <b>{{ name }}</b>',
    reservation_link: '<icon/> {{ count }} réservation',
    reservation_link_plural: '<icon/> {{ count }} réservations',
    checkout_ok: "le paiement s'est bien déroulé",
    do_online_pay: 'réaliser le paiement en ligne',
    cash_online: 'Encaissement en ligne',
    checkout_amount: '{{ amount }} {{ currency }}',
    confirm_user: 'Usager à valider',
    edit_file: 'Voir la fiche / Éditer',
    cashings: 'Encaissements en agence',
    date: "Date de l'encaissement",
    dateContract: 'Date du contrat',
    dateReservation: 'Date de la réservation',
    dateCheckout: "Date de l'encaissement",
    checkoutOperation: 'Opération',
    paymentMethod: 'Type de paiement',
    status: 'Statut',
    amount: 'Montant',
    amount_vat: '{{ amount }} {{ currency }} TTC',
    operation: 'Opération',
    actions: 'Actions',
    see_print: 'Voir / Imprimer',
    see_validate_back: 'Voir / Valider / Retour',
    display_first_el: "Affichage de l'élément {{ index }} à ",
    x_elements_displayed: '{{ perPage }} sur {{ count }} élément',
    x_elements_displayed_plural: '{{ perPage }} sur {{ count }} éléments',
    edit: {
      non_validated: 'Cet usager doit être validé',
    },
    user_validated: 'Le compte utilisateur est validé',
    new_resa: '{{ firstName }} - nouvelle réservation',
    bike_type: '- Type de vélo :',
    freepark_type: '- Type de consigne libre :',
    park_type: '- Type de consigne :',
    choose: 'Choisissez...',
    rental_duration: '- Durée de location :',
    choose_bike_before: "Choisissez un vélo d'abord...",
    choose_consigne_before: "Choisissez une consigne d'abord...",
    choose_duration_before: "Choisissez d'abord une durée de location...",
    rental_prices: '- Tarif de location :',
    duration_price: '(soit {{ price }} {{ currency }} TTC)',
    id_check: "- Vérification identité : <bold>Demander un justificatif d'identité à l'usager</bold>",
    deposit_information: 'Info caution : {{ depositAmount }} {{ currency }} TTC',
    summary: '- Récapitulatif',
    security_deposit_information: 'Dépôt de garantie : {{ securityDepositAmount }} {{ currency }} TTC',
    total_price_with_accessories: 'Prix total réservation, avec accessoires : {{ total }} {{ currency }} TTC',
    create_resa: 'Créer la réservation',
    rent_conditions:
      "En cliquant sur ce bouton, <bold>vous vous êtes assurés que des vélos soient disponibles aux dates demandées à l'aide de l'encadré vert ci-dessus</bold><lineBreak /><bold>Une facture va être générée</bold> : n'oubliez pas d'<bold>encaisser l'usager dans le moyen de réglement sélectionné <u>AVANT DE PASSER à l'étape suivante.</u></bold><lineBreak />Un vélo disponible sera automatiquement attribué selon les dates choisies<lineBreak />Votre usager aura accès à son contrat de location (le mandat SEPA est dans son contrat), ainsi que sa facture dans son espace usager !",
    rent_conditions_no_payment:
      "En cliquant sur ce bouton, <bold>vous vous êtes assurés que des vélos soient disponibles aux dates demandées à l'aide de l'encadré vert ci-dessus</bold><lineBreak />Un vélo disponible sera automatiquement attribué selon les dates choisies<lineBreak />",
    rent_park_conditions:
      "En cliquant sur ce bouton, <bold>vous vous êtes assurés que des consignes soient disponibles aux dates demandées à l'aide de l'encadré vert ci-dessus</bold><lineBreak />Les réservations de consignes s'entendent de 00:00:01 à 23:59:59<lineBreak />Il n'y a pas de place spécifique nominative réservée pour de la consigne libre.<lineBreak />",
    rent_park_conditions_no_payment:
      "En cliquant sur ce bouton, <bold>vous vous êtes assurés que des consignes soient disponibles aux dates demandées à l'aide de l'encadré vert ci-dessus</bold><lineBreak />Les réservations de consignes s'entendent de 00:00:01 à 23:59:59<lineBreak /><bold>Une facture va être générée</bold> : n'oubliez pas d'<bold>encaisser l'usager dans le moyen de réglement sélectionné <u>AVANT DE PASSER à l'étape suivante.</u></bold><lineBreak />Il n'y a pas de place spécifique nominative réservée pour de la consigne libre.<lineBreak />Votre usager aura accès à son contrat de location (le mandat SEPA est dans son contrat), ainsi que sa facture dans son espace usager !",
    user_info: '- Information usager locataire :',
    rent_duration: '- Durée de location :',
    max_duration: 'Durée minimum/maximum de location pour le tarif choisi :',
    selected_duration: 'Durée sélectionnée : ',
    availability: 'Disponibilité :',
    availability_details: '{{ item }} aux dates indiquées',
    days: 'jours',
    park_id_check: "- Vérification identité : <bold>Demander un justificatif d'identité à l'usager</bold>",
    park_public_transport: "- Info transport en commun : <bold>Demander un justificatif d'identité à l'usager</bold>",
    checkout: {
      collection_flag:
        'Pour tout encaissement en agence lié à une réservation (dégradation, retard, etc.) veuillez-vous rendre sur',
      collection_info:
        'Un encaissement génère automatiquement une facture. Votre usager pourra la retrouver dans son espace usager.',
      collection_reserv: "l'espace réservation",
      refund_flag:
        "Pour tout remboursement en agence lié à un paiement INTERNET, s'adresser au régisseur et lui fournir <bold>le nom de l'usager, le montant et l'heure du paiement concerné</bold>.<lineBreak/>",
      refund_info:
        'Un remboursement génère automatiquement une facture. Votre usager pourra la retrouver dans son espace usager.',
      /* collection_title: 'Encaissement manuel',
      refund_title: 'Remboursement manuel',
      collection_btn: 'Créer encaissement',
      refund_btn: 'Créer remboursement',
      table_title: 'Liste des encaissements/remboursements :',
      valid_form: 'Opération réussie',*/
    },
  },
  duration: {
    year: 'année',
    month: 'mois',
    day: 'jour',
    trimester: 'trimestre',
  },
  forms: {
    reason: "Sélectionnez le motif d'utilisation",
    service_trial: 'Essai du service',
    work: 'Travail',
    hobbies: 'Loisirs',
    others: 'Autres',
    status: 'Sélectionnez le statut',
    student: 'Etudiant boursier',
    back_to_work: "Jeune en parcours d'insertion",
    last_name: 'Nom',
    first_name: 'Prénom',
    firm_or_asso: 'Entreprise / association (facultatif)',
    email: 'Email',
    email_error: "Le format d'email est incorrect",
    birthdate: 'Date de naissance',
    iban: 'IBAN',
    bic: 'BIC',
    abotc: 'Num Abo TC',
    ask_rib: "Demander un RIB à l'usager",
    cheque: 'Chèque',
    credit_card: 'CB',
    cc_internet: 'CB Internet',
    payment_method: '- Moyen de règlement :',
    navigo_error: 'Le numéro passe TC ne doit comporter que des chiffres !',
    navigo: 'Numéro passe navigo (10 chiffres)',
    invalid_iban: 'Le format de votre IBAN est incorrect',
    invalid_bic: 'Le format de votre BIC est incorrect',
    mandatoryField: 'Ce champ est obligatoire',
    boursier: "Boursiers / Parcours d'insertion",
    use_reason: "Motif d'utilisation",
    dateStart: 'Date de début',
    dateEnd: 'Date de fin',
  },
  parameters: {
    all_bikes: 'Tous',
    bikes_types: 'Types de vélos',
    verification_text: 'Texte de vérification',
    commercial_response: "Réponse de l'agent commercial obligatoire",
    photo_used: 'Utiliser une photo',
    photo_mandatory: 'Photo obligatoire',
    select_delay: 'Aucun délai de réservation',
    save: 'Enregistrer',
    generate_pdf: 'Générer PDF',
    last_pdf: 'Dernier PDF généré',
    see_on_velocare: 'Voir sur Vélocare',
    sync_success: 'La synchronisation avec Vélocare est désormais effective !',
    cg_velo: 'Conditions générales VÉLO',
    cg_cons: 'Conditions générales CONSIGNE',
    cg_cons_park: 'Conditions générales CONSIGNES LIBRES',
    modification_success: 'Votre modification a été enregistrée',
    relance_email_velo: 'Location vélo',
    relance_email_cons_park: 'Location consigne libre',
    relance_email_cons: 'Location consigne',
    email_inquiry: 'Enquête email',
    contract_end: 'Fin de contrat',
    reminder_email: 'Relance email à - N jours',
    reminder_message: 'Relance SMS à - N jours',
    email_inquiry_plus: 'Enquête email à + N jours',
    coll_name: 'Nom de la collectivité',
    velocare_syncing: 'Synchronisation avec Vélocare',
    sync_with_velocare: 'Félicitations ! Véloclik est maintenant synchronisé avec Vélocare !',
    not_sync_with_velocare:
      "Véloclik n'est pas synchronisé avec Vélocare. Utilisez le bouton ci-dessous pour démarrer la synchronisation",
    sync_button_label: 'Synchroniser',
    see_in_velocare: 'Voir dans Vélocare',
    start_sync_button_label: 'Démarrer la synchronisation',
    url_paiement_page: 'URL page de paiement',
    emails_admin: 'Email des régisseurs séparés par un ; aaa@aaaa.fr;bbbb@ggggg.com ...',
    pdl_name: 'Nom du point de location',
    empl_name: "Nom de l'emplacement de consigne",
    // add_empl: 'Ajouter un emplacement de consigne',
    currency: 'Devise',
    waiting_list_time: 'Temps de validation',
    update: 'Mettre à jour',
    other_update: 'Modifier',
    pdl: 'Points de location',
    add_pdl: 'Ajouter un point de location',
    deposit_success: 'Votre caution a bien été mise à jour !',
    security_deposit_sucess: 'Votre dépôt de garantie a bien été mise à jour !',
    tva: 'TVA',
    low_tva: '5.5%',
    normal_tva: '10%',
    high_tva: '20%',
    delay_success: 'Votre retard a bien été mis à jour !',
    add_delay_success: 'Votre retard a bien été ajouté !',
    nom_caution: 'Nom de la caution',
    nom_type: 'Type de vélo',
    article_placeholder: "Type d'article - choisissez d'abord un article",
    tax_free: '{{ currency }} HT',
    with_tax: '{{ currency }} TTC',
    new_article_success: 'Votre article a bien été ajouté !',
    new_verification_success: 'Votre état des lieux a bien été modifié !',
    theme_update_success: 'Les modifications du thème ont bien été prises en compte',
    theme_media_update_success: 'Les modifications des images du thème ont bien été prises en compte',
    colors: 'Couleurs',
    agents: 'Agent',
    profile: 'Profils / droit',
    agent_update_success: 'Agent mis à jour !',
    agent_add_success: 'Agent ajouté !',
    create_agent: 'Créer',
    agent_add_title: 'Nouvel agent',
    agent_list_title: 'Gestion des agents',
    agent_update: 'MAJ',
    right_update: 'Modifier',
    right_list_title: 'Gestion des profils / droits',
    right_add_title: 'Créer',
    right_create_title: 'Créer un nouveau profil',
    right_update_success: 'Droit bien mis à jour !',
    right_add_success: 'Droit ajouté !',
    Admin: 'Admin',
    Agent: 'Agent',
    Technicien: 'Technicien',
    Lecteur: 'Lecteur',
    Régisseur: 'Régisseur',
    fallback_locale: 'Langue par défaut',
    available_locales: 'Langues disponibles',
    empl_consigne: 'Emplacements de consigne',
    payment_bo: 'Paiement BO accepté',
    time_validation: {
      day: '24h',
      two_days: '48h',
      three_days: '72h',
    },
    forms: {
      motif_utilisation: "Motif d'utilisation",
      boursier: "Boursiers / Parcours d'insertion",
      form_name: 'Nom du formulaire',
      form_action: 'Action',
      success: 'Vos changements sur ce formulaire ont bien été enregistrés',
      submit: 'Editer le formulaire',
      name: 'Nom de champ',
      displayed: 'Affichage',
      required: 'Requis',
      nature: 'Nature',
      civilite: 'Civilité',
      entr_asso: 'Entreprise/association',
      nom: 'Nom',
      prenom: 'Prénom',
      email: 'Email',
      password: 'Mot de passe',
      date_naissance: 'Date de naissance',
      pays_orig: "Pays d'origine",
      adresse: 'Adresse',
      adresse_compl: 'Adresse complémentaire',
      cp: 'Code postal',
      ville: 'Ville',
      pays: 'Pays',
      telephone: 'Téléphone',
      telephone_port: 'Téléphone portable',
      iban: 'IBAN',
      bic: 'BIC',
      abotc: 'Num Abo TC',
      status: 'Sélectionnez votre statut',
      reason: "Sélectionnez votre motif d'utilisation",
      student: 'Etudiant boursier',
      back_to_work: "Jeune en parcours d'insertion",
      num_adherent: "Numéro d'adhérent",
      date_exp_adherent: "Date d'expiration d'adhérent",
      motif_adhesion: "Motif d'adhésion",
      motif_adhesion_autre: "Motif d'adhésion autre",
      motif_options: {
        school_bike: 'Velo-ecole',
        workshop: 'Atelier',
        location: 'Location',
        other: 'Autres',
      },
    },
    // locale_to_update: 'Langue à modifier',
    assets: 'Images',
    minWidth: 'Requis: {{ width }}px largeur minimale (JPEG ou PNG)',
  },
  /*locales: {
    select: 'Sélectionner une langue',
    select_multiple: 'Sélectionner une ou plusieurs langues',
    fr: 'Français 🇫🇷',
    en: 'Anglais 🇬🇧',
  },*/
};
