import { t } from 'i18next';
import _ from 'lodash';
import { FormItemType } from 'users/types/forms';

const nums = _.range(1, 15);
const values = [15, 30, 60];

const delays = nums.map((num) => ({
  value: num.toString(),
  label: `${num.toString()} ${t('duration.day')}${num > 1 ? 's' : ''}`,
}));

const gaps = values.map((value) => ({
  value: value.toString(),
  label: `${value} minutes`,
}));

const pdlParamForm = (): FormItemType[][] => [
  [
    {
      type: 'text',
      placeholder: t('parameters.collectivity.name'),
      label: t('parameters.collectivity.name'),
      name: 'nom_pdl',
      id: 'nom_pdl',
      size: 'col-md-6',
    },
    {
      type: 'text',
      placeholder: t('parameters.collectivity.address'),
      label: t('parameters.collectivity.address'),
      name: 'addr_pdl',
      id: 'addr_pdl',
      size: 'col-md-6',
    },
  ],
  [
    {
      type: 'text',
      placeholder: t('parameters.collectivity.pc'),
      label: t('parameters.collectivity.pc'),
      name: 'cp_pdl',
      id: 'cp_pdl',
      size: 'col-md-6',
    },
    {
      type: 'text',
      placeholder: t('parameters.collectivity.city'),
      label: t('parameters.collectivity.city'),
      name: 'ville_pdl',
      id: 'ville_pdl',
      size: 'col-md-6',
    },
  ],
  [
    {
      type: 'select',
      placeholder: t('parameters.collectivity.delay'),
      label: t('parameters.collectivity.delay'),
      name: 'delai_reservation',
      id: 'delai_reservation',
      size: 'col-md-6',
      options: [{ label: t('parameters.select_delay'), value: '0' }, ...delays],
    },
    {
      type: 'number',
      placeholder: t('parameters.collectivity.limit'),
      label: t('parameters.collectivity.limit'),
      name: 'limit_resa',
      id: 'limit_resa',
      size: 'col-md-6',
    },
  ],
  [
    {
      type: 'select',
      placeholder: t('parameters.collectivity.gap'),
      label: t('parameters.collectivity.gap'),
      name: 'pas_reservation',
      id: 'pas_reservation',
      size: 'col-md-6',
      options: gaps,
    },
  ],
];

export default pdlParamForm;
