import { selectCollAndDeliveryPointID } from 'auth/selectors/authSelectors';
import { BIKE, EDIT, FREE_PARK, PARK, RESA_CREATION, USERS, VALIDATION } from 'internal/constants/routes';
import useApi, { Methods } from 'internal/hooks/useApi';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link,
  LinkProps,
  Outlet,
  Route,
  Routes,
  useLocation,
  useMatch,
  useNavigate,
  useResolvedPath,
} from 'react-router-dom';
import { storeUserDetails } from 'users/actions/usersActions';
import UsersResaFreepark from 'users/components/UserResaFreepark';
import UsersResaBike from 'users/components/UsersResaBike';
import UsersResaPark from 'users/components/UsersResaPark';
import { USER_DETAILS_ENDPOINT } from 'users/constants/endpoints';
import { selectUserDetails } from 'users/selectors/usersSelectors';
import { UserDetailsType } from 'users/types/users';

function CustomLink({ children, to, ...props }: LinkProps) {
  const { pathname } = useResolvedPath(to);
  const firstPart = `${USERS}${RESA_CREATION}`;
  const match = useMatch(`${firstPart}${pathname}`);

  return (
    <div>
      <Link
        to={`${firstPart}${to}`}
        className={`nav-link ${match && 'active'}`}
        data-toggle={'tab'}
        role={'tab'}
        aria-selected={'true'}
        {...props}>
        {children}
      </Link>
    </div>
  );
}

export const UsersReservationLayout = (): ReactElement => {
  const { t } = useTranslation(['old']);
  // @XXX: Get the URLSearch and coll id to make requests
  const [id_user, setUserID] = useState('');
  const { search } = useLocation();
  const navigate = useNavigate();

  // @XXX: Get the user and store it

  const { request, response } = useApi<UserDetailsType>(Methods.GET, USER_DETAILS_ENDPOINT);
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const dispatch = useDispatch();

  useEffect(() => {
    if (search) {
      const queryParams = new URLSearchParams(search);
      const userID = queryParams.get('userID') || '';

      if (!userID) {
        navigate(USERS);
      }

      setUserID(userID);
    } else {
      navigate(USERS);
    }
  }, [search]);

  useEffect(() => {
    if (id_coll && id_user) {
      request({
        params: {
          id_coll,
          id_user: id_user,
        },
      });
    }
  }, [request, id_coll, id_user]);

  useEffect(() => {
    if (response?.success && response.data) {
      dispatch(storeUserDetails(response.data));
    }
  }, [response]);

  // @XXX: Select user freshly stored
  const user = useSelector(selectUserDetails);

  const linkUser = useMemo(() => {
    if (user?.valid_bo) {
      return `${USERS}${EDIT}?userID=${user?.id_user}`;
    }

    return `${USERS}${VALIDATION}?userID=${user?.id_user}`;
  }, [user?.id_user]);

  return (
    <div className={'main-content'}>
      <div className={'card'}>
        <h4 className={'card-title'} id={'nomprenom'}>
          <Link to={linkUser}>
            <strong>{user?.nom}</strong> {t('users.new_resa', { firstName: user?.prenom })}
          </Link>
        </h4>
        <div className={'card-body'}>
          <div className={'row'}>
            <div className={'col-md-12'}>
              <ul className={'nav nav-tabs nav-tabs-danger nav-justified'} role={'tablist'}>
                <li className={'nav-item'}>
                  <CustomLink to={`${BIKE}?userID=${id_user}`}>{t('global.bike')}</CustomLink>
                </li>
                <li className={'nav-item'}>
                  <CustomLink to={`${PARK}?userID=${id_user}`}>{t('global.park')}</CustomLink>
                </li>
                <li className={'nav-item'}>
                  <CustomLink to={`${FREE_PARK}?userID=${id_user}`}>{t('global.free_park')}</CustomLink>
                </li>
              </ul>
              <div className={'tab-content'}>
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const UsersReservation = (): ReactElement => {
  return (
    <>
      {/*<Outlet />*/}
      <Routes>
        <Route element={<UsersReservationLayout />}>
          {/*<Route path={BIKE.replace('/', '')} element={<UsersResaBike />} />*/}
          <Route path={BIKE} element={<UsersResaBike />} />
          <Route path={FREE_PARK} element={<UsersResaFreepark />} />
          <Route path={PARK} element={<UsersResaPark />} />
        </Route>
      </Routes>
    </>
  );
};

export default UsersReservation;
