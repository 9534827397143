import { SERVICE_KEYS } from 'ioc/keys';
import { inject, injectable } from 'inversify';
import { AxiosResponse } from 'axios';
import { Api } from '../api/axios';

const ENDPOINTS = {
  getAllDeposits: 'param/depotsgarantie',
  getAllPriceTypes: 'param/tarifsloc',
  putDeposit: 'param/depotsgarantie',
  postDeposit: 'param/depotsgarantie',
  deleteDeposit: 'param/depotsgarantie',
  // PDL
  getCollectivity: 'param/coll',
  getPDL: 'param/pdl',
  putPDL: 'param/pdlupd',
  getPDLWeekSchedule: 'pdl/calendarrules',
};

export type GetAllDepositsQueryParams = {
  id_coll: number;
};

export type GetAllDepositsResult = {
  [key: string]: {
    depotId: number;
    depotName: string;
    articles: number[];
  }[];
};

export type GetAllPriceTypesResult = {
  article: string;
  id_article: string;
  max_duree: number;
  min_duree: number;
  montant_ttc: number;
  nom_duree: string;
  nom_tarif: string;
  nom_type: string;
  type_duree: string;
}[];

export type PostDepositBodyParams = {
  id_coll: number;
  depot: {
    article: string; //uniquement velo mais servira pour le futur qui sait...
    montant_ht: number;
    taux_tva: number;
    depotName: string;
  };
};

export type PutDepositBodyParams = PostDepositBodyParams & {
  depotId: number;
  articles: number[];
};

export type DeleteDepositQueryParams = {
  depotId: number;
  id_coll: number;
};

export type PDLDetailsRaw = {
  adresse: string;
  cp: string;
  creneau_unique: number;
  delai_reservation: number;
  id_pdl: number;
  is_visible: number;
  limit_resa: number;
  nom: string;
  pas_reservation: string;
  return_slot_interval: string;
  max_delay_reservation: number;
  return_delay: number;
  ville: string;
};

type PutPDLBodyParams = {
  nom_pdl: string;
  addr_pdl: string;
  cp_pdl: string;
  ville_pdl: string;
  delai_reservation: string;
  limit_resa: string;
  pas_reservation: string;
  visible_pdl: boolean;
  creneau_unique: boolean;
  id_coll: number;
  id_pdl: number;
};

export type PDLWeekScheduleRaw = {
  days: {
    day: string; // string date format YYYY-MM-DD
    slots: Date[];
  }[];
  hourStart: string; // format HH:MM
  hourEnd: string;
  slotStep: number;
};

@injectable()
export class ParameterRepository {
  @inject(SERVICE_KEYS.API_INSTANCE)
  private _apiInstance: Api | undefined;

  getAllDeposits(queryParams: GetAllDepositsQueryParams): Promise<AxiosResponse<GetAllDepositsResult>> | undefined {
    return this._apiInstance?.instance.get(ENDPOINTS.getAllDeposits, { params: queryParams });
  }

  getAllPriceTypes(queryParams: GetAllDepositsQueryParams): Promise<AxiosResponse<GetAllPriceTypesResult>> | undefined {
    return this._apiInstance?.instance.get(ENDPOINTS.getAllPriceTypes, { params: queryParams });
  }

  postDeposit(data: PostDepositBodyParams): Promise<AxiosResponse<GetAllPriceTypesResult>> | undefined {
    return this._apiInstance?.instance.post(ENDPOINTS.postDeposit, data);
  }

  putDeposit(data: PutDepositBodyParams): Promise<AxiosResponse<GetAllPriceTypesResult>> | undefined {
    return this._apiInstance?.instance.post(ENDPOINTS.putDeposit, data);
  }

  deleteDeposit(queryParams: DeleteDepositQueryParams): Promise<AxiosResponse<never>> | undefined {
    return this._apiInstance?.instance.delete(ENDPOINTS.deleteDeposit, { params: queryParams });
  }

  /* ------- PDL ------- */

  getCollectivity(queryParams: { id_coll: number }): Promise<AxiosResponse<never>> | undefined {
    return this._apiInstance?.instance.get(ENDPOINTS.getCollectivity, { params: queryParams });
  }

  getPDL(queryParams: { id_coll: number; id_pdl: number }): Promise<AxiosResponse<PDLDetailsRaw>> | undefined {
    return this._apiInstance?.instance.get(ENDPOINTS.getPDL, { params: queryParams });
  }

  updatePDL(data: PutPDLBodyParams): Promise<AxiosResponse<PDLDetailsRaw>> | undefined {
    return this._apiInstance?.instance.post(ENDPOINTS.putPDL, data);
  }

  getPDLWeekSchedule(queryParams: {
    dayStart: Date;
    collId: number;
    pdlId: number;
  }): Promise<AxiosResponse<PDLWeekScheduleRaw>> | undefined {
    return this._apiInstance?.instance.get(ENDPOINTS.getPDLWeekSchedule, { params: queryParams });
  }
}
