import { useInstanceApi } from '../../../api/axios';
import { GetLocaleResponse } from '../../../internal/types/locales';
import { useRequest } from '../../../utils/request';

const URL = {
  fetchLocalesParameters: 'param/languages',
  updateLocalesParameters: 'param/languages',
};

function useLocalesParametersApi() {
  const instance = useInstanceApi();

  const { request: fetchLocalesParameters } = useRequest<GetLocaleResponse>((queryParams) =>
    instance.get(URL.fetchLocalesParameters, { params: queryParams })
  );

  const { request: putLocalesParameters } = useRequest((data) => instance.post(URL.updateLocalesParameters, data));

  return {
    fetchLocalesParameters,
    putLocalesParameters,
  };
}

export default useLocalesParametersApi;
