import React from 'react';
import useIsMobile from '../../../internal/hooks/useIsMobile';
import AgentsAddList from '../../../parameters/components/AgentsAddList';

function AgentRightsPage() {
  const isMobile = useIsMobile();

  return (
    <div className={'main-content'}>
      <div className={'card'}>
        <div className={'card-body'}>
          <div className={!isMobile ? 'row' : ''}>
            <div className={!isMobile ? 'col-md-12' : ''}>
              <AgentsAddList />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AgentRightsPage;
