import styled from '@emotion/styled';
import { TabPanelUnstyled, TabsListUnstyled, TabsUnstyled, TabUnstyled, TabUnstyledProps } from '@mui/base';
import React, { ElementType, PropsWithChildren } from 'react';
import { getColor } from '../../../utils/style';
import styles from './tabs.module.scss';

export const Tabs = styled(TabsUnstyled)`
  //border-bottom: 1px solid ${getColor('gray.200')};
`;

export const Tab = React.forwardRef<HTMLButtonElement, TabUnstyledProps<PropsWithChildren<ElementType>>>(
  ({ children, className, ...propsToForward }, ref) => {
    return (
      <TabUnstyled ref={ref} className={styles.Tab} {...propsToForward}>
        <div className={styles.Tab__content}>{children}</div>
      </TabUnstyled>
    );
  }
);

export const TabPanel = TabPanelUnstyled;

export const TabsList = styled(TabsListUnstyled)`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.color.gray['200']};
  // background-color: ${(props) => props.theme.color.gray['100']};
`;
