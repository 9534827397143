/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import axios, { AxiosResponse } from 'axios';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useOutletContext, useParams } from 'react-router-dom';
import _ from 'lodash';
import { selectCollAndDeliveryPointID } from '../../../auth/selectors/authSelectors';
import ButtonWithIcon from '../../../components/Core/Button/ButtonWithIcon';
import Card from '../../../components/Core/Card/Card';
import Grid from '../../../components/Core/Grid/Grid';
import MainSection from '../../../components/Core/MainSection/MainSection';
import { TOAST_SEVERITY } from '../../../components/Core/Toast/Toast';
import { useSnackbar } from '../../../context/SnackbarContext';
import useBuildForm from '../../../hook/useBuildForm';
import { useInjection } from '../../../ioc/ioc.react';
import { SERVICE_KEYS } from '../../../ioc/keys';
import { UserRepository } from '../../../repository/user.repository';
import { withTheme } from '../../Parameters/Deposit/List/DepositList';
import { getUserCreateFormFields } from '../Create/createForm';
import useReasonJoining from '../Create/useReasonJoining';
import { getDefaultValues, useUpdateUser } from '../utils';
import StateCard from './StateCard';

function Update() {
  //eslint-disable-next-line
  const { userData } = useOutletContext<{ userData: AxiosResponse<any> }>();
  const { userId } = useParams();
  const snackbar = useSnackbar();
  const { t } = useTranslation(['user/create', 'form', 'errors']);
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const queryClient = useQueryClient();

  const userRepository = useInjection<UserRepository>(SERVICE_KEYS.USER_REPOSITORY);
  const defaultValues = useMemo(() => {
    //eslint-disable-next-line
    return getDefaultValues(userData?.data as any);
  }, [userData]);
  const { handleSuccess, labelCTAButton } = useUpdateUser({
    //eslint-disable-next-line
    user: userData?.data as any,
  });

  const { builtFormFields, handleSubmit, watch } = useBuildForm({
    formObject: getUserCreateFormFields(t, _.isString(userData.data.date_anonymization)),
    colsNumber: 3,
    defaultValues,
  });
  const builtFormGroup = useReasonJoining({ builtFormGroup: builtFormFields[0], watch });

  const onSubmit = useCallback(
    handleSubmit(async (data) => {
      // eslint-disable-next-line prefer-const
      let { motif_adhesion, motif_adhesion_autre, ...otherData } = data;
      if (motif_adhesion !== 'autres') motif_adhesion_autre = '';
      try {
        const response = await userRepository.update({
          ...otherData,
          id_coll,
          motif_adhesion_autre,
          motif_adhesion,
          id_user: userId,
        });

        if (response?.status === 200) {
          handleSuccess();
          if (userId) queryClient.invalidateQueries(['user.fetch.'.concat(userId)]);
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const message = error.response?.data.code;
          snackbar?.setAlert({ message: t(`errors:${message.replace('error.', '')}`), severity: TOAST_SEVERITY.error });
        }
      }
    }),
    [userId, id_coll, handleSuccess, handleSubmit, t, snackbar, queryClient]
  );

  return (
    <MainSection className={'reset'}>
      <Grid align={'flex-start'} columns={'auto max-content'}>
        <Card
          content={
            <>
              <form
                css={css`
                  display: grid;
                  gap: 16px;
                `}
                onSubmit={onSubmit}>
                {builtFormGroup}
                {builtFormFields[1]}
                {/* todo: bouger tout le bouton dans le hook et mettre secondary quand edition*/}
                <div style={{ justifySelf: 'flex-end' }}>
                  <ButtonWithIcon
                    disabled={_.isString(userData.data.date_anonymization)}
                    icon={'EditIcon'}
                    type={'submit'}>
                    {labelCTAButton}
                  </ButtonWithIcon>
                </div>
              </form>
            </>
          }
        />
        <StateCard user={userData?.data} />
      </Grid>
    </MainSection>
  );
}

export default withTheme(Update);
