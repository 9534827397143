import { instance } from '../user.api';

const URL = {
  getVouchers: 'param/pj',
  postVoucher: 'param/pj',
  deleteVoucher: 'param/pj',
  putVoucher: 'param/pj',
};

export type RawGenericListReturn = {
  currentPage: number;
  itemPerPage: number;
  // eslint-disable-next-line
  filters: any;
  // eslint-disable-next-line
  result: any[];
  total: number;
  totalPage: number;
};

export async function getVouchers(queryParams: { id_coll: number }) {
  return await instance.get<RawGenericListReturn>(URL.getVouchers, {
    params: queryParams,
  });
}

export type PostVoucherBody = {
  id_coll: number;
  pjId?: number;
  pj: {
    pjName: string;
    isRequired: boolean;
    category: string;
  };
  articles: number[];
};

export async function postVoucher(data: PostVoucherBody) {
  return await instance.post(URL.postVoucher, data);
}

export type DeleteVoucherQueryParams = { id_coll: number; pjId: number };

export async function deleteVoucher(queryParams: DeleteVoucherQueryParams) {
  return await instance.delete(URL.deleteVoucher, { params: queryParams });
}
