import styled from '@emotion/styled/macro';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectCollAndDeliveryPointID } from '../../../auth/selectors/authSelectors';
import ButtonWithIcon from '../../../components/Core/Button/ButtonWithIcon';
import { TOAST_SEVERITY } from '../../../components/Core/Toast/Toast';
import FormGroupCard from '../../../components/FormGroupCard/FormGroupCard';
import { useSnackbar } from '../../../context/SnackbarContext';
import useBuildForm from '../../../hook/useBuildForm';
import { ParametersRenewalType } from '../../../parameters/types/collectivity';
import { withTheme } from '../Deposit/List/DepositList';
import updateRenewalParametersForm from './updateRenewalParametersForm';
import useRenewalParametersApi from './useRenewalParametersApi';

function RenewalParametersPage() {
  const { t } = useTranslation(['parameters/renewalParameters', 'form']);
  const snackbar = useSnackbar();
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const { putRenewalParameters, fetchRenewalParameters } = useRenewalParametersApi();
  const [renewalParameters, setRenewalParameters] = useState<ParametersRenewalType | undefined>(undefined);
  const { builtFormFields, handleSubmit } = useBuildForm({
    formObject: updateRenewalParametersForm(t),
    defaultValues: renewalParameters,
  });

  const onMount = useCallback(async () => {
    const response = await fetchRenewalParameters({ id_coll });

    if (response && response.status === 200) {
      setRenewalParameters(response?.data);
    }
  }, [id_coll]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const onSubmit = useCallback(
    handleSubmit(async (data) => {
      const response = await putRenewalParameters({ ...data, id_coll });

      if (response?.status === 200) {
        snackbar?.setAlert({ message: t('form:global_update_success_message'), severity: TOAST_SEVERITY.success });
      }
    }),
    [id_coll]
  );

  return (
    <MainSection>
      <FormGroupCard
        title={null}
        WrapperComponent={({ children }) => <form onSubmit={onSubmit}>{children}</form>}
        FooterSlotComponent={({ defaultWrapper: DefaultWrapper }) => (
          <DefaultWrapper>
            <ButtonWithIcon
              icon={'SaveIcon'}
              color={'secondary'}
              type={'submit'}
              label={t('form:global_update_label')}
            />
          </DefaultWrapper>
        )}>
        {builtFormFields}
      </FormGroupCard>
    </MainSection>
  );
}

const MainSection = styled.section`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export default withTheme(RenewalParametersPage);
