import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import DataHub from 'fragments/dataHub/DataHub';

import Status from 'internal/constants/status';
import useApi, { Methods } from 'internal/hooks/useApi';

import { USER_DETAILS_ENDPOINT, USER_INVOICES_ENDPOINT } from 'users/constants/endpoints';
import { RawUserInvoiceType, UserDetailsType, UserInvoiceTableType } from 'users/types/users';
import { formatUsersInvoices } from 'users/services/userServices';

import { selectSessionToken } from 'auth/selectors/authSelectors';
import { selectCollAndDeliveryPointID } from 'auth/selectors/authSelectors';
import { USERS, EDIT, VALIDATION } from 'internal/constants/routes';
import { Link } from 'react-router-dom';

const UsersInvoices = (): ReactElement => {
  const [id_user, setUserID] = useState('');
  const { search } = useLocation();

  // @XXX: Get the URLSearch and coll id to make requests

  useEffect(() => {
    if (search) {
      const queryParams = new URLSearchParams(search);
      const userID = queryParams.get('userID') || '';

      setUserID(userID);
    }
  }, [search]);

  const token = useSelector(selectSessionToken);

  const { id_coll } = useSelector(selectCollAndDeliveryPointID);

  // @XXX: Request the user details and the invoices, and save in local state

  const { request, response, status } = useApi<RawUserInvoiceType[]>(Methods.GET, USER_INVOICES_ENDPOINT);
  const { request: userDetailRequest, response: userDetailResponse } = useApi<UserDetailsType>(
    Methods.GET,
    USER_DETAILS_ENDPOINT
  );

  useEffect(() => {
    if (id_coll && id_user) {
      const params = {
        params: {
          id_coll,
          id_user,
        },
      };

      request(params);
      userDetailRequest(params);
    }
  }, [request, id_coll, id_user]);

  const [usersInvoices, saveUserInvoices] = useState<UserInvoiceTableType[]>([]);
  const [user, saveUser] = useState<UserDetailsType | null>(null);

  useEffect(() => {
    if (response?.success && response.data && token) {
      saveUserInvoices(formatUsersInvoices(response.data, token));
    }
  }, [response]);

  useEffect(() => {
    if (userDetailResponse?.success && userDetailResponse.data) {
      saveUser(userDetailResponse.data);
    }
  }, [userDetailResponse]);

  // @XXX: Prepare the data hub state management (eg. pagination, table, search, ...)

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [filterSearch, setSearch] = useState('');

  const handleFilterDate = useCallback(
    (type: string) => {
      if (type === 'asc') {
        saveUserInvoices((prevState) => [
          ...prevState.sort(({ date: a }, { date: b }) => (dayjs(a).isBefore(dayjs(b)) ? 1 : -1)),
        ]);
      } else {
        saveUserInvoices((prevState) => [
          ...prevState.sort(({ date: a }, { date: b }) => (dayjs(a).isAfter(dayjs(b)) ? 1 : -1)),
        ]);
      }
    },
    [usersInvoices, saveUserInvoices]
  );

  const handleFilterAmount = useCallback(
    (type: string) => {
      if (type === 'asc') {
        saveUserInvoices((prevState) => [...prevState.sort(({ amount: a }, { amount: b }) => b - a)]);
      } else {
        saveUserInvoices((prevState) => [...prevState.sort(({ amount: a }, { amount: b }) => a - b)]);
      }
    },
    [usersInvoices, saveUserInvoices]
  );

  const pageIndex = useMemo(() => (page === 1 && 0) || perPage * (page - 1), [perPage, page]);

  const dataTableProps = useMemo(
    () => ({
      cols: ['date', 'amount', 'operation', 'actions'],
      colTraductionScope: 'users',
      colHandlers: [handleFilterDate, handleFilterAmount],
      items: [...usersInvoices].splice(pageIndex, perPage).filter((invoice) => {
        const regExp = new RegExp(
          filterSearch
            .toLowerCase()
            .split(' ')
            .map((word) => `(${word})`)
            .join('.*')
        );
        return `${invoice.date} ${invoice.amount} ${invoice.devise} ttc ${invoice.operation}`.match(regExp);
      }),
    }),
    [usersInvoices, handleFilterDate, handleFilterAmount, pageIndex, filterSearch, perPage]
  );

  const linkUser = useMemo(() => {
    if (user?.valid_bo) {
      return `${USERS}${EDIT}?userID=${user?.id_user}`;
    }

    return `${USERS}${VALIDATION}?userID=${user?.id_user}`;
  }, [user?.id_user]);

  return (
    <div className={'main-content'}>
      <div className={'card'}>
        <h4 className={'card-title'} id={'nomprenom'}>
          <Link to={linkUser}>
            <strong>{user?.nom}</strong> {user?.prenom}
          </Link>
        </h4>
        <div className={'card-body'}>
          <DataHub
            isSearchActive={true}
            setSearch={setSearch}
            setPerPage={setPerPage}
            dataTableProps={dataTableProps}
            maxItems={usersInvoices.length}
            perPage={perPage}
            page={page}
            setPage={setPage}
            isLoading={status === Status.LOADING}
          />
        </div>
      </div>
    </div>
  );
};

export default UsersInvoices;
