import styled from '@emotion/styled/macro';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectCollAndDeliveryPointID } from '../../../auth/selectors/authSelectors';
import Button from '../../../components/Core/Button/Button';
import { TOAST_SEVERITY } from '../../../components/Core/Toast/Toast';
import FormGroupCard from '../../../components/FormGroupCard/FormGroupCard';
import { useSnackbar } from '../../../context/SnackbarContext';
import useBuildForm from '../../../hook/useBuildForm';
import { withTheme } from '../../Parameters/Deposit/List/DepositList';
import updateWorkshopParametersForm from './updateWorkshopParametersForm';
import useWorkshopParametersApi from './useWorkshopParametersApi';

function WorkshopParametersPage() {
  const { t } = useTranslation(['admin/workshopParameters', 'form']);
  const snackbar = useSnackbar();
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const { fetchWorkshopParameters, putWorkshopParameters } = useWorkshopParametersApi();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [defaultWorkshopParameters, setDefaultWorkshopParameters] = useState<Record<string, any> | null>(null);
  const { builtFormFields, handleSubmit } = useBuildForm({
    formObject: updateWorkshopParametersForm(t),
    defaultValues: defaultWorkshopParameters || {},
  });

  const onMount = useCallback(async () => {
    const response = await fetchWorkshopParameters({ id_coll });
    if (response && response.status === 200) setDefaultWorkshopParameters(response.data);
  }, [id_coll]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const onSubmit = useCallback(
    handleSubmit(async (data) => {
      const response = await putWorkshopParameters({ id_coll, ...data });

      if (response && response.status === 200) {
        snackbar?.setAlert({ message: t('success'), severity: TOAST_SEVERITY.success });
      }
    }),
    [id_coll]
  );

  return (
    <MainSection>
      <FormGroupCard
        title={null}
        WrapperComponent={({ children }) => <form onSubmit={onSubmit}>{children}</form>}
        FooterSlotComponent={({ defaultWrapper: DefaultWrapper }) => (
          <DefaultWrapper>
            <Button type={'submit'} label={t('form:global_update_label')} />
          </DefaultWrapper>
        )}>
        {builtFormFields}
      </FormGroupCard>
    </MainSection>
  );
}

const MainSection = styled.section`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export default withTheme(WorkshopParametersPage);
