import React from 'react';
import MainSection from '../../../components/Core/MainSection/MainSection';
import MoneticoFormCard from './MoneticoFormCard';
import StripeFormCard from './StripeFormCard';
import SogecommerceFormCard from './SogecommerceFormCard';
import PayboxFormCard from './PayboxFormCard';

function PaymentProvidersPage() {
  return (
    <MainSection className={'reset'}>
      <StripeFormCard />
      <SogecommerceFormCard />
      <PayboxFormCard />
      <MoneticoFormCard />
    </MainSection>
  );
}

export default PaymentProvidersPage;
