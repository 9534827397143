/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';
import { Children, HtmlHTMLAttributes, PropsWithChildren, useMemo } from 'react';

type GridProps = HtmlHTMLAttributes<HTMLDivElement> & {
  columns?: string | null;
  gap?: number;
  justify?: string;
  align?: string;
};

const gridCss = {
  self: css({
    display: 'grid',
  }),
};

function Grid(props: PropsWithChildren<GridProps>) {
  const { children, columns, justify, align, gap = 16, ...propsToForward } = props;

  const gridTemplateColumns = useMemo(() => {
    return columns ? columns : columns === null ? 'none' : `repeat(${Children.count(children)}, 1fr)`;
  }, [columns, children]);

  return (
    <div
      css={gridCss.self}
      style={{
        justifyContent: justify,
        alignItems: align,
        gridTemplateColumns,
        gap,
      }}
      {...propsToForward}>
      {children}
    </div>
  );
}

export default Grid;
