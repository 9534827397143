import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Tooltip } from '@mui/material';
import { selectCollAndDeliveryPointID } from 'auth/selectors/authSelectors';
import dayjs from 'dayjs';
import Button from 'fragments/button/Button';
import DatePicker from 'fragments/datepicker/DatePicker';
import useApi, { Methods } from 'internal/hooks/useApi';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import style from 'reservations/components/ReservationSummary.module.css';
import { ReservationType } from 'reservations/types/reservation';

type Props = {
  resaID: string;
  reservation: ReservationType;
  isFree: boolean;
  isRenewal: boolean;
  isPark: boolean;
  refresh: () => void;
};

const ReservationLines = ({ resaID, reservation, isFree, isRenewal, isPark, refresh }: Props): ReactElement => {
  const { t } = useTranslation('old');
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [dateVisible, setDateVisible] = useState<boolean>(false);
  const [dateEndVisible, setDateEndVisible] = useState<boolean>(false);
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);

  const { request: submitDateStart, response: responseDateStart } = useApi(Methods.POST, 'user/changeresastart');
  const { request: submitDateEnd, response: responseDateEnd } = useApi(Methods.POST, 'user/changeresaend');

  useEffect(() => {
    setStartDate(dayjs(reservation.date_start).format('YYYY-MM-DD HH:mm'));
  }, [reservation.date_start]);

  useEffect(() => {
    setEndDate(dayjs(reservation.date_end).format('YYYY-MM-DD HH:mm'));
  }, [reservation.date_end]);

  useEffect(() => {
    if (responseDateStart?.success) {
      refresh();
      setDateVisible(false);
    }
  }, [responseDateStart]);

  useEffect(() => {
    if (responseDateEnd?.success) {
      refresh();
      setDateEndVisible(false);
    }
  }, [responseDateEnd]);

  const [number, attribution] = useMemo(() => {
    let number = '-';
    let attribution;

    if (reservation.id_interne) {
      number = t('reservations.number');
      attribution = reservation.id_interne;
    }

    //It's a free park
    if (isPark && !reservation.id_interne && reservation.id_consigne === null) {
      attribution = t('reservations.free_park');
    } else if (!reservation.id_consigne && !reservation.id_velo) {
      // It's a reservation without attributed bike or park
      attribution = t('reservations.not_attributed');
    }

    return [number, attribution];
  }, [reservation.id_interne, isPark, t]);

  const {
    article,
    nom_type: type,
    nom_empl: place,
    date_reservation,
    date_start,
    date_end,
    date_return,
    date_cancel,
    nom_tarif: price,
    montant_article_ttc,
    montant_depot_garantie_ttc,
    montant_caution_ttc,
    sepa_booking_transac_id,
    cb_caution_transac_id,
    cb_booking_transac_id,
    sepa_caution_transac_id,
    total_ttc,
    rum,
    checkout_link,
    nom_empl,
    nom_pdl,
    nom: lastNameUser,
    prenom: firstNameUser,
    accessoires,
    queue_position,
    date_rbt_caution,
    date_valid,
  } = reservation;

  const amount = useMemo(() => {
    if (montant_depot_garantie_ttc && parseFloat(montant_depot_garantie_ttc) > 0) {
      return montant_article_ttc;
    }

    return total_ttc;
  }, []);

  const handleChangeStartDate = useCallback(
    (date: string) => {
      if (date && date !== 'Invalid Date') {
        setStartDate(date);
      }
    },
    [setStartDate]
  );

  const handleChangeEndDate = useCallback(
    (date: string) => {
      if (date && date !== 'Invalid Date') {
        setEndDate(date);
      }
    },
    [setStartDate]
  );

  const handleDateVisibility = useCallback(() => {
    setDateVisible((prevState) => !prevState);
  }, [dateVisible, setDateVisible]);

  const handleDateEndVisibility = useCallback(() => {
    setDateEndVisible((prevState) => !prevState);
  }, [dateEndVisible, setDateEndVisible]);

  const handleStartDateValidation = useCallback(() => {
    if (startDate) {
      submitDateStart({
        id_coll,
        id_resa: resaID,
        date_debut: startDate,
      });
    }
  }, [id_coll, resaID, startDate]);

  const handleEndDateValidation = useCallback(() => {
    if (endDate) {
      submitDateEnd({
        id_coll,
        id_resa: resaID,
        date_fin: endDate,
      });
    }
  }, [id_coll, resaID, endDate]);

  const renderChangeDateButton = useMemo(() => {
    if (reservation.is_canceled !== 1 && reservation.is_returned !== 1 && !reservation?.date_valid && !dateVisible) {
      return (
        <Tooltip title={t('reservations.modify_start_date') as string} arrow>
          <i className={`${style.icon__start__date} icon ti-pencil link`} onClick={handleDateVisibility} />
        </Tooltip>
      );
    }

    if (dateVisible) {
      return (
        <Button
          label={t('reservations.valid')}
          onClick={handleStartDateValidation}
          className={'btn btn-bold btn-block btn-primary'}
        />
      );
    }
  }, [
    reservation.is_canceled,
    reservation.is_returned,
    reservation?.date_valid,
    handleDateVisibility,
    handleStartDateValidation,
    dateVisible,
    t,
  ]);

  const renderChangeEndDateButton = useMemo(() => {
    if (reservation.is_canceled !== 1 && reservation.is_returned !== 1 && !reservation?.date_valid && !dateEndVisible) {
      return (
        <Tooltip title={t('reservations.modify_end_date') as string} arrow>
          <i className={`${style.icon__start__date} icon ti-pencil link`} onClick={handleDateEndVisibility} />
        </Tooltip>
      );
    }

    if (dateEndVisible) {
      return (
        <Button
          label={t('reservations.valid')}
          onClick={handleEndDateValidation}
          className={'btn btn-bold btn-block btn-primary'}
        />
      );
    }
  }, [
    reservation.is_canceled,
    reservation.is_returned,
    reservation?.date_valid,
    dateEndVisible,
    handleDateEndVisibility,
    handleEndDateValidation,
    t,
  ]);

  const renderDate = useMemo(() => {
    if (reservation.is_canceled !== 1 && reservation.is_returned !== 1 && dateVisible && startDate) {
      return (
        <>
          <DatePicker
            placeholder={t('reservations.form_start_date')}
            className={'datetimepicker-input'}
            name={'date_debut'}
            id={'date_debut'}
            type={'datetimepicker-input'}
            defaultValue={startDate}
            parentValue={startDate}
            handleChange={handleChangeStartDate}
            showTimeInput
          />
          {renderChangeDateButton}
        </>
      );
    }

    if (date_start) {
      return (
        <div>
          {t('reservations.start_date', {
            date: dayjs(date_start).format('DD/MM/YYYY'),
            hour: dayjs(date_start).format('HH:mm'),
          })}
          {renderChangeDateButton}
        </div>
      );
    }

    return null;
  }, [
    reservation.is_canceled,
    reservation.is_returned,
    startDate,
    handleChangeStartDate,
    renderChangeDateButton,
    dateVisible,
    date_start,
    t,
  ]);

  const renderEndDate = useMemo(() => {
    if (reservation.is_canceled !== 1 && reservation.is_returned !== 1 && dateEndVisible && endDate) {
      return (
        <>
          <DatePicker
            placeholder={t('reservations.form_end_date')}
            className={'datetimepicker-input'}
            name={'date_end'}
            id={'date_end'}
            type={'datetimepicker-input'}
            defaultValue={endDate}
            parentValue={endDate}
            handleChange={handleChangeEndDate}
            showTimeInput
          />
          {renderChangeEndDateButton}
        </>
      );
    }

    if (date_end) {
      return (
        <div>
          {t('reservations.end_date', {
            date: dayjs(date_end).format('DD/MM/YYYY'),
            hour: dayjs(date_end).format('HH:mm'),
          })}
          {renderChangeEndDateButton}
        </div>
      );
    }

    return null;
  }, [
    reservation.is_canceled,
    reservation.is_returned,
    endDate,
    handleChangeEndDate,
    renderChangeEndDateButton,
    dateEndVisible,
    date_end,
    t,
  ]);

  const renderListAccessories = useMemo(() => {
    if (accessoires?.length > 0) {
      return accessoires.map(({ id_interne, nom_type }, i) => {
        const isLast = i === accessoires.length - 1;
        const hyphen = isLast ? '' : ', ';

        return (
          <span>
            {' '}
            {nom_type} {id_interne}
            {hyphen}
          </span>
        );
      });
    }
  }, [accessoires]);

  return (
    <>
      {isRenewal && (
        <>
          <Trans t={t} i18nKey={'reservations.renewal'} components={{ st: <strong /> }} />
          <br />
        </>
      )}
      {(nom_pdl || nom_empl) && (
        <>
          <Trans t={t} i18nKey={'reservations.pdl'} values={{ pdl: nom_pdl !== '' ? nom_pdl : nom_empl }} />
          <br />
        </>
      )}
      {!!queue_position && (
        <>
          <Trans t={t} i18nKey={'reservations.queue'} values={{ queue: queue_position }} />
          <br />
        </>
      )}
      {firstNameUser && lastNameUser && (
        <>
          {t('reservations.user_name', {
            firstName: firstNameUser,
            lastName: lastNameUser,
          })}
          <br />
        </>
      )}
      <Trans
        t={t}
        i18nKey={'reservations.resa_sum'}
        values={{
          article,
          type,
          attribution,
          number,
        }}
        components={{ st: <strong /> }}
      />
      <br />
      {t('reservations.done', {
        date: dayjs(date_reservation).format('DD/MM/YYYY'),
        hour: dayjs(date_reservation).format('HH:mm'),
      })}
      <br />
      {date_valid && (
        <>
          {t('reservations.valid_date', {
            date: dayjs(date_valid).format('DD/MM/YYYY'),
            hour: dayjs(date_valid).format('HH:mm'),
          })}
          <br />
        </>
      )}
      {place && (
        <>
          <Trans t={t} i18nKey={'reservations.place'} values={{ place }} components={{ st: <strong /> }} />
          <br />
        </>
      )}
      {accessoires && (
        <>
          {t('reservations.accessories')}
          {renderListAccessories}
        </>
      )}
      {renderDate}
      {renderEndDate}

      {date_return && (
        <>
          {t('reservations.date_return', {
            date: dayjs(date_return).format('DD/MM/YYYY'),
            hour: dayjs(date_return).format('HH:mm'),
          })}
          <br />
        </>
      )}
      {date_cancel && (
        <>
          {t('reservations.date_cancel', {
            date: dayjs(date_cancel).format('DD/MM/YYYY'),
            hour: dayjs(date_cancel).format('HH:mm'),
          })}
          <br />
        </>
      )}
      {date_rbt_caution && (
        <>
          {t('reservations.date_refund_deposit', {
            date: dayjs(date_rbt_caution).format('DD/MM/YYYY'),
            hour: dayjs(date_rbt_caution).format('HH:mm'),
          })}
          <br />
        </>
      )}
      {checkout_link && (
        <>
          <a id={'checkoutlink'} href={checkout_link} target={'_blank'}>
            {t('reservations.checkin.title')}
          </a>
          <br />
        </>
      )}
      {!isFree && amount && parseFloat(amount) > 0 && (
        <>
          <Trans
            t={t}
            i18nKey={'reservations.price'}
            values={{ price, amount, currency: reservation?.devise }}
            components={{ st: <strong /> }}
          />
          <br />
        </>
      )}
      {montant_depot_garantie_ttc && parseFloat(montant_depot_garantie_ttc) > 0 && (
        <>
          <Trans
            t={t}
            i18nKey={'reservations.security_deposit'}
            values={{
              securityDepositAmount: montant_depot_garantie_ttc,
              currency: reservation?.devise,
            }}
            components={{ st: <strong /> }}
          />
          <br />
        </>
      )}
      {montant_caution_ttc && parseFloat(montant_caution_ttc) > 0 && (
        <>
          <Trans
            t={t}
            i18nKey={'reservations.deposit'}
            values={{
              depositAmount: montant_caution_ttc,
              currency: reservation?.devise,
            }}
            components={{ st: <strong /> }}
          />
          <br />
        </>
      )}
      {sepa_caution_transac_id && (
        <>
          <Trans
            t={t}
            i18nKey={'reservations.sepa_deposit_transac'}
            values={{ value: sepa_caution_transac_id }}
            components={{ st: <strong /> }}
          />
          <br />
        </>
      )}
      {cb_caution_transac_id && (
        <>
          <Trans
            t={t}
            i18nKey={'reservations.cb_deposit_transac'}
            values={{ value: cb_caution_transac_id }}
            components={{ st: <strong /> }}
          />
          <br />
        </>
      )}
      {rum && (
        <>
          <Trans t={t} i18nKey={'reservations.rum'} values={{ rum }} components={{ st: <strong /> }} />
          <br />
        </>
      )}
      {sepa_booking_transac_id && (
        <>
          <Trans
            t={t}
            i18nKey={'reservations.sepa_transac'}
            values={{ value: sepa_booking_transac_id }}
            components={{ st: <strong /> }}
          />
          <br />
        </>
      )}
      {cb_booking_transac_id && (
        <>
          <Trans
            t={t}
            i18nKey={'reservations.cb_transac'}
            values={{ value: cb_booking_transac_id }}
            components={{ st: <strong /> }}
          />
          <br />
        </>
      )}
    </>
  );
};

export default ReservationLines;
