import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { TOAST_SEVERITY } from '../components/Core/Toast/Toast';
import { useSnackbar } from '../context/SnackbarContext';

//eslint-disable-next-line
function useDisplayError(data?: any) {
  const { t } = useTranslation('errors');
  const snackbarCustom = useSnackbar();
  // const dispatch = useDispatch();

  useEffect(() => {
    if (data && 'exception' in data) {
      snackbarCustom?.setAlert({
        message: t('errors:'.concat('code' in data ? data.code.replace('error.', '') : 'global')),
        severity: TOAST_SEVERITY.error,
      });
    }
  }, [data, t, snackbarCustom]);
}

export default useDisplayError;
