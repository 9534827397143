import { GetFormFieldsFunction } from '../../admin/Collectivity/updateForm';

export const stripeForm: GetFormFieldsFunction = (t) => {
  return [
    {
      colsNumber: 2,
      fields: [
        { type: 'text', name: 'id_stripe', label: t('stripe.id_stripe'), placeholder: t('stripe.id_stripe') },
        {
          type: 'text',
          name: 'id_stripe_private',
          label: t('stripe.id_stripe_private'),
          placeholder: t('stripe.id_stripe_private'),
        },
        {
          type: 'text',
          name: 'id_monthly_payment',
          label: t('stripe.id_monthly_payment'),
          placeholder: t('stripe.id_monthly_payment'),
        },
        {
          type: 'checkbox',
          name: 'paiement_bo',
          label: t('payment_bo'),
          placeholder: t('payment_bo'),
        },
      ],
    },
  ];
};

export const sogecommerceForm: GetFormFieldsFunction = (t) => {
  return [
    {
      colsNumber: 2,
      fields: [
        {
          type: 'text',
          name: 'vads_site_id',
          label: t('sogecommerce.vads_site_id'),
          placeholder: t('sogecommerce.vads_site_id'),
        },
        {
          type: 'text',
          name: 'password_rest',
          label: t('sogecommerce.password_rest'),
          placeholder: t('sogecommerce.password_rest'),
        },
        {
          type: 'text',
          name: 'certificat_key',
          label: t('sogecommerce.certificat_key'),
          placeholder: t('sogecommerce.certificat_key'),
        },
        {
          type: 'text',
          name: 'publickey_js',
          label: t('sogecommerce.publickey_js'),
          placeholder: t('sogecommerce.publickey_js'),
        },
        {
          type: 'text',
          name: 'hmac_rest',
          label: t('sogecommerce.hmac_rest'),
          placeholder: t('sogecommerce.hmac_rest'),
        },
        {
          type: 'text',
          name: 'url_api',
          label: t('sogecommerce.url_api'),
          placeholder: t('sogecommerce.url_api'),
        },
        {
          type: 'text',
          name: 'url_page_paiement',
          label: t('sogecommerce.url_paiement_page'),
          placeholder: t('sogecommerce.url_paiement_page'),
        },
        {
          type: 'text',
          name: 'vads_ctx_mode',
          label: t('sogecommerce.vads_ctx_mode'),
          placeholder: t('sogecommerce.vads_ctx_mode'),
        },
        {
          type: 'checkbox',
          name: 'paiement_bo',
          label: t('payment_bo'),
          placeholder: t('payment_bo'),
        },
      ],
    },
  ];
};

export const payboxSystemForm: GetFormFieldsFunction = (t) => {
  return [
    {
      colsNumber: 2,
      fields: [
        {
          type: 'text',
          name: 'pbx_url',
          label: t('payboxSystem.pbx_url'),
          placeholder: t('payboxSystem.pbx_url'),
        },
        {
          type: 'text',
          name: 'pbx_site',
          label: t('payboxSystem.pbx_site'),
          placeholder: t('payboxSystem.pbx_site'),
        },
        {
          type: 'text',
          name: 'pbx_rang',
          label: t('payboxSystem.pbx_rang'),
          placeholder: t('payboxSystem.pbx_rang'),
        },
        {
          type: 'text',
          name: 'pbx_identifiant',
          label: t('payboxSystem.pbx_identifiant'),
          placeholder: t('payboxSystem.pbx_identifiant'),
        },
        {
          type: 'text',
          name: 'pbx_hmackey',
          label: t('payboxSystem.pbx_hmackey'),
          placeholder: t('payboxSystem.pbx_hmackey'),
        },
        {
          type: 'checkbox',
          name: 'paiement_bo',
          label: t('payment_bo'),
          placeholder: t('payment_bo'),
          columnStart: 1,
        },
      ],
    },
  ];
};

export const moneticoForm: GetFormFieldsFunction = (t) => {
  return [
    {
      colsNumber: 2,
      fields: [
        {
          type: 'text',
          name: 'mco_tpe',
          label: t('monetico.mco_tpe'),
          placeholder: t('monetico.mco_tpe'),
        },
        {
          type: 'text',
          name: 'mco_code_societe',
          label: t('monetico.mco_code_societe'),
          placeholder: t('monetico.mco_code_societe'),
        },
        {
          type: 'text',
          name: 'mco_hmackey',
          label: t('monetico.mco_hmackey'),
          placeholder: t('monetico.mco_hmackey'),
        },
        {
          type: 'text',
          name: 'mco_url',
          label: t('monetico.mco_url'),
          placeholder: t('monetico.mco_url'),
        },
        {
          type: 'checkbox',
          name: 'paiement_bo',
          label: t('payment_bo'),
          placeholder: t('payment_bo'),
        },
      ],
    },
  ];
};
