import Button from 'fragments/button/Button';
import Select from 'fragments/select/Select';
import { t } from 'internal/i18n';
import React, { useMemo } from 'react';

type Props = {
  isInWaitingList: boolean;
  otherBikes: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    label: any;
    value: string;
  }[];
  setNewBikeID: React.Dispatch<React.SetStateAction<string>>;
  submitBikeChange: () => void;
  isAuto: boolean;
  articleNotAttributed: boolean;
};

const ReservationListBikes = ({
  articleNotAttributed,
  otherBikes,
  setNewBikeID,
  submitBikeChange,
  isAuto,
  isInWaitingList,
}: Props) => {
  const renderListBikes = useMemo(() => {
    if (articleNotAttributed && !isInWaitingList) {
      return (
        <div className={'row'}>
          <div className={'col-md-12'}>
            <div className={'row'}>
              <div className={'col-md-6'}>
                <Select
                  label={''}
                  id={'other-bike'}
                  name={'other-bike'}
                  options={otherBikes}
                  handleChange={setNewBikeID}
                  noWrapper
                />
              </div>
              <div className={'col-md-4'}>
                <Button
                  label={t('reservations.assign')}
                  className={'btn btn-primary changeresa ptn-fit'}
                  onClick={submitBikeChange}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }

    return null;
  }, [articleNotAttributed, otherBikes, submitBikeChange, setNewBikeID, isAuto, isInWaitingList]);

  return <>{renderListBikes}</>;
};

export default ReservationListBikes;
