import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import 'fragments/datepicker/DatePicker.css';
import dayjs from 'dayjs';
import validator from 'validator';

registerLocale('fr', fr);

type Props = {
  type: string;
  name: string;
  id: string;
  placeholder?: string;
  className?: string;
  icon?: string;
  size?: string;
  label?: string;
  parentValue?: string;
  defaultValue?: string;
  showTimeInput?: boolean;
  minDate?: Date | null | undefined;
  maxDate?: Date | null | undefined;
  includeDates?: Date[] | undefined;
  handleChange?: (value: string) => void;
};

const DatePicker = ({
  id,
  placeholder = '',
  className = '',
  size = '',
  label = '',
  defaultValue = '',
  parentValue = '',
  minDate = null,
  maxDate = null,
  showTimeInput = false,
  handleChange = () => null,
  includeDates = undefined,
}: Props): ReactElement => {
  const [value, setValue] = useState<Date>();

  const inputClassName = cx('form-control', {
    [className]: className !== '',
  });

  const wrapperClassName = cx('form-group input-group', {
    [size]: size !== '',
    ['empty']: !value,
  });

  const onChange = useCallback(
    (_value: Date) => {
      setValue(_value);
      const date = dayjs(_value).format(className !== 'datetimepicker-input' ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm');
      handleChange(date);
    },
    [handleChange, setValue]
  );

  useEffect(() => {
    if (defaultValue !== '' && !value && validator.isDate(defaultValue)) {
      const defaultDate = new Date(defaultValue);

      setValue(defaultDate);
    }
  }, [defaultValue, value]);

  return (
    <div key={id} className={wrapperClassName} style={{ display: 'flex', flexDirection: 'column' }}>
      {label !== '' && <label htmlFor={id}>{label}</label>}
      <ReactDatePicker
        id={id}
        className={inputClassName}
        selected={(parentValue !== '' && dayjs(parentValue).toDate()) || value}
        placeholderText={placeholder}
        locale={'fr'}
        dateFormat={className !== 'datetimepicker-input' ? 'dd/MM/yyyy' : 'dd/MM/yyyy HH:mm'}
        minDate={minDate}
        maxDate={maxDate}
        onChange={onChange}
        showMonthDropdown
        showYearDropdown
        dropdownMode={'scroll'}
        includeDates={includeDates}
        showTimeSelect={showTimeInput}
        autoComplete={'off'}
      />
    </div>
  );
};

export default DatePicker;
