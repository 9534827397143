import { useInstanceApi } from '../../../api/axios';
import { GridDataState, useRequest } from '../../../utils/request';

const URL = {
  fetchConsigneParkList: 'parc/consignespark',
  putConsignePark: 'parc/updconspark',
};

function useConsigneParkListApi() {
  const instance = useInstanceApi();

  const { request: fetchConsigneParkList } = useRequest((queryParams: GridDataState & { id_coll: number }) =>
    instance.get(URL.fetchConsigneParkList, {
      params: {
        id_coll: queryParams.id_coll,
        p: queryParams.currentPage,
        ipp: queryParams.itemPerPage,
      },
    })
  );

  const { request: putConsignePark } = useRequest((data) => instance.post(URL.putConsignePark, data));

  return {
    fetchConsigneParkList,
    putConsignePark,
  };
}

export default useConsigneParkListApi;
