import dayjs from 'dayjs';
import React, { ComponentPropsWithoutRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Control, Controller } from 'react-hook-form';
import classNames from 'classnames';
import Label from '../Label/Label';
import styles from './timeField.module.scss';

const cx = classNames.bind(styles);

type TimeFieldProps = Omit<ComponentPropsWithoutRef<typeof ReactDatePicker>, 'onChange' | 'value'> & {
  //eslint-disable-next-line
  control: Control<any>;
  name: string;
  label?: string;
};

function TimeField({ control, label, name, ...propsToForward }: TimeFieldProps) {
  const Wrapper = label ? 'div' : React.Fragment;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => {
        return (
          <div>
            <Label>{label}</Label>
            <Wrapper>
              <ReactDatePicker
                {...propsToForward}
                className={cx(styles.Input, { [styles.InputWithValue]: value && value !== '' })}
                wrapperClassName={styles.Wrapper}
                dateFormat={'HH:mm'}
                timeFormat={'HH:mm'}
                value={value ? dayjs(value).format('HH:mm') : value}
                timeIntervals={30}
                showTimeSelect
                showTimeSelectOnly
                onChange={onChange}
              />
            </Wrapper>
          </div>
        );
      }}
    />
  );
}

export default TimeField;
