import React, { useCallback } from 'react';
import styled from '@emotion/styled/macro';
import { getColor } from '../../utils/style';
import useControlled from '../../hook/useControlled';

type CaseScheduleProps = {
  onChange?: (label: string, isToggle: boolean) => void;
  label: string;
  checked: boolean;
};

function CaseSchedule(props: CaseScheduleProps) {
  const { onChange, checked: checkedProp, label } = props;
  const [checked, toggle] = useControlled({
    controlled: checkedProp,
    default: false,
    state: 'checked',
    name: 'CaseSchedule',
  });

  const handleChange = useCallback(() => {
    onChange?.(label, !checked);
    toggle(!checked);
  }, [onChange, checked]);

  return (
    <CaseScheduleRoot isToggle={checked} onClick={handleChange}>
      {label}
    </CaseScheduleRoot>
  );
}

export const CaseScheduleRoot = styled.div<{ isToggle?: boolean }>`
  display: inline;
  min-width: 150px;
  text-align: center;
  padding: 16px 20px;
  background-color: ${(props) => (props.isToggle ? getColor('primary.200')(props) : 'white')};
  color: ${(props) => (props.isToggle ? 'white' : getColor('gray.400'))};
  border: 1px solid ${(props) => (props.isToggle ? getColor('primary.300')(props) : getColor('gray.200')(props))};
  font-size: 12px;
  cursor: ${(props) => 'isToggle' in props && 'pointer'};
  font-weight: bold;
`;

export default CaseSchedule;
