import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { selectCollAndDeliveryPointID } from 'auth/selectors/authSelectors';
import cx from 'classnames';
import Button from 'fragments/button/Button';

import Checkbox from 'fragments/checkbox/Checkbox';
import DataTable from 'fragments/dataTable/DataTable';
import Input from 'fragments/input/Input';
import { setSnackbar, showSnackbar } from 'internal/actions/appActions';
import SnackbarSeverity from 'internal/constants/snackbar';
import useApi, { Methods } from 'internal/hooks/useApi';
import useIsMobile from 'internal/hooks/useIsMobile';
import { toInteger } from 'lodash';
import ParametersInOutCreation from 'parameters/components/ParametersInOutCreation';
import { PARAM_CHECKIN_ENDPOINT } from 'parameters/constants/endpoints';

import { CheckinItemType } from 'parameters/types/checkin';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const ParametersTypeEdition = (): ReactElement => {
  const { t } = useTranslation(['parameters/checkin', 'form']);
  const [formState, setFormState] = useState<CheckinItemType[]>([]);
  const [article, setArticle] = useState<string>('');
  const [type, setType] = useState<string>('');
  const [id, setId] = useState<string>('');
  const [indexUsed, setIndexUsed] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const isMobile = useIsMobile();
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const { search } = useLocation();
  const dispatch = useDispatch();

  const { request: submitVerification, response: verificationResponse } = useApi(Methods.POST, PARAM_CHECKIN_ENDPOINT);
  const { request: deleteVerification, response: deleteResponse } = useApi(Methods.DELETE, PARAM_CHECKIN_ENDPOINT);
  const { request, response } = useApi<{ checkin: CheckinItemType[] }[]>(Methods.GET, PARAM_CHECKIN_ENDPOINT);

  const actionsClasses = cx('btn btn-square btn-outline btn-danger', {
    'ml-10': !isMobile,
  });

  const fetchData = useCallback(() => {
    if (id) {
      request({
        params: {
          id_coll,
          article,
          type_article: id,
        },
      });
    }
  }, [id, id_coll, article]);

  useEffect(() => {
    if (response?.data && response?.success && response.data[0] && response.data[0].checkin?.length > 0) {
      setFormState(response.data[0].checkin);
    }
  }, [response]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (search) {
      const queryParams = new URLSearchParams(search);
      const type = queryParams.get('type') || '';
      const id = queryParams.get('id') || '';
      const article = queryParams.get('article') || '';

      setType(type);
      setId(id);
      setArticle(article);
    }
  }, [search]);

  useEffect(() => {
    if (verificationResponse?.success) {
      dispatch(setSnackbar(t('new_verification_success'), SnackbarSeverity.SUCCESS));
      dispatch(showSnackbar());
    }
  }, [verificationResponse, fetchData, t]);

  useEffect(() => {
    if (deleteResponse?.success) {
      dispatch(setSnackbar(t('new_verification_success'), SnackbarSeverity.SUCCESS));
      dispatch(showSnackbar());
      fetchData();
    }
  }, [deleteResponse, fetchData, t]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleDelete = useCallback(
    (index: number) => () => {
      setIndexUsed(index);
      setOpen(true);
    },
    []
  );

  const handleChangeFormState = useCallback(
    (index: number, key: string) => (value: boolean | string) => {
      setFormState((prevState) => {
        const newFormState = [...prevState];

        newFormState.splice(index, 1, {
          ...prevState[index],
          [key]: value,
        });

        return [...newFormState];
      });
    },
    [formState]
  );

  const handleSubmit = useCallback(
    (index: number) => () => {
      // prettier-ignore
      const {
        id_form, label, is_mandatory, has_media, is_media_mandatory, need_status, is_active, 
      } = formState[index];
      // WTF but usefull. Need to be changed in the future.
      const isNotGlobalCommentary = !(need_status === 0 || need_status === true);

      submitVerification({
        id_form: id_form,
        id_coll,
        article,
        type_article: id,
        label: label,
        is_mandatory: toInteger(is_mandatory),
        has_media: toInteger(has_media),
        is_media_mandatory: toInteger(is_media_mandatory),
        is_active: toInteger(is_active),
        need_status: toInteger(isNotGlobalCommentary),
      });
    },
    [formState, id, id_coll, article]
  );

  const handleDeleteFromDialog = useCallback(() => {
    deleteVerification({
      params: {
        id_coll,
        id_form: formState[indexUsed].id_form,
      },
    });
    setOpen(false);
  }, [formState, indexUsed, id_coll]);

  const items = useMemo(() => {
    if (formState) {
      // prettier-ignore
      return formState.map(({ 
        label, is_mandatory, has_media, is_media_mandatory, is_active, need_status,
      }, i) => {
        let isGlobalCommentary;

        if (typeof need_status === 'number') {
          isGlobalCommentary = !need_status;
        } else {
          isGlobalCommentary = need_status;
        }

        return {
          label: (
            <Input
              value={label}
              handleChange={handleChangeFormState(i, 'label')}
              controlled
              disabled={isGlobalCommentary}
            />
          ),
          is_mandatory: (
            <Checkbox
              checked={!!is_mandatory}
              handleChange={handleChangeFormState(i, 'is_mandatory')}
              disabled={isGlobalCommentary}
              outsideCheckMode
            />
          ),
          has_media: (
            <Checkbox checked={!!has_media} handleChange={handleChangeFormState(i, 'has_media')} outsideCheckMode />
          ),
          is_media_mandatory: (
            <Checkbox
              checked={!!is_media_mandatory}
              handleChange={handleChangeFormState(i, 'is_media_mandatory')}
              outsideCheckMode
            />
          ),
          is_active: (
            <Checkbox checked={!!is_active} handleChange={handleChangeFormState(i, 'is_active')} outsideCheckMode />
          ),
          need_status: (
            <Checkbox
              className={'toto'}
              checked={isGlobalCommentary}
              handleChange={handleChangeFormState(i, 'need_status')}
              outsideCheckMode
            />
          ),
          action: (
            <>
              <button onClick={handleSubmit(i)} className={'btn btn-square btn-outline btn-primary'}>
                <i className={'ti-check'} />
              </button>
              <button onClick={handleDelete(i)} className={actionsClasses}>
                <i className={'ti-trash'} />
              </button>
            </>
          ),
        };
      });
    }
    return [];
  }, [formState]);

  return (
    <div className={'main-content'}>
      <div className={'card'}>
        <h4 className={'card-title'}>
          {t('title')} - {type}
        </h4>
        <div className={'card-body'}>
          <div className={'row'}>
            <div className={'col-md-12'}>
              <div className={'dataTables_wrapper container-fluid dt-bootstrap4 no-footer'}>
                <DataTable
                  cols={[
                    'label',
                    'is_mandatory',
                    'has_media',
                    'is_media_mandatory',
                    'is_active',
                    'need_status',
                    'action',
                  ]}
                  colTraductionScope={'parameters/checkin'}
                  colHandlers={[]}
                  items={items}
                />
              </div>
            </div>
          </div>
          <div className={'row'}>
            <ParametersInOutCreation refresh={fetchData} typeArticle={id} article={article} />
          </div>
        </div>
      </div>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>
          <div className={'modal-confirm-header'}>
            <div className={'modal-confirm-icon'}>!</div>
            <p className={'modal-confirm-title'}>{t('form:are_you_sure')}</p>
          </div>
        </DialogTitle>
        <DialogContent className={'modal-confirm-body'}>
          <p>{t('verification_delete_confirm')}</p>
        </DialogContent>
        <DialogActions className={'modal-confirm-actions'}>
          <Button
            label={t('form:button_yes')}
            className={'btn-primary btn-fit btn-margin'}
            onClick={handleDeleteFromDialog}
          />
          <Button label={t('form:button_no')} className={'btn-secondary btn-fit'} onClick={handleClose} />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ParametersTypeEdition;
