import React, { HtmlHTMLAttributes } from 'react';
import { Control, useWatch } from 'react-hook-form';
import classNames from 'classnames/bind';
import { upperFirst } from 'lodash';
import styles from './FOButtonsVisualizer.module.scss';

const cx = classNames.bind(styles);

function FOButton({
  style,
  variant = 'primary',
}: { variant?: 'primary' | 'secondary' | 'outlined' } & HtmlHTMLAttributes<HTMLButtonElement>) {
  return (
    <button className={cx('FOButton', 'FOButton'.concat(upperFirst(variant)))} style={style}>
      Je suis un bouton
    </button>
  );
}

function FoButtonsVisualizer({ control }: { control: Control }) {
  const colors = useWatch({ control });

  return (
    <div className={styles.Visualizer}>
      <div className={styles.Card}>
        <FOButton
          style={
            {
              '--fo-primary-200': colors['primary-200'],
            } as React.CSSProperties
          }
        />
        <FOButton
          variant={'outlined'}
          style={
            {
              '--fo-primary-200': colors['primary-200'],
            } as React.CSSProperties
          }
        />
        <FOButton
          variant={'secondary'}
          style={
            {
              '--fo-secondary-200': colors['secondary-200'],
            } as React.CSSProperties
          }
        />
      </div>
    </div>
  );
}

export default FoButtonsVisualizer;
