import React from 'react';
import Link from '../components/Core/Link/Link';
import MainSection from '../components/Core/MainSection/MainSection';

function NotFound() {
  return (
    <MainSection>
      <h1>404 - Not Found!</h1>
      <Link to={'/'}>Go Home</Link>
    </MainSection>
  );
}

export default NotFound;
