import React, { Suspense, useCallback, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { BOOKING_STATUS, useBooking } from 'pages/Reservation/Reservation.layout';
import { cancelBooking, ValidBookingBodyForm } from '../../../../api/booking.api';
import { selectCollAndDeliveryPointID } from '../../../../auth/selectors/authSelectors';
import { useConfirm } from '../../../../components/Confirm/ConfirmContext';
import Button from '../../../../components/Core/Button/Button';
import Card from '../../../../components/Core/Card/Card';
import styles from '../../bookingDetails.module.scss';
import AssignModal from './AssignModal';

export enum ARTICLE_TYPES {
  bike = 'velo',
  park = 'consigne',
  // freePark = 'consigne',
}

function ManagementCard() {
  const { t } = useTranslation(['reservation/components/management']);
  const { bookingStatus, rawBooking } = useBooking();
  const { id_coll, id_pdl } = useSelector(selectCollAndDeliveryPointID);
  const { bookingId } = useParams();
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);
  const { confirm } = useConfirm();

  const { mutate: handleCancelBooking, isLoading } = useMutation(
    ['booking.cancel'],
    (data: ValidBookingBodyForm) => {
      return cancelBooking(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['booking']);
      },
    }
  );

  const handleCancel = useCallback(() => {
    if (bookingId)
      confirm({
        title: t('cancelModal.title'),
        content: (
          <p>
            <Trans t={t} i18nKey={'cancelModal.description'} />
          </p>
        ),
      }).then((v) => {
        if (v) handleCancelBooking({ id_coll, id_pdl, id_resa: bookingId });
      });
  }, [bookingId, id_coll, id_pdl, confirm]);

  return bookingId ? (
    <Card
      title={t('cardTitle')}
      className={styles.managementCard}
      content={
        <>
          <p className={styles.managementCard__text}>{t('cancelSection.description')}</p>
          <Button isLoading={isLoading} onClick={handleCancel} color={'secondary'}>
            {t('cancelSection.cta')}
          </Button>
          {rawBooking?.article === ARTICLE_TYPES.bike &&
            bookingStatus === BOOKING_STATUS.VALIDATE &&
            !rawBooking.isVelocareSynced && (
              <>
                <hr className={styles.managementCard__line} />
                <p className={styles.managementCard__text}>{t('exchangeBikeSection.description')}</p>
                <Button color={'secondary'} onClick={() => setIsOpen(true)}>
                  {t('exchangeBikeSection.cta')}
                </Button>
                <Suspense fallback={<></>}>{isOpen && <AssignModal setIsOpen={setIsOpen} />}</Suspense>
              </>
            )}
        </>
      }
    />
  ) : null;
}

export default ManagementCard;
