/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect } from 'react';
import { ClassNames } from '@emotion/react';
import { useForm, UseFormReturn, useWatch } from 'react-hook-form';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import FormGroupCard from '../../../../components/FormGroupCard/FormGroupCard';
import Button from '../../../../components/Core/Button/Button';
import { getColor } from '../../../../utils/style';
import useVisualApi from '../useVisualApi';
import { selectCollAndDeliveryPointID } from '../../../../auth/selectors/authSelectors';
import { TOAST_SEVERITY } from '../../../../components/Core/Toast/Toast';
import { useSnackbar } from '../../../../context/SnackbarContext';
import { ColorPickerControlled } from './NavigationSection';
import { FileField } from './LogoSection';

type BannerVisualizerProps = Pick<UseFormReturn, 'control'> & {
  opacity?: number;
  bg?: string;
  darkeningColor?: string;
};

function BannerVisualizer(props: BannerVisualizerProps) {
  const { control } = props;
  const [opacity, bg] = useWatch({ control, name: ['opacity', 'background'] });

  return (
    <VisualizerRoot bg={bg}>
      <Overlay darkeningColor={opacity} />
    </VisualizerRoot>
  );
}

const Overlay = styled.div<{ opacity?: number; darkeningColor?: string }>`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  background-color: ${(props) => (props.darkeningColor ? props.darkeningColor : 'transparent')};
  opacity: ${({ opacity = 1 }) => opacity};
`;

const VisualizerRoot = styled.div<{ bg?: string }>`
  position: relative;
  background: ${(props) => (props.bg ? `url(${props.bg})` : '#f8f8f8')};
  background-position: center;
  background-size: cover;
`;

//eslint-disable-next-line
function BannerSection({ defaultValues }: { defaultValues: any }) {
  const { t } = useTranslation(['admin/visual', 'form']);
  const snackbar = useSnackbar();
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const { control, handleSubmit, reset } = useForm({ defaultValues });
  const { postBannerConfig, postMedia } = useVisualApi();

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const handlePostMedia = useCallback(
    //eslint-disable-next-line
    async (fileName: string, file: any) => {
      // const file = await dataURLtoFile(rawFile, 'headerBackground');
      const response = await postMedia({
        collId: id_coll,
        file,
        fileName: 'headerBackground',
      });

      if (response && response.status === 200) {
        snackbar?.setAlert({ message: t('form:global_update_success_message'), severity: TOAST_SEVERITY.success });
      }
    },
    [id_coll]
  );

  const onSubmit = useCallback(
    handleSubmit(async (data) => {
      const response = await postBannerConfig({ collId: id_coll, 'banner-opacity': data.opacity });

      if (response && response.status === 200) {
        snackbar?.setAlert({ message: t('form:global_update_success_message'), severity: TOAST_SEVERITY.success });
      }
    }),
    [id_coll]
  );

  return (
    <ClassNames>
      {({ css }) => (
        <FormGroupCard
          contentClassName={css`
            display: grid;
            grid-template-columns: 2fr 1fr;
            gap: 16px;
          `}
          title={t('banner')}
          WrapperComponent={({ children }) => <form onSubmit={onSubmit}>{children}</form>}
          FooterSlotComponent={() => (
            <BannerSectionCardFooterRoot>
              <Button type={'submit'} label={t('update_cta')} />
            </BannerSectionCardFooterRoot>
          )}>
          <div>
            <div>
              <FileField
                returnFile
                onChange={handlePostMedia}
                control={control}
                name={'background'}
                description={t('banner_description')}
              />
            </div>
            <div
              css={css`
                display: grid;
                align-items: center;
                grid-template-columns: repeat(3, 1fr);
                margin-top: 32px;
              `}>
              <ColorPickerControlled
                disableAlpha={false}
                name={'opacity'}
                control={control}
                label={t('banner_opacity')}
              />
            </div>
          </div>
          <BannerVisualizer control={control} />
        </FormGroupCard>
      )}
    </ClassNames>
  );
}

const BannerSectionCardFooterRoot = styled.div`
  //border-top: 1px solid ${getColor('gray.200')};
  //padding: 16px;
  padding-top: 16px;
  display: flex;
  justify-content: flex-end;
`;

export default BannerSection;
