import { GetFormFieldsFunction } from '../Collectivity/updateForm';

const updateContactParametersForm: GetFormFieldsFunction = (t) => {
  return [
    /*{
      fields: [{}],
    },*/
    {
      colsNumber: 2,
      fields: [
        {
          type: 'phone',
          name: 'phone',
          id: 'phone',
          label: t('phone'),
          placeholder: t('phone'),
        },
        {
          type: 'text',
          name: 'email',
          id: 'email',
          label: t('email'),
          placeholder: t('email'),
        },
        {
          type: 'textarea',
          name: 'contactHook',
          id: 'contactHook',
          label: t('contact_hook'),
          placeholder: t('contact_hook'),
        },
        {
          type: 'textarea',
          name: 'address',
          id: 'address',
          label: t('address'),
          placeholder: t('address'),
        },
      ],
    },
  ];
};

export default updateContactParametersForm;
