import { uniqueId } from 'lodash';
import React, { HtmlHTMLAttributes, useCallback, useDeferredValue, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { FileIcon, UploadIcon } from '../../../const/icons';
import { BASE64_HEAD_REGEXP } from '../../../internal/utils/file';
import IconButton from '../Button/IconButton';
import styles from './dropzoneFileInput.module.scss';

export enum DROPZONE_SIZES {
  normal,
  mini,
}

type DropzoneProps = Omit<HtmlHTMLAttributes<HTMLDivElement>, 'defaultValue'> & {
  size?: DROPZONE_SIZES;
  defaultValue?: { src: string } | string;
  onChange?: (newValue?: string | File) => void;
  canBeDeleted?: boolean;
  required?: boolean;
  returnFile?: boolean;
};

type Image = {
  src?: string | null;
  id?: string;
  type?: string;
  name?: string;
};

function DropzoneFileInput({
  size = DROPZONE_SIZES.normal,
  defaultValue,
  onChange,
  required = false,
  canBeDeleted = false,
  returnFile = false,
  className,
  ...propsToForward
}: DropzoneProps) {
  const { t } = useTranslation(['components/dropzoneFile']);
  const [image, setImage] = useState<Image | undefined>(
    typeof defaultValue === 'string' ? { src: defaultValue } : defaultValue
  );
  const deferredImage = useDeferredValue(image);

  useEffect(() => {
    if (defaultValue !== image?.src) {
      if (defaultValue === null) {
        setImage({ src: defaultValue });
      }

      if (defaultValue !== null && returnFile) {
        //eslint-disable-next-line
        setImage(defaultValue as any);
      }
    }
  }, [defaultValue, image]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles.map((file) => {
        let src = '';
        if (returnFile) {
          onChange?.(file);
          return file;
        } else {
          const reader = new FileReader();
          reader.onload = function (e) {
            if (e.target && e.target.result && typeof e.target.result === 'string') {
              src = e.target.result;
              onChange?.(src.replace(BASE64_HEAD_REGEXP, ''));
              setImage({ id: uniqueId(), src, type: file.type, name: file.name });
            }
          };

          reader.readAsDataURL(file);
        }

        return file;
      });
    },
    [onChange, returnFile]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      'application/pdf': [],
      'image/png': [],
      'image/jpeg': [],
      'image/jpg': [],
    },
  });

  const onDeleteCurrentFile = (e: PointerEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setImage(undefined);
    onChange?.(undefined);
  };

  return (
    <div
      className={[
        size === DROPZONE_SIZES.mini && styles.small,
        image && image.src && styles.filled,
        styles.DropzoneFileInput,
        className,
      ].join(' ')}
      {...{ ...propsToForward, ...getRootProps() }}>
      <input {...getInputProps()} type={'file'} />
      <div className={styles.DropzoneFileInput__content}>
        {deferredImage && deferredImage.src ? (
          <>
            {deferredImage?.type === 'application/pdf' ? (
              <div className={styles.PDFViewer}>
                <FileIcon className={'primary'} width={20} />
                <span>{deferredImage.name}</span>
              </div>
            ) : (
              <div
                style={{ backgroundImage: `url(${deferredImage.src})` }}
                className={styles.DropzoneFileInput__viewer}
              />
            )}
            {/*<img
                className={styles.DropzoneFileInput__img}
                // style={{ maxWidth: size === DROPZONE_SIZES.mini ? 50 : 250 }}
                id={image.id}
                src={image.src}
                alt={''}
              />*/}
            {canBeDeleted && (
              <IconButton
                className={styles.DropzoneFileInput__deleteButton}
                onClick={onDeleteCurrentFile}
                icon={'TrashIcon'}
              />
            )}
          </>
        ) : (
          <>
            {required && <span className={styles.requiredText}>*</span>}
            <div className={styles.DropzoneFileInput__upload}>
              <UploadIcon width={16} />
            </div>
            {size === DROPZONE_SIZES.normal && (image === undefined || !image?.src) && (
              <>
                <p>
                  <strong>{t('title.firstLine')}</strong>
                  <br />
                  {t('title.secondLine')}
                </p>

                <span className={'advice'}>{t('requirements')}</span>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default DropzoneFileInput;
