import { instance } from '../user.api';

export const STRIPE_ENDPOINT = 'param/stripe';
export const SOGECOMMERCE_ENDPOINT = 'param/sogecommerce';
export const PAYBOX_ENDPOINT = 'param/paybox';
export const MONETICO_ENDPOINT = 'param/monetico';

const URL = {
  monetico: 'param/monetico',
  sogecommerce: 'param/sogecommerce',
  paybox: 'param/paybox',
  stripe: 'param/stripe',
};

export async function getMoneticoConfig(id_coll: number) {
  return await instance.get(URL.monetico, {
    params: {
      id_coll,
    },
  });
}

export type PutMoneticoBody = {
  mco_code_societe: string;
  mco_hmackey: string;
  mco_tpe: string;
  mco_url: string;
  paiement_bo: boolean;
  id_coll: number;
};

export async function putMoneticoConfig(data: PutMoneticoBody) {
  return await instance.post(URL.monetico, data);
}

export async function getStripeConfig(id_coll: number) {
  return await instance.get(URL.stripe, {
    params: {
      id_coll,
    },
  });
}

export type PutStripeBody = {
  id_stripe: string;
  id_stripe_private: string;
  paiement_bo: boolean;
  id_coll: number;
};

export async function putStripeConfig(data: PutStripeBody) {
  return await instance.post(URL.stripe, data);
}

export async function getSogecommerceConfig(id_coll: number) {
  return await instance.get(URL.sogecommerce, {
    params: {
      id_coll,
    },
  });
}

export type PutSogecommerceBody = {
  certificat_key: string;
  hmac_rest: string;
  password_rest: string;
  publickey_js: string;
  url_api: string;
  url_page_paiement: string;
  vads_ctx_mode: string;
  vads_site_id: string;
  paiement_bo: boolean;
  id_coll: number;
};

export async function putSogecommerceConfig(data: PutSogecommerceBody) {
  return await instance.post(URL.sogecommerce, data);
}

export async function getPayboxConfig(id_coll: number) {
  return await instance.get(URL.paybox, {
    params: {
      id_coll,
    },
  });
}

export type PutPayboxBody = {
  pbx_hmackey: string;
  pbx_identifiant: string;
  pbx_rang: string;
  pbx_site: string;
  pbx_url: string;
  paiement_bo: boolean;
  id_coll: number;
};

export async function putPayboxConfig(data: PutPayboxBody) {
  return await instance.post(URL.paybox, data);
}
