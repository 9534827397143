import { WordingsResponseType } from 'parameters/components/ParametersPages';
import { FRONT_ROUTES, wordingPages } from 'parameters/constants/wordings';
import { FormItemType } from 'users/types/forms';
import { TFunction } from 'i18next';

export const PAGES_WITHOUT_BANNER = [
  FRONT_ROUTES.SIGN_UP,
  FRONT_ROUTES.SIGN_IN,
  FRONT_ROUTES.SIGN_OUT,
  FRONT_ROUTES.HOME,
  FRONT_ROUTES.INSURANCE,
  FRONT_ROUTES.MAIL_VALIDATION,
  FRONT_ROUTES.CHANGE_PWD,
  FRONT_ROUTES.FORGOTTEN_PWD,
];

const buildFormsFromPages = (t: TFunction, selectedLocale?: string): FormItemType[] => {
  if (!selectedLocale) {
    return [];
  }
  const forms: FormItemType[] = [];

  wordingPages.forEach((p) => {
    const baseForm = [
      {
        type: 'text',
        name: `${p}-title-${selectedLocale}`,
        id: `${p}-title-${selectedLocale}`,
        size: 'col-md-4',
        label: `${t(`wordings.${p}`)} - ${t('title')}`,
      },
      {
        type: 'textarea',
        name: `${p}-subtitle-${selectedLocale}`,
        id: `${p}-subtitle-${selectedLocale}`,
        size: 'col-md-4',
        label: `${t(`wordings.${p}`)} - ${t('subtitle')}`,
      },
    ];

    if (!PAGES_WITHOUT_BANNER.includes(p)) {
      baseForm.push({
        type: 'textarea',
        name: `${p}-banner-${selectedLocale}`,
        id: `${p}-banner-${selectedLocale}`,
        size: 'col-md-4',
        label: `${t(`wordings.${p}`)} - ${t('banner')}`,
      });
    } else {
      baseForm.push({
        type: 'void',
        name: `${p}-banner-${selectedLocale}`,
        id: `${p}-banner-${selectedLocale}`,
        size: 'col-md-4',
        label: `${t(`wordings.${p}`)} - ${t('banner')}`,
      });
    }

    forms.push(...baseForm);
    /*        size: 'col-md-6',
        label: `${t(`wordings.${p}`)} - ${t('subtitle')}`,*/
  });

  return forms;
};

const buildFormStateFromPages = (
  pages?: WordingsResponseType,
  localesAvailable?: ('en' | 'fr')[]
): {
  [key: string]: string;
} | null => {
  if (!localesAvailable || !pages) {
    return null;
  }
  const formState = Object.keys(pages).reduce((acc, cur) => {
    const elem = pages[cur];
    localesAvailable.forEach((l) => {
      // Title
      const titleId = `${cur}-title-${l}`;
      const titleValue = elem[l].title;
      acc[titleId] = titleValue;
      // Subtitle
      const subtitleId = `${cur}-subtitle-${l}`;
      const subtitleValue = elem[l].subtitle ? elem[l].subtitle : '';
      acc[subtitleId] = subtitleValue;
      // Banner
      const bannerId = `${cur}-banner-${l}`;
      const bannerValue = elem[l].banner ? elem[l].banner : '';
      acc[bannerId] = bannerValue;
    });
    return acc;
  }, {} as { [key: string]: string });
  return formState;
};

export { buildFormsFromPages, buildFormStateFromPages };
