/** @jsxImportSource @emotion/react */
import React, { ComponentPropsWithoutRef, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ClassNames, css } from '@emotion/react';
import styled from '@emotion/styled';
import { Controller, useForm, UseFormReturn } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { DevTool } from '@hookform/devtools';
import FormGroupCard from '../../../../components/FormGroupCard/FormGroupCard';
import DropzoneFileInput from '../../../../components/Core/DropzoneFileInput/DropzoneFileInput';
import { getColor } from '../../../../utils/style';
import useVisualApi from '../useVisualApi';
import { selectCollAndDeliveryPointID } from '../../../../auth/selectors/authSelectors';
import { TOAST_SEVERITY } from '../../../../components/Core/Toast/Toast';
import { useSnackbar } from '../../../../context/SnackbarContext';

export function FileField(
  props: Pick<UseFormReturn, 'control'> &
    Omit<ComponentPropsWithoutRef<typeof DropzoneFileInput>, 'onChange' | 'defaultValue'> & {
      name: string;
      title?: string;
      description: string;
      //eslint-disable-next-line
      onChange?: (fileName: string, file: any) => void;
    }
) {
  const { title, description, onChange, name, control, ...propsToForward } = props;

  return (
    <div
      css={css`
        flex: 1 0 auto;
      `}>
      {title && <TitleFileField>{title}</TitleFileField>}
      <Controller
        name={name}
        control={control}
        // render={({ field: { value, onChange } }) => <DropzoneFileInput onChange={onChange} defaultValue={value} />}
        render={({ field: { name, value, onChange: onChangeController } }) => {
          return (
            <DropzoneFileInput
              //eslint-disable-next-line
              onChange={(file: any) => {
                const reader = new FileReader();
                reader.onload = function (e) {
                  if (e.target && e.target.result && typeof e.target.result === 'string') {
                    const src = e.target.result;
                    onChangeController(src);
                  }
                };
                reader.readAsDataURL(file as File);
                onChange?.(name, file);
              }}
              defaultValue={{ src: value }}
              {...propsToForward}
            />
          );
        }}
      />
      <DescriptionFileField>{description}</DescriptionFileField>
    </div>
  );
}

const TitleFileField = styled.span`
  display: block;
  font-size: 12px;
  margin-bottom: 16px;
  color: ${getColor('gray.400')};
  font-weight: bold;
`;

const DescriptionFileField = styled.span`
  display: block;
  font-size: 12px;
  margin-top: 16px;
  color: ${getColor('gray.400')};
`;

export type MediaRaw = {
  mediaId: string | null;
  mediaUrl: string | null;
};

type LogoSectionProps = {
  postMedia?: () => void;
  defaultValues: Record<string, string>;
};

function LogoSection(props: LogoSectionProps) {
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const snackbar = useSnackbar();
  const { defaultValues } = props;
  const { t } = useTranslation(['admin/visual']);
  const { control, reset } = useForm({ defaultValues });
  const { postMedia } = useVisualApi();

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const handlePostMedia = useCallback(
    //eslint-disable-next-line
    async (fileName: string, file: File) => {
      // const file = await dataURLtoFile(rawFile, fileName);
      const response = await postMedia({
        collId: id_coll,
        file,
        fileName,
      });

      if (response && response.status === 200) {
        snackbar?.setAlert({ message: t('form:global_update_success_message'), severity: TOAST_SEVERITY.success });
      }
    },
    [id_coll, postMedia, t]
  );

  return (
    <ClassNames>
      {({ css }) => (
        <FormGroupCard
          contentClassName={css`
            overflow: auto;
          `}
          title={t('logos')}>
          <div
            css={css`
              display: flex;
              gap: 16px;
            `}>
            <FileField
              control={control}
              onChange={handlePostMedia}
              returnFile
              name={'navbarLogo'}
              title={t('main_logo_text.title')}
              description={t('main_logo_text.description')}
            />
            <FileField
              control={control}
              onChange={handlePostMedia}
              returnFile
              name={'altLogo'}
              title={t('alternative_main_logo_text.title')}
              description={t('alternative_main_logo_text.description')}
            />
            <FileField
              control={control}
              onChange={handlePostMedia}
              returnFile
              name={'headerLogo'}
              title={t('service_logo_text.title')}
              description={t('service_logo_text.description')}
            />
          </div>
          <DevTool control={control} />
        </FormGroupCard>
      )}
    </ClassNames>
  );
}

export default LogoSection;
