import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { SHA256 } from 'crypto-js';
import logoVelogikDigital from 'assets/img/logo-vlgk-digital.png';
import background from 'assets/img/signin-background.jpg';
import { USER_FORGET_PDW, USER_RESET_PDW } from 'auth/constants/endpoints';
import Button from 'fragments/button/Button';
import MaterialInput from 'fragments/materialInput/MaterialInput';
import { setSnackbar, showSnackbar } from 'internal/actions/appActions';
import { PWD_SEED } from 'internal/constants/api';
import { useLocation, useNavigate } from 'react-router-dom';
import { SIGN_IN } from 'internal/constants/routes';
import SnackbarSeverity from 'internal/constants/snackbar';
import useApi, { Methods } from 'internal/hooks/useApi';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import validator from 'validator';

const ForgottenPassword = (): ReactElement => {
  const { t } = useTranslation(['forgottenPassword', 'common']);
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [copypassword, setCopyPassword] = useState('');
  const [isPasswordSubmission, setIsPwdSubmission] = useState(false);

  const dispatch = useDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);

      const code = params.get('code') || '';
      const _email = params.get('email') || '';

      setEmail(_email);
      setCode(code);
      setIsPwdSubmission(true);
    }
  }, [location.search]);

  const { request: postForgetPdw, response: postForgetRes } = useApi(Methods.POST, USER_FORGET_PDW);
  const { request: postResetPdn, response: postResetPdw } = useApi(Methods.POST, USER_RESET_PDW);

  const onSubmit = useCallback(() => {
    if (isPasswordSubmission) {
      postResetPdn({
        code,
        password: SHA256(password + PWD_SEED).toString(),
        copypassword: SHA256(copypassword + PWD_SEED).toString(),
      });
    } else {
      postForgetPdw({
        email,
      });
    }
  }, [email, code, password, copypassword]);

  useEffect(() => {
    if (postForgetRes?.success) {
      dispatch(setSnackbar(t('forgotten_password'), SnackbarSeverity.SUCCESS));
      dispatch(showSnackbar());
    }
  }, [postForgetRes, t]);

  useEffect(() => {
    if (postResetPdw?.success && postResetPdw.data) {
      dispatch(setSnackbar(t('success'), SnackbarSeverity.SUCCESS));
      dispatch(showSnackbar());

      navigate(SIGN_IN);
    }
  }, [postResetPdw, t]);

  const handleGoBack = useCallback(() => {
    navigate(SIGN_IN);
  }, []);

  const disabled = useMemo(
    () =>
      copypassword !== password ||
      (validator.isEmpty(password) &&
        !validator.isStrongPassword(password, {
          minLength: 10,
          minNumbers: 1,
          minLowercase: 1,
          minSymbols: 1,
          minUppercase: 1,
        })),
    [password, copypassword]
  );

  const renderFormContent = useMemo(() => {
    if (!isPasswordSubmission) {
      return (
        <>
          <MaterialInput
            key={'email'}
            label={t('common:email')}
            name={'email'}
            type={'text'}
            id={'email'}
            handleChange={setEmail}
            required
          />
          <div className={'form-group'}>
            <Button label={t('reset')} onClick={onSubmit} className={'btn btn-bold btn-block btn-primary'} />
          </div>
        </>
      );
    }
    return (
      <>
        <>
          <MaterialInput
            key={'password'}
            label={t('common:password')}
            name={'password'}
            type={'password'}
            id={'password'}
            hasError={
              !validator.isEmpty(password) &&
              !validator.isStrongPassword(password, {
                minLength: 10,
                minNumbers: 1,
                minLowercase: 1,
                minSymbols: 1,
                minUppercase: 1,
              })
                ? t('pwd_error')
                : ''
            }
            handleChange={setPassword}
            required
          />
          <MaterialInput
            key={'password_confirm'}
            label={t('confirmPassword')}
            name={'password_confirm'}
            type={'password'}
            id={'password_confirm'}
            hasError={
              !validator.isEmpty(copypassword) && password !== copypassword ? t('error.passwordsDontMatch') : ''
            }
            handleChange={setCopyPassword}
            required
          />
          <div className={'form-group'}>
            <Button
              label={t('reset')}
              onClick={onSubmit}
              className={'btn btn-bold btn-block btn-primary'}
              disabled={disabled}
            />
          </div>
        </>
      </>
    );
  }, [code, setPassword, setCopyPassword, onSubmit, password, copypassword]);

  return (
    <div
      className={'min-h-fullscreen bg-img center-vh p-20 pace-running'}
      style={{ backgroundImage: `url(${background})` }}
      data-overlay={7}>
      <div className={'card card-round card-shadowed px-50 py-30 w-400px mb-0'} style={{ maxWidth: '100%' }}>
        <h5 className={'text-uppercase'}>{t('title')}</h5>
        <br />
        <form
          className={'form-type-material needs-validation'}
          autoComplete={'off'}
          onSubmit={(e) => e.preventDefault()}>
          {renderFormContent}
          <div onClick={handleGoBack} className={'text-muted hover-primary fs-13 cursor-pointer'}>
            {t('common:goBack')}
          </div>
          <p className={'text-center text-muted fs-13 mt-20 mb-0'}>
            <img src={logoVelogikDigital} alt={'Vélogik'} />
          </p>
        </form>
      </div>
    </div>
  );
};

export default ForgottenPassword;
