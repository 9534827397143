import { useInstanceApi } from '../../../api/axios';
import { useRequest } from '../../../utils/request';

const URL = {
  fetchPDLDetails: '',
  putCalendarRules: 'pdl/calendarrules',
  replicateCalendarRules: 'pdl/calendarweekrules',
};

function usePdlDetailsApi() {
  const instance = useInstanceApi();

  const { request: fetchPDLDetails } = useRequest((queryParams) =>
    instance.get(URL.fetchPDLDetails, { params: queryParams })
  );

  const { request: putCalendarRules } = useRequest((data) => instance.post(URL.putCalendarRules, data));

  const { request: replicateCalendarRules } = useRequest((data) => instance.post(URL.replicateCalendarRules, data));

  return {
    fetchPDLDetails,
    putCalendarRules,
    replicateCalendarRules,
  };
}

export default usePdlDetailsApi;
