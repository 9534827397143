import React from 'react';
import styled from '@emotion/styled';
import Card from '../../../components/Core/Card/Card';
import { Grid } from '../InfosReservation.page';
import Button from '../../../components/Core/Button/Button';

function OtherActionsCard() {
  return (
    <Card>
      <Grid columns={'repeat(2, 1fr)'} align={'center'}>
        <Grid columns={'auto'} align={'center'} justify={'center'}>
          <Text>
            Plus aucune disponibilité ? Vous avez créé la réservation par erreur ? Impossible d’encaisser votre usager ?
          </Text>
          <Grid columns={'auto'} justify={'center'} align={'center'}>
            <Button label={'Annuler la reservation'} />
          </Grid>
        </Grid>
        <Grid columns={'1fr'}>
          <Text>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
            dolore
          </Text>

          <Grid columns={'auto'} justify={'center'} align={'center'}>
            <Button label={'Libérer la caution'} />
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}

const Text = styled.p`
  line-height: normal;
  margin: 0;
`;

export default OtherActionsCard;
