/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled/macro';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectCollAndDeliveryPointID } from '../../../auth/selectors/authSelectors';
import IconButton from '../../../components/Core/Button/IconButton';
import Card from '../../../components/Core/Card/Card';
import Datagrid, { RowItem } from '../../../components/Core/Datagrid/Datagrid';
import { GridColDefinition } from '../../../components/Core/Datagrid/gridCols';
import { GridDataState } from '../../../utils/request';
import { withTheme } from '../../Parameters/Deposit/List/DepositList';
import useInvoiceListApi from './useInvoiceListApi';

function useInvoiceListColumnDefinitions() {
  const { t } = useTranslation('contracts/invoiceList');

  return useMemo<GridColDefinition[]>(() => {
    return [
      {
        type: 'date',
        field: 'date_facture',
        headerName: t('dateInvoice'),
        sortable: true,
        format: 'DD/MM/YYYY',
      },
      {
        type: 'string',
        field: 'nomprenom',
        headerName: t('usagers'),
        sortable: true,
      },
      // TODO: make a special cell for price (type: 'price')
      {
        type: 'custom',
        field: 'total_ttc',
        headerName: t('amount'),
        sortable: true,
        renderCell: ({ row }) => {
          const hasSecurityDeposit = !!row.montant_garantie_ttc && parseFloat(row.montant_garantie_ttc) > 0;
          return (
            <RowItem>
              {row.total_ttc}
              {row.devise}
              {hasSecurityDeposit && (
                <>
                  {' '.concat(
                    t('has_security_deposit', {
                      securityDepositAmount: row.montant_garantie_ttc,
                      articleAmount: row.montant_article_ttc,
                      currency: row.devise,
                    })
                  )}
                </>
              )}
            </RowItem>
          );
        },
      },
      {
        type: 'string',
        field: 'operation',
        headerName: t('operation'),
        sortable: true,
      },
      {
        type: 'custom',
        field: '',
        headerName: '',
        renderCell: ({ row }) => {
          return (
            <RowItem>
              {row.date_anonymization ? (
                <span>
                  <i>{t('archived')}</i>
                </span>
              ) : (
                <IconButton as={'a'} target={'_blank'} href={row.media_url} icon={'UploadIcon'} />
              )}
            </RowItem>
          );
        },
      },
    ];
  }, [t]);
}

function InvoiceList() {
  const columnDefinitions = useInvoiceListColumnDefinitions();
  const { fetchInvoiceList } = useInvoiceListApi();
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const [invoiceList, setInvoiceList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataToForwardForGridData, setDataToForwardForGridData] = useState<GridDataState>({
    search: '',
    totalLines: 0,
    itemPerPage: 10,
    nbrPages: undefined,
    currentPage: 1,
    order: {
      column: 'date_facture',
      dir: 'desc',
    },
    filters: {},
  });

  const onMount = useMemo(
    () =>
      _.debounce(async (queryParams) => {
        const response = await fetchInvoiceList({ id_coll, ...queryParams });

        if (response && response.status === 200) {
          setInvoiceList(response.data.result);
          setDataToForwardForGridData((prevState) => ({
            ...prevState,
            totalLines: response.data.total,
            itemPerPage: response.data.itemPerPage,
            nbrPages: response.data.totalPage,
            currentPage: response.data.currentPage,
            order: response.data.order,
          }));
        }

        setLoading(false);
      }, 1000),
    [id_coll]
  );

  useEffect(() => {
    setLoading(true);
    onMount(dataToForwardForGridData);
  }, [
    onMount,
    dataToForwardForGridData.itemPerPage,
    dataToForwardForGridData.currentPage,
    dataToForwardForGridData.order?.dir,
    dataToForwardForGridData.order?.column,
    dataToForwardForGridData.filters,
  ]);

  return (
    <MainSection className={'reset'}>
      <Card>
        <Datagrid
          loading={loading}
          translationNamespace={'contracts/invoiceList'}
          onChangeFilterText={(search) =>
            setDataToForwardForGridData((prevState) => ({
              ...prevState,
              filters: { ...prevState.filters, filter: search as string },
            }))
          }
          onSort={(orderObject) => setDataToForwardForGridData((prevState) => ({ ...prevState, order: orderObject }))}
          handleChangePage={(newPage) =>
            setDataToForwardForGridData((prevState) => ({ ...prevState, currentPage: newPage }))
          }
          onChangeItemPerPage={(newValue) =>
            setDataToForwardForGridData((prevState) => ({ ...prevState, itemPerPage: newValue as number }))
          }
          rows={invoiceList}
          columns={columnDefinitions}
          {...(({ filters, ...propsToForward }) => propsToForward)(dataToForwardForGridData)}
        />
      </Card>
    </MainSection>
  );
}

const MainSection = styled.section`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export default withTheme(InvoiceList);
