/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { ComponentPropsWithoutRef, useCallback, useEffect } from 'react';
import { Controller, useForm, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectCollAndDeliveryPointID } from '../../../../auth/selectors/authSelectors';
import Button from '../../../../components/Core/Button/Button';
import ColorPicker from '../../../../components/Core/ColorPicker/ColorPicker';
import CheckboxField from '../../../../components/Core/Form/CheckboxField/CheckboxField';
import { TOAST_SEVERITY } from '../../../../components/Core/Toast/Toast';
import FormGroupCard from '../../../../components/FormGroupCard/FormGroupCard';
import { useSnackbar } from '../../../../context/SnackbarContext';
import useVisualApi from '../useVisualApi';

type ColorPickerControlledProps = ComponentPropsWithoutRef<typeof ColorPicker> &
  Pick<UseFormReturn, 'control'> & {
    name: string;
    label?: string;
  };
export function ColorPickerControlled(props: ColorPickerControlledProps) {
  const { label, control, name, ...propsToForward } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <ColorPicker label={label} value={value} onChange={onChange} {...propsToForward} />
      )}
    />
  );
}

type NavigationSectionProps = {
  defaultValues: Record<string, string | boolean>;
};

function NavigationSection(props: NavigationSectionProps) {
  const snackbar = useSnackbar();
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const { defaultValues } = props;
  const { t } = useTranslation(['admin/visual', 'form']);
  const { control, formState, handleSubmit, reset } = useForm({ defaultValues });
  const { postNavigationConfig } = useVisualApi();

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const onSubmit = useCallback(
    handleSubmit(async (data) => {
      //eslint-disable-next-line
      const response = await postNavigationConfig({ collId: id_coll, ...data } as any);
      if (response && response.status === 200) {
        snackbar?.setAlert({ message: t('form:global_update_success_message'), severity: TOAST_SEVERITY.success });
      }
    }),
    [handleSubmit, id_coll]
  );

  return (
    <FormGroupCard title={t('navigation_section_title')}>
      <form
        onSubmit={onSubmit}
        css={css`
          display: grid;
          align-items: center;
          grid-template-columns: repeat(4, 1fr) auto;
          gap: 16px;
        `}>
        <ColorPickerControlled control={control} name={'navbar-background'} label={t('background_color')} />
        <ColorPickerControlled control={control} name={'navbar-color'} label={t('text_color')} />
        <ColorPickerControlled control={control} name={'navbar-hover'} label={t('hover_text_color')} />
        <div>
          <span
            css={css`
              display: block;
              margin-bottom: 16px;
            `}>
            {t('logo')}
          </span>
          <Controller
            name={'use_alternative_logo'}
            control={control}
            render={({ field: { value, onChange } }) => (
              <CheckboxField
                checked={value as unknown as never}
                onChange={onChange}
                label={t('display_alternative_logo_checkbox_label')}
              />
            )}
          />
        </div>
        <Button type={'submit'} disabled={!formState.isDirty} label={t('update_cta')} />
      </form>
    </FormGroupCard>
  );
}

export default NavigationSection;
