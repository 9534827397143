/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import _ from 'lodash';
import React, { ComponentPropsWithoutRef, ForwardedRef, useState } from 'react';
import { FieldError as FieldErrorType } from 'react-hook-form';
import { getColor } from '../../utils/style';
import FieldError from './FieldError/FieldError';
import FormControl from './FormControl/FormControl';
import InputBase from './InputBase/InputBase';
import Label from './Label/Label';

const textFieldCss = {
  helper: css({
    display: 'block',
    marginTop: 4,
    marginBottom: 4,
    fontSize: 12,
  }),
};

type TextFieldProps = Omit<ComponentPropsWithoutRef<typeof InputBase>, 'error' | 'onChange'> & {
  label?: string;
  helper?: string;
  // TODO: replace with string ? and pass only message of FieldError ?
  error?: FieldErrorType;
} & Pick<ComponentPropsWithoutRef<typeof FormControl>, 'onChange'>;

function TextField(props: TextFieldProps, ref: ForwardedRef<HTMLInputElement>) {
  const { label, helper, error, className, required, type = 'text', defaultValue, onChange, ...inputProps } = props;
  const [id] = useState(_.uniqueId('input-'));

  return (
    <FormControl onChange={onChange} value={props.value} error={!!error} defaultValue={defaultValue}>
      <FieldSet className={className}>
        {label && (
          <Label required={required} htmlFor={id}>
            {label}
          </Label>
        )}
        <InputBase ref={ref} id={id} type={type} {...inputProps} />
        {helper && <span css={textFieldCss.helper}>{helper}</span>}
        {error && <FieldError>{error.message}</FieldError>}
      </FieldSet>
    </FormControl>
  );
}

const FieldSet = styled.fieldset`
  /*> div:hover {
    color: ${getColor('red.300')};
  }*/
`;

export default React.forwardRef<HTMLInputElement, TextFieldProps>(TextField);
