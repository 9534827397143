import { selectCollAndDeliveryPointID } from 'auth/selectors/authSelectors';
import Button from 'fragments/button/Button';
import Checkbox from 'fragments/checkbox/Checkbox';
import { setSnackbar, showSnackbar } from 'internal/actions/appActions';
import SnackbarSeverity from 'internal/constants/snackbar';
import useApi, { Methods } from 'internal/hooks/useApi';
// import { setSnackbar, showSnackbar } from 'internal/actions/appActions';
// import SnackbarSeverity from 'internal/constants/snackbar';
import useForm from 'internal/hooks/useForm';
import useRefresh from 'internal/hooks/useRefreshList';
import { RequestType } from 'internal/infrastructure/api';
import { toInteger } from 'lodash';
import { inOutCreationForm } from 'parameters/constants/forms';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

type Props = {
  typeArticle: string;
  article: string;
  refresh: (options?: RequestType | undefined) => void;
};

const ParametersInOutCreation = ({ refresh, typeArticle, article }: Props): ReactElement => {
  const { t } = useTranslation(['parameters/checkin', 'form']);
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const [isCommercialMandatory, setIsCommercialMandatory] = useState<boolean>(false);
  const [isPhotoUsed, setIsPhotoUsed] = useState<boolean>(false);
  const [isPhotoMandatory, setIsPhotoMandatory] = useState<boolean>(false);

  const { request: submitVerification, response: verificationResponse } = useApi(Methods.POST, 'checkin/admin');

  const dispatch = useDispatch();

  const [form, formState] = useForm(inOutCreationForm(), {
    verification_text: '',
  });

  const _refresh = useRefresh(refresh, 1000);

  const onSubmit = useCallback(() => {
    submitVerification({
      id_coll,
      article,
      type_article: typeArticle,
      label: formState.verification_text,
      is_mandatory: toInteger(isCommercialMandatory),
      has_media: toInteger(isPhotoUsed),
      is_media_mandatory: toInteger(isPhotoMandatory),
      is_active: 1,
      need_status: 1,
    });

    _refresh();
  }, [formState, id_coll, _refresh, article, isCommercialMandatory, isPhotoUsed, isPhotoMandatory]);

  useEffect(() => {
    if (verificationResponse?.success) {
      dispatch(setSnackbar(t('parameters.new_verification_success'), SnackbarSeverity.SUCCESS));
      dispatch(showSnackbar());
    }
  }, [verificationResponse]);

  const handleCommercialMandatory = () => {
    setIsCommercialMandatory((prevState) => !prevState);
  };

  const handlePhotoUsed = () => {
    setIsPhotoUsed((prevState) => !prevState);
  };

  const handlePhotoMandatory = () => {
    setIsPhotoMandatory((prevState) => !prevState);
  };

  return (
    <div className={'col-md-12 col-xl-8'}>
      <p className={'lead'}>{t('new_verification')}</p>
      {form}
      <div className={'col-md-12 col-lg-12 d-flex align-items-center'}>
        <Checkbox handleChange={handleCommercialMandatory} checked={isCommercialMandatory} />
        <span className={'ml-2 text-align-center'}>{t('commercial_response')}</span>
      </div>
      <div className={'col-md-12 col-lg-12 d-flex align-items-center'}>
        <Checkbox handleChange={handlePhotoUsed} checked={isPhotoUsed} />
        <span className={'ml-2 text-align-center'}>{t('photo_used')}</span>
      </div>
      <div className={'col-md-12 col-lg-12 d-flex align-items-center'}>
        <Checkbox handleChange={handlePhotoMandatory} checked={isPhotoMandatory} />
        <span className={'ml-2 text-align-center'}>{t('photo_mandatory')}</span>
      </div>
      <Button label={t('form:add')} className={'btn btn-w-xl btn-primary mt-25'} onClick={onSubmit} />
      <hr />
    </div>
  );
};

export default ParametersInOutCreation;
