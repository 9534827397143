import React, { PropsWithChildren } from 'react';
import useControlled from '../../../hook/useControlled';
import FormControlContext, { FormControlState } from './FormControlContext';

type FormControlProps = {
  error?: boolean;
  // onChange?: React.ChangeEventHandler<NativeFormControlElement>;
  onChange?: (newValue: unknown) => void;
  value?: unknown;
  defaultValue?: unknown;
};

function FormControl(props: PropsWithChildren<FormControlProps>) {
  const { children, error, onChange, value: incomingValue, defaultValue } = props;

  const [value, setValue] = useControlled({
    controlled: incomingValue,
    default: defaultValue,
    name: 'FormControl',
    state: 'value',
  });

  const handleChange = (newValue: unknown) => {
    setValue(newValue);
    onChange?.(newValue);
  };

  const childContext: FormControlState = {
    error,
    value: value ?? '',
    onChange: handleChange,
  };

  return <FormControlContext.Provider value={childContext}>{children}</FormControlContext.Provider>;
}

export default FormControl;
