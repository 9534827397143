import { StoreUserDetailsActionType } from 'users/types/actions';
import { UserDetailsType } from 'users/types/users';

export const STORE_USER_DETAILS = 'STORE_USER_DETAILS';

export const storeUserDetails = (userDetails: UserDetailsType): StoreUserDetailsActionType => ({
  type: STORE_USER_DETAILS,
  payload: {
    userDetails,
  },
});
