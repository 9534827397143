import React, { useEffect, useMemo } from 'react';
import {
  ActionFunction,
  json,
  LoaderFunction,
  NavLink,
  useFetcher,
  useLoaderData,
  useNavigate,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { QueryClient } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import _ from 'lodash';
import { useMainTitle } from '../../../../components/Layout/MainTitleContext';
import MainSection from '../../../../components/Core/MainSection/MainSection';
import Card from '../../../../components/Core/Card/Card';
import ButtonWithIcon from '../../../../components/Core/Button/ButtonWithIcon';
import store from '../../../../internal/store';
import { instance } from '../../../../api/user.api';
import useDisplayError from '../../../../hook/useDisplayError';
import { TOAST_SEVERITY } from '../../../../components/Core/Toast/Toast';
import { useSnackbar } from '../../../../context/SnackbarContext';
import { dataURLtoFile } from '../../../../parameters/utils/file';
import { FreeParkForm } from './NewFreeParkPage';
import { freeParkListQuery } from './FreeParkListPage';

/*const mock = new MockAdapter(instance, { onNoMatch: 'passthrough' });
mock.onGet('bike/3').reply(200, { name: 'test', description: 'test' });*/

export const editFreeParkAction: (queryClient: QueryClient) => ActionFunction =
  (queryClient) =>
  async ({ request }) => {
    const collId = store.getState().authState.collectivity;
    const { img, ...data } = await request.json();
    let response = null;

    try {
      response = await instance.post('consignepark/edit', { ...data, idColl: collId });
      if (response.status === 200) {
        await queryClient.invalidateQueries(['consignepark/list']);
        if (img) {
          const file = await dataURLtoFile(img.src, 'img.png');
          await instance.put('consignepark/media', file, {
            params: { idColl: collId, idArticle: response.data.idArticle },
          });
        }
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        console.error(e);
        return json(e.response?.data);
      }
    }

    return response;
  };

export const editFreeParkLoader: (queryClient: QueryClient) => LoaderFunction =
  (queryClient) =>
  async ({ params }) => {
    const collId = store.getState().authState.collectivity;

    const listFreeParkResponse = await queryClient.ensureQueryData({
      ...freeParkListQuery({ idColl: collId }),
    });

    return json({ collId, listFreeParkResponse, freeParkId: Number(params.id) });
  };

function EditFreeParkPage() {
  const fetcher = useFetcher();
  const { t } = useTranslation(['parameters/products/editFreePark', 'form', 'common']);
  useDisplayError(fetcher.data);
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  // const params = useParams();
  const { listFreeParkResponse, freeParkId } = useLoaderData() as {
    listFreeParkResponse?: AxiosResponse;
    collId: number;
    freeParkId: number;
  };

  const freeParkSelected = useMemo(() => {
    return _.find(listFreeParkResponse?.data, { id: freeParkId });
  }, [listFreeParkResponse, freeParkId]);

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      img: { src: freeParkSelected?.mediaUrl },
      name: freeParkSelected?.name,
      description: freeParkSelected?.description,
    },
  });
  useMainTitle(freeParkSelected?.name || '');

  const onSubmit = (data: FieldValues) => {
    const copyData = _.cloneDeep(data);
    if (copyData.img.src === freeParkSelected?.mediaUrl) delete copyData.img;

    fetcher.submit({ ...copyData, id: freeParkId }, { method: 'post', encType: 'application/json' });
  };

  useEffect(() => {
    if (fetcher.data && fetcher.data.status === 200) {
      snackbar?.setAlert({
        message: t('successMessage'),
        severity: TOAST_SEVERITY.success,
      });
      navigate('/parametres/articles/consigne-libre');
    }
  }, [fetcher.data, t]);

  return (
    <MainSection className={'reset'}>
      <Card
        content={
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <p>{t('form:all_required')}</p>
              <FreeParkForm />
              <div className={'row justify-between mt-4'}>
                <ButtonWithIcon
                  as={NavLink}
                  to={'/parametres/articles/consigne-libre'}
                  icon={'CrossWithoutCircle'}
                  color={'secondary'}>
                  {t('common:cancel')}
                </ButtonWithIcon>
                <ButtonWithIcon icon={'CheckWithoutCircle'} type={'submit'}>
                  {t('common:edit')}
                </ButtonWithIcon>
              </div>
            </form>

            <DevTool control={methods.control} />
          </FormProvider>
        }
      />
    </MainSection>
  );
}

export default EditFreeParkPage;
