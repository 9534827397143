import { instance } from '../user.api';

const ENDPOINTS = {
  getPagesConfig: 'param/pages',
};

type GetPagesConfigQueryParams = {
  id_coll: number;
};

export type GetPagesConfigResponse = {
  langs: string[];
} & {
  [page: string]: {
    [lang: string]: {
      title: string;
      subtitle: string;
      banner: string | null;
    };
  };
};

export function getPagesConfig(queryParams: GetPagesConfigQueryParams) {
  return instance.get<GetPagesConfigResponse>(ENDPOINTS.getPagesConfig, { params: queryParams });
}

export type PutPagesConfigBodyForm = {
  id_coll: number;
  wordings: {
    [page: string]: {
      [lang: string]: {
        title: string;
        subtitle: string;
        banner?: string;
      };
    };
  };
};

export function putPagesConfig(bodyForm: PutPagesConfigBodyForm) {
  return instance.post(ENDPOINTS.getPagesConfig, bodyForm);
}
