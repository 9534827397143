import { useInstanceApi } from '../../../api/axios';
import { ParametersWaitingListType } from '../../../parameters/types/collectivity';
import { useRequest } from '../../../utils/request';

const URL = {
  fetchWaitingListParameters: 'param/listeattente',
  putWaitingListParameters: 'param/listeattente',
};

function useWaitingListParametersApi() {
  const instance = useInstanceApi();

  const { request: fetchWaitingListParameters } = useRequest<ParametersWaitingListType>(
    (queryParams: { id_coll: number }) => instance.get(URL.fetchWaitingListParameters, { params: queryParams })
  );

  const { request: putWaitingListParameters } = useRequest((data) => instance.post(URL.putWaitingListParameters, data));

  return {
    fetchWaitingListParameters,
    putWaitingListParameters,
  };
}

export default useWaitingListParametersApi;
