import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  selectCollAndDeliveryPointID,
  selectCollectivityList,
  selectDeliveryPointList,
} from '../../auth/selectors/authSelectors';

function useCurrentCollectivityInfos() {
  const { id_coll, id_pdl } = useSelector(selectCollAndDeliveryPointID);
  const collectivityList = useSelector(selectCollectivityList);
  const pdlList = useSelector(selectDeliveryPointList);

  const title = useMemo(
    () => collectivityList?.find((coll) => coll.id_coll === id_coll)?.nom_coll,
    [id_coll, collectivityList]
  );

  const subTitle = useMemo(() => {
    return pdlList?.find((pdl) => pdl.id_pdl === id_pdl)?.nom_pdl;
  }, [pdlList, id_pdl]);

  return {
    title,
    subTitle,
  };
}

export default useCurrentCollectivityInfos;
