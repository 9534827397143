/** @jsxImportSource @emotion/react */
import React, { useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { ChromePicker, ColorChangeHandler } from 'react-color';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import rgbHex from 'rgb-hex';
import { getColor } from '../../../utils/style';
import Label from '../Label/Label';
import useClickOutside from '../../../internal/hooks/useClickOutside';
import useControlled from '../../../hook/useControlled';

type ColorPickerProps = {
  label?: string;
  value?: string;
  onChange?: (newColor: string) => void;
  disabled?: boolean;
  disableAlpha?: boolean;
};

function ColorPicker(props: ColorPickerProps) {
  const { label, value, disableAlpha = true, onChange, disabled = false } = props;
  const [color, setColor] = useControlled({
    controlled: value,
    default: '',
    name: 'color',
    state: 'ColorPicker',
  });
  const containerRef = useRef(null);
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      {
        name: 'offset',
        options: {
          offset: [0, 20],
        },
      },
    ],
  });
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  useClickOutside(containerRef, () => setDisplayColorPicker(false));

  const onColorChange: ColorChangeHandler = (color) => {
    const hexColor = `#${rgbHex(color.rgb.r, color.rgb.g, color.rgb.b)}`;
    if (!disableAlpha) {
      const hexValueForAlpha = Math.round((color.rgb.a || 0) * 255)
        .toString(16)
        .padStart(2)
        .replace(' ', '0');

      onChange?.(color.rgb.a === 0 ? hexColor.concat('00') : hexColor.concat(hexValueForAlpha));
      setColor(color.rgb.a === 0 ? hexColor.concat('00') : hexColor.concat(hexValueForAlpha));
    } else {
      onChange?.(color.hex);
      setColor(color.hex);
    }
  };

  return (
    <div>
      <div
        css={css`
          position: relative;
          display: inline;
        `}
        ref={containerRef}>
        <div
          css={css`
            display: inline-flex;
            flex-direction: column;
            width: 100%;
            gap: 8px;
          `}
          ref={setReferenceElement}>
          <Label disabled={disabled}>{label}</Label>
          <ColorVisualizerButton
            type={'button'}
            disabled={disabled}
            color={color}
            onClick={() => setDisplayColorPicker((prev) => !prev)}
          />
        </div>

        {displayColorPicker && (
          <div ref={setPopperElement} style={{ ...styles.popper, zIndex: 200 }} {...attributes.popper}>
            <ChromePicker color={color} disableAlpha={disableAlpha} onChange={onColorChange} />
            <div ref={setArrowElement} style={styles.arrow} />
          </div>
        )}
      </div>
    </div>
  );
}

const ColorVisualizerButton = styled.button<{ color?: string }>`
  max-width: 200px;
  height: 32px;
  outline: none;
  border: 1px solid ${getColor('gray.200')};
  border-radius: 4px;
  background-color: ${(props) => props.color};
  opacity: ${(props) => (props.disabled ? 0.8 : 1)};

  &:focus {
    outline: none;
  }
`;

export default ColorPicker;
