import React, { ReactElement, useEffect, useRef } from 'react';
import Lottie from 'lottie-web';
import animationData from 'fragments/loader/loader.json';

const Loader = (): ReactElement => {
  const animationRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (animationRef.current) {
      Lottie.loadAnimation({
        container: animationRef.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData,
      });
    }
  }, [animationRef]);

  return (
    <div className={'d-flex justify-content-center'}>
      <div id={'animation'} ref={animationRef} />
    </div>
  );
};

export default Loader;
