import { TFunction } from 'i18next';
import Joi from 'joi';
import { FieldObject } from '../hook/useBuildForm';
import { getAmountExcludingTaxes, getAmountIncludingTaxes } from '../pages/Parameters/Deposit/List/utils';

const amountForm: (t: TFunction) => FieldObject[] = (t) => [
  {
    name: 'montant_ht',
    label: t('amount_excluding_taxes'),
    placeholder: t('amount_excluding_taxes'),
    type: 'text',
    columnStart: '1',
    onChange: (newValue, getValues, setValue) =>
      setValue?.('montant_ttc', getAmountIncludingTaxes(Number(newValue), getValues?.('taux_tva'))),
  },
  {
    type: 'select',
    name: 'taux_tva',
    label: t('vat_rate'),
    placeholder: t('vat_rate'),
    defaultValue: 5.5,
    onChange: (newValue, getValues, setValue) => {
      const amountExcludingTaxes = Number(getValues?.('montant_ht'));
      amountExcludingTaxes &&
        setValue?.('montant_ttc', getAmountIncludingTaxes(amountExcludingTaxes, getValues?.('taux_tva')));
    },
    options: [
      {
        value: 5.5,
        label: '5.5%',
      },
      { value: 10, label: '10%' },
      { value: 20, label: '20%' },
    ],
    schema: Joi.number(),
  },
  {
    name: 'montant_ttc',
    label: t('amount_including_taxes'),
    placeholder: t('amount_including_taxes'),
    type: 'text',
    onChange: (newValue, getValues, setValue) =>
      setValue?.('montant_ht', getAmountExcludingTaxes(Number(newValue), getValues?.('taux_tva'))),
  },
];

export default amountForm;
