import React, { ChangeEventHandler, useCallback, useMemo } from 'react';
import { ScrollRestoration, useSearchParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { FetchQueryOptions, useQuery, WithRequired } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { instance } from '../../api/user.api';
import Card from '../../components/Core/Card/Card';
import StatBlock, { StatContent } from '../../components/Core/StatBlock/StatBlock';
import StackedBar, { SimpleStackedBarData } from '../../components/charts/StackedBar/StackedBar';
import { selectCollAndDeliveryPointID } from '../../auth/selectors/authSelectors';
import styles from './SupplyAndDemandSection.module.scss';
import FiltersInfos, { contentInfoMap } from './FiltersInfos/FiltersInfos';
import { ArticleTypeListResponse } from './FleetSection';
import { backgroundColors } from './AvailableStackedBarChart';
import RadioButton from './RadioButton/RadioButton';
import { articleTypeListQuery } from './Indicator.page';

export const supplyQuery: (
  queryParams: unknown
) => WithRequired<FetchQueryOptions<AxiosResponse>, 'queryFn' | 'queryKey'> = (queryParams) => ({
  queryKey: ['indicator/supplyAndDemand', queryParams],
  queryFn: ({ queryKey }) => {
    const [url, queryParams] = queryKey as [string, never];
    return instance.get(url, { params: queryParams });
  },
  retryDelay: 4000_000,
  retry: false,
});

type SupplyAndDemandResponse = {
  nbRentInProgress: number;
  nbRentOnStandBy: number;
  usage: {
    moyNbRentByUser: string;
    moyDayDurationInByRent: string;
  };
  txRenewal: string;
  repartitionInProgress: {
    type: Record<string, number>;
    tarif: Record<string, number>;
    duree: Record<string, number>;
  };
  repartitionOnStandBy: {
    type: Record<string, number>;
    tarif: Record<string, number>;
    duree: Record<string, number>;
  };
};

function SupplyAndDemandSection() {
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const { t } = useTranslation(['stats/indicator']);
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: supplyResponse } = useQuery<AxiosResponse<SupplyAndDemandResponse>>({
    ...supplyQuery({
      type: searchParams.get('articles') || 'bike',
      idColl: id_coll,
      pdl: searchParams.get('pdl') !== 'null' ? searchParams.get('pdl') : undefined,
    }),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });

  const { data: articleTypeListResponse } = useQuery<AxiosResponse<ArticleTypeListResponse>>({
    ...articleTypeListQuery({
      article: searchParams.get('articles') || 'bike',
      idColl: id_coll,
    }),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });

  const repartitionOnStandByData: SimpleStackedBarData = useMemo(() => {
    //eslint-disable-next-line
    const typeFilter: 'type' | 'duree' | 'tarif' = (searchParams.get('type') || 'type') as any;
    let array = [];

    if (typeFilter === 'type') {
      array = articleTypeListResponse?.data
        .map((articleType, index) => {
          const articleTypeDetails =
            supplyResponse?.data.repartitionOnStandBy[(searchParams.get('type') || 'type') as 'type'][articleType];
          return {
            label: articleType,
            data: articleTypeDetails ? articleTypeDetails : 0,
            backgroundColor: !articleTypeDetails || articleTypeDetails === 0 ? undefined : backgroundColors[index],
          };
        })
        //eslint-disable-next-line
        .sort((a, b) => (a.data < b.data ? 1 : -1)) as any;
    } else {
      array = _.reduce(
        supplyResponse?.data.repartitionOnStandBy[typeFilter],
        (prev, curr, key) => {
          prev.push({
            label: key,
            data: curr,
            backgroundColor: _.identity(curr) ? backgroundColors[prev.length + 1] : undefined,
          });
          return prev;
        },
        [] as { label: string; data: number; backgroundColor?: string }[]
      );
    }

    return _.every(array, { data: 0 }) ? _.map(array, (dataset) => _.assign(dataset, { data: Number.NaN })) : array;
  }, [articleTypeListResponse, supplyResponse, searchParams]);

  const repartitionInProgressData: SimpleStackedBarData = useMemo(() => {
    //eslint-disable-next-line
    const typeFilter: 'type' | 'duree' | 'tarif' = (searchParams.get('type') || 'type') as any;
    let array = [];

    if (typeFilter === 'type') {
      array = articleTypeListResponse?.data
        .map((articleType, index) => {
          const articleTypeDetails =
            supplyResponse?.data.repartitionInProgress[(searchParams.get('type') || 'type') as 'type'][articleType];
          return {
            label: articleType,
            data: articleTypeDetails ? articleTypeDetails : 0,
            backgroundColor: !articleTypeDetails || articleTypeDetails === 0 ? undefined : backgroundColors[index],
          };
        })
        //eslint-disable-next-line
        .sort((a, b) => (a.data < b.data ? 1 : -1)) as any;
    } else {
      array = _.reduce(
        supplyResponse?.data.repartitionInProgress[typeFilter],
        (prev, curr, key) => {
          prev.push({
            label: key,
            data: curr,
            backgroundColor: _.identity(curr) ? backgroundColors[prev.length + 1] : undefined,
          });
          return prev;
        },
        [] as { label: string; data: number; backgroundColor?: string }[]
      );
    }

    return _.every(array, { data: 0 }) ? _.map(array, (dataset) => _.assign(dataset, { data: Number.NaN })) : array;
  }, [articleTypeListResponse, supplyResponse, searchParams]);

  const handleTypeDataChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setSearchParams((prev) => {
        return { ...Object.fromEntries(prev), type: e.target.value };
      });
    },
    [setSearchParams]
  );

  return (
    <Card
      title={(baseStyle) => (
        <div className={[baseStyle, styles.Title].join(' ')}>
          <h3>{t('supplyAndDemand.title')}</h3>
          <div>
            <span>{t('dateTitle', { date: dayjs().format('DD/MM/YYYY, HH[h]mm') })}</span>
          </div>
        </div>
      )}
      content={
        <div>
          <ScrollRestoration getKey={(location) => location.pathname} />
          <div className={'row gap-8 items-center mb-4'}>
            {['type', 'duree', 'tarif'].map((value) => (
              <RadioButton
                onChange={handleTypeDataChange}
                value={value}
                checked={searchParams.get('type') ? searchParams.get('type') === value : value === 'type'}
                name={'type'}
                label={t(value)}
              />
            ))}
          </div>
          <div className={styles.FirstSection}>
            <StatBlock
              title={t('supplyAndDemand.rentalInProgress.title')}
              nbr={supplyResponse?.data.nbRentInProgress}
              subtitle={t('supplyAndDemand.rentalInProgress.subtitle')}
            />
            <div>
              <FiltersInfos />
              <div>
                <StackedBar
                  optionArgs={{
                    title: t(`chartTitles.${searchParams.get('type') || 'type'}`, {
                      // eslint-disable-next-line
                      article: `$t(common:${contentInfoMap.get(searchParams.get('articles') || 'bike')!.tKey})`,
                    }),
                  }}
                  height={250}
                  data={repartitionInProgressData}
                />
              </div>
            </div>
          </div>

          <div className={styles.SecondSection}>
            <StatBlock title={t('supplyAndDemand.rentalInWaitingList')} nbr={supplyResponse?.data.nbRentOnStandBy} />

            <div>
              <FiltersInfos />
              <div>
                <StackedBar
                  optionArgs={{
                    title: t(`chartTitles.${searchParams.get('type') || 'type'}`, {
                      // eslint-disable-next-line
                      article: `$t(common:${contentInfoMap.get(searchParams.get('articles') || 'bike')!.tKey})`,
                    }),
                  }}
                  height={250}
                  data={repartitionOnStandByData}
                />
              </div>
            </div>
          </div>

          <div className={styles.ThirdSection}>
            <StatBlock
              small
              title={t('supplyAndDemand.renewalPercent.title')}
              nbr={supplyResponse?.data.txRenewal}
              subtitle={t('supplyAndDemand.renewalPercent.subtitle')}
            />

            <StatBlock className={styles.UsageBlock}>
              <StatContent
                small
                title={t('supplyAndDemand.usagePercent.title')}
                nbr={t('days', { days: supplyResponse?.data.usage.moyDayDurationInByRent })}
                subtitle={t('supplyAndDemand.usagePercent.subtitle')}
              />
              <StatContent
                small
                nbr={supplyResponse?.data.usage.moyNbRentByUser}
                subtitle={t('supplyAndDemand.moyNbrRental')}
              />
            </StatBlock>
          </div>
        </div>
      }
    />
  );
}

export default SupplyAndDemandSection;
