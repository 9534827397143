import { useInstanceApi } from '../../../api/axios';
import { GridDataState, useRequest } from '../../../utils/request';

const URL = {
  fetchContractList: 'cf/contrats',
  fetchContractListExport: 'cf/export',
};

function useContractListApi() {
  const instance = useInstanceApi();

  const { request: fetchContractList } = useRequest((queryParams: GridDataState & { id_coll: number }) =>
    instance.get(URL.fetchContractList, {
      params: {
        id_coll: queryParams.id_coll,
        p: queryParams.currentPage,
        ipp: queryParams.itemPerPage,
        'order[dir]': queryParams.order?.dir,
        'order[column]': queryParams.order?.column,
        ...queryParams.filters,
      },
    })
  );

  const { request: fetchContractListExport } = useRequest(
    ({ csvName, ...queryParams }: { id_coll: number; token?: string | null; csvName: string }) =>
      instance.get(URL.fetchContractListExport, {
        params: {
          t: 'c',
          ...queryParams,
        },
        headers: {
          'Content-Type': 'application/csv',
          'Content-Disposition': `attachment; filename=${csvName}`,
        },
      })
  );

  return {
    fetchContractList,
    fetchContractListExport,
  };
}

export default useContractListApi;
