import React, { ComponentPropsWithoutRef } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartData, TooltipItem, ChartOptions } from 'chart.js';
import { Doughnut as DoughnutBase } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const options: ChartOptions<'doughnut'> = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    tooltip: {
      position: 'nearest',
      mode: 'point',
      displayColors: false,
      backgroundColor: '#17394d',
      // mode: 'index',
      cornerRadius: 8,
      callbacks: {
        title(tooltipItems: TooltipItem<'doughnut'>[]): string | string[] | void {
          return tooltipItems.map((tooltipItem) => {
            return `${tooltipItem.label}`;
          });
        },
        label: (tooltipItem: TooltipItem<'doughnut'>): string | string[] | void => {
          return [`Total                                 ${tooltipItem.dataset.data[0]}`];
        },
      },
    },
    legend: { display: false },
  },
};

const createData: (labels: string[], data: number[]) => ChartData<'doughnut'> = (labels, data) => ({
  labels,
  datasets: [
    {
      data,
      backgroundColor: ['#0081c9', '#7149c6', '#cc79ce', '#ff7e67', '#f39233', '#ffdf31', '#03c988'],
      // backgroundColor: ['#ff7e67', '#e893cf', '#03c988'],
      borderWidth: 0,
    },
  ],
});

function Doughnut(
  props: { labels: string[]; data: number[] } & Omit<Partial<ComponentPropsWithoutRef<typeof DoughnutBase>>, 'data'>
) {
  const { labels, data, ...propsToForward } = props;
  return <DoughnutBase {...propsToForward} data={createData(labels, data)} options={options} />;
}

export default Doughnut;
