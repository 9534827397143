import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { getColor } from '../../../utils/style';
import IconButton from '../Button/IconButton';
import { GridColDefinition } from './gridCols';

type OrderObject = {
  dir: string;
  column: string;
};

type HeaderDataGridProps = {
  onSort?: (orderObject: { dir: string; column: string }) => void;
  columns: GridColDefinition[];
  colsTemplate: string;
  order?: OrderObject;
  noBorderTop?: boolean;
};

function HeaderDataGrid(props: HeaderDataGridProps) {
  const { onSort, columns, colsTemplate, order, noBorderTop = false } = props;

  const handleSort = useCallback(
    (field: string) => () => {
      onSort?.({
        dir: order?.column === field ? (order.dir === 'asc' ? 'desc' : 'asc') : 'asc',
        column: field,
      });
    },
    [order, onSort]
  );

  return (
    <HeaderRow
      key={'header'}
      className={'datagrid-cols-header-row'}
      index={0}
      colsTemplate={colsTemplate}
      noBorderTop={noBorderTop}>
      {columns.map((columnDef) => {
        const { sortable = false, headerName, field } = columnDef;

        return (
          <HeaderColumn key={field}>
            <span>{headerName}</span>
            {sortable && (
              <IconButton
                onClick={handleSort(field)}
                icon={order?.column === field && order?.dir === 'desc' ? 'DESCSortIcon' : 'ASCSortIcon'}
              />
            )}
          </HeaderColumn>
        );
      })}
    </HeaderRow>
  );
}

const BaseRow = styled.div<{ colsTemplate: string | number; index: number }>((props) => ({
  display: 'grid',
  gridTemplateColumns: props.colsTemplate,
  gap: 8,
  paddingLeft: 16,
  paddingRight: 16,
  paddingTop: 16,
  paddingBottom: 8,
  backgroundColor: props.index % 2 !== 0 ? getColor('gray.100')(props) : 'transparent',
}));

const HeaderRow = styled(BaseRow)<{ noBorderTop: boolean }>`
  border-top: 1px solid ${(props) => (props.noBorderTop ? 'transparent' : getColor('gray.200')(props))};
`;

const HeaderColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  color: ${getColor('gray.400')};
  font-weight: bold;
`;

export default HeaderDataGrid;
