import React, { PropsWithChildren, useMemo } from 'react';
import styles from './tag.module.scss';

export type TagTypes = 'secondary' | 'primary' | 'success' | 'error' | 'plain-primary' | 'plain-success';

type TagProps = {
  type: TagTypes;
};

function Tag(props: PropsWithChildren<TagProps>) {
  const { type, children } = props;

  const classNameByType = useMemo(() => {
    switch (type) {
      case 'plain-primary':
        return styles.TagPlainPrimary;
      case 'secondary':
        return styles.TagYellow;
      case 'success':
        return styles.TagSuccess;
      case 'error':
        return styles.TagError;
      case 'primary':
      default:
        return styles.TagPrimary;
    }
  }, [type]);

  return <span className={[styles.Tag, classNameByType].join(' ')}>{children}</span>;
}

export default Tag;
