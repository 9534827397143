import React from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { generatePath, NavLink, Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { selectCollAndDeliveryPointID } from '../../../auth/selectors/authSelectors';
import { instance } from '../../../api/user.api';
import { Tab, Tabs, TabsList } from '../../../components/Core/Tabs/Tabs';
import { TabComponent } from '../../Reservation/Reservation.layout';

function ThemeLayout() {
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const { t } = useTranslation(['admin/visual']);

  const { data: themeConfigResponse } = useQuery(
    ['display/all', { collId: id_coll }],
    ({ queryKey }) => {
      const [, queryParams] = queryKey;
      return instance.get('display/all', { params: queryParams });
    },
    { refetchOnWindowFocus: false, staleTime: 400_000, keepPreviousData: true }
  );

  return (
    <div className={'reset'}>
      <div>
        <Tabs>
          <TabsList>
            <Tab
              component={({ children, ...otherProps }: TabComponent) => (
                <NavLink {...otherProps} to={generatePath('.')} end>
                  {children}
                </NavLink>
              )}>
              {t('layout.visual')}
            </Tab>
            <Tab
              component={({ children, ...otherProps }: TabComponent) => (
                <NavLink {...otherProps} to={generatePath('colors')} end>
                  {children}
                </NavLink>
              )}>
              {t('layout.colors')}
            </Tab>
          </TabsList>
        </Tabs>
      </div>

      <Outlet context={themeConfigResponse} />
    </div>
  );
}

export default ThemeLayout;
