import { ThemeProvider } from '@emotion/react';
import { Alert, Snackbar } from '@mui/material';
import { selectSessionToken } from 'auth/selectors/authSelectors';
import axios from 'axios';
// import ScrollToTop from 'fragments/scrollToTop/ScrollToTop';
import { hideSnackbar } from 'internal/actions/appActions';
import { selectLocale } from 'internal/selectors/localeSelectors';
import { SelectSnackbarState } from 'internal/selectors/snackbarSelectors';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
// import Router from 'internal/components/Router';
import NewRouter from 'router/Router';
import { Api } from '../../api/axios';
import { setApiToken } from '../../api/user.api';
import { saveUser } from '../../auth/actions/authActions';
import { TOAST_SEVERITY } from '../../components/Core/Toast/Toast';
import { useSnackbar } from '../../context/SnackbarContext';
import container from '../../ioc/ioc';
import { InversifyProvider } from '../../ioc/ioc.react';
import { SERVICE_KEYS } from '../../ioc/keys';
import theme from '../../theme';

function OldSnackbar() {
  const { snackbar, snackbarOpened } = useSelector(SelectSnackbarState);
  const dispatch = useDispatch();

  const onClose = useCallback(() => {
    dispatch(hideSnackbar());
  }, []);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={snackbarOpened}
      autoHideDuration={6000}
      onClose={onClose}>
      <Alert severity={snackbar.severity}>{snackbar.content}</Alert>
    </Snackbar>
  );
}

function App() {
  const { t, i18n } = useTranslation('errors');
  const snackbarCustom = useSnackbar();
  const token = useSelector(selectSessionToken);
  const locale = useSelector(selectLocale);
  const api = container.get<Api>(SERVICE_KEYS.API_INSTANCE);
  const [ready, setReady] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setReady(false);
    if (token) {
      api.setAuthToken(token);
    }
  }, [token]);

  useEffect(() => {
    setApiToken(token).then(() => setReady(true));
  }, [token]);

  useEffect(() => {
    if (locale && locale !== i18n.language) {
      i18n.changeLanguage(locale);
    }
  }, []);

  const queryClient = useMemo(() => {
    return new QueryClient();
  }, []);

  useEffect(() => {
    queryClient.setDefaultOptions({
      queries: {
        retry: 0,
        useErrorBoundary: false,
        enabled: ready,
        suspense: true,
        onError: (error) => {
          console.error(error);
          if (axios.isAxiosError(error)) {
            snackbarCustom?.setAlert({
              message: t('errors:'.concat(error.response?.data.code.replace('error.', ''))),
              severity: TOAST_SEVERITY.error,
            });

            if (error.response?.status === 403) {
              dispatch(saveUser(null));
            }
          }
        },
      },
      mutations: {
        onError: (error) => {
          console.error(error);
          if (axios.isAxiosError(error)) {
            snackbarCustom?.setAlert({
              message: error.response?.data?.code
                ? t('errors:'.concat(error.response?.data.code.replace('error.', '')))
                : t('errors:unknownError'),
              severity: TOAST_SEVERITY.error,
            });

            if (error.response?.status === 403) {
              dispatch(saveUser(null));
            }
          }
        },
      },
    });
  }, [ready, t]);

  return ready ? (
    /*<QueryErrorResetBoundary>
        {({ reset }) => (
          <ErrorBoundary
            fallbackRender={({ error, resetErrorBoundary }) => (
              <div>
                There was an error! <Button onClick={() => resetErrorBoundary()}>Try again</Button>
                <pre style={{ whiteSpace: 'normal' }}>{error.message}</pre>
              </div>
            )}
            onReset={reset}>
            <>*/
    <InversifyProvider container={container}>
      {/*<QueryParamProvider adapter={ReactRouter6Adapter}>*/}
      <ThemeProvider theme={theme}>
        {/*<ScrollToTop />*/}
        <QueryClientProvider client={queryClient}>
          <NewRouter />
        </QueryClientProvider>
        <OldSnackbar />
      </ThemeProvider>
      {/*</QueryParamProvider>*/}
    </InversifyProvider>
  ) : null;
}

export default App;
