import Select, { ActionMeta } from 'react-select';
import { ComponentPropsWithoutRef } from 'react';
import useFormControlContext from '../FormControl/useFormControlContext';
import { Option } from '../../../hook/useBuildForm';

export type UseSelectParameters = {
  error?: boolean;
  value?: unknown;
  onChange?: ComponentPropsWithoutRef<typeof Select>['onChange'];
};

type FormControlSelectState = {
  error?: boolean;
  value?: unknown;
  onChange?: (newValue: unknown) => void;
};

function useSelect(params: UseSelectParameters) {
  const { value: valueProp, onChange: onChangePropEventHandler, error: errorProp = false } = params;
  const formControlContext = useFormControlContext<FormControlSelectState>();

  let value: unknown;
  let error: boolean;
  if (formControlContext) {
    value = formControlContext.value;
    error = formControlContext.error ?? false;
  } else {
    value = valueProp;
    error = errorProp;
  }

  const handleChange = (newValue: unknown, actionMeta: ActionMeta<unknown>) => {
    formControlContext?.onChange?.(newValue);

    onChangePropEventHandler?.(newValue, actionMeta);
  };
  return {
    error,
    value: value as Option,
    onChange: handleChange,
  };
}

export default useSelect;
