import { useInstanceApi } from '../../../api/axios';
import { useRequest } from '../../../utils/request';

const URL = {
  fetchRenewalParameters: 'param/renouvellement',
  putRenewalParameters: 'param/renouvellement',
};

function useRenewalParametersApi() {
  const instance = useInstanceApi();

  const { request: fetchRenewalParameters } = useRequest((queryParams) =>
    instance.get(URL.fetchRenewalParameters, { params: queryParams })
  );

  const { request: putRenewalParameters } = useRequest((data) => instance.post(URL.putRenewalParameters, data));

  return {
    fetchRenewalParameters,
    putRenewalParameters,
  };
}

export default useRenewalParametersApi;
