/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import axios from 'axios';
import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NumberParam, useQueryParam } from 'use-query-params';
import { selectCollAndDeliveryPointID } from '../../../auth/selectors/authSelectors';
import ButtonWithIcon from '../../../components/Core/Button/ButtonWithIcon';
import Card from '../../../components/Core/Card/Card';
import MainSection from '../../../components/Core/MainSection/MainSection';
import { TOAST_SEVERITY } from '../../../components/Core/Toast/Toast';
import FormGroupCard from '../../../components/FormGroupCard/FormGroupCard';
import { useSnackbar } from '../../../context/SnackbarContext';
import useBuildForm from '../../../hook/useBuildForm';
import { useInjection } from '../../../ioc/ioc.react';
import { SERVICE_KEYS } from '../../../ioc/keys';
import { ParameterRepository, PDLDetailsRaw } from '../../../repository/parameter.repository';
import { withTheme } from '../Deposit/List/DepositList';
import PDLScheduleCard from './components/PDLScheduleCard';
import useGetPDLUpdateFormObject from './pdlUpdateForm';

export type PDLDetails = {
  nom_pdl: string;
  addr_pdl: string;
  cp_pdl: string;
  ville_pdl: string;
  delai_reservation: string;
  limit_resa: string;
  max_delay_reservation: string;
  pas_reservation: string;
  return_slot_interval: string;
  is_visible: boolean;
  return_delay: string;
  creneau_unique: boolean;
};

function transformPdlDetailsRawToPdlDetailsForm({
  nom,
  cp,
  adresse,
  ville,
  delai_reservation,
  pas_reservation,
  return_slot_interval,
  return_delay,
  limit_resa,
  is_visible,
  creneau_unique,
  max_delay_reservation,
}: PDLDetailsRaw): PDLDetails {
  return {
    nom_pdl: nom,
    addr_pdl: adresse,
    cp_pdl: cp,
    ville_pdl: ville,
    delai_reservation: delai_reservation.toString(),
    is_visible: !!is_visible,
    creneau_unique: !!creneau_unique,
    limit_resa: limit_resa.toString(),
    max_delay_reservation: max_delay_reservation.toString(),
    return_slot_interval,
    return_delay: return_delay.toString(),
    pas_reservation,
  };
}

function PdlDetails() {
  const { t } = useTranslation(['translation', 'form', 'parameters/pdlDetails', 'errors']);
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const snackbar = useSnackbar();
  const [pdlId] = useQueryParam('id', NumberParam);
  const parameterRepository = useInjection<ParameterRepository>(SERVICE_KEYS.PARAMETER_REPOSITORY);
  const formObject = useGetPDLUpdateFormObject();
  const [pdlDetails, setPdlDetails] = useState<PDLDetails | undefined>(undefined);
  const { builtFormFields, handleSubmit } = useBuildForm({ formObject, defaultValues: pdlDetails, colsNumber: 3 });

  useEffect(() => {
    async function fetch() {
      try {
        await parameterRepository.getCollectivity({ id_coll });
        if (pdlId) {
          const pdlResponse = await parameterRepository.getPDL({ id_pdl: pdlId, id_coll });
          if (pdlResponse?.status === 200 && pdlResponse.data) {
            setPdlDetails(transformPdlDetailsRawToPdlDetailsForm(pdlResponse.data));
          }
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const code = error.response?.data.code;
          snackbar?.setAlert({
            message: t('errors:'.concat(code.replace('error.', ''))),
            severity: TOAST_SEVERITY.error,
          });
        }
      }
    }

    fetch();
  }, [pdlId, t, id_coll]);

  const onSubmit = useCallback(
    handleSubmit(async (data) => {
      const { is_visible, ...dataToForward } = data;
      try {
        if (pdlId) {
          const response = await parameterRepository.updatePDL({
            id_coll,
            id_pdl: pdlId,
            visible_pdl: is_visible,
            ...(dataToForward as PDLDetails),
          });

          if (response?.status === 200) {
            snackbar?.setAlert({
              message: t('parameters.modification_success'),
              severity: TOAST_SEVERITY.success,
            });
          }
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const code = error.response?.data.code;
          snackbar?.setAlert({
            message: t('errors:'.concat(code.replace('error.', ''))),
            severity: TOAST_SEVERITY.error,
          });
        }
      }
    }),
    [id_coll, pdlId, t]
  );

  return (
    <MainSection>
      <FormGroupCard
        title={pdlDetails?.nom_pdl}
        WrapperComponent={({ children }: PropsWithChildren<unknown>) => <form onSubmit={onSubmit}>{children} </form>}
        FooterSlotComponent={() => (
          <InfoFormFooterRoot>
            <ButtonWithIcon
              color={'secondary'}
              icon={'SaveIcon'}
              type={'submit'}
              label={t('form:global_update_label')}
            />
          </InfoFormFooterRoot>
        )}>
        {builtFormFields}
      </FormGroupCard>
      <Card
        css={css`
          margin-top: 16px;
          width: fit-content;
        `}
        color={'primary'}>
        <span
          css={css`
            font-weight: bold;
            display: block;
          `}>
          {t('parameters/pdlDetails:pdl_schedule_advice.title')}
        </span>
        {t('parameters/pdlDetails:pdl_schedule_advice.description')}
      </Card>
      <PDLScheduleCard />
    </MainSection>
  );
}

const InfoFormFooterRoot = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default withTheme(PdlDetails);
