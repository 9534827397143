import React, { useCallback } from 'react';
import styled from '@emotion/styled/macro';
import { CheckIcon } from '../../../const/icons';
import useControlled from '../../../hook/useControlled';

type ToggleProps = {
  checked?: boolean;
  onChange?: (newValue: boolean) => void;
};

// TODO: need rework for controlled state
function Toggle(props: ToggleProps) {
  const { checked: checkedProp, onChange } = props;

  const [checked, setChecked] = useControlled({
    controlled: checkedProp,
    default: false,
    name: 'checked',
    state: 'Toggle',
  });

  const toggle = useCallback(() => {
    onChange?.(!checked);
    setChecked(!checked);
  }, [checked, onChange]);

  return (
    <Switch onClick={toggle}>
      <Input checked={checked} onChange={toggle} data-testid={'toggle-input'} type={'checkbox'} />
      <Slider>
        <span>
          <CheckIcon width={20} />
        </span>
      </Slider>
    </Switch>
  );
}

const SWITCH_WIDTH = 28;
const SWITCH_HEIGHT = 16;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.color.gray['300']};
  transition: 0.4s;
  border-radius: 34px;

  svg {
    position: absolute;
    height: ${SWITCH_HEIGHT - 4}px;
    width: ${SWITCH_HEIGHT - 4}px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
    color: white;
  }
`;

const Input = styled.input`
  :checked + ${Slider} {
    background-color: ${(props) => props.theme.color.green['200']};
  }

  :focus:checked + ${Slider} {
    box-shadow: 0 0 1px ${(props) => props.theme.color.green['200']};
  }

  :focus + ${Slider} {
    box-shadow: 0 0 1px ${(props) => props.theme.color.gray['300']};
  }

  :checked + ${Slider} svg {
    transform: translateX(${SWITCH_WIDTH / 2 - 2}px);
    background-color: ${(props) => props.theme.color.green['200']};
  }
`;

const Switch = styled.span`
  position: relative;
  display: inline-block;
  width: ${SWITCH_WIDTH}px;
  height: ${SWITCH_HEIGHT}px;

  :hover ${Slider} {
    background-color: ${(props) => props.theme.color.gray['400']};
  }

  :hover ${Input}:checked + ${Slider} {
    background-color: ${(props) => props.theme.color.green['300']};
  }

  :hover ${Input}:checked + ${Slider} svg {
    background-color: ${(props) => props.theme.color.green['300']};
  }

  ${Input} {
    opacity: 0;
    width: 0;
    height: 0;
  }
`;

export default Toggle;
