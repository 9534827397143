import { selectCollAndDeliveryPointID } from 'auth/selectors/authSelectors';
import Button from 'fragments/button/Button';
import DataTable from 'fragments/dataTable/DataTable';
import Select from 'fragments/select/Select';
import { CHECKIN_CHECKOUT_TYPE } from 'internal/constants/routes';
import useApi, { Methods } from 'internal/hooks/useApi';
import useIsMobile from 'internal/hooks/useIsMobile';
import { BIKE_TYPE_ENDPOINT, FREE_PARK_TYPE_ENDPOINT, PARK_TYPE_ENDPOINT } from 'parameters/constants/endpoints';
import { ArticleTypologyType } from 'parameters/types/prices';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

const ParametersCheckinCheckout = (): ReactElement => {
  const { t } = useTranslation('common');
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const [articleTypes, setArticleTypes] = useState<{
    [key: string]: ArticleTypologyType[];
  }>();
  const [articleType, setArticleType] = useState('velo');
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { pathname } = useLocation();

  const { request: requestFreeParkTypes, response: freeParkResponse } = useApi<ArticleTypologyType[]>(
    Methods.GET,
    FREE_PARK_TYPE_ENDPOINT
  );
  const { request: requestParkTypes, response: parkResponse } = useApi<ArticleTypologyType[]>(
    Methods.GET,
    PARK_TYPE_ENDPOINT
  );
  const { request: requestBikeTypes, response: bikesResponse } = useApi<ArticleTypologyType[]>(
    Methods.GET,
    BIKE_TYPE_ENDPOINT
  );

  const fetchData = useCallback(() => {
    requestFreeParkTypes({
      params: {
        id_coll,
      },
    });

    requestParkTypes({
      params: {
        id_coll,
      },
    });

    requestBikeTypes({
      params: {
        id_coll,
      },
    });
  }, [id_coll]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (freeParkResponse?.success && freeParkResponse.data) {
      setArticleTypes((typeArticles) => ({
        ...typeArticles,
        consignepark: freeParkResponse.data,
      }));
    }
  }, [freeParkResponse]);

  useEffect(() => {
    if (parkResponse?.success && parkResponse.data) {
      setArticleTypes((typeArticles) => ({
        ...typeArticles,
        consigne: parkResponse.data,
      }));
    }
  }, [parkResponse]);

  useEffect(() => {
    if (bikesResponse?.success && bikesResponse.data) {
      setArticleTypes((typeArticles) => ({
        ...typeArticles,
        velo: bikesResponse.data,
      }));
    }
  }, [bikesResponse]);

  const handleRedirect = useCallback(
    (typeName: string, id: number) => () => {
      navigate(`${pathname}${CHECKIN_CHECKOUT_TYPE}?type=${typeName}&id=${id}&article=${articleType}`);
    },
    [articleType, navigate, location]
  );

  const items = useMemo(() => {
    if (articleTypes && articleTypes[articleType]) {
      return articleTypes[articleType].map((articleType) => ({
        type_name: <span>{articleType.nom_type}</span>,
        type_action: (
          <Button
            className={'btn-w-xl btn-primary'}
            label={t('common:edit')}
            onClick={handleRedirect(articleType.nom_type, articleType.id_type)}
          />
        ),
      }));
    }

    return [];
  }, [articleTypes, articleType]);

  return (
    <div className={'main-content'}>
      <div className={'card'}>
        <div className={'card-body'}>
          <div className={!isMobile ? 'row' : 'o-scroll'}>
            <div className={!isMobile ? 'col-md-12' : ''}>
              <Select
                className={!isMobile ? 'col-md-4' : ''}
                options={[
                  { label: t('bike'), value: 'velo' },
                  { label: t('park'), value: 'consigne' },
                  { label: t('freePark'), value: 'consignepark' },
                ]}
                handleChange={setArticleType}
              />
              <DataTable
                cols={['type_name', 'type_action']}
                colTraductionScope={'parameters/checkin'}
                colHandlers={[]}
                items={items}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParametersCheckinCheckout;
