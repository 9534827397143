/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import dayjs, { Dayjs } from 'dayjs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NumberParam, useQueryParam } from 'use-query-params';
import { selectCollAndDeliveryPointID } from '../../../../auth/selectors/authSelectors';
import ButtonWithIcon from '../../../../components/Core/Button/ButtonWithIcon';
import Card from '../../../../components/Core/Card/Card';
import Flex from '../../../../components/Core/Flex/Flex';
import { TOAST_SEVERITY } from '../../../../components/Core/Toast/Toast';
import Schedule from '../../../../components/Schedule/Schedule';
import { useSnackbar } from '../../../../context/SnackbarContext';
import { useInjection } from '../../../../ioc/ioc.react';
import { SERVICE_KEYS } from '../../../../ioc/keys';
import { ParameterRepository, PDLWeekScheduleRaw } from '../../../../repository/parameter.repository';
import { getColor } from '../../../../utils/style';
import usePDLDetailsApi from '../usePDLDetailsApi';
import DateSelector from './DateSelector';

function PDLScheduleCard() {
  const { t, i18n } = useTranslation(['parameters/pdlDetails', 'form']);
  const snackbar = useSnackbar();
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const [pdlId] = useQueryParam('id', NumberParam);
  const [date, setDate] = useState(dayjs().locale(i18n.language).weekday(0));
  const { putCalendarRules, replicateCalendarRules } = usePDLDetailsApi();
  const parameterRepository = useInjection<ParameterRepository>(SERVICE_KEYS.PARAMETER_REPOSITORY);
  const [PDLSchedule, setPDLSchedule] = useState<PDLWeekScheduleRaw | undefined>(undefined);

  useEffect(() => {
    dayjs.locale(i18n.language);
    setDate((previous) => previous.locale(i18n.language));
  }, [i18n.language]);

  useEffect(() => {
    async function fetch() {
      if (!pdlId) throw new Error('pdl id is empty');

      const response = await parameterRepository.getPDLWeekSchedule({
        dayStart: date.weekday(0).toDate(),
        pdlId: pdlId,
        collId: id_coll,
      });

      if (response && response.status === 200) {
        setPDLSchedule(() => response?.data);
      }
    }

    fetch();
  }, [pdlId, id_coll, date]);

  const defaultValuesForSchedule = useMemo(() => {
    return PDLSchedule
      ? PDLSchedule.days.reduce((prev, current) => {
          return Object.assign(prev, { [current.day]: current.slots.map((slot) => dayjs(slot)) });
        }, {})
      : [];
  }, [PDLSchedule]);

  const onDayChange = useCallback(
    async (day: string, data: Dayjs[]) => {
      const response = await putCalendarRules({
        collId: id_coll,
        pdlId,
        dayStart: date.toDate(), //jour au format ISO full
        hourStart: PDLSchedule?.hourStart,
        hourEnd: PDLSchedule?.hourEnd,
        days: [
          {
            day: day,
            // slots: data.map((date: Dayjs) => date.utc()),
            slots: data.map((date) => date.toDate()),
          },
        ],
      });

      if (response && response.status === 200) {
        setPDLSchedule((prev) => (prev ? { ...prev, days: response.data.days } : prev));
      }
    },
    [id_coll, pdlId, PDLSchedule, date]
  );

  const onClickReplicateWeek = useCallback(async () => {
    const response = await replicateCalendarRules({
      collId: id_coll,
      pdlId,
      day: date.toDate(), //jour au format ISO full
    });

    if (response && response.status === 200) {
      snackbar?.setAlert({ message: t('form:global_update_success_message'), severity: TOAST_SEVERITY.success });
    }
  }, [id_coll, pdlId, date]);

  const onClickUnavailabilityWeek = useCallback(async () => {
    const response = await putCalendarRules({
      collId: id_coll,
      pdlId,
      dayStart: date.toDate(), //jour au format ISO full
      hourStart: PDLSchedule?.hourStart,
      hourEnd: PDLSchedule?.hourEnd,
      days: [
        {
          day: date.weekday(0).format('YYYY-MM-DD'),
          slots: [],
        },
        {
          day: date.weekday(1).format('YYYY-MM-DD'),
          slots: [],
        },
        {
          day: date.weekday(2).format('YYYY-MM-DD'),
          slots: [],
        },
        {
          day: date.weekday(3).format('YYYY-MM-DD'),
          slots: [],
        },
        {
          day: date.weekday(4).format('YYYY-MM-DD'),
          slots: [],
        },
        {
          day: date.weekday(5).format('YYYY-MM-DD'),
          slots: [],
        },
        {
          day: date.weekday(6).format('YYYY-MM-DD'),
          slots: [],
        },
      ],
    });

    if (response && response.status === 200) {
      setPDLSchedule((prev) => (prev ? { ...prev, days: response.data.days } : prev));
    }
  }, [date, PDLSchedule, id_coll, pdlId]);

  return (
    <Card
      css={css`
        padding-left: 0;
        padding-right: 0;
        overflow: auto;
        display: flex;
        flex-direction: column;
      `}
      content={
        <>
          <DateSelector value={date} onChange={(newDate) => setDate(newDate)} />
          <div
            css={css`
              padding: 0 16px;
            `}>
            <Schedule defaultValues={defaultValuesForSchedule} startDateOfWeek={date} onDayChange={onDayChange} />
          </div>
          <Footer>
            <div
              css={css`
                display: flex;
                align-items: center;
                gap: 32px;
              `}>
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  gap: 8px;
                `}>
                <HelperCase blank={false} />
                <span>{t('open')}</span>
              </div>
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  gap: 8px;
                `}>
                <HelperCase blank={true} />
                <span>{t('close')}</span>
              </div>
            </div>
            <Flex>
              <ButtonWithIcon
                icon={'EditIcon'}
                color={'secondary'}
                onClick={onClickUnavailabilityWeek}
                label={t('unavailability_week_button_label')}
              />
              <ButtonWithIcon
                icon={'EditIcon'}
                color={'secondary'}
                onClick={onClickReplicateWeek}
                label={t('replicate_button_label')}
              />
            </Flex>
          </Footer>
        </>
      }
    />
  );
}

const Footer = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
`;

const HelperCase = styled.span<{ blank: boolean }>`
  display: block;
  width: 16px;
  height: 16px;
  background-color: ${(props) => (props.blank ? 'white' : getColor('primary.200')(props))};
  border: 1px solid ${getColor('gray.400')};
`;

export default PDLScheduleCard;
