import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useApi, { Methods } from 'internal/hooks/useApi';
import { selectCollAndDeliveryPointID } from 'auth/selectors/authSelectors';
import Button from 'fragments/button/Button';
import SnackbarSeverity from 'internal/constants/snackbar';
import { setSnackbar, showSnackbar } from 'internal/actions/appActions';
import { DeliveryPointType } from 'auth/types/collectivity';
import { USER_AUTH_PDL } from 'auth/constants/endpoints';
import { savePdlList } from 'auth/actions/authActions';
import Status from 'internal/constants/status';
import { useTranslation } from 'react-i18next';

const ParametersSync = (): ReactElement => {
  const { t } = useTranslation(['old']);
  const [loading, setLoading] = useState<boolean>(false);
  const { request: fetchSync, response: rawSync } = useApi<{
    isSync: boolean;
  }>(Methods.GET, 'param/sync');

  const {
    request: sync,
    response: syncRes,
    status,
  } = useApi<{
    isSync: boolean;
  }>(Methods.POST, 'param/sync');

  const { request: fetchPdlList, response: pdlListRes } = useApi<DeliveryPointType[]>(Methods.GET, USER_AUTH_PDL);

  const { id_coll } = useSelector(selectCollAndDeliveryPointID);

  const dispatch = useDispatch();

  useEffect(() => {
    if (id_coll) {
      fetchSync({
        params: {
          id_coll,
        },
      });
    }
  }, [id_coll]);

  const dynamicSubtitle = useMemo(() => {
    if (!rawSync?.data?.isSync) {
      return t('parameters.not_sync_with_velocare');
    }
    return t('parameters.sync_with_velocare');
  }, [rawSync?.data?.isSync]);

  const dynamcSyncLabel = useMemo(() => {
    if (!rawSync?.data?.isSync) {
      return t('parameters.start_sync_button_label');
    }
    return t('parameters.sync_button_label');
  }, [rawSync?.data?.isSync, t]);

  const disable = useMemo(() => !rawSync?.data?.isSync, [rawSync?.data?.isSync]);

  const onSync = useCallback(() => {
    setLoading(true);
    sync({
      id_coll,
    });
  }, [id_coll]);

  useEffect(() => {
    if (syncRes?.success) {
      dispatch(setSnackbar(t('parameters.sync_success'), SnackbarSeverity.SUCCESS));
      dispatch(showSnackbar());
      setLoading(false);

      fetchPdlList({
        params: {
          id_coll,
        },
      });
    }
  }, [syncRes?.success]);

  useEffect(() => {
    if (status === Status.FAILURE) {
      setLoading(false);
    }
  }, [status]);

  useEffect(() => {
    if (pdlListRes?.success && pdlListRes.data) {
      dispatch(savePdlList(pdlListRes.data));
    }
  }, [pdlListRes]);

  return (
    <div className={'col-md-12 col-xl-8'}>
      <p className={'lead'}>{t('parameters.velocare_syncing')}</p>
      {dynamicSubtitle}
      <div className={`t-3 ${rawSync?.data?.isSync ? 'mw-160' : ''}`}>
        <Button
          className={'btn-primary'}
          label={dynamcSyncLabel}
          loading={loading}
          onClick={onSync}
          disabled={disable}
        />
      </div>
    </div>
  );
};

export default ParametersSync;
