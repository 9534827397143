/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import MainSection from '../../../components/Core/MainSection/MainSection';
import { selectCollAndDeliveryPointID } from '../../../auth/selectors/authSelectors';
import Button from '../../../components/Core/Button/Button';
import Toast, { TOAST_SEVERITY } from '../../../components/Core/Toast/Toast';
import FormGroupCard from '../../../components/FormGroupCard/FormGroupCard';
import { useSnackbar } from '../../../context/SnackbarContext';
import useBuildForm from '../../../hook/useBuildForm';
import { useInjection } from '../../../ioc/ioc.react';
import { SERVICE_KEYS } from '../../../ioc/keys';
import { PostUserBodyParams, UserRepository } from '../../../repository/user.repository';
import { generatePath } from '../../../utils/router';
import { withTheme } from '../../Parameters/Deposit/List/DepositList';
import { getUserCreateFormFields } from './createForm';
import useReasonJoining from './useReasonJoining';

function Create() {
  const navigate = useNavigate();
  const { t } = useTranslation(['user/create', 'form', 'errors']);
  const snackbar = useSnackbar();
  const userRepository = useInjection<UserRepository>(SERVICE_KEYS.USER_REPOSITORY);
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const { builtFormFields, handleSubmit, watch } = useBuildForm({
    formObject: getUserCreateFormFields(t),
    colsNumber: 3,
  });
  const builtFormGroup = useReasonJoining({ builtFormGroup: builtFormFields[0], watch });

  const onSubmit = useCallback(
    handleSubmit(async (data) => {
      // eslint-disable-next-line prefer-const
      let { motif_adhesion, motif_adhesion_autre, ...otherData } = data;
      if (motif_adhesion !== 'autres') motif_adhesion_autre = null;
      try {
        const response = await userRepository.post({
          ...otherData,
          motif_adhesion,
          motif_adhesion_autre,
          id_coll,
        } as PostUserBodyParams);

        if (response?.status === 200) {
          snackbar?.setAlert({ message: t('create_validated'), severity: TOAST_SEVERITY.success });
          // navigate(`${USERS}${EDIT}?userID=${response.data.id_usager}`);
          navigate(generatePath('home.user.userDetails', { params: { userId: response.data.id_usager } }));
        }
      } catch (error: unknown) {
        console.error(error);
        if (axios.isAxiosError(error)) {
          const messageCode = error.response?.data.code;
          snackbar?.setAlert({
            message: messageCode ? t('errors:'.concat(messageCode.replace('error.', ''))) : t('errors:unknownError'),
            severity: TOAST_SEVERITY.error,
          });
        }
      }
    }),
    [id_coll, t]
  );

  return (
    <MainSection className={'reset'}>
      <div
        css={css`
          align-self: start;
        `}>
        <Toast
          css={css`
            justify-self: start;
          `}
          type={'primary'}>
          {t('info_toast')}
        </Toast>
      </div>
      <Form onSubmit={onSubmit}>
        <FormGroupCard title={t('first_section')} description={t('form:required_fields_notice')}>
          {builtFormGroup}
        </FormGroupCard>
        <FormGroupCard title={t('second_section')} description={t('form:required_fields_notice')}>
          {builtFormFields[1]}
        </FormGroupCard>
        <Button
          css={css`
            justify-self: flex-end;
          `}
          label={t('form_cta')}
          type={'submit'}
        />
      </Form>
    </MainSection>
  );
}

const Form = styled.form`
  display: grid;
  gap: 16px;
`;

export default withTheme(Create);
