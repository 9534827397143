import React, { useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { getCheckOutVerifs, postBookingMedia, postCheckOut, postFinishBooking } from '../../api/booking.api';
import { selectCollAndDeliveryPointID, selectSessionToken } from '../../auth/selectors/authSelectors';
import ButtonWithIcon from '../../components/Core/Button/ButtonWithIcon';
import Card from '../../components/Core/Card/Card';
import Datagrid, { RowItem } from '../../components/Core/Datagrid/Datagrid';
import { GridColDefinition } from '../../components/Core/Datagrid/gridCols';
import DropzoneFileInput, { DROPZONE_SIZES } from '../../components/Core/DropzoneFileInput/DropzoneFileInput';
import FieldError from '../../components/Core/FieldError/FieldError';
import Label from '../../components/Core/Label/Label';
import MainSection from '../../components/Core/MainSection/MainSection';
import SelectField from '../../components/Core/SelectField';
import TextField from '../../components/Core/TextField';
import TextArea from '../../components/Form/TextArea/TextArea';
import { useMainTitle } from '../../components/Layout/MainTitleContext';
import { WarningIcon } from '../../const/icons';
import { generatePath } from '../../utils/router';
import styles from './bookingDetails.module.scss';
import { useCheckStatuses } from './CheckIn.page';

function useColumns({ control }: Partial<UseFormReturn>): GridColDefinition[] {
  const { t } = useTranslation(['reservation/components/checkOut', 'reservation/components/checkIn', 'form']);
  const token = useSelector(selectSessionToken);
  const statuses = useCheckStatuses();

  return useMemo(() => {
    return [
      {
        width: 50,
        field: '',
        type: 'custom',
        renderCell: ({ row }) => {
          return <div>{row.is_mandatory ? <WarningIcon className={'warning'} width={16} /> : null}</div>;
        },
      },
      {
        field: 'label',
        headerName: t('columns.elements'),
        type: 'custom',
        renderCell: ({ row }) => {
          return (
            <div>
              <span>{row.label}</span>
            </div>
          );
        },
      },
      {
        field: '',
        headerName: t('columns.checkInImage'),
        type: 'custom',
        renderCell: ({ row }) => {
          return (
            <RowItem key={row.id_form.toString().concat('media')}>
              {row.entry.media_url ? (
                <img src={row.entry?.media_url?.concat(`&token=${token}`)} alt={'verification'} />
              ) : (
                '-'
              )}
            </RowItem>
          );
        },
      },
      {
        field: 'entry.status',
        headerName: t('columns.checkInStatus'),
        type: 'custom',
        renderCell: ({ row }) => {
          return <RowItem>{statuses.find((status) => row.entry.status === status.value)?.label}</RowItem>;
        },
      },
      {
        field: 'entry.commentaire',
        headerName: t('columns.checkInCommentary'),
        type: 'string',
      },
      {
        field: '',
        headerName: t('columns.image'),
        type: 'custom',
        renderCell: ({ row }) => {
          return (
            <RowItem key={row.id_form.toString().concat('media')}>
              <Controller
                rules={{ required: row.is_media_mandatory === 1 }}
                control={control}
                render={({ fieldState: { error }, field: { onChange } }) => (
                  <div className={'field'}>
                    <DropzoneFileInput
                      required={row.is_media_mandatory === 1}
                      canBeDeleted
                      size={DROPZONE_SIZES.mini}
                      onChange={onChange}
                    />
                    {error && <FieldError>{t('form:errors.'.concat(error.type))}</FieldError>}
                  </div>
                )}
                name={`${row.id_form}.media`}
              />
            </RowItem>
          );
        },
      },
      {
        field: '',
        headerName: t('columns.status'),
        type: 'custom',
        renderCell: ({ row }) => {
          return (
            <RowItem key={row.id_form.toString().concat('status')}>
              <Controller
                name={`${row.id_form}.status`}
                rules={{ required: t('form:errors.required') }}
                control={control}
                defaultValue={statuses[0].value}
                render={({ fieldState: { error }, field: { onChange, value } }) => (
                  <SelectField
                    error={error}
                    placeholder={t('reservation/components/checkIn:page.statusField.placeholder')}
                    onChange={onChange}
                    value={value}
                    className={'field--full field'}
                    menuPosition={'fixed'}
                    options={statuses}
                  />
                )}
              />
            </RowItem>
          );
        },
      },
      {
        field: '',
        headerName: t('columns.commentary'),
        type: 'custom',
        renderCell: ({ row }) => {
          return (
            <RowItem key={row.id_form.toString().concat('commentary')}>
              <Controller
                rules={{ required: row.is_mandatory === 1 ? t('form:errors.required') : false }}
                defaultValue={''}
                control={control}
                render={({ fieldState: { error }, field: { onChange, value } }) => (
                  <div className={'field'}>
                    <TextField
                      error={error}
                      placeholder={t('reservation/components/checkIn:page.commentaryField.placeholder').concat(
                        row.is_mandatory === 1 ? '*' : ''
                      )}
                      value={value || ''}
                      onChange={onChange}
                    />
                  </div>
                )}
                name={`${row.id_form}.commentaire`}
              />
            </RowItem>
          );
        },
      },
    ];
  }, [control, statuses, t, token]);
}

function CheckOutPage() {
  const { t } = useTranslation(['reservation/components/checkOut', 'reservation/components/checkIn', 'form']);
  const { control, handleSubmit } = useForm();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { id_coll, id_pdl } = useSelector(selectCollAndDeliveryPointID);
  const queryClient = useQueryClient();
  useMainTitle(t('pageTitle'));
  const columns = useColumns({ control });

  useEffect(() => {
    if (!location?.state) navigate(generatePath('home.invoicing.reservationDetails.test', { params }));
  }, [location]);

  const { data: checkOutVerifsResponse } = useQuery(
    ['booking.checkOutVerifs', { id_resa: params.bookingId, id_coll }],
    ({ queryKey }) => {
      const [, queryParams] = queryKey as [string, { id_coll: number; id_resa: number }];
      return getCheckOutVerifs(queryParams);
    },
    {
      staleTime: 400_000,
      refetchOnWindowFocus: false,
      onSuccess: async (response) => {
        if (response.data.length === 0) {
          await queryClient.invalidateQueries(['checkInVerifList']);
          navigate(generatePath('home.invoicing.reservationDetails', { params }));
        }
      },
    }
  );

  const { mutate: handlePostCheckOut, isLoading } = useMutation(
    ['booking.post.checkOut'], //eslint-disable-next-line
    async (data: any) => {
      const { bookingId, userId } = params;
      if (!bookingId || !userId) throw new Error('params is empty');
      const copy = {
        ...data,
      };
      await Promise.all(
        //eslint-disable-next-line
        Object.entries(data).map(async ([formId, values]: [string, any]) => {
          //eslint-disable-next-line
          const currentCheckOut = checkOutVerifsResponse?.data.find(
            //eslint-disable-next-line
            (checkOut: any) => checkOut.id_form === Number(formId)
          );
          if (values.media) {
            const response = await postBookingMedia({
              id_coll,
              id_user: userId,
              file: values.media,
              pjName: 'checkout_'.concat(formId),
            });
            delete copy[formId].media;
            Object.assign(copy[formId], { id_media: response.data.id_media });
          } else {
            Object.assign(copy[formId], { id_media: '' });
          }
          Object.assign(copy[formId], { ...currentCheckOut });
        })
      );

      return Promise.all([
        postCheckOut({ form: Object.values(copy), id_coll, id_resa: bookingId }),
        postFinishBooking({ id_coll, id_resa: bookingId, id_pdl, t_etat: location.state.status }),
      ]);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['booking']);
        navigate(generatePath('home.invoicing.reservationDetails', { params }));
      },
    }
  );

  const onSubmit = useCallback(
    handleSubmit(async (data) => {
      handlePostCheckOut(data);
    }),
    [id_coll, params, handlePostCheckOut, checkOutVerifsResponse]
  );

  return (
    <MainSection className={'reset'}>
      <Card color={'primary'}>{t('infoBanner')}</Card>
      <span>{t('form:required_fields_notice')}</span>

      <Card title={t('cardTitle')}>
        <Datagrid
          //eslint-disable-next-line
          rows={checkOutVerifsResponse?.data.filter((row: any) => row.need_status !== 0) || []}
          columns={columns}
        />
      </Card>
      {checkOutVerifsResponse?.data
        //eslint-disable-next-line
        .filter((verif: any) => verif.need_status === 0)
        //eslint-disable-next-line
        .map((row: any) => (
          <Card
            title={t('reservation/components/checkIn:page.globalControlCardTitle')}
            content={
              <>
                <div className={'row gap-8'}>
                  <div className={'flex-1'}>
                    <Controller
                      name={`${row.id_form}.commentaire`}
                      rules={{ required: row.is_mandatory === 1 }}
                      defaultValue={''}
                      control={control}
                      render={({ fieldState: { error }, field: { onChange, value } }) => (
                        <>
                          <Label required={row.is_mandatory === 1}>
                            {t('reservation/components/checkIn:commentaryField.label')}
                          </Label>
                          <div>
                            <TextArea
                              placeholder={t('reservation/components/checkIn:commentaryField.placeholder')}
                              onChange={onChange}
                              value={value}
                            />
                            {error && <FieldError>{t('form:errors.'.concat(error.type))}</FieldError>}
                          </div>
                        </>
                      )}
                    />
                  </div>
                  <Controller
                    name={`${row.id_form}.media`}
                    rules={{ required: row.is_media_mandatory === 1 }}
                    control={control}
                    render={({ fieldState: { error }, field: { onChange } }) => (
                      <div>
                        <DropzoneFileInput required={row.is_media_mandatory === 1} onChange={onChange} />
                        {error && <FieldError>{t('form:errors.'.concat(error.type))}</FieldError>}
                      </div>
                    )}
                  />
                </div>
              </>
            }
          />
        ))}
      <div className={styles.FooterActions}>
        <ButtonWithIcon
          as={Link}
          to={generatePath('home.invoicing.reservationDetails', { params })}
          color={'secondary'}
          icon={'CrossWithoutCircle'}>
          {t('reservation/components/checkIn:page.cancelCta')}
        </ButtonWithIcon>
        <ButtonWithIcon isLoading={isLoading} icon={'CheckWithoutCircle'} onClick={onSubmit}>
          {t('reservation/components/checkIn:page.validateCta')}
        </ButtonWithIcon>
      </div>
    </MainSection>
  );
}

export default CheckOutPage;
