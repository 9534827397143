import React from 'react';
import { useFormContext, UseFormReturn } from 'react-hook-form';

function ConnectForm({ children }: { children: (methods: UseFormReturn) => React.JSX.Element }) {
  const methods = useFormContext();

  return children({ ...methods });
}

export default ConnectForm;
