/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';
import React, { ComponentPropsWithoutRef, useCallback, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import Grid from '../../../../components/Core/Grid/Grid';
import FormGroupCard from '../../../../components/FormGroupCard/FormGroupCard';
import FOButtonsVisualizer from '../components/FOButtonsVisualizer';
import Button from '../../../../components/Core/Button/Button';
import { instance } from '../../../../api/user.api';
import { selectCollAndDeliveryPointID } from '../../../../auth/selectors/authSelectors';
import { useSnackbar } from '../../../../context/SnackbarContext';
import { TOAST_SEVERITY } from '../../../../components/Core/Toast/Toast';
import { ColorPickerControlled } from './NavigationSection';

const colorPickerControlledWithHelperCss = {
  mini: css({
    width: 120,
  }),
  helper: css({
    marginTop: 8,
  }),
};

function ColorPickerControlledWithHelper(
  props: ComponentPropsWithoutRef<typeof ColorPickerControlled> & { helper: string; mini?: boolean }
) {
  const { name, control, mini = false } = props;
  const value = useWatch({ name, control });

  return (
    <div css={mini && colorPickerControlledWithHelperCss.mini}>
      <ColorPickerControlled {...props} />
      <Grid css={colorPickerControlledWithHelperCss.helper} columns={null} gap={4}>
        <span>{props.helper}</span>
        <span>{value}</span>
      </Grid>
    </div>
  );
}

//eslint-disable-next-line
function ThemeColorSection({ defaultValues }: { defaultValues: any }) {
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const { t } = useTranslation(['admin/visual', 'form']);
  const { control, reset, handleSubmit } = useForm({ defaultValues });
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();

  const { mutate: handlePostPrimaryColors } = useMutation(
    ['display/primary'],
    //eslint-disable-next-line
    (bodyForm: any) => {
      return instance.post('display/primary', bodyForm);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['display/all']);
        snackbar?.setAlert({
          message: t('colorsPage.themeCard.updateSuccessfullyMessage'),
          severity: TOAST_SEVERITY.success,
        });
      },
    }
  );

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const onSubmit = useCallback(
    handleSubmit((data) => {
      handlePostPrimaryColors({ collId: id_coll, ...data });
    }),
    [id_coll]
  );

  return (
    <FormGroupCard
      title={t('colorsPage.themeCard.title')}
      WrapperComponent={({ children }) => <form onSubmit={onSubmit}>{children}</form>}
      FooterSlotComponent={() => (
        <div className={'row justify-end mt-4'}>
          <Button type={'submit'} label={t('form:update')} />
        </div>
      )}>
      <p>{t('colorsPage.themeCard.advice')}</p>
      <Grid columns={'1fr auto'}>
        <Grid columns={null}>
          <Grid>
            <ColorPickerControlledWithHelper
              control={control}
              mini
              name={'primary-200'}
              helper={t('colorsPage.themeCard.original')}
            />
            <Grid columns={'repeat(3, auto)'} justify={'flex-end'}>
              <ColorPickerControlledWithHelper
                helper={t('colorsPage.themeCard.light')}
                mini
                control={control}
                name={'primary-100'}
              />
              <ColorPickerControlledWithHelper
                helper={t('colorsPage.themeCard.dark')}
                mini
                control={control}
                name={'primary-300'}
              />
              <ColorPickerControlledWithHelper
                helper={t('colorsPage.themeCard.veryDark')}
                mini
                control={control}
                name={'primary-400'}
              />
            </Grid>
          </Grid>
          <Grid>
            <ColorPickerControlledWithHelper
              control={control}
              mini
              name={'secondary-200'}
              helper={t('colorsPage.themeCard.original')}
            />
            <Grid columns={'repeat(2, auto)'} justify={'flex-end'}>
              <ColorPickerControlledWithHelper
                mini
                helper={t('colorsPage.themeCard.dark')}
                control={control}
                name={'secondary-300'}
              />
              <ColorPickerControlledWithHelper
                mini
                helper={t('colorsPage.themeCard.veryDark')}
                control={control}
                name={'secondary-400'}
              />
            </Grid>
          </Grid>
        </Grid>
        <FOButtonsVisualizer control={control} />
      </Grid>
    </FormGroupCard>
  );
}

export default ThemeColorSection;
