import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Input from 'fragments/input/Input';
import Checkbox from 'fragments/checkbox/Checkbox';
import Button from 'fragments/button/Button';
import useApi, { Methods } from 'internal/hooks/useApi';
import { NEW_PDL_ENDPOINT } from 'parameters/constants/endpoints';
import { selectCollAndDeliveryPointID } from 'auth/selectors/authSelectors';
import { savePdlList } from 'auth/actions/authActions';
import { DeliveryPointType } from 'auth/types/collectivity';
import { USER_AUTH_PDL } from 'auth/constants/endpoints';

type Props = {
  onSuccess: () => void;
  onRefresh: () => void;
};

const ParametersPDLCreator = ({ onSuccess, onRefresh }: Props): ReactElement => {
  const { t } = useTranslation(['old']);
  const dispatch = useDispatch();

  const [pdl, setPdl] = useState('');
  const [active, setActive] = useState(false);

  const { id_coll } = useSelector(selectCollAndDeliveryPointID);

  const { request: createPDL, response: createPDLResp } = useApi(Methods.POST, NEW_PDL_ENDPOINT);
  const { request: fetchPdlList, response: pdlListRes } = useApi<DeliveryPointType[]>(Methods.GET, USER_AUTH_PDL);

  useEffect(() => {
    if (pdlListRes?.success && pdlListRes.data) {
      dispatch(savePdlList(pdlListRes.data));
    }
  }, [pdlListRes]);

  const submitPDL = useCallback(() => {
    createPDL({
      nom_pdl: pdl,
      id_coll,
      visible_pdl: active,
    });
    setPdl('');
    setActive(false);
  }, [pdl, id_coll, active]);

  useEffect(() => {
    if (createPDLResp?.success) {
      onSuccess();
      onRefresh();

      fetchPdlList({
        params: {
          id_coll,
        },
      });
    }
  }, [createPDLResp, id_coll, onSuccess]);

  return (
    <div className={'row'}>
      <div className={'col-md-6'}>
        <Input
          type={'text'}
          className={'form-control'}
          id={'add_nompdl'}
          placeholder={t('parameters.pdl_name')}
          value={pdl}
          handleChange={setPdl}
          controlled
        />
      </div>
      <div className={'col-md-1'}>
        <Checkbox checked={active} handleChange={setActive} />
      </div>
      <div className={'col-md-5'}>
        <Button onClick={submitPDL} label={t('global.add')} className={'btn btn-w-xl btn-primary'} />
      </div>
    </div>
  );
};

export default ParametersPDLCreator;
