import Input from 'fragments/input/Input';
import React, { ChangeEvent, MouseEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import fileTypes from 'reservations/constants/files';
import { CheckinRawType } from 'reservations/types/checkin';

type Props = {
  formState: CheckinRawType[];
  fileUploadClasses: (required: boolean) => string;
  findVerif: (formId: number) => CheckinRawType | undefined;
  onUploadImageArticle: (formId: number) => (e: ChangeEvent<HTMLInputElement>) => void;
  onChoseUploadImage: (formId: number) => (e: MouseEvent<HTMLDivElement>) => void;
  handleChangeFormState: (formId: number, key: string) => (value: boolean | string) => void;
  onRemoveImage: (formId: number) => (e: MouseEvent<HTMLSpanElement>) => void;
};

const GlobalCommentaries = ({
  formState,
  fileUploadClasses,
  findVerif,
  onUploadImageArticle,
  onChoseUploadImage,
  handleChangeFormState,
  onRemoveImage,
}: Props) => {
  const { t } = useTranslation(['parameters/prices', 'parameters/checkin']);

  const globalCommentaries = useMemo(() => {
    if (formState) {
      return formState
        .filter(({ need_status }) => need_status === 0)
        .map(({ commentary, id_form, is_media_mandatory, has_media }) => (
          <>
            <div className={'col-md-6 m-auto'}>
              {has_media > 0 && (
                <div className={fileUploadClasses(!!is_media_mandatory)}>
                  <img
                    className={'cursor-pointer'}
                    src={findVerif(id_form)?.mediaUrl || undefined}
                    onClick={findVerif(id_form)?.mediaUrl ? onChoseUploadImage(id_form) : undefined}
                    style={{ display: !findVerif(id_form)?.mediaUrl ? 'none' : undefined }}
                  />
                  <input
                    type={'file'}
                    className={'fill-available'}
                    id={`mediaUrl-${id_form}`}
                    onChange={onUploadImageArticle(id_form)}
                    accept={`.jpg,.jpeg,.png,${fileTypes.JPEG},${fileTypes.PNG}`}
                    style={{ display: findVerif(id_form)?.mediaUrl ? 'none' : undefined }}
                    required={!!is_media_mandatory}
                  />
                  {findVerif(id_form)?.mediaUrl && (
                    <span className={'text-danger cursor-pointer'} onClick={onRemoveImage(id_form)}>
                      {t('articles_type.remove_image')}
                    </span>
                  )}
                </div>
              )}
              <Input
                className={'form-control'}
                value={commentary}
                handleChange={handleChangeFormState(id_form, 'commentary')}
                controlled
              />
            </div>
          </>
        ));
    }
    return [];
  }, [formState, t]);

  const globalCommentariesContainer = useMemo(() => {
    if (globalCommentaries.length > 0) {
      return (
        <div className={'col-md-8 m-auto card card-body'}>
          <p className={'lead col-md-12'}>{t('parameters/checkin:general_commentaries')}</p>
          {globalCommentaries}
        </div>
      );
    }

    return null;
  }, [globalCommentaries, t]);

  return <>{globalCommentariesContainer}</>;
};

export default GlobalCommentaries;
