import React from 'react';
import { generatePath, NavLink, Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { Tab, Tabs, TabsList } from '../../../../components/Core/Tabs/Tabs';
import { TabComponent } from '../../../Reservation/Reservation.layout';
import { PaintIcon, PriceListIcon, SettingsIcon } from '../../../../const/icons';
import { useMainTitle } from '../../../../components/Layout/MainTitleContext';
import { instance } from '../../../../api/user.api';
import { selectCollAndDeliveryPointID } from '../../../../auth/selectors/authSelectors';

function TulipLayout() {
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const { t } = useTranslation(['insurance/tulip']);
  useMainTitle('Tulip');

  const { data: tulipInfosResponse } = useQuery(
    ['insurance/tulip', { id_coll }],
    ({ queryKey }) => {
      const [, queryParams] = queryKey;
      return instance.get('insurance/tulip', { params: queryParams });
    },
    {
      staleTime: 800_000,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <div className={'reset'}>
      <div>
        <Tabs>
          <TabsList>
            <Tab
              component={({ children, ...otherProps }: TabComponent) => (
                <NavLink {...otherProps} to={generatePath('.')} end>
                  {children}
                </NavLink>
              )}>
              <SettingsIcon width={16} />
              {t('layout.params')}
            </Tab>
            <Tab
              component={({ children, ...otherProps }: TabComponent) => (
                <NavLink {...otherProps} to={generatePath('personalisation')} end>
                  {children}
                </NavLink>
              )}>
              <PaintIcon width={16} />
              {t('layout.customize')}
            </Tab>
            <Tab
              component={({ children, ...otherProps }: TabComponent) => (
                <NavLink {...otherProps} to={generatePath('produits')} end>
                  {children}
                </NavLink>
              )}>
              <PriceListIcon width={16} />
              {t('layout.products')}
            </Tab>
          </TabsList>
        </Tabs>
      </div>

      <React.Suspense fallback={<></>}>
        <Outlet context={tulipInfosResponse} />
      </React.Suspense>
    </div>
  );
}

export default TulipLayout;
