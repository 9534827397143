import { selectCollAndDeliveryPointID } from 'auth/selectors/authSelectors';
import useApi, { Methods } from 'internal/hooks/useApi';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { GET_CHECKOUT_ENDPOINT } from 'reservations/constants/endpoints';
import { CheckinRawType } from 'reservations/types/checkin';

type Props = {
  dependencies: {
    resaID: string;
    openCheckin: boolean;
    openCheckout: boolean;
    open: boolean;
  };
};
/* eslint-disable object-curly-newline */
const useCheckout = ({
  dependencies: { resaID, openCheckin, openCheckout, open },
}: Props): [CheckinRawType[], () => void] => {
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);

  const [verifsCheckout, setVerifsCheckout] = useState<CheckinRawType[]>([]);

  const { request: requestCheckouts, response: checkoutResponse } = useApi<CheckinRawType[]>(
    Methods.GET,
    GET_CHECKOUT_ENDPOINT,
    {
      noSnack: true,
    }
  );

  const fetchCheckouts = useCallback(() => {
    requestCheckouts({
      params: {
        id_coll,
        id_resa: resaID,
      },
    });
  }, [id_coll, resaID, openCheckout, openCheckin]);

  useEffect(() => {
    fetchCheckouts();
  }, [id_coll, resaID, open]);

  useEffect(() => {
    if (checkoutResponse?.success && checkoutResponse.data) {
      setVerifsCheckout(checkoutResponse.data);
    }
  }, [checkoutResponse]);

  return [verifsCheckout, fetchCheckouts];
};

export default useCheckout;
