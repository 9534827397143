import React from 'react';
import { QueryClient } from '@tanstack/react-query';
import { ActionFunction, json, LoaderFunction } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import MainSection from '../../components/Core/MainSection/MainSection';
import { instance } from '../../api/user.api';
import store from '../../internal/store';
import GlobalStatsExport from './customExport/GlobalStatsExport';
import RentalStatsExport from './customExport/RentalStatsExport';
import TurnoverExport from './customExport/TurnoverExport';
import TurnoverByArticleTypeExport from './customExport/TurnoverByArticleTypeExport';

/*const query: (queryParams: unknown) => FetchQueryOptions<AxiosResponse> = (queryParams) => ({
  queryKey: ['export/listEnabled', queryParams],
  queryFn: ({ queryKey }) => {
    const [url, queryParams] = queryKey as [string, unknown];
    return instance.get(url, { params: queryParams });
  },
});*/

export const customExportLoader: (queryClient: QueryClient) => LoaderFunction = () => () => {
  // const collId = store.getState().authState.collectivity;

  /*queryClient.ensureQueryData({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryKey: query({ idColl: collId }).queryKey!,
    queryFn: query({ idColl: collId }).queryFn,
  });*/

  return null;
};

export const customExportAction: (queryClient: QueryClient) => ActionFunction =
  () =>
  async ({ request }) => {
    const collId = store.getState().authState.collectivity;
    const pdlId = store.getState().authState.deliveryPoint;
    const token = store.getState().authState.user.token;
    const data = await request.json();
    let response = null;

    try {
      switch (data.type) {
        case 'global':
          response = await instance.get('export/customDataGlobal', {
            params: { dateStart: data.dates.startDate, dateEnd: data.dates.endDate, id_coll: collId, token },
          });
          break;
        case 'rental':
          response = await instance.get('export/customDataEncaissements', {
            params: { dateStart: data.dates.startDate, dateEnd: data.dates.endDate, id_coll: collId, token },
          });
          break;
        case 'turnover':
          response = await instance.get('export/customDataCA', {
            params: {
              date_start: moment(data.dates.startDate).format('YYYY-MM-DD'),
              date_end: moment(data.dates.endDate).format('YYYY-MM-DD'),
              id_coll: collId,
              token,
              type: 'means',
              id_pdl: pdlId,
            },
          });
          break;
        case 'turnoverByArticleType':
          response = await instance.get('export/customDataCA', {
            params: {
              date_start: moment(data.dates.startDate).format('YYYY-MM-DD'),
              date_end: moment(data.dates.endDate).format('YYYY-MM-DD'),
              id_coll: collId,
              token,
              id_pdl: pdlId,
              type: 'articletypes',
            },
          });
          break;
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        console.error(e);
        return json(e.response?.data);
      }
    }

    return response;
  };

function CustomExportPage() {
  return (
    <MainSection className={'reset'}>
      <GlobalStatsExport />
      <RentalStatsExport />
      <TurnoverExport />
      <TurnoverByArticleTypeExport />
    </MainSection>
  );
}

export default CustomExportPage;
