import { cloneElement } from 'react';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { UseFormWatch } from 'react-hook-form';

type UseReasonJoiningProps = {
  builtFormGroup: EmotionJSX.Element;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  watch: UseFormWatch<any>;
};

function findField(elements: EmotionJSX.Element[], name: string) {
  return elements.findIndex((element) => element.key === name);
}

function useReasonJoining(props: UseReasonJoiningProps) {
  const { builtFormGroup, watch } = props;
  const reasonJoiningValue = watch('motif_adhesion');
  // const reasonJoiningElementIndex = findField(builtFormGroup.props.children, 'motif_adhesion');
  const otherReasonJoiningElementIndex = findField(builtFormGroup.props.children, 'motif_adhesion_autre');

  if (otherReasonJoiningElementIndex) {
    const copyChildren = [...builtFormGroup.props.children];
    const otherReasonJoiningElement = copyChildren.splice(otherReasonJoiningElementIndex, 1);
    if (reasonJoiningValue === 'autres') {
      copyChildren.splice(otherReasonJoiningElementIndex, 0, otherReasonJoiningElement);
    }

    Object.assign(builtFormGroup.props.children, copyChildren);
    return cloneElement(builtFormGroup, { children: copyChildren });
  }

  return builtFormGroup;
}

export default useReasonJoining;
