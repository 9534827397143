import React from 'react';
import { generatePath, NavLink, Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { upperFirst } from 'lodash';
import styles from '../../Reservation/bookingDetails.module.scss';
import { Tab, Tabs, TabsList } from '../../../components/Core/Tabs/Tabs';
import { BikeIcon, PadlockIcon, ParkingIcon, ParkingSafeIcon } from '../../../const/icons';
import { TabComponent } from '../../Reservation/Reservation.layout';

function ProductsLayout() {
  const { t } = useTranslation(['common']);

  return (
    <div className={'reset'}>
      <div className={styles.Layout} id={'products-layout'}>
        <Tabs className={styles.Layout__tabs}>
          <TabsList>
            <Tab
              component={({ children, ...otherProps }: TabComponent) => (
                <NavLink {...otherProps} to={generatePath('.')} end>
                  {children}
                </NavLink>
              )}>
              <BikeIcon width={16} /> {upperFirst(t('bike'))}
            </Tab>
            <Tab
              component={({ children, ...otherProps }: TabComponent) => (
                <NavLink {...otherProps} to={generatePath('accessoire')} end>
                  {children}
                </NavLink>
              )}>
              <PadlockIcon width={16} /> {upperFirst(t('accessory'))}
            </Tab>
            <Tab
              component={({ children, ...otherProps }: TabComponent) => (
                <NavLink {...otherProps} to={generatePath('consigne')} end>
                  {children}
                </NavLink>
              )}>
              <ParkingSafeIcon width={16} /> {upperFirst(t('park'))}
            </Tab>
            <Tab
              component={({ children, ...otherProps }: TabComponent) => (
                <NavLink {...otherProps} to={generatePath('consigne-libre')} end>
                  {children}
                </NavLink>
              )}>
              <ParkingIcon width={16} /> {upperFirst(t('freePark'))}
            </Tab>
          </TabsList>
        </Tabs>
      </div>

      <React.Suspense fallback={<></>}>
        <Outlet />
      </React.Suspense>
    </div>
  );
}

export default ProductsLayout;
