import { useInstanceApi } from '../../../api/axios';
import { useRequest } from '../../../utils/request';

const URL = {
  fetchContactParameters: 'param/contact',
  putContactParameters: 'param/contact',
};

function useContactParametersApi() {
  const instance = useInstanceApi();

  const { request: fetchContactParameters } = useRequest((queryParams) =>
    instance.get(URL.fetchContactParameters, { params: queryParams })
  );

  const { request: putContactParameters } = useRequest((data) => instance.post(URL.putContactParameters, data));

  return {
    fetchContactParameters,
    putContactParameters,
  };
}

export default useContactParametersApi;
