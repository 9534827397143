import React, { ForwardRefRenderFunction, InputHTMLAttributes } from 'react';
import classNames from 'classnames/bind';
import styles from './RadioButton.module.scss';

const cx = classNames.bind(styles);

type RadioButtonProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> & {
  label: string;
};

const RadioButton: ForwardRefRenderFunction<HTMLInputElement, RadioButtonProps> = (props, ref) => {
  const { label, className, ...propsToForward } = props;

  return (
    <label
      className={cx(styles.Wrapper, {
        [styles.Selected]: propsToForward.checked,
        [styles.Disabled]: propsToForward.disabled,
      })}>
      <input ref={ref} className={cx(styles.Input, className)} type={'radio'} {...propsToForward} />
      {label}
    </label>
  );
};

export default React.forwardRef(RadioButton);
