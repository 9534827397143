import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import DataHub from 'fragments/dataHub/DataHub';
import Checkbox from 'fragments/checkbox/Checkbox';
import useApi, { Methods } from 'internal/hooks/useApi';
import { setSnackbar, showSnackbar } from 'internal/actions/appActions';
import SnackbarSeverity from 'internal/constants/snackbar';
import useRefresh from 'internal/hooks/useRefreshList';
import { selectCollAndDeliveryPointID } from 'auth/selectors/authSelectors';
import { ItemType } from 'parameters/types/parameters';
import { PROFILES_ENDPOINT, PROFILES_ADD_ENDPOINT, PROFILES_UPDATE_ENDPOINT } from 'parameters/constants/endpoints';
import { AUTH_SIDEBAR_ENDPOINT } from 'auth/constants/endpoints';
import { saveUserRights } from 'auth/actions/authActions';
import { formatUserRights } from 'auth/services/authServices';
import { RawUserRightsType } from 'auth/types/user';
import useIsMobile from 'internal/hooks/useIsMobile';
import Status from 'internal/constants/status';

const ParametersPermission = (): ReactElement => {
  const { t } = useTranslation('old');
  const [perPage, setPerPage] = useState(10);
  const [maxItems, saveMaxItems] = useState(10);
  const [page, setPage] = useState(1);
  const [orderColumn, setOrderColumn] = useState('');
  const [order, setOrder] = useState('');
  const [filter, setFilter] = useState('');
  const [profilsCols, setProfils] = useState<string[]>([]);
  const [emptyprofil, setEmptyprofil] = useState('');

  const [toSend, setToSend] = useState<Record<string, Record<string, { value: number; id: number }>>>({});
  const { request: refreshRights, response: responseRights } = useApi<RawUserRightsType>(
    Methods.GET,
    AUTH_SIDEBAR_ENDPOINT
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (responseRights?.success && responseRights.data) {
      const _userRights = formatUserRights(responseRights.data);

      dispatch(saveUserRights(_userRights));
    }
  }, [responseRights]);
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);

  const {
    request: requestProfiles,
    response: responseProfiles,
    status,
  } = useApi<{
    profils: string[];
    total: number;
    result: ItemType[];
  }>(Methods.GET, PROFILES_ENDPOINT);
  const { request: addProfil, response: responseAddProfil } = useApi(Methods.POST, PROFILES_ADD_ENDPOINT);
  const { request: updateProfil, response: responseUpdateProfil } = useApi(Methods.POST, PROFILES_UPDATE_ENDPOINT);

  const getListProfiles = useCallback(() => {
    requestProfiles({
      params: {
        id_coll,
        p: page,
        ipp: perPage,
        'order[column]': orderColumn,
        'order[dir]': order,
        filter,
      },
    });
  }, [id_coll, page, perPage, orderColumn, order, filter]);

  useEffect(() => {
    getListProfiles();
  }, [getListProfiles]);

  useEffect(() => {
    if (responseProfiles?.data?.total === 0 || responseProfiles?.data?.total) {
      saveMaxItems(responseProfiles?.data?.total);
    }
  }, [responseProfiles?.data?.total]);

  useEffect(() => {
    if (responseProfiles?.data?.profils) {
      setProfils(responseProfiles?.data?.profils);
    }
  }, [responseProfiles?.data?.profils]);

  const refreshProfiles = useRefresh(getListProfiles, 1000);

  const refresh = useCallback(() => {
    refreshRights({
      params: {
        id_coll,
      },
    });
  }, [refreshRights, id_coll]);

  const _refresh = useRefresh(refresh, 1000);

  const onSubmitAddProfil = useCallback(() => {
    if (id_coll) {
      addProfil({
        id_coll,
        profil_name: (document.getElementById('addprofil_name') as HTMLInputElement)?.value,
      });

      refreshProfiles();
      _refresh();
      setEmptyprofil('');
    }
  }, [id_coll, getListProfiles]);

  useEffect(() => {
    if (responseAddProfil?.success && responseAddProfil?.data) {
      dispatch(setSnackbar(t('parameters.right_add_success'), SnackbarSeverity.SUCCESS));
      dispatch(showSnackbar());
    }
  }, [responseAddProfil?.success, responseAddProfil?.data]);

  const handleChangeState = useCallback(
    (droit: string, profileNom: string, profilId: number) => (value: boolean) => {
      setToSend((prevState) => ({
        ...prevState,
        [droit]: {
          ...prevState[droit],
          [profileNom]: { value: Number(value), id: profilId },
        },
      }));
    },
    []
  );

  const profileExemple = useMemo((): Record<string, Record<string, ReactElement>> | null => {
    if (toSend) {
      return Object.keys(toSend).reduce((acc, droit) => {
        return {
          ...acc,
          [droit]: Object.keys(toSend[droit]).reduce((profileAcc, profile, i) => {
            return {
              ...profileAcc,
              [profile]: (
                <Checkbox
                  key={`checkbox-${droit}-${i}`}
                  checked={!!toSend[droit][profile].value}
                  handleChange={handleChangeState(droit, profile, toSend[droit][profile].id)}
                />
              ),
            };
          }, {}),
        };
      }, {});
    }
    return null;
  }, [toSend]);

  useEffect(() => {
    if (responseProfiles?.success && responseProfiles.data) {
      setToSend(
        responseProfiles?.data?.result.reduce((acc, item) => {
          return {
            ...acc,
            [item.droit]: item.profils.reduce((profileAcc, profile) => {
              return {
                ...profileAcc,
                [profile.nom_profil]: { value: !!profile.value, id: profile.id_profil },
              };
            }, {}),
          };
        }, {})
      );
    }
  }, [responseProfiles, order]);

  const submitProfil = useCallback(
    (droit: string) => () => {
      const dataToSend = Object.keys(toSend[droit]).map((profil) => [
        { droit_name: String(droit) },
        { droit_id: Number(toSend[droit][profil].id) },
        { droit_value: Number(toSend[droit][profil].value) },
      ]);

      if (id_coll) {
        updateProfil({
          id_coll,
          data_profils: JSON.stringify(dataToSend),
        });
        _refresh();
      }
    },
    [id_coll, toSend, _refresh]
  );

  const profilesListItems = useMemo(() => {
    return (
      profileExemple &&
      Object.keys(profileExemple).map((droit) => {
        return {
          droit: (
            <div className={'row'}>
              <div className={'col-md-12'}>
                <div>{droit}</div>
              </div>
            </div>
          ),
          ...profileExemple[droit],
          actions: (
            <div className={'row m-auto'}>
              <div className={'col-md-12'}>
                <div className={'row'}>
                  <button className={'btn btn-primary btn-adapt'} onClick={submitProfil(droit)}>
                    {t('parameters.right_update')}
                  </button>
                </div>
              </div>
            </div>
          ),
        };
      })
    );
  }, [responseProfiles, profileExemple, submitProfil, t]);

  useEffect(() => {
    if (responseUpdateProfil?.success && responseUpdateProfil?.data) {
      dispatch(setSnackbar(t('parameters.right_update_success'), SnackbarSeverity.SUCCESS));
      dispatch(showSnackbar());
    }
  }, [responseUpdateProfil?.success, responseUpdateProfil?.data, t]);

  const handleFilters = useCallback(
    (column: string) => (orderDirection: string) => {
      setOrder(orderDirection);
      setOrderColumn(column);
    },
    []
  );

  const dataTableProfiles = useMemo(
    () => ({
      cols: ['droit', ...profilsCols, 'actions'],
      colHandlers: [handleFilters('droit')],
      colTraductionScope: '',
      items: profilesListItems || [],
      custom: true,
      style: {
        droit: { minWidth: '230px' },
      },
    }),
    [profilesListItems, handleFilters, profilsCols]
  );
  const isMobile = useIsMobile();

  return (
    <>
      <p className={'lead'}>{t('parameters.right_list_title')}</p>
      <div className={isMobile ? 'row o-scroll' : 'row'}>
        <div className={'col-md-12 o-scroll'}>
          <DataHub
            isSearchActive={true}
            setSearch={setFilter}
            setPerPage={setPerPage}
            dataTableProps={dataTableProfiles}
            maxItems={maxItems}
            perPage={perPage}
            page={page}
            setPage={setPage}
            isLoading={status === Status.LOADING}
          />
        </div>
      </div>
      <div className={'row'}>
        <div className={'col-md-6'}>
          <p>{t('parameters.right_create_title')}</p>
          <input
            type={'text'}
            className={'form-control'}
            id={'addprofil_name'}
            placeholder={t('parameters.profileName')}
            value={emptyprofil}
            onChange={(e) => setEmptyprofil(e.target.value)}
          />
          <button id={'add_profil'} className={'btn btn-w-xl btn-primary'} onClick={onSubmitAddProfil}>
            {t('parameters.right_add_title')}
          </button>
        </div>
      </div>
    </>
  );
};

export default ParametersPermission;
