import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectCurrency } from 'internal/selectors/currencySelectors';
import { USER_TARIF_ENDPOINT } from 'users/constants/endpoints';
import useApi, { Methods } from 'internal/hooks/useApi';
import { UserAccessoryType } from 'users/types/users';
import { selectCollAndDeliveryPointID } from 'auth/selectors/authSelectors';

type Props = {
  deposit: number;
  securityDeposit: number;
  duration: number;
  articleID: number | undefined;
  accessories: UserAccessoryType[];
  isBookingEnabled: boolean | undefined;
};

const NewBookingSummary = ({
  deposit,
  securityDeposit,
  articleID,
  duration,
  accessories,
  isBookingEnabled,
}: Props): ReactElement => {
  const { t } = useTranslation('old');
  const currency = useSelector(selectCurrency);
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const [bookingPrice, setBookingPrice] = useState<number>(0);

  const { request: fetchPrice, response: priceResponse } = useApi<{ montant_ttc: string }>(
    Methods.GET,
    USER_TARIF_ENDPOINT,
    {
      noSnack: true,
    }
  );

  useEffect(() => {
    if (articleID && duration && accessories) {
      fetchPrice({
        params: {
          id_coll,
          id_article: articleID,
          time_unit: duration,
          accessoires: accessories.map(({ id_type }) => id_type).join(','),
        },
      });
    }
  }, [articleID, duration, id_coll, accessories]);

  useEffect(() => {
    if (priceResponse?.data?.montant_ttc) {
      setBookingPrice(parseFloat(priceResponse.data.montant_ttc));
    }
  }, [priceResponse]);

  const renderDeposit = useMemo(() => {
    if (deposit > 0) {
      return (
        <>
          <span>
            {t('users.deposit_information', {
              depositAmount: deposit,
              currency,
            })}
          </span>
          <br />
        </>
      );
    }
  }, [deposit, currency, t]);

  const renderSecurityDeposit = useMemo(() => {
    if (securityDeposit > 0 && isBookingEnabled) {
      return (
        <>
          <span>
            {t('users.security_deposit_information', {
              securityDepositAmount: securityDeposit,
              currency,
            })}
          </span>
          <br />
        </>
      );
    }
  }, [securityDeposit, currency, isBookingEnabled, t]);

  const renderTotalPrice = useMemo(() => {
    if (bookingPrice > 0 && isBookingEnabled) {
      return (
        <>
          <span>
            {t('users.total_price_with_accessories', {
              total: bookingPrice.toFixed(2),
              currency,
            })}
          </span>
        </>
      );
    }
  }, [currency, bookingPrice, isBookingEnabled, t]);

  return (
    <>
      <label>{t('users.summary')}</label>
      <div className={'alert alert-info fade show'} role={'alert'} id={'info_cons_park'}>
        {renderDeposit}
        {renderSecurityDeposit}
        {renderTotalPrice}
      </div>
    </>
  );
};

export default NewBookingSummary;
