import { useInstanceApi } from '../../../api/axios';
import { useRequest } from '../../../utils/request';

const API_URLS = {
  fetchDetails: 'display/all',
  postMedia: 'display/asset',
  postNavigationConfig: 'display/navbar',
  postBannerConfig: 'display/banner',
};

type PostNavigationBodyForm = {
  id_coll: number;
  'navbar-background': string;
  'navbar-color': string;
  'navbar-hover': string;
  use_alternative_logo: boolean;
};

function useVisualApi() {
  const instance = useInstanceApi();

  const { request: fetchDetails } = useRequest((params: { collId: number }) =>
    instance.get(API_URLS.fetchDetails, { params })
  );

  //eslint-disable-next-line
  const { request: postMedia } = useRequest((params: { file: any; fileName: string; collId: number }) => {
    return instance.put(API_URLS.postMedia, params.file, {
      params: {
        collId: params.collId,
        mediaType: params.fileName,
      },
    });
  });

  const { request: postNavigationConfig } = useRequest((data: PostNavigationBodyForm) =>
    instance.post(API_URLS.postNavigationConfig, data)
  );

  const { request: postBannerConfig } = useRequest((data: { collId: number; 'banner-opacity': string }) =>
    instance.post(API_URLS.postBannerConfig, data)
  );

  return {
    postMedia,
    fetchDetails,
    postNavigationConfig,
    postBannerConfig,
  };
}

export default useVisualApi;
