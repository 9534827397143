import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Link, useOutletContext } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import { AxiosResponse } from 'axios';
import MainSection from '../../../../components/Core/MainSection/MainSection';
import { selectCollAndDeliveryPointID } from '../../../../auth/selectors/authSelectors';
import { instance } from '../../../../api/user.api';
import Card from '../../../../components/Core/Card/Card';
import { GridColDefinition } from '../../../../components/Core/Datagrid/gridCols';
import Datagrid, { RowItem } from '../../../../components/Core/Datagrid/Datagrid';
import Button from '../../../../components/Core/Button/Button';
import Dialog, { DialogActions, DialogContent, DialogTitle } from '../../../../components/Dialog/Dialog';
import ButtonWithIcon from '../../../../components/Core/Button/ButtonWithIcon';
import useGenerateFields, { Field } from '../../../../hook/useGenerateFields';
import { useSnackbar } from '../../../../context/SnackbarContext';
import { TOAST_SEVERITY } from '../../../../components/Core/Toast/Toast';
import { generatePath } from '../../../../utils/router';
import styles from './Tulip.module.scss';

function useCreateUpdateProductInsuranceFields() {
  const { t } = useTranslation(['insurance/tulip']);
  const keyPrefix = 'productsPage.createUpdateProductInsuranceModal.form.';
  const configurationOptionsKeyPrefix = keyPrefix.concat('configuration.options.');

  const ownT = useCallback((key: string) => t(keyPrefix.concat(key)), [t]);

  return useMemo<Field[]>(() => {
    return [
      { type: 'toggleRadioGroup', label: t(keyPrefix.concat('enabledInsurance.label')), name: 'enabledInsurance' },
      {
        type: 'text',
        label: ownT('brand.label'),
        placeholder: ownT('brand.placeholder'),
        name: 'brand',
        rules: { required: true },
      },
      {
        type: 'text',
        label: ownT('model.label'),
        placeholder: ownT('model.placeholder'),
        name: 'model',
        rules: { required: true },
      },
      {
        type: 'number',
        label: ownT('bikeValue.label'),
        placeholder: ownT('bikeValue.label'),
        name: 'bikeValue',
        rules: { required: true },
      },
      {
        type: 'radio-item',
        options: [
          { label: t(configurationOptionsKeyPrefix.concat('option')), value: 'option' },
          { label: t(configurationOptionsKeyPrefix.concat('inclusion')), value: 'inclusion' },
          { label: t(configurationOptionsKeyPrefix.concat('inclusion_cachee')), value: 'inclusion_cachee' },
        ],
        name: 'configuration',
        label: t(keyPrefix.concat('configuration.label')),
      },
      {
        type: 'number',
        label: ownT('margin.label'),
        placeholder: ownT('margin.label'),
        name: 'margin',
        rules: { required: true },
      },
      {
        type: 'number',
        label: t(keyPrefix.concat('caution.label')),
        name: 'caution',
        placeholder: ownT('caution.label'),
      },
    ];
  }, [t, ownT]);
}

type CreateUpdateProductInsuranceModalProps = {
  row: TulipProductRow;
};

type CreateUpdateProductInsuranceBodyForm = {
  idColl: number;
  title: string;
  enabledInsurance: boolean;
  configuration: string;
  bikeValue: number | null;
  margin?: number | null;
  caution?: number | null;
  subType: string;
  // tulipId: string;
};

function CreateUpdateProductInsuranceModal(props: CreateUpdateProductInsuranceModalProps) {
  //eslint-disable-next-line
  const tulipParamsResponse: AxiosResponse<any> | undefined = useOutletContext();
  const { row } = props;
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const { t } = useTranslation(['insurance/tulip', 'common', 'form']);
  const keyPrefix = 'productsPage.createUpdateProductInsuranceModal.';
  const [open, setIsOpen] = useState(false);
  const fields = useCreateUpdateProductInsuranceFields();
  const queryClient = useQueryClient();
  const defaultValues = useMemo<
    Partial<Omit<CreateUpdateProductInsuranceBodyForm, 'idColl' | 'title' | 'tulipId' | 'subType'>>
  >(() => {
    return {
      bikeValue: row.tulipProduct?.value_excl,
      caution: row.tulipCaution,
      margin: row.tulipMarge,
      configuration: row.tulipConfiguration || 'option',
      enabledInsurance: row.enabledInsurance,
      brand: row.tulipBrand,
      model: row.tulipModel,
    };
  }, [row]);
  const { reset, control, handleSubmit, watch } = useForm<
    Omit<CreateUpdateProductInsuranceBodyForm, 'idColl' | 'title' | 'tulipId' | 'subType'>
  >({ defaultValues });
  const form = useGenerateFields({ fields, control });
  const snackbar = useSnackbar();

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const configuration = watch('configuration');

  const { mutate: postBikeToInsure } = useMutation(
    ['insurance/tulipbiketoinsure'],
    (bodyForm: CreateUpdateProductInsuranceBodyForm) => {
      return instance.post('insurance/tulipbiketoinsure', bodyForm);
    },
    {
      onSuccess: () => {
        snackbar?.setAlert({ message: t('form:global_update_success_message'), severity: TOAST_SEVERITY.success });
        queryClient.invalidateQueries(['insurance/tulipproducts']);
        onCancel();
      },
    }
  );

  const onSubmit = useCallback(
    handleSubmit((data) => {
      const copyData = _.cloneDeep(data);
      if (copyData.configuration !== 'option') delete copyData.margin;

      postBikeToInsure({
        idColl: id_coll,
        title: row.nomType,
        subType: 'cargo',
        // tulipId: tulipParamsResponse?.data.tulipId,
        ...copyData,
      });
    }),
    [id_coll, row, tulipParamsResponse]
  );

  const onCancel = useCallback(() => {
    setIsOpen(false);
    reset();
  }, [reset, setIsOpen]);

  return (
    <React.Fragment key={`test-${row.id}`}>
      <Button color={'secondary'} onClick={() => setIsOpen(true)}>
        {t('productsPage.rowCta')}
      </Button>
      {open && (
        <Dialog style={{ minWidth: 600 }} onCancel={onCancel} open={open}>
          <DialogTitle>
            <span>{t(keyPrefix.concat('title'), { type: row.nomType })}</span>
            <span className={'mandatory-fields-notice'}>{t('form:required_fields_notice_little')}</span>
          </DialogTitle>
          <form onSubmit={onSubmit}>
            <DialogContent>
              <div className={styles.Form}>
                {form[0]}
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 16 }}>
                  {form[1]}
                  {form[2]}
                  {form[3]}
                </div>
                {form[4]}
                {configuration === 'option' && [form[5], form[6]]}
              </div>
            </DialogContent>
            <DialogActions>
              <ButtonWithIcon onClick={onCancel} icon={'CrossWithoutCircle'} color={'secondary'}>
                {t('common:cancel')}
              </ButtonWithIcon>
              <ButtonWithIcon icon={'CheckWithoutCircle'} type={'submit'}>
                {t(keyPrefix.concat('actions.submit'))}
              </ButtonWithIcon>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </React.Fragment>
  );
}

function useProductListColumns() {
  const { t } = useTranslation(['insurance/tulip', 'form', 'common']);
  const keyPrefix = 'productsPage.columns.';
  const configurationOptionsPrefix = 'productsPage.createUpdateProductInsuranceModal.form.configuration.options.';

  return useMemo<GridColDefinition[]>(() => {
    return [
      {
        field: 'nomType',
        headerName: t(keyPrefix.concat('type')),
        type: 'string',
        width: 'minmax(auto, 200px)',
      },
      {
        field: 'enabledInsurance',
        headerName: t(keyPrefix.concat('insured')),
        width: 'minmax(auto, 200px)',
        type: 'custom',
        renderCell: ({ row }) => (
          <RowItem key={`insured-${row.id}`}>{row.enabledInsurance ? t('common:yes') : t('common:no')}</RowItem>
        ),
      },
      {
        field: 'guarantee',
        headerName: t(keyPrefix.concat('guarantee')),
        width: 'minmax(auto, 200px)',
        type: 'custom',
        renderCell: ({ row }) => (
          <RowItem key={`guarantee-${row.id}`}>
            {row?.tulipConfiguration ? t(configurationOptionsPrefix.concat(row.tulipConfiguration)) : '-'}
          </RowItem>
        ),
      },
      {
        field: 'actions',
        type: 'custom',
        renderCell: ({ row }) => (
          <div key={`create-update-modal-${row.id}`} className={'row justify-end'}>
            <CreateUpdateProductInsuranceModal row={row as TulipProductRow} />
          </div>
        ),
      },
    ];
  }, [t]);
}

type TulipProductRow = {
  id: number;
  nomType: string;
  description: string;
  enabledInsurance: boolean;
  tulipProductId: string | null;
  tulipProduct?: {
    uid: string;
    product_type: string;
    data: {
      product_subtype: string;
    };
    source: string;
    created_date: string;
    updated_date: string;
    title: string;
    value_excl: number;
    product_id: string;
  };
  tulipConfiguration?: string;
  tulipMarge?: number | null;
  tulipBrand: string;
  tulipModel: string;
  tulipCaution?: number | null;
};

type TulipProductsResponse = TulipProductRow[];

function TulipProductsPage() {
  //eslint-disable-next-line
  const tulipParamsResponse: AxiosResponse<any> | undefined = useOutletContext();
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const { t } = useTranslation(['insurance/tulip', 'form']);
  const ownT = useCallback((key: string) => t('productsPage.'.concat(key)), [t]);
  const columns = useProductListColumns();

  const { data: tulipProductsResponse } = useQuery(
    ['insurance/tulipproducts', { idColl: id_coll, tulipId: tulipParamsResponse?.data.tulipId }],
    ({ queryKey }) => {
      const [, queryParams] = queryKey;
      return instance.get<TulipProductsResponse>('insurance/tulipproducts', { params: queryParams });
    },
    {
      staleTime: 800_000,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <MainSection className={'reset'}>
      <Card title={t('productsPage.title')}>
        <div>
          {tulipProductsResponse?.data && tulipProductsResponse.data.length > 0 ? (
            <Datagrid rows={tulipProductsResponse?.data || []} columns={columns} />
          ) : (
            <div className={styles.Placeholder}>
              <p>
                <strong>{ownT('placeholder.title')}</strong>
                <br />
                {ownT('placeholder.description')}
              </p>
              <Button as={Link} to={generatePath('home.admin.insurance.tulip')}>
                {ownT('placeholder.cta')}
              </Button>
            </div>
          )}
        </div>
      </Card>
    </MainSection>
  );
}

export default TulipProductsPage;
