import { DeliveryPointType, UserCollectivityType } from 'auth/types/collectivity';
import { UserRightsType, UserType } from 'auth/types/user';
import { RootState } from 'internal/rootReducer';

export const selectUser = (state: RootState): UserType | null => state.authState.user;

export const selectCollectivity = (state: RootState): number => state.authState.collectivity;

export const selectDeliveryPoint = (state: RootState): number => state.authState.deliveryPoint;

export const selectSessionToken = (state: RootState): string | null => state.authState.user?.token || null;

export const selectUserRights = (state: RootState): UserRightsType | null => state.authState.userRights;

export const selectCollAndDeliveryPointID = (
  state: RootState
): {
  id_coll: number;
  id_pdl: number;
} => ({
  id_coll: state.authState.collectivity || 0,
  id_pdl: state.authState.deliveryPoint || 0,
});

export const selectCollectivityList = (state: RootState): UserCollectivityType[] => state.authState.collectivityList;

export const selectDeliveryPointList = (state: RootState): DeliveryPointType[] => state.authState.deliveryPointList;
