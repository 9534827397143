import { BikeStatusType } from '../../park/types/park';
import { instance, ListQueryParams } from '../user.api';

const URL = {
  fetchList: 'parc/velos',
  putAffectation: 'parc/affvelo',
  putStatus: 'parc/etatvelo',
};

export type RawBike = {
  id_velo: number;
  id_interne: string;
  bicycode: string;
  available: number;
  id_pdl: number;
  nom_type: string;
  montant_depot_garantie_ttc: string;
  montant_article_ttc: string;
  total_ttc: string;
  nom_tarif: string;
  userName: string;
  id_resa: number;
  id_user: number;
  id_paiement: number;
  availableStatusTo: BikeStatusType[];
  currentStatus: BikeStatusType;
  currentPdl: { id_pdl: string };
  availablePdl: { value: string; id_aff: number }[];
  velocareLink?: string;
  devise: string;
  id_parent_resa: null | number;
};

export async function getBikeList(queryParams: ListQueryParams & { id_coll: number; id_pdl: number }) {
  return await instance.get<{
    currentPage: number;
    itemPerPage: number;
    // eslint-disable-next-line
    filters: any;
    // eslint-disable-next-line
    result: RawBike[];
    total: number;
    totalPage: number;
    //eslint-disable-next-line
    order: any;
  }>(URL.fetchList, {
    params: queryParams,
  });
}

type PutAffectationBody = {
  id_coll: number;
  id_pdl: number;
  id_velo: number;
  id_aff: string;
};

export async function putAffectation(putBody: PutAffectationBody) {
  return await instance.post(URL.putAffectation, putBody);
}

type PutStatusBody = {
  id_coll: number;
  id_pdl: number;
  id_velo: number;
  id_etat: string;
};

export async function putStatus(putBody: PutStatusBody) {
  return await instance.post(URL.putStatus, putBody);
}
