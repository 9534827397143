import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

function useGetFormErrors() {
  const { t } = useTranslation('form');

  return useMemo(
    () => ({
      'any.required': t('errors.required'),
      'number.base': t('errors.number'),
      'string.base': t('errors.required'),
      'string.empty': t('errors.required'),
      'string.invalidIBAN': t('errors.invalidFormat'),
      'string.invalidSWIFTBIC': t('errors.invalidFormat'),
      'string.required': t('errors.required'),
      'string.email': t('errors.email'),
      'string.min': t('errors.invalidFormat'),
      'any.invalid': t('errors.invalidOption'),
      'passwordComplexity.uppercase': t('errors.invalidFormat'),
      'passwordComplexity.tooLong': t('errors.invalidFormat'),
      'passwordComplexity.tooShort': t('errors.invalidFormat'),
    }),
    [t]
  );
}

export default useGetFormErrors;
