import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { AxiosResponse } from 'axios';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { QueryFunction } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RawGenericListReturn } from '../../../api/params/vouchers.api';
import { fetchUserCheckout } from '../../../api/user.api';
import { selectCollAndDeliveryPointID } from '../../../auth/selectors/authSelectors';
import ButtonWithIcon from '../../../components/Core/Button/ButtonWithIcon';
import { RowItem } from '../../../components/Core/Datagrid/Datagrid';
import { GridColDefinition } from '../../../components/Core/Datagrid/gridCols';
import MainSection from '../../../components/Core/MainSection/MainSection';
import Dialog, { DialogActions, DialogContent, DialogTitle } from '../../../components/Dialog/Dialog';
import useGenerateFields from '../../../hook/useGenerateFields';
import GenericList from '../../Parameters/Voucher/components/GenericList';
import { ListParams } from '../../park/BikeList/BikeList';
import styles from '../../Reservation/bookingDetails.module.scss';
import { TRANSACTION_TYPES } from '../../Reservation/CheckoutList.page';
import { useRefundFormFields, useRegularizationFormFields } from '../../Reservation/constants';

function useColumns(): GridColDefinition[] {
  const { t } = useTranslation('reservation/reservation');

  return useMemo(() => {
    return [
      {
        field: 'date_created',
        headerName: t('receiptsPage.columns.dateCheckout'),
        type: 'date',
      },
      {
        field: 'montant_ttc',
        type: 'custom',
        headerName: t('receiptsPage.columns.amount'),
        renderCell: ({ row }) => (
          <RowItem>
            {row.montant_ttc}
            {row.devise}
          </RowItem>
        ),
      },
      {
        field: 'operation',
        headerName: t('receiptsPage.columns.checkoutOperation'),
        type: 'string',
      },
      {
        field: 'moyen_paiement',
        headerName: t('receiptsPage.columns.paymentMethod'),
        type: 'custom',
        renderCell: ({ row }) => <RowItem>{row.moyen_paiement}</RowItem>,
      },
    ];
  }, [t]);
}

function CreateCheckout() {
  const { t } = useTranslation(['reservation/reservation', 'common']);
  const [layoutElement, setLayoutElement] = useState<Element | null>(null);
  const [open, setIsOpen] = useState(false);
  const { control, handleSubmit, watch, reset } = useForm();
  const choiceTypeTransactionFieldElements = useGenerateFields({
    control,
    fields: useMemo(
      () => [
        {
          type: 'radio-item',
          name: 'transactionType',
          label: t('transactionType'),
          options: [
            { label: t('receiptsPage.regularization_title'), value: TRANSACTION_TYPES.regularization },
            { label: t('receiptsPage.refund_title'), value: TRANSACTION_TYPES.refund },
          ],
        },
      ],
      [t]
    ),
  });
  const transactionType: TRANSACTION_TYPES = watch('transactionType');
  const regularizationForm = useRegularizationFormFields();
  const refundForm = useRefundFormFields();

  const onCancel = useCallback(() => {
    setIsOpen(false);
    reset();
  }, [reset, setIsOpen]);

  useLayoutEffect(() => {
    setLayoutElement(document.querySelector('#user-layout'));
  }, []);

  const transactionForm = useMemo<
    { onSubmit: (onSuccess: () => void) => () => void; fieldElements: (JSX.Element | undefined)[] } | undefined
  >(() => {
    switch (transactionType) {
      case TRANSACTION_TYPES.regularization:
        return regularizationForm;
      case TRANSACTION_TYPES.refund:
        return refundForm;
    }
  }, [handleSubmit, transactionType, regularizationForm, refundForm]);

  return layoutElement
    ? ReactDOM.createPortal(
        <React.Fragment key={'create-checkout'}>
          <ButtonWithIcon onClick={() => setIsOpen(true)} icon={'AddWithoutCircle'} color={'major'}>
            {t('receiptsPage.newRental')}
          </ButtonWithIcon>
          {open && (
            <Dialog style={{ minWidth: 600 }} onCancel={onCancel} open={open}>
              <DialogTitle>{t('receiptsPage.newRental')}</DialogTitle>
              <form onSubmit={transactionForm?.onSubmit(onCancel)}>
                <DialogContent className={styles.CreateCheckoutModal__form}>
                  {choiceTypeTransactionFieldElements}
                  {transactionType ? transactionForm?.fieldElements : null}
                </DialogContent>
                {transactionType && (
                  <DialogActions>
                    <ButtonWithIcon onClick={onCancel} icon={'CrossWithoutCircle'} color={'secondary'}>
                      {t('common:cancel')}
                    </ButtonWithIcon>
                    <ButtonWithIcon icon={'CheckWithoutCircle'} type={'submit'}>
                      {t('receiptsPage.collection_btn')}
                    </ButtonWithIcon>
                  </DialogActions>
                )}
              </form>
            </Dialog>
          )}
        </React.Fragment>,
        layoutElement
      )
    : null;
}

function CheckoutListPage() {
  const { userId } = useParams();
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const columns = useColumns();

  const fetchCheckouts: QueryFunction<AxiosResponse<RawGenericListReturn> | undefined> = ({ queryKey }) => {
    const [, queryParams, listQueryParams] = queryKey as [string, { id_coll: number; id_user?: number }, ListParams];

    if (queryParams.id_user) {
      return fetchUserCheckout({
        id_coll: queryParams.id_coll,
        id_user: queryParams.id_user,
        ipp: listQueryParams.itemPerPage,
        p: listQueryParams.currentPage,
      });
    }
  };

  return (
    <MainSection>
      <CreateCheckout />
      {/*<CollectionFormCard />
      <RefundFormCard />*/}
      <GenericList
        queryFn={fetchCheckouts}
        queryKey={['user.checkout.list', { id_coll, id_user: userId }]}
        columns={columns}
      />
    </MainSection>
  );
}

export default CheckoutListPage;
