import Button from 'fragments/button/Button';
import Select from 'fragments/select/Select';
import { t } from 'internal/i18n';
import React, { useMemo } from 'react';
import { ReservationType } from 'reservations/types/reservation';

type Props = {
  contract: {
    url: string;
    label: string;
  } | null;
  isPark: boolean;
  otherBikes: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    label: any;
    value: string;
  }[];
  reservation: ReservationType | null;
  setNewBikeID: React.Dispatch<React.SetStateAction<string>>;
  submitStdExchange: (t_ech: string) => () => void;
};

const ReservationExchangeBike = ({
  contract,
  isPark,
  otherBikes,
  reservation,
  setNewBikeID,
  submitStdExchange,
}: Props) => {
  const renderExchangeBike = useMemo(() => {
    if (contract && !isPark && otherBikes.length > 0 && !reservation?.isVelocareSynced) {
      return (
        <>
          <div className={'row'} id={'ech_reservation'}>
            <div className={'col-md-12'}>
              <Select
                label={t('reservations.standard_exchange')}
                name={'std_exch'}
                id={'std_exch'}
                options={otherBikes}
                handleChange={setNewBikeID}
              />
            </div>
            <div className={'col-md-12'}>
              <Button
                label={t('reservations.standard_exchange')}
                className={'btn-primary btn-fit btn-margin'}
                onClick={submitStdExchange('std')}
              />
              <Button
                label={t('reservations.std_exchange_stolen')}
                className={'btn-primary btn-fit'}
                onClick={submitStdExchange('vol')}
              />
            </div>
          </div>
          <br />
        </>
      );
    }

    return null;
  }, [contract, isPark, otherBikes, reservation, setNewBikeID, submitStdExchange]);

  return <>{renderExchangeBike}</>;
};

export default ReservationExchangeBike;
