import { RouteObject as RouteObjectForRouterDom } from 'react-router-dom';
import { generatePath } from './router';

type RouteObject<T> = {
  path: string;
  element: JSX.Element;
  children?: ChildrenRouteObject<T>;
  metadata?: {
    name?: string;
    authorization?: string;
  };
};

type ChildrenRouteObject<Parent> = Parent extends { children: infer T }
  ? { [key in keyof T]: RouteObject<T> }
  : //eslint-disable-next-line
    { [key: string]: RouteObject<any> };

class Route<T = never, K extends keyof T = keyof T> {
  private parent: string | undefined;
  public children: { [key in K]: Route } = {} as never;

  constructor(
    private path: string,
    public element: JSX.Element,
    children?: T,
    public metadata?: RouteObject<T>['metadata']
  ) {
    if (children)
      this.children = Object.entries(children).reduce(
        (prev, [key, child]) => ({
          ...prev,
          [key]: new Route<typeof child>(child.path, child.element, child.children, child.metadata).setParent(path),
        }),
        {} as { [key in K]: Route }
      );
  }

  // eslint-disable-next-line
  toLink(params?: any): string {
    return generatePath(this.parent ? this.parent.concat(this.path) : this.path, params);
  }

  setParent(parentPath: string) {
    this.parent = parentPath;
    Object.values<Route>(this.children).forEach((child) => child.setParent(parentPath.concat(child.parent || '')));

    return this;
  }

  formatForRouter(): RouteObjectForRouterDom {
    const route = {
      path: this.path.replace('/', ''),
      element: this.element,
    };

    if (this.children) {
      Object.assign(route, {
        //eslint-disable-next-line
        children: Object.values<Route<any>>(this.children).map((child) => child.formatForRouter()),
      });
    }

    return route;
  }

  pushRoute(newRoute: Route<never, never>) {
    Object.assign(this.children, newRoute.setParent(this.path));
  }

  subChildren(name: K): Route<T[K] extends { children: infer X } ? X : never> {
    return this.children[name] as Route<T[K] extends { children: infer X } ? X : never>;
  }
}

export default Route;
