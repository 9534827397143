/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/fr';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import Weekday from 'dayjs/plugin/weekday';
import _ from 'lodash';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import useControlled from '../../hook/useControlled';
import CaseSchedule, { CaseScheduleRoot } from '../CaseSchedule/CaseSchedule';
import Checkbox from '../Core/Checkbox/Checkbox';

dayjs.extend(Weekday);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale('fr');

const HOUR = 60;
const STEP = 30;
const STARTDAY = HOUR * 7; // 7:00
const ENDDAY = HOUR * 21; // 21:00

export function parseNumber(number: number, digits = 2) {
  return Number(number).toLocaleString(undefined, { minimumIntegerDigits: digits });
}

// function formatTimeWithMinutes(minutes: number) {
//   return `${parseNumber(Math.floor(minutes / 60))}:${parseNumber(Math.floor(minutes % 60))}`;
// }

function getDurationsByDay(day: Dayjs) {
  const startDayDate = day.startOf('D').set('minutes', STARTDAY);
  return Array.from(new Array((ENDDAY - STARTDAY) / STEP + 1)).map((_, i) => {
    const copyDay = startDayDate.add(i * STEP, 'minutes');
    return {
      value: copyDay,
      label: copyDay.format('HH:mm'),
    };
  });
}

const DAY_FORMAT = 'dddd DD MMMM';

function Schedule(props: {
  startDateOfWeek?: Dayjs;
  defaultValues?: Record<string, Dayjs[]>;
  onDayChange?: (day: string, durationOpened: Dayjs[]) => void;
}) {
  const { startDateOfWeek = dayjs().weekday(0), defaultValues } = props;
  const [durationsByDay, setDurationsByDay] = useState<Record<string, Dayjs[]>>(defaultValues || {});

  const handleDurationsDayChange = useCallback(
    (day: string, durationsOpened: Dayjs[]) => {
      setDurationsByDay((prev) => ({ ...prev, [day]: durationsOpened }));
      props.onDayChange?.(day, durationsOpened);
    },
    [setDurationsByDay, props.onDayChange]
  );

  useEffect(() => {
    if (defaultValues) setDurationsByDay(defaultValues);
  }, [defaultValues]);

  return (
    <div
      css={css`
        display: flex;
      `}>
      {[
        startDateOfWeek.weekday(0),
        startDateOfWeek.weekday(1),
        startDateOfWeek.weekday(2),
        startDateOfWeek.weekday(3),
        startDateOfWeek.weekday(4),
        startDateOfWeek.weekday(5),
        startDateOfWeek.weekday(6),
      ].map((day) => {
        return (
          <DaySchedule
            durationsOpened={durationsByDay[day.format('YYYY-MM-DD')] || []}
            onChange={(durationsOpened) => handleDurationsDayChange(day.format('YYYY-MM-DD'), durationsOpened)}
            day={day}
            key={day.format(DAY_FORMAT)}
          />
        );
      })}
    </div>
  );
}

function DaySchedule(props: { day: Dayjs; durationsOpened?: Dayjs[]; onChange: (durationsOpened: Dayjs[]) => void }) {
  const { day, onChange, durationsOpened: durationsOpenedProp } = props;
  const DURATIONS_BY_DAY = getDurationsByDay(day);
  const [durationsOpened, setDurationsOpened] = useControlled<Dayjs[]>({
    default: [],
    controlled: durationsOpenedProp,
    name: 'DaySchedule',
    state: 'durationsOpened',
  });

  const handleChangeCase = useCallback(
    (label: string, isToggle: boolean) => {
      const selectedDuration = DURATIONS_BY_DAY.find((duration) => duration.label === label);
      if (selectedDuration) {
        if (isToggle) {
          const newDurations = [...durationsOpened, selectedDuration.value];
          setDurationsOpened(newDurations);
          onChange(newDurations);
        } else {
          const index = durationsOpened.findIndex((durationOpened) => durationOpened.isSame(selectedDuration.value));
          const newDurations = [...durationsOpened];
          newDurations.splice(index, 1);
          setDurationsOpened(newDurations);
          onChange([...newDurations]);
        }
      }
    },
    [durationsOpened]
  );

  const handleChangeCheckbox = useCallback(
    (day: string, checked: boolean) => {
      checked ? onChange(DURATIONS_BY_DAY.map((duration) => duration.value)) : onChange([]);
    },
    [onChange]
  );

  const shouldBeChecked = useMemo(() => {
    return _.isEqual(
      _.sortBy(durationsOpened.map((date) => date.format('YYYY/MM/DD HH:mm'))),
      DURATIONS_BY_DAY.map((duration) => duration.value.format('YYYY/MM/DD HH:mm'))
    );
  }, [durationsOpened]);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;
      `}>
      <div
        css={css`
          align-self: center;
          padding: 16px;
        `}>
        <Checkbox
          checked={shouldBeChecked}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleChangeCheckbox(day.format(DAY_FORMAT), e.target.checked)
          }
        />
      </div>

      <CaseScheduleRoot
        css={css`
          text-transform: uppercase;
        `}>
        {day.format(DAY_FORMAT)}
      </CaseScheduleRoot>
      {DURATIONS_BY_DAY.map((duration) => {
        return (
          <CaseSchedule
            key={day.format(DAY_FORMAT).concat(duration.label)}
            label={duration.label}
            checked={!!durationsOpened.find((durationOpened) => durationOpened.isSame(duration.value))}
            onChange={handleChangeCase}
          />
        );
      })}
    </div>
  );
}

export default Schedule;
