/** @jsxImportSource @emotion/react */
import React, { ComponentPropsWithoutRef } from 'react';
import styled from '@emotion/styled/macro';
import { css, Theme } from '@emotion/react';
import { NavLink } from 'react-router-dom';
import Accordion from '../Core/Accordion/Accordion';
import AccordionSummary from '../Core/Accordion/AccordionSummary';
import AccordionDetails from '../Core/Accordion/AccordionDetails';
import { getColor } from '../../utils/style';
import SubMenuLink from './SubMenuLink';

type MenuCategory = {
  title: string;
  icon?: JSX.Element;
  to?: string;
  end?: boolean;
  subCategories?: (Omit<MenuCategory, 'subCategories'> & {
    to: string;
  })[];
};

type MenuItemProps = ComponentPropsWithoutRef<typeof Accordion> & {
  onSubItemClick?: (subCategory: string) => void;
  subItemSelected?: string | boolean;
  category: MenuCategory;
};

function MenuItem({ category, onSubItemClick, ...accordionProps }: MenuItemProps) {
  return (
    <Accordion {...accordionProps}>
      {category.subCategories && category.subCategories?.length > 0 ? (
        <>
          <CategoryRoot>
            <Circle>{category.icon}</Circle>
            <span>{category.title}</span>
          </CategoryRoot>
          <SubMenu>
            {category.subCategories?.map((subCategory, index) => (
              <SubMenuLink
                key={subCategory.title + index}
                to={subCategory.to}
                end={subCategory.end || false}
                onClick={() => onSubItemClick?.(subCategory.title)}>
                <Ellipse />
                <span>{subCategory.title}</span>
              </SubMenuLink>
            ))}
          </SubMenu>
        </>
      ) : (
        <NavLink
          css={css`
            display: block;
          `}
          to={category.to || '/'}>
          <CategoryRoot expandIcon={<></>}>
            <Circle>{category.icon}</Circle>
            <span>{category.title}</span>
          </CategoryRoot>
        </NavLink>
      )}
    </Accordion>
  );
}

const Circle = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;

  /*> svg {
    color: white;
  }*/
`;

const menuButtonStyle = (props: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  background-color: transparent;
  font-size: 14px;
  width: calc(100% - 8px);
  margin-right: auto;
  padding-right: 0;
  border-radius: 8px;
  //transition: all 0.4s;

  & > div:first-of-type {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
  }

  & > div:nth-of-type(2) {
    padding-right: 8px;
  }

  & span:nth-of-type(2) {
    padding: 8px 16px;
  }

  :hover ${Circle} {
    background-color: #3c4553;
  }

  &[aria-expanded='true'] {
    background-color: #3c4553;
  }

  &[aria-expanded='true'] ${Circle} {
    background-color: ${getColor('primary.200')(props)};
  }
`;

const CategoryRoot = styled(AccordionSummary)`
  ${menuButtonStyle}
`;

const SubMenu = styled(AccordionDetails)`
  padding: 8px 0;
`;

export const Ellipse = styled.span`
  display: block;
  width: 8px;
  height: 8px;
  border: solid 1px currentColor;
  border-radius: 50%;
  margin: 0 8px;
`;

export default MenuItem;
