import React, { HTMLAttributes, PropsWithChildren, useMemo } from 'react';
import styles from './card.module.scss';

type CardProps<T extends React.ElementType> = Omit<HTMLAttributes<HTMLDivElement>, 'content' | 'title'> &
  PropsWithChildren<{
    color?: 'primary' | 'default';
    as?: T;
    title?: string | ((baseStyle?: string) => React.ReactNode);
    disabled?: boolean;
    content?: React.ReactNode;
  }>;

function Card<T extends React.ElementType = 'button'>(
  props: CardProps<T> & Omit<React.ComponentPropsWithoutRef<T>, keyof CardProps<T>>
) {
  const {
    children,
    content,
    disabled = false,
    title,
    color = 'default',
    className: classNameProp,
    as: Component = 'div',
    ...otherProps
  } = props;

  const className = useMemo(() => {
    const classNameArray: string[] = [styles.Card];
    if (color === 'primary') classNameArray.push(styles.CardPrimary);
    if (disabled) classNameArray.push(styles.CardDisabled);
    if (classNameProp) classNameArray.push(classNameProp);

    return classNameArray.join(' ');
  }, [classNameProp, color, disabled]);

  return (
    <Component className={className} {...otherProps}>
      {title && (typeof title === 'string' ? <div className={styles.CardTitle}>{title}</div> : title(styles.CardTitle))}
      {content ? <CardContent>{content}</CardContent> : children}
    </Component>
  );
}

export function CardContent(props: PropsWithChildren<unknown>) {
  return <div className={'card-content'}>{props.children}</div>;
}

export default Card;
