/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getAttachments, getVouchers, postAttachment } from '../../api/reservation.api';
import { selectCollAndDeliveryPointID, selectSessionToken } from '../../auth/selectors/authSelectors';
import ButtonWithIcon from '../../components/Core/Button/ButtonWithIcon';
import Card from '../../components/Core/Card/Card';
import Datagrid, { renderCell, Row, RowItem } from '../../components/Core/Datagrid/Datagrid';
import { GridColDefinition } from '../../components/Core/Datagrid/gridCols';
import DropzoneFileInput from '../../components/Core/DropzoneFileInput/DropzoneFileInput';
import Label from '../../components/Core/Label/Label';
import MainSection from '../../components/Core/MainSection/MainSection';
import Dialog, { DialogActions, DialogContent, DialogTitle } from '../../components/Dialog/Dialog';
import { ChevronIcon } from '../../const/icons';
import { useThemeUtils } from '../../utils/style';
import { ListParams } from '../park/BikeList/BikeList';
import IconButton from '../../components/Core/Button/IconButton';
import { useConfirm } from '../../components/Confirm/ConfirmContext';
import { instance } from '../../api/user.api';
import { RadioItem } from './components/BookingDetails/FinishCard';
import styles from './bookingDetails.module.scss';

function useColumns(): GridColDefinition[] {
  const { userId: id_user } = useParams();
  const { getColor } = useThemeUtils();
  const { t } = useTranslation(['reservation/reservation', 'common']);
  const { confirm } = useConfirm();
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);

  //eslint-disable-next-line
  const { mutate: deleteDocumentRequest } = useMutation((queryParams: any) =>
    instance.delete('user/pj', { params: queryParams })
  );

  const handleDelete = useCallback(
    //eslint-disable-next-line
    (row: any) => (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      confirm({
        title: t('filesPage.deleteModal.firstConfirm.title'),
        content: (
          <>
            <Card
              className={'mb-4'}
              color={'primary'}
              content={
                <>
                  <Trans
                    i18nKey={'filesPage.deleteModal.firstConfirm.advice'}
                    t={t}
                    components={{ u: <u />, strong: <strong /> }}
                  />
                </>
              }
            />
            <p>
              <Trans
                i18nKey={'filesPage.deleteModal.firstConfirm.text'}
                values={{ documentName: row.type_media }}
                t={t}
                components={{ strong: <strong /> }}
              />
            </p>
          </>
        ),
        yesButton: (
          <ButtonWithIcon icon={'TrashIcon'} color={'danger'}>
            {t('filesPage.deleteModal.firstConfirm.yesCta')}
          </ButtonWithIcon>
        ),
      }).then((result) =>
        result
          ? confirm({
              title: t('filesPage.deleteModal.secondConfirm.title'),
              content: (
                <>
                  <p>
                    <Trans i18nKey={'filesPage.deleteModal.secondConfirm.text'} t={t} />
                  </p>
                </>
              ),
              yesButton: (
                <ButtonWithIcon icon={'TrashIcon'} color={'danger'}>
                  {t('filesPage.deleteModal.secondConfirm.yesCta')}
                </ButtonWithIcon>
              ),
            }).then((result) => (result ? deleteDocumentRequest({ id_coll, id_user, id_media: row.id_media }) : null))
          : null
      );
    },
    [t, deleteDocumentRequest, id_coll, id_user]
  );

  return useMemo(() => {
    return [
      {
        field: 'date_created',
        type: 'date',
        headerName: t('filesPage.columns.date_created'),
      },
      {
        field: 'type_media',
        type: 'string',
        headerName: t('filesPage.columns.type_media'),
      },
      {
        headerName: '',
        field: '',
        type: 'custom',
        width: 120,
        renderCell: ({ row }) => {
          return (
            <RowItem
              css={css`
                display: flex;
                justify-content: flex-end;
                gap: 32px;
              `}>
              {row.date_anonymization ? (
                <span>
                  <i>{t('common:archived')}</i>
                </span>
              ) : (
                <>
                  <IconButton color={'danger'} icon={'TrashIcon'} onClick={handleDelete(row)} />

                  <ChevronIcon
                    width={14}
                    height={14}
                    css={css`
                      color: ${getColor('gray.300')};
                    `}
                  />
                </>
              )}
            </RowItem>
          );
        },
      },
    ];
  }, [t, getColor]);
}

function CreateDocumentModal() {
  const { t } = useTranslation(['reservation/reservation', 'common']);
  const { userId: id_user } = useParams();
  const { control, handleSubmit, reset } = useForm();
  const [layoutElement, setLayoutElement] = useState<Element | null>(null);
  const [open, setIsOpen] = useState(false);
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const queryClient = useQueryClient();

  const { data: voucherList } = useQuery(
    ['user.vouchers', { id_coll }],
    ({ queryKey }) => {
      const [, params] = queryKey as [string, { id_coll: number }];
      return getVouchers({ id_coll: params.id_coll });
    },
    {
      suspense: false,
      staleTime: 450_000,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  const onCancel = useCallback(() => {
    setIsOpen(false);
    reset();
  }, []);

  const { mutate: handlePostAttachment } = useMutation(
    ['user.voucher.post'],
    //eslint-disable-next-line
    (bodyForm: any) => {
      return postAttachment(bodyForm);
    },
    {
      onSuccess: () => {
        // TODO: snackbar
        queryClient.invalidateQueries(['user.voucher.list']);
        onCancel();
      },
    }
  );

  useLayoutEffect(() => {
    setLayoutElement(document.querySelector('#booking-layout'));
  }, []);

  const onSubmit = useCallback(
    handleSubmit((data) => {
      handlePostAttachment({
        id_coll,
        id_user,
        pjName: voucherList?.data['velo'].find((voucher) => voucher.pjId === Number(data.pjId))?.pjName,
        file: data.file,
      });
    }),
    [id_user, id_coll, handlePostAttachment, handleSubmit, voucherList]
  );

  return layoutElement
    ? ReactDOM.createPortal(
        <>
          <ButtonWithIcon onClick={() => setIsOpen(true)} icon={'AddWithoutCircle'} color={'major'}>
            {t('filesPage.title')}
          </ButtonWithIcon>
          {open && (
            <Dialog style={{ minWidth: 600 }} onCancel={onCancel} open={open}>
              <DialogTitle>{t('filesPage.title')}</DialogTitle>
              <form onSubmit={onSubmit}>
                <DialogContent className={styles.CreateDocumentModal__form}>
                  <Controller
                    name={'pjId'}
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <div>
                          <Label>{t('filesPage.fileTypeField.label')}</Label>
                          <div className={styles.RadioGroup}>
                            {voucherList?.data['velo'].map((voucher) => (
                              <RadioItem
                                key={voucher.pjId}
                                selected={Number(value) === voucher.pjId}
                                onChange={onChange}
                                name={'pjId'}
                                value={voucher.pjId}
                                label={voucher.pjName}
                              />
                            ))}
                          </div>
                        </div>
                      );
                    }}
                  />
                  <Controller
                    name={'file'}
                    control={control}
                    render={({ field: { onChange } }) => (
                      <div>
                        <Label>{t('filesPage.fileField.label')}</Label>
                        <DropzoneFileInput onChange={onChange} />
                      </div>
                    )}
                  />
                </DialogContent>
                <DialogActions>
                  <ButtonWithIcon onClick={onCancel} icon={'CrossWithoutCircle'} color={'secondary'}>
                    {t('common:cancel')}
                  </ButtonWithIcon>
                  <ButtonWithIcon icon={'CheckWithoutCircle'} type={'submit'}>
                    {t('filesPage.submitLabel')}
                  </ButtonWithIcon>
                </DialogActions>
              </form>
            </Dialog>
          )}
        </>,
        layoutElement
      )
    : null;
}

function DocumentsPage() {
  const { userId: id_user } = useParams();
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const [listParams, setListParams] = useState<ListParams>({
    order: undefined,
    filters: undefined,
    currentPage: 1,
    itemPerPage: 10,
  });
  const columns = useColumns();
  const token = useSelector(selectSessionToken);

  const { data: attachmentList, isFetching } = useQuery(
    ['user.voucher.list', { ...listParams, id_coll, id_user }],
    ({ queryKey }) => {
      const [, params] = queryKey as [string, ListParams & { id_coll: number; id_user: number }];
      if (params.id_user) {
        return getAttachments({
          p: params.currentPage,
          ipp: params.itemPerPage,
          id_user: params.id_user,
          id_coll: params.id_coll,
        });
      }
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 450_000,
      keepPreviousData: true,
    }
  );

  return (
    <MainSection>
      <CreateDocumentModal />
      {/*<VoucherFormCard id_user={id_user} />*/}
      <Card>
        <Datagrid
          title={'Documents'}
          loading={isFetching}
          rows={attachmentList?.data.result || []}
          columns={columns}
          totalLines={attachmentList?.data.total}
          currentPage={attachmentList?.data.currentPage}
          itemPerPage={attachmentList?.data.itemPerPage}
          nbrPages={attachmentList?.data.totalPage}
          RowComponent={({ row, columns, colsTemplate, index }) => (
            <Row
              colsTemplate={colsTemplate}
              key={'line'.concat(index.toString())}
              target={'_blank'}
              href={`${row.media_url}&token=${token}`}
              as={'a'}>
              {columns.map((column, index) => renderCell(column, row, index))}
            </Row>
          )}
          onSort={(order) => setListParams((prev) => ({ ...prev, order }))}
          handleChangePage={(newPage) => setListParams((prev) => ({ ...prev, currentPage: newPage }))}
          onChangeItemPerPage={(newValue) => setListParams((prev) => ({ ...prev, itemPerPage: newValue as number }))}
        />
      </Card>
    </MainSection>
  );
}

export default DocumentsPage;
