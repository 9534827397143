import React, { useCallback, useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import ParametersPDLs from '../../../parameters/components/collectivity/ParametersPDLs';
import ParametersPDLCreator from '../../../parameters/components/ParametersPDLCreator';
import { setSnackbar, showSnackbar } from '../../../internal/actions/appActions';
import SnackbarSeverity from '../../../internal/constants/snackbar';

function PdlListPage() {
  const { t } = useTranslation(['old']);
  //eslint-disable-next-line
  const collResp: any = useOutletContext();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const [coll, pdls] = useMemo(() => {
    if (collResp.data) {
      return [collResp.data.coll, collResp.data.pdl, collResp.data.homepageText];
    }
    return [null, null];
  }, [collResp]);

  const notifySuccess = useCallback(() => {
    dispatch(setSnackbar(t('parameters.modification_success'), SnackbarSeverity.SUCCESS));
    dispatch(showSnackbar());
  }, [t]);

  return (
    <div className={'main-content'}>
      <div className={'card'}>
        <div className={'card-body'}>
          <ParametersPDLs pdls={pdls} coll={coll} />
          <br />
          <p className={'lead'}>{t('parameters.add_pdl')}</p>
          <ParametersPDLCreator
            onSuccess={notifySuccess}
            onRefresh={() => queryClient.invalidateQueries(['param/coll'])}
          />
        </div>
      </div>
    </div>
  );
}

export default PdlListPage;
