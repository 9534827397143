import { dashboardIcons, dashboardOrder } from 'dashbaord/constants/dashboard';
import { DashboardItemType } from 'dashbaord/types/dashboard';

export const formatRawDashboard = (rawDashboard: Record<string, number>): DashboardItemType[] => {
  return dashboardOrder.map((key) => ({
    id: key,
    value: rawDashboard[key],
    // label: t(`dashboard.${key}`),
    icon: dashboardIcons[key],
  }));
};
