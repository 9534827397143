import React, { useEffect, useMemo } from 'react';
import {
  ActionFunction,
  json,
  LoaderFunction,
  NavLink,
  useFetcher,
  useLoaderData,
  useNavigate,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { QueryClient } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import MockAdapter from 'axios-mock-adapter';
import _ from 'lodash';
import { useMainTitle } from '../../../../components/Layout/MainTitleContext';
import MainSection from '../../../../components/Core/MainSection/MainSection';
import Card from '../../../../components/Core/Card/Card';
import ButtonWithIcon from '../../../../components/Core/Button/ButtonWithIcon';
import store from '../../../../internal/store';
import { instance } from '../../../../api/user.api';
import useDisplayError from '../../../../hook/useDisplayError';
import { bikeListQuery, BikeListRawResponse } from '../Bike/BikeList.page';
import { TOAST_SEVERITY } from '../../../../components/Core/Toast/Toast';
import { useSnackbar } from '../../../../context/SnackbarContext';
import { dataURLtoFile } from '../../../../parameters/utils/file';
import { velocareTypesQuery } from '../Bike/NewBike.page';
import { AccessoryForm } from './NewAccessoryPage';
import { accessoryListQuery, AccessoryListRawResponse } from './AccessoryListPage';

const mock = new MockAdapter(instance, { onNoMatch: 'passthrough' });
mock.onGet('bike/3').reply(200, { name: 'test', description: 'test' });

export const editAccessoryAction: (queryClient: QueryClient) => ActionFunction =
  (queryClient) =>
  async ({ request }) => {
    const collId = store.getState().authState.collectivity;
    const { img, ...data } = await request.json();
    let response = null;

    try {
      response = await instance.post('accessory/edit', { ...data, idColl: collId });
      if (response.status === 200) {
        await queryClient.invalidateQueries(['duration/list']);
        if (img) {
          const file = await dataURLtoFile(img.src, 'img.png');
          await instance.put('accessory/media', file, {
            params: { idColl: collId, idArticle: response.data.idArticle },
          });
        }
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        console.error(e);
        return json(e.response?.data);
      }
    }

    return response;
  };

export const editAccessoryLoader: (queryClient: QueryClient) => LoaderFunction =
  (queryClient) =>
  async ({ params }) => {
    const collId = store.getState().authState.collectivity;

    const accessoryListResponse = await queryClient.ensureQueryData({
      ...accessoryListQuery({ idColl: collId }),
    });

    const bikeListResponse = await queryClient.ensureQueryData({
      ...bikeListQuery({ idColl: collId }),
    });

    const velocareTypesResponse = await queryClient.ensureQueryData({
      ...velocareTypesQuery({ id_coll: collId }),
    });

    return json({
      collId,
      accessoryListResponse,
      bikeListResponse,
      velocareTypesResponse,
      accessoryId: Number(params.id),
    });
  };

function EditAccessoryPage() {
  const fetcher = useFetcher();
  const { t } = useTranslation(['parameters/products/editAccessory', 'form']);
  // const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  useDisplayError(fetcher.data);
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const { accessoryId, accessoryListResponse, bikeListResponse } = useLoaderData() as {
    accessoryListResponse?: AxiosResponse<AccessoryListRawResponse>;
    bikeListResponse?: AxiosResponse<BikeListRawResponse>;
    collId: number;
    accessoryId: number;
  };
  // const { data: bikeResponse } = useQuery<AxiosResponse>(query({ idColl: id_coll, id: params.id }).queryKey!);
  const accessorySelected = useMemo(() => {
    return _.find(accessoryListResponse?.data, { id: accessoryId });
  }, [accessoryListResponse, accessoryId]);

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      img: { src: accessorySelected?.mediaUrl },
      name: accessorySelected?.name,
      description: accessorySelected?.description,
      linkedBike: accessorySelected?.linkedBike.map((bikeId) => bikeId.toString()),
      velocareType: accessorySelected?.velocareType,
    },
  });
  useMainTitle(accessorySelected?.name || '');

  const onSubmit = (data: FieldValues) => {
    const copyData = _.cloneDeep(data);
    if (copyData.img.src === accessorySelected?.mediaUrl) delete copyData.img;

    fetcher.submit({ ...copyData, id: accessoryId }, { method: 'post', encType: 'application/json' });
  };

  useEffect(() => {
    if (fetcher.data && fetcher.data.status === 200) {
      snackbar?.setAlert({
        message: t('successMessage'),
        severity: TOAST_SEVERITY.success,
      });
      navigate('/parametres/articles/accessoire');
    }
  }, [fetcher.data, t]);

  return (
    <MainSection className={'reset'}>
      <Card
        content={
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <p>{t('form:all_required')}</p>
              <AccessoryForm bikes={bikeListResponse?.data} />
              <div className={'row justify-between mt-4'}>
                <ButtonWithIcon
                  as={NavLink}
                  to={'/parametres/articles/accessoire'}
                  icon={'CrossWithoutCircle'}
                  color={'secondary'}>
                  {t('common:cancel')}
                </ButtonWithIcon>
                <ButtonWithIcon icon={'CheckWithoutCircle'} type={'submit'}>
                  {t('common:edit')}
                </ButtonWithIcon>
              </div>
            </form>

            <DevTool control={methods.control} />
          </FormProvider>
        }
      />
    </MainSection>
  );
}

export default EditAccessoryPage;
