import React, { ComponentPropsWithoutRef, useId, useState } from 'react';
import { RenderMonthProps, SingleDatePicker } from 'react-dates';
import { Control, Controller } from 'react-hook-form';
import Label from '../Label/Label';

type SingleDateFieldProps = RenderMonthProps &
  Omit<
    ComponentPropsWithoutRef<typeof SingleDatePicker>,
    'onDateChange' | 'date' | 'onFocusChange' | 'id' | 'focused' | 'noBorder'
  > & {
    //eslint-disable-next-line
    control: Control<any>;
    label: string;
    name: string;
  };

function SingleDateField({ control, label, name, ...propsToForward }: SingleDateFieldProps) {
  const id = useId();
  const [isFocus, setIsFocus] = useState(false);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <div>
            <Label>{label}</Label>
            <div>
              <SingleDatePicker
                id={id}
                hideKeyboardShortcutsPanel
                noBorder
                date={value}
                focused={isFocus}
                onDateChange={(newValue) => onChange(newValue)}
                onFocusChange={({ focused }) => setIsFocus(focused)}
                {...propsToForward}
              />
            </div>
          </div>
        );
      }}
    />
  );
}

export default SingleDateField;
