import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Trans, useTranslation } from 'react-i18next';
import TulipImg from 'assets/img/logo-tulip.webp';
import CylantroImg from 'assets/img/logo-cylantro.webp';
import MainSection from '../../../components/Core/MainSection/MainSection';
import { useMainTitle } from '../../../components/Layout/MainTitleContext';
import Card from '../../../components/Core/Card/Card';
import { instance } from '../../../api/user.api';
import { PartnerInsuranceIcon, SharelockIcon } from '../../../const/icons';
import styles from './InsuranceList.module.scss';
import InsuranceCard from './component/InsuranceCard';

type InsuranceListResponse = {
  id_insurance: number;
  insurance_name: string;
  available: number;
}[];

const insuranceList = new Map([
  [1, { link: 'partenaire', img: <PartnerInsuranceIcon height={38} /> }],
  [2, { link: 'tulip', img: <img height={38} src={TulipImg} /> }],
  [3, { link: '', img: <img height={38} src={CylantroImg} /> }],
  [4, { link: '', img: <SharelockIcon height={26} /> }],
]);

function InsuranceListPage() {
  const { t } = useTranslation(['admin/insurance']);
  useMainTitle('Assurance');

  const { data: insuranceListResponse } = useQuery(['insurance/list'], () => {
    return instance.get<InsuranceListResponse>('insurance/list');
  });

  return (
    <MainSection className={'reset'}>
      <Card color={'primary'}>
        <Trans t={t} i18nKey={'infoCardText'} components={{ strong: <strong /> }} />
      </Card>
      <div className={styles.Grid}>
        {insuranceListResponse &&
          insuranceListResponse.data.map((insurance) => (
            <InsuranceCard
              key={insurance.id_insurance}
              title={insurance.insurance_name}
              disabled={insurance.available === 0}
              {...insuranceList.get(insurance.id_insurance)}
            />
          ))}
      </div>
    </MainSection>
  );
}

export default InsuranceListPage;
