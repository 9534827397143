import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, NavLink, Outlet } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { TabComponent } from '../../Reservation/Reservation.layout';
import { Tab, Tabs, TabsList } from '../../../components/Core/Tabs/Tabs';
import { instance } from '../../../api/user.api';
import { selectCollAndDeliveryPointID } from '../../../auth/selectors/authSelectors';

export type CollectivityAdminParamsResponse = {
  admin: {
    nom: string;
    url_front: string;
    demande_signature: boolean;
    affichage_tarifs: boolean;
    restrictions_horaires: boolean;
    attribution_auto_velo: boolean;
    limit_reservations_user: number;
    devise: string;
    sepa_nom: string;
    sepa_adresse: string;
    sepa_ics: string;
    alwinAuthUser: string;
    alwinAuthPassword: string | null;
    alwinSupervisorCodeId: number;
    velocareSubsidiaryId: number;
    velocareBusinessId: number | null;
    url_confidentialite: string;
  };
};

function CollectivityAdminParamsLayout() {
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const { t } = useTranslation(['admin/collectivityAdmin']);

  const { data: collectivityAdminParamsResponse } = useQuery(
    ['param/admin', { id_coll }],
    ({ queryKey }) => {
      const [, queryParams] = queryKey;
      return instance.get<CollectivityAdminParamsResponse>('param/admin', { params: queryParams });
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 800_000,
    }
  );

  return (
    <div className={'reset'}>
      <div>
        <Tabs>
          <TabsList>
            <Tab
              component={({ children, ...otherProps }: TabComponent) => (
                <NavLink {...otherProps} to={generatePath('.')} end>
                  {children}
                </NavLink>
              )}>
              {t('global')}
            </Tab>
            <Tab
              component={({ children, ...otherProps }: TabComponent) => (
                <NavLink {...otherProps} to={generatePath('velocare')} end>
                  {children}
                </NavLink>
              )}>
              {t('velocare')}
            </Tab>
            <Tab
              component={({ children, ...otherProps }: TabComponent) => (
                <NavLink {...otherProps} to={generatePath('alwin')} end>
                  {children}
                </NavLink>
              )}>
              {t('alwin')}
            </Tab>
            <Tab
              component={({ children, ...otherProps }: TabComponent) => (
                <NavLink {...otherProps} to={generatePath('prestataires-de-paiement')} end>
                  {children}
                </NavLink>
              )}>
              {t('paymentProviders')}
            </Tab>
          </TabsList>
        </Tabs>
      </div>

      <React.Suspense fallback={<></>}>
        <Outlet context={collectivityAdminParamsResponse} />
      </React.Suspense>
    </div>
  );
}

export default CollectivityAdminParamsLayout;
