import 'fragments/dataTable/DataTable.css';
import DataTableHeadCell from 'fragments/dataTable/DataTableHeadCell';
import React, { ReactElement, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export type Props<ItemType> = {
  cols: string[];
  colHandlers?: (((sortType: string) => void) | null)[];
  items: ItemType[];
  colTraductionScope: string;
  custom?: boolean;
  style?: Record<string, Record<string, string>>;
  customEmptyMessage?: string;
};

// eslint-disable-next-line @typescript-eslint/comma-dangle
const DataTable = <ItemType,>({
  cols,
  colHandlers = [],
  items,
  colTraductionScope,
  custom = false,
  style,
  customEmptyMessage,
}: Props<ItemType>): ReactElement => {
  const { t } = useTranslation([colTraductionScope, 'common']);
  const emptyMessage = customEmptyMessage || t('common:empty');

  const renderTableHeadRow = useMemo(
    () =>
      cols.map((item, i) => (
        <DataTableHeadCell key={i} item={item} colHandler={colHandlers[i] || null} traduction={colTraductionScope} />
      )),
    [cols, colHandlers, colTraductionScope]
  );

  const renderTableBodyCells = useCallback(
    //eslint-disable-next-line
    (data: any) =>
      cols.map((item, i) => {
        if (item === 'amount' && data.id_paiement === 0 && data?.bookingMean !== 'agency') {
          return <td key={i} />;
        }

        let wording = data[item];

        if (item === 'amount') {
          wording = t('users.amount_vat', { amount: data[item], currency: data?.devise });

          if (data.hasSecurityDeposit) {
            const { securityDepositAmount, articleAmount } = data;
            wording +=
              ' ' + t('users.has_security_deposit', { securityDepositAmount, articleAmount, currency: data?.devise });
          }
        }

        return (
          <td key={i} style={style?.[item]}>
            {wording}
          </td>
        );
      }),
    [cols]
  );

  const renderTableBodyRows = useMemo(
    () =>
      items.map((item, i) => (
        <tr key={i} role={'row'} className={'odd'}>
          {renderTableBodyCells(item)}
        </tr>
      )),
    [items]
  );

  const renderTableBody = useMemo(() => {
    if (!items.length) {
      return (
        <tr className={'odd'}>
          <td valign={'top'} colSpan={cols.length} className={'dataTables_empty'} style={{ textAlign: 'center' }}>
            {emptyMessage}
          </td>
        </tr>
      );
    }
    return renderTableBodyRows;
  }, [items.length, cols, renderTableBodyRows]);

  return (
    <table
      id={'list'}
      className={'table table-bordered dataTable no-footer ' + (custom ? 'custom-table' : 'table-striped')}
      cellSpacing={'0'}
      role={'grid'}
      aria-describedby={'list_info'}>
      <thead>
        <tr role={'row'}>{renderTableHeadRow}</tr>
      </thead>
      <tbody>{renderTableBody}</tbody>
    </table>
  );
};

export default DataTable;
