import React, { ComponentPropsWithoutRef, HTMLAttributes } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './link.module.scss';

type LinkProps = HTMLAttributes<HTMLAnchorElement> &
  ComponentPropsWithoutRef<typeof NavLink> & {
    externalLink?: boolean;
  };

function Link({ to, children, externalLink = false, className, ...anchorProps }: LinkProps) {
  return externalLink ? (
    <a className={[styles.Link, className].join(' ')} {...anchorProps} href={typeof to === 'string' ? to : to.pathname}>
      {children}
    </a>
  ) : (
    <NavLink className={[styles.Link, className].join(' ')} to={to} end {...anchorProps}>
      {children}
    </NavLink>
  );
}

export default Link;
