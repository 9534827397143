/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { Control, FieldValues, UseFormReset } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { putDegradation } from '../../../api/params/degradation.api';
import { selectCollAndDeliveryPointID } from '../../../auth/selectors/authSelectors';
import IconButton from '../../../components/Core/Button/IconButton';
import { GridValidRowModel } from '../../../components/Core/Datagrid/gridRows';
import { TOAST_SEVERITY } from '../../../components/Core/Toast/Toast';
import { useSnackbar } from '../../../context/SnackbarContext';

type DegradationListActionCellProps = {
  control: Control;
  reset?: UseFormReset<FieldValues>;
  row: GridValidRowModel;
  handleDelete?: (depotId: number) => void;
};

function DegradationListActionCell(props: DegradationListActionCellProps) {
  const { t } = useTranslation('parameters/degradationList');
  const { row, control, reset } = props;
  const snackbar = useSnackbar();
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const { isDirty, dirtyFields } = control._formState;

  const isDisabled = !isDirty || Object.keys(dirtyFields).length === 0;

  const { mutate: handleUpdate } = useMutation(['degradation.put'], () => {
    const { nom_degradation, montant_ht } = control._formValues;
    return putDegradation({ id_coll, id_degradation: row.id_degradation, nom_degradation, montant_ht });
  }, {
    onSuccess: () => {
      snackbar?.setAlert({ message: t('degradation_success'), severity: TOAST_SEVERITY.success });
      reset && reset([], { keepValues: true });
    },
  });

  return (
    <div
      key={`actions-${row.id_caution}`}
      css={css`
        display: grid;
        grid-template-columns: repeat(2, auto);
        justify-content: flex-end;
        gap: 8px;
      `}>
      <IconButton disabled={isDisabled} icon={'RefreshIcon'} color={'action'} onClick={handleUpdate} />
    </div>
  );
}

export default DegradationListActionCell;
