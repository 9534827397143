import styled from '@emotion/styled';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { getColor } from '../../../utils/style';
import Button from '../Button/Button';
import Card from '../Card/Card';

type FormCardProps = {
  title?: string;
  className?: string;
  submitLabel?: string;
  onSubmit?: () => void;
  isLoading?: boolean;
};

function FormCard(props: PropsWithChildren<FormCardProps>) {
  const { title, submitLabel, onSubmit, isLoading = false, children, className } = props;
  const { t } = useTranslation('form');

  return (
    <Card
      title={title}
      className={className}
      content={
        <>
          <form onSubmit={onSubmit}>
            <div>{children}</div>
            <FormCardFooter>
              <Button
                isLoading={isLoading}
                type={'submit'}
                label={submitLabel ? submitLabel : t('global_update_label')}
              />
            </FormCardFooter>
          </form>
        </>
      }
    />
  );
  /*{title && (
        <FormCardHeader>
          <h5>{title}</h5>
        </FormCardHeader>
      )}*/
}

const FormCardFooter = styled.footer`
  // border-top: 1px solid ${getColor('gray.200')};
  padding: 16px 0 0;
  display: flex;
  justify-content: flex-end;
`;

export default FormCard;
