import cx from 'classnames';
import React, { ChangeEvent, ReactElement, useCallback, useEffect, useState } from 'react';

type Props = {
  id: string;
  name: string;
  label: string;
  type: string;
  className?: string;
  hasError?: string;
  required?: boolean;
  defaultValue?: string;
  handleChange?: (value: string) => void;
};

const MaterialInput = ({
  id,
  name,
  label,
  type,
  className = '',
  hasError = '',
  required = false,
  defaultValue = '',
  handleChange = () => null,
}: Props): ReactElement => {
  const [focused, setFocused] = useState(false);
  const [value, setValue] = useState<string>('');

  const formGroupClassName = cx('form-group', {
    'do-float': focused || value !== '',
    'has-error': hasError !== '',
    'has-danger': hasError !== '',
    className: className !== '',
  });

  const onFocus = useCallback(() => {
    setFocused(true);
  }, []);

  const onBlur = useCallback(() => {
    setFocused(false);
  }, []);

  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  }, []);

  useEffect(() => {
    handleChange(value);
  }, [value]);

  return (
    <div className={formGroupClassName} onFocus={onFocus} onBlur={onBlur}>
      <input
        type={type}
        className={'form-control'}
        id={id}
        name={name}
        required={required}
        onChange={onChange}
        value={defaultValue || value}
      />
      <div className={'invalid-feedback'}></div>
      <label htmlFor={id}>{label}</label>
      {hasError && <p style={{ color: 'red' }}>{hasError}</p>}
    </div>
  );
};

export default MaterialInput;
