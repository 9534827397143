import { useInstanceApi } from '../../api/axios';
import { useRequest } from '../../utils/request';

const URL = {
  putUserPassword: 'user/changepassword',
};

function usePasswordChangeApi() {
  const instance = useInstanceApi();

  const { request: putUserPassword } = useRequest((data) => instance.post(URL.putUserPassword, data));

  return { putUserPassword };
}

export default usePasswordChangeApi;
