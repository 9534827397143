import dayjs from 'dayjs';
import { upperFirst } from 'lodash';
import moment from 'moment';
import 'moment/locale/fr';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { BOOKING_STATUS, useBooking } from 'pages/Reservation/Reservation.layout';
import { putEndDate, PutEndDateBodyForm, putStartDate, PutStartDateBodyForm } from '../../../../../api/booking.api';
import { selectCollAndDeliveryPointID } from '../../../../../auth/selectors/authSelectors';
import ButtonWithIcon from '../../../../../components/Core/Button/ButtonWithIcon';
import Card from '../../../../../components/Core/Card/Card';
import SingleDateField from '../../../../../components/Core/SingleDateField/SingleDateField';
import TimeField from '../../../../../components/Core/TimeField/TimeField';
import { TOAST_SEVERITY } from '../../../../../components/Core/Toast/Toast';
import Dialog, { DialogActions, DialogContent, DialogTitle } from '../../../../../components/Dialog/Dialog';
import { useSnackbar } from '../../../../../context/SnackbarContext';
import { ARTICLE_TYPES } from '../ManagementCard';
import { instance } from '../../../../../api/user.api';
import styles from './BookingGenericInfosCard.module.scss';

type LineProps = {
  i18nKey: string;
  //eslint-disable-next-line
  values: Record<string, any>;
  sideContent?: JSX.Element | null;
  context?: string;
  count?: number;
  visible?: boolean;
};

export function Line({ values, i18nKey, sideContent, context, count, visible = true }: LineProps) {
  const { t } = useTranslation(['reservation/booking', 'common']);

  const canBeDisplay = useMemo(() => {
    return visible && !Object.values(values).some((value) => value === null || value === undefined);
  }, [values, visible]);

  return canBeDisplay ? (
    <div className={styles.Line}>
      <div>
        <Trans
          i18nKey={'lines.'.concat(i18nKey)}
          t={t}
          context={context}
          count={count}
          values={values}
          components={{ h2: <h2 className={styles.Line__title} /> }}
        />
      </div>
      {sideContent}
    </div>
  ) : null;
}

function ChangeReturnDateDialog({ defaultDate }: { defaultDate?: string }) {
  const { t } = useTranslation(['reservation/booking', 'common']);
  const { bookingId: id_resa } = useParams();
  const queryClient = useQueryClient();
  const snackbar = useSnackbar();
  moment.locale('fr');
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const [open, setIsOpen] = useState(false);
  const { handleSubmit, reset, control } = useForm();

  useEffect(() => {
    reset({ date: moment(defaultDate), time: defaultDate });
  }, [defaultDate]);

  const { mutate: handlePutDate } = useMutation(
    ['booking/returnRentDate'],
    //eslint-disable-next-line
    (bodyForm: any) => {
      return instance.post('user/rentReturnDate', bodyForm);
    },
    {
      onSuccess: (data, variables) => {
        const successMessage =
          'date_fin' in variables
            ? t('editBookingModal.endDateUpdateSuccessMessage')
            : t('editBookingModal.startDateUpdateSuccessMessage');
        snackbar?.setAlert({
          message: successMessage,
          severity: TOAST_SEVERITY.success,
        });
        queryClient.invalidateQueries(['booking']);
        setIsOpen(false);
      },
    }
  );

  const onSubmit = useCallback(
    handleSubmit((data) => {
      if (id_resa) {
        const time = moment(data.time);
        const date = moment(data.date).hours(time.hours()).minutes(time.minutes());
        handlePutDate({ collId: id_coll, bookingId: id_resa, rentReturnDate: date.format('YYYY-MM-DD HH:mm:ss') });
      }
    }),
    [handleSubmit, id_coll, id_resa, handlePutDate]
  );

  const containerRef = useRef(null);
  return (
    <>
      <ButtonWithIcon icon={'EditIcon'} color={'secondary'} onClick={() => setIsOpen(true)} />
      {ReactDOM.createPortal(
        <Dialog
          wrapperProps={{ id: 'date-update-modal' }}
          style={{ width: 500 }}
          open={open}
          onCancel={() => setIsOpen(false)}>
          <DialogTitle>{t('editBookingModal.title')}</DialogTitle>
          <form onSubmit={onSubmit}>
            <DialogContent ref={containerRef}>
              <div className={'row gap-8 items-end'}>
                <SingleDateField
                  isOutsideRange={(day) =>
                    !day.startOf('day').isSame(moment(defaultDate).startOf('day')) &&
                    !day.startOf('day').isSameOrAfter(moment().startOf('day'))
                  }
                  withPortal
                  control={control}
                  label={t('editBookingModal.startDateField.label')}
                  name={'date'}
                />
                <TimeField portalId={'date-update-modal'} control={control} name={'time'} />
              </div>
            </DialogContent>
            <DialogActions>
              <ButtonWithIcon icon={'CrossWithoutCircle'} color={'secondary'} onClick={() => setIsOpen(false)}>
                {t('common:cancel')}
              </ButtonWithIcon>
              <ButtonWithIcon icon={'CheckWithoutCircle'} type={'submit'}>
                {t('common:validate')}
              </ButtonWithIcon>
            </DialogActions>
          </form>
        </Dialog>,
        document.body
      )}
    </>
  );
}

function ChangeDateDialog({ defaultDate, forStartDate = false }: { forStartDate?: boolean; defaultDate?: string }) {
  const { t } = useTranslation(['reservation/booking', 'common']);
  const { bookingId: id_resa } = useParams();
  const queryClient = useQueryClient();
  const snackbar = useSnackbar();
  moment.locale('fr');
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const [open, setIsOpen] = useState(false);
  const { handleSubmit, reset, control } = useForm({
    /*defaultValues: useMemo(
          () => ({
            date: moment(defaultDate),
            time: defaultDate,
          }),
          [defaultDate]
        ),*/
  });

  useEffect(() => {
    reset({ date: moment(defaultDate), time: defaultDate });
  }, [defaultDate]);

  const { mutate: handlePutDate } = useMutation(
    ['user/changeresastart'],
    (bodyForm: PutStartDateBodyForm | PutEndDateBodyForm) => {
      if ('date_debut' in bodyForm) {
        return putStartDate(bodyForm);
      } else {
        return putEndDate(bodyForm);
      }
    },
    {
      onSuccess: (data, variables) => {
        const successMessage =
          'date_fin' in variables
            ? t('editBookingModal.endDateUpdateSuccessMessage')
            : t('editBookingModal.startDateUpdateSuccessMessage');
        snackbar?.setAlert({
          message: successMessage,
          severity: TOAST_SEVERITY.success,
        });
        queryClient.invalidateQueries(['booking']);
        setIsOpen(false);
      },
    }
  );

  const onSubmit = useCallback(
    handleSubmit((data) => {
      if (id_resa) {
        const time = moment(data.time);
        const date = moment(data.date).hours(time.hours()).minutes(time.minutes());
        if (forStartDate) {
          handlePutDate({ id_coll, id_resa, date_debut: date.format('YYYY-MM-DD HH:mm') });
        } else {
          handlePutDate({ id_coll, id_resa, date_fin: date.format('YYYY-MM-DD HH:mm') });
        }
      }
    }),
    [handleSubmit, id_coll, forStartDate, id_resa, handlePutDate]
  );

  const containerRef = useRef(null);
  return (
    <>
      <ButtonWithIcon icon={'EditIcon'} color={'secondary'} onClick={() => setIsOpen(true)} />
      {ReactDOM.createPortal(
        <Dialog
          wrapperProps={{ id: 'date-update-modal' }}
          style={{ width: 500 }}
          open={open}
          onCancel={() => setIsOpen(false)}>
          <DialogTitle>{t('editBookingModal.title')}</DialogTitle>
          <form onSubmit={onSubmit}>
            <DialogContent ref={containerRef}>
              <div className={'row gap-8 items-end'}>
                <SingleDateField
                  isOutsideRange={(day) =>
                    !day.startOf('day').isSame(moment(defaultDate).startOf('day')) &&
                    !day.startOf('day').isSameOrAfter(moment().startOf('day'))
                  }
                  withPortal
                  control={control}
                  label={
                    forStartDate ? t('editBookingModal.startDateField.label') : t('editBookingModal.endDateField.label')
                  }
                  name={'date'}
                />
                <TimeField portalId={'date-update-modal'} control={control} name={'time'} />
              </div>
            </DialogContent>
            <DialogActions>
              <ButtonWithIcon icon={'CrossWithoutCircle'} color={'secondary'} onClick={() => setIsOpen(false)}>
                {t('common:cancel')}
              </ButtonWithIcon>
              <ButtonWithIcon icon={'CheckWithoutCircle'} type={'submit'}>
                {t('common:validate')}
              </ButtonWithIcon>
            </DialogActions>
          </form>
        </Dialog>,
        document.body
      )}
    </>
  );
}

function BookingGenericInfosCard() {
  const { t } = useTranslation(['reservation/booking', 'common']);
  const { rawBooking, bookingStatus } = useBooking();

  //eslint-disable-next-line
  const lines = useMemo<LineProps[]>(() => {
    //eslint-disable-next-line
    const base: LineProps[] = [
      {
        i18nKey: 'name',
        values: {
          lastName: rawBooking?.nom,
          firstName: rawBooking?.prenom,
        },
      },
      {
        i18nKey: 'type',
        values: {
          type: rawBooking?.id_parent_resa ? t('renewal') : t('location'),
        },
      },
      {
        i18nKey: 'location',
        values: {
          location: rawBooking?.nom_pdl,
        },
        visible: rawBooking?.article === ARTICLE_TYPES.bike && rawBooking?.nom_pdl !== '',
      },
      {
        i18nKey: 'parkLocation',
        values: {
          location: rawBooking?.nom_empl,
        },
        visible: rawBooking?.article === ARTICLE_TYPES.park && rawBooking?.nom_empl !== '',
      },
      {
        i18nKey: 'articleType',
        values: {
          articleType: rawBooking?.nom_type,
        },
        count: rawBooking?.accessoires?.length,
        context: rawBooking?.accessoires ? 'withAccessories' : 'default',
      },
    ];

    if (bookingStatus === BOOKING_STATUS.WAITING) {
      base.push({
        i18nKey: 'queue',
        values: {
          queue: rawBooking?.queue_position,
        },
      });

      return base;
    }

    if (
      bookingStatus &&
      [
        BOOKING_STATUS.VALIDATE,
        BOOKING_STATUS.FINISHED,
        BOOKING_STATUS.CANCELED_AFTER_VALIDATED,
        BOOKING_STATUS.CANCELED,
      ].includes(bookingStatus)
    ) {
      base.push({
        i18nKey: 'assign',
        values: {
          type: upperFirst(t(`common:${rawBooking?.article === ARTICLE_TYPES.bike ? 'bike' : 'park'}`)),
          value: rawBooking?.id_interne,
        },
        visible: rawBooking?.id_interne !== '',
        context: rawBooking?.article === ARTICLE_TYPES.park ? 'female' : 'male',
      });

      if (rawBooking?.accessoires) {
        base.push(
          ...rawBooking.accessoires.map((accessory) => ({
            i18nKey: 'assign',
            values: { type: accessory.nom_type, value: accessory.id_interne },
            context: 'male',
          }))
        );
      }
    }

    base.push(
      {
        i18nKey: 'dateStart',
        values: {
          date: dayjs(rawBooking?.date_start).format('DD/MM/YYYY HH:mm'),
        },
        sideContent:
          bookingStatus === BOOKING_STATUS.BOOKED ? (
            <ChangeDateDialog defaultDate={rawBooking?.date_start} forStartDate />
          ) : null,
      },
      {
        i18nKey: 'dateEnd',
        values: {
          date: dayjs(rawBooking?.date_end).format('DD/MM/YYYY HH:mm'),
        },
        sideContent:
          bookingStatus === BOOKING_STATUS.BOOKED ? <ChangeDateDialog defaultDate={rawBooking?.date_end} /> : null,
      }
    );

    if (
      rawBooking?.article === ARTICLE_TYPES.bike &&
      bookingStatus &&
      [
        BOOKING_STATUS.VALIDATE,
        BOOKING_STATUS.FINISHED,
        BOOKING_STATUS.CANCELED_AFTER_VALIDATED,
        BOOKING_STATUS.CANCELED,
      ].includes(bookingStatus)
    ) {
      base.push({
        i18nKey: 'dateReturn',
        values: {
          date: dayjs(rawBooking?.rentReturnDate).format('DD/MM/YYYY HH:mm'),
        },
        sideContent:
          bookingStatus === BOOKING_STATUS.VALIDATE ? (
            <ChangeReturnDateDialog defaultDate={rawBooking?.rentReturnDate} />
          ) : null,
      });
    }

    return base;
  }, [rawBooking, bookingStatus, t]);

  return (
    <Card
      className={'bookingCard'}
      title={t('genericInfosCard.title')}
      content={
        <>
          {lines.map((line) => (
            <Line key={line.i18nKey} {...line} />
          ))}
        </>
      }
    />
  );
}

export default BookingGenericInfosCard;
