import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { ChartData, ChartDataset, ChartOptions, Plugin } from 'chart.js';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import react from 'react';
import parser from 'html-react-parser';
import _ from 'lodash';

const baseOptions: ChartOptions<'bar'> = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    intersect: false,
    mode: 'index',
  },
  plugins: {
    htmlLegend: {
      containerId: 'legend',
    },
    tooltip: {
      position: 'nearest',
      mode: 'index',
      cornerRadius: 10,
    },
    legend: {
      display: false,
      position: 'top',
      labels: {
        filter: function (item) {
          return item.text.includes('total');
        },
      },
    },
    title: {
      display: true,
      text: 'Type de vélos',
      position: 'bottom',
    },
  },
  scales: {
    y: {
      stacked: true,
      beginAtZero: true,
      suggestedMin: 40,
      // stackWeight: 50,
    },
    x: {
      beginAtZero: true,
      stacked: true,
      display: false,
    },
  },
};

export const htmlLegendPlugin: Plugin = {
  id: 'htmlLegend',
  afterUpdate: (chart, args, options) => {
    const ul = document.getElementById(options.containerId);

    while (ul && ul.firstChild) {
      ul.firstChild.remove();
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const items = chart.options.plugins.legend.labels.generateLabels(chart);
    const totalIndex = items.findIndex((item) => item.text === 'total');
    if (totalIndex !== -1) items.splice(totalIndex, 1);

    if (ul) {
      items.forEach((item, index) => {
        const li = document.createElement('li');
        li.className = 'legend-item';
        li.style.alignItems = 'center';
        li.style.cursor = 'pointer';
        li.style.display = 'flex';
        li.style.flexDirection = 'row';
        li.style.marginLeft = '10px';

        li.onclick = () => {
          chart.setDatasetVisibility(index, !chart.isDatasetVisible(index));
          chart.update();
        };

        // Color box
        const boxSpan = document.createElement('span');
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        boxSpan.style.background = item.fillStyle;

        // Text
        const textContainer = document.createElement('p');
        textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

        const text = document.createTextNode(item.text as string);
        textContainer.appendChild(text);

        li.appendChild(boxSpan);
        li.appendChild(textContainer);
        ul.appendChild(li);
      });
    }
  },
};

// const labels = ['Standard', 'elec', 'pliant', 'test', 'autre type', 'lalal'];

const backgroundColors = ['#28abb9', '#2d6187', '#b8de6f', '#f1e189', '#f39233', '#ffaaab'];

export type RepartitionByTypeData = {
  label: string;
  data: number[];
}[];

const createData: (data: RepartitionByTypeData, labels: string[]) => ChartData<'bar'> = (data, labels) => {
  const totalDataset: ChartDataset<'bar'> = {
    label: 'total',
    data: labels.map((label, index) => (data.every((ageData) => isNaN(ageData.data[index])) ? 0 : Number.NaN)),
    // backgroundColor: '#ccc',
    minBarLength: 10,
    borderRadius: 10,
    barPercentage: 0.5,
    // borderSkipped: false,
  };

  return {
    labels,
    datasets: [
      ...data.map((item, index) => ({
        label: item.label,
        backgroundColor: backgroundColors[index],
        data: item.data,
        minBarLength: 10,
        borderRadius: 15,
        barPercentage: 0.5,
        // borderSkipped: false,
      })),
      totalDataset,
    ],
    /*{
        label: '- de 25 ans',
        backgroundColor: '#28abb9',
        data: [1, 5, 6, 8],
        minBarLength: 10,
        borderRadius: 15,
        barPercentage: 0.5,
      },
      {
        label: '25 - 36 ans',
        backgroundColor: '#2d6187',
        data: [9, 10, 6, 40],
        minBarLength: 10,
        borderRadius: 15,
        barPercentage: 0.5,
      },
      {
        label: '36 - 45 ans',
        backgroundColor: '#b8de6f',
        data: [1, 20, 40, 8],
        minBarLength: 10,
        borderRadius: 15,
        barPercentage: 0.5,
      },
      {
        label: '46 - 55 ans',
        backgroundColor: '#f1e189',
        data: [60, 20, 40, 8],
        minBarLength: 10,
        borderRadius: 15,
        barPercentage: 0.5,
      },
      {
        label: '+ de 55 ans',
        backgroundColor: '#f39233',
        data: [60, 20, 40, 8],
        minBarLength: 10,
        borderRadius: 15,
        barPercentage: 0.5,
      },*/
  };
};

type RepartitionByTypeProps = {
  title?: string;
  //eslint-disable-next-line
  data: RepartitionByTypeData;
  labels: string[];
};

//eslint-disable-next-line
function RepartitionByType(props: RepartitionByTypeProps) {
  const chart = useRef<ChartJSOrUndefined<'bar'>>();
  //eslint-disable-next-line
  const [legendItems, setLegendItems] = useState<any[]>([]);

  useEffect(() => {
    if (chart.current) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const legendItems = chart.current.legendElements;
      if (legendItems) setLegendItems(legendItems);
    }
  }, [chart.current]);

  const options = useMemo(() => {
    return _.merge(baseOptions, {
      plugins: {
        title: {
          text: props.title,
        },
      },
    });
  }, [props.title]);

  return (
    <div>
      <div>
        <Bar
          ref={chart}
          height={300}
          data={createData(props.data, props.labels)}
          options={options}
          plugins={[htmlLegendPlugin]}
        />
      </div>
      <ul id={'legend'} className={'legend'}>
        {legendItems.map((element) => {
          return react.createElement(element.tagName.toLowerCase(), {
            ...element.attributes,
            className: 'legend-item',
            onClick: element.onclick,
            children: parser(element.innerHTML),
          });
        })}
      </ul>
    </div>
  );
}

export default RepartitionByType;
