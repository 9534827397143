import { Theme } from '@emotion/react';
import { createTheme, ThemeOptions } from '@mui/material';

export default createTheme({
  layout: {
    sidebar: {
      width: '300px',
    },
  },
  name: 'default',
  background: {
    body: '#f1f2f4',
    card: 'white',
    filtersCard: '#f1f2f4',
  },
  color: {
    primary: {
      '50': '#cadefc',
      '100': '#e1f0f8',
      '200': '#0080cc',
      '300': '#006199',
      '400': '#17394d',
    },
    primaryGray: {
      '100': '#ebebeb',
      '200': '#414b5a',
      '300': '#2e3540',
    },
    secondary: {
      '100': '#ffeacc',
      '200': '#ab7f46',
    },
    gray: {
      '100': '#f8f9fa',
      '200': '#ebebeb',
      '300': '#8b95a5',
      '400': '#414b5a',
    },
    green: {
      '100': '#ddf2c5',
      '200': '#81cc2d',
      '300': '#619922',
    },
    red: {
      '100': '#f4d4c8',
      '200': '#cc4119',
      '300': '#993012',
    },
  },
} as unknown as ThemeOptions) as unknown as Theme;
