/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectCollAndDeliveryPointID } from '../../../auth/selectors/authSelectors';
import IconButton from '../../../components/Core/Button/IconButton';
import Card from '../../../components/Core/Card/Card';
import Datagrid, { RowItem } from '../../../components/Core/Datagrid/Datagrid';
import { GridColDefinition } from '../../../components/Core/Datagrid/gridCols';
import { GridDataState } from '../../../utils/request';
import { withTheme } from '../../Parameters/Deposit/List/DepositList';
import useContractListApi from './useContractListApi';

function useContractListColumnDefinitions() {
  const { t } = useTranslation('contracts/invoiceList');

  return useMemo<GridColDefinition[]>(() => {
    return [
      {
        type: 'custom',
        field: 'date_start',
        headerName: t('dateContract'),
        sortable: true,
        renderCell: ({ row }) => {
          return (
            <RowItem>
              {dayjs(row.date_start).format('DD/MM/YYYY')} - {dayjs(row.date_end).format('DD/MM/YYYY')}
            </RowItem>
          );
        },
      },
      {
        type: 'string',
        field: 'nomprenom',
        headerName: t('usagers'),
        sortable: true,
      },
      {
        type: 'string',
        field: 'operation',
        headerName: t('operation'),
        sortable: true,
      },
      {
        type: 'custom',
        field: '',
        headerName: '',
        renderCell: ({ row }) => {
          return (
            <RowItem>
              {row.date_anonymization ? (
                <span>
                  <i>{t('archived')}</i>
                </span>
              ) : (
                <IconButton as={'a'} target={'_blank'} href={row.media_url} icon={'UploadIcon'} />
              )}
            </RowItem>
          );
        },
      },
    ];
  }, [t]);
}

function ContractList() {
  const columnDefinitions = useContractListColumnDefinitions();
  const { fetchContractList } = useContractListApi();
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const [contractList, setContractList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataToForwardForGridData, setDataToForwardForGridData] = useState<GridDataState>({
    search: '',
    totalLines: 0,
    itemPerPage: 10,
    nbrPages: undefined,
    currentPage: 1,
    order: {
      column: 'date_facture',
      dir: 'desc',
    },
    filters: {},
  });

  const onMount = useMemo(
    () =>
      _.debounce(async (queryParams) => {
        const response = await fetchContractList({ id_coll, ...queryParams });

        if (response && response.status === 200) {
          setContractList(response.data.result);
          setDataToForwardForGridData((prevState) => ({
            ...prevState,
            totalLines: response.data.total,
            itemPerPage: response.data.itemPerPage,
            nbrPages: response.data.totalPage,
            currentPage: response.data.currentPage,
            order: response.data.order,
          }));
        }

        setLoading(false);
      }, 1000),
    [id_coll]
  );

  useEffect(() => {
    setLoading(true);
    onMount(dataToForwardForGridData);
  }, [
    onMount,
    dataToForwardForGridData.itemPerPage,
    dataToForwardForGridData.currentPage,
    dataToForwardForGridData.order?.dir,
    dataToForwardForGridData.order?.column,
    dataToForwardForGridData.filters,
  ]);

  return (
    <MainSection className={'reset'}>
      <Card css={css({ padding: 0 })}>
        <Datagrid
          loading={loading}
          translationNamespace={'contracts/invoiceList'}
          onChangeFilterText={(search) =>
            setDataToForwardForGridData((prevState) => ({
              ...prevState,
              filters: { ...prevState.filters, filter: search as string },
            }))
          }
          onSort={(orderObject) => setDataToForwardForGridData((prevState) => ({ ...prevState, order: orderObject }))}
          handleChangePage={(newPage) =>
            setDataToForwardForGridData((prevState) => ({ ...prevState, currentPage: newPage }))
          }
          onChangeItemPerPage={(newValue) =>
            setDataToForwardForGridData((prevState) => ({ ...prevState, itemPerPage: newValue as number }))
          }
          rows={contractList}
          columns={columnDefinitions}
          {...(({ filters, ...propsToForward }) => propsToForward)(dataToForwardForGridData)}
        />
      </Card>
    </MainSection>
  );
}

const MainSection = styled.section`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export default withTheme(ContractList);
