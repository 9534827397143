import React, { ElementType, PropsWithChildren, useContext, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { generatePath, NavLink, Outlet } from 'react-router-dom';
import { getBooking } from '../../api/reservation.api';
import { selectCollAndDeliveryPointID } from '../../auth/selectors/authSelectors';
import { RawBooking } from '../../components/BookingCard/BookingCard';
import { Tab, Tabs, TabsList } from '../../components/Core/Tabs/Tabs';
import Tag from '../../components/Core/Tag/Tag';
import { useMainTitle } from '../../components/Layout/MainTitleContext';
import { FileIcon, InfoIcon, ReceiptIcon } from '../../const/icons';
import styles from './bookingDetails.module.scss';
import { useRequiredParams } from './components/BookingDetails/BookingPaymentInfosCard';

const BookingContext = React.createContext<{ rawBooking?: RawBooking; bookingStatus?: BOOKING_STATUS }>({
  rawBooking: undefined,
  bookingStatus: undefined,
});

export function useBooking() {
  return useContext(BookingContext);
}

export enum BOOKING_STATUS {
  BOOKED = 'booked',
  VALIDATE = 'validate',
  CANCELED = 'canceled',
  CANCELED_AFTER_VALIDATED = 'CANCELED_AFTER_VALIDATED',
  WAITING = 'waiting',
  FINISHED = 'finished',
  UNPAID = 'unpaid',
}

function getBookingStatus(rawBooking?: RawBooking): BOOKING_STATUS | undefined {
  if (!rawBooking) return undefined;

  if (rawBooking?.is_canceled === 1) {
    if (rawBooking.date_valid) {
      return BOOKING_STATUS.CANCELED_AFTER_VALIDATED;
    }
    return BOOKING_STATUS.CANCELED;
  }

  if (rawBooking?.queue_position) {
    return BOOKING_STATUS.WAITING;
  }

  if (rawBooking.id_velo !== 0 && rawBooking.is_returned === 0 && rawBooking.date_valid) {
    return BOOKING_STATUS.VALIDATE;
  }

  if (rawBooking.is_returned !== 0) {
    return BOOKING_STATUS.FINISHED;
  }
  if (rawBooking.is_paid === false){
    return BOOKING_STATUS.UNPAID;
  }

  return BOOKING_STATUS.BOOKED;
}

export type TabComponent = PropsWithChildren<ElementType<HTMLHtmlElement>>;

function ReservationLayout() {
  const { t } = useTranslation('reservation/reservation');
  const { userId, bookingId } = useRequiredParams<{ bookingId: string; userId: string }>();
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);

  const { data: bookingResponse, isLoading } = useQuery(
    ['booking', { id_resa: bookingId, id_user: userId, id_coll }],
    ({ queryKey }) => {
      const [, params] = queryKey as [string, { id_user: string }];
      if (params.id_user !== '') {
        //eslint-disable-next-line
        return getBooking(params as any);
      }
    },
    {
      suspense: false,
      staleTime: 260_000,
      refetchOnWindowFocus: false,
    }
  );

  const bookingStatus = useMemo(() => {
    return getBookingStatus(bookingResponse?.data);
  }, [bookingResponse]);

  const tag = useMemo(() => {
    if (bookingStatus === BOOKING_STATUS.CANCELED || bookingStatus === BOOKING_STATUS.CANCELED_AFTER_VALIDATED) {
      return <Tag type={'error'}>{t('bookingStatuses.canceled')}</Tag>;
    }

    if (bookingStatus === BOOKING_STATUS.WAITING) {
      return <Tag type={'secondary'}>{t('bookingStatuses.waitingList')}</Tag>;
    }

    if (bookingStatus === BOOKING_STATUS.VALIDATE) {
      return <Tag type={'success'}>{t('bookingStatuses.validate')}</Tag>;
    }

    if (bookingStatus === BOOKING_STATUS.FINISHED) {
      return <Tag type={'plain-primary'}>{t('bookingStatuses.finished')}</Tag>;
    }
    if (bookingStatus === BOOKING_STATUS.UNPAID) {
      return <Tag type={'secondary'}>{t('bookingStatuses.unpaid')}</Tag>;
    }

    return <Tag type={'primary'}>{t('bookingStatuses.booked')}</Tag>;
  }, [bookingResponse?.data, bookingStatus, t]);

  useMainTitle(
    useMemo(() => {
      return !isLoading ? (
        <div className={styles.title}>
          {t('pageTitle', { firstname: bookingResponse?.data.nom_user, lastname: bookingResponse?.data?.prenom_user })}
          {tag}
        </div>
      ) : null;
    }, [bookingResponse, tag, isLoading, t])
  );

  return (
    <div className={'reset'}>
      <div className={styles.Layout} id={'booking-layout'}>
        <Tabs className={styles.Layout__tabs}>
          <TabsList>
            <Tab
              component={({ children, ...otherProps }: TabComponent) => (
                <NavLink {...otherProps} to={generatePath('.')} end>
                  {children}
                </NavLink>
              )}>
              <InfoIcon width={16} /> {t('tabs.infos')}
            </Tab>
            <Tab
              component={({ children, ...otherProps }: TabComponent) => (
                <NavLink {...otherProps} to={generatePath('pj')} end>
                  {children}
                </NavLink>
              )}>
              <FileIcon width={16} /> {t('tabs.files')}
            </Tab>
            <Tab
              component={({ children, ...otherProps }: TabComponent) => (
                <NavLink {...otherProps} to={generatePath('encaissement')} end>
                  {children}
                </NavLink>
              )}>
              <ReceiptIcon width={16} /> {t('tabs.checkout')}
            </Tab>
          </TabsList>
        </Tabs>
      </div>

      <BookingContext.Provider value={{ rawBooking: bookingResponse?.data, bookingStatus }}>
        <React.Suspense fallback={<></>}>
          <Outlet />
        </React.Suspense>
      </BookingContext.Provider>
    </div>
  );
}

export default ReservationLayout;
