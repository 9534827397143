import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import ReactPhoneInput from 'react-phone-input-2';
import cx from 'classnames';
import 'react-phone-input-2/lib/style.css';

import 'fragments/phoneInput/PhoneInput.css';

type Props = {
  placeholder: string;
  id: string;
  className?: string;
  size?: string;
  defaultValue?: string;
  label?: string;
  handleChange?: (value: string) => void;
};

const PhoneInput = ({
  placeholder,
  id,
  className = '',
  size = '',
  defaultValue = '',
  label = '',
  handleChange = () => null,
}: Props): ReactElement => {
  const [value, setValue] = useState('');

  const inputClassName = cx('form-control form-tel-input-container', {
    [className]: className !== '',
  });

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const wrapperClassName = cx('form-group input-group phone-group', {
    [size]: size !== '',
  });

  const onChange = useCallback((phone: string) => {
    setValue(phone);
  }, []);

  useEffect(() => {
    handleChange(value);
  }, [value]);

  return (
    <div className={wrapperClassName}>
      <label htmlFor={id}>{label}</label>
      <ReactPhoneInput
        key={`phone-input-${id}`}
        country={'fr'}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        containerClass={inputClassName}
      />
    </div>
  );
};

export default PhoneInput;
