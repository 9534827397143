import React from 'react';
import useIsMobile from '../../../internal/hooks/useIsMobile';
import ProfilesAddList from '../../../parameters/components/ProfilesAddList';

function ProfilesPage() {
  const isMobile = useIsMobile();

  return (
    <div className={'main-content'}>
      <div className={'card'}>
        <div className={'card-body'}>
          <div className={!isMobile ? 'row' : ''}>
            <div className={!isMobile ? 'col-md-12' : ''}>
              <ProfilesAddList />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfilesPage;
