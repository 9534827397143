import React, { HtmlHTMLAttributes, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { postBookingCommentary, PostBookingCommentaryBodyForm } from '../../../../api/booking.api';
import { selectCollAndDeliveryPointID } from '../../../../auth/selectors/authSelectors';
import ButtonWithIcon from '../../../../components/Core/Button/ButtonWithIcon';
import Card from '../../../../components/Core/Card/Card';
import Label from '../../../../components/Core/Label/Label';
import { TOAST_SEVERITY } from '../../../../components/Core/Toast/Toast';
import TextArea from '../../../../components/Form/TextArea/TextArea';
import { useSnackbar } from '../../../../context/SnackbarContext';
import { useBooking } from '../../Reservation.layout';

function Commentary(props: HtmlHTMLAttributes<HTMLDivElement>) {
  const { t } = useTranslation(['reservation/components/commentary']);
  const snackbar = useSnackbar();
  const { rawBooking } = useBooking();
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const { bookingId } = useParams();
  const [commentary, setCommentary] = useState('');
  const queryClient = useQueryClient();

  useEffect(() => {
    if (rawBooking?.commentaire) setCommentary(rawBooking?.commentaire);
  }, [rawBooking?.commentaire]);

  const { mutate: handlePostBookingCommentary, isLoading } = useMutation(
    ['booking.post.commentary'],
    (data: PostBookingCommentaryBodyForm) => {
      return postBookingCommentary(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['booking']);
        snackbar?.setAlert({ message: t('successMessage'), severity: TOAST_SEVERITY.success });
      },
    }
  );

  return bookingId ? (
    <Card
      className={props.className}
      content={
        <>
          <Label>{t('commentary')}</Label>
          <div>
            <TextArea
              onChange={(e) => setCommentary(e.target.value)}
              placeholder={t('commentaryPlaceholder')}
              className={'mb-2'}
              value={commentary}
            />
          </div>
          <div className={'row justify-end'}>
            <ButtonWithIcon
              disabled={isLoading}
              icon={'SaveIcon'}
              color={'secondary'}
              label={t('cta')}
              onClick={() =>
                handlePostBookingCommentary({
                  id_coll,
                  id_resa: bookingId,
                  content: commentary,
                })
              }
            />
          </div>
        </>
      }
    />
  ) : null;
}

export default Commentary;
