import React from 'react';
import { NavLink } from 'react-router-dom';
import SoonTag from '../../../../components/Core/SoonTag/SoonTag';
import styles from './InsuranceCard.module.scss';

type InsuranceCardProps = {
  disabled?: boolean;
  title: string;
  link?: string;
  img?: React.ReactNode;
};

function InsuranceCard(props: InsuranceCardProps) {
  const { img, disabled = false, title, link } = props;

  const Component = disabled ? 'div' : NavLink;

  return (
    <Component to={link || ''} className={styles.Card}>
      {disabled && (
        <div className={styles.Disabled}>
          <SoonTag />
        </div>
      )}
      {img}
      <span>{title}</span>
    </Component>
  );
}

export default InsuranceCard;
