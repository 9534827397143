import { TFunction } from 'i18next';

export const bikeStatuses = (t: TFunction): Record<string, string> => ({
  en_location: t('options.en_location'),
  rebus: t('options.rebus'),
  transit: t('options.transit'),
  en_reparation: t('options.en_reparation'),
  vole: t('options.vole'),
  prete: t('options.prete'),
  dispo_pret: t('options.dispo_pret'),
  dispo_location: t('options.dispo_location'),
  vendu: t('options.vendu'),
  a_vendre: t('options.a_vendre'),
  neuf: t('options.neuf'),
  fin_location: t('options.fin_location'),
});
