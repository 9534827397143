import classNames from 'classnames/bind';
import React, { ComponentPropsWithoutRef, SelectHTMLAttributes } from 'react';
import Select from 'react-select';
import useSelect from './useSelect';
import styles from './select.module.scss';

const cx = classNames.bind(styles);

type SelectBaseProps<Option = { label: string; value?: string | number | null }> = {
  options: Option[];
} & Omit<ComponentPropsWithoutRef<Select>, 'options'> &
  Omit<SelectHTMLAttributes<HTMLSelectElement>, keyof ComponentPropsWithoutRef<Select>>;

function SelectBase(props: SelectBaseProps) {
  const { value, options, disabled, onChange: onChangeProp, ...propsToForward } = props;
  const selectProps = useSelect({ value, onChange: onChangeProp });

  return (
    <Select
      classNames={{
        control: (state) =>
          cx(styles.Control, state.hasValue && styles.ControlFilled, state.isFocused && styles.ControlFilled),
        indicatorSeparator: () => styles.IndicatorSeparator,
        multiValue: () => cx(styles.MultiValue),
        multiValueLabel: () => cx(styles.MultiValueLabel),
        multiValueRemove: () => cx(styles.MultiValueRemove),
        option: (state) => (state.isSelected ? '' : state.isFocused ? cx(styles.Option) : cx(styles.OptionHovered)),
        placeholder: () => cx(styles.Placeholder),
        dropdownIndicator: () => cx(styles.DropdownIndicator),
        clearIndicator: () => cx(styles.ClearIndicator),
        singleValue: () => cx(styles.SingleValue),
        input: () => cx(styles.Input),
      }}
      options={options}
      isDisabled={disabled}
      {...selectProps}
      {...propsToForward}
    />
  );
}

export default SelectBase;
