import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getAccessoryList } from '../../../api/park/accessory.api';
import { RawBike } from '../../../api/park/bike.api';
import { selectCollAndDeliveryPointID } from '../../../auth/selectors/authSelectors';
import Card from '../../../components/Core/Card/Card';
import Datagrid, { RowItem } from '../../../components/Core/Datagrid/Datagrid';
import { GridColDefinition } from '../../../components/Core/Datagrid/gridCols';
import Filters from '../../../components/Core/Filters/Filters';
import MainSection from '../../../components/Core/MainSection/MainSection';
import { ListParams } from '../BikeList/BikeList';
import BookingCell from '../BikeList/BookingCell';
import LocationCell from '../BikeList/LocationCell';
import StatusCell from '../BikeList/StatusCell';

//eslint-disable-next-line
function useColumns(filters: any): GridColDefinition[] {
  const { t } = useTranslation('park/genericList');

  return useMemo(
    () => [
      {
        field: 'id_interne',
        type: 'custom',
        headerName: t('columns.accessoryNumber'),
        sortable: true,
        renderCell: ({ row }) => <RowItem key={'interneId' + row.id_accessoire}>{`${row.id_interne}`}</RowItem>,
      },
      /*{
    field: 'id_accessoire',
    type: 'custom',
    headerName: t('columns.accessoryNumber'),
    sortable: true,
    renderCell: ({ row }) => <RowItem key={'id_accessoire' + row.id_accessoire}>{`${row.id_accessoire}`}</RowItem>,
  },*/
      {
        field: 'nom_type',
        type: 'string',
        headerName: t('columns.accessoryType'),
      },
      {
        field: '',
        type: 'custom',
        headerName: t('columns.reservation'),
        renderCell: ({ row }) => <BookingCell key={'booking' + row.id_accessoire} bike={row as RawBike} />,
      },
      {
        field: '',
        width: 300,
        type: 'custom',
        headerName: t('columns.state'),
        renderCell: ({ row }) => (
          <StatusCell
            key={'status' + row.id_accessoire}
            articleType={'accessory'}
            bike={row as RawBike}
            filters={filters}
          />
        ),
      },
      {
        field: '',
        type: 'custom',
        headerName: t('columns.affectation'),
        renderCell: ({ row }) => (
          <LocationCell
            key={'location' + row.id_accessoire}
            filters={filters}
            articleType={'accessory'}
            bike={row as RawBike}
          />
        ),
      },
    ],
    [t, filters]
  );
}

function AccessoryListPage() {
  const { t } = useTranslation('park/genericList');
  const { id_coll, id_pdl } = useSelector(selectCollAndDeliveryPointID);
  const [listParams, setListParams] = useState<{
    filters?: Record<string, unknown>;
    order?: unknown;
    currentPage: number;
    itemPerPage: number;
  }>({
    order: undefined,
    filters: undefined,
    currentPage: 1,
    itemPerPage: 10,
  });

  const { data: accessoryListData, isFetching: isLoading } = useQuery(
    ['accessory.list', { ...listParams, id_coll, id_pdl }],
    ({ queryKey }) => {
      const [, listParams] = queryKey as [string, ListParams & { id_coll: number; id_pdl: number }];

      return getAccessoryList({
        id_coll: listParams.id_coll,
        id_pdl: listParams.id_pdl,
        p: listParams.currentPage,
        ipp: listParams.itemPerPage,
        'order[column]': listParams.order?.column,
        'order[dir]': listParams.order?.dir,
        ...listParams.filters,
      });
    },
    { refetchOnWindowFocus: false, staleTime: 260_000, keepPreviousData: true }
  );

  const accessoryColumnDefinitions = useColumns(listParams.filters);

  return (
    <MainSection className={'reset'}>
      <Filters
        onChange={(filters) => setListParams((prev) => ({ ...prev, filters }))}
        translationNamespace={'park/genericList'}
        filtersRaw={accessoryListData?.data.filters || {}}
      />
      <Card>
        <Datagrid
          loading={isLoading}
          title={t('title.accessories')}
          translationNamespace={'park/genericList'}
          rows={accessoryListData?.data.result || []}
          columns={accessoryColumnDefinitions}
          totalLines={accessoryListData?.data.total}
          currentPage={accessoryListData?.data.currentPage}
          itemPerPage={accessoryListData?.data.itemPerPage}
          nbrPages={accessoryListData?.data.totalPage}
          onChangeFilterText={(search) =>
            setListParams((prev) => ({ ...prev, filters: { ...prev.filters, filter: search } }))
          }
          onSort={(order) => setListParams((prev) => ({ ...prev, order }))}
          handleChangePage={(newPage) => setListParams((prev) => ({ ...prev, currentPage: newPage }))}
          onChangeItemPerPage={(newValue) => setListParams((prev) => ({ ...prev, itemPerPage: newValue as number }))}
          search={accessoryListData?.data.filters.filter.selected}
          order={accessoryListData?.data.order}
        />
      </Card>
    </MainSection>
  );
}

export default AccessoryListPage;
