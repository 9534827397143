export const CGV_EDITION_ENDPOINT = 'param/cgv';
export const CGL_LAST_PDF = 'param/lastcgl';
export const CGL_GENERATE_PDF = 'param/generatecgl';
export const BROADCAST_ENDPOINT = 'param/diff';
export const BROADCAST_DAYS_ENDPOINT = 'param/diffdays';
export const PARAM_COLL_ENDPOINT = 'param/coll';
export const PARAM_RENEWAL_ENDPOINT = 'param/renouvellement';
export const PARAM_COLL_ADMIN = 'param/admin';
export const PARAM_WAITING_LIST = 'param/listeattente';
export const GET_PDL_ENDPOINT = 'param/pdl';
export const UPDATE_PDL_ENDPOINT = 'param/pdlupd';
export const NEW_PDL_ENDPOINT = 'param/pdl';
export const DEPOSIT_ENDPOINT = 'param/cautions';
export const SECURITY_DEPOSIT_ENDPOINT = 'param/depotsgarantie';
export const DEPOSIT_UPDATE_ENDPOINT = 'param/updcaution';
export const SECURITY_DEPOSIT_UPDATE_ENDPOINT = 'param/upddepotgarantie';
export const ARTICLES_PRICES_ENDPOINT = 'param/articles';
export const ARICLE_UPDATE_ENDPOINT = 'param/updatearticle';
export const DEGRADATION_ENDPOINT = 'param/degradations';
export const DEGRADATION_ADD_ENDPOINT = 'param/adddegradation';
export const DEGRADATION_UPDATE_ENDPOINT = 'param/upddegradation';
export const DELAY_ENDPOINT = 'param/retards';
export const DELAY_ADD_ENDPOINT = 'param/addretard';
export const DELAY_UPDATE_ENDPOINT = 'param/updretard';
export const UPDATE_BIKE_TYPE = 'param/updatetypevelo';
export const UPDATE_PARK_TYPE = 'param/updatetypeconsigne';
export const UPDATE_FREE_PARK_TYPE = 'param/updatetypeconsignepark';
export const UPDATE_ACCESSORIES_TYPE = 'param/updatetypeaccessoire';
export const BIKE_TYPE_ENDPOINT = 'param/typesvelo';
export const FREE_PARK_TYPE_ENDPOINT = 'param/typesconsignepark';
export const PARK_TYPE_ENDPOINT = 'param/typesconsigne';
export const ACCESSORIES_TYPE_ENDPOINT = 'param/typesaccessoires';
export const ADD_ARTICLE_TYPE_ENDPOINT = 'param/addarticle';
export const ADD_BIKE_TYPE_ENDPOINT = 'param/addtypevelo';
export const ADD_PARK_TYPE_ENDPOINT = 'param/addtypeconsigne';
export const ADD_FREE_PARK_TYPE_ENDPOINT = 'param/addtypeconsignepark';
export const ADD_ACCESSORIES_TYPE_ENDPOINT = 'param/addtypeaccessoire';
export const UPDATE_IMAGE_TYPE_ENDPOINT = 'param/articlemedia';
export const THEME_ENDPOINT = 'param/visuel';
export const ASSETS_ENDPOINT = 'param/assets';
export const ASSET_ENDPOINT = 'param/asset';
export const AGENTS_ENDPOINT = 'param/agents';
export const AGENTS_INIT_ENDPOINT = 'param/initagents';
export const AGENTS_UPDATE_ENDPOINT = 'param/profilupd';
export const AGENTS_ADD_ENDPOINT = 'param/agent';
export const PROFILES_ENDPOINT = 'param/profils';
export const PROFILES_ADD_ENDPOINT = 'param/profil';
export const PROFILES_UPDATE_ENDPOINT = 'param/profilsupd';
export const LOCALES_ENDPOINT = 'param/languages';
export const FORMS_ENDPOINT = 'param/forms';
export const WORDING_PAGES_ENDPOINT = 'param/pages';
export const WORKSHOP_CONFIGURATION = 'param/workshop';
export const CONTACT_CONFIGURATION = 'param/contact';
export const MODULES_CONFIGURATION = 'param/modules';
export const ACTIVATE_DEPOSIT_ENDPOINT = 'param/admincautions';
export const ACTIVATE_BOOKING_ENDPOINT = 'param/adminbooking';
export const RESA_PROPERTIES_ENDPOINT = 'param/resaproperties';
export const VOUCHERS_CONFIGURATION = 'param/pj';
export const DYNAMIC_FORMS_ENDPOINT = 'booking/forms';
export const STRIPE_ENDPOINT = 'param/stripe';
export const SOGECOMMERCE_ENDPOINT = 'param/sogecommerce';
export const PAYBOX_ENDPOINT = 'param/paybox';
export const MONETICO_ENDPOINT = 'param/monetico';
export const PARAM_CHECKIN_ENDPOINT = 'checkin/admin';
export const UPDATE_EMPL_ENDPOINT = 'param/emplupd';
export const SOGECOMMERCE_ENABLED_ENDPOINT = 'param/sogecommerceenabled';
