import styled from '@emotion/styled';
import React, { HtmlHTMLAttributes, PropsWithChildren, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { getColor } from '../../../utils/style';

type ModalProps = HtmlHTMLAttributes<HTMLDivElement> & {
  setIsOpen: (state: boolean) => void;
  title?: string;
};

function Modal(props: PropsWithChildren<ModalProps>) {
  const { title, setIsOpen, children, ...propsToForward } = props;

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return ReactDOM.createPortal(
    <>
      <Background onClick={() => setIsOpen(false)} />
      <ModalRoot {...propsToForward}>
        {title && <ModalHeader>{title}</ModalHeader>}
        <ModalContent>{children}</ModalContent>
      </ModalRoot>
    </>,
    document.body
  );
}

const Background = styled.div`
  z-index: 1000;
  background-color: black;
  opacity: 66%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
`;

const ModalRoot = styled.div`
  line-height: normal;
  background-color: white;
  z-index: 1200;
  padding: 16px 32px;
  position: fixed;
  top: 50%;
  left: 50%;
  border-radius: 32px;
  min-width: 350px;
  min-height: 100px;
  transform: translate(-50%, -50%);
`;

const ModalHeader = styled.header`
  border-bottom: 1px solid ${getColor('gray.200')};
  padding: 16px 0;
  color: ${getColor('gray.400')};
  font-size: 16px;
  font-weight: bold;
`;

const ModalContent = styled.div`
  padding: 16px 0;
`;

export default Modal;
