import React, { useCallback, useMemo } from 'react';
import { MainTitle, useMainTitle } from 'components/Layout/MainTitleContext';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { saveCollectivity, saveDeliveryPoint, saveUser } from '../../auth/actions/authActions';
import { selectUser } from '../../auth/selectors/authSelectors';
import { LogoutIcon } from '../../const/icons';
import { Option } from '../../hook/useBuildForm';
import { changeLocale } from '../../internal/actions/appActions';
import { SIGN_IN } from '../../internal/constants/routes';
import ButtonBase from '../Core/Button/ButtonBase';
import SelectBase from '../Core/SelectBase/SelectBase';
import Drawer from '../Drawer/Drawer';
import { useMediaQuery } from '../Layout/DefaultLayout';
import styles from './topBar.module.scss';

const useBuildRouteTitles = (): Record<string, string> => {
  const { t } = useTranslation('components/topBar', { keyPrefix: 'pageTitles' });

  return useMemo(
    () => ({
      home: t('home'),
      '/usagers/creation': t('create'),
      '/usagers/validation': t('validation'),
      '/usagers/liste': t('list'),
      '/usagers/nouvelle-reservation/velo': t('newresa_bike'),
      '/usagers/nouvelle-reservation/consignes-libres': t('newresa_freepark'),
      '/usagers/nouvelle-reservation/consignes': t('newresa_park'),
      '/usagers/detail-reservation': t('resadetail'),
      '/usagers/edition': t('edit'),
      '/usagers/reservations': t('bookings'),
      '/usagers/signature': t('signresa'),
      '/usagers/paiement-agence': t('checkout'),
      '/usagers/paiement-en-ligne': t('stripe'),
      '/usagers/factures': t('invoices'),
      '/usagers/contrats': t('contracts'),
      '/contrats-factures/factures': t('invoices'),
      '/contrats-factures/contrats': t('contracts'),
      '/contrats-factures/reservations': t('bookings'),
      '/contrats-factures/detail-reservation': t('resadetail'),
      '/parc/velos': t('bikes'),
      '/parc/consignes': t('consigne'),
      '/parc/consignes-libres': t('consignepark'),
      '/caisse/jour': t('daily'),
      '/caisse/mouvements': t('moves'),
      '/parametres/collectivite': t('collectivity'),
      '/parametres/collectivite/pdl-list': t('collectivity'),
      '/parametres/collectivite/empl-list': t('collectivity'),
      '/parametres/renouvellement': t('renewal'),
      '/parametres/administration': t('administration'),
      '/parametres/articles': t('prices'),
      '/parametres/caution': t('deposits'),
      '/parametres/depot-de-garantie': t('security_deposits'),
      '/parametres/penalite-degradation': t('degradations'),
      '/parametres/penalite-retard': t('delays'),
      '/parametres/diffusion': t('broadcast'),
      '/parametres/contrats': t('param_contracts'),
      '/parametres/visuel': t('visual'),
      '/parametres/langues': t('locales'),
      '/parametres/formulaires': t('forms'),
      '/parametres/pages': t('pages'),
      '/parametres/page-atelier': t('workshop'),
      '/parametres/page-contact': t('contact'),
      '/parametres/modules-actifs': t('modules'),
      '/parametres/config-caution': t('deposit'),
      '/parametres/config-reservation': t('booking'),
      '/parametres/pieces-justificatives': t('vouchers'),
      '/parametres/checkin-checkout': t('checkin_checkout'),
      '/administration/collectivite': t('collectivity'),
      '/administration/collectivite/alwin': t('collectivity'),
      '/administration/collectivite/velocare': t('collectivity'),
      '/administration/file-attente': t('waiting_list'),
      '/parametres/collectivite/pdl': t('pdl'),
      '/administration/collectivite/prestataires-de-paiement': t('payment_providers'),
      '/administration/administration': t('administration'),
      '/administration/articles': t('prices'),
      '/administration/caution': t('deposits'),
      '/administration/penalite-degradation': t('degradations'),
      '/administration/penalite-retard': t('delays'),
      '/administration/diffusion': t('broadcast'),
      '/administration/contrats': t('param_contracts'),
      '/administration/theme': t('visual'),
      '/administration/theme/colors': t('visual'),
      '/administration/langues': t('locales'),
      '/administration/formulaires': t('forms'),
      '/administration/pages': t('pages'),
      '/administration/page-atelier': t('workshop'),
      '/administration/page-contact': t('contact'),
      '/administration/modules-actifs': t('modules'),
      '/administration/config-caution': t('deposit'),
      '/administration/config-reservation': t('booking'),
      '/administration/pieces-justificatives': t('vouchers'),
      '/administration/droits': t('permission'),
      '/administration/droits/agent': t('permission'),
      '/statistiques/collectivite': t('stats'),
    }),
    [t]
  );
};

function TopBar() {
  const isDesktop = useMediaQuery('(min-width: 1200px)');
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const user = useSelector(selectUser);
  const allTitles = useBuildRouteTitles();

  const keyForTitle = useMemo(() => {
    if (pathname === '/') return 'home';
    return pathname;
  }, [pathname]);

  useMainTitle(allTitles[keyForTitle]);

  const languages = useMemo(() => {
    return [
      {
        label: '🇫🇷',
        value: 'fr',
      },
      {
        label: '🇬🇧',
        value: 'en',
      },
    ];
  }, []);

  const signOut = useCallback(() => {
    dispatch(saveUser(null));
    dispatch(saveCollectivity(0));
    dispatch(saveDeliveryPoint(0));

    navigate(SIGN_IN);
  }, []);

  const onChangeLocale = useCallback(
    (option: unknown) => {
      // const newLanguage = (option as Option).value as string;
      i18n.changeLanguage((option as Option).value as string).then(() => {
        dispatch(changeLocale((option as Option).value as string));
        // window.location.reload();
      });
    },
    [i18n]
  );

  const value = useMemo(() => {
    return languages.find((lang) => lang.value === i18n.language);
  }, [languages, i18n.language]);

  return (
    <header className={styles.topBar}>
      {!isDesktop && <Drawer />}
      <div className={styles.Content}>
        <MainTitle className={styles.topBar__title} />
        {/*<h2 className={styles.topBar__title} id={'title'} />*/}
        {/*{allTitles[keyForTitle]}*/}
        {/*</h2>*/}
        <div className={styles.topBar__rightSection}>
          <span className={styles.topBar__user}>{`${user?.prenom} ${user?.nom}`}</span>
          <ButtonBase onClick={signOut}>
            <LogoutIcon width={20} />
          </ButtonBase>
          <SelectBase value={value} onChange={onChangeLocale} options={languages} />
        </div>
      </div>
    </header>
  );
}

export default TopBar;
