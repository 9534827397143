import React, { PropsWithChildren, SyntheticEvent } from 'react';
import useControlled from '../../../hook/useControlled';
import AccordionContext from './AccordionContext';

type AccordionProps = {
  expanded?: boolean;
  defaultExpanded?: boolean;
  onChange?: (event: SyntheticEvent, expanded: boolean) => void;
};

function Accordion(props: PropsWithChildren<AccordionProps>) {
  const { children, defaultExpanded = false, expanded: expandedProp, onChange } = props;
  const [expanded, setExpandedState] = useControlled({
    controlled: expandedProp,
    default: defaultExpanded,
    name: 'Accordion',
    state: 'expanded',
  });

  const handleChange = (event: SyntheticEvent) => {
    setExpandedState(!expanded);

    onChange?.(event, !expanded);
  };

  return <AccordionContext.Provider value={{ expanded, toggle: handleChange }}>{children}</AccordionContext.Provider>;
}

// const AccordionRoot = styled.div``;

export default Accordion;
