import { selectCollAndDeliveryPointID } from 'auth/selectors/authSelectors';
import Button from 'fragments/button/Button';
import Checkbox from 'fragments/checkbox/Checkbox';
import DataTable from 'fragments/dataTable/DataTable';
import Input from 'fragments/input/Input';
import { setSnackbar, showSnackbar } from 'internal/actions/appActions';
import SnackbarSeverity from 'internal/constants/snackbar';
import useApi, { Methods } from 'internal/hooks/useApi';
import useIsMobile from 'internal/hooks/useIsMobile';
import { UPDATE_EMPL_ENDPOINT } from 'parameters/constants/endpoints';
import { EmplType, RawEmplType } from 'parameters/types/collectivity';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

type Props = {
  empls: RawEmplType[] | null;
};

const ParametersEmpls = ({ empls }: Props): ReactElement => {
  const { t } = useTranslation(['old']);
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const [emplState, setEmplState] = useState<Record<string, EmplType>>();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const { request: updateEmpl, response: updateEmplResp } = useApi(Methods.POST, UPDATE_EMPL_ENDPOINT);

  const notifySuccess = useCallback(() => {
    dispatch(setSnackbar(t('parameters.modification_success'), SnackbarSeverity.SUCCESS));
    dispatch(showSnackbar());
  }, []);

  useEffect(() => {
    if (empls) {
      setEmplState(
        empls.reduce((acc, empl) => {
          return {
            ...acc,
            [empl.id_empl]: {
              nom_empl: empl.nom,
              addr_empl: empl.adresse,
              cp_empl: empl.cp,
              ville_empl: empl.ville,
              visible_empl: empl.is_visible,
              id_empl: empl.id_empl,
              pas_reservation: empl.pas_reservation,
              creneau_unique: empl.creneau_unique,
            },
          };
        }, {})
      );
    }
  }, [empls]);

  useEffect(() => {
    if (updateEmplResp?.success) {
      notifySuccess();
    }
  }, [updateEmplResp]);

  const handleChangeEmpl = useCallback(
    (id_empl: string, key: string) => (value: string | boolean) => {
      setEmplState((prevState) => {
        if (prevState) {
          return {
            ...prevState,
            [id_empl]: {
              ...prevState[id_empl],
              [key]: value,
            },
          };
        }
      });
    },
    []
  );

  const handleSubmitEmpl = useCallback(
    (id_empl: string) => () => {
      if (emplState) {
        updateEmpl({
          ...emplState[id_empl],
          id_coll,
        });
      }
    },
    [emplState]
  );

  const renderEmplsfields = useMemo(() => {
    if (emplState) {
      return Object.entries(emplState || {})?.map(([, empl]) => ({
        name: (
          <Input
            className={'form-control'}
            type={'text'}
            value={empl.nom_empl}
            handleChange={handleChangeEmpl(String(empl.id_empl), 'nom')}
            controlled
          />
        ),
        address: (
          <textarea
            style={{ height: '36px', marginBottom: '15px' }}
            className={'form-control'}
            id={`${empl.id_empl}_addr`}
            value={empl.addr_empl}
            onChange={(e) => handleChangeEmpl(String(empl.id_empl), 'addr_empl')(e.target.value)}
          />
        ),
        pc: (
          <Input
            className={'form-control'}
            type={'text'}
            value={empl.cp_empl}
            handleChange={handleChangeEmpl(String(empl.id_empl), 'cp_empl')}
            controlled
          />
        ),
        city: (
          <Input
            className={'form-control'}
            type={'text'}
            id={`${empl.id_empl}_city`}
            value={empl.ville_empl}
            handleChange={handleChangeEmpl(String(empl.id_empl), 'ville_empl')}
            controlled
          />
        ),
        visible: (
          <Checkbox
            id={`${empl.id_empl}_city`}
            checked={empl.visible_empl}
            name={''}
            handleChange={handleChangeEmpl(String(empl.id_empl), 'visible_empl')}
          />
        ),
        action: (
          <div className={isMobile ? 'o-scroll' : ''}>
            <Button
              label={t('parameters.update')}
              className={'btn btn-w-xl btn-primary upd_pdl'}
              onClick={handleSubmitEmpl(String(empl.id_empl))}
            />
          </div>
        ),
      }));
    }

    return [];
  }, [emplState, handleChangeEmpl, handleSubmitEmpl, isMobile]);

  return (
    <>
      <p className={'lead mt-5'}>{t('parameters.empl_consigne')}</p>
      <div className={isMobile ? 'o-scroll' : ''}>
        <div className={'dataTables_wrapper container-fluid dt-bootstrap4 no-footer'}>
          <DataTable
            cols={['name', 'address', 'pc', 'city', 'visible', 'action']}
            colTraductionScope={'parameters/pdlDetails'}
            colHandlers={[]}
            items={renderEmplsfields}
          />
        </div>
      </div>
    </>
  );
};

export default ParametersEmpls;
