import React, { LazyExoticComponent } from 'react';
import { generatePath as generatePathRouterDom, Outlet, RouteObject } from 'react-router-dom';
import UsersResaFreepark from 'users/components/UserResaFreepark';
import { get } from 'lodash';
import UserRights from '../auth/constants/userRights';
import Dashboard from '../dashbaord/components/Dashboard';
import DailyFund from '../fund/components/DailyFund';
import FundMoves from '../fund/components/FundMoves';
import BookingConfigPage from '../pages/admin/BookingConfig/BookingConfig.page';
import CollectivityAdminParametersPage from '../pages/admin/Collectivity/CollectivityAdminParametersPage';
import ContactParametersPage from '../pages/admin/Contact/ContactParametersPage';
import DepositConfigPage from '../pages/admin/DepositConfig/DepositConfigPage';
import FormFieldListPage from '../pages/admin/Forms/FormFieldListPage';
import FormsLayout from '../pages/admin/Forms/Forms.layout';
import LocalesParametersPage from '../pages/admin/Locales/LocalesParametersPage';
import ModuleParametersPage from '../pages/admin/Modules/ModuleParametersPage';
import PageAdministration from '../pages/admin/PagesAdministration/PageAdministration';
import PagesAdministrationLayout from '../pages/admin/PagesAdministration/PagesAdministration.layout';
import VisualPage from '../pages/admin/Visual/VisualPage';
import WaitingListParametersPage from '../pages/admin/WaitingList/WaitingListParametersPage';
import WorkshopParametersPage from '../pages/admin/Workshop/WorkshopParametersPage';
import ContractList from '../pages/Contracts/Contracts/ContractList';
import InvoiceList from '../pages/Contracts/Invoices/InvoiceList';
import ListReservations from '../pages/Contracts/ListReservations/ListReservations';
import AddCautionPage from '../pages/Parameters/Caution/AddCaution.page';
import CautionList from '../pages/Parameters/Caution/CautionList';
import PDLDetails from '../pages/Parameters/Collectivity/PDLDetails';
import DegradationList from '../pages/Parameters/Degradation/DegradationList';
import DelayList from '../pages/Parameters/Delay/DelayList';
import CreateDeposit from '../pages/Parameters/Deposit/CreateDeposit';
import DepositList from '../pages/Parameters/Deposit/List/DepositList';
import PaymentProvidersPage from '../pages/Parameters/PaymentProviders/PaymentProviders.page';
import RenewalParametersPage from '../pages/Parameters/Renewal/RenewalParametersPage';
import BikeVoucherPage from '../pages/Parameters/Voucher/components/BikeVoucher.page';
import AccessoryListPage from '../pages/park/AccessoryList/AccessoryList.page';
import BikeList from '../pages/park/BikeList/BikeList';
import ParkListPage from '../pages/park/ConsigneList/ParkListPage';
import ConsigneParkList from '../pages/park/ConsignePark/ConsigneParkList';
import BookingDetailsPage from '../pages/Reservation/BookingDetails.page';
import CheckInPage from '../pages/Reservation/CheckIn.page';
import CheckOutPage from '../pages/Reservation/CheckOut.page';
import CheckoutListPage from '../pages/Reservation/CheckoutList.page';
import DocumentsPage from '../pages/Reservation/Documents.page';
import OldBookingInfosPage from '../pages/Reservation/OldBookingInfos.page';
import ReservationLayout from '../pages/Reservation/Reservation.layout';
import Create from '../pages/Users/Create/Create';
import CheckoutListUserPage from '../pages/Users/Details/CheckoutList.page';
import ContractListPage from '../pages/Users/Details/ContractList.page';
import InvoiceListPage from '../pages/Users/Details/InvoiceList.page';
import List from '../pages/Users/List';
import ReservationList from '../pages/Users/ReservationList/ReservationList';
import Update from '../pages/Users/Update/Update';
import UserLayout from '../pages/Users/UserLayout';
import ParametersTypeEdition from '../parameters/components/checkinCheckout/ParametersTypeEdition';
import ParametersCheckinCheckout from '../parameters/components/ParametersCheckinCheckout';
import ReservationSignature from '../reservations/components/ReservationSignature';
import UsersInvoices from '../users/components/UsersInvoices';
import UsersResaBike from '../users/components/UsersResaBike';
import UsersResaPark from '../users/components/UsersResaPark';
import { UsersReservationLayout } from '../users/components/UsersReservation';
import CollectivityGlobalParamsPage from '../pages/Parameters/Collectivity/CollectivityGlobalParams.page';
import CollectivityLayout from '../pages/Parameters/Collectivity/Collectivity.layout';
import PdlListPage from '../pages/Parameters/Collectivity/PdlList.page';
import EmplListPage from '../pages/Parameters/Collectivity/EmplList.page';
import ThemeLayout from '../pages/admin/Visual/Theme.layout';
import ColorsPage from '../pages/admin/Visual/Colors.page';
import ContractPage from '../pages/admin/Contracts/Contract.page';
import ContractsLayout from '../pages/admin/Contracts/Contracts.layout';
import ProfilesPage from '../pages/admin/Rights/Profiles.page';
import AdminRightsLayout from '../pages/admin/Rights/AdminRights.layout';
import AgentRightsPage from '../pages/admin/Rights/AgentRights.page';
import InsuranceListPage from '../pages/admin/Insurance/InsuranceList.page';
import PartnerInsurancePage from '../pages/admin/Insurance/PartnerInsurance.page';
import AlwinParametersPage from '../pages/admin/Collectivity/AlwinParameters.page';
import VelocareParamsPage from '../pages/admin/Collectivity/VelocareParams.page';
import CollectivityAdminParamsLayout from '../pages/admin/Collectivity/CollectivityAdminParams.layout';
import BroadcastingParametersPage from '../pages/Parameters/Broadcasting/BroadcastingParameters.page';
import BroadcastingLayout from '../pages/Parameters/Broadcasting/Broadcasting.layout';
import BroadcastingContentEditPage from '../pages/Parameters/Broadcasting/BroadcastingContentEdit.page';
import TulipLayout from '../pages/admin/Insurance/tulip/Tulip.layout';
import TulipParamsPage from '../pages/admin/Insurance/tulip/TulipParams.page';
import TulipCustomizationPage from '../pages/admin/Insurance/tulip/TulipCustomization.page';
import TulipProductsPage from '../pages/admin/Insurance/tulip/TulipProducts.page';
import StatsLayout from '../pages/stats/StatsLayout';
import RawDataPage from '../pages/stats/RawData.page';
import ProductsLayout from '../pages/Parameters/Products/Products.layout';
import BikeListPage from '../pages/Parameters/Products/Bike/BikeList.page';
import PriceListLayout from '../pages/Parameters/PriceList/PriceList.layout';
import PriceListPage from '../pages/Parameters/PriceList/PriceList.page';
// import VoucherPage from '../pages/Parameters/Voucher/VoucherPage';
const VoucherPage = React.lazy(() => import('../pages/Parameters/Voucher/VoucherPage'));
// eslint-disable-next-line import/order

/**
 * Function for generate path who can be use with Router Dom
 * @param simplifyPath Intellisense on that param, object path without '.children.'
 * @param options
 * TODO maybe return error link with message if catch an exception
 */
export function generatePath<K extends SimplifyPaths | string>(
  simplifyPath: K,
  options?: {
    raw?: boolean;
    params?: Record<string, unknown>;
    queryParams?: string;
    useCopy?: boolean;
  }
): string {
  const simplifyPathSplitted = simplifyPath.split('.');
  let routeFullPath = '';

  for (let index = 0; simplifyPathSplitted.length > index; index++) {
    const routerObjectPath = simplifyPathSplitted.slice(0, index + 1).join('.children.');
    routeFullPath += get(options?.useCopy ? copyRouter : router, routerObjectPath.concat('.path')).replace('/*', '');

    if (routeFullPath === '/') {
      routeFullPath = '';
    }
  }

  if (routeFullPath === '') routeFullPath = '/';

  try {
    return options?.raw
      ? routeFullPath
      : generatePathRouterDom(routeFullPath, options?.params).concat(options?.queryParams || '');
  } catch (e) {
    return (e as Error).message;
  }
}

export function getRoute<K extends SimplifyPaths | string>(
  simplifyPath: K,
  options?: {
    params?: Record<string, unknown>;
    queryParams?: string;
    pathForMatch?: string;
    end?: boolean;
    useCopy?: boolean;
  }
): Route & { pathForMatch: string; end?: boolean } {
  const simplifyPathSplitted = simplifyPath.split('.');
  const { path, ...routeObject } = get(options?.useCopy ? copyRouter : router, simplifyPathSplitted.join('.children.'));

  return {
    path: generatePath(simplifyPath, options),
    // pathForMatch: generatePath(simplifyPath, { raw: true }),
    pathForMatch: options?.pathForMatch || generatePath(simplifyPath, { raw: true, useCopy: options?.useCopy }),
    end: options?.end,
    ...routeObject,
  };
}
// function reduceRoute<T, R>(prev: T, [routeName, route]: [string, R]): T {
//   const newPathObject = {
//     [routeName]: { path: (route as unknown as Route).path },
//   };
//
//   'children' in route &&
//     Object.assign(newPathObject, {
//       children: Object.entries((route as unknown as Route).children || {}).reduce(
//         reduceRoute,
//         {} as { [key in keyof T['children']]: Path<any> }
//       ),
//     });
//
//   return { ...prev, ...newPathObject };
// }

/*
function flatRoutesToPaths<R extends Router, K extends keyof R>(router: R): { [key in K]: Path<any> } {
  return Object.entries(router).reduce(reduceRoute, {} as { [key in K]: Path<any> });
}

const paths = flatRoutesToPaths(router);
paths.home.children;
*/
type Primitive = string | number | symbol;

type GenericObject = Record<Primitive, unknown>;

type Join<L extends Primitive | undefined, R extends Primitive | undefined> = L extends string | number
  ? R extends string | number
    ? `${L}.${R}`
    : L
  : R extends string | number
  ? R
  : undefined;

type Union<L extends unknown | undefined, R extends unknown | undefined> = L extends undefined
  ? R extends undefined
    ? undefined
    : R
  : R extends undefined
  ? L
  : L | R;

/**
 * NestedPaths
 * Get all the possible paths of an object
 * @example
 * type Keys = NestedPaths<{ a: { b: { c: string } }>
 * // 'a' | 'a.b' | 'a.b.c'
 */
export type NestedPaths<
  T extends GenericObject,
  Prev extends Primitive | undefined = undefined,
  Path extends Primitive | undefined = undefined
> = {
  [K in keyof T]: T[K] extends GenericObject
    ? NestedPaths<T[K], Union<Prev, Path>, Join<Path, K>>
    : Union<Union<Prev, Path>, Join<Path, K>>;
}[keyof T];

// type GenericRoute = {
//   children?: Record<Primitive, GenericRoute>;
// } & {
//   [key: Primitive]: unknown;
// };

export const copyRouter = {
  home: {
    path: '/',
    metadata: {
      authorization: 'dashboard-voir',
      name: 'home',
    },
    children: {
      parameters: {
        path: '/parametres',
        element: <Outlet />,
        metadata: { name: 'parameters', authorization: UserRights.PARAMETERS_GLOBAL },
        children: {
          priceList: {
            path: '/grille-tarifaire',
            element: <PriceListLayout />,
            metadata: { name: 'priceList', authorization: UserRights.PARAMETERS_PRICES_READ },
            children: {
              priceList: {
                index: true,
                element: <PriceListPage />,
              },
            },
          },
          articles: {
            path: '/articles',
            // element: <ParametersArticles />,
            element: <ProductsLayout />,
            metadata: { name: 'articles', authorization: UserRights.PARAMETERS_ARTICLES_READ },
            children: {
              bike: {
                index: true,
                element: <BikeListPage />,
              },
            },
          },
        },
      },
      stats: {
        path: '/statistiques',
        element: <StatsLayout />,
        metadata: {
          name: 'stats',
          authorization: UserRights.STATS_GLOBAL,
        },
        children: {
          indicators: {
            path: '',
            index: true,
            element: <div>test indicateur</div>,
          },
          raw: {
            path: '/donnee-brutes',
            element: <RawDataPage />,
          },
        },
      },
    },
  },
};

export const router = {
  home: {
    path: '/',
    metadata: {
      authorization: 'dashboard-voir',
      name: 'home',
    },
    children: {
      dashboard: {
        index: true,
        path: '/',
        element: <Dashboard />,
      },
      user: {
        path: '/usagers',
        element: <Outlet />,
        metadata: {
          name: 'user',
          authorization: UserRights.USERS_GLOBAL,
        },
        children: {
          create: {
            path: '/creation',
            element: <Create />,
            metadata: { name: 'user_create', authorization: UserRights.USERS_CREATE },
          },
          list: {
            path: '/liste',
            // element: <UsersList />,
            element: <List />,
            metadata: { name: 'user_list', authorization: UserRights.USERS_LIST },
          },
          userDetails: {
            path: '/:userId',
            element: <UserLayout />,
            children: {
              infos: {
                index: true,
                element: <Update />,
              },
              contractList: { path: '/contrats', element: <ContractListPage /> },
              invoices: { path: '/factures', element: <InvoiceListPage /> },
              booking: {
                path: '/reservations',
                element: <ReservationList />,
              },
              checkout: { path: '/paiement-agence', element: <CheckoutListUserPage /> },
            },
          },
          invoices: { path: '/factures', element: <UsersInvoices /> },
          edit: { path: '/edition', element: <Update /> },
          reservationList: { path: '/reservations', element: <ReservationList /> },
          // contractList: { path: '/contrats', element: <UserContracts /> },
          validation: { path: '/validation', element: <Update /> },
          // checkout: { path: '/paiement-agence', element: <UsersCheckout /> },
          newReservation: {
            path: '/nouvelle-reservation/*',
            element: <UsersReservationLayout />,
            children: {
              bike: {
                path: '/velo',
                element: <UsersResaBike />,
              },
              park: {
                path: '/consignes',
                element: <UsersResaPark />,
              },
              freePark: {
                path: '/consignes-libres',
                element: <UsersResaFreepark />,
              },
              //eslint-disable-next-line
            },
          },
          // reservationDetails: { path: '/detail-reservation', element: <ReservationDetails /> },
          reservationDetails: {
            path: '/detail-reservation',
            // element: <ReservationDetails />,
            element: <ReservationLayout />,
            children: {
              details: {
                path: '',
                index: true,
                // element: <InfosReservationPage />,
                element: <OldBookingInfosPage />,
              },
              vouchers: {
                path: '/pj',
                element: <DocumentsPage />,
              },
              test: {
                path: '/:userId/:bookingId',
                element: <BookingDetailsPage />,
              },
              checkout: {
                path: '/encaissement',
                element: <CheckoutListPage />,
              },
            },
          },
          sign: { path: '/signature', element: <ReservationSignature /> },
        },
      },
      /*stats: {
        path: '/statistiques',
        element: <StatsLayout />,
        metadata: {
          name: 'stats',
          authorization: UserRights.STATS_GLOBAL,
        },
        children: {
          indicators: {
            path: '',
            index: true,
            element: <div>test indicateur</div>,
          },
          raw: {
            path: '/donnee-brutes',
            element: <RawDataPage />,
          },
        },
      },*/
      invoicing: {
        path: '/contrats-factures',
        element: <Outlet />,
        metadata: { name: 'invoicing', authorization: UserRights.INVOICING_GLOBAL },
        children: {
          contracts: {
            path: '/contrats',
            element: <ContractList />,
            metadata: { name: 'contracts', authorization: UserRights.INVOICING_CONTRACT },
          },
          invoices: {
            path: '/factures',
            element: <InvoiceList />,
            // element: <Invoices />,
            metadata: { name: 'invoices', authorization: UserRights.INVOICING_INVOICE },
          },
          bookings: {
            path: '/reservations',
            element: <ListReservations />,
            metadata: { name: 'bookings', authorization: UserRights.INVOICING_RESERVATION },
          },
          checkInVerifs: {
            path: '/:userId/:bookingId/checkin',
            element: <CheckInPage />,
          },
          checkOutVerifs: {
            path: '/:userId/:bookingId/checkout',
            element: <CheckOutPage />,
          },
          // reservationDetails: { path: '/detail-reservation', element: <ReservationDetails /> },
          reservationDetails: {
            path: '/detail-reservation/:userId/:bookingId',
            // element: <ReservationDetails />,
            element: <ReservationLayout />,
            children: {
              details: {
                // path: '/:userId/:bookingId',
                index: true,
                // element: <InfosReservationPage />,
                // element: <OldBookingInfosPage />,
                element: <BookingDetailsPage />,
              },
              vouchers: {
                path: '/pj',
                element: <DocumentsPage />,
              },
              checkout: {
                path: '/encaissement',
                element: <CheckoutListPage />,
              },
            },
          },
        },
      },
      funds: {
        path: '/caisse',
        element: <Outlet />,
        metadata: { name: 'fund', authorization: UserRights.FUND_GLOBAL },
        children: {
          daily: {
            path: '/jour',
            element: <DailyFund />,
            metadata: { name: 'daily', authorization: UserRights.FUND_DAILY },
          },
          moves: {
            path: '/mouvements',
            element: <FundMoves />,
            metadata: { name: 'moves', authorization: UserRights.FUND_MOVES },
          },
        },
      },
      park: {
        path: '/parc',
        element: <Outlet />,
        metadata: { name: 'park', authorization: UserRights.PARK_GLOBAL },
        children: {
          bikeList: {
            path: '/velos',
            element: <BikeList />,
            metadata: { name: 'park_bikes', authorization: UserRights.PARK_BIKES_CONSULT },
          },
          freeParkList: {
            path: '/consignes-libres',
            element: <ConsigneParkList />,
            metadata: { name: 'consigne_park', authorization: UserRights.PARK_LIST_CONSULT },
          },
          parkList: {
            path: '/consignes',
            element: <ParkListPage />,
            metadata: { name: 'consigne', authorization: UserRights.PARK_CONSIGNE_CONSULT },
          },
          accessoryList: {
            path: '/accessoires',
            element: <AccessoryListPage />,
            metadata: { name: 'accessories', authorization: UserRights.PARK_ACCESSORIES_CONSULT },
          },
        },
      },
      parameters: {
        path: '/parametres',
        element: <Outlet />,
        metadata: { name: 'parameters', authorization: UserRights.PARAMETERS_GLOBAL },
        children: {
          checkinCheckout: {
            path: '/checkin-checkout',
            element: <ParametersCheckinCheckout />,
            metadata: { name: 'checkin_checkout', authorization: UserRights.CHECKIN_CHECKOUT_READ },
          },
          checkinCheckoutTypeEdition: {
            path: '/checkin-checkout/type-edition',
            element: <ParametersTypeEdition />,
          },
          broadcast: {
            path: '/diffusion',
            element: <BroadcastingLayout />,
            metadata: { name: 'broadcasting', authorization: UserRights.PARAMETERS_BROADCASTING_READ },
            children: {
              parameters: {
                index: true,
                element: <BroadcastingParametersPage />,
              },
              editContent: {
                path: '/:type',
                element: <BroadcastingContentEditPage />,
              },
            },
          },
          collectivity: {
            path: '/collectivite',
            element: <CollectivityLayout />,
            metadata: { name: 'collectivity', authorization: UserRights.PARAMETERS_COLLECTIVITY_READ },
            children: {
              global: {
                index: true,
                element: <CollectivityGlobalParamsPage />,
              },
              pdlList: {
                path: '/pdl-list',
                element: <PdlListPage />,
              },
              emplList: {
                path: '/empl-list',
                element: <EmplListPage />,
              },
            },
          },
          pdlCollectivity: { path: '/collectivite/pdl', element: <PDLDetails /> },
          renewal: {
            path: '/renouvellement',
            element: <RenewalParametersPage />,
            metadata: { name: 'renewal', authorization: UserRights.PARAMETERS_RENEWAL_READ },
          },
          /*prices: {
            path: '/articles',
            // element: <ParametersArticles />,
            element: <ProductsLayout />,
            metadata: { name: 'prices', authorization: UserRights.PARAMETERS_PRICES_READ },
            children: {
              bike: {
                index: true,
                element: <BikeListPage />,
              },
            },
          },*/
          deposits: {
            path: '/caution',
            element: <CautionList />,
            metadata: { name: 'deposits', authorization: UserRights.PARAMETERS_DEPOSITS_READ },
          },
          addDeposit: {
            path: '/caution/add',
            element: <AddCautionPage />,
          },
          securityDeposit: {
            path: '/depot-de-garantie',
            element: <DepositList />,
            metadata: { name: 'security_deposits', authorization: UserRights.PARAMETERS_SECURITY_DEPOSITS_READ },
          },
          createSecurityDeposit: {
            path: '/depot-de-garantie/create',
            element: <CreateDeposit />,
          },
          degradations: {
            path: '/penalite-degradation',
            element: <DegradationList />,
            metadata: { name: 'degradations', authorization: UserRights.PARAMETERS_DEGRADATIONS_READ },
          },
          delays: {
            path: '/penalite-retard',
            element: <DelayList />,
            metadata: { name: 'delays', authorization: UserRights.PARAMETERS_DELAYS_READ },
          },
          vouchers: {
            path: '/pieces-justificatives/*',
            // element: <ParametersVouchers />,
            metadata: { name: 'vouchers', authorization: UserRights.PARAMETERS_VOUCHERS_READ },
            element: (
              // <React.Suspense fallback={<>...</>}>
              <VoucherPage />
              // </React.Suspense>
            ),
            children: {
              list: {
                path: '/:articleType',
                element: <BikeVoucherPage />,
              },
            },
            /*children: {
              bike: {
                index: true,
                path: '',
                element: <BikeVoucherPage />,
              },
              park: {
                path: '/consignes',
                element: <div>bonjour</div>,
              },
              freePark: {
                path: '/consignes-libres',
                element: <div>bonjour</div>,
              },
            },*/
          },
        },
      },
      admin: {
        path: '/administration',
        element: <Outlet />,
        metadata: { name: 'admin', authorization: UserRights.PARAMETERS_GLOBAL },
        children: {
          collectivity: {
            path: '/collectivite',
            element: <CollectivityAdminParamsLayout />,
            metadata: { name: 'admin_coll', authorization: UserRights.PARAMETERS_ADMIN_READ },
            children: {
              global: {
                index: true,
                element: <CollectivityAdminParametersPage />,
                metadata: { name: 'admin_coll' },
              },
              paymentProviders: {
                path: '/prestataires-de-paiement',
                // element: <ParametersPaymentProviders />,
                element: <PaymentProvidersPage />,
                metadata: { name: 'payment_providers', authorization: UserRights.PARAMETERS_ADMIN_READ },
              },
              velocare: {
                path: '/velocare',
                element: <VelocareParamsPage />,
                metadata: { name: 'admin_coll' },
              },
              alwin: {
                path: '/alwin',
                element: <AlwinParametersPage />,
                metadata: { name: 'admin_coll' },
              },
            },
          },
          waitingList: {
            path: '/file-attente',
            element: <WaitingListParametersPage />,
            metadata: { name: 'waiting_list', authorization: UserRights.WAITING_LIST_READ },
          },
          contracts: {
            path: '/contrats',
            element: <ContractsLayout />,
            metadata: { name: 'contracts', authorization: UserRights.PARAMETERS_CONTRACT_READ },
            children: {
              edit: {
                path: '/:contractType',
                element: <ContractPage />,
              },
            },
          },
          theme: {
            path: '/theme',
            element: <ThemeLayout />,
            metadata: { name: 'theme', authorization: UserRights.PARAMETERS_VISUAL_READ },
            children: {
              visual: {
                index: true,
                element: <VisualPage />,
              },
              colors: {
                path: '/colors',
                element: <ColorsPage />,
              },
            },
          },
          permission: {
            path: '/droits',
            element: <AdminRightsLayout />,
            metadata: { name: 'permission', authorization: UserRights.PARAMETERS_PERMISSION_READ },
            children: {
              userRights: {
                index: true,
                element: <ProfilesPage />,
              },
              agentsRights: {
                path: '/agent',
                element: <AgentRightsPage />,
              },
            },
          },
          locales: {
            path: '/langues',
            element: <LocalesParametersPage />,
            metadata: { name: 'locales', authorization: UserRights.PARAMETERS_LOCALES_READ },
          },
          pages: {
            path: '/pages',
            // element: <ParametersPages />,
            element: <PagesAdministrationLayout />,
            children: {
              pageConfig: {
                path: '/:lang',
                element: <PageAdministration />,
              },
            },
            metadata: { name: 'pages', authorization: UserRights.PARAMETERS_PAGES_READ },
          },
          workshop: {
            path: '/page-atelier',
            element: <WorkshopParametersPage />,
            metadata: { name: 'workshop', authorization: UserRights.PARAMETERS_WORKSHOP_READ },
          },
          contact: {
            path: '/page-contact',
            element: <ContactParametersPage />,
            metadata: { name: 'contact', authorization: UserRights.PARAMETERS_CONTACT_READ },
          },
          modules: {
            path: '/modules-actifs',
            element: <ModuleParametersPage />,
            metadata: { name: 'modules', authorization: UserRights.PARAMETERS_MODULES_READ },
          },
          configCaution: {
            path: '/config-caution',
            // element: <ParametersConfigDeposit />,
            element: <DepositConfigPage />,
            metadata: { name: 'config_deposit', authorization: UserRights.PARAMETERS_BOOKING_READ },
          },
          configBooking: {
            path: '/config-reservation',
            // element: <ParametersConfigBooking />,
            element: <BookingConfigPage />,
            metadata: { name: 'booking_deposit', authorization: UserRights.PARAMETERS_CAUTION_READ },
          },
          insurance: {
            path: '/assurance',
            element: <Outlet />,
            metadata: { name: 'insurance', authorization: UserRights.INSURANCE_READ },
            children: {
              list: {
                index: true,
                element: <InsuranceListPage />,
              },
              partner: {
                path: '/partenaire',
                element: <PartnerInsurancePage />,
              },
              tulip: {
                path: '/tulip',
                element: <TulipLayout />,
                children: {
                  root: {
                    index: true,
                    element: <TulipParamsPage />,
                  },
                  customize: {
                    path: '/personalisation',
                    element: <TulipCustomizationPage />,
                  },
                  products: {
                    path: '/produits',
                    element: <TulipProductsPage />,
                  },
                },
              },
            },
          },
          forms: {
            path: '/formulaires',
            element: <FormsLayout />,
            metadata: { name: 'forms', authorization: UserRights.PARAMETERS_FORMS_READ },
            children: {
              formEdit: { path: '/:formSlug', element: <FormFieldListPage /> },
            },
          },
        },
      },
    },
  },
};

type GenericRoute = Record<
  Primitive,
  //eslint-disable-next-line
  { path?: string; children?: unknown; index?: boolean; element?: unknown; metadata?: any }
>;

type NestedRoutePaths<
  T extends GenericRoute,
  Prev extends Primitive | undefined = undefined,
  Path extends Primitive | undefined = undefined
> = {
  [K in keyof T]: T[K] extends { children: GenericRoute } & GenericRoute
    ? NestedRoutePaths<T[K]['children'], Union<Prev, Path>, Join<Path, K>>
    : Union<Union<Prev, Path>, Join<Path, K>>;
}[keyof T];

// type Assertion = typeof testRouter.home extends { children: GenericRoute } & GenericRoute ? 'vrai' : 'faux';
// type UnionTest = Union<Union<'home', 'home.details'>, Join<'home.details', 'bonjour'>>;

type SimplifyPaths = NestedRoutePaths<typeof router>;

type Router = Record<string, Route>;

export type Route = {
  path?: string;
  index?: boolean;
  //eslint-disable-next-line
  element: JSX.Element | LazyExoticComponent<any>;
  children?: Router;
  //eslint-disable-next-line
  metadata?: any;
};

function reduceRoute(prev: RouteObject[], { children, path, element, ...currentRoute }: Route): RouteObject[] {
  const route = {
    ...currentRoute,
    element: element as React.ReactNode,
    path: path ? (path === '/' ? path : path.slice(1)) : undefined,
  };

  if (currentRoute.index) delete route.path;

  if (!currentRoute.index && children) {
    Object.assign(route, { children: Object.values(children || {}).reduce(reduceRoute, [] as RouteObject[]) });
  }

  return [...prev, route as RouteObject];
}

export function transformAppRouterObjectForRouterDom(router: Router) {
  return Object.values(router).reduce(reduceRoute, [] as RouteObject[]);
}
