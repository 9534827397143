import React, { ReactElement } from 'react';
import { ReservationType } from 'reservations/types/reservation';

import ReservationLines from 'reservations/components/reservation/ReservationLines';

type Props = {
  resaID: string;
  reservation: ReservationType;
  isFree: boolean;
  isRenewal: boolean;
  isPark: boolean;
  refresh: () => void;
};

export enum ArticleFormType {
  BIKE = 'bookingBike',
  CONSIGNE = 'bookingConsigne',
  CONSIGNEPARK = 'bookingConsignePark',
}

const ReservationSummary = ({ resaID, reservation, isFree, isRenewal, isPark, refresh }: Props): ReactElement => {
  return (
    <div className={'col-md-4'}>
      <ReservationLines
        resaID={resaID}
        reservation={reservation}
        isFree={isFree}
        isRenewal={isRenewal}
        isPark={isPark}
        refresh={refresh}
      />
    </div>
  );
};

export default ReservationSummary;
