import dayjs from 'dayjs';
import { t } from 'internal/i18n';
import { ValidationType } from 'users/types/forms';

import validator from 'validator';

export const validations: ValidationType = {
  notEmpty: (value: string) => (validator.isEmpty(value) ? t('forms.mandatoryField') : ''),
  nature: (value: string) => (value === '0' || validator.isEmpty(value) ? t('forms.mandatoryField') : ''),
  civilite: (value: string) => (value === '0' || validator.isEmpty(value) ? t('forms.mandatoryField') : ''),
  password: (value: string): string =>
    !validator.isStrongPassword(value, {
      minLength: 8,
      minNumbers: 0,
      minSymbols: 0,
      minUppercase: 1,
    })
      ? t('error.password_error')
      : '',
  email: (value: string): string => (!validator.isEmail(value) ? t('forms.email_error') : ''),
  iban: (value: string): string => (!validator.isIBAN(value) ? t('forms.invalid_iban') : ''),
  ibanNotEmpty: (value: string): string => {
    const isNotEmpty = !validator.isEmpty(value);

    if (isNotEmpty && !validator.isIBAN(value)) {
      return t('forms.invalid_iban');
    }

    return '';
  },
  bic: (value: string): string => (!validator.isBIC(value) ? t('forms.invalid_bic') : ''),
  bicNotEmpty: (value: string): string => {
    const isNotEmpty = !validator.isEmpty(value);

    if (isNotEmpty && !validator.isBIC(value)) {
      return t('forms.invalid_bic');
    }

    return '';
  },
  abotc: (value: string): string =>
    !validator.isNumeric(value) ||
    !validator.isLength(value, {
      min: 10,
      max: 10,
    })
      ? t('forms.navigo_error')
      : '',
  date_naissance: (value: string) => {
    const today = dayjs();
    const birthDate = dayjs(value);
    if (today.diff(birthDate, 'years') < 18) {
      return t('error.mustBeAnAdult');
    }
    if (!validator.isDate(value)) {
      return t('forms.mandatoryField');
    }
    return '';
  },
};
