import React from 'react';
import { generatePath, NavLink, Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from '../../Reservation/bookingDetails.module.scss';
import { TabComponent } from '../../Reservation/Reservation.layout';
import { Tab, Tabs, TabsList } from '../../../components/Core/Tabs/Tabs';
import { NumberListIcon, PriceListIcon, TimeIcon } from '../../../const/icons';

function PriceListLayout() {
  const { t } = useTranslation(['parameters/priceList/layout']);

  return (
    <div className={'reset'}>
      <div className={styles.Layout} id={'price-list-layout'}>
        <Tabs className={styles.Layout__tabs}>
          <TabsList>
            <Tab
              component={({ children, ...otherProps }: TabComponent) => (
                <NavLink {...otherProps} to={generatePath('.')} end>
                  {children}
                </NavLink>
              )}>
              <PriceListIcon width={16} /> {t('all')}
            </Tab>
            <Tab
              component={({ children, ...otherProps }: TabComponent) => (
                <NavLink {...otherProps} to={generatePath('duree')} end>
                  {children}
                </NavLink>
              )}>
              <TimeIcon width={16} /> {t('durations')}
            </Tab>
            <Tab
              component={({ children, ...otherProps }: TabComponent) => (
                <NavLink {...otherProps} to={generatePath('type')} end>
                  {children}
                </NavLink>
              )}>
              <NumberListIcon width={16} /> {t('priceTypes')}
            </Tab>
          </TabsList>
        </Tabs>
      </div>

      <React.Suspense fallback={<></>}>
        <Outlet />
      </React.Suspense>
    </div>
  );
}

export default PriceListLayout;
