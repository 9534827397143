import React, { useCallback, useEffect, useState } from 'react';
import Select, { MultiValue } from 'react-select';

export type OptionType = { label: string; value: string };

type Props = {
  id: string;
  onChange: (value: string) => void;
  defaultValue?: string;
  wrapperClassName?: string;
  label?: string;
  placeholder?: string;
  options?: OptionType[];
};

// prettier-ignore
const MultiSelect = ({
  id,
  defaultValue,
  onChange,
  wrapperClassName,
  label,
  options,
}: Props): React.ReactElement => {
  const [values, setValues] = useState<MultiValue<OptionType>>();

  useEffect(() => {
    if (options && defaultValue) {
      const defaultValues = defaultValue.split(',');
      const defaultOptions = options.filter((o) => defaultValues.includes(o.value));
      if (defaultOptions) {
        setValues(defaultOptions);
        onChange(defaultOptions.map((o) => o.value).join(','));
      }
    }
  }, [defaultValue]);

  const handleChange = useCallback((value: MultiValue<OptionType>) => {
    setValues(value);
    onChange(value.map((v) => v.value).join(','));
  }, []);

  return (
    <div className={wrapperClassName}>
      <label htmlFor={id}>{label}</label>
      <Select
        key={`multi-select-${id}`}
        options={options}
        value={values}
        onChange={handleChange}
        isMulti
        closeMenuOnSelect
      />
    </div>
  );
};

export default MultiSelect;
