import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  ChartDataset,
  ChartOptions,
  Legend,
  LinearScale,
  Title,
  Tooltip,
  Plugin,
  ChartConfiguration,
  Chart,
  TooltipItem,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { ComponentPropsWithoutRef, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import _, { cloneDeep } from 'lodash';
import { createPortal } from 'react-dom';
import { autoPlacement, useClick, useDismiss, useFloating, useInteractions } from '@floating-ui/react';
import IconButton from '../../Core/Button/IconButton';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const defaultOptions: (args?: OptionArgs) => ChartOptions<'bar'> = (args) => ({
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    intersect: false,
    mode: 'index',
  },
  plugins: {
    tooltip: {
      position: 'nearest',
      mode: 'point',
      displayColors: false,
      backgroundColor: '#17394d',
      // mode: 'index',
      cornerRadius: 8,
      callbacks: {
        title(tooltipItems: TooltipItem<'bar'>[]): string | string[] | void {
          return tooltipItems.map((tooltipItem) => {
            return `${tooltipItem.dataset.label}`;
          });
        },
        label: (tooltipItem: TooltipItem<'bar'>): string | string[] | void => {
          return [`Total                                 ${tooltipItem.dataset.data[0]}`];
        },
      },
    },
    legend: {
      display: false,
      position: 'top',
    },
    title: {
      display: true,
      text: args?.title || 'Type de vélos',
      position: 'bottom',
    },
  },
  scales: {
    x: {
      beginAtZero: true,
      display: false,
      grid: {
        display: false,
      },
    },
    y: {
      beginAtZero: true,
      ticks: {
        // stepSize: 10,
        callback: function (val, index) {
          // Hide every 2nd tick label
          return index % 2 === 0 ? this.getLabelForValue(Number(val)) : '';
        },
      },
      min: 0,
    },
  },
});

export type SimpleStackedBarData = {
  label: string;
  data: number;
  backgroundColor?: string;
}[];

export const createData: (items: SimpleStackedBarData) => ChartData<'bar'> = (items) => {
  //eslint-disable-next-line
  const datasets: ChartDataset<'bar', any>[] = items.map((item, index) => ({
    label: item.label,
    data: [item.data],
    backgroundColor: item.backgroundColor,
    minBarLength: 10,
    borderRadius: 15,
    barPercentage: 0.5,
  }));

  return {
    // labels: items.map((item) => item.label),
    labels: ['total'],
    datasets,
  };
};

// export const createData: (
//   labels: string[],
//   data: number[][] | number[],
//   datasetsLabels: string[]
// ) => ChartData<'bar'> = (labels, data, datasetsLabels) => {
//   //eslint-disable-next-line
//   const datasets: ChartDataset<'bar', any>[] = [
//     {
//       label: datasetsLabels[0],
//       data: data[0],
//       /*data: [
//         { x: 'standard', total: 40, available: 10 },
//         { x: 'PLIANT', total: 100, available: 60 },
//         // { x: 'PLIANT', total: 100, available: 60 },
//       ],*/
//       backgroundColor: ['#0081c9', '#7149c6', '#cc79ce', '#ff7e67', '#f39233', '#ffdf31', '#03c988'],
//       // hoverBackgroundColor: (ctx) => ctx.
//       minBarLength: 10,
//       borderRadius: 15,
//       barPercentage: 0.5,
//       // order: 1,
//     },
//   ];
//
//   /*if (data.length > 1) {
//     datasets.push({
//       label: datasetsLabels[1],
//       data: data[1],
//       backgroundColor: ['#00000029'],
//       yAxisID: 'test',
//       borderRadius: 15,
//       barPercentage: 0.5,
//       minBarLength: 10,
//       order: 0,
//     });
//   }*/
//
//   return {
//     labels,
//     datasets,
//   };
// };

type OptionArgs = {
  title: string;
};

type StackedBarProps = Omit<Partial<ComponentPropsWithoutRef<typeof Bar>>, 'options' | 'data'> & {
  labels?: string[];
  data: SimpleStackedBarData;
  datasetLabels?: string[];
  containerLegendId?: string;
  optionArgs?: OptionArgs;
};

export const htmlLegendPlugin: Plugin = {
  id: 'htmlLegend',
  afterUpdate: (chart) => {
    const legendElements: Element[] = [];
    const config = chart.config as ChartConfiguration<'bar'>;
    // const ul = document.createElement('ul');

    const backgroundColor = config.data.datasets.find((dataset) => dataset.label === 'total')
      ?.backgroundColor as unknown as string[] | undefined;
    if (backgroundColor) {
      chart.config.data.labels?.forEach((label, index) => {
        const li = document.createElement('li');
        li.style.alignItems = 'center';
        li.style.cursor = 'pointer';
        li.style.display = 'flex';
        li.style.flexDirection = 'row';
        li.style.marginLeft = '10px';

        li.onclick = () => {
          // const { type } = config;
          // if (type === 'pie' || type === 'doughnut') {
          // Pie and doughnut charts only have a single dataset and visibility is per item
          // chart.toggleDataVisibility(index);
          // } else {
          chart.toggleDataVisibility(index);
          chart.getDatasetMeta(0).controller.getDataset().hidden = true;

          // chart.setDatasetVisibility(index, !chart.isDatasetVisible(index));
          // }
          chart.update();
        };
        /* li.addEventListener('click', () => {
          console.log('test');
        });*/

        // Color box
        const boxSpan = document.createElement('span');
        boxSpan.style.background = backgroundColor[index];
        // boxSpan.style.borderColor = item.strokeStyle;
        // boxSpan.style.borderWidth = item.lineWidth + 'px';
        boxSpan.style.display = 'inline-block';
        boxSpan.style.flexShrink = '0';
        boxSpan.style.height = '20px';
        boxSpan.style.marginRight = '10px';
        boxSpan.style.width = '20px';

        // Text
        const textContainer = document.createElement('p');
        // textContainer.style.color = item.fontColor;
        textContainer.style.margin = '0';
        textContainer.style.padding = '0';
        textContainer.style.textDecoration = chart.getDatasetMeta(index).hidden ? 'line-through' : '';

        const text = document.createTextNode(label as string);
        textContainer.appendChild(text);

        li.appendChild(boxSpan);
        li.appendChild(textContainer);
        // ul.appendChild(li);
        legendElements.push(li);
      });

      // legendElements.push(ul);
    }

    //eslint-disable-next-line
    // @ts-ignore
    Object.assign(chart, { legendElements });
  },
};

type CustomLegendProps = {
  legendItems: HTMLElement[];
};

export function CustomLegend(props: CustomLegendProps) {
  const { legendItems } = props;
  const [isOpen, setIsOpen] = useState(false);
  const legendContainer = useRef<HTMLUListElement | null>(null);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [autoPlacement({ allowedPlacements: ['bottom-end', 'bottom', 'bottom-start'] })],
  });

  const click = useClick(context, { toggle: true });
  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss]);

  useEffect(() => {
    if (legendContainer.current) {
      legendItems.forEach((item) => {
        legendContainer.current?.appendChild(item);
      });
    }
  }, [legendContainer.current, legendItems]);

  return (
    <div ref={refs.setReference}>
      <IconButton icon={'SettingsIcon'} {...getReferenceProps()} />

      {isOpen && (
        <div
          className={'legend-tooltip'}
          ref={refs.setFloating}
          style={{ ...floatingStyles, zIndex: 200 }}
          {...getFloatingProps()}>
          <ul
            ref={legendContainer}
            // dangerouslySetInnerHTML={{ __html: legendItems.map((item) => item.outerHTML).join('') }}
          >
            {/*{legendItems.map((element) => {
              // console.log(element.attributes.getNamedItem('style'));
              const _style = getComputedStyle(element);
              console.log('test', _style);
              const reactElement = react.createElement(element.tagName.toLowerCase(), {
                ...element.attributes,
                className: element.className,
                onClick: element.onclick,
                children: parser(element.innerHTML),
              });

              reactElement;

              return reactElement;
            })}*/}
          </ul>
        </div>
      )}
    </div>
  );
}

export const emptyPlugin = {
  id: 'emptyPlugin',
  afterDraw: (chart: Chart) => {
    if (
      /*(chart.data.datasets.length > 0 &&
        Array.isArray(chart.data.datasets[0].data) &&
        (chart.data.datasets[0].data.length === 0 || chart.data.datasets[0].data?.every((item) => item === 0))) ||*/
      _.every(chart.data.datasets, ['data', [Number.NaN]])
    ) {
      const ctx = chart.ctx;
      // chart.clear();
      chart.reset();
      /*ctx.save();
      ctx.restore();*/
      const width = chart.width;
      const height = chart.height;

      ctx.save();
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillStyle = '#414b5a';
      ctx.font = 'bold 12px Roboto';
      ctx.fillText('Nous sommes désolé.', width / 2, height / 2 - 7);
      ctx.font = '12px Roboto';
      ctx.fillText('Nous n’avons aucune donnée à afficher', width / 2, height / 2 + 7);
      ctx.restore();
      // ctx.save();
      // chart.update();
    }
  },
};

function StackedBar(props: StackedBarProps) {
  const chart = useRef<ChartJSOrUndefined<'bar'>>();
  const { containerLegendId, labels, data, datasetLabels, ...propsToForward } = props;
  const [container, setContainer] = useState<HTMLElement | null>(null);
  //eslint-disable-next-line
  const [legendItems, setLegendItems] = useState<any[]>([]);

  const options = useMemo(() => {
    // const max = Math.round(Math.max(...data[0]) / 10) * 10;
    const _defaultOptions = cloneDeep(defaultOptions(props.optionArgs));
    // _defaultOptions.scales!.y!.max = max;
    // _defaultOptions.scales!.test!.max = max;

    if (chart.current) {
      chart.current.options = _defaultOptions;
      chart.current.update();
    }

    return _defaultOptions;
  }, [data, chart.current, props.optionArgs]);

  useEffect(() => {
    if (chart.current) {
      // console.log(chart.current?.config.data.datasets);
      // console.log(chart.current);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // const legendItems = chart.current?.options.plugins?.legend?.labels?.generateLabels(chart.current);
      const legendItems = chart.current.legendElements;
      if (legendItems) setLegendItems(legendItems);
    }
  }, [chart.current]);

  useLayoutEffect(() => {
    if (containerLegendId) {
      const element = document.getElementById(containerLegendId);
      if (element) setContainer(element);
    }
  }, [containerLegendId]);

  return (
    <>
      {container && createPortal(<CustomLegend legendItems={legendItems} />, container)}
      <Bar
        ref={chart}
        {...propsToForward}
        options={options}
        plugins={[htmlLegendPlugin, emptyPlugin]}
        data={createData(data)}
      />
    </>
  );
}

export default StackedBar;
