import { RawDegradationType, RawDelayType } from '../reservations/types/reservation';
import { RawBooking } from '../components/BookingCard/BookingCard';
import { instance, ListQueryParams } from './user.api';

const URL = {
  getBooking: 'user/reservation',
  getAttachments: 'user/pj',
  getVouchers: 'user/pjlist',
  postAttachment: 'user/media64',
  getCheckoutList: 'user/caisse',
  postRegularization: 'user/caisseregul',
  postPenalty: 'user/caisseretdeg',
  getDegradationList: 'param/degradations',
  getSogecommerceConfig: 'param/sogecommerceenabled',
  getDelayList: 'param/retards',
  postRefund: 'user/rbt',
};

type GetBookingQueryParams = {
  id_coll: number;
  id_user: number;
  id_resa: number;
};

// export type RawBooking = {
//   id_consigne: null | number;
//   id_velo: number;
//   id_interne: string;
//   nom_empl: string;
//   nom_pdl: string;
//   id_pdl: number;
//   numero_resa: number;
//   date_reservation: string;
//   date_start: string;
//   date_end: string;
//   nom: string;
//   prenom: string;
//   date_naissance: string;
//   id_paiement: number;
//   id_userbo_valid: number;
//   valid_bo: number;
//   pdl_valid: string;
//   date_valid: string;
//   article: string;
//   nom_tarif: string;
//   nom_type: string;
//   contrat_link: string;
//   isVelocareSynced: boolean;
//   is_returned: number;
//   is_canceled: number;
//   date_return: null;
//   date_cancel: null;
//   id_parent_resa: null;
//   total_ttc: string;
//   total_ht: string;
//   devise: string;
//   id_user: number;
//   is_auto: number;
//   nom_user: string;
//   prenom_user: string;
//   date_rbt_caution: null | string;
//   commentaire: null | string;
//   montant_caution_ttc: string;
//   montant_depot_garantie_ht: string;
//   rum: string;
//   checkout_link: string | null;
// };

type ListResponse<T> = {
  currentPage: number;
  itemPerPage: number;
  // eslint-disable-next-line
  filters: any;
  // eslint-disable-next-line
  result: T[];
  total: number;
  totalPage: number;
};

export function getBooking(queryParams: GetBookingQueryParams) {
  return instance.get<RawBooking>(URL.getBooking, { params: queryParams });
}

export function getAttachments(queryParams: ListQueryParams & { id_user: number; id_coll: number }) {
  //eslint-disable-next-line
  return instance.get<ListResponse<any>>(URL.getAttachments, { params: queryParams });
}

type RawVoucher = {
  isRequired: boolean;
  pjId: number;
  pjName: string;
};

//eslint-disable-next-line
export function getVouchers(queryParams: any) {
  return instance.get<Record<string, RawVoucher[]>>(URL.getVouchers, { params: queryParams });
}

type PostAttachmentBodyForm = {
  pjName: string;
  id_coll: number;
  id_user: number;
  file: string;
};

export function postAttachment(data: PostAttachmentBodyForm) {
  return instance.post(URL.postAttachment, data);
}

type GetCheckoutListQueryParams = ListQueryParams & {
  id_coll: number;
  id_user: number;
};

export function getCheckoutList(queryParams: GetCheckoutListQueryParams) {
  return instance.get(URL.getCheckoutList, { params: queryParams });
}

type PostRegularizationBodyForm = {
  id_resa: number;
  id_coll: number;
  id_user: number;
  montant: string;
  moyen: string;
};

export function postRegularization(data: PostRegularizationBodyForm) {
  return instance.post(URL.postRegularization, data);
}

export function getDelayPenaltyList(queryParams: ListQueryParams & { id_coll: number }) {
  return instance.get<ListResponse<RawDelayType>>(URL.getDelayList, { params: queryParams });
}

export function getDegradationPenaltyList(queryParams: ListQueryParams & { id_coll: number }) {
  return instance.get<ListResponse<RawDegradationType>>(URL.getDegradationList, { params: queryParams });
}

type PostPenaltyBodyForm = {
  id_coll: number;
  id_pdl: number;
  id_user: number | string;
  id_t: number;
  t: string;
  moyen: number;
  coeff?: number;
};

export function postDelayOrDegradation(data: PostPenaltyBodyForm) {
  return instance.post(URL.postPenalty, data);
}

type PostRefundBodyForm = {
  id_resa: number;
  id_coll: number;
  amount: number;
  type: string;
  operation: string;
};

export function postRefund(data: PostRefundBodyForm) {
  return instance.post(URL.postRefund, data);
}

type GetSogecommerceConfigQueryParams = {
  id_coll: number;
};

export function getSogecommerceConfig(queryParams: GetSogecommerceConfigQueryParams) {
  return instance.get<{ booking: boolean }>(URL.getSogecommerceConfig, { params: queryParams });
}
