/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import React, { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { putStatus as putAccessoryStatus } from '../../../api/park/accessory.api';
import { putStatus, RawBike } from '../../../api/park/bike.api';
import { putStatus as putParkStatus } from '../../../api/park/park.api';
import { selectCollAndDeliveryPointID } from '../../../auth/selectors/authSelectors';
import IconButton from '../../../components/Core/Button/IconButton';
import { RowItem } from '../../../components/Core/Datagrid/Datagrid';
import SelectField from '../../../components/Core/SelectField';
import { bikeStatuses as bikeStat } from '../../../park/constants/bikes';

type StatusCellProps = {
  bike: RawBike;
  articleType?: 'bike' | 'park' | 'accessory';
  //eslint-disable-next-line
  filters?: any;
};

function StatusCell({ bike, articleType = 'bike', filters }: StatusCellProps) {
  const { t } = useTranslation('park/genericList');
  const { handleSubmit, control } = useForm();
  const queryClient = useQueryClient();
  const { id_coll, id_pdl } = useSelector(selectCollAndDeliveryPointID);
  const bikeStatuses = bikeStat(t);
  const putArticleStatus =
    articleType === 'bike' ? putStatus : articleType === 'park' ? putParkStatus : putAccessoryStatus;
  //eslint-disable-next-line
  const { mutate: handlePutArticleStatus } = useMutation(['bike.status'], (data: any) => putArticleStatus(data), {
    onSuccess: () => {
      queryClient.invalidateQueries([articleType.concat('.list')]);
    },
  });

  const defaultValue = bike.currentStatus.id_etat.toString();

  const options = useMemo(
    () => [
      ...bike.availableStatusTo.map(({ id_etat: value, value: label }) => ({
        label: bikeStatuses[label],
        value: value.toString(),
      })),
      {
        label: bikeStatuses[bike.currentStatus.value],
        value: defaultValue,
      },
    ],
    [bike.availableStatusTo]
  );

  const onSubmit = useCallback(
    handleSubmit((data) => {
      const queryParams = {};
      if (articleType === 'park') {
        Object.assign(queryParams, { id_empl: filters?.filterEmpl });
      } else {
        Object.assign(queryParams, {
          id_pdl: filters?.filterPdl || id_pdl,
        });
      }

      const idKey = articleType === 'bike' ? 'id_velo' : articleType === 'park' ? 'id_cons' : 'id_accessoire';

      handlePutArticleStatus({
        id_coll,
        // id_pdl: filters.filterPdl || id_pdl,
        ...queryParams,
        //eslint-disable-next-line
        [idKey]: (bike as any)[idKey === 'id_cons' ? 'id_consigne' : idKey],
        id_etat: data.id_etat,
        //eslint-disable-next-line
      } as any);
    }),
    [handleSubmit, id_pdl, filters]
  );

  return bike.availableStatusTo.length === 0 ? (
    <RowItem>{bikeStatuses[bike.currentStatus.value]}</RowItem>
  ) : (
    <Grid>
      <Controller
        name={'id_etat'}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, value }, formState }) => (
          <>
            <SelectField menuPosition={'fixed'} value={value} onChange={onChange} options={options} />
            <IconButton
              disabled={!formState.isDirty || value === defaultValue}
              icon={'RefreshIcon'}
              color={'action'}
              onClick={onSubmit}
            />
          </>
        )}
      />
    </Grid>
  );
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 8px;
  align-items: center;
`;

export default StatusCell;
