import React, { ComponentPropsWithoutRef, InputHTMLAttributes, useMemo } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
// import 'fragments/datepicker/DatePicker.css';
// import 'react-datepicker/dist/react-datepicker.css';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import useInput from '../InputBase/useInput';
import InputStyled from '../InputStyled/InputStyled';

registerLocale('fr', fr);
dayjs.extend(customParseFormat);

const FORMAT = 'YYYY/MM/DD';
const FORMAT_DISPLAYED = 'DD/MM/YYYY';

type DatePickerBaseProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  keyof ComponentPropsWithoutRef<typeof ReactDatePicker>
> &
  Omit<ComponentPropsWithoutRef<typeof ReactDatePicker>, 'onChange' | 'value'> & {
    onChange?: (newValue: string | null) => void;
    value?: string | null;
    dateFormat?: string;
  };

function DatePickerBase(props: DatePickerBaseProps) {
  const { placeholder, value, onChange: onChangeProp, dateFormat = FORMAT, ...propsToForward } = props;
  const inputProps = useInput<string | null>({ value, onChange: onChangeProp });

  const onChange = (date: Date) => {
    const newDate = dayjs(date);
    inputProps.onChange(newDate.format(FORMAT));
  };

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const newDate = dayjs(event.target.value, FORMAT_DISPLAYED);
    if (newDate.isValid()) {
      inputProps.onChange(newDate.format(FORMAT));
    } else {
      inputProps.onChange(null);
    }
  };

  const container = document.getElementById('root');

  const selected = useMemo(() => {
    const selectedDate = dayjs(inputProps.value ? inputProps.value : null, dateFormat);
    return selectedDate.isValid() ? selectedDate.toDate() : null;
  }, [inputProps.value]);

  return (
    <ReactDatePicker
      selected={selected}
      customInput={<InputStyled />}
      locale={'fr'}
      dateFormat={'dd/MM/yyyy'}
      autoComplete={'off'}
      showMonthDropdown
      showYearDropdown
      scrollableYearDropdown
      placeholderText={placeholder}
      dropdownMode={'scroll'}
      onChange={onChange}
      onBlur={onBlur}
      // onChangeRaw={test}
      popperPlacement={'bottom'}
      popperModifiers={[
        {
          name: 'preventOverflow',
          options: {
            boundary: container,
            rootBoundary: 'document',
          },
        },
      ]}
      {...propsToForward}
    />
  );
}

export default DatePickerBase;
