import {
  SAVE_COLLECTIVITY,
  SAVE_COLLECTIVITY_LIST,
  SAVE_DELIVERY_POINT,
  SAVE_DELIVERY_POINT_LIST,
  SAVE_USER,
  SAVE_USER_RIGHTS,
} from 'auth/actions/authActions';
import { AuthActionType } from 'auth/types/actions';
import { DeliveryPointType, UserCollectivityType } from 'auth/types/collectivity';
import { UserRightsType, UserType } from 'auth/types/user';

type State = {
  user: UserType | null;
  userRights: UserRightsType | null;
  collectivity: number;
  deliveryPoint: number;
  collectivityList: UserCollectivityType[];
  deliveryPointList: DeliveryPointType[];
};

const initialState: State = {
  user: null,
  userRights: null,
  collectivity: 0,
  deliveryPoint: 0,
  collectivityList: [],
  deliveryPointList: [],
};

export default (state: State = initialState, action: AuthActionType): State => {
  switch (action.type) {
    case SAVE_USER:
      const { user } = action.payload;

      return {
        ...state,
        user,
      };

    case SAVE_COLLECTIVITY:
      const { collectivity } = action.payload;

      return {
        ...state,
        collectivity,
      };

    case SAVE_DELIVERY_POINT:
      const { deliveryPoint } = action.payload;

      return {
        ...state,
        deliveryPoint,
      };

    case SAVE_USER_RIGHTS:
      const { userRights } = action.payload;

      return {
        ...state,
        userRights,
      };

    case SAVE_COLLECTIVITY_LIST:
      const { collectivityList } = action.payload;

      return {
        ...state,
        collectivityList,
      };

    case SAVE_DELIVERY_POINT_LIST:
      const { deliveryPointList } = action.payload;

      return {
        ...state,
        deliveryPointList,
      };

    default:
      return state;
  }
};
