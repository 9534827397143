/** @jsxImportSource @emotion/react */
import { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react/macro';
import styled from '@emotion/styled/macro';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { flatten } from 'lodash';
import React, { ComponentPropsWithoutRef, useEffect, useMemo } from 'react';
import { Controller, useForm, UseFormReturn, ValidationMode } from 'react-hook-form';
import DatePickerField from '../components/Core/DatePicker/DatePickerField';
import DateRangeField from '../components/Core/DateRangeField';
import DropzoneFileInput from '../components/Core/DropzoneFileInput/DropzoneFileInput';
import CheckboxField from '../components/Core/Form/CheckboxField/CheckboxField';
import PhoneField from '../components/Core/PhoneField/PhoneField';
import SelectBase from '../components/Core/SelectBase/SelectBase';
import SelectField from '../components/Core/SelectField';
import TextField from '../components/Core/TextField';
import useGetFormErrors from './useGetFormErrors';

type BaseFieldObject = FieldStylesParams & {
  name: string;
  id?: string;
  placeholder?: string;
  label: string;
  helper?: string;
  defaultValue?: string | number;
  css?: SerializedStyles;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (newValue: any, getValues?: UseFormReturn['getValues'], setValue?: UseFormReturn['setValue']) => void;
  schema?: Joi.AnySchema;
  required?: boolean;
  disabled?: boolean;
};

type TextFieldObject = BaseFieldObject & {
  type: 'text' | 'password' | 'number';
};

type TextAreaFieldObject = BaseFieldObject & {
  type: 'textarea';
};

type DateFieldObject = BaseFieldObject & {
  type: 'date';
};

type DateRangeFieldObject = BaseFieldObject & {
  type: 'daterange';
};

type PhoneFieldObject = BaseFieldObject & {
  type: 'phone';
};

type CheckboxFieldObject = BaseFieldObject & {
  type: 'checkbox';
};

type RadioFieldObject = BaseFieldObject & {
  type: 'radio';
};

type FileFieldObject = BaseFieldObject & {
  type: 'file';
  defaultValue?: string;
};

type CustomFieldObject = BaseFieldObject & {
  type: 'custom';
  component: JSX.Element;
};

export type Option = { value?: string | number | null; label: string };

type SelectFieldObject = BaseFieldObject &
  Partial<Omit<ComponentPropsWithoutRef<typeof SelectBase>, 'onChange'>> & {
    type: 'select';
    options: Option[];
    isMulti?: boolean;
  };

export type FieldObject =
  | SelectFieldObject
  | TextFieldObject
  | CustomFieldObject
  | PhoneFieldObject
  | DateFieldObject
  | CheckboxFieldObject
  | TextAreaFieldObject
  | RadioFieldObject
  | FileFieldObject
  | DateRangeFieldObject;

export type GroupFormObject = {
  colsNumber?: number;
  fields: FieldObject[];
};

export type FormObject = GroupFormObject[];

type UseBuildFormProps = {
  colsNumber?: number;
  formObject?: FormObject;
  withValidation?: boolean;
  // eslint-disable-next-line
  defaultValues?: { [x: string]: any };
};

function useBuildForm(props: UseBuildFormProps) {
  const errorMessages = useGetFormErrors();
  const { formObject, colsNumber, defaultValues, withValidation = true } = props;
  const formSchema = useMemo(
    () =>
      Joi.object({
        ...flatten(
          formObject
            ? formObject.map((groupForm) => {
                return groupForm.fields.map((field) => {
                  if (field.schema) {
                    return { [field.name]: field.schema };
                  } else {
                    // String default ? or base on type ?
                    if (field.type === 'checkbox') return { [field.name]: Joi.boolean().optional() };
                    return { [field.name]: Joi.string().optional().allow(null).allow('') };
                  }
                });
              })
            : []
        ).reduce<Record<string, Joi.AnySchema>>((previousValue, currentValue) => {
          return {
            ...previousValue,
            ...currentValue,
          };
        }, {} as Record<string, Joi.AnySchema>),
      }),
    [formObject]
  );

  const useFormProps = useMemo(() => {
    const formProps = {
      mode: 'onChange' as unknown as keyof ValidationMode,
      defaultValues,
    };

    if (withValidation)
      Object.assign(formProps, {
        resolver: joiResolver(formSchema.messages(errorMessages)),
      });

    return formProps;
  }, [withValidation, errorMessages, formSchema]);

  const { control, handleSubmit, reset, watch, setValue, getValues, unregister, register, formState } =
    useForm(useFormProps);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const builtFormFields = useMemo(() => {
    return formObject
      ? formObject.map((groupObject, index) => (
          <GroupFormRoot key={'group-object-' + index} colsNumber={(groupObject.colsNumber ?? colsNumber) || 1}>
            {groupObject.fields.map((fieldObject) => {
              const { onChange: onChangeField, css, required = false, ...propsToForward } = fieldObject;

              // const isRequired = (formSchema && formSchema[fieldObject.name]._flags?.presence === 'required') || false;

              switch (fieldObject.type) {
                case 'file':
                  return (
                    <Controller
                      key={fieldObject.name}
                      name={fieldObject.name}
                      control={control}
                      defaultValue={null}
                      render={({ field: { onChange, value } }) => (
                        <DropzoneFileInput
                          onChange={onChange}
                          defaultValue={value}
                          css={[css, fieldStyles({ columnStart: fieldObject.columnStart })]}
                          {...(propsToForward as Omit<typeof fieldObject, 'onChange'>)}
                        />
                      )}
                    />
                  );
                case 'checkbox':
                  return (
                    <Controller
                      key={fieldObject.name}
                      name={fieldObject.name}
                      control={control}
                      defaultValue={false}
                      render={({ fieldState: { error }, field: { onChange, value } }) => (
                        <CheckboxField
                          onChange={onChange}
                          error={error}
                          checked={value}
                          css={[css, fieldStyles({ columnStart: fieldObject.columnStart })]}
                          {...(propsToForward as Omit<typeof fieldObject, 'onChange'>)}
                        />
                      )}
                    />
                  );
                case 'phone':
                  return (
                    <Controller
                      key={fieldObject.name}
                      name={fieldObject.name}
                      control={control}
                      defaultValue={null}
                      render={({ fieldState: { error }, field: { onChange, value } }) => (
                        <PhoneField
                          error={error}
                          onChange={(newValue) => {
                            onChange(newValue);
                            onChangeField?.(newValue, getValues, setValue);
                          }}
                          value={value}
                          css={fieldStyles({ columnStart: fieldObject.columnStart })}
                          {...(propsToForward as Omit<typeof fieldObject, 'onChange'>)}
                        />
                      )}
                    />
                  );
                case 'custom':
                  return React.cloneElement(fieldObject.component, {
                    key: 'custom',
                    control,
                    setValue,
                    unregister,
                    getValues,
                    register,
                    reset,
                    name: fieldObject.name,
                    label: fieldObject.label,
                    ...fieldObject.component.props,
                  });
                case 'select':
                  return (
                    <Controller
                      key={fieldObject.name}
                      name={fieldObject.name}
                      control={control}
                      defaultValue={fieldObject.isMulti ? [] : fieldObject.defaultValue || null}
                      render={({ fieldState: { error }, field: { onChange, value } }) => (
                        <SelectField
                          error={error}
                          onChange={(newValue) => {
                            onChange(newValue);
                            onChangeField?.(newValue, getValues, setValue);
                          }}
                          value={
                            fieldObject.isMulti
                              ? fieldObject.options.filter((option) => value.includes(option.value)) || null
                              : fieldObject.options.find((option) => option.value === value) || null
                          }
                          css={fieldStyles({ columnStart: fieldObject.columnStart })}
                          {...(propsToForward as Omit<typeof fieldObject, 'onChange'>)}
                        />
                      )}
                    />
                  );
                case 'date':
                  return (
                    <Controller
                      key={fieldObject.name}
                      name={fieldObject.name}
                      control={control}
                      defaultValue={null}
                      render={({ fieldState: { error }, field: { onChange, value } }) => (
                        <DatePickerField
                          error={error}
                          onChange={(newValue) => {
                            onChange(newValue);
                            onChangeField?.(newValue, getValues, setValue);
                          }}
                          value={value}
                          css={fieldStyles({ columnStart: fieldObject.columnStart })}
                          {...(propsToForward as Omit<typeof fieldObject, 'onChange'>)}
                        />
                      )}
                    />
                  );
                case 'daterange':
                  return (
                    <Controller
                      key={fieldObject.name}
                      name={fieldObject.name}
                      control={control}
                      defaultValue={null}
                      render={({ fieldState: { error }, field: { onChange, value } }) => (
                        <DateRangeField
                          error={error}
                          onChange={(newValue) => {
                            onChange(newValue);
                            onChangeField?.(newValue, getValues, setValue);
                          }}
                          value={value}
                          css={fieldStyles({ columnStart: fieldObject.columnStart })}
                          {...(propsToForward as Omit<typeof fieldObject, 'onChange'>)}
                        />
                      )}
                    />
                  );
                case 'radio':
                  return (
                    <Controller
                      key={fieldObject.name}
                      name={fieldObject.name}
                      control={control}
                      defaultValue={null}
                      render={({ fieldState: { error }, field: { onChange, value } }) => (
                        <DateRangeField
                          error={error}
                          onChange={(newValue) => {
                            onChange(newValue);
                            onChangeField?.(newValue, getValues, setValue);
                          }}
                          value={value}
                          css={fieldStyles({ columnStart: fieldObject.columnStart })}
                          {...(propsToForward as Omit<typeof fieldObject, 'onChange'>)}
                        />
                      )}
                    />
                  );
                case 'text':
                case 'number':
                case 'password':
                case 'textarea':
                  return (
                    <Controller
                      key={fieldObject.name}
                      name={fieldObject.name}
                      control={control}
                      defaultValue={null}
                      render={({ fieldState: { error }, field: { value, onChange } }) => (
                        <TextField
                          key={fieldObject.name + '-text-field'}
                          error={error}
                          value={value}
                          required={required}
                          type={fieldObject.type === 'textarea' ? 'textarea' : 'text'}
                          onChange={(newValue) => {
                            onChange(newValue);
                            fieldObject?.onChange?.(newValue, getValues, setValue);
                          }}
                          css={fieldStyles({ columnStart: fieldObject.columnStart })}
                          {...(propsToForward as Omit<typeof fieldObject, 'onChange' | 'type'>)}
                        />
                      )}
                    />
                  );
              }
            })}
          </GroupFormRoot>
        ))
      : [];
  }, [formObject, control, formSchema, setValue, unregister]);

  return {
    watch,
    getValues,
    reset,
    setValue,
    handleSubmit,
    formState,
    builtFormFields,
  };
}

type FieldStylesParams = {
  /**
   * Use for form grid
   * @example
   * 3 '(for place field in third position)'
   * 2 / span 2
   */
  columnStart?: number | string;
  rowStart?: number;
};

const fieldStyles = ({ columnStart, rowStart }: FieldStylesParams) => css`
  grid-column: ${columnStart};
  grid-row-start: ${rowStart};
`;

const GroupFormRoot = styled.div<{ colsNumber: number }>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.colsNumber}, 1fr);
  gap: 16px 8px;
`;

export default useBuildForm;
