import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { upperFirst } from 'lodash';
import MainSection from '../../../../components/Core/MainSection/MainSection';
import Card from '../../../../components/Core/Card/Card';
import useGenerateFields, { Field } from '../../../../hook/useGenerateFields';
import Button from '../../../../components/Core/Button/Button';
import { instance } from '../../../../api/user.api';
import { selectCollAndDeliveryPointID } from '../../../../auth/selectors/authSelectors';
import { useSnackbar } from '../../../../context/SnackbarContext';
import { TOAST_SEVERITY } from '../../../../components/Core/Toast/Toast';
import styles from './Tulip.module.scss';

function useCustomizationRawFields() {
  const { t } = useTranslation(['insurance/tulip', 'common']);

  return useMemo<Field[]>(() => {
    return ['fr', 'en']
      .map<Field[]>((lang) => {
        return [
          {
            type: 'text',
            label: `${t(`common:${lang}`)} ${t('customizationPage.titleField')}${t(`customizationPage.${lang}`)}`,
            name: 'title'.concat(upperFirst(lang)),
          },
          {
            type: 'text',
            label: `${t(`common:${lang}`)} ${t('customizationPage.textField')}${t(`customizationPage.${lang}`)}`,
            name: 'description'.concat(upperFirst(lang)),
          },
        ];
      })
      .flat();
  }, [t]);
}

type PostInsuranceParamsBodyForm = {
  idColl: number;
  titleFr: string;
  descriptionFr: string;
  titleEn: string;
  descriptionEn: string;
};

function TulipCustomizationPage() {
  const snackbar = useSnackbar();
  //eslint-disable-next-line
  const tulipParamsResponse: AxiosResponse<any> = useOutletContext();
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const { t } = useTranslation(['insurance/tulip', 'form']);
  const fields = useCustomizationRawFields();
  const queryClient = useQueryClient();
  const defaultValues = useMemo(
    () => ({
      titleFr: tulipParamsResponse.data.titleFr,
      descriptionFr: tulipParamsResponse.data.descriptionFr,
      titleEn: tulipParamsResponse.data.titleEn,
      descriptionEn: tulipParamsResponse.data.descriptionEn,
    }),
    [tulipParamsResponse]
  );
  const { control, handleSubmit } = useForm({ defaultValues });
  const form = useGenerateFields({ control, fields });

  const { mutate: postInsuranceParams } = useMutation(
    ['insurance/tuliptext'],
    (bodyForm: PostInsuranceParamsBodyForm) => {
      return instance.post('insurance/tuliptext', bodyForm);
    },
    {
      onSuccess: () => {
        snackbar?.setAlert({ message: t('form:global_update_success_message'), severity: TOAST_SEVERITY.success });
        queryClient.invalidateQueries(['insurance/tulip']);
      },
    }
  );

  const onSubmit = useCallback(
    handleSubmit((data) => {
      postInsuranceParams({
        ...data,
        idColl: id_coll,
        tulipId: tulipParamsResponse?.data.tulipId,
      } as PostInsuranceParamsBodyForm);
    }),
    [id_coll, tulipParamsResponse]
  );

  return (
    <MainSection className={'reset'}>
      <Card
        title={t('customizationPage.title')}
        content={
          <form onSubmit={onSubmit}>
            <div className={styles.CustomizationForm}>{form}</div>
            <div className={'row justify-end mt-4'}>
              <Button type={'submit'}>{t('form:update')}</Button>
            </div>
          </form>
        }
      />
    </MainSection>
  );
}

export default TulipCustomizationPage;
