import styled from '@emotion/styled';
import React, { ComponentType, HTMLAttributes, PropsWithChildren } from 'react';
import { getColor } from '../../utils/style';
import Card from '../Core/Card/Card';

type FormGroupCardProps = Omit<HTMLAttributes<HTMLDivElement>, 'title'> & {
  title?: string | null;
  description?: string;
  FooterSlotComponent?: React.ComponentType<{ defaultWrapper: ComponentType<PropsWithChildren> }>;
  //eslint-disable-next-line
  WrapperComponent?: React.ComponentType<PropsWithChildren>;
  contentClassName?: string;
};

function FormGroupCard(props: PropsWithChildren<FormGroupCardProps>) {
  const {
    title,
    children,
    className,
    contentClassName,
    FooterSlotComponent,
    WrapperComponent = ({ children }) => <>{children}</>,
  } = props;

  /*{title !== null && (
        <CardHeader>
          <TextSkeleton as={'h5'} text={title} />
          {description && <span>{description}</span>}
        </CardHeader>
      )}*/

  return (
    <Card
      title={title || undefined}
      className={className}
      content={
        <>
          <WrapperComponent>
            <div className={contentClassName}>{children}</div>
            {FooterSlotComponent && <FooterSlotComponent defaultWrapper={DefaultFooterWrapper} />}
          </WrapperComponent>
        </>
      }
    />
  );
}

const DefaultFooterWrapper = styled.div`
  // border-top: 1px solid ${getColor('gray.200')};
  padding-top: 16px;
  display: flex;
  justify-content: flex-end;
`;

export default FormGroupCard;
