import React, { ComponentPropsWithoutRef } from 'react';
import { FieldError } from 'react-hook-form';
import FormControl from '../FormControl/FormControl';
import Label from '../Label/Label';
import DatePickerBase from './DatePickerBase';

type DatePickerFieldProps = Omit<ComponentPropsWithoutRef<typeof FormControl>, 'error'> &
  ComponentPropsWithoutRef<typeof DatePickerBase> & {
    error?: FieldError;
    label?: string;
  };

function DatePickerField(props: DatePickerFieldProps) {
  const { label, required, error, onChange, value, ...propsToForward } = props;

  return (
    <FormControl onChange={onChange} value={value} error={!!error}>
      <div>
        {label && <Label required={required}>{label}</Label>}
        <DatePickerBase {...propsToForward} />
      </div>
    </FormControl>
  );
}

export default DatePickerField;
