import { instance } from '../user.api';

const URL = {
  getList: 'param/cautions',
  putOne: 'param/cautions',
  getAllPriceTypes: 'param/tarifsloc',
};

type GetCautionListQueryParams = {
  id_coll: number;
};

export type GetDepositListData = Record<
  string,
  {
    articles: number[];
    cautionId: number;
    cautionName: string;
    montant_ht: string;
    montant_ttc: string;
    taux_tva: number;
  }[]
>;

export function getCautionList(queryParams: GetCautionListQueryParams) {
  return instance.get<GetDepositListData>(URL.getList, { params: queryParams });
}

export type PutCautionBodyForm = {
  id_coll: number;
  cautionId: number;
  caution: {
    article: string;
    cautionName: string;
    montant_ht: number;
    taux_tva: number;
  };
  articles: number[];
};

export function putCaution(bodyForm: PutCautionBodyForm) {
  return instance.post(URL.putOne, bodyForm);
}

export function postCaution(bodyForm: Omit<PutCautionBodyForm, 'cautionId'>) {
  return instance.post(URL.putOne, bodyForm);
}

type GetPriceListQueryParams = {
  id_coll: number;
};

export type GetPriceListData = {
  id_article: number;
  article: string;
  nom_tarif: string;
  montant_ttc: number;
  min_duree: number;
  max_duree: number;
  type_duree: string;
  nom_duree: string;
  nom_type: string;
}[];

export function getPriceList(queryParams: GetPriceListQueryParams) {
  return instance.get<GetPriceListData>(URL.getAllPriceTypes, { params: queryParams });
}
