import React, { Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { NavLink, Outlet, useParams } from 'react-router-dom';
import { getUserById } from '../../api/user.api';
import { selectCollAndDeliveryPointID } from '../../auth/selectors/authSelectors';
import Badge from '../../components/Core/Badge/Badge';
import { Tab, Tabs, TabsList } from '../../components/Core/Tabs/Tabs';
import { useMainTitle } from '../../components/Layout/MainTitleContext';
import { BookingIcon, ContractIcon, InfoIcon, InvoiceIcon, ReceiptIcon } from '../../const/icons';
import { generatePath } from '../../utils/router';
import bookingStyles from '../Reservation/bookingDetails.module.scss';
import { TabComponent } from '../Reservation/Reservation.layout';
import styles from './user.module.scss';

function UserLayout() {
  const { t } = useTranslation('user/userLayout');
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const params = useParams();

  const { data: userData, isLoading } = useQuery(
    [`user.fetch.${params.userId}`, { userId: params.userId, id_coll }],
    ({ queryKey }) => {
      //eslint-disable-next-line
      const [, { userId: id_user, id_coll }] = queryKey as [string, { userId: any; id_coll: any }];
      return getUserById({ id_user, id_coll });
    },
    { refetchOnWindowFocus: false, staleTime: 260_000 }
  );

  useMainTitle(
    useMemo(() => {
      return !isLoading ? (
        <div className={bookingStyles.title}>
          {userData?.data.nom} {userData?.data.prenom}
        </div>
      ) : null;
    }, [userData, isLoading])
  );

  return (
    <div className={'reset'}>
      <div className={styles.Layout} id={'user-layout'}>
        <Tabs className={styles.Layout__tabs}>
          <TabsList>
            <Tab
              component={({ children, ...otherProps }: TabComponent) => (
                <NavLink {...otherProps} to={generatePath('home.user.userDetails', { params })} end>
                  {children}
                </NavLink>
              )}>
              <InfoIcon width={16} /> {t('infos')}
            </Tab>
            <Tab
              component={({ children, ...otherProps }: TabComponent) => (
                <NavLink {...otherProps} to={generatePath('home.user.userDetails.checkout', { params })} end>
                  {children}
                </NavLink>
              )}>
              <ReceiptIcon width={16} /> {t('checkout')}
            </Tab>
            <Tab
              component={({ children, ...otherProps }: TabComponent) => (
                <NavLink {...otherProps} to={generatePath('home.user.userDetails.invoices', { params })} end>
                  {children}
                </NavLink>
              )}>
              <InvoiceIcon width={16} /> {t('invoices')}
            </Tab>
            <Tab
              component={({ children, ...otherProps }: TabComponent) => (
                <NavLink {...otherProps} to={generatePath('home.user.userDetails.contractList', { params })} end>
                  {children}
                </NavLink>
              )}>
              <ContractIcon width={16} /> {t('contracts')}
            </Tab>
            <Tab
              component={({ children, ...otherProps }: TabComponent) => (
                <NavLink {...otherProps} to={generatePath('home.user.userDetails.booking', { params })} end>
                  {children}
                </NavLink>
              )}>
              <Badge badgeContent={userData?.data.nb_attente} translate={'8px, -25%'}>
                <BookingIcon width={16} /> {t('bookings')}
              </Badge>
            </Tab>
          </TabsList>
        </Tabs>
      </div>

      <Suspense fallback={<></>}>
        <Outlet context={{ userData }} />
      </Suspense>
    </div>
  );
}

export default UserLayout;
