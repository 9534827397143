import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Input from 'fragments/input/Input';
import Checkbox from 'fragments/checkbox/Checkbox';
import Button from 'fragments/button/Button';
import useApi, { Methods } from 'internal/hooks/useApi';
import { selectCollAndDeliveryPointID } from 'auth/selectors/authSelectors';

type Props = {
  onSuccess: () => void;
  onRefresh: () => void;
};

const ParametersEmplCreator = ({ onSuccess, onRefresh }: Props): ReactElement => {
  const { t } = useTranslation(['old']);
  const [nom_empl, setEmpl] = useState('');
  const [active, setActive] = useState(false);

  const { id_coll } = useSelector(selectCollAndDeliveryPointID);

  const { request: createEmpl, response: emplRes } = useApi(Methods.POST, 'param/empl');

  const submitEmpl = useCallback(() => {
    createEmpl({
      nom_empl,
      id_coll,
      visible_empl: active,
    });
    setEmpl('');
    setActive(false);
  }, [nom_empl, id_coll, active]);

  useEffect(() => {
    if (emplRes?.success) {
      onSuccess();
      onRefresh();
    }
  }, [emplRes, id_coll, onSuccess]);

  return (
    <div className={'row'}>
      <div className={'col-md-6'}>
        <Input
          type={'text'}
          className={'form-control'}
          id={'add_nomempl'}
          placeholder={t('parameters.empl_name')}
          value={nom_empl}
          handleChange={setEmpl}
          controlled
        />
      </div>
      <div className={'col-md-1'}>
        <Checkbox checked={active} handleChange={setActive} />
      </div>
      <div className={'col-md-5'}>
        <Button onClick={submitEmpl} label={t('global.add')} className={'btn btn-w-xl btn-primary'} />
      </div>
    </div>
  );
};

export default ParametersEmplCreator;
