/** @jsxImportSource @emotion/react */
import React, { InputHTMLAttributes } from 'react';
import { css, Theme } from '@emotion/react';
import { getColor } from '../../../utils/style';

const radioButtonCss = {
  self: css({
    fontSize: '12px',
    fontWeight: 'bold',
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: '8px',
    letterSpacing: 'normal',
    alignItems: 'center',
    justifyContent: 'flex-start',
  }),
  input: (theme: Theme) =>
    css({
      appearance: 'none',
      backgroundColor: 'white',
      margin: 0,
      color: 'currentcolor',
      width: 16,
      height: 16,
      border: `1px solid ${getColor('gray.300')({ theme })}`,
      borderRadius: '50%',
      display: 'grid',
      placeContent: 'center',
      '&:hover': {
        borderColor: getColor('gray.400')({ theme }),
      },
      '::before': {
        content: '""',
        width: 8,
        height: 8,
        borderRadius: '50%',
        transform: 'scale(0)',
        transition: '120ms transform ease-in-out',
        boxShadow: 'inset 1em 1em var(--form-control-color)',
        backgroundColor: 'CanvasText',
      },
      ':checked:before': {
        transform: 'scale(1)',
      },
    }),
};

function RadioButton(
  props: InputHTMLAttributes<HTMLInputElement> & {
    label: string;
  }
) {
  return (
    <label css={radioButtonCss.self}>
      <input css={(theme) => radioButtonCss.input(theme)} type={'radio'} {...props} />
      {props.label}
    </label>
  );
}

export default RadioButton;
