import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { selectCollAndDeliveryPointID, selectSessionToken } from 'auth/selectors/authSelectors';
import cx from 'classnames';
import Button from 'fragments/button/Button';
import DataTable from 'fragments/dataTable/DataTable';
import Input from 'fragments/input/Input';
import Select from 'fragments/select/Select';
import { setSnackbar, showSnackbar } from 'internal/actions/appActions';
import SnackbarSeverity from 'internal/constants/snackbar';
import Status from 'internal/constants/status';
import useApi, { Methods } from 'internal/hooks/useApi';
import { t } from 'internal/i18n';
import { BASE64_HEAD_REGEXP } from 'internal/utils/file';
import { debounce } from 'lodash';
import { MIN_WIDTH_ARTICLE_IMAGE_TYPE } from 'parameters/constants/image';
import React, { ChangeEvent, MouseEvent, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GET_CHECKOUT_ENDPOINT, POST_ATTACHMENTS_ENDPOINT } from 'reservations/constants/endpoints';
import fileTypes from 'reservations/constants/files';
import { CheckinRawType } from 'reservations/types/checkin';
import { RawAttachmentType } from 'reservations/types/reservation';
import { getTradFromStatus } from 'reservations/utils/checkin';
import GlobalCommentaries from './checkin/GlobalCommentaries';

type Props = {
  open: boolean;
  onClose: () => void;
  onNextAction: (() => void) | undefined;
  verifs: CheckinRawType[];
  userID: string;
  resaID: string;
  refetch: () => void;
};

const CheckoutDialog = ({
  //prettier-ignore
  open,
  onClose,
  verifs,
  userID,
  resaID,
  refetch,
  onNextAction,
}: Props): ReactElement => {
  const fileUploadClasses = (required: boolean) =>
    cx('form-group col-md-12', {
      ['alert-text']: required,
    });

  const labelClasses = (required: boolean) =>
    cx('col-md-12', {
      ['alert-text']: required,
    });

  const [formState, setFormState] = useState<CheckinRawType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState<RawAttachmentType[]>([]);
  const [indexFileSend, setIndexFileSend] = useState<number>(0);
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const token = useSelector(selectSessionToken);
  const dispatch = useDispatch();

  const { request: postAttachments, response: attachmentResponse } = useApi<RawAttachmentType>(
    Methods.POST,
    POST_ATTACHMENTS_ENDPOINT
  );
  const {
    request: submitCheckouts,
    response: checkoutsResponse,
    status: statusCheckout,
  } = useApi(Methods.POST, GET_CHECKOUT_ENDPOINT);

  const fetch = useCallback(() => {
    setFormState(verifs);
  }, [verifs]);

  useEffect(() => {
    const formFiles = formState.filter((verif) => !!verif.mediaUrl);

    if (indexFileSend > 0 && indexFileSend < formFiles.length) {
      const fileSend = formFiles[indexFileSend];
      const pjName = `checkout_${fileSend.id_form}`;

      debounce(() => {
        postAttachments({
          pjName,
          id_coll,
          id_user: userID,
          file: fileSend.mediaUrl?.replace(BASE64_HEAD_REGEXP, ''),
        });
      }, 1000)();
    }
  }, [indexFileSend, formState, postAttachments, id_coll, userID]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  useEffect(() => {
    if (attachmentResponse?.success) {
      setFiles((prevState) => [...prevState, attachmentResponse.data]);
      setIndexFileSend((prevState) => prevState + 1);
    }
  }, [attachmentResponse]);

  const handleClose = useCallback(() => {
    onClose();
    setFormState(verifs);
  }, [verifs, onClose]);

  useEffect(() => {
    if (checkoutsResponse?.success) {
      if (onNextAction) {
        onNextAction();
      }

      onClose();
      setFormState([]);
      refetch();
      setIsLoading(false);
    }
  }, [checkoutsResponse]);

  const form = useMemo(
    () =>
      formState.map((verif) => {
        const { commentary, status, need_status } = verif;
        const id_media = files.find((file) => file.type === `checkout_${verif.id_form}`)?.id_media || '';

        let actualStatus;

        // Its not a genetal commentary. We need status
        if (need_status) {
          actualStatus = status ?? 'bon_etat';
        }

        return {
          ...verif,
          commentaire: commentary ?? '',
          mediaUrl: '',
          id_media,
          status: actualStatus,
        };
      }) || [],
    [formState, files]
  );

  useEffect(() => {
    if (statusCheckout === Status.FAILURE) {
      setIsLoading(false);
    }
  }, [statusCheckout]);

  useEffect(() => {
    if (
      files.length === formState.filter((verif) => !!verif.mediaUrl).length &&
      attachmentResponse?.success &&
      indexFileSend === files.length
    ) {
      submitCheckouts({
        id_coll,
        id_resa: resaID,
        form,
      });
    }
  }, [files, attachmentResponse, formState, id_coll, resaID, form]);

  const allFilesUploaded = useMemo(() => {
    return formState.every((item) => !!item.mediaUrl || item.is_media_mandatory === 0);
  }, [formState]);

  const allCommented = useMemo(() => {
    return formState.every((item) => !!item.commentary || item.is_mandatory === 0 || item.need_status === 0);
  }, [formState]);

  const handleValidCheck = useCallback(() => {
    setIsLoading(true);

    if (formState.some((verif) => !!verif.mediaUrl)) {
      const firstFileSend = formState.filter((verif) => !!verif.mediaUrl)[0];
      const pjName = `checkout_${firstFileSend.id_form}`;

      postAttachments({
        pjName,
        id_coll,
        id_user: userID,
        file: firstFileSend.mediaUrl?.replace(BASE64_HEAD_REGEXP, ''),
      });
    } else {
      submitCheckouts({
        id_coll,
        id_resa: resaID,
        form,
      });
    }
  }, [formState, id_coll, userID, form, postAttachments, resaID]);

  const handleChangeFormState = useCallback(
    (formId: number, key: string) => (value: boolean | string) => {
      setFormState((prevState) => {
        const newFormState = [...prevState];
        const index = newFormState.findIndex((item) => item.id_form === formId);

        if (index !== -1) {
          newFormState.splice(index, 1, {
            ...prevState[index],
            [key]: value,
          });
        }

        return [...newFormState];
      });
    },
    [formState]
  );

  const findVerif = useCallback(
    (formId: number) => {
      return formState.find((verif) => verif.id_form === formId);
    },
    [formState]
  );

  const onUploadImageArticle = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (formId: number) => (e: ChangeEvent<HTMLInputElement>) => {
      const filesArray = e.target.files;
      if (filesArray) {
        const file = filesArray[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.addEventListener(
          'load',
          () => {
            const img = new Image();
            img.src = URL.createObjectURL(file);
            img.onload = () => {
              if (img.width < MIN_WIDTH_ARTICLE_IMAGE_TYPE) {
                dispatch(setSnackbar(t('error.minWidthError'), SnackbarSeverity.ERROR));
                dispatch(showSnackbar());

                if (e.target) {
                  e.target.value = '';
                }

                return;
              }
              handleChangeFormState(formId, 'mediaUrl')(reader.result as string);
            };
          },
          false
        );
      }
    },
    []
  );

  const onChoseUploadImage = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (formId: number) => (e: MouseEvent<HTMLDivElement>) => {
      const elem = document.getElementById(`mediaUrl-${formId}`) as HTMLInputElement;
      if (elem) {
        elem.click();
      }
    },
    []
  );

  const onRemoveImage = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (formId: number) => (e: MouseEvent<HTMLSpanElement>) => {
      const elem = document.getElementById(`mediaUrl-${formId}`) as HTMLInputElement;
      if (elem) {
        elem.value = '';
      }
      handleChangeFormState(formId, 'mediaUrl')('');
    },
    []
  );

  const statuses = useMemo(() => {
    return [
      { label: t('reservations.checkin.good_condition'), value: 'bon_etat' },
      { label: t('reservations.checkin.used'), value: 'used' },
      { label: t('reservations.checkin.defective'), value: 'defectueux' },
    ];
  }, []);

  // prettier-ignore
  const items = useMemo(() => {
    if (formState) {
      return (
        formState
          .filter(({ need_status }) => need_status === 1)
          .map(({
            id_form, label, is_mandatory, has_media, status, commentary, is_media_mandatory, entry,
          }) => ({
            label: <p className={labelClasses(!!is_mandatory)}>{label}</p>,
            media: entry?.media_url && (
              <div className={'form-row'}>
                <div className={fileUploadClasses(!!is_media_mandatory)}>
                  <a href={`${entry?.media_url}&token=${token}`} target={'_blank'}>
                    <img className={'cursor-pointer'} src={`${entry?.media_url}&token=${token}`} />
                  </a>
                </div>
              </div>
            ),
            status: <p className={'col-md-12'}>{getTradFromStatus(entry?.status)}</p>,
            commentary: <p className={'col-md-12'}>{entry?.commentaire}</p>,
            media_checkout: has_media > 0 && (
              <div className={'form-row'}>
                <div className={fileUploadClasses(!!is_media_mandatory)}>
                  <img
                    className={'cursor-pointer'}
                    src={findVerif(id_form)?.mediaUrl || undefined}
                    onClick={findVerif(id_form)?.mediaUrl ? onChoseUploadImage(id_form) : undefined}
                    style={{ display: !findVerif(id_form)?.mediaUrl ? 'none' : undefined }}
                  />
                  <input
                    type={'file'}
                    className={'fill-available'}
                    id={`mediaUrl-${id_form}`}
                    onChange={onUploadImageArticle(id_form)}
                    accept={`.jpg,.jpeg,.png,${fileTypes.JPEG},${fileTypes.PNG}`}
                    style={{ display: findVerif(id_form)?.mediaUrl ? 'none' : undefined }}
                    required={!!is_media_mandatory}
                  />
                  {findVerif(id_form)?.mediaUrl && (
                    <span className={'text-danger cursor-pointer'} onClick={onRemoveImage(id_form)}>
                      {t('parameters.articles_type.remove_image')}
                    </span>
                  )}
                </div>
              </div>
            ),
            status_checkout: (
              <Select
                id={'available-status'}
                name={'available-status'}
                handleChange={handleChangeFormState(id_form, 'status')}
                options={statuses}
                value={status}
                noWrapper
              />
            ),
            commentary_checkout: (
              <Input value={commentary} handleChange={handleChangeFormState(id_form, 'commentary')} controlled />
            ),
          }))
      );
    }
    return [];
  }, [formState, token]);

  return (
    <Dialog open={open} onClose={handleClose} fullScreen fullWidth maxWidth={'lg'}>
      <DialogTitle>
        <p className={'card-title'}>{t('reservations.checkin.title_checkout')}</p>
      </DialogTitle>
      <DialogContent className={'m-16 bg-color-app'}>
        <div className={'row'}>
          <div className={'dataTables_wrapper container-fluid dt-bootstrap4 no-footer'}>
            <DataTable
              cols={[
                'label',
                'media',
                'status',
                'commentary',
                'media_checkout',
                'status_checkout',
                'commentary_checkout',
              ]}
              colTraductionScope={'reservations.checkin'}
              colHandlers={[]}
              items={items}
            />
          </div>
        </div>
        <GlobalCommentaries
          formState={formState}
          fileUploadClasses={fileUploadClasses}
          findVerif={findVerif}
          onUploadImageArticle={onUploadImageArticle}
          onChoseUploadImage={onChoseUploadImage}
          handleChangeFormState={handleChangeFormState}
          onRemoveImage={onRemoveImage}
        />
      </DialogContent>
      <DialogActions className={'modal-confirm-actions'}>
        <Button
          loading={
            statusCheckout === Status.LOADING ||
            (indexFileSend > 0 && indexFileSend < formState.filter((verif) => !!verif.mediaUrl).length) ||
            isLoading
          }
          label={t('reservations.checkin.button_yes')}
          className={'btn-primary btn-fit btn-margin'}
          onClick={handleValidCheck}
          disabled={
            !(allFilesUploaded && allCommented) ||
            statusCheckout === Status.LOADING ||
            statusCheckout === Status.SUCCESS ||
            isLoading
          }
        />
        <Button label={t('reservations.checkin.button_no')} className={'btn-secondary btn-fit'} onClick={handleClose} />
      </DialogActions>
    </Dialog>
  );
};

export default CheckoutDialog;
