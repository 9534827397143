import { saveCollectivity, saveDeliveryPoint, saveUser } from 'auth/actions/authActions';
import { selectSessionToken } from 'auth/selectors/authSelectors';
import axios from 'axios';
import { setSnackbar, showSnackbar } from 'internal/actions/appActions';
import SnackbarSeverity from 'internal/constants/snackbar';
import Status from 'internal/constants/status';

import api, { RequestType } from 'internal/infrastructure/api';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

export enum Methods {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  PATCH = 'patch',
  DELETE = 'del',
}

export type Response<T> = {
  success: boolean;
  data: T;
};

type Query = {
  method: Methods;
  endpoint: string;
};

type UseApiOutput<T, R> = {
  request: (options?: RequestType, body?: R) => void;
  response: Response<T> | null;
  status: Status;
};

type UseApiOptions = {
  noSnack: boolean;
};

const useApi = <T, R = undefined>(method: Methods, endpoint: string, config?: UseApiOptions): UseApiOutput<T, R> => {
  const { t } = useTranslation('errors');
  const dispatch = useDispatch();

  const token = useSelector(selectSessionToken);

  const query = useRef<Query>({ method, endpoint });

  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const [response, setResponse] = useState<Response<T> | null>(null);

  useEffect(() => {
    if (!token) {
      api.removeAuthToken();
    } else {
      api.setAuthToken(token);
    }
  }, [token]);

  useEffect(() => {
    query.current = { method, endpoint };
  }, [method, endpoint]);

  const request = useCallback(
    async (options: RequestType = {}, body?: R) => {
      let cancelled = false;
      try {
        setStatus(Status.LOADING);
        const { method, endpoint } = query.current;

        const data = await api[method](options.url || endpoint, options, body);

        setStatus(Status.SUCCESS);
        setResponse({ success: true, data });
      } catch (e) {
        if (!cancelled && axios.isAxiosError(e)) {
          setStatus(Status.FAILURE);
          setResponse({ success: false, data: e?.response?.data?.exception });

          if (!config?.noSnack) {
            dispatch(
              setSnackbar(t(e?.response?.data?.code.replace('error.', '') || 'error.default'), SnackbarSeverity.ERROR)
            );
            dispatch(showSnackbar());
          }

          if (e?.response?.data?.code === 'error.invalidOrExpiredToken') {
            dispatch(saveUser(null));
            dispatch(saveCollectivity(0));
            dispatch(saveDeliveryPoint(0));
          }
        }
      }

      return () => {
        cancelled = true;
      };
    },
    [query]
  );

  return {
    request,
    response,
    status,
  };
};

export default useApi;
