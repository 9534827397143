import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import useControlled from '../../../hook/useControlled';

type SwitchBaseProps = {
  className?: string;
  checked?: boolean;
  defaultChecked?: boolean;
  disabled?: boolean;
  onChange?: React.ChangeEventHandler;
  icon: React.ReactNode;
  checkedIcon: React.ReactNode;
  id?: string;
  name?: string;
  value?: unknown;
  type?: string;
};

function SwitchBase(props: SwitchBaseProps) {
  const {
    checkedIcon,
    type,
    disabled,
    id,
    icon,
    value,
    checked: checkedProp,
    onChange,
    defaultChecked,
    name,
    className,
  } = props;

  const [checked, setCheckedState] = useControlled({
    controlled: checkedProp,
    default: defaultChecked,
    state: 'checked',
    name: 'SwitchBase',
  });

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.nativeEvent.defaultPrevented) {
        return;
      }

      const newChecked = event.target.checked;

      setCheckedState(newChecked);

      onChange?.(event);
    },
    [onChange, setCheckedState]
  );

  const hasLabelFor = type === 'checkbox' || type === 'radio';

  return (
    <SwitchBaseRoot className={className}>
      <Input
        id={hasLabelFor ? id : undefined}
        checked={checkedProp}
        defaultChecked={defaultChecked}
        disabled={disabled}
        type={type}
        name={name}
        onChange={handleInputChange}
        {...(type === 'checkbox' && value === undefined
          ? {}
          : { value: value as string | number | readonly string[] | undefined })}
      />
      {checked ? checkedIcon : icon}
    </SwitchBaseRoot>
  );
}

const SwitchBaseRoot = styled.span`
  display: inline-flex;
  position: relative;
  cursor: pointer;
  user-select: none;
`;

const Input = styled.input`
  margin: 0;
  position: absolute;
  opacity: 0;
  z-index: 10;
  left: 0;
  top: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
`;

export default SwitchBase;
