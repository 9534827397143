import {
  CHANGE_LOCALE,
  HIDE_SNACKBAR,
  SET_LOCALES,
  SET_SNACKBAR_CONTENT,
  SHOW_SNACKBAR,
  SET_CURRENCY,
} from 'internal/actions/appActions';
import Devises from 'internal/constants/devises';
import SnackbarSeverity from 'internal/constants/snackbar';
import { AppActionType } from 'internal/types/actions';

type AppState = {
  token: string | null;
  snackbar: {
    content: string;
    severity: SnackbarSeverity;
  };
  snackbarOpened: boolean;
  locale: string;
  locales: string[];
  currency: Devises;
};

const initialState = {
  token: '',
  snackbar: {
    content: '',
    severity: SnackbarSeverity.INFO,
  },
  snackbarOpened: false,
  locale: navigator.language.split('-')[0],
  locales: [],
  currency: Devises.EUR,
};

const appReducer = (state: AppState = initialState, action: AppActionType): AppState => {
  switch (action.type) {
    case SHOW_SNACKBAR:
      return {
        ...state,
        snackbarOpened: true,
      };

    case HIDE_SNACKBAR:
      return {
        ...state,
        snackbarOpened: false,
      };

    case SET_SNACKBAR_CONTENT:
      const { content, severity } = action.payload;

      return {
        ...state,
        snackbar: {
          content,
          severity,
        },
      };

    case CHANGE_LOCALE:
      const { locale } = action.payload;

      return {
        ...state,
        locale,
      };

    case SET_LOCALES:
      const { locales } = action.payload;

      return {
        ...state,
        locales,
      };

    case SET_CURRENCY:
      const { currency } = action.payload;

      return {
        ...state,
        currency,
      };

    default:
      return state;
  }
};

export default appReducer;
