import React, { LabelHTMLAttributes, PropsWithChildren, useMemo } from 'react';
import styles from './label.module.scss';

type LabelProps = PropsWithChildren<LabelHTMLAttributes<HTMLLabelElement>> & {
  required?: boolean;
  disabled?: boolean;
  secondary?: boolean;
  bigMargin?: boolean;
  noMargin?: boolean;
};

function Label({ children, className, required, noMargin, disabled = false, secondary, bigMargin }: LabelProps) {
  const extraProps = useMemo(() => (disabled ? { 'aria-disabled': true } : {}), [disabled]);

  return (
    <label
      className={[
        styles.Label,
        secondary ? styles.Secondary : null,
        bigMargin ? styles.Big : null,
        noMargin ? styles.NoMargin : null,
        className,
      ].join(' ')}
      {...extraProps}>
      {children}
      {required ? '*' : null}
    </label>
  );
}

export default Label;
