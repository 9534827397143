import React from 'react';
import { generatePath, NavLink, Outlet } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Tab, Tabs, TabsList } from '../../../components/Core/Tabs/Tabs';
import { TabComponent } from '../../Reservation/Reservation.layout';
import { selectCollAndDeliveryPointID } from '../../../auth/selectors/authSelectors';
import { instance } from '../../../api/user.api';

export const BROADCAST_LIST = {
  bike: {
    ids: ['relance_email_velo', 'relance_sms_velo'],
  },
  park: {
    ids: ['relance_email_cons', 'relance_sms_cons'],
  },
  freePark: {
    ids: ['relance_email_cons_park', 'relance_sms_cons_park'],
  },
  investigation: {
    ids: ['enquete_email'],
  },
};

function BroadcastingLayout() {
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const { t } = useTranslation(['old']);

  const { data: broadcastResponse } = useQuery(
    ['param/diff', { id_coll }],
    ({ queryKey }) => {
      const [url, queryParams] = queryKey as [string, { id_coll: number }];
      return instance.get(url, { params: queryParams });
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 400_000,
    }
  );

  return (
    <div className={'reset'}>
      <div id={'pages-config-layout'}>
        <Tabs>
          <TabsList>
            <Tab
              key={'tabs.params'}
              component={({ children, ...otherProps }: TabComponent) => (
                <NavLink {...otherProps} to={generatePath('.')} end>
                  {children}
                </NavLink>
              )}>
              {t('parameters.broadcastParameters')}
            </Tab>
            {Object.keys(BROADCAST_LIST).map((type) => (
              <Tab
                key={'tabs.'.concat(type)}
                component={({ children, ...otherProps }: TabComponent) => (
                  <NavLink {...otherProps} to={generatePath(type)} end>
                    {children}
                  </NavLink>
                )}>
                {t(`parameters.${BROADCAST_LIST[type as keyof typeof BROADCAST_LIST].ids[0]}`)}
              </Tab>
            ))}
          </TabsList>
        </Tabs>
      </div>

      <React.Suspense fallback={<></>}>
        <Outlet context={broadcastResponse} />
      </React.Suspense>
    </div>
  );
}

export default BroadcastingLayout;
