import styled from '@emotion/styled';
import React, { ComponentPropsWithoutRef } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';

type FormModalProps = Omit<ComponentPropsWithoutRef<typeof Modal>, 'onSubmit'> & {
  validButtonLabel: string;
  onSubmit?: () => void;
};

function FormModal(props: FormModalProps) {
  const { children, onSubmit, validButtonLabel, ...propsToForward } = props;
  const { t } = useTranslation('form');

  return (
    <Modal {...propsToForward}>
      <form onSubmit={onSubmit}>
        {children}
        <ModalActions>
          <Button onClick={() => propsToForward.setIsOpen(false)} color={'secondary'} label={t('cancel')} />
          <Button type={'submit'} label={validButtonLabel} />
        </ModalActions>
      </form>
    </Modal>
  );
}

const ModalActions = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 32px;
`;

export default FormModal;
