import { joiResolver } from '@hookform/resolvers/joi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Joi from 'joi';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Control, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { postCaution } from '../../../api/params/caution.api';
import { selectCollAndDeliveryPointID } from '../../../auth/selectors/authSelectors';
import ButtonWithIcon from '../../../components/Core/Button/ButtonWithIcon';
import Card from '../../../components/Core/Card/Card';
import MainSection from '../../../components/Core/MainSection/MainSection';
import { TOAST_SEVERITY } from '../../../components/Core/Toast/Toast';
import { useMainTitle } from '../../../components/Layout/MainTitleContext';
import { useSnackbar } from '../../../context/SnackbarContext';
import useGenerateFields from '../../../hook/useGenerateFields';
import useGetFormErrors from '../../../hook/useGetFormErrors';
import { generatePath } from '../../../utils/router';
import { getAmountExcludingTaxes, getAmountIncludingTaxes } from '../Deposit/List/utils';
import styles from './caution.module.scss';

function AddCautionPage() {
  const { t } = useTranslation(['parameters/cautionList', 'form']);
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  useMainTitle(t('addPage.mainTitle'));
  const errorMessages = useGetFormErrors();
  const schema = useMemo(() => {
    return Joi.object()
      .keys({
        caution: {
          cautionName: Joi.string(),
          taux_tva: Joi.number(),
          montant_ttc: Joi.number(),
          montant_ht: Joi.number().required(),
        },
      })
      .messages(errorMessages);
  }, [errorMessages]);

  const { control, handleSubmit, watch, setValue, reset } = useForm<{
    caution: {
      montant_ht: number | null;
      cautionName: string;
      montant_ttc: number | null;
      taux_tva: number;
    };
  }>({
    resolver: joiResolver(schema),
    defaultValues: {
      caution: {
        montant_ttc: null,
        montant_ht: null,
        taux_tva: 5.5,
      },
    },
  });
  const fields = useGenerateFields({
    control: control as unknown as Control,
    fields: useMemo(
      () => [
        {
          type: 'text',
          name: 'caution.cautionName',
          label: t('addPage.fields.name.label'),
          placeholder: t('addPage.fields.name.label'),
        },
        {
          type: 'number',
          name: 'caution.montant_ht',
          label: t('form:commonFields.amount_excluding_taxes.label'),
          placeholder: t('form:commonFields.amount_excluding_taxes.label'),
          wrapperClassName: 'start-1',
        },
        {
          type: 'select',
          name: 'caution.taux_tva',
          label: t('form:commonFields.vat_rate.label'),
          options: [
            {
              value: 5.5,
              label: '5.5%',
            },
            { value: 10, label: '10%' },
            { value: 20, label: '20%' },
          ],
        },
        {
          type: 'number',
          name: 'caution.montant_ttc',
          label: t('form:commonFields.amount_including_taxes.label'),
          placeholder: t('form:commonFields.amount_including_taxes.label'),
        },
      ],
      [t]
    ),
  });

  useEffect(() => {
    const { unsubscribe } = watch((data, { name, type }) => {
      if (data.caution && type) {
        const { taux_tva, montant_ht, montant_ttc } = data.caution;
        switch (name) {
          case 'caution.taux_tva':
          case 'caution.montant_ht':
            setValue('caution.montant_ttc', Number(getAmountIncludingTaxes(Number(montant_ht), Number(taux_tva))), {
              shouldDirty: false,
              shouldTouch: false,
            });
            return;
          case 'caution.montant_ttc':
            setValue('caution.montant_ht', Number(getAmountExcludingTaxes(Number(montant_ttc), Number(taux_tva))), {
              shouldDirty: false,
              shouldTouch: false,
            });
            return;
        }
      }
    });

    return () => unsubscribe();
  }, [watch]);

  const { mutate: handlePostDeposit } = useMutation(
    ['param/cautions'],
    (bodyForm: Parameters<typeof postCaution>[0]) => {
      return postCaution(bodyForm);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['param/cautions']);
        reset();
        snackbar?.setAlert({ message: t('addPage.newDepositSuccessfullyAdded'), severity: TOAST_SEVERITY.success });
        navigate(generatePath('home.parameters.deposits'));
      },
    }
  );

  const onSubmit = useCallback(
    handleSubmit((data) => {
      const {
        caution: { montant_ht, cautionName, taux_tva },
      } = data;

      if (montant_ht) {
        handlePostDeposit({
          id_coll,
          caution: {
            article: 'velo',
            montant_ht,
            cautionName,
            taux_tva,
          },
          articles: [],
        });
      }
    }),
    [handleSubmit, id_coll]
  );

  return (
    <MainSection className={'reset'}>
      <Card
        content={
          <div>
            <form onSubmit={onSubmit}>
              <div className={styles.Form}>{fields}</div>
              <div className={styles.Form__footer}>
                <ButtonWithIcon type={'submit'} icon={'CheckWithoutCircle'}>
                  {t('form:add')}
                </ButtonWithIcon>
              </div>
            </form>
          </div>
        }
      />
    </MainSection>
  );
}

export default AddCautionPage;
