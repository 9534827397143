import React from 'react';
import BookingCard from '../../components/BookingCard/BookingCard';
import MainSection from '../../components/Core/MainSection/MainSection';
import styles from './bookingDetails.module.scss';
import BookingGenericInfosCard from './components/BookingDetails/BookingGenericInfosCard/BookingGenericInfosCard';
import BookingPaymentInfosCard from './components/BookingDetails/BookingPaymentInfosCard';
import Commentary from './components/BookingDetails/Commentary';
import FinishCard from './components/BookingDetails/FinishCard';
import ManagementCard from './components/BookingDetails/ManagementCard';
import ValidateBookingTunnel from './components/BookingDetails/ValidateBookingTunnel';
import { BOOKING_STATUS, useBooking } from './Reservation.layout';

function BookingDetailsPage() {
  const { bookingStatus } = useBooking();

  return (
    <MainSection className={'reset'}>
      <div className={styles.grid}>
        {bookingStatus === BOOKING_STATUS.BOOKED && <ValidateBookingTunnel />}
        <div className={styles.leftSection}>
          {bookingStatus === BOOKING_STATUS.VALIDATE && <FinishCard />}
          <BookingGenericInfosCard />
          {bookingStatus !== BOOKING_STATUS.WAITING && <BookingPaymentInfosCard />}
          <Commentary className={styles.commentarySection} />
        </div>
        <div className={styles.rightSection}>
          <BookingCard />
          {bookingStatus &&
            ![BOOKING_STATUS.CANCELED, BOOKING_STATUS.CANCELED_AFTER_VALIDATED, BOOKING_STATUS.FINISHED].includes(
              bookingStatus
            ) && <ManagementCard />}
        </div>
      </div>
    </MainSection>
  );
}

export default BookingDetailsPage;
