import React, { useMemo } from 'react';
import { ReservationType } from 'reservations/types/reservation';
import AccessoriesAvailables from 'reservations/components/AccessoriesAvailables';

type Props = {
  reservation: ReservationType | null;
  isPark: boolean;
  isInWaitingList: boolean;
  isAuto: boolean;
  resaID: string;
  refresh: () => void;
};

//prettier-ignore
const ReservationAccessoriesAvailables = ({
  reservation, isPark, isAuto, resaID, refresh, isInWaitingList,
}: Props) => {
  const renderListAccessoriesAvailables = useMemo(() => {
    if (!reservation?.date_valid && !isPark && !isAuto && !isInWaitingList) {
      return (
        <>
          <br />
          <AccessoriesAvailables resaID={resaID} refresh={refresh} />
        </>
      );
    }

    return null;
  }, [resaID, isPark, isAuto, reservation?.date_valid]);

  return <>{renderListAccessoriesAvailables}</>;
};

export default ReservationAccessoriesAvailables;
