import React, { useCallback, useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { upperFirst } from 'lodash';
import CheckboxItemGroup from '../../components/Form/CheckboxItemGroup';
import CheckboxItem from '../../components/Core/CheckboxItem/CheckboxItem';
import Label from '../../components/Core/Label/Label';
import { Fieldset } from './RawData.page';

type ParkSectionRawResponse = {
  articles: Record<string, string[]>;
  pdl: string[];
  emplacements: string[];
};

type ParkRawDataExportProps = {
  data?: ParkSectionRawResponse;
};

type FormValues = {
  articles: { value: string; articles: string[] }[];
};

function ParkRawDataExport({ data }: ParkRawDataExportProps) {
  const { t } = useTranslation(['stats/rawData']);
  const { register } = useFormContext();

  const { append, remove, fields } = useFieldArray<FormValues, 'articles', 'id'>({
    name: 'articles',
  });

  const tOptions = useCallback((key: string) => upperFirst(t('fields.type.options.'.concat(key))), [t]);

  const typeOptions = useMemo(() => {
    return data?.articles
      ? Object.keys(data?.articles).map((type) => ({
          label: tOptions(type),
          value: type,
        }))
      : [];
  }, [data, tOptions]);

  return (
    <Fieldset className={'mt-4'} legend={t('exportContentSection')}>
      <div className={'box-field'}>
        <Label bigMargin>
          {t('fields.type.label')}
          <div className={'checkbox-group'}>
            {typeOptions.map((type) => (
              <CheckboxItem
                onChange={(e) => {
                  if (e.target.checked) {
                    append({ value: e.target.value, articles: [] }, { shouldFocus: false });
                  } else {
                    remove(fields.findIndex((field) => field.value === type.value));
                  }
                }}
                checked={!!fields.find((field) => field.value === type.value)}
                label={type.label}
                value={type.value}
              />
            ))}
          </div>
        </Label>
      </div>

      {fields.find((field) => field.value === 'bike' || field.value === 'accessory') && (
        <div className={'box-field'}>
          <Label bigMargin>
            {t('fields.pdl.label')}
            <CheckboxItemGroup options={data?.pdl.map((item) => ({ label: item, value: item })) || []} name={'pdl'} />
          </Label>
        </div>
      )}

      {fields.find((field) => field.value === 'consigne' || field.value === 'consignePark') && (
        <div className={'box-field'}>
          <Label bigMargin>
            {t('fields.empl.label')}
            <CheckboxItemGroup
              options={data?.emplacements.map((item) => ({ label: item, value: item })) || []}
              name={'emplacements'}
            />
          </Label>
        </div>
      )}

      {fields.length > 0 && (
        <div className={'box-field'}>
          <Label bigMargin>
            {t('fields.articleType.label')}
            {data?.articles &&
              fields.map((item, index) => (
                <div>
                  <Label secondary>
                    {tOptions(item.value)}
                    <div className={'checkbox-group'}>
                      {data.articles[item.value]
                        .map((articleName) => ({ label: articleName, value: articleName }))
                        .map((option) => (
                          <CheckboxItem
                            {...register(`articles.${index}.articles`)}
                            value={option.value}
                            label={option.label}
                          />
                        ))}
                    </div>
                  </Label>
                </div>
              ))}
          </Label>
        </div>
      )}
    </Fieldset>
  );
}

export default ParkRawDataExport;
