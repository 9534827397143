import styled from '@emotion/styled';
import React, { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { putAffectation, RawBike } from '../../../api/park/bike.api';
import { putAffectation as putAccessoryAffectation } from '../../../api/park/accessory.api';
import IconButton from '../../../components/Core/Button/IconButton';
import { RowItem } from '../../../components/Core/Datagrid/Datagrid';
import SelectField from '../../../components/Core/SelectField';
import { selectCollAndDeliveryPointID, selectDeliveryPointList } from '../../../auth/selectors/authSelectors';

type LocationCellProps = {
  bike: RawBike;
  articleType?: 'bike' | 'accessory';
  //eslint-disable-next-line
  filters?: any;
};

function LocationCell({ bike, articleType = 'bike', filters }: LocationCellProps) {
  const { handleSubmit, control } = useForm();
  const queryClient = useQueryClient();
  const { id_coll, id_pdl } = useSelector(selectCollAndDeliveryPointID);
  const pdlList = useSelector(selectDeliveryPointList);
  const putAffectationMethod = articleType === 'bike' ? putAffectation : putAccessoryAffectation;
  const { mutate: handlePutAffectation } = useMutation(
    [articleType?.concat('.affectation')],
    //eslint-disable-next-line
    (data: any) => putAffectationMethod(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([articleType?.concat('.list')]);
      },
    }
  );

  const defaultValue = bike.currentPdl.id_pdl.toString();
  const currentPdlLabel = pdlList.find((pdl) => pdl.id_pdl === Number(defaultValue))?.nom_pdl || '';

  const onSubmit = useCallback(
    handleSubmit((data) => {
      const idKey = articleType === 'bike' ? 'id_velo' : 'id_accessoire';
      handlePutAffectation({
        id_coll,
        id_pdl: filters?.filterPdl || id_pdl,
        id_aff: data.id_aff,
        //eslint-disable-next-line
        [idKey]: (bike as any)[idKey],
      });
    }),
    [handleSubmit, handlePutAffectation, filters, id_pdl, id_coll]
  );

  const availablePDL = useMemo(() => {
    return [
      ...bike.availablePdl.map(({ value: label, id_aff: value }) => ({
        label,
        value: value.toString(),
      })),
      {
        label: currentPdlLabel,
        value: bike.currentPdl.id_pdl.toString(),
      },
    ];
  }, [bike.availablePdl, bike.currentPdl, currentPdlLabel]);

  return bike.availablePdl.length === 0 ? (
    <RowItem>{currentPdlLabel}</RowItem>
  ) : (
    <Grid>
      <Controller
        name={'id_aff'}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, value }, formState }) => (
          <>
            <SelectField
              menuPosition={'fixed'}
              value={availablePDL.find((option) => option.value === value)}
              onChange={onChange}
              options={availablePDL}
            />
            <IconButton
              disabled={!formState.isDirty || value === defaultValue}
              icon={'RefreshIcon'}
              color={'action'}
              onClick={onSubmit}
            />
          </>
        )}
      />
    </Grid>
  );
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 8px;
  align-items: center;
`;

export default LocationCell;
