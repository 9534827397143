import { t } from 'internal/i18n';
import { FormItemType } from 'users/types/forms';
import validator from 'validator';
import { TFunction } from 'i18next';

export const bookingBikeForm = (t?: TFunction): FormItemType[][] => [
  [
    {
      type: 'text',
      placeholder: t?.('forms.last_name'),
      name: 'nom',
      id: 'nom',
      size: 'col-md-6',
    },
    {
      type: 'text',
      placeholder: t?.('forms.first_name'),
      name: 'prenom',
      id: 'prenom',
      size: 'col-md-6',
    },
  ],
  [
    {
      type: 'text',
      placeholder: t?.('forms.firm_or_asso'),
      name: 'entr_asso',
      id: 'entr_asso',
      size: 'col-md-12',
    },
  ],
  [
    {
      type: 'select',
      name: 'boursier',
      id: 'boursier',
      size: 'col-md-12',
      options: [
        { value: '', label: t?.('forms.status') || 'status' },
        { value: 'boursier', label: t?.('forms.student') || 'student' },
        { value: 'insertion', label: t?.('forms.back_to_work') || 'back_to_work' },
      ],
    },
  ],
  [
    {
      type: 'text',
      placeholder: t?.('forms.email'),
      name: 'email',
      id: 'email',
      size: 'col-md-6',
      onError: (value: string): string =>
        !validator.isEmpty(value) && !validator.isEmail(value) ? t?.('forms.email_error') || '' : '',
    },
    {
      type: 'date',
      placeholder: t?.('forms.birthdate'),
      className: 'datepicker',
      name: 'date_naissance',
      id: 'date_naissance',
      size: 'col-md-6',
    },
  ],
  [
    {
      type: 'text',
      placeholder: t?.('forms.iban'),
      name: 'iban',
      id: 'iban',
      size: 'col-md-6',
      onError: (value: string): string =>
        !validator.isEmpty(value) && !validator.isIBAN(value) ? t?.('forms.invalid_iban') || '' : '',
    },
    {
      type: 'text',
      placeholder: t?.('forms.bic'),
      name: 'bic',
      id: 'bic',
      size: 'col-md-6',
      onError: (value: string): string =>
        !validator.isEmpty(value) && !validator.isBIC(value) ? t?.('forms.invalid_bic') || '' : '',
    },
  ],
  [
    {
      type: 'select',
      name: 'motif_utilisation',
      id: 'motif_utilisation',
      size: 'col-md-12',
      options: [
        { value: '', label: t?.('forms.reason') || 'reason' },
        { value: 'essai du service', label: t?.('forms.service_trial') || 'service_trial' },
        { value: 'travail', label: t?.('forms.work') || 'work' },
        { value: 'loisirs', label: t?.('forms.hobbies') || 'hobbies' },
        { value: 'autres', label: t?.('forms.others') || 'others' },
      ],
    },
  ],
];

export const bankAccountInfoForm = (): FormItemType[][] => [
  [
    {
      type: 'select',
      label: t('forms.payment_method'),
      name: 'moyen_reglement',
      id: 'moyen_reglement',
      size: 'col-md-12',
      options: [
        { value: 'cheque', label: t('forms.cheque') },
        { value: 'cb', label: t('forms.credit_card') },
      ],
    },
  ],
];

export const commonConsigneForm = (t?: TFunction) => [
  [
    {
      type: 'text',
      placeholder: t?.('forms.last_name'),
      name: 'nom',
      id: 'nom',
      size: 'col-md-6',
    },
    {
      type: 'text',
      placeholder: t?.('forms.first_name'),
      name: 'prenom',
      id: 'prenom',
      size: 'col-md-6',
    },
  ],
  [
    {
      type: 'text',
      placeholder: t?.('forms.firm_or_asso'),
      name: 'entr_asso',
      id: 'entr_asso',
      size: 'col-md-12',
    },
  ],
  [
    {
      type: 'text',
      placeholder: t?.('forms.email'),
      name: 'email',
      id: 'email',
      size: 'col-md-6',
      onError: (value: string): string =>
        !validator.isEmpty(value) && !validator.isEmail(value) ? t?.('forms.email_error') || '' : '',
    },
    {
      type: 'date',
      placeholder: t?.('forms.birthdate'),
      className: 'datepicker',
      name: 'date_naissance',
      id: 'date_naissance',
      size: 'col-md-6',
    },
  ],
  [
    {
      type: 'text',
      placeholder: t?.('forms.iban'),
      name: 'iban',
      id: 'iban',
      size: 'col-md-6',
      onError: (value: string): string =>
        !validator.isEmpty(value) && !validator.isIBAN(value) ? t?.('forms.invalid_iban') || '' : '',
    },
    {
      type: 'text',
      placeholder: t?.('forms.bic'),
      name: 'bic',
      id: 'bic',
      size: 'col-md-6',
      onError: (value: string): string =>
        !validator.isEmpty(value) && !validator.isBIC(value) ? t?.('forms.invalid_bic') || '' : '',
    },
  ],
  [
    {
      type: 'select',
      name: 'motif_utilisation',
      id: 'motif_utilisation',
      size: 'col-md-12',
      options: [
        { value: '', label: t?.('forms.reason') },
        { value: 'essai du service', label: t?.('forms.service_trial') },
        { value: 'travail', label: t?.('forms.work') },
        { value: 'loisirs', label: t?.('forms.hobbies') },
        { value: 'autres', label: t?.('forms.others') },
      ],
    },
  ],
  [
    {
      type: 'text',
      placeholder: t?.('forms.navigo'),
      name: 'abotc',
      id: 'abotc',
      size: 'col-md-8',
      onError: (value: string): string =>
        !validator.isEmpty(value) && !validator.isNumeric(value) ? t?.('forms.navigo_error') || '' : '',
    },
  ],
];

export const paymentMeanForm = (
  t: TFunction,
  isBookingEnabled?: boolean,
  // isCautionEnabled: boolean,
  isPaymentBOEnabled?: boolean
): FormItemType[][] => {
  if (isBookingEnabled) {
    const options = [
      { value: 'cheque', label: t('forms.cheque') },
      { value: 'cash', label: t('forms.cash') },
      { value: 'cb', label: t('forms.credit_card') },
      { value: "bank_transfer", label: t('forms.bank_transfer') },
    ];

    if (isPaymentBOEnabled) {
      options.push({ value: 'cb_internet', label: t('forms.cc_internet') });
    }

    return [
      [
        {
          type: 'select',
          label: t('forms.payment_method'),
          name: 'moyen_reglement',
          id: 'moyen_reglement',
          size: 'col-md-12',
          options,
        },
      ],
    ];
  }

  return [];
};
