import { TFunction } from 'i18next';
import Joi from 'joi';
import { FormObject } from '../../../hook/useBuildForm';
import Devises from '../../../internal/constants/devises';

export type GetFormFieldsFunction = (t: TFunction) => FormObject;

const getCollectivityAdminParametersFormFields: GetFormFieldsFunction = (t) => {
  return [
    {
      fields: [
        {
          type: 'text',
          label: t('coll_name'),
          name: 'nom',
          columnStart: '1/2 span',
        },
        {
          type: 'text',
          label: t('front_url'),
          name: 'url_front',
        },
        /*{
          type: 'number',
          label: t('velocareSubsidiaryId'),
          name: 'velocareSubsidiaryId',
          columnStart: '1/2 span',
          schema: Joi.number().allow(null).allow(''),
        },
        {
          type: 'text',
          label: t('velocareBusinessId'),
          name: 'velocareBusinessId',
          schema: Joi.number().allow(null).allow(''),
        },
        {
          type: 'text',
          label: t('alwin_id'),
          name: 'alwinAuthUser',
        },
        {
          type: 'text',
          label: t('alwin_pass'),
          name: 'alwinAuthPassword',
        },
        {
          type: 'number',
          label: t('alwin_code'),
          name: 'alwinSupervisorCodeId',
          schema: Joi.number().allow(null).allow(''),
        },*/
        {
          type: 'select',
          label: t('currency'),
          name: 'devise',
          options: [
            { label: t('choose_currency'), value: '0' },
            { label: Devises.EUR, value: Devises.EUR },
            { label: Devises.POUND, value: Devises.POUND },
          ],
        },
        {
          type: 'number',
          label: t('booking_limit_user'),
          name: 'limit_reservations_user',
          schema: Joi.number().allow(null).allow(''),
        },
        /*{
          type: 'text',
          label: t('privacy_policy'),
          name: 'url_confidentialite',
        },*/
      ],
    },
    {
      colsNumber: 1,
      fields: [
        {
          type: 'checkbox',
          label: t('ask_sign'),
          name: 'demande_signature',
        },
        {
          type: 'checkbox',
          label: t('show_tariffs'),
          name: 'affichage_tarifs',
        },
        {
          type: 'checkbox',
          label: t('hours_restriction'),
          name: 'restrictions_horaires',
        },
        {
          type: 'checkbox',
          label: t('automatic_attribution'),
          name: 'attribution_auto_velo',
        },
      ],
    },
  ];
};

export default getCollectivityAdminParametersFormFields;
