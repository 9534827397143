import Joi from 'joi';
import { GetFormFieldsFunction } from '../../admin/Collectivity/updateForm';

const updateRenewalParametersForm: GetFormFieldsFunction = (t) => {
  return [
    {
      colsNumber: 2,
      fields: [
        {
          type: 'text',
          label: t('limit_renewal'),
          placeholder: t('limit_renewal'),
          name: 'limit_renouvellement',
          id: 'limit_renouvellement',
          schema: Joi.number(),
        },
        {
          type: 'text',
          label: t('limit_before_end'),
          placeholder: t('limit_before_end'),
          name: 'nombre_jour_avant_fin_renouvelement',
          id: 'nombre_jour_avant_fin_renouvelement',
          schema: Joi.number(),
        },
        {
          type: 'text',
          label: t('limit_after_end'),
          placeholder: t('limit_after_end'),
          name: 'nombre_jour_apres_fin_renouvelement',
          id: 'nombre_jour_apres_fin_renouvelement',
          schema: Joi.number(),
        },
      ],
    },
  ];
};

export default updateRenewalParametersForm;
