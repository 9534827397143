import { instance } from '../user.api';

const URL = {
  bookingConfig: 'param/adminbooking',
};

export type RawBookingConfig = {
  confirmationText: Record<string, string>;
  enabled: boolean;
  langs: string[];
  options: Record<string, string[]>;
  paymentInfoText: Record<string, string>;
  paymentsMeans: Record<string, boolean | string | null>;
};

export async function getBookingConfig(id_coll: number) {
  return await instance.get<RawBookingConfig>(URL.bookingConfig, {
    params: {
      id_coll,
    },
  });
}

export type PutBookingConfigBody = Omit<RawBookingConfig, 'langs' | 'options'> & {
  id_coll: number;
};

export async function putBookingConfig(data: PutBookingConfigBody) {
  const bodyForm = { ...data };
  if (!('agency' in bodyForm.paymentsMeans)) {
    bodyForm.paymentsMeans.agency = false;
  }
  return await instance.post(URL.bookingConfig, bodyForm);
}
