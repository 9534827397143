import React, { ForwardedRef, InputHTMLAttributes, useMemo } from 'react';
import styled from '@emotion/styled/macro';
import { css, Theme } from '@emotion/react';
import { CheckIcon, CrossIcon } from '../../../const/icons';
import { getColor } from '../../../utils/style';

const defaultIconMapping = {
  success: <CheckIcon width={20} />,
  error: <CrossIcon width={20} />,
};

export enum INPUT_COLORS {
  success = 'success',
}

type InputBaseProps = InputHTMLAttributes<HTMLInputElement> & {
  color?: INPUT_COLORS;
  error?: boolean;
};

function InputStyled(props: InputBaseProps, ref: ForwardedRef<HTMLInputElement>) {
  const { error = false, value, ...propsToForward } = props;

  const color = useMemo(() => {
    if (value && value !== '' && !error) {
      return INPUT_COLORS.success;
    }
    return undefined;
  }, [value, error]);

  const icon = useMemo(() => {
    if (error) return defaultIconMapping.error;
    else if (color === INPUT_COLORS.success) return defaultIconMapping.success;

    return null;
  }, [color, error]);

  return (
    <Root color={color} error={error}>
      <Input value={value} color={color} ref={ref} {...propsToForward} />
      {icon}
    </Root>
  );
}

const Root = styled.div<{ error?: boolean }>`
  //display: flex;
  display: block;
  position: relative;
  width: 100%;
  margin: 0;

  & > svg {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: ${(props) => {
      if (props.error) return getColor('red.200')(props);
      else if (props.color === INPUT_COLORS.success) return getColor('green.200')(props);
    }};
  }

  // TODO: add hover styles for svg
`;

const Input = styled.input<Omit<InputBaseProps, 'onChange'>>`
  display: block;
  background-color: white;
  outline: none;
  font-size: 14px;
  border-radius: 8px;
  padding: 8px;
  border: solid 1px ${getColor('gray.200')};
  transition: all 0.3s;
  width: 100%;
  text-overflow: ellipsis;

  :disabled {
    background-color: ${getColor('gray.200')};
  }

  ::placeholder {
    color: ${getColor('gray.300')};
  }

  ${(props) => {
    if (props.error) return errorStyle(props);
    if (props.color === INPUT_COLORS.success) return successStyle(props);

    return normalStyle(props);
  }}
`;

const normalStyle = (props: { theme: Theme }) => css`
  :hover {
    border-color: ${getColor('gray.300')(props)};
  }

  :focus,
  :not(:placeholder-shown) {
    border-color: ${getColor('primary.200')(props)};
  }

  :not(:placeholder-shown):hover {
    border-color: ${getColor('primary.300')(props)};
  }
`;

const errorStyle = (props: { theme: Theme }) => css`
  border-color: ${getColor('red.200')(props)};

  :hover,
  :focus {
    border-color: ${getColor('red.300')(props)};
  }
`;

const successStyle = (props: { theme: Theme }) => css`
  border-color: ${getColor('green.200')(props)};

  :hover,
  :focus {
    border-color: ${getColor('green.300')(props)};
  }
`;

export default React.forwardRef<HTMLInputElement, InputBaseProps>(InputStyled);
