import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FetchQueryOptions, QueryClient, useQuery, WithRequired } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { ActionFunction, Await, defer, json, LoaderFunction, useFetcher, useLoaderData } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragStartEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers';
import { shallowEqual, useSelector } from 'react-redux';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useDebounce } from 'use-debounce';
import { instance } from '../../../api/user.api';
import store from '../../../internal/store';
import { GridColDefinition } from '../../../components/Core/Datagrid/gridCols';
import Datagrid, { RowItem } from '../../../components/Core/Datagrid/Datagrid';
import MainSection from '../../../components/Core/MainSection/MainSection';
import Card from '../../../components/Core/Card/Card';
import { selectCollAndDeliveryPointID } from '../../../auth/selectors/authSelectors';
import ButtonWithIcon from '../../../components/Core/Button/ButtonWithIcon';
import { GridLinesIcon } from '../../../const/icons';
import { GridValidRowModel } from '../../../components/Core/Datagrid/gridRows';
import Dialog, { DialogActions, DialogContent, DialogTitle } from '../../../components/Dialog/Dialog';
import { TOAST_SEVERITY } from '../../../components/Core/Toast/Toast';
import { useSnackbar } from '../../../context/SnackbarContext';
import useDisplayError from '../../../hook/useDisplayError';
import styles from './DurationList.module.scss';
import { DraggableRow } from './TypePriceList.page';
import NewDuration, { DurationForm } from './NewDuration';

export const durationListQuery: (
  queryParams: unknown
) => WithRequired<FetchQueryOptions<AxiosResponse>, 'queryFn' | 'queryKey'> = (queryParams) => ({
  queryKey: ['duration/list', queryParams],
  queryFn: ({ queryKey }) => {
    const [apiUrl, queryParams] = queryKey;
    return instance.get(apiUrl as string, { params: queryParams });
  },
});

export const durationListAction: (queryClient: QueryClient) => ActionFunction =
  (queryClient) =>
  async ({ request }) => {
    const collId = store.getState().authState.collectivity;
    const { action, ...data } = await request.json();
    let response = null;

    try {
      switch (action) {
        case 'updateOrder':
          response = await instance.post('duration/updateOrder', data.items);
          if (response.status === 200) await queryClient.invalidateQueries(['duration/list']);
          break;
        case 'new':
          response = await instance.post('duration/new', { ...data, idColl: collId });
          if (response.status === 200) await queryClient.invalidateQueries(['duration/list']);
          break;
        case 'edit':
          response = await instance.post('duration/edit', { ...data, idColl: collId });
          if (response.status === 200) await queryClient.invalidateQueries(['duration/list']);
          break;
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        console.error(e);
        return json(e.response?.data);
      }
    }

    return response;
  };

export const durationListLoader: (queryClient: QueryClient) => LoaderFunction = (queryClient) => () => {
  const collId = store.getState().authState.collectivity;

  const durationListResponse = queryClient.ensureQueryData({
    ...durationListQuery({ idColl: collId }),
  });

  return defer({ collId, durationListResponse });
};

type UpdatePriceTypeProps = {
  row: GridValidRowModel;
};

function UpdateDuration({ row }: UpdatePriceTypeProps) {
  const fetcher = useFetcher();
  const { t } = useTranslation(['parameters/priceList/durationList', 'common']);
  const [open, setIsOpen] = useState(false);
  useDisplayError(fetcher.data);
  const snackbar = useSnackbar();
  const methods = useForm({ defaultValues: row, mode: 'onChange' });
  const { reset } = methods;

  const onSubmit = (data: FieldValues) => {
    fetcher.submit({ action: 'edit', ...data }, { method: 'post', encType: 'application/json' });
  };

  const onCancel = useCallback(() => {
    setIsOpen(false);
    reset(row);
  }, [reset, setIsOpen]);

  useEffect(() => {
    if (fetcher.data && fetcher.data.status === 200 && fetcher.data.config.url === 'duration/edit') {
      snackbar?.setAlert({
        message: t('editSuccessfullyMessage'),
        severity: TOAST_SEVERITY.success,
      });
      onCancel();
    }
  }, [fetcher.data, onCancel, t]);

  return (
    <>
      <ButtonWithIcon
        color={'secondary'}
        icon={'EditIcon'}
        onClick={(e: MouseEvent) => {
          e.preventDefault();
          setIsOpen(true);
        }}>
        {t('common:edit')}
      </ButtonWithIcon>
      {open && (
        <Dialog style={{ minWidth: 600 }} onCancel={onCancel} open={open}>
          <DialogTitle>{t('updateModal.title')}</DialogTitle>
          <FormProvider {...methods}>
            <form onSubmit={methods?.handleSubmit(onSubmit)}>
              <DialogContent className={'grid gap-8'}>
                <Card className={'mb-4'} color={'primary'} content={<>{t('updateModal.advice')}</>} />
                <span className={'block mb-4'}>{t('form:all_required')}</span>
                <DurationForm />
              </DialogContent>
              <DialogActions>
                <ButtonWithIcon onClick={onCancel} icon={'CrossWithoutCircle'} color={'secondary'}>
                  {t('common:cancel')}
                </ButtonWithIcon>
                <ButtonWithIcon icon={'CheckWithoutCircle'} type={'submit'}>
                  {t('common:apply')}
                </ButtonWithIcon>
              </DialogActions>
            </form>
          </FormProvider>
        </Dialog>
      )}
    </>
  );
}

function useColumns(): GridColDefinition[] {
  const { t } = useTranslation('parameters/priceList/durationList');

  const getColumnText = useCallback(
    (key: string) => {
      return t('columns.'.concat(key));
    },
    [t]
  );

  return useMemo(
    () => [
      {
        field: '',
        type: 'custom',
        renderCell: () => <GridLinesIcon width={14} />,
        width: 26,
      },
      {
        field: 'name',
        type: 'string',
        headerName: getColumnText('name'),
      },
      {
        field: 'type',
        type: 'custom',
        headerName: getColumnText('type'),
        renderCell: ({ row }) => <RowItem>{t(`fields.type.options.${row.type}`)}</RowItem>,
      },
      {
        field: 'min',
        type: 'string',
        headerName: getColumnText('min'),
      },
      {
        field: 'max',
        type: 'string',
        headerName: getColumnText('max'),
      },
      {
        field: 'id',
        type: 'actions',
        renderCell: ({ row }) => (
          <div className={'row justify-end items-center w-100'}>
            <UpdateDuration row={row} />
          </div>
        ),
        width: 'minmax(100px, auto)',
      },
      {
        field: 'id',
        type: 'custom',
        renderCell: () => <GridLinesIcon className={'ml-2'} width={14} />,
        width: 26,
      },
    ],
    [t]
  );
}

function SortableDurationList() {
  const { t } = useTranslation(['parameters/priceList/durationList', 'common']);
  const fetcher = useFetcher();
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const snackbar = useSnackbar();
  const { data: durationListResponseResolve } = useQuery<AxiosResponse>({
    ...durationListQuery({ idColl: id_coll }),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
  const [, setActiveId] = useState<number | null>(null);
  const [items, setItems] = useState<{ name: string; type: string; position: number; id: number }[]>([]);
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    setItems(durationListResponseResolve?.data || []);
  }, [durationListResponseResolve]);

  function handleDragStart(event: DragStartEvent) {
    const { active } = event;

    setActiveId(Number(active.id));
  }

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (over && active.id !== over?.id) {
      setItems((items) => {
        const oldIndex = items.findIndex((item) => item.position + 1 === active.id);
        const newIndex = items.findIndex((item) => item.position + 1 === over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }

    setActiveId(null);
  }

  function reset() {
    setItems(durationListResponseResolve?.data || []);
  }

  useEffect(() => {
    if (fetcher.data && fetcher.data.status === 200 && fetcher.data.config.url === 'duration/updateOrder') {
      snackbar?.setAlert({
        message: t('orderUpdatedSuccessfullyMessage'),
        severity: TOAST_SEVERITY.success,
      });
    }
  }, [fetcher.data, t]);

  const isDirty = useMemo(() => {
    return durationListResponseResolve?.data && items.length > 0
      ? !shallowEqual(items, durationListResponseResolve.data)
      : false;
  }, [items, durationListResponseResolve]);

  const [isDirtyDebounced] = useDebounce(isDirty, 100);

  const columns = useColumns();

  const handleUpdateOrderList = () => {
    fetcher.submit(
      {
        action: 'updateOrder',
        items: items.reduce((prev, currentItem, currentIndex) => {
          Object.assign(prev, { [currentItem.id]: currentIndex });
          return prev;
        }, {} as Record<string, string>),
      },
      { method: 'post', encType: 'application/json' }
    );
  };

  return (
    <>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        modifiers={[restrictToFirstScrollableAncestor]}>
        <SortableContext items={items.map((item) => ({ id: item.position + 1 }))}>
          <Datagrid className={styles.List} RowComponent={DraggableRow} rows={items} columns={columns} />
        </SortableContext>
      </DndContext>
      {isDirtyDebounced && (
        <div className={'row items-center p-16 justify-between'}>
          <ButtonWithIcon color={'secondary'} icon={'CrossWithoutCircle'} onClick={reset}>
            {t('common:cancel')}
          </ButtonWithIcon>
          <ButtonWithIcon icon={'EditIcon'} onClick={handleUpdateOrderList}>
            {t('common:apply')}
          </ButtonWithIcon>
        </div>
      )}
    </>
  );
}

function DurationListPage() {
  const { t } = useTranslation(['parameters/priceList/durationList']);
  const { durationListResponse } = useLoaderData() as {
    durationListResponse?: AxiosResponse;
    collId: number;
  };

  return (
    <>
      <NewDuration />
      <MainSection>
        <Card color={'primary'} content={<>{t('advice')}</>} />
        <Card>
          <Await resolve={durationListResponse}>
            <SortableDurationList />
          </Await>
        </Card>
      </MainSection>
    </>
  );
}

export default DurationListPage;
