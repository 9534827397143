import { css } from '@emotion/react';
import _ from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormObject } from '../../../hook/useBuildForm';

function useGetPDLUpdateFormObject() {
  const { t } = useTranslation('parameters/pdlDetails');
  const nums = _.range(1, 15);
  const values = [15, 30, 60];

  const delays = nums.map((num) => ({
    value: num.toString(),
    label: `${num.toString()} ${t('duration.day')}${num > 1 ? 's' : ''}`,
  }));

  const gaps = values.map((value) => ({
    value: value.toString(),
    label: `${value} minutes`,
  }));

  return useMemo(
    (): FormObject => [
      {
        fields: [
          {
            type: 'text',
            placeholder: t('columns.name'),
            label: t('columns.name'),
            name: 'nom_pdl',
            id: 'nom_pdl',
          },
          {
            type: 'text',
            placeholder: t('columns.address'),
            label: t('columns.address'),
            name: 'addr_pdl',
            id: 'addr_pdl',
            columnStart: '2 span',
          },
          {
            type: 'text',
            placeholder: t('columns.pc'),
            label: t('columns.pc'),
            name: 'cp_pdl',
            id: 'cp_pdl',
          },
          {
            type: 'text',
            placeholder: t('columns.city'),
            label: t('columns.city'),
            name: 'ville_pdl',
            id: 'ville_pdl',
            columnStart: '2 span',
          },
          {
            type: 'select',
            placeholder: t('columns.delay'),
            label: t('columns.delay'),
            name: 'delai_reservation',
            id: 'delai_reservation',
            options: [{ label: t('select_delay'), value: '0' }, ...delays],
          },
          {
            type: 'text',
            placeholder: t('columns.limit'),
            label: t('columns.limit'),
            name: 'limit_resa',
            id: 'limit_resa',
          },
          {
            type: 'text',
            placeholder: t('maxDayForRent.label'),
            label: t('maxDayForRent.label'),
            name: 'max_delay_reservation',
            id: 'max_delay_reservation',
          },
          {
            type: 'select',
            placeholder: t('columns.gap'),
            label: t('columns.gap'),
            name: 'pas_reservation',
            id: 'pas_reservation',
            options: gaps,
            columnStart: '1 / 1 span',
          },
          {
            type: 'select',
            placeholder: t('returnSlotInterval.label'),
            label: t('returnSlotInterval.label'),
            name: 'return_slot_interval',
            id: 'return_slot_interval',
            options: gaps,
            columnStart: '1 / 1 span',
          },
          {
            type: 'select',
            placeholder: t('returnDelay.label'),
            label: t('returnDelay.label'),
            name: 'return_delay',
            id: 'return_delay',
            options: [{ label: t('select_delay'), value: '0' }, ...delays],
          },
          {
            type: 'checkbox',
            name: 'is_visible',
            label: t('columns.visible'),
            columnStart: 1,
            css: css`
              margin-top: 20px;
            `,
          },
          {
            type: 'checkbox',
            name: 'creneau_unique',
            label: t('columns.unique_niche'),
            columnStart: 1,
          },
        ],
      },
    ],
    [delays, t]
  );
}

export default useGetPDLUpdateFormObject;
