enum UserRights {
  USERS_GLOBAL = 'usagers',
  USERS_CREATE = 'usagers_creer',
  USERS_LIST = 'usagers_liste',
  USERS_DETAIL_READ = 'usagers_fiche-voir',
  USERS_FUND_READ = 'usagers_caisse-voir',
  USERS_CONTRACT_READ = 'usagers_contrats',
  USERS_RESA_READ = 'usagers_reservations-voir',
  PARK_GLOBAL = 'parc',
  PARK_BIKES_CONSULT = 'parc_velos-voir',
  PARK_LIST_CONSULT = 'parc_consignes_park-voir',
  PARK_CONSIGNE_CONSULT = 'parc_consignes-voir',
  PARK_ACCESSORIES_CONSULT = 'parc_accessoires-voir',
  INVOICING_GLOBAL = 'cf',
  INVOICING_INVOICE = 'usagers_factures',
  INVOICING_CONTRACT = 'usagers_contrats',
  INVOICING_RESERVATION = 'usagers_reservations-voir',
  FUND_GLOBAL = 'caisse',
  PARAMETERS_GLOBAL = 'p',
  PARAMETERS_CONTRACT_READ = 'p_contrats-voir',
  PARAMETERS_CONTRACT_EDIT = 'p_contrats-edit',
  PARAMETERS_BROADCASTING_READ = 'p_diff-voir',
  PARAMETERS_BROADCASTING_EDIT = 'p_diff-edit',
  PARAMETERS_COLLECTIVITY_READ = 'p_collectivite-voir',
  PARAMETERS_COLLECTIVITY_EDIT = 'p_collectivite-edit',
  PARAMETERS_RENEWAL_READ = 'p_renouvellement-voir',
  PARAMETERS_RENEWAL_EDIT = 'p_renouvellement-edit',
  PARAMETERS_COLLECTIVITY_ADDPDL = 'p_collectivite-addpdl',
  PARAMETERS_COLLECTIVITY_EDITPDL = 'p_collectivite-editpdl',
  PARAMETERS_DEPOSITS_READ = 'p_cautions-voir',
  PARAMETERS_DEPOSITS_EDIT = 'p_cautions-edit',
  PARAMETERS_SECURITY_DEPOSITS_READ = 'p_garanties-voir',
  PARAMETERS_SECURITY_DEPOSITS_EDIT = 'p_garanties-edit',
  PARAMETERS_PRICES_READ = 'p_prix-voir',
  PARAMETERS_ARTICLES_READ = 'p_articles-voir',
  PARAMETERS_ARTICLES_EDIT = 'p_articles-edit',
  PARAMETERS_PRICES_ADD = 'p_prix-addarticle',
  PARAMETERS_PRICES_EDIT = 'p_prix-editarticle',
  PARAMETERS_DEGRADATIONS_READ = 'p_degradations-voir',
  PARAMETERS_DEGRADATIONS_EDIT = 'p_degradations-edit',
  PARAMETERS_DEGRADATIONS_ADD = 'p_degradations-add',
  PARAMETERS_DELAYS_READ = 'p_retards-voir',
  PARAMETERS_DELAYS_EDIT = 'p_retards-edit',
  PARAMETERS_DELAYS_ADD = 'p_retards-add',
  PARAMETERS_VISUAL_READ = 'p_visuel-voir',
  PARAMETERS_VISUAL_EDIT = 'p_visuel-edit',
  PARAMETERS_PERMISSION_READ = 'p_agents-voir',
  PARAMETERS_PERMISSION_ADD = 'p_agents-add',
  PARAMETERS_PERMISSION_EDIT = 'p_agents-edit',
  PARAMETERS_FORMS_READ = 'p_forms-voir',
  PARAMETERS_FORMS_EDIT = 'p_forms-edit',
  PARAMETERS_PAGES_READ = 'p_pages-voir',
  PARAMETERS_PAGES_EDIT = 'p_pages-edit',
  PARAMETERS_WORKSHOP_READ = 'p_workshop-voir',
  PARAMETERS_WORKSHOP_EDIT = 'p_workshop-edit',
  PARAMETERS_CONTACT_READ = 'p_contact-voir',
  PARAMETERS_CONTACT_EDIT = 'p_contact-edit',
  PARAMETERS_MODULES_READ = 'p_modules-voir',
  PARAMETERS_MODULES_EDIT = 'p_modules-edit',
  PARAMETERS_VOUCHERS_READ = 'p_pj-voir',
  PARAMETERS_VOUCHERS_EDIT = 'p_pj-edit',
  PARAMETERS_LOCALES_READ = 'p_languages-voir',
  PARAMETERS_LOCALES_EDIT = 'p_languages-edit',
  PARAMETERS_BOOKING_READ = 'p_adminbooking-voir',
  PARAMETERS_CAUTION_READ = 'p_admincautions-voir',
  PARAMETERS_ADMIN_READ = 'p_admin-voir',
  PARAMETERS_ADMIN_EDIT = 'p_admin-edit',
  STATS_GLOBAL = 'stats',
  FUND_MOVES = 'caisse_mouvements',
  FUND_DAILY = 'caisse_jour-voir',
  FUND_VALIDATION = 'caisse_jour-valid',
  STATS_COLLECTIVITY_READ = 'stats_collectivite-voir',
  CHECKIN_CHECKOUT_READ = 'p_checkin-voir',
  CHECKIN_CHECKOUT_EDIT = 'p_checkin-edit',
  WAITING_LIST_READ = 'p_listeattente-voir',
  WAITING_LIST_EDIT = 'p_listeattente-edit',
  INSURANCE_READ = 'p_insurance-voir',
  INSURANCE_EDIT = 'p_insurance-edit',
}

export default UserRights;
