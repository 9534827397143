import { CircularProgress } from '@mui/material';
import cx from 'classnames';
import React, { ButtonHTMLAttributes, ReactElement, useCallback } from 'react';

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  label: string;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
};

const Button = ({
  label,
  className = '',
  loading = false,
  disabled = false,
  onClick = () => null,
  ...propsToForward
}: Props): ReactElement => {
  const _className = cx('btn', {
    [className]: className !== '',
    ['disabled']: disabled || loading,
    ['d-flex align-items-center']: loading,
  });

  const _onClick = useCallback(() => {
    !disabled && onClick();
  }, [disabled, onClick]);

  return (
    <button className={_className} onClick={_onClick} {...propsToForward}>
      {label}
      {loading && <CircularProgress style={{ width: '15px', height: '15px', marginLeft: '10px', color: 'white' }} />}
    </button>
  );
};

export default Button;
