import Joi from 'joi';
import { GetFormFieldsFunction } from '../Collectivity/updateForm';

const updateWorkshopParametersForm: GetFormFieldsFunction = (t) => {
  return [
    {
      colsNumber: 3,
      fields: [
        {
          type: 'phone',
          name: 'phone',
          id: 'phone',
          label: t('phone'),
          placeholder: t('phone'),
        },
        {
          type: 'text',
          name: 'email',
          id: 'email',
          label: t('email'),
          placeholder: t('email'),
        },
        // TODO: add textare case for useBuildForm
        {
          type: 'textarea',
          name: 'openingHours',
          id: 'openingHours',
          label: t('opening_hours'),
          placeholder: t('opening_hours'),
        },
        {
          type: 'text',
          name: 'street',
          id: 'street',
          label: t('address'),
          placeholder: t('address'),
        },
        {
          type: 'text',
          name: 'postalCode',
          id: 'postalCode',
          label: t('zip'),
          placeholder: t('zip'),
        },
        {
          type: 'text',
          name: 'city',
          id: 'city',
          label: t('city'),
          placeholder: t('city'),
        },
        {
          type: 'number',
          name: 'lat',
          id: 'lat',
          label: t('latitude'),
          placeholder: t('latitude'),
          schema: Joi.number(),
        },
        {
          type: 'number',
          name: 'lng',
          id: 'lng',
          label: t('longitude'),
          placeholder: t('longitude'),
          schema: Joi.number(),
        },
      ],
    },
  ];
};

export default updateWorkshopParametersForm;
