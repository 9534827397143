import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { TOAST_SEVERITY } from '../../components/Core/Toast/Toast';
import { useSnackbar } from '../../context/SnackbarContext';
import { UserDetailsType } from '../../users/types/users';
import { generatePath } from '../../utils/router';

export function getDefaultValues(user?: UserDetailsType) {
  // TODO: change for pick values and not exclude
  if (user) {
    const {
      nom,
      prenom,
      bic,
      cp,
      adresse,
      adresse_compl,
      boursier,
      civilite,
      date_exp_adherent,
      date_naissance,
      email,
      iban,
      motif_adhesion_autre,
      motif_adhesion,
      motif_utilisation,
      num_adherent,
      nature,
      pays,
      entr_asso,
      telephone,
      ville,
      telephone_port,
    } = user;
    return {
      nom,
      prenom,
      bic,
      cp,
      adresse,
      adresse_compl,
      boursier,
      civilite,
      date_exp_adherent,
      date_naissance,
      email,
      iban,
      motif_adhesion_autre,
      motif_adhesion,
      motif_utilisation,
      num_adherent,
      nature,
      pays,
      entr_asso,
      telephone,
      ville,
      telephone_port,
    };
  }

  return undefined;
}

export function useUpdateUser(props: { user?: UserDetailsType }) {
  const { user } = props;
  const { t } = useTranslation(['user/validation', 'user/update']);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const snackbar = useSnackbar();

  const isValidationUserRoute = useMemo(() => user?.valid_bo === 0, [user]);

  const handleSuccess = useCallback(() => {
    if (isValidationUserRoute) {
      queryClient.invalidateQueries({ queryKey: ['user.list'] });
      queryClient.invalidateQueries({ queryKey: [`user.fetch.${user?.id_user}`] });
      snackbar?.setAlert({ message: t('user/validation:user_validated'), severity: TOAST_SEVERITY.success });
      navigate(generatePath('home.user.userDetails.infos', { params: { userId: user?.id_user } }));
    } else {
      snackbar?.setAlert({ message: t('user/update:update_validated'), severity: TOAST_SEVERITY.success });
    }
  }, [isValidationUserRoute, user, queryClient, t]);

  const labelCTAButton = useMemo(() => {
    return t(isValidationUserRoute ? 'user/validation:form_cta' : 'user/update:form_cta');
  }, [isValidationUserRoute, t]);

  return {
    handleSuccess,
    labelCTAButton,
  };
}
