import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ContentState, convertFromHTML, EditorState } from 'draft-js';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { useQueryClient } from '@tanstack/react-query';
import { selectCollAndDeliveryPointID } from '../../../auth/selectors/authSelectors';
import {
  BroadcastEditorsType,
  BroadcastMessageEditorsType,
  BroadcastStateType,
} from '../../../parameters/types/broadcasting';
import TextEditor from '../../../parameters/components/TextEditor';
import useApi, { Methods } from '../../../internal/hooks/useApi';
import { BROADCAST_ENDPOINT } from '../../../parameters/constants/endpoints';
import { useRequiredParams } from '../../Reservation/components/BookingDetails/BookingPaymentInfosCard';
import { setSnackbar, showSnackbar } from '../../../internal/actions/appActions';
import SnackbarSeverity from '../../../internal/constants/snackbar';
import { useMainTitle } from '../../../components/Layout/MainTitleContext';
import { BROADCAST_LIST } from './Broadcasting.layout';

function BroadcastingContentEditPage() {
  const { type } = useRequiredParams<{ type: string }>();
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const broadcastingResponse = useOutletContext<AxiosResponse | undefined>();
  const { t } = useTranslation(['old', 'components/topBar']);
  const [broadcastState, setBroadcastState] = useState<Partial<BroadcastStateType>>({
    enquete_email: EditorState.createEmpty(),
    relance_email_cons: EditorState.createEmpty(),
    relance_email_cons_park: EditorState.createEmpty(),
    relance_email_velo: EditorState.createEmpty(),
    enquete_email_days: 0,
    relance_email_days: 0,
    relance_sms_cons: '',
    relance_sms_cons_park: '',
    relance_sms_days: 0,
    relance_sms_velo: '',
  });
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  useMainTitle(t('components/topBar:pageTitles.broadcast'));

  const { request: postBroadcastChanges, response: postBroadcastResp } = useApi(Methods.POST, BROADCAST_ENDPOINT);

  useEffect(() => {
    if (broadcastingResponse) {
      //prettier-ignore
      const {
        enquete_email,
        relance_email_cons_park,
        relance_email_velo,
        relance_email_cons,
          ...rest
      } = broadcastingResponse.data;

      const toConvert = {
        enquete_email,
        relance_email_cons_park,
        relance_email_velo,
        relance_email_cons,
      };

      const textEditorsContents: Record<BroadcastEditorsType, EditorState> = Object.keys(toConvert).reduce(
        (acc, articleType) => {
          const blocksFromHTML = convertFromHTML(toConvert[articleType as BroadcastEditorsType]);
          const contentState = ContentState.createFromBlockArray(
            blocksFromHTML?.contentBlocks,
            blocksFromHTML?.entityMap
          );

          return {
            ...acc,
            [articleType]: EditorState.createWithContent(contentState),
          };
        },
        {
          enquete_email: EditorState.createEmpty(),
          relance_email_cons_park: EditorState.createEmpty(),
          relance_email_velo: EditorState.createEmpty(),
          relance_email_cons: EditorState.createEmpty(),
        }
      );

      const state: Partial<BroadcastStateType> = {
        ...textEditorsContents,
        ...rest,
      };

      setBroadcastState(state);
    }
  }, [broadcastingResponse]);

  const changeEditorState = useCallback(
    (editorType: string) => (editorState: EditorState | string) => {
      setBroadcastState((prevState) => ({
        ...prevState,
        [editorType]: editorState,
      }));
    },
    []
  );

  const onSubmit = useCallback(
    (markup: string, type: string) => {
      postBroadcastChanges({
        id_coll,
        to_up: type,
        [type]: markup,
      });
    },
    [id_coll, postBroadcastChanges]
  );

  const [emailState, messageState] = useMemo(() => {
    return BROADCAST_LIST[type as keyof typeof BROADCAST_LIST].ids;
  }, [type]);

  useEffect(() => {
    if (postBroadcastResp?.success) {
      dispatch(setSnackbar(t('parameters.modification_success'), SnackbarSeverity.SUCCESS));
      dispatch(showSnackbar());
      queryClient.invalidateQueries(['param/diff']);
    }
  }, [postBroadcastResp, queryClient]);

  return (
    <div className={'main-content'}>
      <div className={'card'}>
        <div className={'card-body'}>
          <div className={'row'}>
            <div key={`${emailState}${messageState}`}>
              <div className={'row'}>
                <div className={'col-md-12'}>
                  <p>{t(`parameters.${emailState}`)}</p>
                </div>
              </div>
              <div className={'row'}>
                <div className={'col-md-6'}>
                  <TextEditor
                    type={emailState}
                    //eslint-disable-next-line
                    editorState={broadcastState[emailState as BroadcastEditorsType] as any}
                    setEditorState={changeEditorState(emailState)}
                    onSubmit={onSubmit}
                  />
                </div>
                {messageState && (
                  <div className={'col-md-6'}>
                    <textarea
                      className={'form-control'}
                      id={emailState}
                      value={broadcastState[messageState as BroadcastMessageEditorsType]}
                      onChange={(e) =>
                        setBroadcastState((prevState) => ({
                          ...prevState,
                          [messageState]: e.target.value,
                        }))
                      }
                    />
                    <button
                      id={messageState}
                      className={'btn btn-w-xl btn-primary savetpl'}
                      onClick={() =>
                        //eslint-disable-next-line
                        onSubmit(broadcastState[messageState as BroadcastMessageEditorsType] as any, messageState)
                      }>
                      {t('parameters.save')}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BroadcastingContentEditPage;
