/** @jsxImportSource @emotion/react */
import React, { useCallback } from 'react';
import { Control, FieldValues, UseFormReset } from 'react-hook-form';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { GridValidRowModel } from '../../../components/Core/Datagrid/gridRows';
import { useSnackbar } from '../../../context/SnackbarContext';
import IconButton from '../../../components/Core/Button/IconButton';
import { selectCollAndDeliveryPointID } from '../../../auth/selectors/authSelectors';
import { TOAST_SEVERITY } from '../../../components/Core/Toast/Toast';
import useConsigneParkListApi from './useConsigneParkListApi';

type ConsigneParkListActionCellProps = {
  control: Control;
  reset?: UseFormReset<FieldValues>;
  row: GridValidRowModel;
  handleDelete?: (depotId: number) => void;
};

function ConsigneParkListActionCell(props: ConsigneParkListActionCellProps) {
  const { t } = useTranslation('park/genericList');
  const { row, control, reset } = props;
  const snackbar = useSnackbar();
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const { isDirty, dirtyFields } = control._formState;
  const { putConsignePark } = useConsigneParkListApi();

  const isDisabled = !isDirty || Object.keys(dirtyFields).length === 0;

  const handleUpdate = useCallback(async () => {
    const { nb_places } = control._formValues;
    const response = await putConsignePark({ id_coll, nb_places, id_conspark: row.id_consigne });

    if (response && response.status === 200) {
      snackbar?.setAlert({ message: t('freeParkUpdateSuccessMessage'), severity: TOAST_SEVERITY.success });
      reset && reset([], { keepValues: true });
    }
  }, [control._formValues]);

  return (
    <div
      key={`actions-${row.id_caution}`}
      css={css`
        display: grid;
        grid-template-columns: repeat(2, auto);
        justify-content: flex-end;
        gap: 8px;
      `}>
      <IconButton disabled={isDisabled} icon={'RefreshIcon'} color={'action'} onClick={handleUpdate} />
    </div>
  );
}

export default ConsigneParkListActionCell;
