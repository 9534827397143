import React, { InputHTMLAttributes } from 'react';
import styles from './CheckboxItem.module.scss';

type CheckboxItemProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> & {
  label: string;
};

const CheckboxItem = React.forwardRef<HTMLInputElement, CheckboxItemProps>((props, ref) => {
  const { label, ...propsToForward } = props;

  return (
    <label className={styles.Label}>
      <div className={styles.Checkbox}>
        <input ref={ref} type={'checkbox'} className={styles.Input} {...propsToForward} />
        <span className={styles.Checkmark} />
      </div>
      {label}
    </label>
  );
});

export default CheckboxItem;
