import React, { Dispatch, ReactElement, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectCollAndDeliveryPointID } from 'auth/selectors/authSelectors';
import Button from 'fragments/button/Button';
import { SIGN, USERS } from 'internal/constants/routes';
import useApi, { Methods } from 'internal/hooks/useApi';
import useRefresh from 'internal/hooks/useRefreshList';
import {
  CHANGE_BIKE_ENDPOINT,
  GET_USER_SIGN_ENDPOINT,
  OTHER_BIKES_ENDPOINT,
  STD_EXCHANGE_ENDPOINT,
} from 'reservations/constants/endpoints';
import { ReservationType } from 'reservations/types/reservation';
import Status from 'internal/constants/status';
import CheckinDialog from 'reservations/components/CheckinDialog';
import CheckoutDialog from 'reservations/components/CheckoutDialog';
import useCheckin from 'reservations/hooks/useCheckin';
import ReservationListBikes from 'reservations/components/reservation/ReservationListBikes';
import ReservationOtherBikes from 'reservations/components/reservation/ReservationOtherBikes';
import ReservationAccessoriesAvailables from 'reservations/components/reservation/ReservationAccessoriesAvailables';
import ReservationContractPart from 'reservations/components/reservation/ReservationContractPart';
import ReservationValid from 'reservations/components/reservation/ReservationValid';
import ReservationExchangeBike from 'reservations/components/reservation/ReservationExchangeBike';
import ReservationFinish from 'reservations/components/reservation/ReservationFinish';
import ReservationCancelDialog from 'reservations/components/reservation/ReservationCancelDialog';
import useCheckout from 'reservations/hooks/useCheckout';
import ReservationDepositRefund from 'reservations/components/reservation/ReservationDepositRefund';

type Props = {
  resaID: string;
  reservation: ReservationType | null;
  userID: string;
  contract: { url: string; label: string } | null;
  submitStatus: Status;
  refresh: () => void;
  submit: () => void;
  cancel: () => void;
  finish: [Dispatch<SetStateAction<string>>, () => void];
  isPark: boolean;
  askSignature: boolean;
};

const ReservationStatus = ({
  resaID,
  reservation,
  userID,
  contract,
  submitStatus,
  refresh,
  submit,
  cancel,
  finish,
  isPark,
  askSignature,
}: Props): ReactElement => {
  const { t } = useTranslation('old');
  const navigate = useNavigate();
  const { id_coll, id_pdl } = useSelector(selectCollAndDeliveryPointID);
  const [userSign, setUserSign] = useState('');
  const [otherBikesShown, setOtherBikesShown] = useState(false);
  const [open, setOpen] = useState(false);
  const [openCheckin, setOpenCheckin] = useState(false);
  const [openCheckout, setOpenCheckout] = useState(false);
  const [newBikeID, setNewBikeID] = useState('');
  const [verifsCheckout, fetchCheckouts] = useCheckout({
    dependencies: {
      resaID,
      openCheckin,
      openCheckout,
      open,
    },
  });

  const { request: fetchUserSign, response: userSignRes } = useApi<{ img_sign: string }>(
    Methods.GET,
    GET_USER_SIGN_ENDPOINT
  );
  const { request: fetchOtherBikes, response: otherBikesRes } = useApi<{ id: number; id_interne: string }[]>(
    Methods.GET,
    OTHER_BIKES_ENDPOINT
  );

  const [verifs, fetchCheckins, isCheckinResponse] = useCheckin({
    dependencies: {
      resaID,
      openCheckin,
      open,
      fetchCheckouts,
    },
  });

  const { request: postNewBike } = useApi(Methods.POST, CHANGE_BIKE_ENDPOINT);
  const { request: postStdExchange } = useApi(Methods.POST, STD_EXCHANGE_ENDPOINT);

  useEffect(() => {
    fetchUserSign({
      params: {
        id_coll,
        id_resa: resaID,
      },
    });
  }, [fetchUserSign, id_coll, resaID]);

  const refetchCheckinCheckout = useCallback(() => {
    fetchCheckins();
  }, [fetchCheckins, fetchCheckouts]);

  useEffect(() => {
    refetchCheckinCheckout();
  }, [id_coll, resaID, open]);

  useEffect(() => {
    fetchOtherBikes({
      params: {
        id_coll,
        id_resa: resaID,
        id_pdl,
      },
    });
  }, [fetchOtherBikes, id_coll, id_pdl, resaID, reservation?.id_interne]);

  useEffect(() => {
    if (userSignRes?.success && userSignRes.data) {
      setUserSign(userSignRes.data.img_sign || '');
    }
  }, [userSignRes]);

  const articleNotAttributed = useMemo(
    () => reservation?.id_velo === 0 || reservation?.id_consigne === 0,
    [reservation]
  );

  const isInWaitingList = useMemo(() => !reservation?.date_start && !reservation?.date_end, [reservation]);

  const labelList = useCallback(
    (id_interne: string) => {
      if (!isPark) {
        return t('reservations.other_bike', { id: id_interne });
      }

      return t('reservations.other_parks', { id: id_interne });
    },
    [isPark, t]
  );

  const isAuto = useMemo(() => reservation?.is_auto !== 0, [reservation]);

  const otherBikes = useMemo(() => {
    if (otherBikesRes?.success && otherBikesRes.data && reservation) {
      return otherBikesRes.data
        .filter(({ id }) => id !== reservation.id_velo)
        .map(({ id, id_interne }) => ({
          label: labelList(id_interne),
          value: id.toString(),
        }));
    }
    return [];
  }, [otherBikesRes, reservation, labelList]);

  const handleClickSignResa = useCallback(() => {
    navigate(`${USERS}${SIGN}?resaID=${resaID}&userID=${userID}`);
  }, []);

  const showOtherBikes = useCallback(() => {
    setOtherBikesShown(true);
  }, []);

  const refreshList = useRefresh(refresh, 1000);

  const submitResa = useCallback(() => {
    submit();
    refreshList();
  }, [submit, refreshList, verifs]);

  const resaIsFree = useMemo(() => !reservation?.id_paiement, [reservation]);
  const hasCheckin = useMemo(() => verifs?.length > 0, [verifs]);
  const hasCheckout = useMemo(() => verifsCheckout?.length > 0, [verifsCheckout]);
  const canValidResa = useMemo(
    () =>
      ((askSignature && userSign) || !askSignature) &&
      !contract &&
      !articleNotAttributed &&
      isCheckinResponse &&
      !reservation?.queue_position,
    [askSignature, userSign, contract, articleNotAttributed, isCheckinResponse, reservation?.queue_position]
  );

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleCloseCheck = useCallback(() => {
    setOpenCheckin(false);
  }, []);

  const handleOpenCheck = useCallback(() => {
    setOpenCheckin(true);
  }, []);

  const handleCloseCheckout = useCallback(() => {
    setOpenCheckout(false);
  }, []);

  const handleOpenCheckout = useCallback(() => {
    setOpenCheckout(true);
  }, []);

  const submitBikeChange = useCallback(() => {
    setOtherBikesShown(false);
    postNewBike({
      id_coll,
      id_pdl,
      id_resa: resaID,
      id_new: newBikeID || otherBikes[0]?.value,
    });
    setNewBikeID('');
    refreshList();
  }, [setOtherBikesShown, postNewBike, otherBikes, id_coll, id_pdl, resaID, newBikeID, refreshList]);

  const openCancelConfirm = useCallback(() => {
    setOpen(true);
  }, []);

  const cancelResa = useCallback(() => {
    cancel();
    refreshList();
    setOpen(false);
  }, [cancel, refreshList]);

  const submitStdExchange = useCallback(
    (t_ech: string) => () => {
      postStdExchange({
        id_coll,
        id_pdl,
        id_resa: resaID,
        id_new: newBikeID || otherBikes[0]?.value || '',
        t_ech,
      });

      refreshList();
    },
    [postStdExchange, refreshList, id_coll, id_pdl, resaID, newBikeID, otherBikes]
  );

  const [setFinishState, finishResa] = finish;

  const finishRent = useCallback(() => {
    finishResa();
    refreshList();
  }, [finishResa, refreshList]);

  const renderCheckinDialog = useMemo(() => {
    let action;

    if (canValidResa) {
      action = submitResa;
    } else if (askSignature) {
      action = handleClickSignResa;
    }

    return (
      <CheckinDialog
        open={openCheckin}
        onClose={handleCloseCheck}
        onNextAction={action}
        verifs={verifs}
        userID={userID}
        resaID={resaID}
        refetch={refetchCheckinCheckout}
      />
    );
  }, [openCheckin, handleCloseCheck, verifs, userID, resaID, refetchCheckinCheckout]);

  const renderCheckoutDialog = useMemo(() => {
    let action;

    if (open) {
      action = cancelResa;
    } else {
      action = finishRent;
    }

    return (
      <CheckoutDialog
        open={openCheckout}
        onClose={handleCloseCheckout}
        onNextAction={action}
        verifs={verifsCheckout}
        userID={userID}
        resaID={resaID}
        refetch={refetchCheckinCheckout}
      />
    );
  }, [openCheckout, handleCloseCheckout, verifsCheckout, userID, resaID, refetchCheckinCheckout]);

  return (
    <div className={'col-md-6'}>
      {reservation?.is_canceled !== 1 && reservation?.is_returned !== 1 && (
        <>
          <ReservationListBikes
            isInWaitingList={isInWaitingList}
            articleNotAttributed={articleNotAttributed}
            otherBikes={otherBikes}
            setNewBikeID={setNewBikeID}
            submitBikeChange={submitBikeChange}
            isAuto={isAuto}
          />
          <ReservationOtherBikes
            isPark={isPark}
            isInWaitingList={isInWaitingList}
            otherBikesShown={otherBikesShown}
            submitBikeChange={submitBikeChange}
            showOtherBikes={showOtherBikes}
            setNewBikeID={setNewBikeID}
            otherBikes={otherBikes}
            articleNotAttributed={articleNotAttributed}
            reservation={reservation}
            contract={contract}
          />
          <ReservationAccessoriesAvailables
            reservation={reservation}
            isPark={isPark}
            isInWaitingList={isInWaitingList}
            isAuto={isAuto}
            resaID={resaID}
            refresh={refreshList}
          />
          <ReservationContractPart
            isInWaitingList={isInWaitingList}
            resaIsFree={resaIsFree}
            userSign={userSign}
            contract={contract}
            askSignature={askSignature}
            submitStatus={submitStatus}
            handleOpenCheck={handleOpenCheck}
            handleClickSignResa={handleClickSignResa}
            hasCheckin={hasCheckin}
            articleNotAttributed={articleNotAttributed}
          />
          <ReservationValid
            canValidResa={canValidResa}
            hasCheckin={hasCheckin}
            handleOpenCheck={handleOpenCheck}
            submitResa={submitResa}
          />
          <ReservationExchangeBike
            contract={contract}
            isPark={isPark}
            otherBikes={otherBikes}
            reservation={reservation}
            setNewBikeID={setNewBikeID}
            submitStdExchange={submitStdExchange}
          />
          <div className={'row'}>
            <div className={'col-md-12'}>
              {t('reservations.cancelation_text')}
              <br />
            </div>
            <div className={'col-md-4'}>
              <Button
                label={t('reservations.cancel')}
                className={'btn btn-primary cancelresa btn-fit'}
                onClick={openCancelConfirm}
              />
            </div>
          </div>
          <br />
          <ReservationFinish
            hasCheckout={hasCheckout}
            handleOpenCheckout={handleOpenCheckout}
            finishRent={finishRent}
            isPark={isPark}
            userSign={userSign}
            reservation={reservation}
            setFinishState={setFinishState}
          />
          {renderCheckinDialog}
          {renderCheckoutDialog}
        </>
      )}
      <ReservationDepositRefund refresh={refresh} isPark={isPark} reservation={reservation} resaID={resaID} />

      <ReservationCancelDialog
        hasCheckout={hasCheckout}
        handleOpenCheckout={handleOpenCheckout}
        cancelResa={cancelResa}
        handleClose={handleClose}
        open={open}
      />
    </div>
  );
};

export default ReservationStatus;
