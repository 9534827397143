/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { AxiosResponse } from 'axios';
import { TFunction } from 'i18next';
import React, { useCallback, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { QueryFunction, useMutation, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { fetchDegradationList, postDegradation } from '../../../api/params/degradation.api';
import { RawGenericListReturn } from '../../../api/params/vouchers.api';
import { selectCollAndDeliveryPointID } from '../../../auth/selectors/authSelectors';
import Button from '../../../components/Core/Button/Button';
import { Row, RowItem } from '../../../components/Core/Datagrid/Datagrid';
import { GridColDefinition } from '../../../components/Core/Datagrid/gridCols';
import FormModal from '../../../components/Core/FormModal/FormModalProps';
import InputBase from '../../../components/Core/InputBase/InputBase';
import { TOAST_SEVERITY } from '../../../components/Core/Toast/Toast';
import amountForm from '../../../const/amountForm';
import { useSnackbar } from '../../../context/SnackbarContext';
import useBuildForm, { FormObject } from '../../../hook/useBuildForm';
import { ListParams } from '../../park/BikeList/BikeList';
import { RowProps } from '../Caution/CautionList';
import { withTheme } from '../Deposit/List/DepositList';
import { getAmountExcludingTaxes, getAmountIncludingTaxes } from '../Deposit/List/utils';
import GenericList from '../Voucher/components/GenericList';
import DegradationListActionCell from './DegradationListActionCell';

const NAMESPACE = 'parameters/degradationList';

function useGetDegradationListColumnDefinitions() {
  const { t } = useTranslation(NAMESPACE);

  return useMemo<GridColDefinition[]>(() => {
    return [
      {
        type: 'formField',
        field: 'nom_degradation',
        headerName: t('columns.nom_degradation'),
        // TODO: add name param in renderCell function (for Controller name and defaultValue)
        renderCell: ({ row, control }) => {
          return (
            <Controller
              control={control}
              name={'nom_degradation'}
              key={`input-name-${row.id_degradation}`}
              defaultValue={row.nom_degradation}
              render={({ field: { onChange, value, name } }) => (
                <InputBase name={name} onChange={onChange} value={value} />
              )}
            />
          );
        },
      },
      {
        type: 'formField',
        field: 'montant_ht',
        headerName: t('columns.montant_ht'),
        renderCell: ({ row, control, setValue }) => {
          const handleChange = useCallback(
            (
              onChange: (newValue: string | number | readonly string[] | undefined) => void,
              newValue: string | number | readonly string[] | undefined
            ) => {
              const amountExcludingTaxes = Number(newValue);
              setValue('montant_ttc', getAmountIncludingTaxes(amountExcludingTaxes, Number(row.taux_tva)));
              onChange(newValue);
            },
            [row]
          );

          return (
            <Controller
              control={control}
              name={'montant_ht'}
              defaultValue={Number(row.montant_ht).toFixed(4)}
              key={`input-price-${row.depotId}`}
              render={({ field: { onChange, value, name } }) => (
                <InputBase onChange={(newValue) => handleChange(onChange, newValue)} value={value} name={name} />
              )}
            />
          );
        },
      },
      {
        type: 'string',
        field: 'taux_tva',
        headerName: t('columns.taux_tva'),
        width: 120,
      },
      {
        headerName: t('columns.montant'),
        field: 'montant',
        type: 'formField',
        renderCell: ({ row, control, setValue }) => {
          const handleChange = useCallback(
            (
              onChange: (newValue: string | number | readonly string[] | undefined) => void,
              newValue: string | number | readonly string[] | undefined
            ) => {
              const amountIncludingTaxes = Number(newValue);
              setValue('montant_ht', getAmountExcludingTaxes(amountIncludingTaxes, Number(row.taux_tva)));
              onChange(newValue);
            },
            [row]
          );

          return (
            <Controller
              control={control}
              name={'montant'}
              key={`montant-${row.id_degradation}`}
              defaultValue={Number(row.montant).toFixed(4)}
              render={({ field: { onChange, value, name } }) => (
                <InputBase name={name} onChange={(newValue) => handleChange(onChange, newValue)} value={value} />
              )}
            />
          );
        },
      },
      {
        type: 'formField',
        field: '',
        headerName: '',
        renderCell: (props) => <DegradationListActionCell {...props} />,
        width: '1fr',
      },
    ];
  }, [t]);
}

function DegradationListRow({ columns, row, index, colsTemplate }: RowProps) {
  const useFormReturn = useForm({ mode: 'onChange' });

  const { isDirty, dirtyFields } = useFormReturn.formState;

  const buildCell = useCallback(
    (column: GridColDefinition, index: number) => {
      if (column.type === 'formField') {
        return (
          <div
            key={'value-' + column.field + index}
            css={css`
              display: grid;
              align-items: center;
            `}>
            {column.renderCell({ row, ...useFormReturn, columnDef: column })}
          </div>
        );
      }

      return (
        <RowItem key={'value-' + column.field + index}>
          <span>{row[column.field]}</span>
        </RowItem>
      );
    },
    [row, isDirty, dirtyFields]
  );

  return (
    <Row key={'line'.concat(index.toString())} colsTemplate={colsTemplate}>
      {columns.map((column, index) => buildCell(column, index))}
    </Row>
  );
}

function getCreateDegradationForm(t: TFunction): FormObject {
  return [
    {
      fields: [
        { type: 'text', name: 'nom_degradation', label: t('nom_degradation'), placeholder: t('nom_degradation') },
        ...amountForm(t),
      ],
    },
  ];
}

function DegradationList() {
  const { t } = useTranslation([NAMESPACE, 'form']);
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();
  const columnDefinitions = useGetDegradationListColumnDefinitions();
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);

  const { builtFormFields, handleSubmit, reset } = useBuildForm({
    colsNumber: 2,
    formObject: getCreateDegradationForm(t),
  });

  const { mutate: handlePostDegradation } = useMutation(
    ['degradation.post'],
    //eslint-disable-next-line
    (data: any) => {
      return postDegradation(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['degradation.list']);
        reset();
        snackbar?.setAlert({ message: t('add_degradation_success'), severity: TOAST_SEVERITY.success });
        setCreateModalIsOpen(false);
      },
    }
  );

  const onSubmitCreateDegradation = useCallback(
    handleSubmit(async ({ montant_ttc, ...data }) => {
      handlePostDegradation({ id_coll, ...data });
    }),
    [id_coll]
  );

  const queryFn: QueryFunction<AxiosResponse<RawGenericListReturn>> = ({ queryKey }) => {
    const [, requestParams, listParams] = queryKey as [string, { id_coll: number }, ListParams];

    return fetchDegradationList({
      id_coll: requestParams.id_coll,
      p: listParams.currentPage,
      ipp: listParams.itemPerPage,
    });
  };

  return (
    <MainSection className={'reset'}>
      <div
        css={css`
          display: flex;
          //padding-top: 16px;
          //padding-right: 16px;
          justify-content: flex-end;
        `}>
        <Button label={t('title_degradation')} onClick={() => setCreateModalIsOpen(true)} />
        {createModalIsOpen && (
          <FormModal
            validButtonLabel={t('form:add')}
            onSubmit={onSubmitCreateDegradation}
            title={t('title_degradation')}
            setIsOpen={setCreateModalIsOpen}>
            {builtFormFields}
          </FormModal>
        )}
      </div>
      <GenericList
        RowComponent={DegradationListRow}
        queryFn={queryFn}
        queryKey={['degradation.list', { id_coll }]}
        columns={columnDefinitions}
      />
    </MainSection>
  );
}

const MainSection = styled.section`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export default withTheme(DegradationList);
