import logoVelogikDigital from 'assets/img/logo-vlgk-digital.png';
import { SIGN_IN_ENDPOINT } from 'auth/constants/endpoints';
import { CollectivityType } from 'auth/types/collectivity';
import { UserType } from 'auth/types/user';
import { SHA256 } from 'crypto-js';
import Button from 'fragments/button/Button';
import MaterialInput from 'fragments/materialInput/MaterialInput';
import { PWD_SEED } from 'internal/constants/api';
import { FORGOT_PWD } from 'internal/constants/routes';
import useApi, { Methods } from 'internal/hooks/useApi';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

type Props = {
  saveCollectivityList: (collectivityList: CollectivityType[]) => void;
  saveLocalUser: (user: Omit<UserType, 'coll'>) => void;
};

const AuthForm = ({ saveCollectivityList, saveLocalUser }: Props): ReactElement => {
  const { t } = useTranslation(['signIn', 'common']);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const { request, response } = useApi<{ coll: CollectivityType[] } & UserType>(Methods.POST, SIGN_IN_ENDPOINT);

  const onSubmit = useCallback(
      //eslint-disable-next-line
    (e: any) => {
      e.preventDefault();
      request({
        email,
        password: SHA256(password + PWD_SEED).toString(),
      });
    },
    [email, password]
  );

  useEffect(() => {
    if (response?.success && response.data) {
      const { coll, ...user } = response.data;

      saveLocalUser(user);
      saveCollectivityList(coll);
    }
  }, [response, saveLocalUser, saveCollectivityList]);

  return (
    <>
      <h5 className={'text-uppercase'}>{t('welcome')}</h5>
      <br />
      <form
        className={'form-type-material needs-validation'}
        data-provide={'validation'}
        autoComplete={'off'}
        onSubmit={onSubmit}>
        <MaterialInput
          label={t('common:email')}
          name={'email'}
          type={'email'}
          id={'email'}
          handleChange={setEmail}
          required
        />
        <MaterialInput
          label={t('common:password')}
          defaultValue={undefined}
          name={'password'}
          type={'password'}
          id={'password'}
          handleChange={setPassword}
          required
        />
        <div className={'form-group flexbox'}>
          <div className={'custom-control custom-checkbox'} />
          <Link className={'text-muted hover-primary fs-13'} to={FORGOT_PWD}>
            {t('forgotten_pwd')}
          </Link>
        </div>
        <div className={'form-group'}>
          <Button label={t('log_in')} type={'submit'} className={'btn btn-bold btn-block btn-primary'} />
        </div>
      </form>
      <p className={'text-center text-muted fs-13 mt-20 mb-0'}>
        <img src={logoVelogikDigital} alt={'Vélogik'} />
      </p>
    </>
  );
};

export default AuthForm;
