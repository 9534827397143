import { useEffect, useState } from 'react';
import styles from './LinearProgressBar.module.scss';

type LinearProgressBar = {
  isLoading: boolean;
};

function LinearProgressBar({ isLoading }: LinearProgressBar) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (!isLoading) {
          return 100;
        }

        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 95);
      });
    }, 200);

    return () => {
      clearInterval(timer);
    };
  }, [isLoading]);

  return (
    <div className={styles.Container}>
      <div className={styles.EmptyBar}>
        <div className={styles.FillingBar} style={{ width: `${progress}%` }} />
      </div>
      <span className={styles.Text}>{progress.toFixed(0)}%</span>
    </div>
  );
}

export default LinearProgressBar;
