import React, { ForwardedRef, InputHTMLAttributes, TextareaHTMLAttributes } from 'react';
import { CheckIcon, CrossIcon } from '../../../const/icons';
import styles from './Input.module.scss';

const TextArea = React.forwardRef<HTMLTextAreaElement, TextareaHTMLAttributes<HTMLTextAreaElement>>((props, ref) => {
  const { className, ...propsToForward } = props;
  return (
    <div className={styles.Root}>
      <textarea ref={ref} className={[styles.Input, className].join(' ')} {...propsToForward} />
      {/*{isDirty ? inputIconsMap.get(isValid ? 'success' : 'error') : null}*/}
    </div>
  );
});

const inputIconsMap = new Map([
  ['success', <CheckIcon width={20} />],
  ['error', <CrossIcon width={20} />],
]);

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  isValid?: boolean;
  isDirty?: boolean;
};

export const Input = React.forwardRef<HTMLInputElement, InputProps>((props: InputProps, ref) => {
  const { isDirty, isValid, className, ...propsToForward } = props;

  return (
    <div className={styles.Root}>
      <input ref={ref} className={[styles.Input, className].join(' ')} {...propsToForward} />
      {isDirty ? inputIconsMap.get(isValid ? 'success' : 'error') : null}
    </div>
  );
});

type TextFieldControlledProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  name: string;
};

type TextAreaFieldControlledProps = InputHTMLAttributes<HTMLTextAreaElement> & {
  label?: string;
  name: string;
};

export const TextAreaFieldControlled = React.forwardRef<HTMLTextAreaElement, TextAreaFieldControlledProps>(
  (props, ref) => {
    const { name, ...propsToForward } = props;

    return (
      <TextArea
        ref={ref}
        name={name}
        {...propsToForward}
        /*isValid={!!fieldState.error}
               onChange={field.onChange}
               isDirty={fieldState.isDirty} */
      />
    );
  }
);

const TextFieldControlled = (props: TextFieldControlledProps, ref: ForwardedRef<HTMLInputElement>) => {
  const { name, ...propsToForward } = props;

  return (
    <Input
      ref={ref}
      name={name}
      {...propsToForward}
      /*isValid={!!fieldState.error}
           onChange={field.onChange}
           isDirty={fieldState.isDirty} */
    />
  );
};

export default React.forwardRef(TextFieldControlled);
