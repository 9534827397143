import { SERVICE_KEYS } from 'ioc/keys';
import { inject, injectable } from 'inversify';
import { AxiosResponse } from 'axios';
import { Api } from '../api/axios';

const ENDPOINTS = {
  getAll: 'user/dashboard',
};

@injectable()
export class DashboardRepository {
  @inject(SERVICE_KEYS.API_INSTANCE)
  private _apiInstance: Api | undefined;

  getAll(collectivityId: number, pdlId: number): Promise<AxiosResponse<Record<string, number>>> | undefined {
    return this._apiInstance?.instance.get(ENDPOINTS.getAll, {
      params: {
        id_coll: collectivityId,
        id_pdl: pdlId,
      },
    });
  }
}
