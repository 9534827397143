/** @jsxImportSource @emotion/react */
import React, { PropsWithChildren } from 'react';
import { css } from '@emotion/react/macro';

const flexCss = {
  self: css({
    display: 'flex',
    alignItems: 'center',
  }),
};

type FlexProps = PropsWithChildren<{
  gap?: number;
}>;

function Flex(props: FlexProps) {
  const { gap = 8 } = props;

  return (
    <div css={flexCss.self} style={{ gap }}>
      {props.children}
    </div>
  );
}

export default Flex;
