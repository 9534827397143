import React, { useCallback, useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';
import Button from '../Button/Button';

type PaginationProps = {
  currentPage: number;
  nbrPages: number;
  handleChangePage?: (pageSelected: number) => void;
};

function Pagination(props: PaginationProps) {
  const { currentPage: currentPageProp, nbrPages, handleChangePage } = props;
  const { t } = useTranslation('common');
  const [currentPage, setCurrentPage] = useQueryParam('page', withDefault(NumberParam, 1));

  useEffect(() => {
    if (currentPageProp !== currentPage) setCurrentPage(currentPageProp);
  }, [currentPageProp, currentPage]);

  // Handle page query params upper max page
  useEffect(() => {
    if (currentPage > nbrPages) setCurrentPage(nbrPages);
  }, [nbrPages, currentPage]);

  const onChange = useCallback((pageSelected: number) => {
    setCurrentPage(pageSelected);
    handleChangePage?.(pageSelected);
  }, []);

  const middleButtons = useMemo(() => {
    const leftPages =
      nbrPages >= 3
        ? currentPage > 1
          ? currentPage < nbrPages
            ? [currentPage - 1, currentPage, currentPage + 1]
            : [currentPage - 2, currentPage - 1, currentPage]
          : [1, 2, 3]
        : nbrPages === 2
        ? [1, 2]
        : [1];

    const rightPages =
      nbrPages >= 4
        ? currentPage < nbrPages - 2
          ? [nbrPages - 1, nbrPages]
          : currentPage < nbrPages - 1
          ? [nbrPages]
          : []
        : [];

    return (
      <>
        {leftPages.map((page) => (
          <Button
            key={'page-'.concat(page.toString())}
            data-testid={'button-page-'.concat(page.toString())}
            onClick={() => onChange(page)}
            color={page === currentPage ? 'primary' : 'secondary'}
            label={page.toString()}
          />
        ))}
        {currentPage < nbrPages - 3 && nbrPages > 3 && <Button color={'secondary'} label={'...'} disabled />}
        {rightPages.map((page) => (
          <Button
            key={'page-'.concat(page.toString())}
            onClick={() => onChange(page)}
            color={page === currentPage ? 'primary' : 'secondary'}
            label={page.toString()}
          />
        ))}
      </>
    );
  }, [currentPage, nbrPages]);

  return currentPage > 0 && currentPage <= nbrPages ? (
    <PaginationRoot>
      {currentPage > 1 && (
        <Button
          data-testid={'button-page-previous'}
          onClick={() => onChange(currentPage - 1)}
          color={'secondary'}
          label={t('pagination.previous')}
        />
      )}
      {middleButtons}
      {currentPage !== nbrPages && (
        <Button
          data-testid={'button-page-next'}
          onClick={() => onChange(currentPage + 1)}
          color={'secondary'}
          label={t('pagination.next')}
        />
      )}
    </PaginationRoot>
  ) : null;
}

const PaginationRoot = styled.div`
  display: flex;
  gap: 8px;
`;

export default Pagination;
