import { selectCollAndDeliveryPointID } from 'auth/selectors/authSelectors';
import DashboardCard from 'dashbaord/components/DashboardCard';
import { DASHBOARD_ENDPOINT } from 'dashbaord/constants/endpoints';
import { formatRawDashboard } from 'dashbaord/services/dahboardService';
import { DashboardItemType } from 'dashbaord/types/dashboard';
import useApi, { Methods } from 'internal/hooks/useApi';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

const Dashboard = (): ReactElement => {
  const [dashboard, saveDashboard] = useState<DashboardItemType[]>([]);

  const { request, response } = useApi<Record<string, number>>(Methods.GET, DASHBOARD_ENDPOINT);
  const { id_coll, id_pdl } = useSelector(selectCollAndDeliveryPointID);

  useEffect(() => {
    if (id_coll && id_pdl) {
      request({
        params: {
          id_coll,
          id_pdl,
        },
      });
    }
  }, [id_pdl]);

  useEffect(() => {
    if (response?.success && response.data) {
      saveDashboard(formatRawDashboard(response.data));
    }
  }, [response]);

  const renderDashboard = useMemo(
    () => dashboard.map((item) => <DashboardCard key={item.id} item={item} />),
    [dashboard]
  );

  return (
    <div className={'main-content'}>
      {/*<MainTitle>test</MainTitle>*/}
      <div className={'row'}>{renderDashboard}</div>
    </div>
  );
};

export default Dashboard;
