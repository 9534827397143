import { SERVICE_KEYS } from 'ioc/keys';
import { inject, injectable } from 'inversify';
import { AxiosResponse } from 'axios';
import { SHA256 } from 'crypto-js';
import { Api } from '../api/axios';
import { UserType } from '../auth/types/user';
import { PWD_SEED } from '../internal/constants/api';

const ENDPOINTS = {
  signIn: 'user/signin',
};

export type SignInRequestParams = {
  email: string;
  password: string;
};

@injectable()
export class AuthRepository {
  @inject(SERVICE_KEYS.API_INSTANCE)
  private _apiInstance: Api | undefined;

  signIn(params: SignInRequestParams): Promise<AxiosResponse<UserType>> | undefined {
    const { email, password } = params;
    return this._apiInstance?.instance.post(ENDPOINTS.signIn, {
      email,
      password: SHA256(password + PWD_SEED).toString(),
    });
  }
}
