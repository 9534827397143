import {
  SaveCollectivityActionType,
  SaveDeliveryPointActionType,
  SaveDeliveryPointListActionType,
  SaveUserActionType,
  SaveUserCollectivityActionType,
  SaveUserRightsActionType,
} from 'auth/types/actions';
import { DeliveryPointType, UserCollectivityType } from 'auth/types/collectivity';
import { UserRightsType, UserType } from 'auth/types/user';

export const SAVE_USER = 'SAVE_USER';
export const SAVE_COLLECTIVITY = 'SAVE_COLLECTIVITY';
export const SAVE_DELIVERY_POINT = 'SAVE_DELIVERY_POINT';
export const SAVE_USER_RIGHTS = 'SAVE_USER_RIGHTS';
export const SAVE_COLLECTIVITY_LIST = 'SAVE_COLLECTIVITY_LIST';
export const SAVE_DELIVERY_POINT_LIST = 'SAVE_DELIVERY_POINT_LIST';

export const saveUser = (user: UserType | null): SaveUserActionType => ({
  type: SAVE_USER,
  payload: {
    user,
  },
});

export const saveCollectivity = (collectivity: number): SaveCollectivityActionType => ({
  type: SAVE_COLLECTIVITY,
  payload: {
    collectivity,
  },
});

export const saveDeliveryPoint = (deliveryPoint: number): SaveDeliveryPointActionType => ({
  type: SAVE_DELIVERY_POINT,
  payload: {
    deliveryPoint,
  },
});

export const saveUserRights = (userRights: UserRightsType | null): SaveUserRightsActionType => ({
  type: SAVE_USER_RIGHTS,
  payload: {
    userRights,
  },
});

export const saveCollectivityList = (collectivityList: UserCollectivityType[]): SaveUserCollectivityActionType => ({
  type: SAVE_COLLECTIVITY_LIST,
  payload: {
    collectivityList,
  },
});

export const savePdlList = (deliveryPointList: DeliveryPointType[]): SaveDeliveryPointListActionType => ({
  type: SAVE_DELIVERY_POINT_LIST,
  payload: {
    deliveryPointList,
  },
});
