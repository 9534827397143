import { useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { withTheme } from '../../Parameters/Deposit/List/DepositList';
import FormGroupCard from '../../../components/FormGroupCard/FormGroupCard';
import Button from '../../../components/Core/Button/Button';
import { useSnackbar } from '../../../context/SnackbarContext';
import { selectCollAndDeliveryPointID } from '../../../auth/selectors/authSelectors';
import useBuildForm from '../../../hook/useBuildForm';
import { TOAST_SEVERITY } from '../../../components/Core/Toast/Toast';
import useLocalesParametersApi from './useLocalesParametersApi';
import updateLocaleParametersForm from './updateLocaleParametersForm';

function LocalesParametersPage() {
  const { t } = useTranslation(['admin/localesParameters', 'form']);
  const snackbar = useSnackbar();
  const { fetchLocalesParameters, putLocalesParameters } = useLocalesParametersApi();
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const queryClient = useQueryClient();
  const [localeParameters, setLocaleParameters] = useState<
    { fallback_locale: string; available_locales: string[] | string } | undefined
  >(undefined);
  const { builtFormFields, handleSubmit } = useBuildForm({
    formObject: updateLocaleParametersForm(t),
    colsNumber: 3,
    defaultValues: localeParameters,
  });

  const onMount = useCallback(async () => {
    const response = await fetchLocalesParameters({ id_coll });
    if (response?.status === 200) {
      setLocaleParameters({
        fallback_locale: response.data.defaultLanguage,
        available_locales: response.data.activeLanguages || navigator.language,
      });
    }
  }, [id_coll]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const onSubmit = useCallback(
    handleSubmit(async (data) => {
      const response = await putLocalesParameters({
        id_coll,
        defaultLanguage: data.fallback_locale,
        activeLanguages: data.available_locales,
      });

      if (response?.status === 200) {
        queryClient.invalidateQueries(['param/pages']);
        snackbar?.setAlert({ message: t('form:global_update_success_message'), severity: TOAST_SEVERITY.success });
      }
    }),
    [id_coll]
  );

  return (
    <MainSection>
      <FormGroupCard
        title={null}
        WrapperComponent={({ children }) => <form onSubmit={onSubmit}>{children}</form>}
        FooterSlotComponent={({ defaultWrapper: DefaultWrapper }) => (
          <DefaultWrapper>
            <Button type={'submit'} label={t('form:global_update_label')} />
          </DefaultWrapper>
        )}>
        {builtFormFields}
      </FormGroupCard>
    </MainSection>
  );
}

const MainSection = styled.section`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export default withTheme(LocalesParametersPage);
