/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useCallback } from 'react';
import { Control, FieldValues, UseFormReset, useFormState, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteVoucher, DeleteVoucherQueryParams, postVoucher } from '../../../../api/params/vouchers.api';
import { selectCollAndDeliveryPointID } from '../../../../auth/selectors/authSelectors';
import IconButton from '../../../../components/Core/Button/IconButton';
import { GridValidRowModel } from '../../../../components/Core/Datagrid/gridRows';
import { TOAST_SEVERITY } from '../../../../components/Core/Toast/Toast';
import { useSnackbar } from '../../../../context/SnackbarContext';

type VoucherListActionCellProps = {
  control: Control;
  reset?: UseFormReset<FieldValues>;
  row: GridValidRowModel;
  handleDelete?: (depotId: number) => void;
};

function VoucherListActionCell(props: VoucherListActionCellProps) {
  const { t } = useTranslation('parameters/voucherList');
  const { articleType } = useParams();
  const { row, control, reset } = props;
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const { isDirty, dirtyFields } = useFormState({ control });
  const values = useWatch({ control });

  const isDisabled = !isDirty || Object.keys(dirtyFields).length === 0;

  const { mutate: handlePostVoucher } = useMutation(
    ['voucher.put'],
    //eslint-disable-next-line
    (data: any) => {
      return postVoucher(data);
    },
    {
      onSuccess: () => {
        snackbar?.setAlert({ message: t('updateSuccessMessage'), severity: TOAST_SEVERITY.success });
        reset?.([], { keepValues: true });
      },
    }
  );

  const { mutate: handleDeleteVoucher } = useMutation(
    ['voucher.delete'],
    (data: DeleteVoucherQueryParams) => {
      return deleteVoucher(data);
    },
    {
      onSuccess: (successData, variables) => {
        queryClient.setQueriesData(['voucher.list', { id_coll }], (oldData) => {
          if (articleType && oldData) {
            //eslint-disable-next-line
            const data = (oldData as { data: Record<string, any[]> }).data;
            const items = [...data[articleType]];
            const deleteItemIndex = items.findIndex((item) => item.pjId === variables.pjId);
            items.splice(deleteItemIndex, 1);

            return {
              //eslint-disable-next-line
              ...(oldData as any),
              data: { ...data, [articleType]: items },
            };
          }

          return oldData;
        });
        snackbar?.setAlert({ message: t('deleteSuccessMessage'), severity: TOAST_SEVERITY.success });
      },
    }
  );

  const handleUpdate = useCallback(async () => {
    const { pjName, isRequired, articles } = values;
    handlePostVoucher({
      id_coll,
      pjId: row.pjId,
      pj: {
        pjName,
        isRequired,
        category: articleType || 'velo',
      },
      articles,
    });
  }, [values, row, reset]);

  return (
    <div
      key={`actions-${row.pjId}`}
      css={css`
        display: grid;
        grid-template-columns: repeat(2, auto);
        justify-content: flex-end;
        gap: 8px;
      `}>
      <IconButton disabled={isDisabled} icon={'RefreshIcon'} color={'action'} onClick={handleUpdate} />
      <IconButton
        icon={'TrashIcon'}
        color={'danger'}
        onClick={() => handleDeleteVoucher({ id_coll, pjId: row.pjId })}
      />
    </div>
  );
}

export default VoucherListActionCell;
