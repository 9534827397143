import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectCollAndDeliveryPointID } from '../../../auth/selectors/authSelectors';
import Card from '../../../components/Core/Card/Card';
import Datagrid, { Row, RowItem } from '../../../components/Core/Datagrid/Datagrid';
import { GridColDefinition } from '../../../components/Core/Datagrid/gridCols';
import InputBase from '../../../components/Core/InputBase/InputBase';
import { GridDataState } from '../../../utils/request';
import { RowProps } from '../../Parameters/Caution/CautionList';
import { withTheme } from '../../Parameters/Deposit/List/DepositList';
import ConsigneParkListActionCell from './ConsigneParkListActionCell';
import useConsigneParkListApi from './useConsigneParkListApi';

const NAMESPACE = 'park/genericList';

type RawRowResult = {
  id_consigne: number;
  nb_places: number;
  nom_empl: string;
  nom_type: string;
};

function useGetConsigneParkListColumnDefinitions() {
  const { t } = useTranslation(NAMESPACE, { keyPrefix: 'columns' });

  return useMemo<GridColDefinition[]>(() => {
    return [
      {
        type: 'string',
        field: 'nom_empl',
        headerName: t('nom_empl'),
      },
      {
        type: 'string',
        field: 'nom_type',
        headerName: t('nom_type'),
      },
      {
        type: 'formField',
        field: 'nb_places',
        headerName: t('nb_places'),
        // TODO: add name param in renderCell function (for Controller name and defaultValue)
        renderCell: ({ row, control }) => {
          return (
            <Controller
              control={control}
              name={'nb_places'}
              key={`input-nb_places-${row.id_consigne}`}
              defaultValue={row.nb_places}
              render={({ field: { onChange, value, name } }) => (
                <InputBase name={name} onChange={onChange} value={value} />
              )}
            />
          );
        },
      },
      {
        type: 'formField',
        field: '',
        headerName: '',
        renderCell: ({ control, row, reset }) => {
          return <ConsigneParkListActionCell control={control} row={row} reset={reset} />;
        },
      },
    ];
  }, [t]);
}

function ConsigneParkListRow({ columns, row, index, colsTemplate }: RowProps) {
  // TODO: add schema with number check on nb_places field ?
  const useFormReturn = useForm({ mode: 'onChange' });

  const { isDirty, dirtyFields } = useFormReturn.formState;

  const buildCell = useCallback(
    (column: GridColDefinition, index: number) => {
      if (column.type === 'formField') {
        return (
          <div
            key={'value-' + column.field + index}
            css={css`
              display: grid;
              align-items: center;
            `}>
            {column.renderCell({ row, ...useFormReturn, columnDef: column })}
          </div>
        );
      }

      return (
        <RowItem key={'value-' + column.field + index}>
          <span>{row[column.field]}</span>
        </RowItem>
      );
    },
    [row, isDirty, dirtyFields]
  );

  return (
    <Row key={'line'.concat(index.toString())} colsTemplate={colsTemplate}>
      {columns.map((column, index) => buildCell(column, index))}
    </Row>
  );
}

function ConsigneParkList() {
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const [loading, setLoading] = useState(false);
  const { fetchConsigneParkList } = useConsigneParkListApi();
  const [consigneParkList, setConsigneParkList] = useState<RawRowResult[]>([]);
  const columnDefinitions = useGetConsigneParkListColumnDefinitions();
  const [dataToForwardForGridData, setDataToForwardForGridData] = useState<GridDataState>({
    search: '',
    totalLines: 0,
    itemPerPage: 10,
    nbrPages: undefined,
    currentPage: 1,
  });

  const onMount = useMemo(
    () =>
      _.debounce(async (queryParams) => {
        const response = await fetchConsigneParkList({ id_coll, ...queryParams });

        if (response && response.status === 200) {
          setConsigneParkList(response.data.result);
          setDataToForwardForGridData((prevState) => ({
            ...prevState,
            totalLines: response.data.total,
            itemPerPage: response.data.itemPerPage,
            nbrPages: response.data.totalPage,
            currentPage: response.data.currentPage,
            order: response.data.order,
          }));
        }

        setLoading(false);
      }, 1000),
    [id_coll]
  );

  useEffect(() => {
    setLoading(true);
    onMount(dataToForwardForGridData);
  }, [onMount, dataToForwardForGridData.itemPerPage, dataToForwardForGridData.currentPage]);

  return (
    <MainSection className={'reset'}>
      <Card>
        <Datagrid
          loading={loading}
          rows={consigneParkList}
          columns={columnDefinitions}
          RowComponent={ConsigneParkListRow}
          handleChangePage={(newPage) =>
            setDataToForwardForGridData((prevState) => ({ ...prevState, currentPage: newPage }))
          }
          onChangeItemPerPage={(newValue) =>
            setDataToForwardForGridData((prevState) => ({ ...prevState, itemPerPage: newValue as number }))
          }
          {...(({ filters, ...propsToForward }) => propsToForward)(dataToForwardForGridData)}
        />
      </Card>
    </MainSection>
  );
}

const MainSection = styled.section`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export default withTheme(ConsigneParkList);
