import React, { useCallback, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useBooking } from 'pages/Reservation/Reservation.layout';
import { getDepositConfig, postReleaseDeposit, PostReleaseDepositBodyForm } from '../../../../api/booking.api';
import { selectCollAndDeliveryPointID } from '../../../../auth/selectors/authSelectors';
import { useConfirm } from '../../../../components/Confirm/ConfirmContext';
import ButtonWithIcon from '../../../../components/Core/Button/ButtonWithIcon';
import Card from '../../../../components/Core/Card/Card';
import styles from '../../bookingDetails.module.scss';
import { Line } from './BookingGenericInfosCard/BookingGenericInfosCard';
import { ARTICLE_TYPES } from './ManagementCard';

export const useRequiredParams = <T extends Record<string, unknown>>() => useParams() as T;

function BookingPaymentInfosCard() {
  const { t } = useTranslation(['reservation/booking']);
  const { bookingId } = useRequiredParams<{ bookingId: string }>();
  const { rawBooking, bookingStatus } = useBooking();
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const { confirm } = useConfirm();
  const queryClient = useQueryClient();

  const { data: sogecommerceConfigResponse } = useQuery(
    ['sogecommerce.config', { id_coll }],
    ({ queryKey }) => {
      const [, queryParams] = queryKey as [string, { id_coll: number }];
      return getDepositConfig(queryParams);
    },
    { refetchOnWindowFocus: false, staleTime: 260_000 }
  );

  const { mutate: handlePostReleaseDeposit } = useMutation(
    ['booking.releaseDeposit'],
    (data: PostReleaseDepositBodyForm) => {
      return postReleaseDeposit(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['booking']);
      },
    }
  );

  const lines = useMemo(() => {
    return [
      {
        i18nKey: 'bookingAmount',
        values: {
          amount: rawBooking?.montant_article_ttc || rawBooking?.total_ttc,
        },
      },
      {
        i18nKey: 'securityDepositAmount',
        values: {
          amount: rawBooking?.montant_depot_garantie_ttc,
        },
      },
      {
        i18nKey: 'depositAmount',
        values: {
          amount: rawBooking?.montant_caution_ttc,
        },
      },
      {
        i18nKey: 'priceType',
        values: {
          type: rawBooking?.nom_tarif,
        },
      },
      {
        i18nKey: 'cb_transac',
        values: {
          value: rawBooking?.cb_booking_transac_id,
        },
      },
      {
        i18nKey: 'sepa_deposit_transac',
        values: {
          value: rawBooking?.sepa_caution_transac_id,
        },
      },
      {
        i18nKey: 'rum',
        values: {
          rum: rawBooking?.rum,
        },
      },
      {
        i18nKey: 'sepa_transac',
        values: {
          value: rawBooking?.sepa_booking_transac_id,
        },
      },
      {
        i18nKey: 'cb_deposit_transac',
        values: {
          value: rawBooking?.cb_caution_transac_id,
        },
      },
    ];
  }, [rawBooking]);

  const canReleaseDeposit = useMemo(() => {
    const isBike = rawBooking?.article === ARTICLE_TYPES.bike;

    return (
      rawBooking !== undefined &&
      !rawBooking.date_rbt_caution &&
      isBike &&
      'sepa_caution_transac_id' in rawBooking &&
      'montant_caution_ttc' in rawBooking &&
      parseFloat(rawBooking.montant_caution_ttc.replace(/,/g, '')) !== 0 &&
      sogecommerceConfigResponse?.data.caution
    );
  }, [rawBooking, bookingStatus, sogecommerceConfigResponse]);

  const handleReleaseDeposit = useCallback(() => {
    confirm({
      title: t('releaseDeposit.modal.title'),
      content: (
        <p>
          <Trans t={t} i18nKey={'releaseDeposit.modal.description'} />
        </p>
      ),
    }).then((v) => {
      if (v) handlePostReleaseDeposit({ id_coll, id_resa: Number(bookingId), type: 'caution' });
    });
  }, [confirm, bookingId, id_coll, handlePostReleaseDeposit]);

  return (
    <Card
      className={['bookingCard', styles.BookingCard].join(' ')}
      title={t('paymentInfosCard.title')}
      content={
        <>
          {lines.map((line) => (
            <Line key={line.i18nKey} i18nKey={line.i18nKey} values={line.values} />
          ))}
          {canReleaseDeposit && (
            <div className={styles.PaymentInfosCardFooter}>
              <ButtonWithIcon color={'secondary'} icon={'PadlockIcon'} onClick={handleReleaseDeposit}>
                {t('releaseDeposit.cta')}
              </ButtonWithIcon>
            </div>
          )}
        </>
      }
    />
  );
}

export default BookingPaymentInfosCard;
