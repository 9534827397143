import { instance, ListQueryParams } from '../user.api';

const URL = {
  fetchList: 'parc/accessoires',
  putAffectation: 'parc/affaccessoire',
  putStatus: 'parc/etataccessoire',
};

export async function getAccessoryList(queryParams: ListQueryParams & { id_coll: number; id_pdl: number }) {
  return await instance.get<{
    currentPage: number;
    itemPerPage: number;
    // eslint-disable-next-line
    filters: any;
    // eslint-disable-next-line
    result: any[];
    total: number;
    totalPage: number;
    //eslint-disable-next-line
    order: any;
  }>(URL.fetchList, {
    params: queryParams,
  });
}

type PutAffectationBody = {
  id_coll: number;
  id_pdl: number;
  id_velo: number;
  id_aff: string;
};

export async function putAffectation(putBody: PutAffectationBody) {
  return await instance.post(URL.putAffectation, putBody);
}

type PutStatusBody = {
  id_coll: number;
  id_pdl: number;
  id_velo: number;
  id_etat: string;
};

export async function putStatus(putBody: PutStatusBody) {
  return await instance.post(URL.putStatus, putBody);
}
