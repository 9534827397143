import { instance, ListQueryParams } from '../user.api';

const URL = {
  fetchDegradationList: 'param/degradations',
  putDegradation: 'param/upddegradation',
  postDegradation: 'param/adddegradation',
};

//eslint-disable-next-line
export function postDegradation(data: any) {
  return instance.post(URL.postDegradation, data);
}

//eslint-disable-next-line
export function putDegradation(data: any) {
  return instance.post(URL.putDegradation, data);
}

export function fetchDegradationList(queryParams: ListQueryParams & { id_coll: number }) {
  return instance.get(URL.fetchDegradationList, {
    params: queryParams,
  });
}
