import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import { t } from 'internal/i18n';
import dayjs from 'dayjs';

registerLocale('fr', fr);

type Props = {
  handleChange?: (startDate: string, endDate: string) => void;
  defaultStartDate?: Date | null;
  defaultEndDate?: Date | null;
  noDefault?: boolean;
};

const DateRangePicker = ({
  handleChange = () => null,
  defaultEndDate = null,
  defaultStartDate = null,
  noDefault = false,
}: Props): ReactElement => {
  const [startDate, setStartDate] = useState<Date | null>();
  const [endDate, setEndDate] = useState<Date | null>();

  useEffect(() => {
    if (defaultStartDate) {
      setStartDate(defaultStartDate);
    }
    if (defaultEndDate) {
      setEndDate(defaultEndDate);
    }
  }, [defaultEndDate, defaultStartDate]);

  useEffect(() => {
    if (!defaultStartDate && !noDefault) {
      setStartDate(new Date());
    }
    if (!defaultEndDate && !noDefault) {
      setEndDate(new Date());
    }
  }, [noDefault]);

  useEffect(() => {
    if (startDate && endDate) {
      handleChange(dayjs(startDate).format('YYYY-MM-DD'), dayjs(endDate).format('YYYY-MM-DD'));
    }
  }, [startDate, endDate]);

  const handleChangeStartDate = useCallback((date: Date | null) => {
    if (date) {
      setStartDate(date);
    }
  }, []);

  const handleChangeEndDate = useCallback((date: Date | null) => {
    if (date) {
      setEndDate(date);
    }
  }, []);

  return (
    <>
      <div className={'col-md-6'}>
        <div className={'input-group'} data-provide={'datepicker'} data-language={'fr'}>
          <div className={'input-group-prepend'}>
            <span className={'input-group-text'}>{t('global.from')}</span>
          </div>
          <DatePicker
            className={'datepicker form-control'}
            selected={startDate}
            onChange={handleChangeStartDate}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            locale={'fr'}
            dateFormat={'dd/MM/yyyy'}
          />

          <div className={'input-group-prepend input-group-append'}>
            <span className={'input-group-text'}>{t('global.to')}</span>
          </div>
          <DatePicker
            className={'datepicker form-control'}
            selected={endDate}
            onChange={handleChangeEndDate}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            locale={'fr'}
            dateFormat={'dd/MM/yyyy'}
          />
        </div>
      </div>
    </>
  );
};

export default DateRangePicker;
