/** @jsxImportSource @emotion/react */
import { TFunction } from 'i18next';
import React, { useCallback } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Joi from 'joi';
import Card from '../../../components/Core/Card/Card';
import useBuildForm, { FormObject } from '../../../hook/useBuildForm';
import Button from '../../../components/Core/Button/Button';
import { useInjection } from '../../../ioc/ioc.react';
import { ParameterRepository } from '../../../repository/parameter.repository';
import { SERVICE_KEYS } from '../../../ioc/keys';
import { selectCollAndDeliveryPointID } from '../../../auth/selectors/authSelectors';
import { useSnackbar } from '../../../context/SnackbarContext';
import { TOAST_SEVERITY } from '../../../components/Core/Toast/Toast';
import { withTheme } from './List/DepositList';
import { getAmountExcludingTaxes, getAmountIncludingTaxes } from './List/utils';

function getCreateDepositForm(t: TFunction): FormObject {
  return [
    {
      colsNumber: 2,
      fields: [
        {
          name: 'depotName',
          label: t('name'),
          placeholder: t('name'),
          type: 'text',
        },
        {
          name: 'montant_ht',
          label: t('amount_excluding_taxes'),
          placeholder: t('amount_excluding_taxes'),
          type: 'text',
          columnStart: '1',
          onChange: (newValue, getValues, setValue) =>
            setValue?.('montant_ttc', getAmountIncludingTaxes(Number(newValue), getValues?.('taux_tva'))),
        },
        {
          type: 'select',
          name: 'taux_tva',
          label: t('vat_rate'),
          placeholder: t('vat_rate'),
          defaultValue: 5.5,
          onChange: (newValue, getValues, setValue) => {
            const amountExcludingTaxes = Number(getValues?.('montant_ht'));
            amountExcludingTaxes &&
              setValue?.('montant_ttc', getAmountIncludingTaxes(amountExcludingTaxes, getValues?.('taux_tva')));
          },
          options: [
            {
              value: 5.5,
              label: '5.5%',
            },
            { value: 10, label: '10%' },
            { value: 20, label: '20%' },
          ],
          schema: Joi.number(),
        },
        {
          name: 'montant_ttc',
          label: t('amount_including_taxes'),
          placeholder: t('amount_including_taxes'),
          type: 'text',
          onChange: (newValue, getValues, setValue) =>
            setValue?.('montant_ht', getAmountExcludingTaxes(Number(newValue), getValues?.('taux_tva'))),
        },
      ],
    },
  ];
}

function CreateDeposit() {
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const { t } = useTranslation(['deposit/form', 'deposit/createPage', 'translation']);
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const { builtFormFields, handleSubmit } = useBuildForm({
    formObject: getCreateDepositForm(t),
  });
  const parameterRepository = useInjection<ParameterRepository>(SERVICE_KEYS.PARAMETER_REPOSITORY);

  const onSubmit = useCallback(
    handleSubmit(async (data) => {
      try {
        const response = await parameterRepository.postDeposit({
          id_coll,
          depot: {
            article: 'velo',
            taux_tva: data.taux_tva,
            depotName: data.depotName,
            montant_ht: data.montant_ht,
          },
        });

        if (response?.status === 200) {
          snackbar?.setAlert({ message: t('deposit/createPage:success_message'), severity: TOAST_SEVERITY.success });
          navigate('/parametres/depot-de-garantie');
        }
      } catch (error: unknown) {
        console.error(error);
        if (axios.isAxiosError(error)) {
          const messageCode = error.response?.data.code;
          snackbar?.setAlert({ message: t('translation:'.concat(messageCode)), severity: TOAST_SEVERITY.error });
        }
      }
    }),
    [id_coll]
  );

  return (
    <section
      css={css`
        padding: 16px;
        height: calc(100% - 64px);
      `}>
      <Card>
        <form onSubmit={onSubmit}>
          {builtFormFields}
          <div
            css={css`
              display: flex;
              justify-content: flex-end;
              margin-top: 16px;
            `}>
            <Button type={'submit'} label={t('deposit/createPage:cta')} />
          </div>
        </form>
      </Card>
    </section>
  );
}

export default withTheme(CreateDeposit);
