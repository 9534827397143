import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import Button from 'fragments/button/Button';
import useApi, { Methods } from 'internal/hooks/useApi';
import { selectCollAndDeliveryPointID, selectSessionToken } from 'auth/selectors/authSelectors';
import DateRangePicker from 'fund/components/DateRangePicker';
import FundTable from 'fund/components/FundTable';
import { DAILY_FUND_ENDPOINT, EXPORT_FUND_ENDPOINT } from 'fund/constants/endpoints';
import { FundElementType } from 'fund/types/fund';
import ExportDialog from 'fund/components/ExportDialog';
import { getMimeType } from 'internal/utils/file';
import { useTranslation } from 'react-i18next';

const DailyFund = (): ReactElement => {
  const { t } = useTranslation('fund');
  const { id_coll, id_pdl } = useSelector(selectCollAndDeliveryPointID);
  const token = useSelector(selectSessionToken);
  const [modalOpen, setModalOpen] = useState(false);
  const [date_start, setStartDate] = useState('');
  const [date_end, setEndDate] = useState('');
  const [formatFile, setFormatFile] = useState('');
  const [buffer, setBuffer] = useState<Blob | null>();

  const { request: fetchFund, response: fundResponse } = useApi<
    {
      postes: { [key: string]: FundElementType };
    } & {
      [key: string]: FundElementType;
    }
  >(Methods.GET, DAILY_FUND_ENDPOINT);
  const { request: fetchExport, response: exportResponse } = useApi<Blob>(Methods.GET, EXPORT_FUND_ENDPOINT);

  useEffect(() => {
    fetchFund({
      params: {
        id_coll,
        id_pdl,
        date_start: dayjs().format('YYYY-MM-DD'),
        date_end: dayjs().format('YYYY-MM-DD'),
      },
    });
  }, [id_pdl, id_coll]);

  useEffect(() => {
    if (exportResponse?.success && exportResponse?.data) {
      setBuffer(exportResponse.data);
    }
  }, [exportResponse?.success && exportResponse?.data]);

  useEffect(() => {
    if (buffer && formatFile) {
      const filename = `exports_daily_funds_${dayjs().format('DD/MM/YYYY')}.${formatFile}`;
      const url = window.URL.createObjectURL(new Blob([buffer], { type: getMimeType(formatFile) }));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      setBuffer(null);
    }
  }, [buffer, formatFile]);

  const submitDateRange = useCallback(() => {
    fetchFund({
      params: {
        id_coll,
        id_pdl,
        date_start,
        date_end,
      },
    });
  }, [id_pdl, id_coll, date_start, date_end]);

  const fund = useMemo(() => {
    if (fundResponse?.success && fundResponse.data) {
      const { postes, ...totaux } = fundResponse.data;
      return { ...postes, ...totaux };
    }
    return undefined;
  }, [fundResponse]);

  const handleChangeDateRange = useCallback((startDate: string, endDate: string) => {
    setStartDate(startDate);
    setEndDate(endDate);
  }, []);

  const handleModalOpen = useCallback(() => {
    setModalOpen(true);
  }, [setModalOpen]);

  const handleModalClose = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  const handleModalValidate = useCallback(
    (format: string) => () => {
      setFormatFile(format);

      fetchExport({
        responseType: 'arraybuffer',
        params: {
          id_coll,
          id_pdl,
          format,
          token,
          date_start,
          date_end,
        },
      });
      handleModalClose();
    },
    [handleModalClose, id_coll, id_pdl, token, date_start, date_end, dayjs]
  );

  const renderDateRange = useMemo(() => {
    if (handleChangeDateRange) {
      return <DateRangePicker handleChange={handleChangeDateRange} />;
    }

    return null;
  }, []);

  return (
    <div className={'main-content'}>
      <div className={'card'}>
        <div className={'card-body'}>
          <div className={'row'}>
            {renderDateRange}
            <div className={'col-md-6'}>
              <Button
                label={t('see_data')}
                onClick={submitDateRange}
                className={'btn btn-margin btn-w-xl btn-primary'}
              />
              <button onClick={handleModalOpen} className={'btn btn-margin-left btn-square btn-outline btn-primary'}>
                <i className={'ti-download'} />
              </button>
            </div>
          </div>
          <br />
          <div className={'row'}>
            <div className={'col-md-12'}>
              <FundTable fund={fund} />
            </div>
          </div>
        </div>
      </div>
      <ExportDialog open={modalOpen} onClose={handleModalClose} onValidate={handleModalValidate} />
    </div>
  );
};

export default DailyFund;
