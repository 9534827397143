import { useCallback, useEffect, useRef } from 'react';

const useRefresh = (onRefresh: () => void, delay: number): (() => void) => {
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (timer.current) {
      return () => {
        clearTimeout(timer.current as NodeJS.Timeout);
      };
    }
  }, [timer]);

  return useCallback(() => {
    timer.current = setTimeout(() => {
      onRefresh();
    }, delay);
  }, [timer.current, onRefresh]);
};

export default useRefresh;
