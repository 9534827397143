import cx from 'classnames';
import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  item: string;
  colHandler?: ((sortType: string, item: string) => void) | null;
  traduction?: string;
};

const DataTableHeadCell = ({ item, colHandler, traduction = '' }: Props): ReactElement => {
  const { t } = useTranslation(traduction);
  const [sortDescendingState, setSortDescendingState] = useState(false);

  const handleArrowButtonClick = useCallback(() => {
    setSortDescendingState((prevState) => !prevState);
    if (colHandler) {
      const sortType = sortDescendingState ? 'desc' : 'asc';

      colHandler(sortType, item);
    }
  }, [sortDescendingState, colHandler, item]);

  const ascendingArrowClasses = useMemo(
    () =>
      cx('arrow-up fa fa-long-arrow-up', {
        ascending: sortDescendingState,
        descending: !sortDescendingState,
      }),
    [sortDescendingState]
  );

  const descendingArrowClasses = useMemo(
    () =>
      cx('arrow-down fa fa-long-arrow-down', {
        ascending: sortDescendingState,
        descending: !sortDescendingState,
      }),
    [sortDescendingState]
  );

  const renderArrows = useMemo(() => {
    if (colHandler) {
      return (
        <div className={'arrow-buttons'} onClick={handleArrowButtonClick}>
          <i className={ascendingArrowClasses} />
          <i className={descendingArrowClasses} />
        </div>
      );
    }
    return null;
  }, [colHandler, ascendingArrowClasses, descendingArrowClasses, handleArrowButtonClick]);

  return (
    <th className={'sorting_desc'} tabIndex={0}>
      <span>{traduction ? t(`columns.${item}`) : item}</span>
      {renderArrows}
    </th>
  );
};

export default DataTableHeadCell;
