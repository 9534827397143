import React, { ChangeEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import RadioButton from '../../../../components/Core/RadioButton/RadioButton';
import Grid from '../../../../components/Core/Grid/Grid';

type ToggleRadioGroupProps = {
  onChange?: (value: boolean) => void;
  label?: string;
  value: boolean;
  name: string;
  style?: 'column' | 'row';
};

function ToggleRadioGroup(props: ToggleRadioGroupProps) {
  const { style = 'row' } = props;
  const { t } = useTranslation('form');

  const onChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value;

    props.onChange?.(Number(value) !== 0);
  };

  return (
    /*<div>
      {props.label && <Label>{props.label}</Label>}*/
    <Grid columns={style === 'row' ? 'auto auto' : '1fr'} gap={style === 'row' ? 16 : 4}>
      <RadioButton checked={props.value} name={props.name} value={1} label={t('yes')} onChange={onChange} />
      <RadioButton checked={!props.value} name={props.name} value={0} label={t('no')} onChange={onChange} />
    </Grid>
    // </div>
  );
}

export default ToggleRadioGroup;
