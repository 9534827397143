/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';
import { AxiosResponse } from 'axios';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryFunction } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { NavLink, useOutletContext, useParams } from 'react-router-dom';
import _ from 'lodash';
import { RawGenericListReturn } from '../../../api/params/vouchers.api';
import { fetchUserBookings } from '../../../api/user.api';
import { selectCollAndDeliveryPointID } from '../../../auth/selectors/authSelectors';
import Button from '../../../components/Core/Button/Button';
import { renderCell, Row, RowItem } from '../../../components/Core/Datagrid/Datagrid';
import { GridColDefinition } from '../../../components/Core/Datagrid/gridCols';
import MainSection from '../../../components/Core/MainSection/MainSection';
import Tag, { TagTypes } from '../../../components/Core/Tag/Tag';
import { ChevronIcon } from '../../../const/icons';
import { BIKE, RESA_CREATION, USERS } from '../../../internal/constants/routes';
import { generatePath } from '../../../utils/router';
import { useThemeUtils } from '../../../utils/style';
import { withTheme } from '../../Parameters/Deposit/List/DepositList';
import GenericList from '../../Parameters/Voucher/components/GenericList';
import { ListParams } from '../../park/BikeList/BikeList';

const mapTagTypeByBookingStatus = new Map<string, TagTypes>([
  ['file_attente', 'secondary'],
  ['attente', 'primary'],
  ['annulee', 'error'],
  ['validee', 'success'],
  ['terminee', 'plain-primary'],
]);

function useBookingColumns() {
  const { t } = useTranslation(['contracts/bookingList']);
  const { getColor } = useThemeUtils();

  const getColumnName = useCallback((name: string) => t(`columns.${name}`), [t]);

  return useMemo<GridColDefinition[]>(() => {
    return [
      { headerName: getColumnName('date_start'), type: 'date', field: 'date_start', sortable: true },
      { headerName: getColumnName('date_end'), type: 'date', field: 'date_end', sortable: true },
      {
        headerName: getColumnName('price'),
        type: 'custom',
        field: '',
        renderCell: ({ row }) => {
          const details =
            'montant_depot_garantie_ttc' in row
              ? ` (${t('deposit')} ${row.montant_depot_garantie_ttc.concat(row.devise)} + ${t(
                  'booking'
                )} ${row.montant_article_ttc.concat(row.devise)})`
              : '';
          return (
            <RowItem>
              {row.total_ttc}
              {row.devise}
              {details}
            </RowItem>
          );
        },
      },
      { headerName: getColumnName('user_name'), type: 'string', field: 'nomprenom', sortable: true },
      {
        headerName: getColumnName('booking'),
        type: 'custom',
        field: 'article',
        renderCell: ({ row }) => {
          return (
            <RowItem>
              {row.statut === 'file_attente' ? (
                <>
                  {row.article}
                  <br />
                  {`${t('position')} ${row.queue_position}`}
                </>
              ) : (
                row.article
              )}
            </RowItem>
          );
        },
      },
      {
        headerName: getColumnName('location'),
        type: 'custom',
        field: 'nom_pdl',
        renderCell: ({ row }) => {
          return <RowItem>{'nom_pdl' in row && row.id_pdl ? row.nom_pdl : row.nom_empl}</RowItem>;
        },
      },
      {
        headerName: getColumnName('status'),
        type: 'custom',
        renderCell: ({ row }) => {
          return (
            <div>
              {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
              <Tag type={mapTagTypeByBookingStatus.get(row.statut)!}>{t(`options.${row.statut}`)}</Tag>
            </div>
          );
        },
        field: 'statut',
      },
      {
        headerName: '',
        field: '',
        type: 'custom',
        width: 40,
        renderCell: () => {
          return (
            <RowItem
              css={css`
                display: flex;
                justify-content: flex-end;
              `}>
              <ChevronIcon
                width={14}
                height={14}
                css={css`
                  color: ${getColor('gray.300')};
                `}
              />
            </RowItem>
          );
        },
      },
    ];
  }, [t, getColor, getColumnName]);
}

function ReservationList() {
  //eslint-disable-next-line
  const { userData } = useOutletContext<{ userData: AxiosResponse<any> }>();
  const { t } = useTranslation(['contracts/bookingList']);
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const { userId } = useParams();
  const columns = useBookingColumns();

  const fetchBookings: QueryFunction<AxiosResponse<RawGenericListReturn> | undefined> = ({ queryKey }) => {
    const [, requestParams, listParams] = queryKey as [string, { id_coll: number; id_user?: number }, ListParams];

    if (requestParams.id_user) {
      return fetchUserBookings({
        id_coll: requestParams.id_coll,
        id_user: requestParams.id_user,
        ipp: listParams.itemPerPage,
        p: listParams.currentPage,
        ...listParams.filters,
      });
    }
  };

  /*const linkUser = useMemo(() => {
    if (user?.valid_bo) {
      return `${USERS}${EDIT}?userID=${user?.id_user}`;
    }

    return `${USERS}${VALIDATION}?userID=${user?.id_user}`;
  }, [user?.id_user]);*/

  const goToNewResa = useMemo(() => {
    return `${USERS}${RESA_CREATION}${BIKE}?userID=${userId}`;
  }, [userId]);

  return (
    <MainSection className={'reset'}>
      {userData.data && !_.isString(userData.data.date_anonymization) && (
        <div
          css={css`
            display: flex;
            justify-content: flex-end;
          `}>
          <Button as={NavLink} to={goToNewResa} label={t('cta_users')} />
        </div>
      )}
      <GenericList
        title={t('title')}
        translationNamespace={'contracts/bookingList'}
        RowComponent={({ row, columns, colsTemplate, index }) => (
          <Row
            colsTemplate={colsTemplate}
            key={'line'.concat(index.toString())}
            to={generatePath('home.invoicing.reservationDetails', {
              params: { bookingId: row.id_reservation, userId: row.id_user },
            })}
            as={NavLink}>
            {columns.map((column, index) => renderCell(column, row, index))}
          </Row>
        )}
        queryKey={['user.booking.list', { id_coll, id_user: userId }]}
        queryFn={fetchBookings}
        columns={columns}
      />
    </MainSection>
  );
}

export default withTheme(ReservationList);
