import ButtonWithIcon from 'components/Core/Button/ButtonWithIcon';
import React, { Fragment, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useFetcher } from 'react-router-dom';
import { DevTool } from '@hookform/devtools';
import Dialog, { DialogActions, DialogContent, DialogTitle } from '../../../components/Dialog/Dialog';
import TextFieldControlled from '../../../components/Form/ControlledFields/TextFieldControlled';
import Label from '../../../components/Core/Label/Label';
import ConnectForm from '../../../components/Form/ConnectForm';
import { RadioItem } from '../../Reservation/components/BookingDetails/FinishCard';
import { TOAST_SEVERITY } from '../../../components/Core/Toast/Toast';
import { useSnackbar } from '../../../context/SnackbarContext';
import useDisplayError from '../../../hook/useDisplayError';

export function DurationForm() {
  const { t } = useTranslation(['parameters/priceList/durationList', 'form', 'common']);

  const durationTypeOptions = useMemo(() => {
    const typeT = (key: string) => t('fields.type.options.'.concat(key));
    return ['jour', 'mois', 'trimestre', 'annee'].map((durationValue) => ({
      label: typeT(durationValue),
      value: durationValue,
    }));
  }, [t]);

  return (
    <ConnectForm>
      {({ register }) => (
        <>
          <fieldset>
            <Label>{t('fields.name.label')}</Label>
            <TextFieldControlled placeholder={t('fields.name.placeholder')} {...register('name', { required: true })} />
          </fieldset>

          <fieldset>
            <Label>{t('fields.type.label')}</Label>
            <div className={'checkbox-group'}>
              {durationTypeOptions.map((option) => (
                <RadioItem
                  {...register('type', { required: true })}
                  label={option.label}
                  value={option.value as never}
                />
              ))}
            </div>
          </fieldset>

          <div className={'grid w-100 items-center gap-8'} style={{ gridTemplateColumns: '1fr 1fr' }}>
            <fieldset>
              <Label>{t('fields.min.label')}</Label>
              <TextFieldControlled
                type={'number'}
                placeholder={t('fields.min.placeholder')}
                {...register('min', { required: true, valueAsNumber: true })}
              />
            </fieldset>

            <fieldset>
              <Label>{t('fields.max.label')}</Label>
              <TextFieldControlled
                type={'number'}
                placeholder={t('fields.max.placeholder')}
                {...register('max', { required: true, valueAsNumber: true })}
              />
            </fieldset>
          </div>
        </>
      )}
    </ConnectForm>
  );
}

function NewDuration() {
  const fetcher = useFetcher();
  const { t } = useTranslation(['parameters/priceList/durationList', 'form', 'common']);
  const [layoutElement, setLayoutElement] = useState<Element | null>(null);
  const [open, setIsOpen] = useState(false);
  const snackbar = useSnackbar();
  useDisplayError(fetcher.data);
  const methods = useForm({ mode: 'onChange', defaultValues: { name: null, type: null, min: null, max: null } });
  const { reset } = methods;

  useLayoutEffect(() => {
    setLayoutElement(document.querySelector('#price-list-layout'));
  }, []);

  const onCancel = useCallback(() => {
    setIsOpen(false);
    reset();
  }, [reset, setIsOpen]);

  const onSubmit = (data: FieldValues) => {
    fetcher.submit({ action: 'new', ...data }, { method: 'post', encType: 'application/json' });
  };

  useEffect(() => {
    if (fetcher.data && fetcher.data.status === 200 && fetcher.data.config.url === 'duration/new') {
      snackbar?.setAlert({
        message: t('createSuccessfullyMessage'),
        severity: TOAST_SEVERITY.success,
      });
      onCancel();
    }
  }, [fetcher.data, onCancel]);

  return (
    layoutElement &&
    createPortal(
      <Fragment key={'create-price-type'}>
        <ButtonWithIcon onClick={() => setIsOpen(true)} icon={'AddWithoutCircle'} color={'major'}>
          {t('newCta')}
        </ButtonWithIcon>
        {open && (
          <Dialog style={{ minWidth: 600 }} onCancel={onCancel} open={open}>
            <DialogTitle>{t('newModal.title')}</DialogTitle>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <DialogContent className={'grid gap-8'}>
                  <span className={'block mb-4'}>{t('form:all_required')}</span>
                  <DurationForm />
                </DialogContent>
                <DialogActions>
                  <ButtonWithIcon onClick={onCancel} icon={'CrossWithoutCircle'} color={'secondary'} type={'button'}>
                    {t('common:cancel')}
                  </ButtonWithIcon>
                  <ButtonWithIcon icon={'CheckWithoutCircle'} type={'submit'}>
                    {t('newModal.submitCta')}
                  </ButtonWithIcon>
                </DialogActions>
              </form>

              <DevTool control={methods.control} />
            </FormProvider>
          </Dialog>
        )}
      </Fragment>,
      layoutElement
    )
  );
}

export default NewDuration;
