import React, { PropsWithChildren } from 'react';

function Stepper({ children }: PropsWithChildren<unknown>) {
  const childrenArray = React.Children.toArray(children);
  const steps = childrenArray.map((step, index) => {
    if (React.isValidElement(step))
      return React.cloneElement(step, {
        last: index + 1 === childrenArray.length,
        ...step.props,
      });
  });

  return <>{steps}</>;
}

export default Stepper;
