import useApi, { Methods } from 'internal/hooks/useApi';
import React, { useCallback, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { ContentState, EditorState } from 'draft-js';
import { useDispatch, useSelector } from 'react-redux';
import htmlToDraft from 'html-to-draftjs';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { CGV_EDITION_ENDPOINT } from '../../../parameters/constants/endpoints';
import { selectCollAndDeliveryPointID } from '../../../auth/selectors/authSelectors';
import { setSnackbar, showSnackbar } from '../../../internal/actions/appActions';
import SnackbarSeverity from '../../../internal/constants/snackbar';
import { useRequiredParams } from '../../Reservation/components/BookingDetails/BookingPaymentInfosCard';
import TextEditor from '../../../parameters/components/TextEditor';
import { useMainTitle } from '../../../components/Layout/MainTitleContext';

function ContractPage() {
  const { t } = useTranslation(['old', 'components/topBar']);
  const { contractType } = useRequiredParams<{ contractType: string }>();
  //eslint-disable-next-line
  const CGVResponse: any = useOutletContext();
  const [editors, setEditorsState] = useState<{
    [key: string]: EditorState;
  } | null>(null);
  const dispatch = useDispatch();
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const queryClient = useQueryClient();
  useMainTitle(t('components/topBar:pageTitles.param_contracts'));

  const { request: postCGV, response: postCGVResponse } = useApi(Methods.POST, CGV_EDITION_ENDPOINT);

  useEffect(() => {
    if (CGVResponse) {
      const editorsState = (Object.keys(CGVResponse.data) as Array<keyof typeof CGVResponse.data>).reduce(
        (acc, articleType) => {
          const value = CGVResponse.data[articleType];
          const blocksFromHTML = htmlToDraft(value as string);
          const contentState = ContentState.createFromBlockArray(
            blocksFromHTML?.contentBlocks,
            blocksFromHTML?.entityMap
          );

          return {
            ...acc,
            [articleType]: EditorState.createWithContent(contentState),
          };
        },
        {}
      );

      setEditorsState(editorsState);
    }
  }, [CGVResponse, contractType]);

  const changeEditorState = useCallback(
    (editorState: EditorState) => {
      setEditorsState((prevState) => ({
        ...prevState,
        [contractType]: editorState,
      }));
    },
    [contractType]
  );

  const onSubmit = useCallback(
    (markup: string, type: string) => {
      postCGV({
        id_coll,
        cgtxt: markup,
        cgt: type.replace('cg_', ''),
      });
    },
    [id_coll]
  );

  useEffect(() => {
    if (postCGVResponse?.success) {
      queryClient.invalidateQueries(['param/cgv']);
      dispatch(setSnackbar(t('parameters.modification_success'), SnackbarSeverity.SUCCESS));
      dispatch(showSnackbar());
    }
  }, [postCGVResponse, queryClient]);

  return (
    <div className={'main-content'}>
      {editors && (
        <div className={'card'}>
          <div className={'card-body'}>
            <p className={'lead'}>{t(`parameters.${contractType}`)}</p>
            <TextEditor
              type={contractType}
              editorState={editors[contractType]}
              setEditorState={changeEditorState}
              onSubmit={onSubmit}
              withImages
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default ContractPage;
