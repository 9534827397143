import React, { ReactElement, useMemo } from 'react';
import cx from 'classnames';
import { t } from 'internal/i18n';

type Props = {
  actualPage: number;
  threshold: number;
  maxItems: number;
  handleClickNext: () => void;
  handleClickPrev: () => void;
  handlePageClick: (page: number) => () => void;
  className?: string;
};

const Pagination = ({
  actualPage,
  threshold,
  maxItems,
  handleClickNext,
  handleClickPrev,
  handlePageClick,
}: Props): ReactElement => {
  const pages = useMemo(() => Math.ceil(maxItems / threshold), [maxItems, threshold]);

  const firstDynamicIndex = useMemo(
    () => (actualPage > 2 && actualPage + 3 < pages && actualPage) || (actualPage + 3 >= pages && pages - 3) || 2,
    [actualPage, pages]
  );
  const secondDynamicIndex = useMemo(
    () => (actualPage > 2 && actualPage + 2 < pages && actualPage + 1) || (actualPage + 2 >= pages && pages - 2) || 3,
    [actualPage, pages]
  );

  const renderPaginationStages = useMemo(() => {
    if (pages < 7) {
      return new Array(pages).fill(null).map((_, i) => {
        const key = `pagination_el_${i}`;
        return (
          <li
            className={cx('paginate_button page-item', {
              active: actualPage === i + 1,
            })}
            onClick={handlePageClick(i + 1)}
            key={key}
          >
            <span className={'page-link'}>{i + 1}</span>
          </li>
        );
      });
    }

    return (
      <>
        <li
          className={cx('paginate_button page-item', {
            active: actualPage === 1,
          })}
          onClick={handlePageClick(1)}
        >
          <span className={'page-link'}>1</span>
        </li>
        {actualPage >= 3 && <span className={'paginate_button page-item'}>...</span>}
        <li
          className={cx('paginate_button page-item', {
            active: actualPage === firstDynamicIndex,
          })}
          onClick={handlePageClick(firstDynamicIndex !== 2 ? firstDynamicIndex - 1 : firstDynamicIndex)}
        >
          <span className={'page-link'}>{firstDynamicIndex}</span>
        </li>
        <li
          className={cx('paginate_button page-item', {
            active: actualPage === secondDynamicIndex,
          })}
          onClick={handlePageClick(secondDynamicIndex)}
        >
          <span className={'page-link'}>{secondDynamicIndex}</span>
        </li>
        {actualPage <= pages - 3 && <span className={'paginate_button page-item'}>...</span>}
        <li
          className={cx('paginate_button page-item', {
            active: actualPage === pages - 1,
          })}
          onClick={handlePageClick(pages - 1)}
        >
          <span className={'page-link'}>{pages - 1}</span>
        </li>
        <li
          className={cx('paginate_button page-item', {
            active: actualPage === pages,
          })}
          onClick={handlePageClick(pages)}
        >
          <span className={'page-link'}>{pages}</span>
        </li>
      </>
    );
  }, [pages, handlePageClick, firstDynamicIndex, secondDynamicIndex, actualPage]);

  return (
    <ul className={'pagination'}>
      <li
        className={cx('paginate_button page-item previous', {
          disabled: actualPage === 1,
        })}
        id={'list_previous'}
      >
        <div role={'button'} tabIndex={0} onClick={handleClickPrev} className={'page-link'}>
          {t('global.prev')}
        </div>
      </li>
      {renderPaginationStages}
      <li
        className={cx('paginate_button page-item next', {
          disabled: pages === 1 || actualPage === pages,
        })}
        id={'list_next'}
      >
        <div role={'button'} tabIndex={0} onClick={handleClickNext} className={'page-link'}>
          {t('global.next')}
        </div>
      </li>
    </ul>
  );
};

export default Pagination;
