import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import { getPriceList } from '../../../api/params/caution.api';
import { selectCollAndDeliveryPointID } from '../../../auth/selectors/authSelectors';
import { Option } from '../../../hook/useBuildForm';

function usePriceOptions() {
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);

  const { data: getPriceListResponse } = useQuery(
    ['param/tarifsloc', { id_coll }],
    ({ queryKey }) => {
      const [, queryParams] = queryKey as [string, { id_coll: number }];
      return getPriceList(queryParams);
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 400_000,
    }
  );

  return useCallback(
    (article: string, transform?: (options: Option[]) => Option[]) => {
      const options =
        getPriceListResponse?.data
          .filter((priceType) => priceType.article === article)
          .map((priceType) => ({
            value: priceType.id_article,
            label: `${priceType.nom_tarif} - ${priceType.nom_type} - ${priceType.nom_duree}`,
          })) || [];

      return transform ? transform(options) : options;
    },
    [getPriceListResponse]
  );
}

export default usePriceOptions;
