/** @jsxImportSource @emotion/react */
import { AxiosResponse } from 'axios';
import { compact, flatten } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryFunction, QueryKey, useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { css } from '@emotion/react';
import { NavLink } from 'react-router-dom';
import { getCautionList, GetDepositListData, getPriceList } from '../../../api/params/caution.api';
import Button from '../../../components/Core/Button/Button';
import MainSection from '../../../components/Core/MainSection/MainSection';
import { generatePath } from '../../../utils/router';
import { ARTICLE_TYPES } from '../../Reservation/components/BookingDetails/ManagementCard';
import { withTheme } from '../Deposit/List/DepositList';
import { selectCollAndDeliveryPointID } from '../../../auth/selectors/authSelectors';
import { RawDepositType } from '../../../parameters/types/parameters';
import Card from '../../../components/Core/Card/Card';
import { Row, RowItem } from '../../../components/Core/Datagrid/Datagrid';
import { GridColDefinition } from '../../../components/Core/Datagrid/gridCols';
import InputBase from '../../../components/Core/InputBase/InputBase';
import { GridValidRowModel } from '../../../components/Core/Datagrid/gridRows';
import { getAmountExcludingTaxes, getAmountIncludingTaxes } from '../Deposit/List/utils';
import usePriceOptions from '../Deposit/UsePriceOptions';
import usePriceTypeSelect, { usePriceAvailableSelect } from '../Deposit/UsePriceTypeSelect';
import GenericList from '../Voucher/components/GenericList';
import CautionListActionCell from './CautionListActionCell';

const NAMESPACE = 'parameters/cautionList';

type UseColumnsParams = {
  queryFn: QueryFunction<AxiosResponse<GetDepositListData>>;
  queryKey: QueryKey;
};

function useGetCautionListColumnDefinitions({ queryFn, queryKey }: UseColumnsParams) {
  const { t } = useTranslation(NAMESPACE);
  const getPriceOptionsByArticle = usePriceOptions();
  const getPriceTypeSelectByArticle = usePriceTypeSelect(getPriceOptionsByArticle);
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);

  const { data: getDepositResponse } = useQuery(
    [...queryKey, { currentPage: 1, filters: {}, itemPerPage: 10 }],
    queryFn,
    {
      refetchOnWindowFocus: false,
      staleTime: 400_000,
    }
  );

  const { data: priceList } = useQuery(
    ['param/tarifsloc', { id_coll }],
    ({ queryKey }) => {
      const [, queryParams] = queryKey as [string, { id_coll: number }];
      return getPriceList(queryParams);
    },
    {
      select: (response) => response.data.filter((priceOption) => priceOption.article === ARTICLE_TYPES.bike),
      refetchOnWindowFocus: false,
      staleTime: 400_000,
    }
  );

  const optionsAlreadySelected = useMemo(() => {
    const options = Object.values(getDepositResponse?.data || {})[0].map((deposit) => {
      return deposit.articles;
    });
    return compact(flatten(options));
  }, [getDepositResponse]);

  const getAvailablePrices = useCallback(
    (pricesSelected: number[]) => {
      return (
        priceList
          ?.filter(
            (priceOption) =>
              !optionsAlreadySelected.includes(priceOption.id_article) ||
              pricesSelected?.includes(priceOption.id_article)
          )
          .map((priceOption) => ({
            label: `${priceOption.nom_tarif} - ${priceOption.nom_type} - ${priceOption.nom_duree}`,
            value: priceOption.id_article,
          })) || []
      );
    },
    [optionsAlreadySelected, priceList]
  );

  const getSelect = usePriceAvailableSelect(getAvailablePrices);

  return useMemo<GridColDefinition[]>(() => {
    return [
      {
        type: 'formField',
        field: 'cautionName',
        headerName: t('columns.nom_caution'),
        renderCell: ({ row, control }) => {
          return (
            <Controller
              control={control}
              name={'cautionName'}
              key={`input-name-${row.cautionId}`}
              defaultValue={row.cautionName}
              render={({ field: { onChange, value, name } }) => (
                <InputBase name={name} onChange={onChange} value={value} />
              )}
            />
          );
        },
      },
      {
        headerName: t('columns.prices'),
        field: 'articles',
        type: 'formField',
        width: 'minmax(400px, 2fr)',
        renderCell: ({ row, control }) => {
          const name = 'articles';
          // const selectNode = getPriceTypeSelectByArticle(row.article, control, name, row.articles);
          const selectNode = getSelect(row, control, name, row.articles);
          return React.cloneElement(selectNode, {
            id: name,
            key: `select-${row.cautionId}-${row.cautionName}`,
          });
        },
      },
      {
        type: 'formField',
        field: 'montant_ht',
        headerName: t('columns.montant_ht'),
        renderCell: ({ row, control, setValue }) => {
          const handleChange = useCallback(
            (
              onChange: (newValue: string | number | readonly string[] | undefined) => void,
              newValue: string | number | readonly string[] | undefined
            ) => {
              const amountExcludingTaxes = Number(newValue);
              setValue('montant_ttc', getAmountIncludingTaxes(amountExcludingTaxes, Number(row.taux_tva)));
              onChange(newValue);
            },
            [row]
          );

          return (
            <Controller
              control={control}
              name={'montant_ht'}
              defaultValue={Number(row.montant_ht).toFixed(4)}
              key={`input-price-${row.depotId}`}
              render={({ field: { onChange, value, name } }) => (
                <InputBase onChange={(newValue) => handleChange(onChange, newValue)} value={value} name={name} />
              )}
            />
          );
        },
      },
      {
        type: 'string',
        field: 'taux_tva',
        headerName: t('columns.taux_tva'),
        width: 120,
      },
      {
        headerName: t('columns.montant'),
        field: 'montant_ttc',
        type: 'formField',
        renderCell: ({ row, control, setValue }) => {
          const handleChange = useCallback(
            (
              onChange: (newValue: string | number | readonly string[] | undefined) => void,
              newValue: string | number | readonly string[] | undefined
            ) => {
              const amountIncludingTaxes = Number(newValue);
              setValue('montant_ht', getAmountExcludingTaxes(amountIncludingTaxes, Number(row.taux_tva)));
              onChange(newValue);
            },
            [row]
          );

          return (
            <Controller
              control={control}
              name={'montant_ttc'}
              key={`input-price-with-taxes-${row.depotId}`}
              defaultValue={((Number(row.montant_ht) * row.taux_tva) / 100 + Number(row.montant_ht)).toFixed(4)}
              render={({ field: { onChange, value, name } }) => (
                <InputBase name={name} onChange={(newValue) => handleChange(onChange, newValue)} value={value} />
              )}
            />
          );
        },
      },
      {
        type: 'formField',
        field: '',
        headerName: '',
        renderCell: (props) => <CautionListActionCell {...props} />,
        width: '1fr',
      },
    ];
  }, [t, getPriceTypeSelectByArticle, getSelect]);
}

export type RowProps = {
  columns: GridColDefinition[];
  row: GridValidRowModel;
  index: number;
  colsTemplate: string;
};

function CautionListRow({ columns, row, index, colsTemplate }: RowProps) {
  const useFormReturn = useForm({ mode: 'onChange' });

  const buildCell = useCallback(
    (column: GridColDefinition, index: number) => {
      if (column.type === 'formField') {
        return (
          <div
            key={'value-' + column.field + index}
            css={css`
              display: grid;
              align-items: center;
            `}>
            {column.renderCell({ row, ...useFormReturn, columnDef: column })}
          </div>
        );
      }

      return (
        <RowItem key={'value-' + column.field + index}>
          <span>{row[column.field]}</span>
        </RowItem>
      );
    },
    [row]
  );

  return (
    <Row key={'line'.concat(index.toString())} colsTemplate={colsTemplate}>
      {columns.map((column, index) => buildCell(column, index))}
    </Row>
  );
}

function CautionList() {
  const { t } = useTranslation(['parameters/cautionList']);
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);

  const queryFn: QueryFunction<AxiosResponse> = ({ queryKey }) => {
    const [, listParams] = queryKey as [string, { id_coll: number }];

    return getCautionList(listParams);
  };

  const transformFn = useCallback((dataResponse?: AxiosResponse) => {
    if (dataResponse) {
      const entries = Object.entries(dataResponse?.data as Record<string, RawDepositType[]>);

      return {
        rows:
          entries.length > 0
            ? [
                ...entries.map(([article, groupDepositItem]) => [
                  ...groupDepositItem.map((depositItem) => ({ ...depositItem, article })),
                ])[0],
              ]
            : [],
      };
    }
    return { rows: [] };
  }, []);

  const queryKey = useMemo(() => {
    return ['param/cautions', { id_coll }];
  }, [id_coll]);

  const columnDefinitions = useGetCautionListColumnDefinitions({ queryFn, queryKey });

  return (
    <MainSection className={'reset'}>
      <div className={'row justify-end'}>
        <Button as={NavLink} to={generatePath('home.parameters.addDeposit')}>
          {t('addCta')}
        </Button>
      </div>
      <Card>
        <GenericList
          RowComponent={CautionListRow}
          transformFn={transformFn}
          queryFn={queryFn}
          queryKey={queryKey}
          columns={columnDefinitions}
        />
      </Card>
    </MainSection>
  );
}

export default withTheme(CautionList);
