import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { GetPagesConfigResponse, putPagesConfig, PutPagesConfigBodyForm } from '../../../api/admin/pagesAdmin.api';
import { selectCollAndDeliveryPointID } from '../../../auth/selectors/authSelectors';
import ButtonWithIcon from '../../../components/Core/Button/ButtonWithIcon';
import Card from '../../../components/Core/Card/Card';
import MainSection from '../../../components/Core/MainSection/MainSection';
import { TOAST_SEVERITY } from '../../../components/Core/Toast/Toast';
import { useMainTitle } from '../../../components/Layout/MainTitleContext';
import { useSnackbar } from '../../../context/SnackbarContext';
import useGenerateFields, { Field } from '../../../hook/useGenerateFields';
import { FRONT_ROUTES, wordingPages } from '../../../parameters/constants/wordings';
import { PAGES_WITHOUT_BANNER } from '../../../parameters/utils/array';
import { useRequiredParams } from '../../Reservation/components/BookingDetails/BookingPaymentInfosCard';
import styles from './pagesAdministration.module.scss';

function PageAdministration() {
  const { t } = useTranslation(['admin/pages', 'components/topBar', 'form']);
  const { lang } = useRequiredParams<{ lang: string }>();
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const pagesConfigResponse = useOutletContext<AxiosResponse<GetPagesConfigResponse>>();
  const navigate = useNavigate();
  const snackbar = useSnackbar();

  useMainTitle(
    useMemo(() => {
      return t('components/topBar:pageTitles.pages');
    }, [lang, t])
  );

  useEffect(() => {
    if (!pagesConfigResponse.data.langs.includes(lang)) {
      navigate('/');
    }
  }, [lang, pagesConfigResponse]);

  const formConfig = useMemo(() => {
    if (pagesConfigResponse?.data) {
      const { langs, ...pages } = pagesConfigResponse.data;

      return Object.entries(pages).reduce(
        (acc, [pageName, page]) => {
          if (!wordingPages.includes(pageName as FRONT_ROUTES)) {
            return acc;
          }

          Object.entries(page).forEach(([lang, values]) => {
            const pageValues: { title: string; subtitle: string; banner?: string | null } = { ...values };
            // if (pageValues.banner === null) {
            if (PAGES_WITHOUT_BANNER.includes(pageName as FRONT_ROUTES)) {
              delete pageValues.banner;
            }

            if (pageValues.banner === null) pageValues.banner = '';

            // Object.assign(acc.defaultValues, { [pageName]: { [lang]: pageValues as any } });
            //eslint-disable-next-line
            _.set(acc.defaultValues, `${pageName}.${lang}`, pageValues as any);

            acc.fields[lang] = [
              ...acc.fields[lang],
              ...Object.keys(pageValues).map<Field>((key) => ({
                wrapperClassName: key === 'title' ? styles.TitleField : undefined,
                name: `${pageName}.${lang}.${key}`,
                type: 'text',
                label: `${t(`wordings.${pageName}`)} - ${t(key)}`,
              })),
            ];
          });

          return acc;
        },
        {
          defaultValues: {},
          fields: langs.reduce((acc, lang) => Object.assign(acc, { [lang]: [] }), {}),
        } as {
          defaultValues: {
            [pageName: string]: { [lang: string]: { title: string; subtitle: string; banner?: string } };
          };
          fields: Record<string, Field[]>;
        }
      );
    }

    return { fields: {}, defaultValues: {} };
  }, [pagesConfigResponse, t]);

  const { control, handleSubmit } = useForm({ defaultValues: formConfig.defaultValues });
  const fields = useGenerateFields({ fields: lang in formConfig.fields ? formConfig.fields[lang] : [], control });

  const { mutate: handlePutPagesConfig } = useMutation(
    ['param/pages'],
    (bodyForm: PutPagesConfigBodyForm) => {
      return putPagesConfig(bodyForm);
    },
    {
      onSuccess: () => {
        snackbar?.setAlert({ message: t('form:global_update_success_message'), severity: TOAST_SEVERITY.success });
      },
    }
  );

  const onSubmit = useCallback(
    handleSubmit((data) => {
      handlePutPagesConfig({
        id_coll,
        wordings: data,
      });
    }),
    [handlePutPagesConfig, id_coll]
  );

  return (
    <MainSection>
      <Card
        content={
          <form onSubmit={onSubmit}>
            <div className={styles.Form}>{fields}</div>
            <div className={styles.FormFooter}>
              <ButtonWithIcon type={'submit'} icon={'CheckWithoutCircle'}>
                {t('form:update')}
              </ButtonWithIcon>
            </div>
          </form>
        }
      />
    </MainSection>
  );
}

export default PageAdministration;
