export const tableSections = [
  'location_velo',
  'location_cons',
  'depots_garantie',
  'bike_renewal',
  'autres',
  'totaux',
  'rbt',
  'totauxg',
];
