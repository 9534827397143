/** @jsxImportSource @emotion/react */
import React, { useCallback } from 'react';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import dayjs, { Dayjs } from 'dayjs';
import { getColor } from '../../../../utils/style';
import IconButton from '../../../../components/Core/Button/IconButton';
import useControlled from '../../../../hook/useControlled';

type DateSelectorProps = {
  onChange?: (newDate: Dayjs) => void;
  value?: Dayjs;
};

function DateSelector(props: DateSelectorProps) {
  const { value, onChange } = props;
  const [date, setDate] = useControlled({ controlled: value, default: dayjs(), name: 'DateSelector', state: 'date' });

  const handleClickLeft = useCallback(() => {
    const newDate = date.weekday(-7);
    onChange?.(newDate);
    setDate(newDate);
  }, [date]);

  const handleClickRight = useCallback(() => {
    const newDate = date.weekday(7);
    onChange?.(newDate);
    setDate(newDate);
  }, [date]);

  return (
    <DateSelectorRoot>
      <div
        css={css`
          display: flex;
          align-items: center;
        `}>
        <IconButton
          onClick={handleClickLeft}
          css={css`
            transform: rotate(180deg);
          `}
          icon={'ChevronIcon'}
        />
        <DurationText>
          {date.weekday(0).format('DD/MM/YYYY')} - {date.weekday(6).format('DD/MM/YYYY')}
        </DurationText>
        <IconButton onClick={handleClickRight} icon={'ChevronIcon'} />
      </div>
    </DateSelectorRoot>
  );
}

const DateSelectorRoot = styled.header`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid ${getColor('gray.200')};
  padding-bottom: 16px;
`;

const DurationText = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: ${getColor('gray.400')};
`;

export default DateSelector;
