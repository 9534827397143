import { selectCollAndDeliveryPointID } from 'auth/selectors/authSelectors';
import useApi, { Methods } from 'internal/hooks/useApi';
import { VoucherSectionsType } from 'parameters/types/forms';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ArticleTypology from 'users/constants/article';

type VouchersListProps = {
  articleID: number | undefined;
  type: ArticleTypology;
};

const VouchersList = ({ articleID, type }: VouchersListProps): ReactElement => {
  const { t } = useTranslation(['old']);
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const { request: getVouchers, response: voucherRes } = useApi<VoucherSectionsType>(Methods.GET, 'user/pjlist');

  useEffect(() => {
    if (id_coll && articleID) {
      getVouchers({
        params: {
          id_coll,
          id_article: articleID,
        },
      });
    }
  }, [id_coll, articleID]);

  const renderMandatoryVouchers = useMemo(() => {
    if (voucherRes?.success && voucherRes.data && voucherRes?.data[type]) {
      return voucherRes?.data[type].map((voucher) => (
        <>
          <span>
            <Trans t={t} i18nKey={'users.pj_check'} components={{ b: <b /> }} values={{ name: voucher.pjName }} />
          </span>
          <br />
        </>
      ));
    }

    return null;
  }, [voucherRes, type, articleID, t]);

  return <>{renderMandatoryVouchers}</>;
};

export default VouchersList;
