import { Controller, UseFormReturn, useWatch } from 'react-hook-form';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { FormObject, Option } from '../../../hook/useBuildForm';
import { RawBookingConfig } from '../../../api/admin/bookingConfig.api';
import { paymentsOptions } from '../../../parameters/constants/payments';
import SelectBase from '../../../components/Core/SelectBase/SelectBase';
import SelectField from '../../../components/Core/SelectField';
import Label from '../../../components/Core/Label/Label';

export function transformRawBookingConfig(rawBookingConfig?: RawBookingConfig) {
  if (rawBookingConfig) {
    const { paymentsMeans, options, langs, ...other } = rawBookingConfig;
    return {
      defaultValues: {
        paymentsMeans,
        ...other,
      },
      options,
    };
  }

  return undefined;
}

export type CustomFieldProps = {
  control: UseFormReturn['control'];
  setValue: UseFormReturn['setValue'];
  unregister: UseFormReturn['unregister'];
  register: UseFormReturn['register'];
  getValues: UseFormReturn['getValues'];
  reset: UseFormReturn['reset'];
  label: string;
  name: string;
};

type ExtraFormProps = {
  rawOptions?: Record<string, string[]>;
  rawPaymentMeans?: Record<string, string | boolean | null>;
};

function ExtraFormByPaymentMean(props: ExtraFormProps & Partial<CustomFieldProps>) {
  const { t } = useTranslation('admin/bookingConfig');
  const { rawOptions, control, unregister, rawPaymentMeans, register } = props;
  const paymentsMeans: Record<string, string> | undefined = useWatch({ name: 'paymentsMeans', control });
  const [paymentMeansSelected, setPaymentMeansSelected] = useState<Option[]>([]);

  useEffect(() => {
    setPaymentMeansSelected(
      paymentsOptions(false, t).filter((option) => Object.keys(rawPaymentMeans || {}).includes(option.value))
    );
  }, [rawPaymentMeans, t]);

  const onChange = useCallback(
    (newValue: unknown) => {
      (newValue as Option[])
        .map((option) => option.value)
        .forEach((optionSelected) => {
          if (
            !Object.keys(paymentsMeans || {}).includes(optionSelected as string) &&
            !rawOptions?.[optionSelected as string]
          ) {
            register?.('paymentsMeans.'.concat(optionSelected as string), { value: true });
          }
        });

      Object.keys(paymentsMeans || {}).forEach((paymentMean) => {
        if (!(newValue as Option[]).map((option) => option.value).includes(paymentMean)) {
          unregister?.('paymentsMeans.'.concat(paymentMean), {
            keepValue: false,
            keepDefaultValue: false,
            keepDirty: false,
            keepTouched: false,
            keepDirtyValues: false,
          });
        }
      });
      /*(newValue as Option[])
          .map((option) => option.value)
          .forEach((paymentMean) => {
            if (!rawOptions?.[paymentMean as string]) {
              setValue?.('paymentsMeans.'.concat(paymentMean as string), true, {
                shouldDirty: true,
                shouldTouch: true,
              });
            }
          });*/

      setPaymentMeansSelected(newValue as Option[]);
    },
    [paymentsMeans, control, unregister, rawOptions]
  );

  return (
    <ExtraFormRoot>
      <div>
        <Label>{t('booking_active')}</Label>
        <PaymentMeansSelect
          value={paymentMeansSelected}
          isMulti
          onChange={onChange}
          options={paymentsOptions(false, t)}
        />
      </div>
      <Grid>
        {paymentMeansSelected
          .map((paymentMean) => paymentMean.value)
          ?.filter((paymentMeanSelected) => (rawOptions?.[paymentMeanSelected as string]?.length || 0) > 0)
          .map((paymentMean, index) =>
            rawOptions?.[paymentMean as string].length || 0 > 0 ? (
              <Controller
                key={(paymentMean as string).concat(index.toString())}
                control={control}
                name={'paymentsMeans.'.concat(paymentMean as string)}
                render={({ fieldState: { error }, field: { onChange, value } }) => {
                  const options =
                    rawOptions?.[paymentMean as string].map((value) => ({ value, label: t(value) })) || [];

                  return (
                    <SelectField
                      label={t(paymentMean as string)}
                      placeholder={t(paymentMean as string)}
                      error={error}
                      onChange={onChange}
                      options={options}
                      value={options.find((option) => option.value === value) || null}
                    />
                  );
                }}
              />
            ) : null
          )}
      </Grid>
    </ExtraFormRoot>
  );
}

const PaymentMeansSelect = styled(SelectBase)``;

const Grid = styled.div`
  display: grid;
  gap: 16px;
`;

const ExtraFormRoot = styled(Grid)`
  grid-column: 1 / 2 span;
  grid-template-columns: repeat(2, 1fr);
`;

function useBookingForm(
  rawPaymentMeans?: Record<string, string | boolean | null>,
  rawOptions?: Record<string, string[]>
): FormObject {
  const { t } = useTranslation('admin/depositConfig');

  return useMemo(
    () => [
      {
        colsNumber: 2,
        fields: [
          {
            type: 'checkbox',
            label: t('btn_deposit'),
            name: 'enabled',
          },
          {
            type: 'custom',
            component: <ExtraFormByPaymentMean rawPaymentMeans={rawPaymentMeans} rawOptions={rawOptions} />,
            name: '',
            label: '',
          },
          {
            type: 'text',
            name: 'paymentInfoText.fr',
            label: t('caution_payment_info_fr'),
            columnStart: 1,
          },
          {
            type: 'text',
            name: 'paymentInfoText.en',
            label: t('caution_payment_info_en'),
          },
        ],
      },
    ],
    [t, rawPaymentMeans, rawOptions]
  );
}

export default useBookingForm;
