import { upperFirst } from 'lodash';
import React, { Dispatch, Suspense, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import { useBooking } from 'pages/Reservation/Reservation.layout';
import {
  getBookingConfig,
  getCheckInVerifs,
  getSign,
  validBooking,
  ValidBookingBodyForm,
} from '../../../../api/booking.api';
import { selectCollAndDeliveryPointID, selectSessionToken } from '../../../../auth/selectors/authSelectors';
import ButtonWithIcon from '../../../../components/Core/Button/ButtonWithIcon';
import IconButton from '../../../../components/Core/Button/IconButton';
import Card from '../../../../components/Core/Card/Card';
import { CheckIcon } from '../../../../const/icons';
import { generatePath } from '../../../../utils/router';
import styles from '../../bookingDetails.module.scss';
import AssignModal from './AssignModal';
import { ARTICLE_TYPES } from './ManagementCard';
import SignatureModal from './SignatureModal';

type CardProps = {
  currentState: VALIDATE_BOOKING_STATES;
  setCurrentState: Dispatch<React.SetStateAction<VALIDATE_BOOKING_STATES>>;
};

function AssignCard({ currentState, setCurrentState }: CardProps) {
  const { t } = useTranslation(['reservation/components/assign', 'common']);
  const { rawBooking } = useBooking();
  const [isOpen, setIsOpen] = useState(false);

  const isFreeParking = useMemo(() => rawBooking?.id_velo === null && rawBooking?.id_consigne === null, [rawBooking]);
  const isParking = useMemo(() => rawBooking?.article === ARTICLE_TYPES.park, [rawBooking]);

  const isAlreadyAssign = useMemo(() => {
    return rawBooking?.id_velo
      ? rawBooking.id_velo !== 0
      : rawBooking?.id_consigne
      ? rawBooking.id_consigne !== 0
      : false;
  }, [rawBooking]);

  useEffect(() => {
    if (currentState === VALIDATE_BOOKING_STATES.ASSIGN_BIKE && (isAlreadyAssign || isFreeParking))
      setCurrentState(VALIDATE_BOOKING_STATES.CHECK_IN);
  }, [isAlreadyAssign, setCurrentState, currentState, isFreeParking]);

  return !isFreeParking ? (
    <>
      <Card
        className={[styles.BookingCard, styles.assignCard].join(' ')}
        title={t('cardTitle')}
        content={
          <>
            {isAlreadyAssign ? (
              <>
                <p>
                  <strong>
                    {rawBooking?.article === ARTICLE_TYPES.bike
                      ? upperFirst(t('common:bike'))
                      : upperFirst(t('common:park'))}
                  </strong>
                  <br />
                  {rawBooking?.id_interne}
                </p>
                {rawBooking?.accessoires &&
                  rawBooking.accessoires.map((accessory) => (
                    <p>
                      <strong>{accessory.nom_type}</strong>
                      <br />
                      {accessory.id_interne}
                    </p>
                  ))}
                <div className={[styles.ValidTunnelCardFooter, styles.assignCard__footer].join(' ')}>
                  <p className={styles.successText}>
                    <CheckIcon width={16} /> {t('isAssigned')}
                  </p>
                  <IconButton onClick={() => setIsOpen(true)} icon={'EditIcon'} />
                </div>
              </>
            ) : (
              <>
                <span className={styles.assignCard__text}>
                  {t('cardDescription', {
                    context: isParking ? 'female' : 'male',
                    article: isParking ? '$t(common:park)' : '$t(common:bike)',
                  })}
                </span>
                <div className={styles.ValidTunnelCardFooter}>
                  <ButtonWithIcon onClick={() => setIsOpen(true)} icon={'EditIcon'} color={'secondary'}>
                    {t('cardCta')}
                  </ButtonWithIcon>
                </div>
              </>
            )}
            <Suspense fallback={<></>}>{isOpen && <AssignModal setIsOpen={setIsOpen} />}</Suspense>
          </>
        }
      />
    </>
  ) : null;
}

function CheckinCard({ setCurrentState, currentState }: CardProps) {
  const { t } = useTranslation(['reservation/components/checkIn']);
  const params = useParams();
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);

  const { data: checkInListResponse } = useQuery(
    ['checkInVerifList', { id_resa: params.bookingId, id_coll }],
    ({ queryKey }) => {
      const [, queryParams] = queryKey as [string, { id_resa: number; id_coll: number }];
      return getCheckInVerifs(queryParams);
    },
    {
      staleTime: 260_000,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (currentState === VALIDATE_BOOKING_STATES.CHECK_IN && checkInListResponse?.data.length === 0)
      setCurrentState(VALIDATE_BOOKING_STATES.SIGN);
  }, [checkInListResponse?.data, currentState]);

  return checkInListResponse?.data.length > 0 ? (
    <Card
      disabled={currentState < VALIDATE_BOOKING_STATES.CHECK_IN}
      title={t('cardTitle')}
      className={styles.BookingCard}
      content={
        <>
          <span className={styles.assignCard__text}>{t('cardDescription')}</span>
          <div className={styles.ValidTunnelCardFooter}>
            <ButtonWithIcon
              as={NavLink}
              to={generatePath('home.invoicing.checkInVerifs', { params })}
              icon={'EditIcon'}
              color={'secondary'}>
              {t('cta')}
            </ButtonWithIcon>
          </div>
        </>
      }
    />
  ) : null;
}

function SignatureCard({ setCurrentState, currentState }: CardProps) {
  const { t } = useTranslation(['reservation/components/signature']);
  const { bookingId } = useParams();
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const [isOpen, setIsOpen] = useState(false);
  const token = useSelector(selectSessionToken);

  const { data: bookingConfig } = useQuery(
    ['booking.config', { id_coll }],
    ({ queryKey }) => {
      const [, queryParams] = queryKey as [string, { id_coll: number }];
      return getBookingConfig(queryParams);
    },
    {
      // enabled: currentState === VALIDATE_BOOKING_STATES.SIGN,
      suspense: false,
      staleTime: 260_000,
      refetchOnWindowFocus: false,
      /*onSuccess: (response) => {
        if (!response.data.demande_signature) setCurrentState(VALIDATE_BOOKING_STATES.VALIDATE);
      },*/
    }
  );

  const { data: signResponse } = useQuery(
    ['signatureBooking', { id_resa: bookingId, id_coll }],
    ({ queryKey }) => {
      const [, queryParams] = queryKey as [string, { id_resa: number; id_coll: number }];
      return getSign(queryParams);
    },
    {
      suspense: false,
      enabled: bookingConfig?.data.demande_signature || false,
      staleTime: 260_000,
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        if ('img_sign' in response.data) setCurrentState(VALIDATE_BOOKING_STATES.VALIDATE);
      },
    }
  );

  useEffect(() => {
    if (bookingConfig && currentState === VALIDATE_BOOKING_STATES.SIGN && !bookingConfig.data.demande_signature)
      setCurrentState(VALIDATE_BOOKING_STATES.VALIDATE);

    if (signResponse?.data && 'img_sign' in signResponse.data) setCurrentState(VALIDATE_BOOKING_STATES.VALIDATE);
  }, [currentState, bookingConfig, signResponse]);

  return bookingConfig?.data.demande_signature ? (
    <>
      <Card
        disabled={currentState < VALIDATE_BOOKING_STATES.SIGN}
        title={t('cardTitle')}
        className={styles.BookingCard}
        content={
          <>
            {signResponse && 'img_sign' in signResponse.data ? (
              <>
                <img
                  width={100}
                  src={signResponse.data.img_sign.concat('&front&token=' + token)}
                  alt={'signature-reservation-'}
                />
                <div className={[styles.ValidTunnelCardFooter, styles.assignCard__footer].join(' ')}>
                  <p className={styles.successText}>
                    <CheckIcon width={16} /> {t('isSigned')}
                  </p>
                </div>
              </>
            ) : (
              <>
                <span className={styles.assignCard__text}>{t('description')}</span>
                <div className={styles.ValidTunnelCardFooter}>
                  <ButtonWithIcon onClick={() => setIsOpen(true)} icon={'EditIcon'} color={'secondary'}>
                    {t('cta')}
                  </ButtonWithIcon>
                </div>

                {isOpen && <SignatureModal setIsOpen={setIsOpen} />}
              </>
            )}
          </>
        }
      />
    </>
  ) : null;
}

function ValidateCard({ currentState }: CardProps) {
  const { t } = useTranslation(['reservation/components/validate']);
  const { bookingId } = useParams();
  const { id_coll, id_pdl } = useSelector(selectCollAndDeliveryPointID);
  const queryClient = useQueryClient();

  const { mutate: handleValidBooking, isLoading } = useMutation(
    ['validBooking'],
    (data: ValidBookingBodyForm) => {
      return validBooking(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['booking']);
      },
    }
  );

  return (
    <Card
      disabled={useMemo(() => currentState < VALIDATE_BOOKING_STATES.VALIDATE, [currentState])}
      className={styles.BookingCard}
      title={t('cardTitle')}
      content={
        <>
          <span className={styles.assignCard__text}>{t('cardDescription')}</span>
          <div className={styles.ValidTunnelCardFooter}>
            <ButtonWithIcon
              icon={'CheckWithoutCircle'}
              onClick={() => bookingId && handleValidBooking({ id_pdl, id_coll, id_resa: bookingId })}
              isLoading={isLoading}>
              {t('cardCta')}
            </ButtonWithIcon>
          </div>
        </>
      }
    />
  );
}

enum VALIDATE_BOOKING_STATES {
  ASSIGN_BIKE,
  CHECK_IN,
  SIGN,
  VALIDATE,
}

function ValidateBookingTunnel() {
  const [currentState, setCurrentState] = useState(VALIDATE_BOOKING_STATES.ASSIGN_BIKE);

  return (
    <Suspense fallback={<></>}>
      <div className={styles.validateBookingSection}>
        <AssignCard key={'assign-card'} setCurrentState={setCurrentState} currentState={currentState} />
        <CheckinCard key={'checkin-card'} setCurrentState={setCurrentState} currentState={currentState} />
        <SignatureCard key={'signature-card'} setCurrentState={setCurrentState} currentState={currentState} />
        <ValidateCard key={'validate-card'} setCurrentState={setCurrentState} currentState={currentState} />
      </div>
    </Suspense>
  );
}

export default ValidateBookingTunnel;
