import React, { useCallback, useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import TulipImg from 'assets/img/logo-tulip.webp';
import MainSection from '../../../../components/Core/MainSection/MainSection';
import Card from '../../../../components/Core/Card/Card';
import Button from '../../../../components/Core/Button/Button';
import useGenerateFields, { Field } from '../../../../hook/useGenerateFields';
import { instance } from '../../../../api/user.api';
import Label from '../../../../components/Core/Label/Label';
import { selectCollAndDeliveryPointID } from '../../../../auth/selectors/authSelectors';
import { useSnackbar } from '../../../../context/SnackbarContext';
import { TOAST_SEVERITY } from '../../../../components/Core/Toast/Toast';
import SoonTag from '../../../../components/Core/SoonTag/SoonTag';
import styles from './Tulip.module.scss';

function useTulipFormFields() {
  const { t } = useTranslation(['insurance/tulip'], { keyPrefix: 'paramsPage.form' });

  return useMemo<Field[]>(() => {
    return [
      { name: 'enabled', label: t('tulipEnabled.label'), type: 'toggle' },
      { name: 'damageTheftInsuranceEnabled', label: t('damageTheft.label'), type: 'toggleRadioGroup' },
      // { name: '', label: t('tulipId.label'), placeholder: t('tulipId.label'), type: 'text', rules: { required: true } },
      { name: 'tulipId', label: t('tulipId.label'), placeholder: t('tulipId.label'), type: 'text' },
    ];
  }, [t]);
}

type PostInsuranceParamsBodyForm = {
  idColl: number;
  enabled: number;
  tulipId: string;
  damageTheftInsuranceEnabled: number;
  bodilyInjuryInsuranceEnabled: number;
};

function TulipParamsPage() {
  const snackbar = useSnackbar();
  //eslint-disable-next-line
  const tulipParamsResponse: AxiosResponse<any> | undefined = useOutletContext();
  const defaultValues = useMemo(
    () => ({
      tulipId: tulipParamsResponse?.data.tulipId || null,
      enabled: tulipParamsResponse?.data.enabled || 0,
      damageTheftInsuranceEnabled: tulipParamsResponse?.data.damageTheftInsuranceEnabled || null,
    }),
    [tulipParamsResponse?.data]
  );
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const { t } = useTranslation(['insurance/tulip', 'form']);
  const queryClient = useQueryClient();
  const { control, handleSubmit, reset } = useForm<
    Omit<PostInsuranceParamsBodyForm, 'id_coll' | 'bodily_injury_insurance_enabled'>
  >({
    defaultValues,
  });
  const fields = useTulipFormFields();
  const form = useGenerateFields({ fields, control });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const { mutate: postInsuranceParams } = useMutation(
    ['insurance/tulip'],
    (bodyForm: PostInsuranceParamsBodyForm) => {
      return instance.post('insurance/tulip', bodyForm);
    },
    {
      onSuccess: () => {
        snackbar?.setAlert({ message: t('form:global_update_success_message'), severity: TOAST_SEVERITY.success });
        queryClient.invalidateQueries(['insurance/tulip']);
      },
    }
  );

  const onSubmit = useCallback(
    handleSubmit((data) => {
      const { tulipId, damageTheftInsuranceEnabled, enabled } = data;

      postInsuranceParams({
        tulipId,
        enabled: enabled ? 1 : 0,
        damageTheftInsuranceEnabled: damageTheftInsuranceEnabled ? 1 : 0,
        bodilyInjuryInsuranceEnabled: 0,
        idColl: id_coll,
      });
    }),
    [id_coll]
  );

  return (
    <MainSection className={'reset'}>
      <Card
        title={(baseStyle) => (
          <div className={[baseStyle, styles.InfosCardTitle].join(' ')}>
            <h3>{t('paramsPage.infosCard.title')}</h3>
            <div>
              <img height={25} src={TulipImg} alt={'tulip-logo'} />
            </div>
          </div>
        )}
        content={
          <div className={styles.InfosCardContent}>
            <div className={styles.InfosCardContentBase}>
              <Trans t={t} i18nKey={'paramsPage.infosCard.content.base'} components={{ ul: <ul />, li: <li /> }} />
            </div>
            <div className={styles.InfosCardContentFooter}>
              <p>{t('paramsPage.infosCard.content.footer.leftText')}</p>
              <Button as={'a'} href={'https://mytulip.typeform.com/to/q6hUWHD8#partner=V%C3%A9loclik'}>
                {t('paramsPage.infosCard.content.footer.cta')}
              </Button>
            </div>
          </div>
        }
      />
      <Card
        title={t('paramsPage.formCard.title')}
        content={
          <div>
            <form onSubmit={onSubmit}>
              <div className={styles.Form}>
                {form[0]}
                {form[1]}
                <div>
                  <Label className={'block mb-2'}>Je souhaite ajouter des garanties corporelles</Label>
                  <SoonTag />
                </div>
                {form[2]}
              </div>
              <div className={'mt-4 row justify-end'}>
                <Button type={'submit'}>{t('form:update')}</Button>
              </div>
            </form>
          </div>
        }
      />
    </MainSection>
  );
}

export default TulipParamsPage;
