/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useCallback } from 'react';
import { Control, Controller } from 'react-hook-form';
import SelectField from '../../../components/Core/SelectField';
import { Option } from '../../../hook/useBuildForm';

export function usePriceAvailableSelect(getAvailablePriceOptions: (pricesSelected: number[]) => Option[]) {
  return useCallback(
    //eslint-disable-next-line
    (row: any, control: Control, name: string, defaultValue = []) => {
      const options = getAvailablePriceOptions(row.articles);

      return (
        <Controller
          key={name}
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ fieldState: { error }, field: { onChange, value } }) => (
            <SelectField
              menuPosition={'fixed'}
              css={css`
                display: flex;
                width: 100%;
                overflow: hidden;

                & > div {
                  width: 100%;
                }
              `}
              error={error}
              onChange={onChange}
              value={options.filter((option) => value && value.includes(option.value)) || null}
              options={options}
              isMulti
            />
          )}
        />
      );
    },
    [getAvailablePriceOptions]
  );
}

function usePriceTypeSelect(getPriceOptionsByArticle: (article: string) => Option[]) {
  return useCallback(
    (article: string, control: Control, name: string, defaultValue = []) => {
      const articlePriceTypeList = getPriceOptionsByArticle(article);

      return (
        <Controller
          key={name}
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ fieldState: { error }, field: { onChange, value } }) => (
            <SelectField
              menuPosition={'fixed'}
              css={css`
                display: flex;
                width: 100%;

                & > div {
                  width: 100%;
                }
              `}
              error={error}
              onChange={onChange}
              value={articlePriceTypeList.filter((option) => value && value.includes(option.value)) || null}
              options={articlePriceTypeList}
              isMulti
            />
          )}
        />
      );
    },
    [getPriceOptionsByArticle]
  );
}

export default usePriceTypeSelect;
