import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { selectCollAndDeliveryPointID } from 'auth/selectors/authSelectors';
import dayjs from 'dayjs';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from 'fragments/button/Button';
import useApi, { Methods } from 'internal/hooks/useApi';
import useForm from 'internal/hooks/useForm';
import { selectCurrency } from 'internal/selectors/currencySelectors';
import { selectLocale } from 'internal/selectors/localeSelectors';
import { DYNAMIC_FORMS_ENDPOINT, RESA_PROPERTIES_ENDPOINT } from 'parameters/constants/endpoints';
import { useLocation, useNavigate } from 'react-router-dom';
import ProductSelectionForm, { State as ProductState } from 'users/components/BikeProductSelectionForm';
import DurationForm from 'users/components/DurationForm';
import AccessoriesForm from 'users/components/reservation/AccessoriesForm';
import NewBookingSummary from 'users/components/reservation/NewBookingSummary';
import ArticleTypology from 'users/constants/article';
import {
  DEPOSIT_ENDPOINT,
  POST_USER_RESERVATION_ENDPOINT,
  SECURITY_DEPOSIT_ENDPOINT,
  USER_TARIF_ENDPOINT,
} from 'users/constants/endpoints';
import { bookingBikeForm, paymentMeanForm } from 'users/constants/forms';
import { selectUserDetails } from 'users/selectors/usersSelectors';
import { UserAccessoryType } from 'users/types/users';
import { assembleForm } from 'users/utils/dynamicForms';
import { computeRentalDuration, computeRentalDurationTrads } from 'users/utils/resabike';
import { generatePath } from '../../utils/router';
import DurationBloc from './reservation/DurationBloc';
import VouchersList from './reservation/VouchersList';

export type RemoteFieldType = {
  name: string;
  required: boolean;
  displayed: boolean;
};

const UsersResaBike = (): ReactElement => {
  const { t } = useTranslation(['reservation/reservation']);
  const { id_coll, id_pdl } = useSelector(selectCollAndDeliveryPointID);
  const locale = useSelector(selectLocale);
  const currency = useSelector(selectCurrency);
  const [durationText, setDurationText] = useState<string>('');

  const { request: fetchBooking, response: bookingRes } = useApi<{
    bookingEnabled: boolean;
    cautionEnabled: boolean;
    paiement_bo: string[];
  }>(Methods.GET, RESA_PROPERTIES_ENDPOINT);

  const { request: fetchForm, response: fetchFormRes } = useApi<{
    bookingBike: {
      fields: RemoteFieldType[][];
    };
  }>(Methods.GET, DYNAMIC_FORMS_ENDPOINT);

  const { request: fetchPrice, response: priceResponse } = useApi<{ montant_ttc: string }>(
    Methods.GET,
    USER_TARIF_ENDPOINT,
    {
      noSnack: true,
    }
  );

  useEffect(() => {
    if (id_coll) {
      fetchBooking({
        params: {
          id_coll,
        },
      });
      fetchForm({ params: { id_coll, formName: 'bookingBike' } });
    }
  }, [id_coll]);

  const remoteBookingBikeForm = useMemo(() => fetchFormRes?.data.bookingBike.fields || [], [fetchFormRes]);

  const isBookingEnabled = useMemo(() => bookingRes?.data.bookingEnabled, [bookingRes]);
  const isCautionEnabled = useMemo(() => bookingRes?.data.cautionEnabled, [bookingRes]);
  const isPaymentBOEnabled = useMemo(
    () => bookingRes?.data?.paiement_bo && bookingRes?.data?.paiement_bo.length > 0,
    [bookingRes]
  );

  // @XXX: Select user state and initialise userInfo form
  const user = useSelector(selectUserDetails);

  const initialUserFormState = useMemo(
    () => ({
      nom: user?.nom || '',
      prenom: user?.prenom || '',
      email: user?.email || '',
      boursier: user?.boursier || '',
      date_naissance: user?.date_naissance || '',
      entr_asso: user?.entr_asso || '',
      iban: user?.iban || '',
      bic: user?.bic || '',
      motif_utilisation: user?.motif_utilisation || '',
    }),
    [user]
  );

  const initBanState = useMemo(() => ({ moyen_reglement: 'cheque' }), []);

  const bookingForm = useMemo(() => {
    if (remoteBookingBikeForm) {
      return assembleForm(remoteBookingBikeForm, undefined, t);
    }
    return bookingBikeForm(t);
  }, [remoteBookingBikeForm, t]);

  // @XXX: All the form state
  const [form, formState] = useForm(bookingForm, initialUserFormState, {
    shouldLoad: true,
  });
  const [{ startingDate, endingDate }, setDateFormState] = useState<{ startingDate: string; endingDate: string }>({
    startingDate: '',
    endingDate: '',
  });
  const [bankForm, bankFormState] = useForm(paymentMeanForm(t, isBookingEnabled, isPaymentBOEnabled), initBanState);
  const [{ selectedBikeType, selectedPriceFormula }, setProductFormState] = useState<ProductState>({
    selectedBikeType: '',
    selectedPriceFormula: null,
    selectedPrice: '',
  });

  const [accessoriesState, setAccessoriesState] = useState<UserAccessoryType[]>([]);
  const accessoriesUsed = useMemo(() => accessoriesState.filter(({ use }) => use), [accessoriesState]);

  const onProductChange = useCallback(
    (state: ProductState) => {
      setProductFormState(state);
    },
    [setProductFormState]
  );

  const rentalDurationTypology = useMemo(() => {
    return computeRentalDuration(selectedPriceFormula?.type_duree || '');
  }, [selectedPriceFormula?.type_duree]);

  const rentalDurationTrads = useMemo(() => {
    return computeRentalDurationTrads(selectedPriceFormula?.type_duree || '');
  }, [selectedPriceFormula?.type_duree, locale]);

  const isTrimester = useMemo(() => selectedPriceFormula?.type_duree === 'trimestre', [selectedPriceFormula]);
  const diff = useMemo(() => {
    const end = dayjs(endingDate).hour(0).minute(0).second(0).millisecond(0);
    const start = dayjs(startingDate).hour(0).minute(0).second(0).millisecond(0);
    const diff = dayjs(end).diff(dayjs(start), rentalDurationTypology) || 0;

    return diff;
  }, [endingDate, startingDate, rentalDurationTypology]);

  const duration = useMemo(() => (isTrimester ? diff / 3 : diff), [diff, isTrimester]);

  const bookingPrice = useMemo(() => {
    if (!isBookingEnabled || !priceResponse?.data.montant_ttc) {
      return 0;
    }

    if (priceResponse?.success && priceResponse?.data) {
      return parseFloat(priceResponse.data.montant_ttc);
    }

    return 0;
  }, [isBookingEnabled, selectedPriceFormula?.montant_ttc, priceResponse?.data, priceResponse?.success]);

  const isGoodDurationText = useMemo(
    () =>
      (selectedPriceFormula?.min_duree === duration && selectedPriceFormula?.montant_ttc === bookingPrice) ||
      (selectedPriceFormula?.min_duree && duration > selectedPriceFormula?.min_duree),
    [selectedPriceFormula, duration, bookingPrice]
  );

  // @XXX: Compute the text of the green card in func of duration
  useEffect(() => {
    if (isGoodDurationText) {
      setDurationText(`${duration || 1} ${rentalDurationTrads}${
        duration < 2 || rentalDurationTrads === 'mois' ? '' : 's'
      }
      ${t('users.duration_price', { price: bookingPrice, currency })}`);
    }

    if (!bookingPrice) {
      setDurationText(`
      ${duration || 1} ${rentalDurationTrads}${duration < 2 || rentalDurationTrads === 'mois' ? '' : 's'}
    `);
    }
  }, [rentalDurationTrads, currency, bookingPrice, duration, priceResponse, selectedPriceFormula]);

  const { request: fetchSecurityDeposit, response: securityDepositResponse } = useApi<{
    montant_ttc: string;
    montant_ht: string;
  }>(Methods.GET, SECURITY_DEPOSIT_ENDPOINT);

  const { request: fetchDeposit, response: depositResponse } = useApi<{
    montant: number;
    montant_ht: string;
  }>(Methods.GET, DEPOSIT_ENDPOINT);

  // @XXX : Aggregate forms and POST it to users/reservation on button click
  const { request: postForm, response: postFormResponse } = useApi<{ id_reservation: string; redir_to: string }>(
    Methods.POST,
    POST_USER_RESERVATION_ENDPOINT
  );

  const [id_user, setUserID] = useState('');
  const { search } = useLocation();

  useEffect(() => {
    if (search) {
      const queryParams = new URLSearchParams(search);
      const userID = queryParams.get('userID') || '';

      setUserID(userID);
    }
  }, [search]);

  useEffect(() => {
    if (id_coll && id_user && selectedPriceFormula?.id_article) {
      fetchSecurityDeposit({
        params: {
          id_coll,
          id_article: selectedPriceFormula?.id_article,
          id_user,
        },
      });
    }
  }, [fetchSecurityDeposit, id_coll, id_user, selectedPriceFormula?.id_article]);

  useEffect(() => {
    if (id_coll && id_user && selectedBikeType) {
      fetchDeposit({
        params: {
          id_coll,
          id_type: selectedBikeType,
          id_user,
        },
      });
    }
  }, [fetchDeposit, id_coll, id_user, selectedBikeType]);

  useEffect(() => {
    if (selectedPriceFormula?.id_article && duration) {
      fetchPrice({
        params: {
          id_coll,
          id_article: selectedPriceFormula?.id_article,
          time_unit: duration,
        },
      });
    }
  }, [selectedPriceFormula?.id_article, duration, id_coll]);

  const securityDeposit = useMemo(() => {
    if (
      securityDepositResponse?.success &&
      securityDepositResponse.data &&
      parseFloat(securityDepositResponse.data.montant_ttc) > 0
    ) {
      return parseFloat(securityDepositResponse.data.montant_ttc);
    }

    return 0;
  }, [securityDepositResponse, currency]);

  const deposit = useMemo(() => {
    if (isCautionEnabled && depositResponse?.success && depositResponse.data && depositResponse.data.montant > 0) {
      return depositResponse.data.montant;
    }

    return 0;
  }, [isCautionEnabled, depositResponse, currency]);

  const handleSubmit = useCallback(() => {
    const accessoriesUsedIds = accessoriesUsed.map(({ id_type }) => id_type);

    postForm({
      id_coll,
      id_pdl,
      ...formState,
      moyen_reglement: bankFormState.moyen_reglement || initBanState.moyen_reglement,
      id_user,
      id_article: selectedPriceFormula?.id_article,
      date_fin: endingDate,
      date_debut: startingDate,
      accessoires: accessoriesUsedIds,
    });
  }, [
    postForm,
    id_coll,
    id_pdl,
    id_user,
    initBanState,
    formState,
    bankFormState,
    selectedPriceFormula,
    endingDate,
    startingDate,
    accessoriesUsed,
  ]);

  const navigate = useNavigate();

  useEffect(() => {
    if (postFormResponse?.success && postFormResponse.data) {
      if (postFormResponse.data.redir_to) {
        window.location.href = postFormResponse.data.redir_to;
      } else {
        navigate(
          generatePath('home.invoicing.reservationDetails', {
            params: { userId: user?.id_user, bookingId: postFormResponse.data.id_reservation },
          })
        );
      }
    }
  }, [postFormResponse]);

  const informativeWording = useMemo(() => {
    if (isBookingEnabled) {
      return 'users.rent_conditions';
    }
    return 'users.rent_conditions_no_payment';
  }, [isBookingEnabled]);

  const renderPaymentMeanSelect = useMemo(() => {
    if (!isBookingEnabled) {
      return null;
    }
    return (
      <>
        <br />
        <div className={'row'}>{bankForm}</div>
      </>
    );
  }, [isBookingEnabled, bankForm]);

  return (
    <div className={'tab-pane fade show active'}>
      <br />
      <ProductSelectionForm
        articleType={ArticleTypology.BIKE}
        handleChange={onProductChange}
        isFree={isBookingEnabled}
      />
      <label>{t('users.user_info')}</label>
      <div className={'row'}>{form}</div>
      {selectedBikeType && (
        <>
          <AccessoriesForm
            articleID={selectedPriceFormula?.id_article}
            formState={accessoriesState}
            setFormState={setAccessoriesState}
            startingDate={startingDate}
            endingDate={endingDate}
          />
          <DurationBloc
            selectedPriceFormula={selectedPriceFormula}
            rentalDurationTrads={rentalDurationTrads}
            durationText={durationText}
            startingDate={startingDate}
            endingDate={endingDate}
            selectedBikeType={selectedBikeType}
          />
        </>
      )}
      <DurationForm price={selectedPriceFormula} handleChangeDate={setDateFormState} />
      <br />
      <VouchersList articleID={selectedPriceFormula?.id_article} type={ArticleTypology.BIKE} />
      <br />
      <NewBookingSummary
        deposit={deposit}
        securityDeposit={securityDeposit}
        articleID={selectedPriceFormula?.id_article}
        duration={duration}
        accessories={accessoriesUsed}
        isBookingEnabled={isBookingEnabled}
      />
      {renderPaymentMeanSelect}
      <br />
      <Button className={'btn btn-w-xl btn-primary'} label={t('users.create_resa')} onClick={handleSubmit} />
      <br />
      <span>
        <Trans
          i18nKey={informativeWording}
          components={{
            bold: <strong />,
            lineBreak: <br />,
            u: <u />,
          }}
        />
      </span>
    </div>
  );
};

export default UsersResaBike;
