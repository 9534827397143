import {
  DateRangePicker as DateRangePickerBase,
  DayPickerRangeController,
  FocusedInputShape,
  RenderMonthProps,
} from 'react-dates';
import React, { ComponentPropsWithoutRef, useState } from 'react';
import moment, { Moment } from 'moment/moment';
import {
  autoUpdate,
  flip,
  FloatingPortal,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFocus,
  useInteractions,
} from '@floating-ui/react';
import Label from '../../../components/Core/Label/Label';
import dateRangePickerStyles from './DateRangePicker.module.scss';
import DateInput from './DateInput';

function DateRangePicker(
  props: RenderMonthProps &
    Omit<
      ComponentPropsWithoutRef<typeof DateRangePickerBase>,
      'onFocusChange' | 'focusedInput' | 'startDateId' | 'endDateId' | 'disabled'
    > & {
      className?: string;
      disabled?: boolean;
    }
) {
  const { disabled } = props;
  const [focus, setFocus] = useState<FocusedInputShape | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    strategy: 'absolute',
    middleware: [shift(), flip()],
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context, { toggle: false });
  const focusEvent = useFocus(context);
  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([focusEvent, dismiss, click]);

  const onChange: (type: 'startDate' | 'endDate') => (newValue: Moment | null) => void = (type) => (newValue) => {
    const dates = { startDate: props.startDate, endDate: props.endDate };
    dates[type] = newValue;

    props.onDatesChange(dates);
  };

  return (
    <div className={[props.className].join(' ')}>
      <div ref={refs.setReference} className={dateRangePickerStyles.Root}>
        <Label>
          Du
          <DateInput
            disabled={disabled}
            value={props.startDate?.format('DD/MM/YYYY') || ''}
            onChange={onChange('startDate')}
            {...getReferenceProps({ onFocus: () => setFocus('startDate') })}
          />
        </Label>
        <Label>
          Au
          <DateInput
            disabled={disabled}
            value={props.endDate?.format('DD/MM/YYYY') || ''}
            onChange={onChange('endDate')}
            {...getReferenceProps({ onFocus: () => setFocus('endDate') })}
          />
        </Label>
      </div>

      {isOpen && (
        <FloatingPortal>
          <div ref={refs.setFloating} style={{ ...floatingStyles, zIndex: 200 }} {...getFloatingProps()}>
            <DayPickerRangeController
              startDate={props.startDate}
              endDate={props.endDate}
              numberOfMonths={2}
              onClose={() => setIsOpen(false)}
              onDatesChange={props.onDatesChange}
              minimumNights={0}
              focusedInput={focus}
              onFocusChange={(inputFocused) => setFocus(inputFocused ? inputFocused : 'startDate')}
              initialVisibleMonth={() => moment()}
            />
          </div>
        </FloatingPortal>
      )}
      {/*<DateRangePickerBase
        ref={ref}
        startDateId={'start-date-id'}
        endDateId={'end-date-id'}
        focusedInput={focus}
        noBorder
        startDateAriaLabel={'test'}
        customArrowIcon={<></>}
        onFocusChange={(inputFocused) => setFocus(inputFocused)}
        {...props}
      />*/}
    </div>
  );
}

export default DateRangePicker;
