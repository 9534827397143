/** @jsxImportSource @emotion/react */
import React, { HTMLAttributes, PropsWithChildren, useContext } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ChevronIcon } from '../../../const/icons';
import { getColor } from '../../../utils/style';
import AccordionContext from './AccordionContext';

type AccordionSummaryProps = HTMLAttributes<HTMLDivElement> & {
  expandIcon?: JSX.Element;
};

const defaultExpandIcon = (
  <ChevronIcon
    css={css`
      transform: rotate(90deg);
    `}
    width={8}
    height={8}
  />
);

function AccordionSummary({
  expandIcon = defaultExpandIcon,
  children,
  className,
}: PropsWithChildren<AccordionSummaryProps>) {
  const { expanded, toggle } = useContext(AccordionContext);

  return (
    <AccordionSummaryRoot aria-expanded={expanded} className={className} onClick={toggle}>
      <AccordionSummaryContent>{children}</AccordionSummaryContent>
      {expandIcon && <AccordionExpandIconWrapper expanded={expanded}>{expandIcon}</AccordionExpandIconWrapper>}
    </AccordionSummaryRoot>
  );
}

const AccordionSummaryRoot = styled.button`
  background-color: white;
  display: flex;
  align-items: center;
  padding: 8px;
  outline: none;
  border: none;
  cursor: pointer;
  color: ${(props) => (props['aria-expanded'] ? 'white' : getColor('gray.300')(props))};

  :focus-visible,
  :focus {
    outline: none;
  }
`;

const AccordionSummaryContent = styled.div`
  margin-right: 10px;
`;

const AccordionExpandIconWrapper = styled.div<{ expanded: boolean }>`
  display: inline-flex;
  padding-bottom: 1px;

  > svg {
    transition: all 0.4s ease-in-out;
    ${(props) => props.expanded && expandedIconStyles}
  }
`;

const expandedIconStyles = css`
  padding-bottom: 0;
  transform-origin: center center;
  transform: rotate(90deg) scale(-1);
`;

export default AccordionSummary;
