import React, { ComponentType } from 'react';
import { createRoutesFromElements, redirectDocument, Route as RouteComponent, RouteObject } from 'react-router-dom';
import { QueryClient } from '@tanstack/react-query';
import { router, transformAppRouterObjectForRouterDom } from '../utils/router';
import ForgottenPassword from '../auth/components/ForgottenPassword';
import SignIn from '../auth/components/SignIn';
import DefaultLayout from '../components/Layout/DefaultLayout';
import Dashboard from '../dashbaord/components/Dashboard';
import NotFound from '../pages/NotFound';
import PasswordChangePage from '../pages/PasswordChange/PasswordChangePage';
import Route from '../utils/route';
import StatsLayout, { statsLayoutLoader } from '../pages/stats/StatsLayout';
import RawDataPage, { rawDataAction, rawDataLoader } from '../pages/stats/RawData.page';
import IndicatorPage, { indicatorLoader } from '../pages/stats/Indicator.page';
import CustomExportPage, { customExportAction, customExportLoader } from '../pages/stats/CustomExport.page';
import BikeListPage, { bikeListAction, bikeListLoader } from '../pages/Parameters/Products/Bike/BikeList.page';
import ProductsLayout from '../pages/Parameters/Products/Products.layout';
import NewBikePage, { newBikeLoader } from '../pages/Parameters/Products/Bike/NewBike.page';
import PriceListPage, { priceListAction, priceListLoader } from '../pages/Parameters/PriceList/PriceList.page';
import PriceListLayout from '../pages/Parameters/PriceList/PriceList.layout';
import DurationListPage, {
  durationListAction,
  durationListLoader,
} from '../pages/Parameters/PriceList/DurationList.page';
import TypePriceListPage, {
  priceTypeListAction,
  priceTypeListLoader,
} from '../pages/Parameters/PriceList/TypePriceList.page';
import EditBikePage, { editBikeAction, editBikeLoader } from '../pages/Parameters/Products/Bike/EditBike.page';
import AccessoryListPage, {
  accessoryListAction,
  accessoryListLoader,
} from '../pages/Parameters/Products/Accessory/AccessoryListPage';
import NewAccessoryPage, { newAccessoryLoader } from '../pages/Parameters/Products/Accessory/NewAccessoryPage';
import NewPricePage, { newPriceLoader } from '../pages/Parameters/PriceList/NewPrice.page';
import EditAccessoryPage, {
  editAccessoryAction,
  editAccessoryLoader,
} from '../pages/Parameters/Products/Accessory/EditAccessory.page';
import ParkListPage, { parkListAction, parkListLoader } from '../pages/Parameters/Products/Park/ParkListPage';
import NewParkPage, { newParkLoader } from '../pages/Parameters/Products/Park/NewParkPage';
import EditParkPage, { editParkAction, editParkLoader } from '../pages/Parameters/Products/Park/EditParkPage';
import FreeParkListPage, {
  freeParkListAction,
  freeParkListLoader,
} from '../pages/Parameters/Products/FreePark/FreeParkListPage';
import NewFreeParkPage, { newFreeParkLoader } from '../pages/Parameters/Products/FreePark/NewFreeParkPage';
import EditFreeParkPage, {
  editFreeParkAction,
  editFreeParkLoader,
} from '../pages/Parameters/Products/FreePark/EditFreeParkPage';
import EditPricePage, { editPriceLoader } from '../pages/Parameters/PriceList/EditPrice.page';

export type RouteObjectExtended = RouteObject & {
  layout?: string;
};

export function createLazyComponent(factory: () => Promise<{ default: ComponentType }>) {
  const LazyComponent = React.lazy(factory);
  return <LazyComponent />;
}

export const passwordChangeRoute = new Route('/changement-mot-de-passe', <PasswordChangePage />);
export const passwordForgotten = new Route('/mot-de-passe-oublie', <ForgottenPassword />);
export const homeRoute = new Route('/', <Dashboard />, undefined, { name: 'home', authorization: 'dashboard-voir' });
export const signInRoute = new Route('connexion', <SignIn />);
/*export const statsRoute = new Route('/statistiques/collectivite', <StatsCollectivity />, undefined, {
  name: 'stats',
  authorization: ProfilesPage.STATS_GLOBAL,
});*/

const newRouter = transformAppRouterObjectForRouterDom(router.home.children);

const routes: (queryClient: QueryClient) => RouteObjectExtended[] = (queryClient) => [
  // signInRoute.formatForRouter(),
  {
    id: 'signIn',
    path: 'connexion',
    element: <SignIn />,
    action: () => {
      return redirectDocument('..');
    },
  },
  passwordChangeRoute.formatForRouter(),
  passwordForgotten.formatForRouter(),
  {
    path: '/',
    element: <DefaultLayout />,
    loader: () => {
      return null;
    },
    children: [
      ...newRouter,
      ...createRoutesFromElements([
        <RouteComponent id={'statsLayout'} path={'statistiques'} loader={statsLayoutLoader} element={<StatsLayout />}>
          <RouteComponent index element={<IndicatorPage />} loader={indicatorLoader(queryClient)} />
          <RouteComponent
            id={'stats-raw-data'}
            path={'donnee-brutes'}
            loader={rawDataLoader(queryClient)}
            action={rawDataAction(queryClient)}
            element={<RawDataPage />}
          />
          <RouteComponent
            id={'stats-custom-data'}
            path={'donnees-personnalisees'}
            loader={customExportLoader(queryClient)}
            action={customExportAction(queryClient)}
            element={<CustomExportPage />}
          />
        </RouteComponent>,
        <RouteComponent id={'price-list'} path={'parametres/grille-tarifaire'} element={<PriceListLayout />}>
          <RouteComponent
            id={'price-list-index'}
            index
            element={<PriceListPage />}
            loader={priceListLoader(queryClient)}
          />
          <RouteComponent
            id={'duration-list'}
            path={'duree'}
            element={<DurationListPage />}
            action={durationListAction(queryClient)}
            loader={durationListLoader(queryClient)}
          />
          <RouteComponent
            id={'type-price-list'}
            path={'type'}
            element={<TypePriceListPage />}
            action={priceTypeListAction(queryClient)}
            loader={priceTypeListLoader(queryClient)}
          />
        </RouteComponent>,
        <RouteComponent id={'productsLayout'} path={'parametres/articles'} element={<ProductsLayout />}>
          <RouteComponent
            id={'bikeList'}
            action={bikeListAction(queryClient)}
            loader={bikeListLoader(queryClient)}
            element={<BikeListPage />}
            index
          />
          <RouteComponent
            id={'accessoryList'}
            loader={accessoryListLoader(queryClient)}
            action={accessoryListAction(queryClient)}
            path={'accessoire'}
            element={<AccessoryListPage />}
          />
          <RouteComponent
            id={'parkList'}
            loader={parkListLoader(queryClient)}
            action={parkListAction(queryClient)}
            path={'consigne'}
            element={<ParkListPage />}
          />
          <RouteComponent
            id={'freeParkList'}
            loader={freeParkListLoader(queryClient)}
            action={freeParkListAction(queryClient)}
            path={'consigne-libre'}
            element={<FreeParkListPage />}
          />
        </RouteComponent>,
        <RouteComponent
          id={'new-price'}
          element={<NewPricePage />}
          loader={newPriceLoader(queryClient)}
          action={priceListAction(queryClient)}
          path={'parametres/grille-tarifaire/prix/nouveau'}
        />,
        <RouteComponent
          id={'edit-price'}
          element={<EditPricePage />}
          loader={editPriceLoader(queryClient)}
          action={priceListAction(queryClient)}
          path={'parametres/grille-tarifaire/prix/:id'}
        />,
        <RouteComponent
          id={'new-bike'}
          element={<NewBikePage />}
          loader={newBikeLoader(queryClient)}
          action={bikeListAction(queryClient)}
          path={'parametres/articles/velo/nouveau'}
        />,
        <RouteComponent
          id={'edit-bike'}
          element={<EditBikePage />}
          loader={editBikeLoader(queryClient)}
          action={editBikeAction(queryClient)}
          path={'parametres/articles/velo/:id'}
        />,
        <RouteComponent
          id={'new-accessory'}
          element={<NewAccessoryPage />}
          loader={newAccessoryLoader(queryClient)}
          action={accessoryListAction(queryClient)}
          path={'parametres/articles/accessoire/nouveau'}
        />,
        <RouteComponent
          id={'edit-accessory'}
          element={<EditAccessoryPage />}
          loader={editAccessoryLoader(queryClient)}
          action={editAccessoryAction(queryClient)}
          path={'parametres/articles/accessoire/:id'}
        />,
        <RouteComponent
          id={'new-park'}
          element={<NewParkPage />}
          loader={newParkLoader(queryClient)}
          action={parkListAction(queryClient)}
          path={'parametres/articles/consigne/nouveau'}
        />,
        <RouteComponent
          id={'edit-park'}
          element={<EditParkPage />}
          loader={editParkLoader(queryClient)}
          action={editParkAction(queryClient)}
          path={'parametres/articles/consigne/:id'}
        />,
        <RouteComponent
          id={'new-free-park'}
          element={<NewFreeParkPage />}
          loader={newFreeParkLoader(queryClient)}
          action={freeParkListAction(queryClient)}
          path={'parametres/articles/consigne-libre/nouveau'}
        />,
        <RouteComponent
          id={'edit-free-park'}
          element={<EditFreeParkPage />}
          loader={editFreeParkLoader(queryClient)}
          action={editFreeParkAction(queryClient)}
          path={'parametres/articles/consigne-libre/:id'}
        />,
      ]),

      /*{
        index: true,
        ...homeRoute.formatForRouter(),
      },*/
      // userRoutes.formatForRouter(),
      // parametersRoutes.formatForRouter(),
      // adminRoutes.formatForRouter(),
      // parkRoutes.formatForRouter(),
      // fundsRoutes.formatForRouter(),
      // invoicingRoutes.formatForRouter(),
      // statsRoute.formatForRouter(),
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
];

export default routes;
