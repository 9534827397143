/* eslint-disable max-len */

export default {
  // translation: {
  global: {
    Dashboard: 'Dashboard',
    sign_in: 'Sign in',
    sign_out: 'Sign out',
    home: 'Home',
    prev: 'Prev',
    next: 'Next',
    bike: 'Bike',
    park: 'Bike park',
    free_park: 'Free Bike park',
    forgot_pwd: 'Password forgotten',
    go_back: 'Go back',
    search: 'Search : ',
    search_by_user: 'Seach by user: ',
    daily_fund: 'Day',
    from: 'From',
    to: 'To',
    collectivity: 'Collectivité',
    display: 'Display ',
    elements: 'elements',
    yes: 'yes',
    no: 'no',
    add: 'Add',
    users: 'Users',
    title: 'Title',
    subtitle: 'Subtitle',
    delete: 'Delete',
    reset: 'Reset',
  },
  filters: {
    all: 'All',
    bikesType: 'Bike type',
    status: 'Status',
    parksType: 'Park type',
    articlesType: 'Article type',
    bookingStatus: 'Status',
    accessoriesType: 'Accessories type',
    pdls: 'Delivery point / Park location',
    empl: 'Park location',
    articles: 'Article',
  },
  stats: {
    turnover_title: 'Turnover by means of payment',
    turnover_by_article: 'Turnover and number of reservations by type of article',
  },
  export: {
    title: 'Export',
    validate: 'Validate',
    cancel: 'Cancel',
    format: {
      csv: 'CSV',
      pdf: 'PDF',
    },
  },
  navigation: {
    home: 'Dashboard',
    administration: 'Administration',
    create: 'Create a user',
    validation: 'Validate a user',
    list: 'Users list',
    edit: 'User File',
    invoices: 'Invoices',
    contracts: 'Contracts',
    bookings: 'Bookings',
    bikes: 'park bike management',
    consignepark: 'free park management',
    consigne: 'Park management',
    resadetail: 'Reservation details',
    signresa: 'Contract sign',
    checkout: 'User cashings',
    daily: 'Daily Fund',
    moves: 'Fund moves',
    collectivity: 'Collectivité',
    waiting_list: 'Waiting list',
    renewal: 'Renewal',
    newresa_bike: 'Nouvelle réservation de Vélo',
    newresa_park: 'Nouvelle réservation de consigne',
    newresa_freepark: 'New freepark reservation',
    newresa: 'New reservation',
    stripe: 'Online checkout',
    prices: 'Price configuration',
    param_contracts: 'Contracts configuration',
    deposits: 'Deposits configuration',
    security_deposits: 'Security deposits configuration',
    degradations: 'Degradations configuration',
    delays: 'Delays configuration',
    broadcast: 'Broadcast configuration',
    visual: 'Theme configuration',
    locales: 'Languages configuration',
    forms: 'Forms configuration',
    pages: 'Pages configuration',
    workshop: 'Workshop page configuration',
    contact: 'Contact page configuration',
    modules: 'Active modules configuration',
    deposit: 'deposit configuration',
    booking: 'Booking configuration',
    vouchers: 'Vouchers configuration',
    permission: 'Agents, readers and managers configuration',
    checkin_checkout: 'Inventory configuration',
    pdl: 'Delivery point configuration',
  },
  error: {
    cannotSetNegOrZeroPrice: 'The amount should be upper than zero',
    depotNameTooLong: 'Deposit name is too large',
    montant_htIsEmpty: 'The amount is needed',
    cannotSetZeroPrice: 'The amount should be upper than zero',
    tooSmallDateEnd: 'The end date must be after the start date',
    alreadyRegisteredAgent: 'This agent is already registered',
    meanNotAvailable: 'Payment method not available for this type of refund',
    motif_utilisationIsEmpty: 'Use reason is mandatory',
    wrongReservation: 'Wrong booking',
    paymentNotCompleted: 'Payment not completed',
    transactionNotFoundForRefund: 'The transaction does not exist or has not been captured',
    badTransactionRefundAmount: 'Refund already done or amount too large',
    cannotRefundTransaction: 'Cannot refund transaction',
    cannotCancelToken: 'Cannot cancel SEPA',
    tooBigRefund: 'Refund amount is too big',
    refundAlreadyDone: 'The refund has already been done',
    resaNotFound: 'Booking not found',
    natureIsEmpty: 'Nature is mandatory',
    ibanIsMissing: 'IBAN is missing',
    bicIsMissing: 'BIC is missing',
    sogecommerceError: 'Error from Sogecommerce',
    invalidPasReservation: 'Invalid time, please select a valid booking time',
    notAvailableAccessoire: 'Accessory not available or already reserved',
    default: 'A problem occurred',
    amountTooSmall: 'Amount too small',
    noDepotGarantieForUser: 'No security deposit for this user',
    rbtBiggerThanDeposit: 'The amount of the refund may not exceed the amount of the security deposit',
    minDuration: 'Minimum duration not respected',
    noNegativeValue: 'negative booking limit',
    missingParameters: 'You have not filled all mandatory fields.',
    codeOrValidated: 'Please verify the information provided .Beware : you can only validate a code once.',
    alreadyAccount: 'You are already registered with this email, please sign in !',
    wrongCredentials: 'You have entered a wrong username or wrong password',
    unknownError: "Une erreur inattendue s'est produite. Veuillez réessayer plus tard.",
    invalidOrExpiredToken: 'Your session is expired. Please login in.',
    missingToken: 'Your session is expired. Please login in.',
    emailNotValidated: 'You have not yet authorized your e-mail. Please check your inbox and login in.',
    expiredBooking: 'Your reseration has expired. Please do the reservation process again',
    noAvailability: 'No availability for the requested dates. Please change your reservation dates or try again',
    maxResaCapacity: 'You have reached the maximum reservation capacity for your account.',
    unknownUser: "It seems your e-mail address doesn't exist.",
    pleaseWait: 'You can only ask for a new password every 10 minutes.',
    passwordTooShort: 'Your password is too short.',
    passwordsDontMatch: "The password and it's confirmation don't match.",
    wrongResetCode: 'The link you are using seems broken.',
    resetCodeExpired: 'The link you are using seems broken, please try the password reset process again.',
    resetCodeAlreadyUsed:
      'You already have used this reset password link, please try the password reset process again.',
    wrongPayment: 'Wrong payment',
    unallowedUser: "Accès non autorisé. Vous n'avez pas accès à cette fonctionnalité",
    invalidStateTo: 'You cannot change to this state',
    invalidStateFrom: 'You cannot change state from this state',
    emailError: 'We are sorry. An email could not be send to the administrator',
    minWidthError: "The image you upload doesn't meet the minimum required width",
    defaultLangNotInLangs: 'The default language is not found in the chosen languages',
    mustBeAnAdult: 'You must be 18 years old !',
    password_error: '8 or more characters and only letters and capitals',
    civiliteIsEmpty: 'Title is mandatory',
    entr_assoIsEmpty: 'Company/Organization is mandatory',
    nomIsEmpty: 'Firstname is mandatory',
    prenomIsEmpty: 'Lastname is mandatory',
    emailIsEmpty: 'Email is mandatory',
    num_adherentIsEmpty: 'Membership number is mandatory',
    date_exp_adherentIsEmpty: 'Membership expiration date is mandatory',
    motif_adhesionIsEmpty: 'Reason for membership is mandatory',
    motif_adhesion_autreIsEmpty: 'Other reason for membership is mandatory',
    date_naissanceIsEmpty: 'Birthdate is mandatory',
    depotNameIsEmpty: 'Deposit name is mandatory',
    pays_origIsEmpty: 'Country of birth is mandatory',
    adresseIsEmpty: 'Address is mandatory',
    adresse_complIsEmpty: 'Address complement is mandatory',
    cpIsEmpty: 'ZIP code is mandatory',
    villeIsEmpty: 'City is mandatory',
    paysIsEmpty: 'Country is mandatory',
    telephoneIsEmpty: 'Phone number is mandatory',
    telephone_portIsEmpty: 'Cellphone number is mandatory',
    ibanIsEmpty: 'IBAN is mandatory',
    bicIsEmpty: 'BIC is mandatory',
    abotcIsEmpty: 'Navigo Transportation card ID is mandatory',
    wrongIban: 'IBAN is not valid',
    wrongBic: 'BIC is not valid',
    malformatedInput: 'Malformated input',
    missingVelocareTypeConfig: 'Missing Velocare Type configuration',
    boursierIsMissing: 'Scolarship is missing',
    boursierIsEmpty: 'Scolarship is mandatory',
    cannotChangeDueToSyncAndResa: 'You cannot change because you have already made a sync and reservation',
    velocareTypeAlreadyAssigned: 'This Velocare Type is already assigned',
    cannotEditVelocareSyncedEmpl: 'You cannot edit Velocare synced locations',
    cannotEditVelocareSyncedPdl: 'You cannot edit Velocare synced delivery point',
    resaInTheFuture: 'This article is reserved for a later date',
    noEndForRepare: "You can't finish the reservation: bike under repair",
    missingUrlParameter: 'Invalid URL',
  },
  sidebar: {
    payment_providers: 'Payment providers',
    users: 'Users',
    admin_coll: 'Collectivity administration',
    waiting_list: 'Waiting list',
    admin: 'Administration',
    create_users: 'Create a user',
    users_list: 'Users list',
    park: 'Manage parks',
    park_bikes: 'Bikes',
    consigne_park: 'Free bike parks',
    consigne: 'Bike parks',
    invoicing: 'Invoincing and contracts',
    fund: 'Fund',
    parameters: 'Parameters',
    stats: 'Stats',
    daily: 'Daily',
    moves: 'Moves',
    collectivity: 'Collectivity',
    renewal: 'Renewal',
    contracts: 'Contracts',
    broadcasting: 'Broadcasting tools',
    prices: 'Prices',
    deposits: 'Deposits',
    security_deposits: 'Security deposits',
    degradations: 'Dégradations',
    delays: 'Delays',
    theme: 'Visuel',
    permission: 'Agents, readers, managers',
    locales: 'Locales',
    forms: 'Forms',
    pages: 'Pages',
    workshop: 'Workshop page',
    contact: 'Contact page',
    modules: 'Active modules',
    config_deposit: 'Caution config',
    booking_deposit: 'Réservation config',
    vouchers: 'Vouchers',
    checkin_checkout: 'Inventory',
    accessories: 'Accessories',
  },
  dashboard: {
    rv_0: 'Reservations starting today',
    rc_0: 'Park reservations starting today',
    rcp_0: 'Free park reservations starting today',
    rv_30: 'Bike reservation starting tomorrow +30d',
    rv_e0: 'Bike reservation ending today',
    rv_ed: 'Bike reservations already expired',
    rc_ed: 'Park reservation already expired',
    rcp_ed: 'Free park reservations already expired',
  },
  home: {
    welcome: 'Welcome',
    on_tool: 'on the bike rental and deposit tool of the agglomeration of',
    locality: 'Saint-Quentin-en-Yvelines',
  },
  sign_in: {
    please_sign_in: 'Please sign in',
    forgotten_pwd: 'Password forgotten ?',
    sign_up: 'Sign in',
    no_account: 'No account ?',
    forgotten_password: "We've send you an email to reset your password",
    pwd_reset_success: 'Votre mot de passe a bien été réinitialisé',
    log_in: 'Se connecter',
    welcome: 'Bienvenue sur Veloclik',
  },
  forgotten_pwd: {
    title: 'Mot de passe oublié',
    reset: 'Reset',
    success: 'Your password has been reset !',
    pwd_error: '2 or more characters and only letters and capitals',
  },
  footer: {
    copyright: 'Copyright © {{ year }} ',
    all_rights_reserved: '. All rights reserved. v.{{ version }}',
  },
  reservations: {
    form_start_date: 'Start date',
    form_end_date: 'End date',
    waiting_list: 'position : {{ queue }}',
    accessories: 'Accessories : ',
    modify_start_date: 'Modify start date',
    modify_end_date: 'Modify end date',
    valid: 'Valid',
    renewal: 'Renewal',
    file_uploaded: 'Your file has been uploaded !',
    statement_submit: 'Your statement has been well submitted !',
    comment_submit: 'Your comment has been well submitted !',
    client_name: '<st>{{ lastName }}</st> {{ firstName }} - Reservation details',
    user_name: 'User : {{ lastName }} {{ firstName }}',
    done: 'Reservation taken on : {{ date }} at {{ hour }}',
    valid_date: 'Actual start date: {{ date }} at {{ hour }}',
    number: 'number',
    resa_sum: 'Reservation : {{ article }} {{ attribution }} - Type : {{ type }}',
    free_park: 'free park',
    not_attributed: 'Not attributed',
    pdl: 'Agency : {{ pdl }}',
    queue: 'Queue position : {{ queue }}',
    place: 'Park emplacement : <st>{{ place }}</st>',
    start_date: 'Start date : {{ date }} at {{ hour }}',
    end_date: 'End date : {{ date }} at {{ hour }}',
    date_return: 'Effective return date : {{ date }} à {{ hour }}',
    date_cancel: 'Cancelation date : {{ date }} at {{ hour }}',
    date_refund_deposit: 'deposit release date : {{ date }} at {{ hour }}',
    price: 'Reservation price : {{ amount }} {{ currency }} VAT - Tariff : <st>{{ price }}</st>',
    security_deposit: 'Security deposit price : {{ securityDepositAmount }} {{ currency }} VAT',
    deposit: 'deposit price : {{ depositAmount }} {{ currency }} VAT',
    iban_bic: 'IBAN / BIC',
    iban: 'IBAN',
    bic: 'BIC',
    issue: "There's an issue with your bike/park emplacement ? You can change now.",
    is_not_attributed: 'Manual assignment ? Change your accessories.',
    sign_resa: 'Sign contract',
    other_bike: 'Bike #{{ id }}',
    other_parks: 'Park #{{ id }}',
    change_bike: 'Change bike',
    assign: 'Assign',
    assign_bike: 'assign this bike',
    assign_park: 'assign this park',
    clear: 'Effacer',
    valid_sign: 'Valider la signature',
    client_name_sign: '<st>{{ name }}</st> {{ firstName }} - contract sign',
    confirm_resa: 'Confirm reservaiton',
    contract_generation: 'The rental contract ans SEPA mandate will be generated',
    choose_bike: 'Choose a bike before validate :',
    cancel: 'Cancel reservation',
    cancelation_text: "No availability ? You have created reservation by mistake ? You can't cash your customer ?",
    validation_contract:
      'This reservation was confirmed on : {{ dateValidation }} at {{ hourValidation }} in agency : {{ pdl }}',
    contract: 'Rental contract',
    standard_exchange: 'Standard exchange',
    std_exchange_stolen: 'Standard exchange (stolen bike)',
    are_you_sure: 'Are you sure ?',
    resa_cancel_confirm: 'You will cancel the reservation, are you sure ?',
    resa_release_deposit_confirm: 'You will release the deposit, are you sure ?',
    end_rent: 'Reservation end :',
    pdl_option: 'Available to rent (in that rental point)',
    repare_option: 'Not available to rent (needs repair)',
    stolen_option: 'Not available to rent (stolen bike)',
    end_rent_button: 'End of reservation - bike return',
    refund_deposit: 'Release deposit',
    refund_deposit_success: 'Your deposit has been released',
    button_yes: "Yes, i'm sure",
    button_no: 'No, I give up',
    date: "Date de l'envoi PJ",
    type: 'Type',
    operation: 'Opération',
    send_attachments: 'Envoyer',
    title_attachments: "Pièces d'identité, IBAN, justificatifs de transport...",
    add_iban: 'Add a new voucher :',
    checkout_type: 'Choisissez...',
    create_checkout: 'Créer encaissement',
    title_table: 'Liste des encaissements :',
    cb: 'CB',
    cheque: 'Chèque',
    security_deposit_type: 'Security deposit',
    rental: 'Rental',
    other: 'Other',
    degradation_title: 'Encaissement Dégradation',
    delay_title: 'Encaissement Retard',
    regularization_title: 'Collection Regularization',
    commentary: 'Commentary',
    end_park: "End of park rent (don't consider this in case of free park) :",
    end_park_button: 'End of park rent',
    available_end: 'Available to rent',
    unavailable_end: 'Unavailable to rent',
    checkin: {
      title: 'End of rental inventory',
      title_checkin: 'Condition of equipment - start of rental',
      title_checkout: 'Condition of equipment - end of rental',
      general_commentaries: 'General commentaries',
      label: 'Controlled element',
      media: 'Photo - start of rental',
      status: 'Status - start of rental',
      commentary: "Controller's comment - start of rental",
      button_yes: 'Valid',
      button_no: 'Cancel',
      is_mandatory: '(Mandatory)',
      forgotten_files: 'You forgot to upload some photos',
      good_condition: 'Good condition',
      defective: 'Defective',
      media_checkout: 'Photo - start of rental',
      status_checkout: 'Status - start of rental',
      commentary_checkout: 'Commentary - start of rental',
    },
    article_type: {
      consigne: 'bike park',
      consignepark: 'free bike park',
      velo: 'bike',
    },
    statuses: {
      validated: 'validated',
      cancelled: 'cancelled',
      finished: 'finished',
      waiting: 'waiting',
      waiting_list: 'In waiting list',
    },
    cb_transac: 'Order reference CC : {{ cb_transac_id }}',
    rum: 'Transaction reference deposit SEPA : {{ rum }}',
    sepa_transac: 'SEPA deposit order reference: {{ sepa_transac_id }}',
    date_start: 'End date : ',
    date_end: 'Start date : ',
  },
  users: {
    paper_sepa: 'Generation paper SEPA mandate',
    no_paper_sepa: 'No SEPA paper mandate generation',
    pdl: 'Delivery point / Park location',
    usagers: 'Users',
    statuses: {
      need_validate: 'Need to validate',
      validated: 'Validated',
    },
    accessories: {
      title: '- Accessories',
      name: 'Name',
      price: 'Price VAT per duration',
      use: 'Use',
      empty: 'No accessorie available',
      dispo: 'Available',
      total: 'Total price : {{ price }} {{ currency }} VAT',
    },
    has_security_deposit:
      '( Security deposit {{ securityDepositAmount }} {{ currency }} + Reservation {{ articleAmount }} {{ currency }} )',
    online_checkout: 'Pay online',
    pj_check: 'Voucher check : <b>{{ pjName }}</b>',
    reservation_link: '<icon/> {{ count }} réservation',
    reservation_link_plural: '<icon/> {{ count }} reservations',
    checkout_ok: 'The payment has been made',
    do_online_pay: 'Proceed to payment online',
    cash_online: 'Cash online',
    checkout_amount: '{{ amount }} {{ currency }}',
    confirm_user: 'User to be validated',
    edit_file: 'View file / Edit',
    cashings: 'Agency collections',
    date: 'Receipt date',
    dateContract: 'Contrat date',
    dateReservation: 'Booking date',
    dateCheckout: 'Collection date',
    checkoutOperation: 'Operation',
    paymentMethod: 'Payment type',
    status: 'Status',
    amount: 'Amount',
    amount_vat: '{{ amount }} {{ currency }} VAT INCLUDED',
    operation: 'Operation',
    actions: 'Actions',
    see_print: 'See / Print',
    see_validate_back: 'View / Confirm / Return',
    display_first_el: 'Display {{ index }} of ',
    x_elements_displayed: '{{ perPage }} on {{ count }} element',
    x_elements_displayed_plural: '{{ perPage }} on {{ count }} elements',
    name: 'Name',
    address: 'Address',
    users_list: 'Users List',
    users: 'Users',
    empty: 'No data available',
    title: 'Create a user',
    nature: 'Nature',
    individual: 'Individual',
    association: 'Association',
    company: 'Company',
    administration: 'Administration',
    civility: 'Civility',
    mr: 'Mr.',
    mrs: 'Ms.',
    firm: 'Company',
    firm_or_asso: 'Company / Association (optional)',
    last_name: 'Lastname',
    first_name: 'Firstname',
    email: 'Email',
    birthdate: 'Birth date',
    address_title: 'Adress',
    full_address: 'Additional address (optional)',
    zip: 'Postal Code',
    city: 'City',
    country: 'Country',
    phone: 'Phone',
    mobile_phone: 'Cellphone',
    create_new_resa: 'New reservation',
    btn: {
      create: 'Create user',
      edit: 'Edit user',
      validate: 'Validate user',
    },
    remark: "Make sure you have the user's email address, they will receive their password directly in their email box",
    email_error: 'The email format is incorrect',
    edit: {
      validated: 'This user was validated on {{ valid_date }} by {{ prenom }} {{ nom }}',
      non_validated: 'This user must be validated',
      created: 'This user created his account on {{ create_date }}',
      updated: 'Last updated on {{ update_date }}.',
      title_info: 'Informations:',
      title_access: 'Access :',
      agency_receipts: 'Agency collections',
      invoices_access: 'Access to invoices',
      contracts_access: 'Access to contracts',
      bookings: 'Reservations',
    },
    create_validated: 'The user account has been successfully created',
    update_validated: 'The user account is updated',
    user_validated: 'The user account is validated',
    new_resa: '{{ firstName }} - new reservation',
    bike_type: '- Bike type :',
    freepark_type: '- Type de consigne libre :',
    park_type: '- Type de consigne :',
    choose: 'Choose...',
    rental_duration: '- Location duration :',
    choose_bike_before: 'Choose a bike before',
    choose_duration_before: 'Choose a rental duration before...',
    rental_prices: '- Rental prices :',
    duration_price: '({{ price }} {{ currency }} VAT)',
    id_check: '- Identity check : <bold>Ask the user for a proof of identity</bold>',
    deposit_information: 'deposit information : {{ depositAmount }} {{ currency }} VAT',
    summary: '- Summary',
    security_deposit_information: 'Security deposit : {{ securityDepositAmount }} {{ currency }} VAT',
    total_price_with_accessories: 'Total price booking, with accessories : {{ price }} {{ currency }} VAT',
    create_resa: 'Create reservation',
    rent_conditions: `When clicking this button, <bold>you insure the bike you want to rent is available at the requested dates with the green info box below</bold><lineBreak />
      <bold>An invoice will be generated</bold> : don't forget<bold>to cash out customer with the selected payment method <u>BEFORE YOU GO NEXT STEP.</u></bold><lineBreak />
      An available bike will automatically being given to the customer<lineBreak />
      The customer will have access the rental contract (including SEPA mandate), and the invoice to it's customer space !`,
    rent_conditions_no_payment: `When clicking this button, <bold>you insure the bike you want to rent is available at the requested dates with the green info box below</bold><lineBreak />
      An available bike will automatically being given to the customer<lineBreak />`,
    rent_park_conditions: `When clicking this button, <bold>you insure the bike you want to rent is available at the requested dates with the green info box below</bold><lineBreak />
      The park reservations take from 00:00:01 to 23:59:59<lineBreak />
      <bold>An invoice will be generated</bold> : don't forget<bold>to cash out customer with the selected payment method <u>BEFORE YOU GO NEXT STEP.</u></bold><lineBreak />
      An available bike will automatically being given to the customer<lineBreak />
      The customer will have access the rental contract (including SEPA mandate), and the invoice to it's customer space !`,
    rent_park_conditions_no_payment: `When clicking this button, <bold>you insure the bike you want to rent is available at the requested dates with the green info box below</bold><lineBreak />
      The park reservations take from 00:00:01 to 23:59:59<lineBreak />
      An available bike will automatically being given to the customer<lineBreak />`,
    user_info: '- Rent user informations :',
    rent_duration: '- Rent duration :',
    max_duration: 'Max/min duration for the requested dates :',
    selected_duration: 'Selected duration : ',
    availability: 'Availability :',
    availability_details: '{{ item }} on the selected dates',
    days: 'days',
    park_id_check: '- Identity check : <bold>Ask the user for a proof of identity</bold>',
    park_public_transport: '- Public transport check : <bold>Ask the user for a proof of identity</bold>',
    checkout: {
      collection_flag:
        'Pour tout encaissement en agence lié à une réservation (dégradation, retard, etc.) veuillez-vous rendre sur',
      collection_info:
        'Un encaissement génère automatiquement une facture. Votre usager pourra la retrouver dans son espace usager.',
      collection_reserv: "l'espace réservation",
      refund_flag:
        "Pour tout remboursement en agence lié à un paiement INTERNET, s'adresser au régisseur et lui fournir <bold>le nom de l'usager, le montant et l'heure du paiement concerné</bold>.<lineBreak/>",
      refund_info:
        'Un remboursement génère automatiquement une facture. Votre usager pourra la retrouver dans son espace usager.',
      collection_title: 'Encaissement manuel',
      refund_title: 'Remboursement manuel',
      collection_btn: 'Créer encaissement',
      refund_btn: 'Créer remboursement',
      table_title: 'Liste des encaissements/remboursements :',
      valid_form: 'Opération réussie',
    },
  },
  duration: {
    year: 'year',
    month: 'month',
    day: 'day',
    trimester: 'trimester',
  },
  bikes: {
    veloNumber: 'Bike number',
    bicycode: 'Bicycode',
    bikeType: 'Bike type',
    reservation: 'Reservation',
    state: 'Status',
    affectation: 'Affectation',
    resa_amount: '{{montant_ttc}} {{ currency }} - {{nom_tarif}}',
    bike_state: ' - {{date_updated}} : Status : {{etat}} - Affectation = {{nom_pdl}} ({{user}})',
    no_history: 'No history found for this bike',
    bike_history: 'History of bike n°#{{bikeID}}',
    leased: 'Leased',
    wasted: 'Wasted',
    transit: 'On transit',
    on_repair: 'On repair',
    stolen: 'Stolen',
    lent: 'Lent',
    for_sale: 'For sale',
    sold: 'Sold',
    booking_available: 'Available for loan',
    available_rent: 'Available to booking',
    new: 'New',
    change_state: 'Change status',
    change: 'Change',
    change_affectation: 'Change affectation',
    end_rent: 'End rent',
  },
  accessories: {
    accessoryNumber: 'Accessory number',
    accessoryType: 'Accessory type',
    reservation: 'Reservation',
    state: 'Status',
    affectation: 'Affectation',
    resa_amount: '{{montant_ttc}} {{ currency }} - {{nom_tarif}}',
    bike_state: ' - {{date_updated}} : Status : {{etat}} - Affectation = {{nom_pdl}} ({{user}})',
    no_history: 'No history found for this bike',
    bike_history: 'History of bike n°#{{bikeID}}',
    leased: 'Leased',
    wasted: 'Wasted',
    transit: 'On transit',
    on_repair: 'On repair',
    stolen: 'Stolen',
    lent: 'Lent',
    booking_available: 'Available for loan',
    available_rent: 'Available to booking',
    new: 'New',
    change_state: 'Change status',
    change: 'Change',
    change_affectation: 'Change affectation',
    end_rent: 'End rent',
  },
  forms: {
    email_error: 'Email format is incorrect',
    abotc: 'Navigo ID',
    ask_rib: 'Ask for bank account information (RIB) :',
    cheque: 'Bank check',
    cash: 'Cash',
    credit_card: 'Credit card on site',
    cc_internet: 'Credit card online',
    payment_method: '- Payment type :',
    navigo_error: 'Navigo ID must contains only digits !',
    navigo: 'Navigo ID (10 digits)',
    invalid_iban: 'IBAN format is incorrect',
    invalid_bic: 'BIC format is incorrect',
    mandatoryField: 'Field is mandatory',
    dateStart: 'Start date',
    dateEnd: 'End date',
  },
  invoicing: {
    pdl: 'Delivery point / Park location',
    usagers: 'Usagers',
    clients: 'Clients',
    operation: 'Opérations',
    actions: 'Actions',
    dateContract: 'Date du contrat',
    dateInvoice: "Date de l'encaissement",
    amount: 'Montant',
    check: 'Voir',
    start: 'Début',
    end: 'Fin',
    status: 'Statut',
  },
  consignepark: {
    nom_empl: 'Emplacement',
    action: 'Actions',
    nom_type: 'Type de consigne',
    nb_places: 'Nombre de places',
    success: 'Votre consigne libre bien été mise à jour !',
    button: 'Modifier',
  },
  fund: {
    cb_agence: 'BC in agency',
    cb_internet: 'BC internet',
    cheque: 'Chèque',
    soustotal: 'Sub-total',
    location_velo: 'Bike rent',
    location_cons: 'Park rent',
    bike_renewal: 'Bikes renewal',
    depots_garantie: 'Security deposits',
    autres: 'Other cashings',
    totaux: 'TOTAL of cashinds',
    rbt: 'Refunds',
    totauxg: 'TOTAL (cashings - refunds)',
    date_fund: '{{ date }} fund',
    confirm_fund: 'Confirm my fund',
    report_error: 'Report an error',
    done_valid: 'Fund done on {{ date }} at {{ hour }} - <st>Fund valid</st>',
    done_error: 'Fund done on {{ date }} at {{ hour }} - <st>Error reported</st>',
    payment_solution: 'Moyen de réglement',
    total: 'Total HT',
    total_VAT: 'Total TTC',
    see_data: 'See data',
  },
  consigne: {
    nom_type: 'Park type',
    id_interne: 'Numéro de consigne',
    state: 'Etat',
    nom_empl: 'Emplacement',
    upstairs: 'In height ?',
    reparation: 'En réparation',
    available: 'Disponible à la location',
    rented: 'For rent',
  },
  deposit: {
    nom_type: 'Bike type',
    nom_caution: 'deposit name',
    montant_ht: 'deposit amount excl. taxes',
    taux_tva: 'VAT (%)',
    montant: 'deposit amount including taxes',
    action: 'Actions',
  },
  security_deposit: {
    nom_type: 'Bike type',
    nom_garantie: 'Security deposit name',
    montant_ht: 'Security deposit amount excl. taxes',
    taux_tva: 'VAT (%)',
    montant: 'Security deposit amount including taxes',
    action: 'Actions',
  },
  parameters: {
    all_bikes: 'All',
    bikes_types: 'Bikes types',
    limit_renewal: 'Renewal limit (in days)',
    limit_before_end: 'Number of days before the end of the rental period',
    limit_after_end: 'Number of days after the end of the rental period',
    pbx_url: 'Paybox URL',
    pbx_site: 'Store number',
    pbx_rang: 'Rank number',
    pbx_identifiant: 'ID Paybox',
    pbx_hmackey: 'HMAC key',
    monetico_tpe: 'TPE',
    monetico_code: 'Company code',
    monetico_url: 'URL Monetico',
    verification_text: 'Verification text',
    commercial_response: 'Commercial agent response mandatory',
    photo_used: 'Use a photo',
    photo_mandatory: 'Photo mandatory',
    select_delay: 'No reservation delay',
    booking_limit: 'Bookings limit',
    save: 'Save',
    generate_pdf: 'Generate PDF',
    last_pdf: 'Last generated PDF',
    cg_velo: 'Terms and conditions BIKE',
    cg_cons: 'Terms and conditions PARK',
    cg_cons_park: 'Terms and conditions FREE PARKS',
    see_on_velocare: 'See on Velocare',
    modification_success: 'Your changes has been saved',
    relance_email_velo: 'Bike rental',
    relance_email_cons_park: 'Free park rental',
    relance_email_cons: 'Park rental',
    email_inquiry: 'Email inquiry',
    velocareBusinessId: 'Velocare business ID',
    velocareSubsidiaryId: 'Velocare subsidiary ID',
    velocare_syncing: 'Syncing with Velocare',
    ask_sign: 'Ask customer sign',
    show_tariffs: 'Show tariffs',
    hours_restriction: 'Schedule restriction for the rental end date',
    automatic_attribution: 'Activate automatic attribution',
    sync_with_velocare: 'Congrats ! Veloclik is now synced with Velocare !',
    not_sync_with_velocare: 'Véloclik is not synced with Velocare. Please use the button below to begin the sync.',
    contract_end: 'Contract end',
    see_in_velocare: 'See in Vélocare',
    reminder_email: 'Email reminder -N days',
    reminder_message: 'Message messenger -N days',
    email_inquiry_plus: 'Email inquiry +N days',
    coll_name: 'Collectivity name',
    front_url: 'Front URL (user space) without http(s)://',
    insurance_url: 'Partner insurance URL',
    stripe_id: 'Stripe ID : pk_xxxxxxxx',
    certificat_key: 'API key',
    publickey_js: 'Public key Javascript',
    hmac_rest: 'HMAC-SHA-256 key',
    vads_ctx_mode: 'Shop mode',
    url_api: 'API URL',
    url_paiement_page: 'Paiement page URL',
    vads_site_id: 'Shop ID',
    password_rest: 'API REST Password',
    stripe_title: 'Stripe',
    sogecommerce_title: 'Sogecommerce',
    paybox_title: 'Paybox',
    monetico_title: 'Monetico',
    emails_admin: 'Managers email splitted by a semicolon aaa@aaaa.fr;bbbb@ggggg.com ...',
    alwin_id: 'Alwin - Web ID',
    alwin_pass: 'Alwin - Web Password',
    alwin_code: 'Alwin - SupervisorCodeId',
    privacy_policy: 'Privacy policy',
    choose_currency: 'Choose currency...',
    currency: 'Currency',
    waiting_list_time: 'Validation time',
    booking_limit_user: 'Simultaneous booking limit',
    update: 'Update',
    other_update: 'Modify',
    pdl: 'Delivery point',
    add_pdl: 'Add a delivery point',
    article: 'Article',
    type: 'Article type',
    name: 'Tariff name',
    durationName: 'Duration name',
    durationType: 'Duration type',
    minDuration: 'Min duration',
    maxDuration: 'Max duration',
    taxFreeAmountPerDuration: 'Price (without taxes) per duration',
    amountWithTaxPerDuration: 'Price (includes taxes) per duration',
    taxFreeAmount: 'Price without taxes',
    tax: 'TVA (%)',
    amountWithTax: 'Price (includes taxes)',
    actions: 'Actions',
    article_update: 'Your article has been updated !',
    deposit_success: 'The deposit has been updated !',
    security_deposit_sucess: 'The security deposit has been updated !',
    degradation_success: 'Your degradation has been updated !',
    add_degradation_success: 'Your degradation has been added !',
    title_degradation: 'New degradation',
    title_delay: 'New delay',
    tva: 'TVA',
    low_tva: '5.5%',
    normal_tva: '10%',
    high_tva: '20%',
    btn_add_degradation: 'Add',
    nom_degradation: 'Label of the degradation',
    nom_retard: 'Label of the delay',
    delay_success: 'Your delay has been updated !',
    add_delay_success: 'Your delay has been added !',
    nom_caution: 'Name of the deposit',
    nom_type: 'Type de vélo',
    article_placeholder: "Type d'article - choisissez d'abord un article",
    tax_free: '{{ currency }} excl. taxes',
    with_tax: '{{ currency }} including taxes',
    new_article: 'Nouvel article',
    descr: 'Description du type',
    image: 'Image',
    action: 'Action',
    bikeTypes: 'Type de vélo (libellé)',
    new_bikeTypes: 'Nouveau type de vélo',
    parkTypes: 'Type de consigne (libellé)',
    accessoriesTypes: 'Accessories type (label)',
    new_parkTypes: 'Nouveau type de consigne',
    freeParkTypes: 'Type de consigne libre (libellé)',
    new_freeParkTypes: 'Nouveau type de consigne libre',
    new_accessoriesTypes: 'New accessories type (label)',
    update_bike: 'Votre type de vélo a bien été mis à jour',
    update_freepark: 'Votre type de consigne libre a bien été mis à jour',
    update_accessory: 'Your accessory type has been updated',
    update_park: 'Votre type de consigne a bien été mis à jour',
    new_bike_type_success: 'Le type de vélo " {{ name }} " a bien été ajouté',
    new_park_type_success: 'Le type de consigne " {{ name }} " a bien été ajouté',
    new_freepark_type_success: 'Le type de consigne libre " {{ name }} " a bien été ajouté',
    new_accessory_type_success: 'The accessory type " {{ name }} " has been added',
    new_article_success: 'Votre article a bien été ajouté !',
    new_verification_success: 'Your inventory has been updated !',
    theme_update_success: 'The theme has been sucessfully updated',
    theme_media_update_success: 'The theme images has been sucessfully updated',
    colors: 'Colors',
    agents: 'Agent',
    profile: 'Profiles / Rights',
    agent_update_success: 'Agent has been updated !',
    agent_add_success: 'Agent added !',
    create_agent: 'Create',
    agent_add_title: 'New agent',
    agent_list_title: 'Agents management',
    agent_update: 'UPDATE',
    right_update: 'Update',
    right_list_title: 'Configuration of profiles / rights',
    right_add_title: 'Create',
    right_create_title: 'Create new profile',
    right_update_success: 'Droit bien mis à jour !',
    right_add_success: 'Right added !',
    Admin: 'Admin',
    Agent: 'Agent',
    Technicien: 'Technician',
    Lecteur: 'Reader',
    Régisseur: 'Régisseur',
    fallback_locale: 'Fallback locale',
    available_locales: 'Locales available',
    payment_bo: 'BO payment accepted',
    time_validation: {
      day: '24h',
      two_days: '48h',
      three_days: '72h',
    },
    collectivity: {
      name: 'Name',
      address: 'Adress',
      pc: 'Postal code',
      city: 'City',
      delay: 'Booking delay',
      limit: 'Booking limit',
      visible: 'Visible',
      unique_niche: 'Unique niche',
      gap: 'Booking gap',
      action: 'Action',
    },
    checkin: {
      new_verification: 'New verification',
      title: 'Inventoryt',
      type_name: 'Article type',
      type_action: 'Action',
      verification_delete_confirm: 'You are going to delete this verification, are you sure ?',
      label: 'Label',
      is_mandatory: 'Mandatory',
      has_media: 'With photo',
      is_media_mandatory: 'Photo mandatory',
      need_status: 'Overall comment',
      is_active: 'Active',
      action: 'Action',
    },
    forms: {
      motif_utilisation: 'Reason for use',
      boursier: 'Scolarship',
      form_name: 'Form name',
      form_action: 'Action',
      success: 'Your changes on this form has been saved !',
      submit: 'Edit form',
      name: 'Field name',
      displayed: 'Displayed',
      required: 'Required',
      nature: 'Nature',
      civilite: 'Civility',
      entr_asso: 'Firm/Association',
      nom: 'Last name',
      prenom: 'First name',
      email: 'Email',
      password: 'Password',
      date_naissance: 'Birthdate',
      pays_orig: 'Origin COuntry',
      adresse: 'Address',
      adresse_compl: 'Additional address',
      cp: 'ZIP',
      ville: 'City',
      pays: 'Country',
      telephone: 'Phone',
      telephone_port: 'Mobile phone',
      booking: 'Booking Form',
      abotc: 'Num Abo TC',
    },
    locale_to_update: 'Locale to update',
    workshop: {
      title: 'Workshop page title',
      subtitle: 'Workshop page subtitle',
      phone: 'Phone',
      email: 'E-mail',
      address: 'Address',
      zip: 'ZIP',
      city: 'City',
      latitude: 'Latitude',
      longitude: 'Longitude',
      opening_hours: 'Opening hours',
      submit: 'Save changes',
      success: 'Your changes on the Workshop page has been saved !',
    },
    contact: {
      contact_hook: 'Contact text',
      phone: 'Telephone',
      email: 'E-mail',
      address: 'Address',
      submit: 'Save modifications',
      address_placeholder: 'My address, 66666 Placeholder',
      contact_placeholder: 'Feel free to contact us so that we can answer you as soon as possible !',
      phone_placeholder: '09087898787',
      email_placeholder: 'test@placeholder.com',
      success: 'Your changes has been saved !',
    },
    modules: {
      title: 'Active modules :',
      sign_up: 'Sign up module"',
      sign_in: 'Sign in module"',
      contact: 'Contact module',
      my_account: 'My information module',
      my_reservations: 'My reservation module',
      my_invoices: 'My invoices module',
      workshop: 'Workshop module',
      rent_bike: 'Ren a bike module',
      rent_storage: 'Rent a freepark module',
      rent_park: 'Rent a park module',
      insurance: 'Insurance module',
      home: 'Home module',
      change_pwd: 'Change password module',
      sign_out: 'Sign out module',
      mail_validation: 'Mail validation module',
      forgotten_pwd: 'Forgotten password module',
      repair_and_overhaul: 'Repair and overhaul module',
      renewal: 'Renewal module',
      accessoires: 'Accessories module',
      liste_attente: 'Waiting list module',
    },
    assets: 'Assets',
    minWidth: 'Required: {{ width }}px min width (JPEG or PNG)',
    config_deposit: 'Configuration de la caution',
    deposit_active: 'Caution activée',
    btn_deposit: 'Activer la caution',
    deposit_config_success: 'Votre activation de la caution a bien été mise à jour !',
    config_booking: 'Configuration de la réservation',
    booking_active: 'Activated booking methods',
    btn_booking: 'Activer la réservation',
    booking_config_success: 'Votre activation de la réservation a bien été mise à jour !',
    confirmation_txt_fr: 'Reservation confirmation text (french)',
    confirmation_txt_en: 'Reservation confirmation text (english)',
    booking_payment_info_fr: 'Information text about reservation (french)',
    booking_payment_info_en: 'Information text about reservation (english)',
    caution_payment_info_fr: 'Information text about deposit (french)',
    caution_payment_info_en: 'Information text about deposit (english)',
    homepage_txt_fr: 'Homepage text (french)',
    homepage_txt_en: 'Homepage text (english)',
    homepage_dynamic_fr: 'Homepage text (french)',
    homepage_dynamic_en: 'Homepage text (english)',
    vouchers: {
      name: 'Voucher name',
      required: 'Required',
      new_voucher: 'New voucher',
      velo: 'Voucher for bike rent',
      consigne: 'Voucher for park rent',
      consignepark: 'Voucher for freepark rent',
      delete: 'Delete voucher',
      edit: 'Edit voucher',
      add: 'Add a new voucher',
      edit_success: 'The voucher has been updated !',
      delete_success: 'The voucher has been deleted !',
      tariffs: 'Tariffs',
      search_tariffs: 'Tariffs...',
    },
    articles_type: {
      col_label_accessoryBikes: 'Bike types...',
      col_label_bikeTypes: 'Bike type label',
      col_label_parkTypes: 'Park type label',
      col_label_freeParkTypes: 'Freepark type label',
      col_label_accessoriesTypes: 'Accessories type label',
      col_description: 'Type description',
      remove_image: 'Remove image',
    },
    pages_success: 'The pages translation has been updated !',
  },
  locales: {
    select: 'Select a locale',
    select_multiple: 'Select one or more locale',
    fr: 'French 🇫🇷',
    en: 'English 🇬🇧',
  },
  payments: {
    cb: 'Credit Card',
    agency: 'Agency',
    sepa: 'Mandate SEPA',
    mangopay: 'Mangopay',
    stripe: 'Stripe',
    sogecommerce: 'Sogecommerce',
    paybox: 'Paybox System',
    monetico: 'Monetico',
  },
  wordings: {
    HOME: 'Homepage',
    SIGN_IN: 'Login',
    SIGN_UP: 'Register',
    MAIL_VALIDATION: 'Mail validation',
    FORGOTTEN_PWD: 'Forgotten password',
    CHANGE_PWD: 'Change password',
    MY_ACCOUNT: 'My account',
    MY_RESERVATIONS: 'My reservations',
    MY_INVOICES: 'My invoices',
    RENT_BIKE: 'Rent a bike',
    RENT_STORAGE: 'Rent a storage',
    RENT_PARK: 'Rent a park',
    CONTACT: 'Contact page',
    WORKSHOP: 'Workshop page',
    REPAIR_AND_OVERHAUL: 'Repare and overhaul page',
    RENEWAL: 'Renewal module',
    LISTE_ATTENTE: 'Waiting list module',
  },
  // },
};
