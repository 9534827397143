import React, { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import Doughnut from 'components/charts/Doughnut/Doughnut';
import dayjs from 'dayjs';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import StatBlock from '../../components/Core/StatBlock/StatBlock';
import Card from '../../components/Core/Card/Card';
import { selectCollAndDeliveryPointID } from '../../auth/selectors/authSelectors';
import styles from './FleetSection.module.scss';
import AvailableStackedBarChart, { AvailableChartData, backgroundColors } from './AvailableStackedBarChart';
import { articleTypeListQuery, fleetQuery } from './Indicator.page';
import { contentInfoMap } from './FiltersInfos/FiltersInfos';

type FleetResponse = {
  nbArticle: number;
  nbStolen: Record<string, number>;
  totalStolen: number;
  totalMaintenance: number;
  nbMaintenance: Record<string, number>;
  nbAvailableRent: number;
  detailsArticles: Record<string, { total: number; available: number }>;
};

export type ArticleTypeListResponse = string[];

function FleetSection() {
  const { t } = useTranslation(['stats/indicator']);
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const [searchParams] = useSearchParams();

  const { data: fleetResponse } = useQuery<AxiosResponse<FleetResponse>>({
    ...fleetQuery({
      type: searchParams.get('articles') || 'bike',
      idColl: id_coll,
      pdl: searchParams.get('pdl') !== 'null' ? searchParams.get('pdl') : undefined,
    }),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });

  const { data: articleTypeListResponse } = useQuery<AxiosResponse<ArticleTypeListResponse>>({
    ...articleTypeListQuery({
      article: searchParams.get('articles') || 'bike',
      idColl: id_coll,
    }),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });

  const availableData: AvailableChartData = useMemo(() => {
    const array = articleTypeListResponse?.data
      .map((articleType, index) => {
        const articleTypeDetails = fleetResponse?.data.detailsArticles[articleType];
        return {
          label: articleType,
          data: articleTypeDetails ? [articleTypeDetails.total, articleTypeDetails.available] : [0, 0],
          backgroundColor: !articleTypeDetails || articleTypeDetails.total === 0 ? undefined : backgroundColors[index],
        };
      })
      //eslint-disable-next-line
      .sort((a, b) => (a.data[0] < b.data[0] ? 1 : -1)) as any;

    return _.every(array, ['data', [0, 0]])
      ? _.map(array, (dataset) => _.assign(dataset, { data: [Number.NaN] }))
      : array;
  }, [articleTypeListResponse, fleetResponse]);

  return (
    <Card
      title={(baseStyle) => (
        <div className={[baseStyle, styles.AvailableCardTitle].join(' ')}>
          <h3>{t('fleet.title')}</h3>
          <div className={'row gap-8 items-center'}>
            <span>{t('dateTitle', { date: dayjs().format('DD/MM/YYYY, HH[h]mm') })}</span>
            <div id={'fleet-legend'} />
          </div>
        </div>
      )}
      className={styles.Card}
      content={
        <div>
          <div className={styles.AvailableSection}>
            <div className={styles.Left}>
              <StatBlock title={t('fleet.available')} nbr={fleetResponse?.data.nbAvailableRent} />
              <StatBlock small nbr={fleetResponse?.data.nbArticle} subtitle={t('fleet.total')} />
            </div>
            {/*<StackedBar
                containerLegendId={'fleet-legend'}
                labels={Object.keys(fleetResponse.data.detailsArticles)}
                data={Object.values(fleetResponse.data.detailsArticles).reduce((prev, current) => {
                  if (prev.length === 0) prev.push([current.total], [current.available]);
                  else {
                    prev[0].push(current.total);
                    prev[1].push(current.available);
                  }
                  return prev;
                }, [] as number[][])}
                datasetLabels={['total', 'available']}
              />*/}
            <AvailableStackedBarChart
              className={styles.AvailableChart}
              title={t(`chartTitles.${searchParams.get('type') || 'type'}`, {
                // eslint-disable-next-line
                article: `$t(common:${contentInfoMap.get(searchParams.get('articles') || 'bike')!.tKey})`,
              })}
              data={availableData}
              containerLegendId={'fleet-legend'}
            />
          </div>

          <div className={styles.BottomSection}>
            <StatBlock
              title={t('fleet.stolen')}
              nbr={fleetResponse?.data.totalStolen}
              chart={
                <Doughnut
                  data={Object.values(fleetResponse?.data.nbStolen || {})}
                  labels={Object.keys(fleetResponse?.data.nbStolen || {})}
                  width={156}
                />
              }
            />
            <StatBlock
              title={t('fleet.maintenance.title')}
              subtitle={t('fleet.maintenance.subtitle')}
              nbr={fleetResponse?.data.totalMaintenance}
              chart={
                <Doughnut
                  data={Object.values(fleetResponse?.data.nbMaintenance || {})}
                  labels={Object.keys(fleetResponse?.data.nbMaintenance || {})}
                  width={156}
                />
              }
            />
          </div>
        </div>
      }
    />
  );
}

export default FleetSection;
