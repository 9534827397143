import Joi from 'joi';
import { ComponentPropsWithoutRef } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FormObject } from '../../../../hook/useBuildForm';
import { CustomFieldProps } from '../../../admin/DepositConfig/depositConfig.form';
import usePriceOptions from '../../Deposit/UsePriceOptions';
import ToggleRadioGroup from './ToggleRadioGroup';

export function ToggleRadioWithController(
  props: Partial<CustomFieldProps> & { controllerName?: string } & Pick<
      ComponentPropsWithoutRef<typeof ToggleRadioGroup>,
      'style'
    >
) {
  const { name = '', label = '', control } = props;

  return (
    <Controller
      control={control}
      defaultValue={false}
      render={({ field: { onChange, value } }) => (
        <ToggleRadioGroup name={name} value={value} label={label} onChange={onChange} style={props.style} />
      )}
      name={props.controllerName ? props.controllerName : props.name || ''}
    />
  );
}

export function useNewVoucherForm(): FormObject {
  const { articleType } = useParams();
  const { t } = useTranslation('parameters/voucherList');
  const getPriceOptionsByArticle = usePriceOptions();

  const prices = getPriceOptionsByArticle(articleType || 'velo');

  return [
    {
      colsNumber: 2,
      fields: [
        {
          type: 'text',
          name: 'pjName',
          label: t('columns.name'),
          placeholder: t('columns.name'),
          schema: Joi.string().required(),
        },
        {
          type: 'custom',
          component: <ToggleRadioWithController />,
          label: t('columns.isRequired'),
          name: 'isRequired',
          schema: Joi.boolean(),
        },
        {
          type: 'select',
          options: prices,
          name: 'articles',
          label: t('columns.prices'),
          placeholder: t('columns.prices'),
          isMulti: true,
          schema: Joi.array(),
        },
      ],
    },
  ];
}
