import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import 'fragments/checkbox/Checkbox.css';

type Props = {
  name?: string;
  id?: string;
  label?: string;
  className?: string;
  checked?: boolean;
  handleChange?: (checked: boolean) => void;
  disabled?: boolean;
  outsideCheckMode?: boolean;
};

const Checkbox = ({
  // prettier-ignore
  name = '',
  id,
  label = '',
  checked,
  className = '',
  handleChange = () => null,
  disabled = false,
  outsideCheckMode = false,
}: Props): ReactElement => {
  const [_checked, setChecked] = useState<boolean>();

  useEffect(() => {
    if (_checked === undefined || outsideCheckMode) {
      setChecked(checked);
    }
  }, [checked, _checked]);

  const onChange = useCallback(() => {
    setChecked(!_checked);
    handleChange(!_checked);
  }, [_checked]);

  return (
    <div>
      <input
        type={'checkbox'}
        className={className}
        name={name}
        onChange={onChange}
        id={id}
        checked={_checked}
        disabled={disabled}
      />
      {label && (
        <label htmlFor={id} className={'custom-control-label'}>
          {label}
        </label>
      )}
    </div>
  );
};

export default Checkbox;
