import React, { ButtonHTMLAttributes } from 'react';
import classNames from 'classnames/bind';
import ListLoader from '../ListLoader/ListLoader';
import styles from './button.module.scss';

const cx = classNames.bind(styles);

type ButtonColors = 'major' | 'primary' | 'secondary' | 'danger';

type ButtonProps<T extends React.ElementType> = ButtonHTMLAttributes<HTMLButtonElement> & {
  isLoading?: boolean;
  color?: ButtonColors;
  label?: string | JSX.Element;
  as?: T;
};

function Button<T extends React.ElementType = 'button'>({
  color = 'primary',
  label,
  isLoading,
  as,
  children,
  className,
  ...otherProps
}: ButtonProps<T> & Omit<React.ComponentPropsWithoutRef<T>, keyof ButtonProps<T>>) {
  const Component = as ?? 'button';

  return (
    <Component
      className={cx(
        'Button',
        {
          ButtonPrimary: color === 'primary',
          ButtonSecondary: color === 'secondary',
          ButtonMajor: color === 'major',
          ButtonDanger: color === 'danger',
        },
        className
      )}
      disabled={isLoading}
      {...otherProps}>
      {isLoading ? <ListLoader width={20} /> : children ? children : label}
    </Component>
  );
}

export default Button;
