/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';
import React from 'react';
import { RawBike } from '../../../api/park/bike.api';
import { RowItem } from '../../../components/Core/Datagrid/Datagrid';
import Link from '../../../components/Core/Link/Link';
import { generatePath } from '../../../utils/router';

type BookingCellProps = {
  bike: RawBike;
};

function BookingCell({ bike }: BookingCellProps) {
  return bike.available === 12 || bike.available === 5 ? (
    <div>
      {bike.id_user && bike.id_resa && (
        <>
          <Link
            css={css({ fontWeight: 'bold' })}
            to={generatePath('home.invoicing.reservationDetails', {
              params: { userId: bike.id_user, bookingId: bike.id_resa },
            })}>
            #{bike.id_resa}
          </Link>
          <br />
        </>
      )}
      {bike.userName && (
        <>
          <span>{bike.userName}</span>
          <br />
        </>
      )}
    </div>
  ) : (
    <RowItem></RowItem>
  );
}

export default BookingCell;
