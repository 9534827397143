import Joi from 'joi';
import React, { ComponentPropsWithoutRef, useCallback, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { BOOKING_STATUS, useBooking } from 'pages/Reservation/Reservation.layout';
import {
  getAccessoriesOptions,
  GetAccessoriesOptionsQueryParams,
  getArticleOptions,
  GetArticleOptionsQueryParams,
  postChangeAccessoryBooking,
  postChangeBooking,
} from '../../../../api/booking.api';
import { selectCollAndDeliveryPointID } from '../../../../auth/selectors/authSelectors';
import ButtonWithIcon from '../../../../components/Core/Button/ButtonWithIcon';
import Modal from '../../../../components/Core/Modal/Modal';
import useBuildForm, { FieldObject, FormObject } from '../../../../hook/useBuildForm';
import styles from '../../bookingDetails.module.scss';
import { ARTICLE_TYPES } from './ManagementCard';

type AssignModalProps = Omit<ComponentPropsWithoutRef<typeof Modal>, 'title'>;

function AssignModal(props: AssignModalProps) {
  const { t } = useTranslation(['reservation/components/assign', 'common']);
  const { rawBooking, bookingStatus } = useBooking();
  const { bookingId: id_resa } = useParams();
  const { id_coll, id_pdl } = useSelector(selectCollAndDeliveryPointID);
  const queryClient = useQueryClient();
  const { data: optionsResponse } = useQuery(
    ['booking.article.options', { id_coll, id_pdl, id_resa }],
    ({ queryKey }) => {
      const [, queryParams] = queryKey as [string, GetArticleOptionsQueryParams];
      return getArticleOptions(queryParams);
    },
    {
      staleTime: 400_000,
      refetchOnWindowFocus: false,
    }
  );
  const { data: accessoriesOptionsResponse } = useQuery(
    ['booking.accessories.options', { id_coll, id_pdl, id_resa }],
    ({ queryKey }) => {
      const [, queryParams] = queryKey as [string, GetAccessoriesOptionsQueryParams];
      return getAccessoriesOptions(queryParams);
    },
    {
      enabled: bookingStatus === BOOKING_STATUS.BOOKED,
      staleTime: 400_000,
      refetchOnWindowFocus: false,
    }
  );

  const formObject = useMemo<FormObject>(() => {
    let mainArticleSchema = Joi.number();
    if (rawBooking && (rawBooking?.id_velo || rawBooking?.id_consigne)) {
      mainArticleSchema = mainArticleSchema.optional().allow(null);
    }
    return [
      {
        fields: [
          {
            type: 'select',
            name: 'id_new',
            label: rawBooking?.nom_type || '',
            placeholder:
              rawBooking?.id_interne && rawBooking.id_interne !== ''
                ? rawBooking.id_interne
                : t('selectArticle.placeholder', {
                    context: rawBooking?.article === ARTICLE_TYPES.bike ? 'male' : 'female',
                  }),
            options: optionsResponse?.data.map((option) => ({ label: option.id_interne, value: option.id })) || [],
            schema: mainArticleSchema,
          },
          ...Object.values(accessoriesOptionsResponse?.data || {}).map<FieldObject>((accessoryOptions) => ({
            type: 'select',
            name: 'id_new_'.concat(accessoryOptions[0].id_type.toString()),
            label: `${accessoryOptions[0].nom_type}`,
            placeholder:
              rawBooking?.accessoires?.find((accessory) => accessory.nom_type === accessoryOptions[0].nom_type)
                ?.id_interne || t('selectAccessory.placeholder'),
            options: accessoryOptions.map((accessory) => ({
              label: accessory.id_interne,
              value: JSON.stringify(accessory),
              // value: accessory.id_accessoire,
            })),
            schema: Joi.any().optional().allow(null),
            // schema: Joi.any(),
          })),
        ],
      },
    ];
  }, [rawBooking?.article, rawBooking?.nom_type, optionsResponse, accessoriesOptionsResponse, t]);

  const { builtFormFields, handleSubmit, formState } = useBuildForm({
    formObject,
    colsNumber: 2,
  });

  const disabled = useMemo(() => !formState.isDirty || Object.keys(formState.errors).length > 0, [formState]);

  const { mutate: handlePostChangeBooking } = useMutation(
    ['booking.change'], //eslint-disable-next-line
    (data: any) => {
      const { id_new, id_coll, id_pdl, id_resa, ...accessories } = data;
      //eslint-disable-next-line
      const promises: Promise<any>[] = [
        ...Object.entries(accessories)
          .filter(([, accessory]) => accessory)
          //eslint-disable-next-line
          .map(async ([, accessoryString]: [string, any]) => {
            const accessory = JSON.parse(accessoryString);
            return postChangeAccessoryBooking({
              id_coll,
              id_pdl,
              id_resa,
              id_type: accessory.id_type,
              id_accessoire: accessory.id_accessoire,
            });
          }),
      ];
      if (id_new)
        promises.push(
          postChangeBooking({
            id_coll,
            id_pdl,
            id_resa,
            id_new,
          })
        );
      return Promise.all(promises);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['booking']);
        props.setIsOpen(false);
      },
    }
  );

  const onSubmit = useCallback(
    handleSubmit((data) => {
      if (id_resa) {
        handlePostChangeBooking({
          id_coll,
          id_resa,
          id_pdl,
          ...data,
        });
      }
    }),
    [id_coll, id_resa, id_pdl]
  );

  return (
    <Modal className={styles.assignModal} title={t('modalTitle')} {...props}>
      {builtFormFields}
      <div className={styles.assignModal__footer}>
        <ButtonWithIcon icon={'CrossWithoutCircle'} color={'secondary'} onClick={() => props.setIsOpen(false)}>
          {t('common:cancel')}
        </ButtonWithIcon>
        <ButtonWithIcon icon={'CheckWithoutCircle'} onClick={onSubmit} disabled={disabled}>
          {t('modalCta')}
        </ButtonWithIcon>
      </div>
    </Modal>
  );
}

export default AssignModal;
