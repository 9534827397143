import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { withTheme } from '../../Parameters/Deposit/List/DepositList';
import FormGroupCard from '../../../components/FormGroupCard/FormGroupCard';
import Button from '../../../components/Core/Button/Button';
import { useSnackbar } from '../../../context/SnackbarContext';
import { selectCollAndDeliveryPointID } from '../../../auth/selectors/authSelectors';
import useBuildForm, { FieldObject } from '../../../hook/useBuildForm';
import { TOAST_SEVERITY } from '../../../components/Core/Toast/Toast';
import useModuleParametersApi from './useModuleParametersApi';

function ModuleParametersPage() {
  const { t } = useTranslation(['admin/moduleParameters', 'form']);
  const snackbar = useSnackbar();
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const { fetchModuleParameters, putModuleParameters } = useModuleParametersApi();
  const [moduleList, setModuleList] = useState<Record<string, { isEnabled: boolean }> | undefined>(undefined);
  const { builtFormFields, handleSubmit } = useBuildForm({
    withValidation: false,
    formObject: useMemo(
      () =>
        moduleList
          ? [
              {
                colsNumber: 2,
                fields: Object.keys(moduleList).reduce<FieldObject[]>((acc, name) => {
                  acc.push({
                    type: 'checkbox',
                    label: t('modules.'.concat(name.toLowerCase())),
                    name: name.concat('.isEnabled'),
                  });

                  return acc;
                }, []),
              },
            ]
          : undefined,
      [moduleList, t]
    ),
    defaultValues: moduleList,
  });

  const onMount = useCallback(async () => {
    const response = await fetchModuleParameters({
      id_coll,
    });

    if (response && response.status === 200) {
      setModuleList(response.data);
    }
  }, [id_coll]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const onSubmit = useCallback(
    handleSubmit(async (data) => {
      const response = await putModuleParameters({
        id_coll,
        modules: data,
      });

      if (response && response.status === 200) {
        snackbar?.setAlert({ message: t('form:global_update_success_message'), severity: TOAST_SEVERITY.success });
      }
    }),
    [id_coll]
  );

  return (
    <MainSection>
      <FormGroupCard
        title={t('title')}
        WrapperComponent={({ children }) => <form onSubmit={onSubmit}>{children}</form>}
        FooterSlotComponent={({ defaultWrapper: DefaultWrapper }) => (
          <DefaultWrapper>
            <Button type={'submit'} label={t('form:global_update_label')} />
          </DefaultWrapper>
        )}>
        {builtFormFields}
      </FormGroupCard>
    </MainSection>
  );
}

const MainSection = styled.section`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export default withTheme(ModuleParametersPage);
