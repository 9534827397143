import React, { useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getUserList } from '../../api/user.api';
import { selectCollAndDeliveryPointID } from '../../auth/selectors/authSelectors';
import Card from '../../components/Core/Card/Card';
import Datagrid, { renderCell, Row, RowItem } from '../../components/Core/Datagrid/Datagrid';
import { GridColDefinition } from '../../components/Core/Datagrid/gridCols';
import Filters from '../../components/Core/Filters/Filters';
import MainSection from '../../components/Core/MainSection/MainSection';
import { generatePath } from '../../utils/router';
import { ListParams } from '../park/BikeList/BikeList';
import UserActionsCell from './UserActionsCell';

function useColumn(): GridColDefinition[] {
  const { t } = useTranslation('user/userList', { keyPrefix: 'columns' });

  return useMemo(() => {
    return [
      {
        field: 'nom',
        type: 'custom',
        headerName: t('name'),
        sortable: true,
        renderCell: ({ row }) => <RowItem>{`${row.prenom} ${row.nom}`}</RowItem>,
      },
      {
        field: 'adresse',
        type: 'custom',
        headerName: t('address'),
        renderCell: ({ row }) => (
          <RowItem>
            {row.adresse}
            <br />
            {`${row.cp} ${row.ville}`}
          </RowItem>
        ),
      },
      {
        field: 'email',
        type: 'custom',
        headerName: t('contacts'),
        renderCell: ({ row }) => (
          <RowItem>
            {row.email} <br /> {row.telephone}
          </RowItem>
        ),
      },
      {
        field: '',
        type: 'custom',
        headerName: t('actions'),
        renderCell: (data) => <UserActionsCell row={data.row} />,
      },
    ];
  }, [t]);
}

export type RawFilters = {
  [key: string]: {
    zone: number;
    order: number;
    values?: unknown;
    selected: number;
  };
};

/*export function transformRawFiltersToFiltersObject(rawFilters?: RawFilters) {
  return Object.entries(rawFilters || {}).reduce(
    (prev, [filterName, data]) => ({ ...prev, [filterName]: data.selected }),
    {}
  );
}*/

function List() {
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const [listParams, setListParams] = useState<ListParams>({
    order: undefined,
    filters: undefined,
    currentPage: 1,
    itemPerPage: 10,
  });
  const userColumnDefinitions = useColumn();

  const { data: userListData, isFetching: isLoading } = useQuery(
    ['user.list', { ...listParams, id_coll }],
    ({ queryKey }) => {
      const [, params] = queryKey as [string, ListParams & { id_coll: number; id_user: number }];

      return getUserList({
        id_coll,
        p: params.currentPage,
        ipp: params.itemPerPage,
        'order[column]': params.order?.column,
        'order[dir]': params.order?.dir,
        ...params.filters,
      });
    },
    { staleTime: 260_000, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  return (
    <MainSection className={'reset'}>
      <Filters
        //eslint-disable-next-line
        onChange={(filters) => setListParams((prev) => ({ ...prev, filters }))}
        translationNamespace={'user/userList'}
        filtersRaw={userListData?.data.filters || {}}
      />
      <Card>
        <Datagrid
          loading={isLoading}
          translationNamespace={'user/userList'}
          rows={userListData?.data.result || []}
          columns={userColumnDefinitions}
          totalLines={userListData?.data.total}
          onChangeFilterText={(search) =>
            setListParams((prev) => ({ ...prev, filters: { ...prev.filters, filter: search } }))
          }
          search={userListData?.data.filters.filter.selected}
          RowComponent={({ row, columns, colsTemplate, index }) => (
            <Row
              colsTemplate={colsTemplate}
              key={'line'.concat(index.toString())}
              to={generatePath('home.user.userDetails', {
                params: {
                  userId: row.id_user,
                },
              })}
              as={NavLink}>
              {columns.map((column, index) => renderCell(column, row, index))}
            </Row>
          )}
          currentPage={userListData?.data.currentPage}
          itemPerPage={userListData?.data.itemPerPage}
          nbrPages={userListData?.data.totalPage}
          order={userListData?.data.order}
          onSort={(order) => setListParams((prev) => ({ ...prev, order }))}
          handleChangePage={(newPage) => setListParams((prev) => ({ ...prev, currentPage: newPage }))}
          onChangeItemPerPage={(newValue) => setListParams((prev) => ({ ...prev, itemPerPage: newValue as number }))}
        />
      </Card>
    </MainSection>
  );
}

export default List;
