import React, { ReactElement, useEffect, useMemo } from 'react';
import { useCallback } from 'react';

import Checkbox from 'fragments/checkbox/Checkbox';
import DataTable from 'fragments/dataTable/DataTable';

import { t } from 'internal/i18n';

import useApi, { Methods } from 'internal/hooks/useApi';
import { useSelector } from 'react-redux';
import { selectCollAndDeliveryPointID } from 'auth/selectors/authSelectors';
import { ACCESSORIES_TYPE_ENDPOINT } from 'parameters/constants/endpoints';
import { UserAccessoryType } from 'users/types/users';
import { selectCurrency } from 'internal/selectors/currencySelectors';
import { BIKE_AVAILABILITY_ENDPOINT } from 'users/constants/endpoints';
import ArticleTypology from 'users/constants/article';

type Props = {
  articleID: number | undefined;
  formState: UserAccessoryType[];
  setFormState: React.Dispatch<React.SetStateAction<UserAccessoryType[]>>;
  startingDate: string;
  endingDate: string;
};

// prettier-ignore
const AccessoriesForm = ({
  formState, setFormState, articleID, startingDate, endingDate, 
}: Props): ReactElement => {
  const { id_coll, id_pdl } = useSelector(selectCollAndDeliveryPointID);
  const currency = useSelector(selectCurrency);

  const { request, response } = useApi<UserAccessoryType[]>(Methods.GET, ACCESSORIES_TYPE_ENDPOINT);
  const { request: fetchBikeAvailability, response: bikeAvailabilityResponse } = useApi<{
    nb_velo: number;
    nb_accessoires: number;
    id_type: string;
  }>(Methods.GET, BIKE_AVAILABILITY_ENDPOINT);

  useEffect(() => {
    if (id_coll && id_pdl && startingDate && endingDate && formState) {
      formState.forEach(({ id_type, fetched }) => {
        if (!fetched) {
          fetchBikeAvailability({
            params: {
              id_coll,
              id_pdl,
              article: ArticleTypology.ACCESSORY,
              id_type,
              date_debut: startingDate,
              date_fin: endingDate,
            },
          });
        }
      });
    }
  }, [id_coll, id_pdl, startingDate, endingDate, formState]);

  useEffect(() => {
    if (bikeAvailabilityResponse?.data && bikeAvailabilityResponse?.success) {
      setFormState((prevState) => {
        const index = formState.findIndex(({ id_type }) => id_type === parseInt(bikeAvailabilityResponse.data.id_type));
        const newFormState = [...prevState];

        newFormState.splice(index, 1, {
          ...prevState[index],
          dispo: bikeAvailabilityResponse.data.nb_accessoires,
          fetched: true,
        });

        return [...newFormState];
      });
    }
  }, [bikeAvailabilityResponse?.data, setFormState]);

  const fetchData = useCallback(() => {
    if (articleID) {
      request({
        params: {
          id_coll,
          id_article: articleID,
        },
      });
    }
  }, [articleID, id_coll]);

  useEffect(() => {
    if (response?.data && response?.success) {
      setFormState(response.data.map((accessory: UserAccessoryType) => ({ ...accessory, use: false, dispo: 0 })));
    }
  }, [response?.data, response?.success, setFormState]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleChangeFormState = useCallback(
    (index: number, key: string) => (value: boolean | string) => {
      setFormState((prevState) => {
        const newFormState = [...prevState];

        newFormState.splice(index, 1, {
          ...prevState[index],
          [key]: value,
        });

        return [...newFormState];
      });
    },
    [formState]
  );

  const items = useMemo(() => {
    if (formState) {
      return formState.map(({ nom_type, montant_ttc, use, dispo }, i) => {
        const available = dispo > 0;

        return {
          name: <span>{nom_type}</span>,
          price: (
            <span>
              {montant_ttc} {currency}
            </span>
          ),
          dispo: <span>{dispo}</span>,
          use: (
            <Checkbox
              checked={use}
              handleChange={handleChangeFormState(i, 'use')}
              outsideCheckMode
              disabled={!available}
            />
          ),
        };
      });
    }
    return [];
  }, [formState, currency]);

  return (
    <>
      <label>{t('users.accessories.title')}</label>
      <div>
        <div className={'main-content'}>
          <div className={'row'}>
            <div className={'col-md-12'}>
              <div className={'dataTables_wrapper container-fluid dt-bootstrap4 no-footer'}>
                <DataTable
                  cols={['name', 'price', 'dispo', 'use']}
                  colTraductionScope={'reservation/reservation'}
                  colHandlers={[]}
                  items={items}
                  customEmptyMessage={t('users.accessories.empty')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccessoriesForm;
