import React, { ReactElement, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Dialog } from '@mui/material';
import Button from 'fragments/button/Button';
import useApi, { Methods } from 'internal/hooks/useApi';
import { selectCollAndDeliveryPointID } from 'auth/selectors/authSelectors';
import style from 'park/components/HistoryDialog.module.css';
import { PARK_HISTORY_ENDPOINT } from 'park/constants/endpoints';
import { BikeHistoryRecordType } from 'park/types/park';

type Props = {
  open: boolean;
  onClose: () => void;
  bikeHistory: number;
};

const HistoryDialog = ({ open, onClose, bikeHistory }: Props): ReactElement => {
  const { t } = useTranslation(['old']);
  const { request: fetchHistory, response: fetchHistoryResponse } = useApi<{
    histo: BikeHistoryRecordType[];
    id_interne: number;
  }>(Methods.GET, PARK_HISTORY_ENDPOINT);

  const { id_pdl, id_coll } = useSelector(selectCollAndDeliveryPointID);

  useEffect(() => {
    fetchHistory({
      params: {
        id_coll,
        id_pdl,
        id_velo: bikeHistory,
      },
    });
  }, [id_coll, id_pdl, bikeHistory, open]);

  const [history, bikeID] = useMemo(() => {
    if (fetchHistoryResponse?.success && fetchHistoryResponse.data) {
      return [fetchHistoryResponse.data.histo, fetchHistoryResponse.data.id_interne];
    }
    return [null, null];
  }, [fetchHistoryResponse]);

  const renderHistoryLines = useMemo(() => {
    if (history) {
      return history.map(({ date_updated, etat, nom_pdl, user }) => (
        <span>{t('bikes.bike_state', { date_updated, etat, nom_pdl, user })}</span>
      ));
    }
  }, [history, t]);

  const renderContent = useMemo(() => {
    if (history?.length) {
      return renderHistoryLines;
    }

    return <span>{t('bikes.no_history')}</span>;
  }, [history, t]);

  return (
    <Dialog open={open} onClose={onClose}>
      <div className={style.modal}>
        <div className={style.modal__header}>
          <h2 className={style.modal__header__title}>{t('bikes.bike_history', { bikeID })}</h2>
        </div>
        <div className={style.modal__content}>{renderContent}</div>
        <div className={style.modal__footer}>
          <Button
            label={'OK'}
            className={`${style.modal__footer__button} btn btn-bold btn-primary`}
            onClick={onClose}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default HistoryDialog;
