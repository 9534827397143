import axios, { AxiosResponse } from 'axios';
import _, { upperFirst } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { FiltersRaw } from '../components/Core/Filters/Filters';
import { TOAST_SEVERITY } from '../components/Core/Toast/Toast';
import { useSnackbar } from '../context/SnackbarContext';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useRequest<ResponseType = any, T = any>(
  fetchFunc: (props: T) => Promise<AxiosResponse<ResponseType>> | undefined
) {
  const { t } = useTranslation('errors');
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);

  const request = useCallback(
    async (props: T) => {
      try {
        setLoading(true);
        const response = await fetchFunc(props);
        setLoading(false);
        return response;
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const code = error.response?.data.code;
          snackbar?.setAlert({
            message: t('errors:'.concat(code.replace('error.', ''))),
            severity: TOAST_SEVERITY.error,
          });
          setLoading(false);
        }

        return undefined;
      }
    },
    [fetchFunc, t]
  );

  return { request, loading };
}

export type GridDataState = {
  search?: string;
  totalLines: number;
  itemPerPage: number;
  nbrPages?: number;
  currentPage: number;
  order?: {
    column: string;
    dir: string;
  };
  filters?: Record<string, unknown>;
};

export function useDefaultListUrlParams(): Record<string, unknown> {
  const { search } = useLocation();

  return useMemo(() => {
    const defaultValues = { filters: {} };
    const urlParams = new URLSearchParams(search);
    urlParams.forEach((value, name) => {
      if (name === 'page') {
        Object.assign(defaultValues, { currentPage: Number(value) });
      } else {
        Object.assign(defaultValues.filters, { ['filter'.concat(upperFirst(name))]: Number(value) || value });
      }
    });

    return defaultValues;
  }, []);
}

export function useListRequest<T>(fetchFunc: (props: T) => Promise<AxiosResponse> | undefined, extraProps: T) {
  const defaultValues = useDefaultListUrlParams();
  const { request } = useRequest(fetchFunc);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [results, setResults] = useState<any[]>([]);
  const [filters, setFilters] = useState<FiltersRaw>({});
  const [dataGridData, setDataGridData] = useState<GridDataState>({
    search: '',
    totalLines: 0,
    itemPerPage: 10,
    nbrPages: undefined,
    currentPage: 1,
    order: {
      column: 'date_start',
      dir: 'desc',
    },
    filters: {},
  });

  useEffect(() => {
    setDataGridData((prev) => {
      const copy = { ...prev };
      _.assign(copy, _.pick(defaultValues, _.keys(prev)));
      // if ('filters' in defaultValues) _.assign(copy.filters, defaultValues['filters']);
      return copy;
    });
  }, [defaultValues]);

  // TODO: can add AbortController for cancellation https://axios-http.com/docs/cancellation
  const fetch = useMemo(() => {
    return _.debounce(async (gridDataState: GridDataState) => {
      const response = await request({
        ...extraProps,
        p: gridDataState.currentPage,
        ipp: gridDataState.itemPerPage,
        'order[dir]': gridDataState.order?.dir,
        'order[column]': gridDataState.order?.column,
        ...gridDataState.filters,
      });

      if (response && response.status === 200) {
        setResults(response.data.result);
        setFilters(response.data.filters);
        setDataGridData((prevState) => ({
          ...prevState,
          totalLines: response.data.total,
          itemPerPage: response.data.itemPerPage,
          nbrPages: response.data.totalPage,
          currentPage: response.data.currentPage,
          order: response.data.order,
        }));
      }

      setLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    setLoading(true);
    fetch(dataGridData);
  }, [
    dataGridData.itemPerPage,
    dataGridData.currentPage,
    dataGridData.order?.column,
    dataGridData.order?.dir,
    dataGridData.filters,
  ]);

  return {
    loading,
    results,
    filters,
    setDataGridData,
    dataGridData,
  };
}
