import axios, { AxiosRequestConfig } from 'axios';
import ApiExecutor from '@dotmind/utils/dist/react/ApiExecutor';

import { API_KEY, BASE_URL, TIMEOUT } from 'internal/constants/api';

// eslint-disable-next-line
export type RequestType = AxiosRequestConfig | Record<string, any>;

function apiInstance() {
  return axios.create({
    baseURL: BASE_URL,
    timeout: TIMEOUT,
    headers: {
      ['X-Velogikback-Apikey']: API_KEY,
      ['X-Velogik-Host']: 'veloclik-inte.velogik.com',
    },
  });
}

function api() {
  const instance = apiInstance();

  return {
    ...new ApiExecutor(instance),
    setAuthToken: (token: string) => (instance.defaults.headers.common['Authorization'] = token),
    del: (url: string, params: AxiosRequestConfig) => instance.delete(url, params),
    put: (url: string, params: AxiosRequestConfig, data: unknown) => instance.put(url, data, params),
    removeAuthToken: () => {
      delete instance.defaults.headers.common['Authorization'];
    },
  };
}

export default api();
