/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react/macro';
import MainSection from 'components/Core/MainSection/MainSection';
import { useOutletContext } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { useQueryClient } from '@tanstack/react-query';
import { withTheme } from '../../Parameters/Deposit/List/DepositList';
import FormGroupCard from '../../../components/FormGroupCard/FormGroupCard';
import Button from '../../../components/Core/Button/Button';
import { selectCollAndDeliveryPointID } from '../../../auth/selectors/authSelectors';
import useBuildForm from '../../../hook/useBuildForm';
import { useSnackbar } from '../../../context/SnackbarContext';
import { TOAST_SEVERITY } from '../../../components/Core/Toast/Toast';
import useCollectivityAdminParametersApi from './useCollectivityAdminParametersApi';
import getCollectivityAdminParametersFormFields from './updateForm';
import { CollectivityAdminParamsResponse } from './CollectivityAdminParams.layout';

type CollectivityAdminGlobalParameters = Omit<
  CollectivityAdminParamsResponse['admin'],
  | 'sepa_ics'
  | 'sepa_nom'
  | 'sepa_adresse'
  | 'velocareBusinessId'
  | 'velocareSubsidiaryId'
  | 'alwinSupervisorCodeId'
  | 'alwinAuthPassword'
  | 'alwinAuthUser'
  | 'url_confidentialite'
>;

function transformFormDataForPost({
  // nom: nom_coll,
  url_front: urlfront_coll,
  ...paramsToForward
}: CollectivityAdminGlobalParameters) {
  return {
    // nom_coll,
    urlfront_coll,
    ...paramsToForward,
  };
}

function CollectivityAdminParametersPage() {
  const collectivityAdminParamsResponse = useOutletContext<
    AxiosResponse<CollectivityAdminParamsResponse> | undefined
  >();
  const { t } = useTranslation(['admin/collectivityAdmin', 'form']);
  const snackbar = useSnackbar();
  const { udpateCollectivityAdminParameters } = useCollectivityAdminParametersApi();
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const [details, setDetails] = useState<CollectivityAdminGlobalParameters | undefined>(undefined);
  const { builtFormFields, handleSubmit } = useBuildForm({
    formObject: getCollectivityAdminParametersFormFields(t),
    colsNumber: 3,
    defaultValues: details,
  });
  const queryClient = useQueryClient();

  useEffect(() => {
    if (collectivityAdminParamsResponse?.data) {
      const {
        sepa_adresse,
        sepa_ics,
        sepa_nom,
        alwinAuthUser,
        alwinAuthPassword,
        alwinSupervisorCodeId,
        velocareBusinessId,
        velocareSubsidiaryId,
        url_confidentialite,
        ...collectivityAdminParametersDefaultValues
      } = collectivityAdminParamsResponse.data.admin;
      setDetails(collectivityAdminParametersDefaultValues);
    }
  }, [id_coll, collectivityAdminParamsResponse]);

  const onSubmit = useCallback(
    handleSubmit(async (data) => {
      const response = await udpateCollectivityAdminParameters({
        ...transformFormDataForPost(data as unknown as CollectivityAdminGlobalParameters),
        id_coll,
      });

      if (response && response.status === 200) {
        queryClient.invalidateQueries(['param/admin']);
        snackbar?.setAlert({ message: t('modification_success'), severity: TOAST_SEVERITY.success });
      }
    }),
    [id_coll, queryClient]
  );

  return (
    <MainSection>
      <FormGroupCard
        title={details?.nom}
        WrapperComponent={({ children }) => <form onSubmit={onSubmit}>{children}</form>}
        FooterSlotComponent={({ defaultWrapper: DefaultWrapper }) => (
          <DefaultWrapper>
            <Button type={'submit'} label={t('form:global_update_label')} />
          </DefaultWrapper>
        )}>
        <div
          css={css`
            display: grid;
            gap: 16px;
          `}>
          {builtFormFields}
        </div>
      </FormGroupCard>
    </MainSection>
  );
}

export default withTheme(CollectivityAdminParametersPage);
