import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { RawBike } from '../../../api/park/bike.api';
import { getParkList } from '../../../api/park/park.api';
import { selectCollAndDeliveryPointID } from '../../../auth/selectors/authSelectors';
import Card from '../../../components/Core/Card/Card';
import Datagrid, { RowItem } from '../../../components/Core/Datagrid/Datagrid';
import { GridColDefinition } from '../../../components/Core/Datagrid/gridCols';
import Filters from '../../../components/Core/Filters/Filters';
import MainSection from '../../../components/Core/MainSection/MainSection';
import { ListParams } from '../BikeList/BikeList';
import BookingCell from '../BikeList/BookingCell';
import StatusCell from '../BikeList/StatusCell';

//eslint-disable-next-line
function useColumns(filters: any): GridColDefinition[] {
  const { t } = useTranslation('park/genericList');

  return useMemo(
    () => [
      {
        field: 'id_interne',
        type: 'custom',
        headerName: t('columns.parkNumber'),
        sortable: true,
        renderCell: ({ row }) => <RowItem key={'interneId' + row.id_consigne}>{`${row.id_interne}`}</RowItem>,
      },
      /*{
    field: 'id_consigne',
    type: 'custom',
    headerName: t('columns.parkNumber'),
    sortable: true,
    renderCell: ({ row }) => <RowItem key={'id_consigne' + row.id_consigne}>{`${row.id_consigne}`}</RowItem>,
  },*/
      {
        field: 'nom_type',
        type: 'string',
        headerName: t('columns.parkType'),
      },
      {
        field: 'nom_type',
        type: 'custom',
        renderCell: ({ row }) => (
          <RowItem key={'id_consigne' + row.id_consigne}>{`${
            row.is_upstairs === 1 ? t('common:yes') : t('common:no')
          }`}</RowItem>
        ),
        headerName: t('columns.upstairs'),
      },
      {
        field: '',
        type: 'custom',
        headerName: t('columns.reservation'),
        renderCell: ({ row }) => <BookingCell key={'booking' + row.id_consigne} bike={row as RawBike} />,
      },
      {
        field: '',
        width: 300,
        type: 'custom',
        headerName: t('columns.state'),
        renderCell: ({ row }) => (
          <StatusCell key={'status' + row.id_consigne} articleType={'park'} bike={row as RawBike} />
        ),
      },
    ],
    [t, filters]
  );
}

function ParkListPage() {
  const { t } = useTranslation(['park/genericList', 'common']);
  const { id_coll, id_pdl } = useSelector(selectCollAndDeliveryPointID);
  const [listParams, setListParams] = useState<ListParams>({
    order: undefined,
    filters: undefined,
    currentPage: 1,
    itemPerPage: 10,
  });

  const { data: parkListData, isFetching: isLoading } = useQuery(
    ['park.list', { ...listParams, id_coll, id_pdl }],
    ({ queryKey }) => {
      const [, listParams] = queryKey as [string, ListParams & { id_coll: number; id_pdl: number }];

      return getParkList({
        id_coll: listParams.id_coll,
        id_pdl: listParams.id_pdl,
        p: listParams.currentPage,
        ipp: listParams.itemPerPage,
        'order[column]': listParams.order?.column,
        'order[dir]': listParams.order?.dir,
        ...listParams.filters,
      });
    },
    { refetchOnWindowFocus: false, staleTime: 260_000, keepPreviousData: true }
  );

  const parkColumnDefinitions = useColumns(listParams.filters);

  return (
    <MainSection className={'reset'}>
      <Filters
        onChange={(filters) => setListParams((prev) => ({ ...prev, filters }))}
        translationNamespace={'park/genericList'}
        filtersRaw={parkListData?.data.filters || {}}
      />
      <Card>
        <Datagrid
          loading={isLoading}
          title={t('title.consigne')}
          translationNamespace={'park/genericList'}
          rows={parkListData?.data.result || []}
          columns={parkColumnDefinitions}
          totalLines={parkListData?.data.total}
          currentPage={parkListData?.data.currentPage}
          itemPerPage={parkListData?.data.itemPerPage}
          nbrPages={parkListData?.data.totalPage}
          onChangeFilterText={(search) =>
            setListParams((prev) => ({ ...prev, filters: { ...prev.filters, filter: search } }))
          }
          onSort={(order) => setListParams((prev) => ({ ...prev, order }))}
          handleChangePage={(newPage) => setListParams((prev) => ({ ...prev, currentPage: newPage }))}
          onChangeItemPerPage={(newValue) => setListParams((prev) => ({ ...prev, itemPerPage: newValue as number }))}
          search={parkListData?.data.filters.filter.selected}
          order={parkListData?.data.order}
        />
      </Card>
    </MainSection>
  );
}

export default ParkListPage;
