import _ from 'lodash';
import React, { useCallback, useId } from 'react';
import { Control, Controller, UseControllerProps } from 'react-hook-form';
import FieldError from '../components/Core/FieldError/FieldError';
import InputBase from '../components/Core/InputBase/InputBase';
import Label from '../components/Core/Label/Label';
import SelectBase from '../components/Core/SelectBase/SelectBase';
import { RadioItem } from '../pages/Reservation/components/BookingDetails/FinishCard';
import Toggle from '../components/Core/Toggle/Toggle';
import ToggleRadioGroup from '../pages/Parameters/Voucher/components/ToggleRadioGroup';
import { Option } from './useBuildForm';

type BaseField = {
  box?: boolean;
  name: string;
  label?: string;
  disabled?: boolean;
  wrapperClassName?: string;
  className?: string;
  required?: boolean;
  rules?: UseControllerProps['rules'];
};

type TextField = {
  type: 'text' | 'number';
  placeholder?: string;
};

type SelectField = {
  type: 'select';
  options: Option[];
};

type RadioItemField = {
  type: 'radio-item';
  big?: boolean;
  options: { label: string; value: unknown; disabled?: boolean }[];
};

type FileField = {
  type: 'file';
};

type ToggleField = {
  type: 'toggle';
};

type ToggleRadioGroupField = {
  type: 'toggleRadioGroup';
};

export type Field = BaseField &
  (TextField | FileField | RadioItemField | SelectField | ToggleField | ToggleRadioGroupField);

type UseGenerateFieldsParams = {
  fields: Field[];
  //eslint-disable-next-line
  control: Control<any>;
};

function useGenerateFields({ fields, control }: UseGenerateFieldsParams) {
  const uniqueId = useId();
  const commonProps = useCallback(
    (field: Field) => ({
      name: field.name,
      control,
      rules: field.rules,
      key: field.name.concat(uniqueId),
    }),
    [control]
  );

  return fields.map((fieldDefinition) => {
    const { box = false, label, type, name, wrapperClassName, className, ...propsToForward } = fieldDefinition;
    let fieldElement: React.ReactNode = <></>;
    const _wrapperClassName = [wrapperClassName];

    switch (type) {
      case 'toggleRadioGroup':
        _wrapperClassName.unshift('grid justify-start v-gap-4');
        fieldElement = (
          <Controller
            defaultValue={false}
            {...commonProps(fieldDefinition)}
            render={({ field: { onChange, value } }) => {
              return <ToggleRadioGroup label={label} value={value} name={name} onChange={onChange} />;
            }}
          />
        );
        break;
      case 'toggle':
        _wrapperClassName.unshift('row items-center v-gap-4');
        fieldElement = (
          <Controller
            defaultValue={false}
            {...commonProps(fieldDefinition)}
            render={({ field: { onChange, value } }) => {
              return <Toggle onChange={onChange} checked={value} />;
            }}
          />
        );
        break;
      case 'radio-item':
        fieldElement = (
          <Controller
            {...commonProps(fieldDefinition)}
            render={({ field: { onChange, value } }) => {
              return (
                <div className={['radio-group', fieldDefinition.big && 'big'].join(' ')}>
                  {fieldDefinition.options.map((option) => (
                    <RadioItem
                      key={option.label.concat(_.uniqueId())}
                      onChange={onChange}
                      selected={value === option.value}
                      checked={value === option.value}
                      name={fieldDefinition.name}
                      disabled={option.disabled}
                      value={option.value as string}
                      label={option.label}
                    />
                  ))}
                </div>
              );
            }}
          />
        );
        break;
      case 'select':
        fieldElement = (
          <Controller
            {...commonProps(fieldDefinition)}
            defaultValue={null}
            render={({ field: { onChange, value } }) => {
              return (
                <SelectBase
                  onChange={(optionSelected) => onChange((optionSelected as Option).value)}
                  value={fieldDefinition.options.find((option) => option.value === value) || value}
                  options={fieldDefinition.options}
                />
              );
            }}
          />
        );
        break;
      case 'text':
      case 'number':
        fieldElement = (
          <Controller
            {...commonProps(fieldDefinition)}
            defaultValue={type === 'text' ? '' : null}
            render={({ fieldState: { error }, field: { onChange, value } }) => {
              return (
                <>
                  <InputBase error={!!error} {...propsToForward} type={type} onChange={onChange} value={value || ''} />
                  {error && <FieldError>{error.message}</FieldError>}
                </>
              );
            }}
          />
        );
        break;
    }

    if (box) {
      _wrapperClassName.unshift('box-field');
    }

    if (label) {
      fieldElement = (
        <div key={fieldDefinition.name.concat('wrapper')} className={_wrapperClassName.join(' ')}>
          {label && <Label required={!!fieldDefinition.rules?.required || false}>{label}</Label>}
          {fieldElement}
        </div>
      );
    }

    return fieldElement;
  });
}

export default useGenerateFields;
