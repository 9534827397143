import useApi, { Methods } from 'internal/hooks/useApi';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectCollAndDeliveryPointID } from 'auth/selectors/authSelectors';
import { setSnackbar, showSnackbar } from 'internal/actions/appActions';
import SnackbarSeverity from 'internal/constants/snackbar';
import TextAreaInput from 'fragments/input/TextAreaInput';
import style from 'reservations/components/reservation/ReservationCommentary.module.css';

type Props = {
  resaID: string;
  commentary: string;
};

const ReservationCommentary = ({ commentary, resaID }: Props): ReactElement => {
  const { t } = useTranslation(['old', 'common']);
  const [comment, setComment] = useState('');
  const dispatch = useDispatch();
  const { id_coll, id_pdl } = useSelector(selectCollAndDeliveryPointID);

  const { request, response: submitResponse } = useApi(Methods.POST, 'user/commentaire');

  const onSubmitComment = useCallback(() => {
    if (id_coll && id_pdl) {
      request({
        id_coll,
        id_resa: resaID,
        content: comment,
      });
    }
  }, [id_coll, id_pdl, resaID, comment, request]);

  useEffect(() => {
    if (submitResponse?.success) {
      dispatch(setSnackbar(t('reservations.comment_submit'), SnackbarSeverity.SUCCESS));
      dispatch(showSnackbar());
    }
  }, [submitResponse]);

  return (
    <>
      <div className={'col-md-12'}>
        <p className={'lead'}>{t('reservations.commentary')}</p>
      </div>
      <div className={'col-md-12'}>
        <TextAreaInput className={style.text__input__booking} handleChange={setComment} defaultValue={commentary} />
      </div>
      <div className={'col-md-2'}>
        <button className={'btn btn-primary btn-fit'} onClick={onSubmitComment}>
          {t('common:edit')}
        </button>
      </div>
    </>
  );
};

export default ReservationCommentary;
