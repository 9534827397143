import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Button from 'fragments/button/Button';
import { t } from 'internal/i18n';
import React, { useMemo } from 'react';

type Props = {
  hasCheckout: boolean;
  handleOpenCheckout: () => void;
  cancelResa: () => void;
  handleClose: () => void;
  open: boolean;
};

//prettier-ignore
const ReservationCancelDialog = ({
 hasCheckout, handleOpenCheckout, cancelResa, handleClose, open, 
}: Props) => {
  const renderCancelResaButton = useMemo(() => {
    const action = hasCheckout ? handleOpenCheckout : cancelResa;

    return (
      <Button label={t('reservations.button_yes')} className={'btn-primary btn-fit btn-margin'} onClick={action} />
    );
  }, [cancelResa, hasCheckout, handleOpenCheckout]);

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>
        <div className={'modal-confirm-header'}>
          <div className={'modal-confirm-icon'}>!</div>
          <p className={'modal-confirm-title'}>{t('reservations.are_you_sure')}</p>
        </div>
      </DialogTitle>
      <DialogContent className={'modal-confirm-body'}>
        <p>{t('reservations.resa_cancel_confirm')}</p>
      </DialogContent>
      <DialogActions className={'modal-confirm-actions'}>
        {renderCancelResaButton}
        <Button label={t('reservations.button_no')} className={'btn-secondary btn-fit'} onClick={handleClose} />
      </DialogActions>
    </Dialog>
  );
};

export default ReservationCancelDialog;
