import dayjs from 'dayjs';

export const dashboardOrder = ['rv_0', 'rc_0', 'rcp_0', 'rv_30', 'rv_e0', 'rv_ed', 'rc_ed', 'rcp_ed'];

export const dashboardIcons: Record<string, string> = {
  rv_0: 'fa fa-bicycle text-primary fs-30',
  rc_0: 'fa fa-key text-primary fs-30',
  rcp_0: 'fa fa-key text-primary fs-30',
  rv_30: 'fa fa-bicycle text-info',
  rv_e0: 'fa fa-bicycle text-warning fs-30',
  rv_ed: 'fa fa-bicycle text-danger fs-30',
  rc_ed: 'fa fa-key text-danger fs-30',
  rcp_ed: 'fa fa-key text-danger fs-30',
};

export const dashboardParamsLinks: Record<string, string> = {
  rv_0: 'article=velo&today=1',
  rc_0: 'article=consigne&today=1',
  rcp_0: 'article=consignepark&today=1',
  rv_30: 'article=velo&tomorrow=1',
  rv_e0: 'article=velo&duedate=today',
  rv_ed: 'article=velo&duedate=past',
  rc_ed: 'article=consigne&duedate=past',
  rcp_ed: 'article=consignepark&duedate=past',
};

const FORMAT = 'YYYY-MM-DD';
export function getListReservationsFiltersById() {
  const today = dayjs().format(FORMAT);
  const tomorrowDay = dayjs().add(1, 'day');
  const tomorrow = `${tomorrowDay.format(FORMAT)}/${dayjs(tomorrowDay).add(30, 'day').format(FORMAT)}`;

  return {
    rv_0: `status=2&article=1&startDate=${today}/${today}`,
    rc_0: `status=2&article=2&startDate=${today}/${today}`,
    rcp_0: `status=2&article=3&startDate=${today}/${today}`,
    rv_30: `status=2&article=1&startDate=${tomorrow}`,
    rv_e0: `status=1&article=1&endDate=${today}/${today}`,
    rv_ed: `status=1&article=1&endDate=null/${today}`,
    rc_ed: `status=1&article=2&endDate=null/${today}`,
    rcp_ed: `status=1&article=3&endDate=null/${today}`,
  };
}
