/* eslint-disable object-curly-newline */
import { selectCollAndDeliveryPointID } from 'auth/selectors/authSelectors';
import useApi, { Methods } from 'internal/hooks/useApi';
import { RequestType } from 'internal/infrastructure/api';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { GET_CHECKIN_ENDPOINT } from 'reservations/constants/endpoints';
import { CheckinRawType } from 'reservations/types/checkin';

type Props = {
  dependencies: {
    resaID: string;
    openCheckin: boolean;
    open: boolean;
    fetchCheckouts: (options?: RequestType | undefined, body?: undefined) => void;
  };
};

const useCheckin = ({
  dependencies: { resaID, openCheckin, open, fetchCheckouts },
}: Props): [CheckinRawType[], () => void, boolean | undefined] => {
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);

  const [verifs, setVerifs] = useState<CheckinRawType[]>([]);

  const { request: requestCheckins, response: checkinResponse } = useApi<CheckinRawType[]>(
    Methods.GET,
    GET_CHECKIN_ENDPOINT
  );

  const fetchCheckins = useCallback(() => {
    requestCheckins({
      params: {
        id_coll,
        id_resa: resaID,
      },
    });
  }, [id_coll, resaID, openCheckin]);

  const isCheckinResponse = useMemo(() => checkinResponse?.success, [checkinResponse]);

  useEffect(() => {
    fetchCheckins();
  }, [id_coll, resaID, open]);

  useEffect(() => {
    if (checkinResponse?.success && checkinResponse.data) {
      setVerifs(checkinResponse.data);

      if (checkinResponse.data.length === 0) {
        fetchCheckouts();
      }
    }
  }, [checkinResponse]);

  return [verifs, fetchCheckins, isCheckinResponse];
};

export default useCheckin;
