import { t } from 'internal/i18n';

export const getTradFromStatus = (status: string | undefined): string => {
  switch (status) {
    case 'bon_etat':
      return t('reservations.checkin.good_condition');

    case 'used':
      return t('reservations.checkin.used');

    case 'defectueux':
      return t('reservations.checkin.defective');

    default:
      return t('reservations.checkin.good_condition');
  }
};
