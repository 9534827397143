import { selectSessionToken } from 'auth/selectors/authSelectors';
import Button from 'fragments/button/Button';
import Status from 'internal/constants/status';
import { t } from 'internal/i18n';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

type Props = {
  resaIsFree: boolean;
  userSign: string;
  contract: {
    url: string;
    label: string;
  } | null;
  askSignature: boolean;
  submitStatus: Status;
  handleOpenCheck: () => void;
  handleClickSignResa: () => void;
  hasCheckin: boolean;
  articleNotAttributed: boolean;
  isInWaitingList: boolean;
};

const ReservationContractPart = ({
  resaIsFree,
  userSign,
  contract,
  askSignature,
  submitStatus,
  handleOpenCheck,
  handleClickSignResa,
  hasCheckin,
  articleNotAttributed,
  isInWaitingList,
}: Props) => {
  const token = useSelector(selectSessionToken);

  const renderSignedResa = useMemo(() => {
    if (askSignature && !isInWaitingList) {
      const action = hasCheckin ? handleOpenCheck : handleClickSignResa;

      if (userSign) {
        return (
          <div>
            <img src={`${userSign}&front&token=${token}`} alt={'signature'} />
          </div>
        );
      }
      return (
        <Button
          label={t('reservations.sign_resa')}
          className={'btn btn-primary signresa btn-fit mt-6'}
          onClick={action}
        />
      );
    }
    return null;
  }, [userSign, askSignature, token, submitStatus, handleClickSignResa, hasCheckin, isInWaitingList]);

  const renderSignOrContractPart = useMemo(() => {
    if (contract) {
      return (
        <div className={'row'} id={'details_reservation'}>
          <div className={'col-md-12'}>
            {contract.label}
            <br />
            {contract.url && (
              <a id={'contratlink'} href={`${contract.url}&token=${token}`} target={'_blank'}>
                {t('reservations.contract')}
              </a>
            )}
          </div>
        </div>
      );
    }

    if (articleNotAttributed) {
      return null;
    }

    return (
      <>
        <div className={'row'}>
          <div className={'col-md-12'}>
            <div className={'row'}>
              <div className={'col-md-4'}>{renderSignedResa}</div>
            </div>
          </div>
        </div>
        {userSign && (
          <div className={'row'}>
            <div className={'col-md-12'}>{t('reservations.contract_generation')}</div>
          </div>
        )}
      </>
    );
  }, [resaIsFree, userSign, contract, renderSignedResa, articleNotAttributed]);

  return <>{renderSignOrContractPart}</>;
};

export default ReservationContractPart;
