import { useInstanceApi } from '../../../api/axios';
import { useRequest } from '../../../utils/request';

const URL = {
  fetchModuleParameters: 'param/modules',
  putModuleParameters: 'param/modules',
};

function useModuleParametersApi() {
  const instance = useInstanceApi();

  const { request: fetchModuleParameters } = useRequest((queryParams) =>
    instance.get(URL.fetchModuleParameters, { params: queryParams })
  );

  const { request: putModuleParameters } = useRequest((data) => instance.post(URL.fetchModuleParameters, data));

  return {
    fetchModuleParameters,
    putModuleParameters,
  };
}

export default useModuleParametersApi;
