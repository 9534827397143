import React, { DialogHTMLAttributes, HtmlHTMLAttributes, PropsWithChildren, useEffect, useRef } from 'react';
// import useClickOutside from '../../internal/hooks/useClickOutside';
import styles from './dialog.module.scss';

type Props = PropsWithChildren<
  DialogHTMLAttributes<HTMLDialogElement> & {
    onCancel: () => void;
    wrapperProps?: HtmlHTMLAttributes<HTMLDivElement>;
  }
>;

function Dialog({ children, wrapperProps, open, ...propsToForward }: Props) {
  const dialogRef = useRef(null);
  // useClickOutside(dialogRef, () => propsToForward.onCancel());

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [open]);

  return open ? (
    <div className={styles.Wrapper} role={'presentation'}>
      <div onClick={propsToForward.onCancel} className={styles.Backdrop} />
      <div className={styles.Container} {...wrapperProps}>
        <dialog ref={dialogRef} className={styles.Dialog} open={open} {...propsToForward}>
          {children}
        </dialog>
      </div>
    </div>
  ) : null;
}

export function DialogTitle({
  children,
  className,
  ...propsToForward
}: PropsWithChildren<HtmlHTMLAttributes<HTMLDivElement>>) {
  return (
    <div className={[styles.Dialog__title, className].join(' ')} {...propsToForward}>
      {children}
    </div>
  );
}

export const DialogContent = React.forwardRef<HTMLDivElement, PropsWithChildren<HtmlHTMLAttributes<HTMLDivElement>>>(
  ({ children, className, ...propsToForward }, ref) => {
    return (
      <div ref={ref} className={[styles.Dialog__content, className].join(' ')} {...propsToForward}>
        {children}
      </div>
    );
  }
);

export function DialogActions({ children, ...propsToForward }: PropsWithChildren<HtmlHTMLAttributes<HTMLDivElement>>) {
  return (
    <div className={styles.Dialog__actions} {...propsToForward}>
      {children}
    </div>
  );
}

export default Dialog;
