export const CREATE_USER_ENDPOINT = 'user/create';
export const USER_DETAILS_ENDPOINT = 'user/details';
export const USER_INVOICES_ENDPOINT = 'user/factures';
export const USER_LIST_ENDPOINT = 'user/list';
export const USER_CONTRACT_ENPOINT = 'user/contrats';
export const USER_RESA_ENDPOINT = 'user/reservations';
export const POST_USER_RESERVATION_ENDPOINT = 'user/reservation';
export const BIKE_TYPES_ENDPOINT = 'param/typesvelo';
export const FREEPARK_TYPES_ENDPOINT = 'param/typesconsignepark';
export const PARK_TYPES_ENDPOINT = 'param/typesconsigne';
export const RENTAL_DURATIONS_ENDPOINT = 'param/dureesloc';
export const RENTAL_PRICES_ENDPOINT = 'param/tarifsloc';
export const BIKE_AVAILABILITY_ENDPOINT = 'param/disposloc';
export const PARK_LOCATION_ENDPOINT = 'param/empl';
export const USER_CHECKOUT_ENDPOINT = 'user/caisse';
export const USER_ONLINE_PAY_ENDPOINT = 'user/caisseonline';
export const SECURITY_DEPOSIT_ENDPOINT = 'param/depotgarantie';
export const DEPOSIT_ENDPOINT = 'param/caution';
export const USER_TARIF_ENDPOINT = 'user/tarif';
