import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useOutletContext, useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { useQueryClient } from '@tanstack/react-query';
import { selectCollAndDeliveryPointID } from '../../../auth/selectors/authSelectors';
import Button from '../../../components/Core/Button/Button';
import Checkbox from '../../../components/Core/Checkbox/Checkbox';
import Datagrid, { RowItem } from '../../../components/Core/Datagrid/Datagrid';
import { GridColDefinition } from '../../../components/Core/Datagrid/gridCols';
import { TOAST_SEVERITY } from '../../../components/Core/Toast/Toast';
import FormGroupCard from '../../../components/FormGroupCard/FormGroupCard';
import { useSnackbar } from '../../../context/SnackbarContext';
import MainSection from '../../../components/Core/MainSection/MainSection';
import { useMainTitle } from '../../../components/Layout/MainTitleContext';
import useFormParametersApi from './useFormParametersApi';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function useFormFieldColumnDefinitions(defaultValues: Record<string, any>) {
  const { t } = useTranslation('admin/formParameters');
  const { control, reset, handleSubmit } = useForm({ defaultValues });

  // Need reset for another entity (object)
  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const formFieldColumnDefinitions = useMemo<GridColDefinition[]>(() => {
    return [
      {
        type: 'custom',
        field: 'name',
        headerName: 'Nom',
        renderCell: ({ row }) => {
          return <RowItem>{t(row.name)}</RowItem>;
        },
      },
      {
        type: 'custom',
        field: '',
        headerName: t('columns.displayed'),
        renderCell: ({ row }) => {
          return (
            <RowItem>
              <Controller
                defaultValue={false}
                control={control}
                render={({ field: { value, onChange, name } }) => (
                  <Checkbox key={name} onChange={onChange} checked={value} />
                )}
                name={row.name.concat('.displayed')}
              />
            </RowItem>
          );
        },
      },
      {
        type: 'custom',
        field: '',
        headerName: t('columns.required'),
        renderCell: ({ row }) => {
          return (
            <RowItem>
              <Controller
                control={control}
                defaultValue={false}
                render={({ field: { value, onChange, name } }) => (
                  <Checkbox key={name} onChange={onChange} checked={value} />
                )}
                name={row.name.concat('.required')}
              />
            </RowItem>
          );
        },
      },
    ];
  }, [control, t]);

  return {
    formFieldColumnDefinitions,
    handleSubmit,
  };
}

type FormFieldObject = {
  name: string;
  required: boolean;
  displayed: boolean;
};

function FormFieldListPage() {
  const { t } = useTranslation(['admin/formParameters', 'form', 'components/topBar']);
  const snackbar = useSnackbar();
  const { formSlug } = useParams();
  useMainTitle(t('components/topBar:pageTitles.forms'));
  //eslint-disable-next-line
  const formListResponse = useOutletContext<AxiosResponse<any> | undefined>();
  const queryClient = useQueryClient();
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const [formField, setFormFields] = useState<FormFieldObject[]>([]);
  const { putFormFieldParametersForPage } = useFormParametersApi();

  const defaultValuesFormatted = useMemo(() => {
    return formField.reduce((previousValue, currentValue) => {
      const { name, ...paramsToForward } = currentValue;
      return { ...previousValue, [name]: paramsToForward };
    }, {});
  }, [formField]);

  const { formFieldColumnDefinitions, handleSubmit } = useFormFieldColumnDefinitions(defaultValuesFormatted);

  const onSubmit = useCallback(
    handleSubmit(async (data) => {
      const fields = Object.keys(data).map((fieldName) => ({ name: fieldName, ...data[fieldName] }));
      const response = await putFormFieldParametersForPage({
        id_coll,
        form: {
          formName: formSlug,
          fields,
        },
      });

      if (response && response.status === 200) {
        queryClient.invalidateQueries(['param/forms']);
        snackbar?.setAlert({ message: t('form:global_update_success_message'), severity: TOAST_SEVERITY.success });
      }
    }),
    [queryClient, id_coll, formSlug]
  );

  useEffect(() => {
    if (formSlug && formListResponse && formSlug in formListResponse.data)
      setFormFields(formListResponse.data[formSlug]);
  }, [formListResponse, formSlug]);

  return (
    <MainSection className={'reset'}>
      <FormGroupCard
        title={t(formSlug as string)}
        WrapperComponent={({ children }) => <form onSubmit={onSubmit}>{children}</form>}
        FooterSlotComponent={({ defaultWrapper: DefaultWrapper }) => (
          <DefaultWrapper>
            <Button type={'submit'} label={t('form:global_update_label')} />
          </DefaultWrapper>
        )}>
        <Datagrid rows={formField} columns={formFieldColumnDefinitions} />
      </FormGroupCard>
    </MainSection>
  );
}

export default FormFieldListPage;
