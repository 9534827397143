import { TFunction } from 'i18next';
import Joi from 'joi';
import { FormObject } from '../../../hook/useBuildForm';
import countries from '../../../internal/utils/countries';
import JoiExtended from '../../../joi/joiExtended';

export function getUserCreateFormFields(t: TFunction, isArchived?: boolean): FormObject {
  return [
    {
      fields: [
        {
          id: 'nature',
          type: 'select',
          name: 'nature',
          label: t('form.nature'),
          placeholder: t('form.nature'),
          options: [
            { value: undefined, label: t('form.nature') },
            { value: 'part', label: t('form.individual') },
            { value: 'asso', label: t('form.association') },
            { value: 'soc', label: t('form.company') },
            { value: 'admin', label: t('form.administration') },
          ],
          disabled: isArchived,
          // schema: Joi.string().invalid('0').required(),
        },
        {
          type: 'select',
          name: 'civilite',
          label: t('form.civility'),
          placeholder: t('form.civility'),
          id: 'civilite',
          options: [
            { value: undefined, label: t('form.civility') },
            { value: 'm', label: t('form.mr') },
            { value: 'mme', label: t('form.mrs') },
            { value: 'entr', label: t('form.firm') },
            { value: 'asso', label: t('form.association') },
          ],
          disabled: isArchived,
        },
        {
          type: 'text',
          name: 'entr_asso',
          label: t('form.firm_or_asso'),
          placeholder: t('form.firm_or_asso'),
          id: 'entr_asso',
          disabled: isArchived,
        },
        {
          type: 'text',
          placeholder: t('form.last_name'),
          label: t('form.last_name'),
          name: 'nom',
          id: 'nom',
          columnStart: 1,
          rowStart: 2,
          disabled: isArchived,
          // schema: Joi.string().min(2),
        },
        {
          type: 'text',
          placeholder: t('form.first_name'),
          label: t('form.first_name'),
          name: 'prenom',
          id: 'prenom',
          disabled: isArchived,
        },
        {
          type: 'text',
          placeholder: t('form.email'),
          label: t('form.email'),
          name: 'email',
          id: 'email',
          schema: Joi.string().email({ tlds: false }).required(),
          required: true,
          disabled: isArchived,
        },
        {
          type: 'date',
          placeholder: t('form.birthdate'),
          label: t('form.birthdate'),
          name: 'date_naissance',
          id: 'date_naissance',
          disabled: isArchived,
        },
        {
          type: 'text',
          label: t('form.address'),
          placeholder: t('form.address'),
          name: 'adresse',
          id: 'adresse',
          columnStart: '1 / 3 span',
          disabled: isArchived,
        },
        {
          type: 'text',
          placeholder: t('form.full_address'),
          label: t('form.full_address'),
          name: 'adresse_compl',
          id: 'adresse_compl',
          disabled: isArchived,
        },
        {
          type: 'text',
          placeholder: t('form.zip'),
          label: t('form.zip'),
          name: 'cp',
          id: 'cp',
          disabled: isArchived,
        },
        {
          type: 'text',
          placeholder: t('form.city'),
          label: t('form.city'),
          name: 'ville',
          id: 'ville',
          disabled: isArchived,
        },
        {
          type: 'select',
          label: t('form.country'),
          name: 'pays',
          options: countries,
          id: 'pays',
          disabled: isArchived,
        },
        {
          type: 'phone',
          label: t('form.phone'),
          name: 'telephone',
          id: 'telephone',
          disabled: isArchived,
          // schema: Joi.string(),
        },
        {
          type: 'phone',
          label: t('form.mobile_phone'),
          name: 'telephone_port',
          id: 'telephone_port',
          disabled: isArchived,
        },
        {
          type: 'text',
          label: t('form.memberNumber'),
          placeholder: t('form.memberNumber'),
          name: 'num_adherent',
          id: 'num_adherent',
          disabled: isArchived,
        },
        {
          type: 'date',
          label: t('form.memberExpirationDate'),
          placeholder: t('form.memberExpirationDate'),
          name: 'date_exp_adherent',
          id: 'date_exp_adherent',
          disabled: isArchived,
        },
        {
          type: 'select',
          name: 'motif_adhesion',
          label: t('form.membershipReason'),
          placeholder: t('form.membershipReason'),
          options: [
            { value: undefined, label: t('form.membershipReason') },
            { value: 'velo-ecole', label: t('form.reasonOptions.school_bike') },
            { value: 'atelier', label: t('form.reasonOptions.workshop') },
            { value: 'location', label: t('form.reasonOptions.location') },
            { value: 'autres', label: t('form.reasonOptions.other') },
          ],
          columnStart: '1',
          disabled: isArchived,
        },
        {
          type: 'text',
          name: 'motif_adhesion_autre',
          label: t('form.membershipOtherReason'),
          placeholder: t('form.membershipOtherReason'),
          disabled: isArchived,
        },
      ],
    },
    {
      fields: [
        {
          type: 'select',
          name: 'boursier',
          id: 'boursier',
          label: t('form.boursier'),
          placeholder: t('form.boursier'),
          options: [
            { value: undefined, label: t('form.status') },
            { value: 'boursier', label: t('form.student') },
            { value: 'insertion', label: t('form.back_to_work') },
          ],
          disabled: isArchived,
        },
        {
          type: 'select',
          label: t('form.use_reason'),
          placeholder: t('form.use_reason'),
          name: 'motif_utilisation',
          id: 'motif_utilisation',
          options: [
            { value: undefined, label: t('form.reason') },
            { value: 'essai du service', label: t('form.service_trial') },
            { value: 'travail', label: t('form.work') },
            { value: 'loisirs', label: t('form.hobbies') },
            { value: 'autres', label: t('form.others') },
          ],
          disabled: isArchived,
        },
        {
          type: 'text',
          label: t('form.iban'),
          placeholder: t('form.iban'),
          name: 'iban',
          id: 'iban',
          columnStart: 1,
          schema: JoiExtended.bank().iban().allow(null).allow(''),
          disabled: isArchived,
        },
        {
          type: 'text',
          label: t('form.bic'),
          placeholder: t('form.bic'),
          name: 'bic',
          id: 'bic',
          schema: JoiExtended.bank().swiftbic().allow(null).allow(''),
          disabled: isArchived,
        },
      ],
    },
  ];
}
