import { selectCollAndDeliveryPointID } from 'auth/selectors/authSelectors';
import Button from 'fragments/button/Button';
import Checkbox from 'fragments/checkbox/Checkbox';
import DataTable from 'fragments/dataTable/DataTable';
import { setSnackbar, showSnackbar } from 'internal/actions/appActions';
import { COLLECTIVITY_PDL } from 'internal/constants/routes';
import SnackbarSeverity from 'internal/constants/snackbar';
import useApi, { Methods } from 'internal/hooks/useApi';
import useIsMobile from 'internal/hooks/useIsMobile';
import { t } from 'internal/i18n';
import { UPDATE_PDL_ENDPOINT } from 'parameters/constants/endpoints';
import { ParametersCollectivityType, ParametersPdlType, PDLObjectType } from 'parameters/types/collectivity';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

type Props = {
  coll: ParametersCollectivityType | null;
  pdls: ParametersPdlType[] | null;
};

const ParametersPDLs = ({ pdls, coll }: Props): ReactElement => {
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const [pdlState, setPdlState] = useState<Record<string, PDLObjectType>>({});
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const { request: updatePDL, response: updatePDLResp } = useApi(Methods.POST, UPDATE_PDL_ENDPOINT);

  const notifySuccess = useCallback(() => {
    dispatch(setSnackbar(t('parameters.modification_success'), SnackbarSeverity.SUCCESS));
    dispatch(showSnackbar());
  }, []);

  useEffect(() => {
    if (pdls && pdls.length) {
      setPdlState(
        pdls.reduce((acc, pdl) => {
          return {
            ...acc,
            [pdl.id_pdl]: {
              nom_pdl: pdl.nom || '',
              addr_pdl: pdl.adresse || '',
              cp_pdl: pdl.cp || '',
              ville_pdl: pdl.ville || '',
              alwinid_pdl: String(coll?.alwinSupervisorCodeId) || '',
              visible_pdl: !!pdl.is_visible,
              id_pdl: pdl.id_pdl,
              delai_reservation: pdl.delai_reservation || 0,
              limit_resa: pdl.limit_resa || 0,
            },
          };
        }, {})
      );
    }
  }, [pdls, coll]);

  useEffect(() => {
    if (updatePDLResp?.success) {
      notifySuccess();
    }
  }, [updatePDLResp]);

  const handleChangePdls = useCallback(
    (pdl: string, key: string) => (value: string | boolean) => {
      setPdlState((prevState) => ({
        ...prevState,
        [pdl]: {
          ...prevState[pdl],
          [key]: value,
        },
      }));
    },
    []
  );

  const handleRedirectPDL = useCallback(
    (id: number) => () => {
      navigate(`/parametres/collectivite${COLLECTIVITY_PDL}?id=${id}`);
    },
    [navigate]
  );

  const handleSubmitPDL = useCallback(
    (pdl: string) => () => {
      updatePDL({
        ...pdlState[pdl],
        id_coll,
      });
    },
    [pdlState, updatePDL, id_coll]
  );

  const renderPDLfields = useMemo(() => {
    if (pdlState) {
      return Object.keys(pdlState).map((pdl) => ({
        name: <span>{pdlState[pdl].nom_pdl}</span>,
        visible: (
          <Checkbox
            id={`${pdlState[pdl].id_pdl}_city`}
            checked={pdlState[pdl].visible_pdl}
            name={''}
            handleChange={handleChangePdls(pdl, 'visible_pdl')}
            disabled
          />
        ),
        action: (
          <div className={isMobile ? 'o-scroll' : ''}>
            <Button
              label={t('parameters.other_update')}
              className={'btn btn-w-xl btn-primary upd_pdl'}
              onClick={handleRedirectPDL(pdlState[pdl].id_pdl)}
            />
          </div>
        ),
      }));
    }

    return [];
  }, [pdlState, handleChangePdls, handleSubmitPDL, isMobile]);

  return (
    <>
      <p className={'lead'}>{t('parameters.pdl')}</p>
      <div className={isMobile ? 'o-scroll' : ''}>
        <div className={'dataTables_wrapper container-fluid dt-bootstrap4 no-footer'}>
          <DataTable
            cols={['name', 'visible', 'action']}
            colTraductionScope={'parameters/pdlDetails'}
            colHandlers={[]}
            items={renderPDLfields}
          />
        </div>
      </div>
    </>
  );
};

export default ParametersPDLs;
