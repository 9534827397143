export enum FRONT_ROUTES {
  HOME = 'HOME',
  SIGN_IN = 'SIGN_IN',
  SIGN_UP = 'SIGN_UP',
  MAIL_VALIDATION = 'MAIL_VALIDATION',
  CONTACT = 'CONTACT',
  FORGOTTEN_PWD = 'FORGOTTEN_PWD',
  CHANGE_PWD = 'CHANGE_PWD',
  MY_ACCOUNT = 'MY_ACCOUNT',
  MY_RESERVATIONS = 'MY_RESERVATIONS',
  MY_INVOICES = 'MY_INVOICES',
  WORKSHOP = 'WORKSHOP',
  SIGN_OUT = 'SIGN_OUT',
  RENT_BIKE = 'RENT_BIKE',
  RENT_STORAGE = 'RENT_STORAGE',
  RENT_PARK = 'RENT_PARK',
  INSURANCE = 'INSURANCE',
  ACCESSOIRES = 'ACCESSOIRES',
  REPAIR_AND_OVERHAUL = 'REPAIR_AND_OVERHAUL',
  RENEWAL = 'RENEWAL',
  LISTE_ATTENTE = 'LISTE_ATTENTE',
  RETURN_RENT = 'RETURN_RENT',
}

const wordingPages = [
  FRONT_ROUTES.HOME,
  FRONT_ROUTES.MY_ACCOUNT,
  FRONT_ROUTES.RENT_BIKE,
  FRONT_ROUTES.RENT_PARK,
  FRONT_ROUTES.RENT_STORAGE,
  FRONT_ROUTES.SIGN_UP,
  FRONT_ROUTES.SIGN_IN,
  FRONT_ROUTES.CHANGE_PWD,
  FRONT_ROUTES.FORGOTTEN_PWD,
  FRONT_ROUTES.MAIL_VALIDATION,
  FRONT_ROUTES.MY_INVOICES,
  FRONT_ROUTES.MY_RESERVATIONS,
  FRONT_ROUTES.CONTACT,
  FRONT_ROUTES.WORKSHOP,
  FRONT_ROUTES.REPAIR_AND_OVERHAUL,
  FRONT_ROUTES.RENEWAL,
  FRONT_ROUTES.LISTE_ATTENTE,
  FRONT_ROUTES.RETURN_RENT,
];

export { wordingPages };
