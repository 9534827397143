import styled from '@emotion/styled/macro';
import { FetchQueryOptions, useQuery } from '@tanstack/react-query';
import React, { ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosResponse } from 'axios';
import { getAllCollectivites, instance } from '../../api/user.api';
import { saveCollectivityList, savePdlList } from '../../auth/actions/authActions';
import { selectCollAndDeliveryPointID } from '../../auth/selectors/authSelectors';
import { ChevronIcon } from '../../const/icons';
import Menu from '../Menu/Menu';
import CollectivityModal from './CollectivityModal';
import useCurrentCollectivityInfos from './useCurrentCollectivityInfos';
import styles from './Sidebar.module.scss';

export const pdlListQuery: (queryParams: unknown) => FetchQueryOptions<AxiosResponse> = (queryParams) => ({
  queryKey: ['user/authpdl', queryParams],
  queryFn: ({ queryKey }) => {
    const [url, queryParams] = queryKey as [string, { id_coll: number }];
    return instance.get(url, { params: queryParams });
  },
});

function Sidebar({ top }: { top?: ReactNode }) {
  const dispatch = useDispatch();
  const [collectivityModalIsOpen, setIsOpen] = useState(false);
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const { title, subTitle } = useCurrentCollectivityInfos();

  useQuery(['user/auth'], () => getAllCollectivites(), {
    onSuccess: (response) => {
      dispatch(saveCollectivityList(response.data));
    },
    staleTime: 400_000,
    refetchOnWindowFocus: false,
  });

  const { data: pdlListByCollIdResponse } = useQuery({
    ...pdlListQuery({ id_coll }),
    staleTime: 400_000,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    dispatch(savePdlList(pdlListByCollIdResponse?.data));
  }, [pdlListByCollIdResponse]);

  return (
    <div className={[styles.Root, top ? styles.WithTop : null].join(' ')}>
      {top}
      <header>
        <ButtonTitle onClick={() => setIsOpen(true)}>
          <div>
            <CollectivityName>{title}</CollectivityName>
            <span>{subTitle}</span>
          </div>
          <ToggleSidebarIcon width={14} />
        </ButtonTitle>
        {collectivityModalIsOpen && (
          <React.Suspense fallback={<></>}>
            <CollectivityModal setIsOpen={setIsOpen} />
          </React.Suspense>
        )}
      </header>
      <Menu />
      {/*<SidebarFooter>footer content</SidebarFooter>*/}
    </div>
  );
}

const ToggleSidebarIcon = styled(ChevronIcon)``;

const CollectivityName = styled.h5`
  opacity: 1;
  color: white;
  font-size: 18px;
  font-weight: 700;
`;

const ButtonTitle = styled.button`
  cursor: pointer;
  width: 100%;
  padding: 22px 16px;
  text-align: left;

  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  border: none;
  outline: none;
  color: white;

  :focus,
  :focus-visible {
    outline: none;
  }
`;

/*const SidebarFooter = styled.footer`
  color: white;
`;*/

export default Sidebar;
