/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { ComponentPropsWithoutRef, HtmlHTMLAttributes, PropsWithChildren, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Option } from '../../../hook/useBuildForm';
import { getColor } from '../../../utils/style';
import { INPUT_COLORS } from '../InputBase/InputBase';
import Label from '../Label/Label';
import ListLoader from '../ListLoader/ListLoader';
import Pagination from '../Pagination/Pagination';
import SelectBase from '../SelectBase/SelectBase';
import TextField from '../TextField';
import { GridColDefinition } from './gridCols';
import { GridRowsProp, GridValidRowModel } from './gridRows';
import HeaderDataGrid from './HeaderDataGrid';
import styles from './datagrid.module.scss';

type DatagridProps = {
  title?: string;
  search?: string;
  totalLines?: number;
  itemPerPage?: number;
  translationNamespace?: string;
  className?: string;
  onChangeItemPerPage?: (newValue: unknown) => void;
  onChangeFilterText?: (search: unknown) => void;
  loading?: boolean;
  rows: GridRowsProp;
  columns: GridColDefinition[];
  RowComponent?: React.ComponentType<{
    key: React.Key;
    columns: GridColDefinition[];
    row: GridValidRowModel;
    index: number;
    colsTemplate: string;
  }>;
  // RowComponent?: React.ComponentType;
  // rowRender?: (props: ) => React.ReactNode[];
} & Omit<ComponentPropsWithoutRef<typeof HeaderDataGrid>, 'colsTemplate'> &
  Partial<ComponentPropsWithoutRef<typeof Pagination>>;

export function renderCell(column: GridColDefinition, row: GridValidRowModel, index: number) {
  if (column.type === 'custom') {
    return column.renderCell({ row, custom: '', index });
  }

  if (column.type === 'date') {
    const date = dayjs(row[column.field]);
    return (
      <RowItem key={'value-' + column.field + index}>
        {date.isValid() ? date.format(column.format || 'DD/MM/YYYY HH:mm:ss') : '-'}
      </RowItem>
    );
  }

  return <RowItem key={'value-' + column.field + index}>{_.get(row, column.field)}</RowItem>;
}

function Datagrid(props: DatagridProps) {
  const {
    currentPage,
    nbrPages,
    handleChangePage,
    columns,
    rows,
    title,
    totalLines,
    itemPerPage,
    onChangeItemPerPage,
    onChangeFilterText,
    RowComponent,
    order,
    loading = false,
    search,
    onSort,
    className,
    translationNamespace,
  } = props;
  const { t } = useTranslation(translationNamespace ? [translationNamespace, 'common'] : ['common']);

  const colsTemplate = useMemo(() => {
    return columns.reduce((previousValue, currentValue) => {
      let width = 'minmax(100px, 1fr)';
      if (currentValue.width)
        width =
          typeof currentValue.width === 'string' ? currentValue.width : currentValue.width.toString().concat('px');
      return `${previousValue} ${width}`;
    }, '');
  }, [columns]);

  const rowsNode = useMemo(() => {
    return rows.map((row, lineIndex) => {
      return RowComponent ? (
        // React.cloneElement(<RowComponent row={row} columns={columns} colsTemplate={''} index={lineIndex} />, { columns, row })
        // React.cloneElement(RowComponent, { columns, row })
        <RowComponent
          // key={'cell-'.concat(lineIndex.toString())}
          key={'cell-'.concat(_.uniqueId())}
          index={lineIndex}
          colsTemplate={colsTemplate}
          columns={columns}
          row={row}
        />
      ) : (
        <Row key={'line' + lineIndex} style={{ gridTemplateColumns: colsTemplate }}>
          {columns.map((column) => {
            return renderCell(column, row, lineIndex);
            /*if (column.type === 'custom') {
                return column.renderCell({ row, custom: '' });
              }

              return <RowItem key={'value-' + column.field + index}>{row[column.field]}</RowItem>;*/
          })}
        </Row>
      );
    });
  }, [rows, columns]);

  return (
    <DatagridRoot className={className}>
      {itemPerPage && (
        <Header>
          <div>
            <Label>{t('common:data_grid_item_per_page')}</Label>
            <SelectBase
              defaultValue={{ value: itemPerPage, label: itemPerPage.toString() }}
              onChange={(optionSelected: unknown) => onChangeItemPerPage?.((optionSelected as Option).value)}
              options={[
                { value: 10, label: '10' },
                { value: 20, label: '20' },
              ]}
            />
          </div>
          {onChangeFilterText && search !== undefined && (
            <TextField
              color={INPUT_COLORS.normal}
              onChange={_.debounce((newValue) => onChangeFilterText(newValue), 800)}
              defaultValue={search}
              label={t('filters.filter')}
              placeholder={t('filters.filter')}
            />
          )}
        </Header>
      )}
      {totalLines !== undefined && (
        <SecondHeader>
          <span>{totalLines.toString().concat(` ${t('common:data_grid_items')}`)}</span>
        </SecondHeader>
      )}
      <HeaderDataGrid
        noBorderTop={!title}
        order={order}
        columns={columns}
        onSort={onSort}
        colsTemplate={colsTemplate}
      />
      {loading ? (
        <div
          css={css`
            justify-self: center;
            margin: 40px 0;
          `}>
          <ListLoader color={'primary'} />
        </div>
      ) : (
        <div className={[styles.DataGrid__content, 'datagrid-content'].join(' ')}>{rowsNode}</div>
      )}
      {/*<ListLoader />*/}
      {currentPage && nbrPages && (
        <div
          css={css`
            display: flex;
            justify-content: center;
            padding: 16px;
          `}>
          <Pagination currentPage={currentPage} nbrPages={nbrPages} handleChangePage={handleChangePage} />
        </div>
      )}
    </DatagridRoot>
  );
}

const DatagridRoot = styled.div`
  display: grid;
  overflow-x: auto;
  //overflow-y: hidden;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
`;

const SecondHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px;
`;

type RowProps<T extends React.ElementType> = PropsWithChildren<HtmlHTMLAttributes<HTMLDivElement>> & {
  as?: T;
  colsTemplate?: string;
};

export const Row = React.forwardRef(
  <T extends React.ElementType = 'div'>(
    {
      children,
      as,
      className,
      colsTemplate,
      style,
      ...propsToForward
    }: RowProps<T> & Omit<React.ComponentPropsWithoutRef<T>, keyof RowProps<T>>,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    const Component = as ?? 'div';

    return (
      <Component
        ref={ref}
        style={colsTemplate ? { gridTemplateColumns: colsTemplate, ...style } : style}
        className={[styles.Row, className].join(' ')}
        {...propsToForward}>
        {children}
      </Component>
    );
  }
);

export const RowItem = styled.div`
  //align-self: center;
  font-size: 12px;
  color: ${getColor('gray.400')};
  display: flex;
  align-items: center;
`;

export default Datagrid;
