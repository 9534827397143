import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import DataHub from 'fragments/dataHub/DataHub';
import SnackbarSeverity from 'internal/constants/snackbar';
import useRefresh from 'internal/hooks/useRefreshList';
import useApi, { Methods } from 'internal/hooks/useApi';
import { setSnackbar, showSnackbar } from 'internal/actions/appActions';
import { selectCollAndDeliveryPointID } from 'auth/selectors/authSelectors';
import { UpdateAgentsType, ProfileType, PdlsType, AgentType, ChoicesType } from 'parameters/types/parameters';
import {
  AGENTS_ENDPOINT,
  AGENTS_INIT_ENDPOINT,
  AGENTS_UPDATE_ENDPOINT,
  AGENTS_ADD_ENDPOINT,
} from 'parameters/constants/endpoints';
import useIsMobile from 'internal/hooks/useIsMobile';

const AgentsAddList = (): ReactElement => {
  const { t } = useTranslation(['old', 'user/create']);
  const [perPage, setPerPage] = useState(10);
  const [maxItems, saveMaxItems] = useState(10);
  const [page, setPage] = useState(1);
  const [choices, setChoices] = useState<ChoicesType[]>([]);
  const [pdlValue, setSelectedPdl] = useState('1');
  const [profilValue, setSelectedProfil] = useState('1');
  const [pdlId, setPdlId] = useState('');
  const [id_agent, setUserId] = useState('');
  const [rights, saveFomattedChoices] = useState<UpdateAgentsType[]>([]);
  const [orderColumn, setOrderColumn] = useState('');
  const [order, setOrder] = useState('');
  const [filter, setFilter] = useState('');
  const [emptyNom, setEmptyNom] = useState('');
  const [emptyPrenom, setEmptyPrenom] = useState('');
  const [emptyEmail, setEmptyEmail] = useState('');
  const dispatch = useDispatch();
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);

  const { request, response } = useApi<{ total?: number; result: AgentType[] }>(Methods.GET, AGENTS_ENDPOINT);
  const { request: update, response: responseUpdate } = useApi(Methods.POST, AGENTS_UPDATE_ENDPOINT);
  const { request: selectAddAgents, response: responseSelectAddAgents } = useApi<{
    pdls: PdlsType[];
    profils: ProfileType[];
  }>(Methods.GET, AGENTS_INIT_ENDPOINT);
  const { request: addAgents, response: responseAddAgents } = useApi(Methods.POST, AGENTS_ADD_ENDPOINT);

  const getListAgents = useCallback(() => {
    request({
      params: {
        id_coll,
        p: page,
        ipp: perPage,
        'order[column]': orderColumn,
        'order[dir]': order,
        filter,
      },
    });
  }, [id_coll, page, perPage, orderColumn, order, filter]);

  const addToList = useCallback(() => {
    selectAddAgents({
      params: {
        id_coll,
      },
    });
  }, [id_coll]);

  useEffect(() => {
    getListAgents();
  }, [getListAgents]);

  useEffect(() => {
    addToList();
  }, [addToList]);

  useEffect(() => {
    if (response?.data?.total === 0 || response?.data?.total) {
      saveMaxItems(response?.data?.total);
    }
  }, [response?.data?.total]);

  useEffect(() => {
    if (response?.success && response?.data) {
      const bit_pdl = choices.reduce((acc, { value }) => acc + value, 0);
      const newRights = [...rights];
      const rightsPdl = rights.find(({ id_pdl }) => id_pdl === pdlId);

      if (rightsPdl) {
        rightsPdl.bit_pdl = bit_pdl;
      } else {
        newRights.push({
          id_pdl: pdlId,
          bit_pdl,
        });
      }

      saveFomattedChoices(newRights);
    }
  }, [response?.success, response?.data, pdlId, choices]);

  const onSubmitUpdateAgent = useCallback(() => {
    if (id_coll && rights && id_agent) {
      update({
        id_coll,
        id_agent,
        droits: JSON.stringify(rights),
      });
    }
  }, [id_coll, id_agent, rights]);

  const onChange = useCallback(
    (id: string) => (value: unknown) => {
      const pdlAndUserIds = id.split('_');
      setChoices(value as ChoicesType[]);
      setPdlId(pdlAndUserIds[0]);
      setUserId(pdlAndUserIds[1]);
    },
    []
  );

  const agentsListItems = useMemo(() => {
    // prettier-ignore
    return response?.data?.result?.map(({
      nom, prenom, email, id_user, pdls,
    }) => ({
      agents: (
        <div className={'row'} key={id_user}>
          <div className={'col-md-12'}>
            <div>
              {nom} {prenom}
            </div>
            <div>{email}</div>
          </div>
        </div>
      ),
      profile: pdls?.map(({ nom_pdl, profils, id_pdl }) => (
        <div className={'row'} key={id_pdl}>
          <div className={'col-md-4'}>
            <p style={{ display: 'inline' }}>{nom_pdl}</p>
          </div>
          <div className={'col-md-8'}>
            <Select
              key={`select-${id_pdl}-${id_user}`}
              options={profils?.map(({ nom_profil, bit_profil }) => ({
                value: bit_profil,
                label: nom_profil,
              }))}
              isMulti
              closeMenuOnSelect={true}
              onChange={onChange(`${id_pdl}_${id_user}`)}
              defaultValue={profils?.map(({ checked, nom_profil, bit_profil }) => {
                return checked ? { value: bit_profil, label: nom_profil } : '';
              })}
            />
          </div>
        </div>
      )),
      actions: (
        <div className={'row'}>
          <div className={'col-md-4'}>
            <button className={'btn btn-w-xl btn-primary btn-fit'} onClick={onSubmitUpdateAgent}>
              {t('parameters.agent_update')}
            </button>
          </div>
        </div>
      ),
    }));
  }, [response, onSubmitUpdateAgent]);

  useEffect(() => {
    if (responseUpdate?.success && responseUpdate?.data) {
      dispatch(setSnackbar(t('parameters.agent_update_success'), SnackbarSeverity.SUCCESS));
      dispatch(showSnackbar());
    }
  }, [responseUpdate?.success, responseUpdate?.data, t]);

  const handleFilters = useCallback(
    (column: string) => (orderDirection: string) => {
      setOrder(orderDirection);
      setOrderColumn(column);
    },
    []
  );

  const dataTableAgents = useMemo(
    () => ({
      cols: ['agents', 'profile', 'actions'],
      colHandlers: [handleFilters('nom')],
      colTraductionScope: 'admin/agents',
      items: agentsListItems || [],
      custom: true,
      style: {
        profile: { minWidth: '450px' },
      },
    }),
    [agentsListItems, handleFilters]
  );

  const renderPdl = useMemo(
    () =>
      responseSelectAddAgents?.data?.pdls?.map(({ id_pdl, nom }) => (
        <option key={id_pdl} value={id_pdl}>
          {nom}
        </option>
      )),
    [responseSelectAddAgents]
  );

  const renderProfil = useMemo(
    () =>
      responseSelectAddAgents?.data?.profils?.map(({ id_profil, nom_profil }) => (
        <option key={id_profil} value={id_profil}>
          {nom_profil}
        </option>
      )),
    [responseSelectAddAgents]
  );

  const refreshAgents = useRefresh(getListAgents, 100);

  const submitNewAgent = useCallback(() => {
    if (id_coll && pdlValue && profilValue) {
      addAgents({
        id_coll,
        nom: (document.getElementById('addagent_nom') as HTMLInputElement)?.value,
        prenom: (document.getElementById('addagent_prenom') as HTMLInputElement)?.value,
        email: (document.getElementById('addagent_email') as HTMLInputElement)?.value,
        profil: parseInt(profilValue),
        pdl: parseInt(pdlValue),
      });

      refreshAgents();

      setEmptyNom('');
      setEmptyPrenom('');
      setEmptyEmail('');
    }
  }, [id_coll, pdlValue, profilValue]);

  useEffect(() => {
    if (responseAddAgents?.success && responseAddAgents?.data) {
      dispatch(setSnackbar(t('parameters.agent_add_success'), SnackbarSeverity.SUCCESS));
      dispatch(showSnackbar());
    }
  }, [responseAddAgents?.success, responseAddAgents?.data, t]);

  const isMobile = useIsMobile();

  return (
    <>
      <p className={'lead'}>{t('parameters.agent_list_title')}</p>
      <div className={isMobile ? 'row o-scroll' : 'row'}>
        <div className={'col-md-12'}>
          <div className={'dataTables_wrapper container-fluid dt-bootstrap4 no-footer'}>
            <DataHub
              isSearchActive={true}
              setSearch={setFilter}
              setPerPage={setPerPage}
              dataTableProps={dataTableAgents}
              maxItems={maxItems}
              perPage={perPage}
              page={page}
              setPage={setPage}
            />
            <hr />
            <p className={'lead'}>{t('parameters.agent_add_title')}</p>
            <div className={'row'}>
              <div className={'col-md-2'}>
                <input
                  type={'text'}
                  className={'form-control'}
                  placeholder={t('user/create:form.last_name')}
                  id={'addagent_nom'}
                  value={emptyNom}
                  onChange={(e) => setEmptyNom(e.target.value)}
                />
              </div>
              <div className={'col-md-2'}>
                <input
                  type={'text'}
                  className={'form-control'}
                  placeholder={t('user/create:form.first_name')}
                  id={'addagent_prenom'}
                  value={emptyPrenom}
                  onChange={(e) => setEmptyPrenom(e.target.value)}
                />
              </div>
              <div className={'col-md-2'}>
                <input
                  type={'text'}
                  className={'form-control'}
                  placeholder={t('user/create:form.email')}
                  id={'addagent_email'}
                  value={emptyEmail}
                  onChange={(e) => setEmptyEmail(e.target.value)}
                />
              </div>
              <div className={'col-md-5'}>
                <div className={'row'}>
                  <div className={'col-md-8'}>
                    <select className={'form-control'} id={'listpdls'} onChange={(e) => setSelectedPdl(e.target.value)}>
                      {renderPdl}
                    </select>
                  </div>
                  <div className={'col-md-4'}>
                    <select
                      className={'form-control'}
                      id={'listprofils'}
                      onChange={(e) => setSelectedProfil(e.target.value)}>
                      {renderProfil}
                    </select>
                  </div>
                </div>
              </div>
              <div className={'col-md-1'}>
                <div className={'row'}>
                  <button id={'addagent'} className={'btn btn-primary'} onClick={submitNewAgent}>
                    {t('parameters.create_agent')}
                  </button>
                </div>
              </div>
            </div>
            <hr />
          </div>
        </div>
      </div>
    </>
  );
};

export default AgentsAddList;
