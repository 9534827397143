import { TFunction } from 'i18next';
import { FormItemType } from 'users/types/forms';

const collParamForm = (t: TFunction): FormItemType[][] => [
  [
    /*{
      type: 'text',
      placeholder: t('parameters.insurance_url'),
      label: t('parameters.insurance_url'),
      name: 'url_assurance',
      id: 'url_assurance',
      size: 'col-md-4',
    },*/
    {
      type: 'text',
      placeholder: t('parameters.emails_admin'),
      label: t('parameters.emails_admin'),
      name: 'emailreg_coll',
      id: 'emailreg_coll',
      size: 'col-md-6',
    },
  ],
];

export default collParamForm;
