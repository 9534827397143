import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getPayboxConfig, PutPayboxBody, putPayboxConfig } from '../../../api/admin/paymentProviders.api';
import { selectCollAndDeliveryPointID } from '../../../auth/selectors/authSelectors';
import FormCard from '../../../components/Core/FormCard/FormCard';
import { TOAST_SEVERITY } from '../../../components/Core/Toast/Toast';
import { useSnackbar } from '../../../context/SnackbarContext';
import useBuildForm from '../../../hook/useBuildForm';
import { payboxSystemForm } from './providers.forms';

function PayboxFormCard() {
  const snackbar = useSnackbar();
  const { t } = useTranslation('admin/paymentProviders');
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const { mutate: putConfig, isLoading } = useMutation(
    ['put.paymentProvider.paybox'],
    (data: Omit<PutPayboxBody, 'id_coll'>) => putPayboxConfig({ id_coll, ...data }),
    {
      onSuccess: () => {
        snackbar?.setAlert({ message: 'success', severity: TOAST_SEVERITY.success });
      },
    }
  );
  const { data: config } = useQuery(
    ['get.paymentProvider.paybox', id_coll],
    ({ queryKey }) => {
      const [, id_coll] = queryKey;
      return getPayboxConfig(id_coll as number);
    },
    {
      staleTime: 40000_000,
      refetchOnWindowFocus: false,
    }
  );
  const { builtFormFields, handleSubmit } = useBuildForm({
    formObject: payboxSystemForm(t),
    defaultValues: config?.data,
  });

  const onSubmit = useCallback(
    handleSubmit(async (data) => {
      putConfig(data as Omit<PutPayboxBody, 'id_coll'>);
    }),
    []
  );

  return (
    <FormCard title={t('payboxSystem.paybox_title')} isLoading={isLoading} onSubmit={onSubmit}>
      {builtFormFields}
    </FormCard>
  );
}

export default PayboxFormCard;
