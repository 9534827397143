import { SyntheticEvent } from 'react';
import useFormControlContext from '../FormControl/useFormControlContext';

export type UseInputParameters<T = string | number | readonly string[] | undefined> = {
  error?: boolean;
  value?: unknown;
  onChange?: (newValue: T, event?: SyntheticEvent) => void;
};

const useInput = <T = string | number | readonly string[] | undefined>(parameters: UseInputParameters<T>) => {
  const { value: valueProp, onChange: onChangePropEventHandler, error: errorProp = false } = parameters;
  const formControlContext = useFormControlContext();

  // TODO: add useControlled ?

  let value: unknown;
  let error: boolean;
  if (formControlContext) {
    value = formControlContext.value;
    error = formControlContext.error ?? false;
  } else {
    value = valueProp;
    error = errorProp;
  }

  // TODO: add event as optional parameter ?
  const handleChange = (newValue: T, event?: SyntheticEvent) => {
    formControlContext?.onChange?.(newValue);

    onChangePropEventHandler?.(newValue, event);
  };

  return {
    error,
    value: value as HTMLInputElement['value'],
    onChange: handleChange,
  };
};

export default useInput;
