import React from 'react';
import { AxiosResponse } from 'axios';
import { useOutletContext } from 'react-router-dom';
import MainSection from '../../../components/Core/MainSection/MainSection';
import NavigationSection from './Section/NavigationSection';
import ThemeColorSection from './Section/ThemeColorSection';

function ColorsPage() {
  //eslint-disable-next-line
  const themeConfig: AxiosResponse<any> | undefined = useOutletContext();

  return (
    <MainSection>
      <NavigationSection defaultValues={themeConfig?.data?.navbar} />
      <ThemeColorSection defaultValues={themeConfig?.data?.['primary-colors']} />
    </MainSection>
  );
}

export default ColorsPage;
