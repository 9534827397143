import { TFunction } from 'i18next';

export type OptionType = { label: string; value: string };

export const paymentsOptions = (isBooking?: boolean, t?: TFunction): OptionType[] => {
  const base = [
    { label: t?.('cb') || 'cb', value: 'cb' },
    { label: t?.('agency') || 'agency', value: 'agency' },
    { label: t?.('sepa') || 'sepa', value: 'sepa' },
  ];

  if (isBooking) {
    base.push({ label: t?.('sepamult') || 'sepamult', value: 'sepamult' });
    base.push({ label: t?.('cbmult') || 'cbmult', value: 'cbmult' });
  }

  return base;
};
