import { FormItemType, ValidationType } from 'users/types/forms';
import { validations } from 'users/utils/validationForms';
import { RemoteFieldType } from 'users/components/UsersResaBike';
import { bookingBikeForm, commonConsigneForm } from 'users/constants/forms';
import { TFunction } from 'i18next';

export const fieldsDictionnary = (t?: TFunction) => [...commonConsigneForm(t).flat(), ...bookingBikeForm(t).flat()];

export const processValidationFallback = (
  fieldName: string,
  verifyWhenNotEmpty = false
): ValidationType[typeof fieldName] => {
  if (!Object.prototype.hasOwnProperty.call(validations, fieldName)) {
    return validations.notEmpty;
  }

  if (verifyWhenNotEmpty) {
    return validations[`${fieldName}NotEmpty`];
  }

  return validations[fieldName];
};

const aggregateValidation = (rawField: FormItemType, required: boolean): FormItemType => {
  const verifyWhenNotEmpty = (rawField.name === 'iban' || rawField.name === 'bic') && !required;

  return {
    ...rawField,
    onError: required || verifyWhenNotEmpty ? processValidationFallback(rawField.name, verifyWhenNotEmpty) : undefined,
  };
};

const disableField =
  (schemaType?: string, t?: TFunction) =>
  (row: RemoteFieldType[]): FormItemType[] =>
    row.reduce((acc: FormItemType[], field: RemoteFieldType) => {
      if (!field.displayed) {
        return acc;
      }

      const _schemaType = schemaType ? `${schemaType}_` : '';

      const finalField = fieldsDictionnary(t).find((refField) => refField.id === `${_schemaType}${field.name}`);

      if (!finalField) {
        return acc;
      }
      //eslint-disable-next-line
      return [...acc, aggregateValidation(finalField as unknown as any, field.required)];
    }, []);

const computeFieldSize = (fieldRow: FormItemType[]) =>
  fieldRow.map((field: FormItemType) => ({
    ...field,
    size: `col-md-${12 / fieldRow.length}`,
  }));

export const assembleForm = (
  remoteForm: RemoteFieldType[][] | Record<string, RemoteFieldType[]>,
  schemaType?: string,
  t?: TFunction
): FormItemType[][] =>
  (Array.isArray(remoteForm) ? remoteForm : Object.values(remoteForm))
    .map(disableField(schemaType, t))
    .map(computeFieldSize);

export const isFormInvalid = (
  form: RemoteFieldType[][] | Record<string, RemoteFieldType[]>,
  formState: Record<string, string>
): boolean => {
  return (Array.isArray(form) ? form : Object.values(form))
    .flat()
    .filter(
      (field) =>
        (field.required || ((field.name === 'iban' || field.name === 'bic') && !field.required)) &&
        Object.prototype.hasOwnProperty.call(formState, field.name)
    )
    .some((field) => {
      const verifyWhenNotEmpty = (field.name === 'iban' || field.name === 'bic') && !field.required;

      const validation = processValidationFallback(field.name, verifyWhenNotEmpty);

      return validation(formState[field.name]);
    });
};
