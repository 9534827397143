import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCheckoutList } from '../../api/reservation.api';
import { selectCollAndDeliveryPointID } from '../../auth/selectors/authSelectors';
import ButtonWithIcon from '../../components/Core/Button/ButtonWithIcon';
import Card from '../../components/Core/Card/Card';
import Datagrid, { RowItem } from '../../components/Core/Datagrid/Datagrid';
import { GridColDefinition } from '../../components/Core/Datagrid/gridCols';
import MainSection from '../../components/Core/MainSection/MainSection';
import Dialog, { DialogActions, DialogContent, DialogTitle } from '../../components/Dialog/Dialog';
import useGenerateFields from '../../hook/useGenerateFields';
import { ListParams } from '../park/BikeList/BikeList';
import { usePenaltyFormFields, useRefundFormFields, useRegularizationFormFields } from './constants';
import styles from './bookingDetails.module.scss';

const checkoutListColumnDefinitions: (t: (key: string) => string) => GridColDefinition[] = (t) => [
  {
    field: 'date_created',
    type: 'date',
    headerName: t('receiptsPage.columns.dateCheckout'),
  },
  {
    field: 'montant_ttc',
    type: 'custom',
    headerName: t('receiptsPage.columns.amount'),
    renderCell: ({ row }) => (
      <RowItem>
        {row.montant_ttc}
        {row.devise}
      </RowItem>
    ),
  },
  {
    field: 'operation',
    type: 'string',
    headerName: t('receiptsPage.columns.checkoutOperation'),
  },
  {
    field: 'moyen_paiement',
    type: 'string',
    headerName: t('receiptsPage.columns.paymentMethod'),
  },
];

export enum TRANSACTION_TYPES {
  regularization = 'regularization',
  degradation = 'degradation',
  delay = 'delay',
  refund = 'refund',
}

function CreateCheckout() {
  const { t } = useTranslation(['reservation/reservation', 'common']);
  const [layoutElement, setLayoutElement] = useState<Element | null>(null);
  const [open, setIsOpen] = useState(false);
  const { control, handleSubmit, watch, reset } = useForm();
  const choiceTypeTransactionFieldElements = useGenerateFields({
    control,
    fields: useMemo(
      () => [
        {
          type: 'radio-item',
          name: 'transactionType',
          label: t('transactionType'),
          options: [
            { label: t('receiptsPage.regularization_title'), value: TRANSACTION_TYPES.regularization },
            { label: t('receiptsPage.degradation_title'), value: TRANSACTION_TYPES.degradation },
            { label: t('receiptsPage.delay_title'), value: TRANSACTION_TYPES.delay },
            { label: t('receiptsPage.refund_title'), value: TRANSACTION_TYPES.refund },
          ],
        },
      ],
      [t]
    ),
  });
  const transactionType: TRANSACTION_TYPES = watch('transactionType');
  const regularizationForm = useRegularizationFormFields();
  const penaltyForm = usePenaltyFormFields({ transactionType });
  const refundForm = useRefundFormFields();

  const onCancel = useCallback(() => {
    setIsOpen(false);
    reset();
  }, [reset, setIsOpen]);

  useLayoutEffect(() => {
    setLayoutElement(document.querySelector('#booking-layout'));
  }, []);

  const transactionForm = useMemo<
    { onSubmit: (onSuccess: () => void) => () => void; fieldElements: (JSX.Element | undefined)[] } | undefined
  >(() => {
    switch (transactionType) {
      case TRANSACTION_TYPES.regularization:
        return regularizationForm;
      case TRANSACTION_TYPES.degradation:
      case TRANSACTION_TYPES.delay:
        return penaltyForm;
      case TRANSACTION_TYPES.refund:
        return refundForm;
    }
  }, [handleSubmit, transactionType, regularizationForm, penaltyForm, refundForm]);

  return layoutElement
    ? ReactDOM.createPortal(
        <React.Fragment key={'create-checkout'}>
          <ButtonWithIcon onClick={() => setIsOpen(true)} icon={'AddWithoutCircle'} color={'major'}>
            {t('receiptsPage.newRental')}
          </ButtonWithIcon>
          {open && (
            <Dialog style={{ minWidth: 600 }} onCancel={onCancel} open={open}>
              <DialogTitle>{t('receiptsPage.newRental')}</DialogTitle>
              <form onSubmit={transactionForm?.onSubmit(onCancel)}>
                <DialogContent className={styles.CreateCheckoutModal__form}>
                  {choiceTypeTransactionFieldElements}
                  {transactionType ? transactionForm?.fieldElements : null}
                </DialogContent>
                {transactionType && (
                  <DialogActions>
                    <ButtonWithIcon onClick={onCancel} icon={'CrossWithoutCircle'} color={'secondary'}>
                      {t('common:cancel')}
                    </ButtonWithIcon>
                    <ButtonWithIcon icon={'CheckWithoutCircle'} type={'submit'}>
                      {t('receiptsPage.collection_btn')}
                    </ButtonWithIcon>
                  </DialogActions>
                )}
              </form>
            </Dialog>
          )}
        </React.Fragment>,
        layoutElement
      )
    : null;
}

function CheckoutListPage() {
  const { userId: id_user } = useParams();
  const { t } = useTranslation('reservation/reservation');
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const [listParams, setListParams] = useState<ListParams>({
    order: undefined,
    filters: undefined,
    currentPage: 1,
    itemPerPage: 10,
  });

  const { data: checkoutList, isFetching } = useQuery(
    ['booking.checkout.list', { ...listParams, id_coll, id_user }],
    ({ queryKey }) => {
      const [, params] = queryKey as [string, ListParams & { id_coll: number; id_user: number }];
      if (params.id_user) {
        return getCheckoutList({
          p: params.currentPage,
          ipp: params.itemPerPage,
          id_user: params.id_user,
          id_coll: params.id_coll,
        });
      }
    },
    {
      suspense: false,
      refetchOnWindowFocus: false,
      staleTime: 450_000,
      keepPreviousData: true,
    }
  );

  return id_user ? (
    <MainSection>
      <CreateCheckout />
      {/*<RegularizationFormCard />
      <PenaltyFormCard userId={id_user} />
      <PenaltyFormCard userId={id_user} type={'delay'} />*/}
      {/*<RefundFormCard userId={id_user} />*/}
      <Card>
        <Datagrid
          title={t('table_title')}
          loading={isFetching}
          rows={checkoutList?.data.result || []}
          columns={checkoutListColumnDefinitions(t)}
          totalLines={checkoutList?.data.total}
          currentPage={checkoutList?.data.currentPage}
          itemPerPage={checkoutList?.data.itemPerPage}
          nbrPages={checkoutList?.data.totalPage}
          onSort={(order) => setListParams((prev) => ({ ...prev, order }))}
          handleChangePage={(newPage) => setListParams((prev) => ({ ...prev, currentPage: newPage }))}
          onChangeItemPerPage={(newValue) => setListParams((prev) => ({ ...prev, itemPerPage: newValue as number }))}
        />
      </Card>
    </MainSection>
  ) : null;
}

export default CheckoutListPage;
