export const DASHBOARD = '/';
export const SIGN_IN = '/connexion';
export const USERS = '/usagers';
export const BIKE_PARK = '/parc';
export const INVOICING = '/contrats-factures';
export const FUND = '/caisse';
export const PARAMETERS = '/parametres';
export const STATS = '/statistiques';
export const FORGOT_PWD = '/mot-de-passe-oublie';

// @XXX: Sous-routes
export const PARK = '/consignes';
export const FREE_PARK = '/consignes-libres';
export const BIKE_LIST = '/velos';
export const ACCESSORIES_LIST = '/accessoires';
export const CREATE = '/creation';
export const EDIT = '/edition';
export const LIST = '/liste';
export const INVOICES = '/factures';
export const CONTRACTS = '/contrats';
export const BOOKINGS = '/reservations';
export const RESA_CREATION = '/nouvelle-reservation';
export const RESA_DETAIL = '/detail-reservation';
export const VALIDATION = '/validation';
export const CHECKOUT = '/paiement-agence';
export const SIGN = '/signature';
export const DAILY = '/jour';
export const MOVES = '/mouvements';
export const COLLECTIVITY = '/collectivite';
export const WAITING_LIST = '/file-attente';
export const COLLECTIVITY_PDL = '/pdl';
export const RENEWAL = '/renouvellement';
export const PAYMENT_PROVIDERS = '/prestataires-de-paiement';
export const STRIPE = '/paiement-en-ligne';
export const BROADCAST = '/diffusion';
export const PRICES = '/articles';
export const DEPOSITS = '/caution';
export const SECURITY_DEPOSITS = '/depot-de-garantie';
export const DEGRADATIONS = '/penalite-degradation';
export const DELAYS = '/penalite-retard';
export const THEME = '/visuel';
export const PERMISSION = '/droits';
export const LOCALES = '/langues';
export const FORMS = '/formulaires';
export const PAGES = '/pages';
export const WORKSHOP = '/page-atelier';
export const CONTACT = '/page-contact';
export const MODULES = '/modules-actifs';
export const CONFIG_DEPOSIT = '/config-caution';
export const CONFIG_BOOKING = '/config-reservation';
export const VOUCHERS = '/pieces-justificatives';
export const ADMINISTRATION = '/administration';
export const CHECKIN_CHECKOUT = '/checkin-checkout';
export const CHECKIN_CHECKOUT_TYPE = '/type-edition';

// @XXX: Routes de 3e niveau (créa résavélo/résaconsigne)
export const BIKE = '/velo';
