import { AxiosResponse } from 'axios';
import _ from 'lodash';
import React, { ComponentPropsWithoutRef, useEffect, useMemo, useState } from 'react';
import { QueryFunction, useQuery } from '@tanstack/react-query';
import Card from '../../../../components/Core/Card/Card';
import Datagrid from '../../../../components/Core/Datagrid/Datagrid';
import { GridColDefinition } from '../../../../components/Core/Datagrid/gridCols';
import Filters from '../../../../components/Core/Filters/Filters';
import { useDefaultListUrlParams } from '../../../../utils/request';

type GenericListProps = {
  title?: string;
  queryFn: QueryFunction<
    | AxiosResponse<{
        currentPage: number;
        itemPerPage: number;
        // eslint-disable-next-line
        filters: any;
        // eslint-disable-next-line
        result: any[];
        total: number;
        totalPage: number;
        order?: {
          column: string;
          dir: string;
        };
      }>
    | undefined
  >;
  queryKey: unknown[];
  columns: GridColDefinition[];
  transformFn?: (dataResponse?: AxiosResponse) => {
    //eslint-disable-next-line
    rows: any[];
    totalLines?: number;
    currentPage?: number;
    itemPerPage?: number;
    nbrPages?: number;
  };
} & Pick<ComponentPropsWithoutRef<typeof Datagrid>, 'RowComponent' | 'onChangeFilterText' | 'translationNamespace'>;

function GenericList(props: GenericListProps) {
  const defaultValues = useDefaultListUrlParams();
  const { queryFn, queryKey, columns, title, ...propsToForward } = props;
  const [listParams, setListParams] = useState<{
    filters?: Record<string, unknown>;
    order?: unknown;
    currentPage: number;
    itemPerPage: number;
  }>({
    order: undefined,
    filters: {},
    currentPage: 1,
    itemPerPage: 10,
  });

  useEffect(() => {
    setListParams((prev) => {
      const copy = { ...prev };
      _.assign(copy, _.pick(defaultValues, _.keys(prev)));
      // if ('filters' in defaultValues) _.assign(copy.filters, defaultValues['filters']);
      return copy;
    });
  }, [defaultValues]);

  const { data: listData, isLoading } = useQuery([...queryKey, { ...listParams }], queryFn, {
    suspense: false,
    refetchOnWindowFocus: false,
    staleTime: 260_000,
  });

  const datagridProps = useMemo(() => {
    return props.transformFn
      ? props.transformFn(listData)
      : {
          rows: listData?.data.result || [],
          itemPerPage: listData?.data.itemPerPage,
          totalLines: listData?.data.total,
          currentPage: listData?.data.currentPage,
          nbrPages: listData?.data.totalPage,
          order: listData?.data.order,
        };
  }, [props.transformFn, listData]);

  return (
    <>
      <Filters
        translationNamespace={propsToForward.translationNamespace || ''}
        filtersRaw={listData?.data?.filters || {}}
        onChange={(filters) => setListParams((prev) => ({ ...prev, filters }))}
      />
      <Card title={title}>
        <Datagrid
          loading={isLoading}
          // title={props.title}
          columns={columns}
          onChangeFilterText={(search) =>
            setListParams((prev) => ({ ...prev, filters: { ...prev.filters, filter: search } }))
          }
          search={listData?.data?.filters?.filter?.selected}
          {...{ ...datagridProps, ...propsToForward }}
          onSort={(order) => setListParams((prev) => ({ ...prev, order }))}
          handleChangePage={(newPage) => setListParams((prev) => ({ ...prev, currentPage: newPage }))}
          onChangeItemPerPage={(newValue) => setListParams((prev) => ({ ...prev, itemPerPage: newValue as number }))}
          RowComponent={props.RowComponent}
        />
      </Card>
    </>
  );
}

export default GenericList;
