import Joi from 'joi';
import { GetFormFieldsFunction } from '../Collectivity/updateForm';

const updateLocaleParametersForm: GetFormFieldsFunction = (t) => {
  return [
    {
      fields: [
        {
          id: 'fallback_locale',
          type: 'select',
          name: 'fallback_locale',
          label: t('select'),
          options: [
            { label: t('select'), value: '0' },
            { label: t('fr'), value: 'fr' },
            { label: t('en'), value: 'en' },
          ],
        },
        {
          type: 'select',
          isMulti: true,
          name: 'available_locales',
          id: 'available_locales',
          label: t('available_locales'),
          options: [
            { label: t('select_multiple'), value: '0' },
            { label: t('fr'), value: 'fr' },
            { label: t('en'), value: 'en' },
          ],
          schema: Joi.array(),
        },
      ],
    },
  ];
};

export default updateLocaleParametersForm;
