import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './SoonTag.module.scss';

function SoonTag() {
  const { t } = useTranslation(['common']);

  return <span className={styles.Tag}>{t('soon')}</span>;
}

export default SoonTag;
