import { useTheme } from '@emotion/react';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Grid from '../../../components/Core/Grid/Grid';
import FormGroupCard from '../../../components/FormGroupCard/FormGroupCard';
import { CheckIcon, InfoIcon } from '../../../const/icons';
import { UserDetailsType } from '../../../users/types/users';
import { getColor } from '../../../utils/style';

type LineProps = {
  icon: JSX.Element;
  i18nKey: string;
  //eslint-disable-next-line
  values: any;
};

function Line(props: LineProps) {
  const { t } = useTranslation('user/update');

  return (
    <Grid columns={'auto 1fr'} align={'center'}>
      {props.icon}
      <span style={{ maxWidth: 250 }}>
        <Trans t={t} i18nKey={props.i18nKey} values={props.values} />
      </span>
    </Grid>
  );
}

type StateCardProps = {
  user?: UserDetailsType;
};

function StateCard({ user }: StateCardProps) {
  const theme = useTheme();

  const lines = useMemo(() => {
    const _lines = [
      <Line
        icon={<CheckIcon style={{ color: getColor('green.200')({ theme }) }} width={20} />}
        i18nKey={'validatedAt'}
        values={{
          date: dayjs(user?.date_validbo).format('DD/MM/YYYY HH:mm'),
          validatedBy: `${user?.valid_by.nom} ${user?.valid_by.prenom}`,
        }}
      />,
      <Line
        icon={<InfoIcon style={{ color: getColor('primary.200')({ theme }) }} width={20} />}
        i18nKey={'createdAt'}
        values={{
          createdDate: dayjs(user?.date_created).format('DD/MM/YYYY HH:mm'),
        }}
      />,
    ];
    if (user?.date_updated)
      _lines.push(
        <Line
          icon={<InfoIcon style={{ color: getColor('primary.200')({ theme }) }} width={20} />}
          i18nKey={'updatedAt'}
          values={{
            updatedDate: dayjs(user?.date_updated).format('DD/MM/YYYY HH:mm'),
          }}
        />
      );

    if (user?.date_lastactivity)
      _lines.push(
        <Line
          icon={<InfoIcon style={{ color: getColor('primary.200')({ theme }) }} width={20} />}
          i18nKey={'lastActivity'}
          values={{
            date: dayjs(user?.date_lastactivity).format('DD/MM/YYYY HH:mm'),
          }}
        />
      );

    if (user?.date_anonymization)
      _lines.push(
        <Line
          icon={<InfoIcon style={{ color: getColor('primary.200')({ theme }) }} width={20} />}
          i18nKey={'archivedAt'}
          values={{
            date: dayjs(user?.date_anonymization).format('DD/MM/YYYY HH:mm'),
          }}
        />
      );

    return _lines;
  }, [user]);

  return user?.valid_bo === 1 ? (
    <FormGroupCard title={'À propos'}>
      <Grid columns={'auto'}>{lines}</Grid>
    </FormGroupCard>
  ) : null;
}

export default StateCard;
