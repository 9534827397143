import _ from 'lodash';
import { Theme, useTheme } from '@emotion/react';
import theme from '../theme';

type Join<K, P> = K extends string | number
  ? P extends string | number
    ? `${K}${'' extends P ? '' : '.'}${P}`
    : never
  : never;
type Prev = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, ...0[]];
export type Leaves<T, D extends number = 10> = [D] extends [never]
  ? never
  : T extends object
  ? { [K in keyof T]-?: Join<K, Leaves<T[K], Prev[D]>> }[keyof T]
  : '';

export type ValidColorsKey = Leaves<typeof theme.color, 4>;

export type NestedValueOf<Obj, Key extends string> = Obj extends object
  ? Key extends `${infer Parent}.${infer Leaf}`
    ? Parent extends keyof Obj
      ? NestedValueOf<Obj[Parent], Leaf>
      : never
    : Key extends keyof Obj
    ? Obj[Key]
    : never
  : never;

interface GetColorsFunction {
  (path: ValidColorsKey): (styledProps: { theme: Theme }) => NestedValueOf<typeof theme.color, ValidColorsKey>;
}

export const getColor: GetColorsFunction = (path) => (styledProps) => {
  return _.get(styledProps.theme.color, path);
};

export function useThemeUtils(): {
  getColor: (path: ValidColorsKey) => NestedValueOf<typeof theme.color, ValidColorsKey>;
} {
  const theme = useTheme();

  return {
    getColor: (path: string) => _.get(theme.color, path),
  };
}
