import DatePicker from 'fragments/datepicker/DatePicker';

import Input from 'fragments/input/Input';
import TextAreaInput from 'fragments/input/TextAreaInput';
import PhoneInput from 'fragments/phoneInput/PhoneInput';
import MultiSelect from 'fragments/select/MultiSelect';
import Select from 'fragments/select/Select';
import { devisesIcons } from 'internal/constants/devises';
import { selectCurrency } from 'internal/selectors/currencySelectors';
import { isEqual } from 'lodash';
import React, { Dispatch, ReactElement, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormItemType } from 'users/types/forms';

type State = Record<string, string>;

const useForm = (
  form: FormItemType[][],
  initialState: State,
  options: {
    rowWrapperClass?: string;
    shouldLoad?: boolean;
    controlled?: boolean;
  } = {
    rowWrapperClass: '',
    shouldLoad: false,
    controlled: false,
  }
): [ReactElement[] | ReactElement, State, Dispatch<SetStateAction<State | undefined>>] => {
  const currency = useSelector(selectCurrency);
  const [formState, setFormState] = useState<State | undefined>(initialState);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (options.shouldLoad) {
      if (!loading && !isEqual(initialState, formState)) {
        setFormState(initialState);
      }
    }
  }, [formState, setFormState, options.shouldLoad, initialState]);

  useEffect(() => {
    if (options.shouldLoad) {
      setTimeout(() => {
        setLoading(true);
      }, 1500);
    }
  }, [options.shouldLoad]);

  const handleChange = useCallback(
    (id: string) => (value: string) => {
      setFormState((prevState) => ({
        ...prevState,
        [id]: value,
      }));
    },
    []
  );

  const renderFormRow = useCallback(
    (formRow: FormItemType[]) => {
      return formRow.map(({ id = '', type, ...inputProps }: FormItemType): ReactElement | null => {
        if (inputProps?.icon && inputProps?.icon === 'devise') {
          inputProps.icon = devisesIcons[currency];
        }

        switch (type) {
          case 'select':
            return (
              <Select
                key={id}
                {...{ id, ...inputProps }}
                defaultValue={initialState[id]}
                value={options.controlled ? formState?.[id] : undefined}
                handleChange={handleChange(id)}
              />
            );

          case 'text':
            return (
              <Input
                key={id}
                {...{ id, type, ...inputProps }}
                defaultValue={initialState[id]}
                handleChange={handleChange(id)}
                value={options.controlled ? formState?.[id] : undefined}
                controlled={options.controlled}
              />
            );

          case 'textarea':
            return (
              <TextAreaInput
                key={id}
                {...{ id, type, ...inputProps }}
                defaultValue={initialState[id]}
                handleChange={handleChange(id)}
                value={options.controlled ? formState?.[id] : undefined}
                controlled={options.controlled}
              />
            );

          case 'number':
            return (
              <Input
                key={id}
                {...{ id, type, ...inputProps }}
                defaultValue={initialState[id]}
                handleChange={handleChange(id)}
                value={options.controlled ? formState?.[id] : undefined}
                controlled={options.controlled}
              />
            );

          case 'password':
            return (
              <Input
                key={id}
                {...{ id, type, ...inputProps }}
                defaultValue={initialState[id]}
                handleChange={handleChange(id)}
                value={options.controlled ? formState?.[id] : undefined}
                controlled={options.controlled}
              />
            );

          case 'date':
            return (
              <DatePicker
                key={id}
                {...{ id, type, ...inputProps }}
                defaultValue={initialState[id]}
                handleChange={handleChange(id)}
              />
            );

          case 'phone':
            return (
              <PhoneInput
                id={id}
                key={id}
                placeholder={inputProps.placeholder || ''}
                defaultValue={initialState[id]}
                size={inputProps.size}
                label={inputProps.label}
                handleChange={handleChange(id)}
              />
            );

          case 'multiselect':
            return (
              <MultiSelect
                key={id}
                onChange={handleChange(id)}
                defaultValue={initialState[id]}
                {...{ id, type, ...inputProps }}
              />
            );
          case 'void':
            return <div className={inputProps.size} />;

          default:
            return null;
        }
      });
    },
    [initialState, options, currency]
  );

  const renderForm = useMemo(() => {
    return form.map((formRow, i) => {
      if (options.rowWrapperClass === '' || !options.rowWrapperClass) {
        return <>{renderFormRow(formRow)}</>;
      }

      const key = `${i}`;

      return (
        <div key={key} className={options.rowWrapperClass}>
          {renderFormRow(formRow)}
        </div>
      );
    });
  }, [form, renderFormRow, options, initialState]);

  return [renderForm, formState || {}, setFormState];
};

export default useForm;
