import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { cloneDeep } from 'lodash';
import React, { useCallback } from 'react';
import { Control, FieldValues, FormState, UseFormReset, useFormState } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { putCaution, PutCautionBodyForm } from '../../../api/params/caution.api';
import { GridValidRowModel } from '../../../components/Core/Datagrid/gridRows';
import { useSnackbar } from '../../../context/SnackbarContext';
import IconButton from '../../../components/Core/Button/IconButton';
import { selectCollAndDeliveryPointID } from '../../../auth/selectors/authSelectors';
import { TOAST_SEVERITY } from '../../../components/Core/Toast/Toast';

type CautionListActionCellProps = {
  control: Control;
  //eslint-disable-next-line
  formState: FormState<any>;
  reset?: UseFormReset<FieldValues>;
  row: GridValidRowModel;
  handleDelete?: (depotId: number) => void;
};

function CautionListActionCell(props: CautionListActionCellProps) {
  const { t } = useTranslation('form');
  const { row, control, reset } = props;
  const snackbar = useSnackbar();
  const formState = useFormState({ control });
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const { isDirty, dirtyFields } = formState;
  const queryClient = useQueryClient();

  const { mutate: handlePutCaution } = useMutation(
    ['put-caution'],
    (bodyForm: PutCautionBodyForm) => {
      return putCaution(bodyForm);
    },
    {
      onSuccess: (data, variables) => {
        queryClient.setQueriesData(
          ['param/cautions', { id_coll }],
          //eslint-disable-next-line
          (oldList?: AxiosResponse<Record<string, any[]>>) => {
            const index = Object.values(oldList?.data || {})[0].findIndex(
              (deposit) => deposit.cautionId === variables.cautionId
            );
            if (index !== undefined) {
              const {
                caution: { article, ...caution },
                cautionId,
                articles,
              } = variables;
              const newList = cloneDeep(oldList);
              newList?.data[article].splice(index, 1, { ...caution, cautionId, articles });
              return newList;
            }

            return oldList;
          }
        );

        snackbar?.setAlert({ message: t('global_update_success_message'), severity: TOAST_SEVERITY.success });
        reset && reset([], { keepValues: true });
      },
    }
  );

  const isDisabled = !isDirty || Object.keys(dirtyFields).length === 0;

  const handleUpdate = useCallback(async () => {
    const formValues = control._formValues;
    const rowValue = {
      id_coll,
      cautionId: row.cautionId,
      caution: {
        article: 'velo',
        cautionName: formValues.cautionName,
        montant_ht: Number(formValues.montant_ht),
        taux_tva: row.taux_tva || 20,
      },
      articles: formValues.articles,
    };

    handlePutCaution(rowValue);
  }, [control._formValues, id_coll]);

  return (
    <div key={`actions-${row.id_caution}`} className={'row justify-end'}>
      <IconButton disabled={isDisabled} icon={'RefreshIcon'} color={'action'} onClick={handleUpdate} />
    </div>
  );
}

export default CautionListActionCell;
