import { useInstanceApi } from '../../../api/axios';
import { ParametersAdminType } from '../../../parameters/types/collectivity';
import { useRequest } from '../../../utils/request';

const URL = {
  fetchDetails: 'param/admin',
  udpateCollectivityAdminParameters: 'param/admin',
};

function useCollectivityAdminParametersApi() {
  const instance = useInstanceApi();

  const { request: fetchDetails } = useRequest<{ admin: ParametersAdminType }>((queryParams: { id_coll: number }) => {
    return instance.get(URL.fetchDetails, { params: queryParams });
  });

  const { request: udpateCollectivityAdminParameters } = useRequest((data) => {
    return instance.post(URL.udpateCollectivityAdminParameters, data);
  });

  return {
    fetchDetails,
    udpateCollectivityAdminParameters,
  };
}

export default useCollectivityAdminParametersApi;
