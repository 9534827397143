import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, NavLink, Outlet, useLocation, useMatch, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { selectCollAndDeliveryPointID } from '../../../auth/selectors/authSelectors';
import { Tab, Tabs, TabsList } from '../../../components/Core/Tabs/Tabs';
import { TabComponent } from '../../Reservation/Reservation.layout';
import { instance } from '../../../api/user.api';

function ContractsLayout() {
  const { t } = useTranslation(['old']);
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const navigate = useNavigate();
  const location = useLocation();
  const match = useMatch('/administration/contrats/:contractType');

  const { data: CGVResponse } = useQuery(
    ['param/cgv', { id_coll }],
    ({ queryKey }) => {
      const [, queryParams] = queryKey;
      return instance.get('param/cgv', { params: queryParams });
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 400_000,
    }
  );

  useEffect(() => {
    if (CGVResponse && !match) navigate(Object.keys(CGVResponse?.data || {})[0]);
  }, [CGVResponse, location, match]);

  return (
    <div className={'reset'}>
      <div id={'contracts-config-layout'}>
        <Tabs>
          <TabsList>
            {Object.keys(CGVResponse?.data || {})?.map((contractType) => (
              <Tab
                key={'tabs.'.concat(contractType)}
                component={({ children, ...otherProps }: TabComponent) => (
                  <NavLink {...otherProps} to={generatePath(contractType)} end>
                    {children}
                  </NavLink>
                )}>
                {t(`parameters.${contractType}`)}
              </Tab>
            ))}
          </TabsList>
        </Tabs>
      </div>

      <Outlet context={CGVResponse} />
    </div>
  );
}

export default ContractsLayout;
